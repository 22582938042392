import { Component } from 'preact';

import Header from './header';
import IndicatorTable from './indicator-table';
import get from 'lodash-es/get';

import { graphql } from '@apollo/client/react/hoc';
import FreeBusyQuery from '../../graphql/queries/calendar/free-busy.graphql';
import GetWorkingHoursQuery from '../../graphql/queries/calendar/working-hours.graphql';
import { callWith } from '../../lib/util';
import { merge as mergeFreeBusy } from '../../utils/free-busy';

import moment from 'moment';
import toPairs from 'lodash-es/toPairs';
import flatMap from 'lodash-es/flatMap';
import map from 'lodash-es/map';
import concat from 'lodash-es/concat';
import find from 'lodash-es/find';
import filter from 'lodash-es/filter';
import cloneDeep from 'lodash-es/cloneDeep';
import isString from 'lodash-es/isString';
import { CELL_WIDTH } from './constants';
import s from './style.less';
import { ATTENDEE_ROLE, CALENDAR_USER_TYPE } from '../../constants/calendars';
import findIndex from 'lodash-es/findIndex';
import { withText } from 'preact-i18n';
import { withProps } from 'recompose';

const freeBusyQueryVariables = ({ identitiesInfo, attendees, event }) => ({
	names: concat(
		[identitiesInfo.address],
		attendees.filter(attendee => !isString(attendee)).map(({ address }) => address)
	),
	start: moment(event.start).startOf('day').valueOf(),
	end: moment(event.start).endOf('day').valueOf()
});

@withText({
	allInvitees: 'calendar.editModal.availability.allInvitees'
})
@withProps(({ identitiesInfo }) => {
	const { zimbraPrefFromDisplay, zimbraPrefFromAddress } = get(identitiesInfo, 'identity.0._attrs');

	return {
		identitiesInfo: {
			address: zimbraPrefFromAddress,
			name: zimbraPrefFromDisplay
		}
	};
})
@graphql(FreeBusyQuery, {
	skip: ({ identitiesInfo, identitiesLoading, identitiesError }) =>
		!identitiesInfo || identitiesLoading || identitiesError,
	options: props => ({
		variables: freeBusyQueryVariables(props)
	}),
	props: ({
		ownProps: { attendees, identitiesInfo, isForwardInvite, prevAttendees, isProposeTime },
		data: { freeBusy = [], loading, ...rest }
	}) => {
		if (loading) {
			return {
				loading: true
			};
		}
		let userFreeBusy = freeBusy.map(({ id, __typename, ...statuses }) => {
			let statusInstances = flatMap(toPairs(statuses), ([status, events]) =>
				map(events, ({ start, end }) => ({
					status,
					start,
					end
				}))
			);

			// Remove any empty instances caused by null values created during
			// normalization, chronologically sort of statuses
			statusInstances = statusInstances.sort((instA, instB) => instA.start - instB.start);

			let attendee = find(attendees, ({ address }) => address === id);

			if (!attendee && id === identitiesInfo.address) {
				// Create an attendee entry for the current user, as it's missing
				// from the array.
				attendee = {
					...identitiesInfo,
					role: ATTENDEE_ROLE.required
				};
			}
			return {
				attendee,
				disableRequiredToggle: attendee.address === identitiesInfo.address,
				freeBusyStatuses: filter(statusInstances, inst => inst && inst.start && inst.end),
				isRequired: !attendee || attendee.role === ATTENDEE_ROLE.required,
				disableToggle:
					isProposeTime ||
					(isForwardInvite &&
						prevAttendees.some(prevAttendee => prevAttendee.address === attendee.address))
			};
		});

		if (!userFreeBusy.length) {
			// When freeBusy query has not resolved, fallback to using the raw attendee list
			// tobuild an empty table and prevent a scroll jump once the real data resolves.
			userFreeBusy = concat([identitiesInfo], attendees).map(attendee => ({
				attendee,
				disableRequiredToggle: attendee.address === identitiesInfo.address,
				statuses: [],
				isRequired: true
			}));
		}

		// re-order the array in which attendees + locations were added
		userFreeBusy.sort((a, b) => {
			const firstIndex = findIndex(attendees, ({ address }) => address === a.attendee.address);
			const secondIndex = findIndex(attendees, ({ address }) => address === b.attendee.address);
			return firstIndex - secondIndex;
		});

		// Create a mock attendee entry that represents the merged statuses of all
		// attendees.
		return {
			freeBusyData: {
				...rest,
				loading,
				freeBusy: userFreeBusy
			}
		};
	}
})
@graphql(GetWorkingHoursQuery, {
	skip: ({ identitiesInfo, freeBusyData }) => !identitiesInfo || !freeBusyData,
	options: props => ({
		variables: freeBusyQueryVariables(props)
	}),
	props: ({
		ownProps: { freeBusyData, allInvitees },
		data: { workingHours = [], loading, ...rest }
	}) => {
		if (loading) {
			return {
				loading: true
			};
		}
		const statusesData = workingHours.map(({ id, __typename, ...statuses }) => {
			const workingStatusInstances = flatMap(toPairs(statuses), ([status, events]) =>
				map(events, ({ start, end }) => ({
					status,
					start,
					end
				}))
			);

			const freeBusyArrayOfUsers = get(freeBusyData, 'freeBusy');

			const currentFreeBusy =
				freeBusyArrayOfUsers &&
				freeBusyArrayOfUsers.find(({ attendee }) => attendee.address === id);

			return {
				...currentFreeBusy,
				workingHourStatuses: workingStatusInstances
			};
		});

		const allFreeBusy = {
			attendee: { name: allInvitees },
			disableRequiredToggle: true,
			// Added (instance.start !== instance.end) check to skip instance which have same start and end as they don't makes any sense
			freeBusyStatuses: mergeFreeBusy(
				freeBusyData.freeBusy.map(
					({ isRequired, freeBusyStatuses }) => isRequired && freeBusyStatuses
				)
			).filter(inst => inst && inst.start && inst.end && inst.start !== inst.end),
			isRequired: true
		};

		return {
			availabilityIndicatorData: {
				...rest,
				loading,
				statusesData: concat([allFreeBusy], statusesData)
			}
		};
	}
})
export default class AvailabilityIndicator extends Component {
	onDayChange = dayModifier => {
		const availabilityDate = moment(this.props.event.start).add(dayModifier, 'days').valueOf();

		this.props.onStartChange(availabilityDate);
	};

	handleRefresh = () => this.props.freeBusyData.refetch(freeBusyQueryVariables(this.props));

	onChangeIsRequired = (attendee, newRole) => {
		const { forwardAttendees, isForwardInvite } = this.props;
		const attendees = cloneDeep(isForwardInvite ? forwardAttendees : this.props.attendees);
		const attendeeIdx = (isForwardInvite ? forwardAttendees : this.props.attendees).findIndex(
			a => a.address === attendee.address
		);
		if (attendeeIdx !== -1) {
			const updatedAttendee = {
				...attendees[attendeeIdx],
				role: newRole
			};
			attendees[attendeeIdx] = updatedAttendee;
			if (attendee.calendarUserType === CALENDAR_USER_TYPE.resource) {
				const locations = attendees.filter(a => a.calendarUserType === CALENDAR_USER_TYPE.resource);
				this.props.onLocationsChange({ value: locations });
			} else {
				this.props.onAttendeesChange({ value: attendees });
			}
		}
	};

	onGridClick = e => {
		e.preventDefault();
		e.stopPropagation();
		const { onStartChange, event } = this.props;
		const hourSlot = e.offsetX / CELL_WIDTH;

		if (hourSlot >= 24) return;

		const availabilityTime = moment(event.start)
			.hour(hourSlot)
			.minute(hourSlot % 1 >= 0.5 ? 30 : 0)
			.valueOf();

		onStartChange(availabilityTime);
	};

	render({ event, availabilityIndicatorData, allInvitees }) {
		return (
			<div class={s.availabilityIndicator}>
				<Header
					onNextDay={callWith(this.onDayChange, 1)}
					onPrevDay={callWith(this.onDayChange, -1)}
					onRefresh={this.handleRefresh}
					onClose={this.props.onClose}
					value={event.start}
				/>
				<IndicatorTable
					onChangeIsRequired={this.onChangeIsRequired}
					event={event}
					onGridClick={this.onGridClick}
					freeBusy={availabilityIndicatorData && availabilityIndicatorData.statusesData}
					allInviteesText={allInvitees}
				/>
			</div>
		);
	}
}
