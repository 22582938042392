import cx from 'classnames';
import ActionButton from '../../action-button';
import { connect } from 'react-redux';
import { toggle as toggleSidebar } from '../../../store/sidebar/actions';
import s from './style.less';

function SidebarButton(props) {
	return (
		<ActionButton
			icon="bars"
			monotone
			iconOnly
			onClick={props.toggleSidebar}
			className={cx(s.actionButton, props.className)}
		/>
	);
}

export default connect(null, { toggleSidebar })(SidebarButton);
