import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';

const initialState = null;

export default handleActions(
	{
		[actionCreators.setTemplate]: (state, action) => action.payload
	},
	initialState
);
