import { Text } from 'preact-i18n';
import moment from 'moment';
import { USER_FOLDER_IDS } from '../constants';
import { soapTimeToJson } from '../utils/prefs';
import { hasFlag, flattenFolders } from '../utils/folders';
import {
	CALENDAR_TYPE,
	CALENDAR_IDS,
	PARTICIPATION_STATUS,
	PREF_TO_VIEW,
	CALENDAR_REPEAT_FREQUENCY,
	weekDaySorter,
	INTERVAL_SHORTHAND_MAP,
	CALENDAR_USER_TYPE
} from '../constants/calendars';
import COLORS from '../constants/colors';
import get from 'lodash-es/get';
import array from '@zimbra/util/src/array';
import flatMap from 'lodash-es/flatMap';
import find from 'lodash-es/find';
import { deepClone, parseAddress, isValidEmail } from '../lib/util';
import { cloneWithoutTypeName } from '../graphql/utils/graphql';
import isEmpty from 'lodash-es/isEmpty';
import omit from 'lodash-es/omit';

export function calendarDateFormat(
	date,
	formats = {
		sameDay: '[Today]',
		nextDay: '[Tomorrow]',
		nextWeek: 'dddd',
		lastDay: '[Yesterday]',
		lastWeek: '[Last] dddd',
		sameElse: 'DD/MM/YYYY'
	}
) {
	return moment(date).calendar(null, formats);
}

// Is this a holiday calendar?
export const isHolidayCalendar = calendar =>
	calendar && !calendar.owner && calendar.url && /holiday/i.test(calendar.url);

// Is this a calendar owned by the user?
export const isOwnCalendar = calendar =>
	!calendar.owner && !calendar.url && !isHolidayCalendar(calendar);

// Is this an external calendar that has been linked
// with this user?
export const isOtherCalendar = calendar => calendar && calendar.owner;

export function getDefaultLocaleValue(value, format) {
	return format ? moment(value).locale('en').format(format) : moment(value).locale('en');
}

export const filteredCalendarList = (folder, id) => {
	const calendar = Object.assign({}, folder);
	const childFolders = get(calendar, 'folders');
	calendar.folders =
		childFolders &&
		childFolders.filter(child => child.id !== id).map(child => filteredCalendarList(child, id));
	return calendar;
};

export const calendarType = calendar =>
	isOtherCalendar(calendar)
		? CALENDAR_TYPE.other
		: isOwnCalendar(calendar)
		? CALENDAR_TYPE.own
		: isHolidayCalendar(calendar)
		? CALENDAR_TYPE.holiday
		: null;

export const prepareCalendarList = (folders, linkedFolders) => {
	const calendars = deepClone(
		[...(folders || []), ...(linkedFolders || [])].filter(
			cl => cl.id !== String(USER_FOLDER_IDS.TRASH)
		)
	);
	const calendarSections = {};

	Object.keys(CALENDAR_TYPE).forEach(key => (calendarSections[key] = []));

	calendars.forEach(cal => {
		const sectionsIdx = calendarType(cal);
		if (sectionsIdx) {
			if (sectionsIdx === CALENDAR_TYPE.other) {
				const childFolders = get(cal, 'folders');
				cal.folders =
					childFolders &&
					childFolders.map(function iter(a) {
						a.owner = cal.owner;
						a.ownerZimbraId = cal.ownerZimbraId;
						Array.isArray(a) && a.map(iter);
						return a;
					});
			}

			calendarSections[sectionsIdx].push(cal);
		}
	});
	return {
		calendars,
		calendarSections
	};
};

export const getIdsOfSubFolder = folder => flattenFolders([folder]).map(({ id }) => id);

export const getCheckedFolderIDS = (folders = []) =>
	getActiveCalendars(folders).map(({ id }) => id);

export const rearrangePrimaryCalendars = items =>
	items.reduce((sortedItems, item) => {
		item.id === CALENDAR_IDS[CALENDAR_TYPE.own].DEFAULT
			? sortedItems.unshift(item)
			: sortedItems.push(item);
		return sortedItems;
	}, []);

export const colorForCalendar = calendar => COLORS[(calendar && calendar.color) || 0];

export const filterNonEditableCalendars = calendars => {
	const { calendarSections } = prepareCalendarList(calendars);
	// remove the non-editable calendars from the others calendar
	calendarSections[CALENDAR_TYPE.other] = calendarSections[CALENDAR_TYPE.other].filter(
		otherCal => otherCal.permissions && otherCal.permissions.toLowerCase().indexOf('w') > -1
	);
	const primaryCalendars = calendarSections[CALENDAR_TYPE.own].concat(
		calendarSections[CALENDAR_TYPE.other]
	);
	return rearrangePrimaryCalendars(primaryCalendars);
};

export const filterNonInsertableCalendars = calendars =>
	flattenFolders(calendars).filter(
		calendar =>
			(!calendar.permissions || calendar.permissions.includes('i')) && !isHolidayCalendar(calendar)
	);

export function isParticipatingInEvent({ participationStatus }) {
	return !~[PARTICIPATION_STATUS.declined].indexOf(participationStatus);
}

export function getView({ zimbraPrefCalendarInitialView }) {
	return (
		(zimbraPrefCalendarInitialView && PREF_TO_VIEW[zimbraPrefCalendarInitialView]) ||
		PREF_TO_VIEW.month
	);
}

export function getWorkingHours(zimbraPrefCalendarWorkingHours) {
	return zimbraPrefCalendarWorkingHours && soapTimeToJson(zimbraPrefCalendarWorkingHours);
}

export function getRecurrenceField(recurrence, field, newEvent) {
	return newEvent ? get(recurrence, `add.rule.${field}`) : get(recurrence, `add.0.rule.0.${field}`);
}

export function fetchRecurrenceText({ recurrence, start, newEvent = false, ...rest }) {
	const freq = getRecurrenceField(recurrence, 'frequency', newEvent),
		days = array(
			getRecurrenceField(recurrence, newEvent ? 'byday.wkday' : 'byday.0.wkday', newEvent)
		).map(({ day }) => day),
		interval = getRecurrenceField(
			recurrence,
			newEvent ? 'interval.intervalCount' : 'interval.0.intervalCount',
			newEvent
		),
		count = getRecurrenceField(recurrence, newEvent ? 'count.number' : 'count.0.number', newEvent),
		endDate = getRecurrenceField(recurrence, newEvent ? 'until.date' : 'until.0.date', newEvent);

	if (!freq) {
		return;
	}
	const customRecurrenceObject = {},
		customRecurrenceString = [freq],
		numberOfDays = days.length;

	// Specify the interval of the event i.e Weekly/ Every 2 week.
	if (interval === 1) {
		customRecurrenceString.push('one');
	} else {
		customRecurrenceString.push('multiple');
		customRecurrenceObject.interval = interval;
	}

	if (freq === CALENDAR_REPEAT_FREQUENCY.weekly) {
		if (days.join(',') === 'MO,TU,WE,TH,FR') {
			// Display weekdays if all the weekdays are selected.
			customRecurrenceString.push('allWeek');
		} else if (numberOfDays) {
			// Display specific days selected.
			customRecurrenceObject.days = days
				.map(day => moment().day(weekDaySorter[day]).format('dddd'))
				.join(', ');
		} else {
			// Display weekly when no specific days are selected specifically.
			customRecurrenceString.push('noSpecificDay');
		}
	}

	if (freq === CALENDAR_REPEAT_FREQUENCY.yearly || freq === CALENDAR_REPEAT_FREQUENCY.monthly) {
		if (numberOfDays) {
			// Recurrence by weekday rule. Ex - Monthly on third thursday/ Annually on first tuesday of May
			customRecurrenceObject.order = rest['order' + Math.ceil(moment(start).date() / 7)];
			customRecurrenceObject.day = moment().day(weekDaySorter[days[0]]).format('dddd');
			customRecurrenceObject.monthName =
				freq === CALENDAR_REPEAT_FREQUENCY.yearly &&
				moment()
					.month(
						Number(
							getRecurrenceField(
								recurrence,
								newEvent ? 'bymonth.monthList' : 'bymonth.0.monthList',
								newEvent
							)
						) - 1
					)
					.format('MMMM');
			customRecurrenceString.push('byWeekDayRule');
		} else {
			// Recurrence by date rule. Ex - Monthly on Day 22/ Annually on May 22
			customRecurrenceString.push('byDateRule');
			customRecurrenceObject.date = moment(start).format(
				freq === CALENDAR_REPEAT_FREQUENCY.yearly ? 'MMM D' : 'D'
			);
		}
	}
	if (count || endDate) {
		customRecurrenceString.push('endCondition');
		// Add end condition, either by the number of occurrences i.e. count or by endDate
		if (count) {
			customRecurrenceString.push('byCount');
			customRecurrenceObject.count = count;
		} else {
			customRecurrenceString.push('byDate');
			customRecurrenceObject.endDate = moment(endDate).format('[customformatMonthDayYearMedium]');
		}
	} else {
		customRecurrenceString.push('noEndCondition');
	}

	return {
		customRecurrenceString: customRecurrenceString.join('.'),
		customRecurrenceObject
	};
}

export function CalendarOptionItem({ calendar, style }) {
	const { name } = calendar;
	return (
		<div class={style.calendarOptionItem}>
			<span class={style.calendarColor} style={{ backgroundColor: colorForCalendar(calendar) }} />
			<span class={style.calendarText}>
				{<Text id={`folders.${name.replace(' ', '_')}`}>{name}</Text>}
			</span>
		</div>
	);
}

// It returns the events with instances data, end and start date of the event.
// This return event is in the form which is required by big calendar to display events.
export function calendarSavedEvents(appointments) {
	return flatMap(appointments, appointment =>
		appointment.instances
			? appointment.instances.map(instance =>
					// Use exception data if available
					instance.isException
						? {
								...instance,
								date: new Date(instance.start),
								timezoneOffset: appointment.timezoneOffset,
								color: appointment.color,
								...(appointment.permissions && { permissions: appointment.permissions }),
								folderId: appointment.folderId,
								parentFolderName: appointment.parentFolderName,
								calendarId: appointment.calendarId
						  }
						: {
								...appointment,
								date: new Date(instance.start),
								utcRecurrenceId: instance.utcRecurrenceId
						  }
			  )
			: {
					...appointment,
					date: new Date(appointment.date)
			  }
	).map(event => ({
		...event,
		start: event.allDay
			? new Date(handleAllDayNonRecurringEvent(event.date, event.timezoneOffset))
			: event.date,
		end: event.allDay
			? moment(handleAllDayNonRecurringEvent(event.date, event.timezoneOffset))
					.add(event.duration - 1000, 'ms')
					.toDate()
			: moment(event.date).add(event.duration, 'ms').toDate()
	}));
}

function handleAllDayNonRecurringEvent(startDate, timezoneOffset) {
	const localOffset = moment(startDate).utcOffset() * (60 * 1000);
	const updatedDate = moment(startDate - localOffset + timezoneOffset);
	return updatedDate.valueOf();
}

export function setDefaultReminderValue(value) {
	return (
		(value === 0 && 'never') ||
		(value > 0 && value < 60 && `${value}m`) ||
		(value >= 60 && value < 1440 && `${value / 60}h`) ||
		(value >= 1440 && value < 10080 && `${value / (60 * 24)}d`) ||
		(value >= 10080 && value <= 20160 && `${value / (60 * 24 * 7)}w`) ||
		'0s'
	);
}

export function remindValueFor(relativeTrigger) {
	return (
		(relativeTrigger.weeks && `${relativeTrigger.weeks}w`) ||
		(relativeTrigger.days && `${relativeTrigger.days}d`) ||
		(relativeTrigger.hours && `${relativeTrigger.hours}h`) ||
		(typeof relativeTrigger.minutes === 'number' && `${relativeTrigger.minutes}m`) ||
		(typeof relativeTrigger.seconds === 'number' && `${relativeTrigger.seconds}s`)
	);
}

export function getVisibleAppointmentsData(calendars = [], zimlets) {
	// Hide holiday calendars if the Zimlet is not enabled
	const isHolidayZimletEnabled = find(
		zimlets.zimlet,
		zimlet =>
			get(zimlet, 'zimlet.0.name') === 'zm-x-zimlet-holiday-calendar' &&
			get(zimlet, 'zimletContext.0.presence') === 'enabled'
	);
	const checkedCalendars =
		flattenFolders(calendars).filter(calendar =>
			// Calendars added via Subscribe zimlet may not necessarily be of holiday. But appointments from such calendars should be shown irrespective of holiday zimlet status.
			isHolidayZimletEnabled || calendar.url
				? hasFlag(calendar, 'checked')
				: hasFlag(calendar, 'checked') && !isHolidayCalendar(calendar)
		) || [];

	return flatMap(checkedCalendars, c => {
		if (c.appointments) {
			const appointments =
				c.permissions && c.permissions.includes('f')
					? c.appointments.appointments.filter(
							appointment =>
								appointment.freeBusyActual &&
								(appointment.freeBusyActual.includes('F') ||
									appointment.freeBusyActual.includes('B'))
					  )
					: c.appointments.appointments;
			return appointments.map(appointment => ({
				...appointment,
				parentFolderName: c.name,
				color: colorForCalendar(c),
				...(c.permissions && { permissions: c.permissions }),
				owner: c.owner,
				calendarId: c.id
			}));
		}
		return [];
	});
}

export function savedEvents(calendars, zimlets) {
	const appointments = getVisibleAppointmentsData(calendars, zimlets);
	return calendarSavedEvents(appointments);
}

export function getEditableCalendarIDs(calendarsArray) {
	const calendars = flattenFolders(calendarsArray);
	return calendars && calendars.length
		? filterNonEditableCalendars(calendars).map(({ id, ownerZimbraId, sharedItemId }) =>
				ownerZimbraId && sharedItemId ? `${ownerZimbraId}:${sharedItemId}` : id
		  )
		: [];
}

export function getNonSharedActiveCalendars(calendars) {
	if (calendars && calendars.length > 0) {
		// This filter will be removed when reminders for shared calendars will be implemented.
		const nonSharedCalendars = calendars.filter(({ sharedItemId }) => sharedItemId === null);
		return getActiveCalendars(nonSharedCalendars);
	}
	return [];
}

export const getActiveCalendars = (calendars = []) =>
	flattenFolders(calendars).filter(cal => hasFlag(cal, 'checked'));

export function prepareCalendarsWithAppointments(calendars, appointments) {
	if (!appointments) {
		return calendars;
	}

	const categorizedAppointments = {};

	appointments.forEach(appointment => {
		if (!categorizedAppointments[appointment.folderId]) {
			categorizedAppointments[appointment.folderId] = [];
		}
		categorizedAppointments[appointment.folderId].push(appointment);
	});

	return getAppointmentsWithCalendar(calendars, categorizedAppointments);
}

export const getAppointmentsWithCalendar = (calendars, categorizedAppointments = []) => {
	calendars = deepClone(calendars);
	return calendars.map(calendar => {
		const actualCalId =
			calendar.ownerZimbraId && calendar.sharedItemId
				? `${calendar.ownerZimbraId}:${calendar.sharedItemId}`
				: calendar.id;

		calendar.folders =
			getAppointmentsWithCalendar(get(calendar, 'folders') || [], categorizedAppointments) || null;

		return {
			...calendar,
			appointments: {
				appointments: categorizedAppointments[actualCalId] || [],
				__typename: 'SearchResponse'
			}
		};
	});
};
//Event invitation time format for api
export function zimbraFormat(date, allDay) {
	return allDay
		? getDefaultLocaleValue(date, 'YYYYMMDD')
		: getDefaultLocaleValue(date, 'YYYYMMDD[T]HHmmss');
}

export function getReminderInterval(reminderValue) {
	const [, intervalValue, intervalType] = reminderValue.match(/(\d*)([mhdws])/);
	return {
		[INTERVAL_SHORTHAND_MAP[intervalType]]: parseInt(intervalValue, 10)
	};
}

export function getReminderValue(value) {
	return (
		(value.weeks && value.weeks * 10080) ||
		(value.days && value.days * 1440) ||
		(value.hours && value.hours * 60) ||
		(value.minutes && value.minutes) ||
		'0'
	);
}

export function handleAppointmentsNoOp(notifications, refetchAppointments, calendars) {
	// act if appointment was created or modified
	const createdAppointments = get(notifications, 'created.appt');
	const modifiedAppointments = get(notifications, 'modified.appt');

	let isSelectedFolderAppointmentCreated, isSelectedFolderAppointmentModified;

	// filter no.of checked calender list and assign to default folderId
	const checkedCalendarList = getActiveCalendars(calendars);

	if (
		(createdAppointments && createdAppointments.length > 0) ||
		(modifiedAppointments && modifiedAppointments.length > 0)
	) {
		if (createdAppointments && createdAppointments.length > 0) {
			isSelectedFolderAppointmentCreated = createdAppointments.some(appointment =>
				checkedCalendarList.some(calendar => appointment.l === calendar.id)
			);
		}

		if (modifiedAppointments && modifiedAppointments.length > 0) {
			isSelectedFolderAppointmentModified = modifiedAppointments.some(appointment =>
				checkedCalendarList.some(
					calendar =>
						/**
						 * Return true if modified event is from checked calendar or on delete event,
						 * appointment folder became trash folder
						 */

						appointment.l === calendar.id || appointment.l === USER_FOLDER_IDS.TRASH.toString()
				)
			);
		}

		if (isSelectedFolderAppointmentCreated || isSelectedFolderAppointmentModified) {
			refetchAppointments();
		}
	}
}

/**
 * @param {object} [baseEnd]	The end time of the appointment, should come from eveng obj or comp obj
 * @return {moment}				Returns rounded
 */
const roundToNearestMinute = baseEndTime => {
	const seconds = baseEndTime.getSeconds();
	return seconds >= 30
		? moment(baseEndTime)
				.add(60 - seconds, 's')
				.toDate()
		: moment(baseEndTime).toDate();
};

const getEventTimeMetadata = ({ event, comp, queryParams, editInstance }) => {
	let start, end, baseEndTime, eventStartTimeZone, eventEndTimeZone;
	const { start: queryStart, end: queryEnd, counter } = queryParams;

	//set up start & end time, rounding up to the minute if more than 30 seconds on the event
	if (counter && queryStart && queryEnd) {
		start = moment(queryStart).toDate();
		baseEndTime = moment(queryEnd).toDate();
		end = comp.allDay ? baseEndTime : roundToNearestMinute(baseEndTime);
	} else if (!isEmpty(comp) && !editInstance) {
		start = comp.allDay ? event.start : moment(get(comp, 'start.0.date')).toDate();
		baseEndTime = comp.allDay ? event.end : moment(get(comp, 'end.0.date')).toDate();
		end = comp.allDay ? baseEndTime : roundToNearestMinute(baseEndTime);
		eventStartTimeZone = get(comp, 'start.0.timezone');
		eventEndTimeZone = get(comp, 'end.0.timezone');
	} else {
		start = event.start;
		baseEndTime = event.end;
		end = event.allDay ? baseEndTime : baseEndTime && roundToNearestMinute(baseEndTime);
	}

	return {
		start,
		end,
		eventStartTimeZone,
		eventEndTimeZone
	};
};

const getNotesContent = (htmlString, isHtml = true) => {
	if (isHtml) {
		const notes = htmlString.replace(
			/(<html .*?><body id="htmlmode">)(.*)(<\/body><\/html>)/gs,
			'$2'
		);
		const regex = /~\*~\*<\/div><br.*?>(.*)/gs;
		const matchGroups = regex.exec(notes) || [];
		return matchGroups.length ? matchGroups[1] : notes;
	}
	return htmlString.replace(/\r\n|\n/gs, '<br>');
};

const extractLocationPayload = (locations, attendees) => {
	if (!(locations && locations.length)) return;
	if (Array.isArray(locations)) return locations;

	const locationGroup = locations.split(';');
	let customLocationStr = '';
	const extractedLocations = attendees.filter(
		attendee => get(attendee, 'calendarUserType') === CALENDAR_USER_TYPE.resource
	);

	// Custom locations entered by user should be classified into
	// 1. Tokens for syntactically valid email address
	// 2. Plain strings (strings containing semi-colon should be handled specially)
	locationGroup.forEach(location => {
		const trimmedLoc = location.trim();
		const parsedLocation = parseAddress(trimmedLoc);
		if (!extractedLocations.find(loc => loc.address === parsedLocation.address)) {
			if (isValidEmail(trimmedLoc)) {
				extractedLocations.push(parsedLocation);
			} else {
				customLocationStr = customLocationStr
					? customLocationStr.concat(';').concat(location)
					: customLocationStr.concat(location);
			}
		}
	});

	customLocationStr && extractedLocations.push(customLocationStr.trim());
	return extractedLocations;
};

/**
 *
 * @param {object} appointmentData - Appointment data received from server
 * @param {object} eventFromCalendar - Updated event data on edit event tab
 * @param {object} queryParams - URL params
 * @param {Boolean} editInstance - is instance open for edit
 * @param {Boolean} isCopyInvite - true when user copy existing event
 * @param {function} modifyAttendeeDataForCopy - To copy attendee from existing event to new copied event
 * @returns event data
 */

export function getEventSignificiantNonsignificantData(
	appointmentData,
	eventFromCalendar,
	queryParams,
	editInstance,
	isCopyInvite,
	modifyAttendeeDataForCopy
) {
	const message = appointmentData?.message;
	const comp = cloneWithoutTypeName(message?.invitations?.[0]?.components?.[0]);
	const htmlDescription = comp?.htmlDescription?.[0]?._content;
	const description = comp?.description?.[0]?._content;
	const isDraft = comp?.draft || eventFromCalendar?.draft;
	const rucurrenceRule = comp?.recurrence?.[0]?.add?.[0]?.rule?.[0];
	const repeatValue = rucurrenceRule?.frequency || 'NONE';
	const attendees = comp?.attendees || eventFromCalendar?.attendees || [];
	const locations = comp?.location || eventFromCalendar?.locations || [];
	const extractedLocations = extractLocationPayload(locations, attendees);
	const recurrenceUntilDate = rucurrenceRule?.until?.[0]?.date;

	const { start, end, eventStartTimeZone, eventEndTimeZone } = getEventTimeMetadata({
		event: eventFromCalendar,
		comp,
		queryParams,
		editInstance
	});

	const significiantEvent = {
		name: eventFromCalendar?.name || comp?.name || '',
		start,
		end
	};

	let eventPropertiesForCopy;
	if (isCopyInvite) {
		const owner = eventFromCalendar?.owner;
		const organizer = eventFromCalendar?.organizer;
		const { modifiedAttendees, modifiedOrganizer } = modifyAttendeeDataForCopy(
			attendees,
			organizer,
			owner
		);
		eventPropertiesForCopy = {
			isOrganizer: !owner && true,
			organizer: modifiedOrganizer,
			attendees: modifiedAttendees,
			draft: false
		};
		eventFromCalendar = omit(eventFromCalendar, [
			'revision',
			'modifiedSequence',
			'instances',
			'participationStatus',
			'parentFolderName',
			'utcRecurrenceId',
			'__typename'
		]);
	}

	const significiantEventData = {
		notes:
			(htmlDescription && getNotesContent(htmlDescription)) ||
			(description && getNotesContent(description, false)) ||
			eventFromCalendar?.excerpt ||
			'',
		attendees: (isCopyInvite ? eventPropertiesForCopy.attendees : attendees).map(att =>
			omit(att, '__typename')
		),
		...(extractedLocations && {
			locations: extractedLocations
		}),
		attachments: message?.attachments || eventFromCalendar?.attachments || [],
		eventStartTimeZone,
		eventEndTimeZone,
		isDraft,
		repeatValue,
		// until date converted UTC date format(e.g.20190720T035959Z) to required/supported format(e.g.20190720)
		endsOnDate: recurrenceUntilDate && moment(recurrenceUntilDate).format('YYYYMMDD'),
		endsAfterRecur: rucurrenceRule?.count?.[0]?.number,
		customByMonthDayRule: rucurrenceRule?.bymonthday?.[0],
		customByDayRule: rucurrenceRule?.byday?.[0],
		customByMonthRule: rucurrenceRule?.bymonth?.[0],
		customBySetPosRule: rucurrenceRule?.bysetpos?.[0],
		customIntervalRule: rucurrenceRule?.interval?.[0]?.intervalCount,
		isCustomRecurringEvent: rucurrenceRule
	};

	return {
		significiantEvent,
		significiantEventData,
		allDay: comp?.allDay || false,
		...(isCopyInvite && {
			updatedEventFromCalendar: eventFromCalendar,
			eventPropertiesForCopy
		})
	};
}
