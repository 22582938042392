import { Text } from 'preact-i18n';
import style from '../style';
import cx from 'classnames';
import Select from '../../select';
import { DATE_FIELD_NAME } from '../../../constants/locale';
import HelpTooltip from '../../help-tooltip';

const createSelectOptions = (options, fieldName) =>
	options.map(({ value, label }) => (
		<option value={value} key={value}>
			{fieldName === DATE_FIELD_NAME ? label : <Text id={label} />}
		</option>
	));

function DateTimeSettings({ defaulValue, options, fieldName, onFieldChange, matchesScreenXsMax }) {
	const textId = `settings.general.${fieldName}Label`;
	return (
		<div class={style.subsection}>
			<div class={cx(style.subsectionTitle, style.forSelect)}>
				<Text id={textId} />
			</div>
			<div class={style.subsectionBody}>
				<Select class={style.select} value={defaulValue} onChange={onFieldChange(fieldName)}>
					{createSelectOptions(options, fieldName)}
				</Select>
				{fieldName === DATE_FIELD_NAME && (
					<HelpTooltip
						position={matchesScreenXsMax ? 'left' : 'right'}
						customStyle={style.defaultGeneralTooltip}
					>
						<p class={style.helpTooltipTitle}>
							<Text id="settings.general.dateFormatLabel" />
						</p>
						<p>
							<Text id="settings.general.dateFormatHelpTip" />
						</p>
					</HelpTooltip>
				)}
			</div>
		</div>
	);
}

export default DateTimeSettings;
