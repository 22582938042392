import { Component } from 'preact';
import { Text, withText } from 'preact-i18n';
import Select from '../../select';
import { ChoiceInput, Button } from '@zimbra/blocks';
import style from './style.less';
import cx from 'classnames';
import get from 'lodash-es/get';
import concat from 'lodash-es/concat';
import set from 'lodash-es/set';
import merge from 'lodash-es/merge';
import has from 'lodash-es/has';
import find from 'lodash-es/find';
import cloneDeep from 'lodash-es/cloneDeep';
import { flattenFolders, canMoveMessagesIntoFolders } from '../../../utils/folders';
import { withPropsOnChange } from 'recompose';
import {
	RULE_PATH_PREFIX,
	getRulePredicateOptions,
	FILTER_ACTION_TYPE
} from '../../../constants/filter-rules';
import { INBOX } from '../../../constants/folders';
import TextInput from '../../text-input';
import {
	getFilterConditionConfig,
	findRulePredicateOption,
	dropFolderFilter,
	normalizePath
} from '../../../utils/settings-filter';
import TagAction from './tag-action';

@withText({
	toccLabel: 'settings.filterRules.tocc',
	fromLabel: 'settings.filterRules.from',
	subjectLabel: 'settings.filterRules.subject',
	bodyLabel: 'settings.filterRules.body',
	containsLabel: 'settings.filterRules.contains',
	containsNotLabel: 'settings.filterRules.containsNot',
	matchesWildCard: 'settings.filterRules.matchesWildCard',
	matchesWildCardNot: 'settings.filterRules.matchesWildCardNot',
	matchesExactlyLabel: 'settings.filterRules.matchesExactly',
	matchesExactlyNotLabel: 'settings.filterRules.matchesExactlyNot'
})
@withPropsOnChange(
	[
		'folders',
		'sharedFolders',
		'containsLabel',
		'containsNotLabel',
		'matchesWildCard',
		'matchesWildCardNot',
		'matchesExactlyLabel',
		'matchesExactlyNotLabel'
	],
	({
		folders,
		sharedFolders,
		containsLabel,
		containsNotLabel,
		matchesWildCard,
		matchesWildCardNot,
		matchesExactlyLabel,
		matchesExactlyNotLabel
	}) => ({
		folders: flattenFolders(folders.concat(canMoveMessagesIntoFolders(sharedFolders)))
			.filter(dropFolderFilter)
			.map(({ absFolderPath }) => normalizePath(absFolderPath)),
		rulePredicateOptions: getRulePredicateOptions({
			containsLabel,
			containsNotLabel,
			matchesWildCard,
			matchesWildCardNot,
			matchesExactlyLabel,
			matchesExactlyNotLabel
		})
	})
)
export default class FilterModalContent extends Component {
	state = {
		selectedAction: find(
			Object.keys(get(this.props.value, 'actions.0', [])),
			actionName => actionName !== FILTER_ACTION_TYPE.STOP && actionName
		)
	};

	onRulePredicateChange = rulePath => ev => {
		const value = cloneDeep(this.props.value);
		const rule = get(value, rulePath);
		const predicateForValue = get(this.props, `rulePredicateOptions.${ev.target.value}`);
		merge(rule, predicateForValue.update);
		this.props.onChange(set(value, rulePath, rule));
	};

	onRuleValueChange = rulePath => ev => {
		const value = cloneDeep(this.props.value);
		const rule = get(value, rulePath);
		rule.value = ev.target.value;
		this.props.onChange(set(value, rulePath, rule));
	};

	onRuleMatchCaseChange = rulePath => () => {
		const value = cloneDeep(this.props.value);
		const rule = get(value, rulePath);
		rule.caseSensitive = has(rule, 'caseSensitive') ? !rule.caseSensitive : true;
		this.props.onChange(set(value, rulePath, rule));
	};

	handleActionChange = ev => {
		const { folders, tagList } = this.props;
		const value = cloneDeep(this.props.value);
		const stop = get(value, 'actions.0.stop');
		let selectedAction;

		// reset actions so it only contains existing 'stop' value (removes old action so we can set the new one correctly)
		stop ? set(value, 'actions.0', { stop }) : set(value, 'actions.0', {});

		if (ev.target.value === FILTER_ACTION_TYPE.FILE_INTO || folders.indexOf(ev.target.value) > -1) {
			const actionPath = `actions.0.${FILTER_ACTION_TYPE.FILE_INTO}.0`;
			const action = get(value, actionPath) || {
				index: 0
			};
			action.folderPath =
				ev.target.value === FILTER_ACTION_TYPE.FILE_INTO ? INBOX : ev.target.value;

			set(value, actionPath, action);

			selectedAction = FILTER_ACTION_TYPE.FILE_INTO;
		} else if (ev.target.value === FILTER_ACTION_TYPE.REDIRECT) {
			set(value, 'actions.0.redirect', [{ address: '' }]);
			selectedAction = FILTER_ACTION_TYPE.REDIRECT;
		} else if (ev.target.value === FILTER_ACTION_TYPE.FLAG) {
			set(value, 'actions.0.flag', [{ flagName: 'flagged' }]);
			selectedAction = FILTER_ACTION_TYPE.FLAG;
		} else if (ev.target.value === FILTER_ACTION_TYPE.MARK_AS_READ) {
			set(value, 'actions.0.flag', [{ flagName: 'read' }]);
			selectedAction = FILTER_ACTION_TYPE.MARK_AS_READ;
		} else if (ev.target.value === FILTER_ACTION_TYPE.TAG) {
			const actionPath = 'actions.0.tag.0';
			const action = get(value, actionPath) || {
				index: 0
			};
			action.tagName = tagList[0];
			selectedAction = FILTER_ACTION_TYPE.TAG;
			set(value, actionPath, action);
		} else {
			// otherwise, change action to discard
			set(value, 'actions.0.discard', [{}]);
			selectedAction = FILTER_ACTION_TYPE.DISCARD;
		}

		this.props.onChange(value);
		this.setState({
			selectedAction,
			redirectToAddress: ''
		});
	};

	onTagNameChange = ev => {
		const value = cloneDeep(this.props.value);
		set(value, 'actions.0.tag.0.tagName', ev.target.value);
		this.props.onChange(value);
	};

	onRuleNameChange = ev => {
		const value = cloneDeep(this.props.value);
		value.name = ev.target.value;
		this.props.onChange(value);
	};

	onRedirectAddressChange = ev => {
		const { value } = this.props;
		this.props.onChange(set(value, 'actions.0.redirect.0.address', ev.target.value));
	};

	onProcessAdditionalChange = e => {
		const value = cloneDeep(this.props.value);

		if (e.target.checked) {
			value.actions[0].stop = [{ index: 1 }];
		} else {
			delete value.actions[0].stop;
		}

		this.props.onChange(value);
	};

	onAllOrAnyFilterChange = ev => {
		this.props.onChange(set(this.props.value, 'conditions.0.allOrAny', ev.target.value));
	};

	componentDidMount() {
		this.filterNameElement.focus();
	}

	renderMatchCaseLabel = (rule, rulePath) => (
		<label class={rule.caseSensitive ? cx(style.checkbox, style.checked) : style.checkbox}>
			<ChoiceInput onChange={this.onRuleMatchCaseChange(rulePath)} checked={rule.caseSensitive} />
			<Text id="settings.filterRuleModal.caseSensitive" />
		</label>
	);

	render(
		{
			value,
			folders,
			matchesScreenMd,
			title,
			rulePredicateOptions,
			toccLabel,
			fromLabel,
			bodyLabel,
			subjectLabel,
			switchToAdvancedFilter,
			filterMailFeature,
			tagList
		},
		{ selectedAction }
	) {
		const selectAllOrAnyRule = get(value, `conditions[0].allOrAny`);
		const actionFlagName = get(value, 'actions.0.flag.0.flagName');
		const isMarkedAsReadRule = actionFlagName === FILTER_ACTION_TYPE.MARK_AS_READ;
		const selectedDropdownValue = isMarkedAsReadRule ? actionFlagName : selectedAction;
		const processAdditionalDiv = [
			<div class={style.processAdditional}>
				<label>
					<ChoiceInput
						onChange={this.onProcessAdditionalChange}
						type="checkbox"
						checked={value.actions[0].stop}
					/>
					<Text id="settings.filterRuleModal.stopProcessing" />
				</label>
			</div>
		];
		return (
			<div class={style.filterModalContent}>
				<div class={cx(style.subsection, style.titleSubsection)}>
					{!matchesScreenMd && title && (
						<div class={style.title}>
							<Text id={title} />
						</div>
					)}
					<div class={cx(style.subsectionTitle, style.filterSubsectionTitle)}>
						<Text id="settings.filterRuleModal.filterNameLabel" />
					</div>
					<div class={style.subsectionBody}>
						<input
							class={cx(style.textInput, style.textInputOfFilter)}
							type="text"
							onChange={this.onRuleNameChange}
							value={value.name}
							ref={elem => (this.filterNameElement = elem)}
						/>
					</div>
					<Button
						class={style.advancedFilterSetting}
						styleType="text"
						onClick={switchToAdvancedFilter}
					>
						<Text id="settings.filterRuleModal.switchToAdvance" />
					</Button>
				</div>

				<div class={cx(style.half, style.inline, style.rulePrompt)}>
					<Text id="settings.filterRuleModal.rulePrompt1" />
				</div>
				<div class={cx(style.half, style.inline)}>
					<Select value={selectAllOrAnyRule} onChange={this.onAllOrAnyFilterChange}>
						<option value="allof">
							<Text id="settings.filterRules.allof" />
						</option>
						<option value="anyof">
							<Text id="settings.filterRules.anyof" />
						</option>
					</Select>
				</div>
				<div class={cx(style.half, style.inline, style.rulePrompt)}>
					<Text id="settings.filterRuleModal.rulePrompt2" />
				</div>
				<div class={style.ruleSection}>
					{getFilterConditionConfig({
						rulePredicateOptions,
						toccLabel,
						fromLabel,
						bodyLabel,
						subjectLabel
					})
						.filter(Boolean)
						.map(({ label, getRulePath, predicateOptions }) => {
							const rulePath = concat(RULE_PATH_PREFIX, getRulePath(get(value, RULE_PATH_PREFIX)));
							const rule = get(value, rulePath, {});
							const selectedPredicateOption = findRulePredicateOption(rule, rulePredicateOptions);
							return (
								<div class={style.subsection}>
									<div class={style.subsectionTitle}>{label}</div>
									<div class={style.subsectionBody}>
										<div class={cx(style.half, style.inline)}>
											<Select
												value={selectedPredicateOption.value}
												onChange={this.onRulePredicateChange(rulePath)}
												fullWidth
											>
												{predicateOptions.map(option => (
													<option value={option.value}>{option.label}</option>
												))}
											</Select>
										</div>
										<input
											class={cx(style.textInput, style.textInputOfFilter)}
											type="text"
											onChange={this.onRuleValueChange(rulePath)}
											value={rule.value}
										/>
										{matchesScreenMd && this.renderMatchCaseLabel(rule, rulePath)}
									</div>
									{!matchesScreenMd && this.renderMatchCaseLabel(rule, rulePath)}
								</div>
							);
						})}
				</div>
				<div class={cx(style.subsection, style.moveMessageSubsection, style.marginBottom)}>
					<div class={cx(style.subsectionTitle, style.thenLabel)}>
						<Text id="settings.filterRuleModal.thenLabel" />
					</div>
					<div class={style.subsectionBody}>
						<div class={cx(style.half, style.inline, style.itemSelect)}>
							<Select value={selectedDropdownValue} onChange={this.handleActionChange} fullWidth>
								<option value={FILTER_ACTION_TYPE.FILE_INTO}>
									<Text id="settings.filterRuleModal.actions.moveToFolder" />
								</option>
								<option value={FILTER_ACTION_TYPE.MARK_AS_READ}>
									<Text id="settings.filterRuleModal.actions.markAsRead" />
								</option>
								<option value={FILTER_ACTION_TYPE.DISCARD}>
									<Text id="settings.filterRuleModal.actions.discard" />
								</option>
								{filterMailFeature && (
									<option value={FILTER_ACTION_TYPE.REDIRECT}>
										<Text id="settings.filterRuleModal.actions.redirect" />
									</option>
								)}
								<option value={FILTER_ACTION_TYPE.FLAG}>
									<Text id="settings.filterRuleModal.actions.flag" />
								</option>
								{tagList && (
									<option value={FILTER_ACTION_TYPE.TAG} disabled={!tagList.length}>
										<Text id="settings.filterRuleModal.actions.tagWith" />
									</option>
								)}
							</Select>
						</div>
						<div class={cx(style.smallerSelect, style.itemSelect)}>
							{selectedAction === FILTER_ACTION_TYPE.FILE_INTO && (
								<MoveToFolderSelect
									folders={folders}
									onChange={this.handleActionChange}
									value={value}
									selectedAction={selectedAction}
								/>
							)}
							{selectedAction === FILTER_ACTION_TYPE.REDIRECT && (
								<RedirectAddressInput
									onChange={this.onRedirectAddressChange}
									value={value}
									selectedAction={selectedAction}
								/>
							)}
							{selectedAction === FILTER_ACTION_TYPE.TAG && (
								<TagAction tagList={tagList} onChange={this.onTagNameChange} value={value} />
							)}
						</div>
						{matchesScreenMd && processAdditionalDiv}
					</div>
					{!matchesScreenMd && processAdditionalDiv}
				</div>
			</div>
		);
	}
}

function MoveToFolderSelect({ folders, value, onChange }) {
	const folderSelect = get(value, `actions.0.${FILTER_ACTION_TYPE.FILE_INTO}.0.folderPath`);

	return (
		<Select value={folderSelect} onChange={onChange} fullWidth>
			{folders.map(fPath => (
				<option value={fPath}>
					{fPath.search(/\//g) === -1 ? <Text id={`folders.${fPath}`}>{fPath}</Text> : fPath}
				</option>
			))}
		</Select>
	);
}

function RedirectAddressInput({ value, onChange }) {
	return (
		<TextInput
			class={cx(style.textInputOfFilter, style.actionsInput)}
			type="email"
			onChange={onChange}
			value={get(value, 'actions.0.redirect.0.address')}
		/>
	);
}
