import { Component } from 'preact';
import { Text, withText } from 'preact-i18n';
import includes from 'lodash-es/includes';
import get from 'lodash-es/get';
import { getEmail, isValidEmail } from '../../lib/util';

import ShareInfoCard from '../share-info-card';
import AddressField from '../address-field';
import PermissionsSelect from './permissions-select';

import s from './style.less';

@withText('shareItemModal.emailAddressesPlaceholder')
export default class EmailShareInfoCard extends Component {
	isItemAlreadySharedWithUser = email => includes(this.props.aclEmails, email);

	isEmailTokenSameAsOwner = email => get(this.props, 'ownerEmail') === email;

	isSelected = suggestion => {
		const email = getEmail(suggestion.email);
		return this.isItemAlreadySharedWithUser(email) || this.isEmailTokenSameAsOwner(email);
	};

	previouslySelectedLabel = suggestion => {
		const email = getEmail(suggestion.email);
		if (this.isItemAlreadySharedWithUser(email)) {
			return 'shareItemModal.addressFieldPreviouslySelected';
		} else if (this.isEmailTokenSameAsOwner(email)) {
			return 'shareItemModal.addressFieldOwner';
		}
	};

	validateToken = (address, token) => {
		const email = getEmail(token.address);
		return (
			isValidEmail(email) &&
			!this.isEmailTokenSameAsOwner(email) &&
			!this.isItemAlreadySharedWithUser(email)
		);
	};

	render({
		onEmailsToInviteChange,
		onInvitePermissionsChange,
		emailsToInvite,
		invitePermissions,
		emailAddressesPlaceholder,
		screen
	}) {
		return (
			<ShareInfoCard
				title={
					<span>
						<Text id={`shareItemModal.${screen}.emailSelectLabel`} />{' '}
						<PermissionsSelect
							value={invitePermissions}
							onChange={onInvitePermissionsChange}
							bold
							inline
							collapseLabel
							inlineArrow
							screen={screen}
						/>
					</span>
				}
			>
				<AddressField
					class={s.emailInput}
					placeholder={emailAddressesPlaceholder}
					value={emailsToInvite}
					onChange={onEmailsToInviteChange}
					wasPreviouslySelected={this.isSelected}
					previouslySelectedLabel={this.previouslySelectedLabel}
					validateToken={this.validateToken}
					formSize
				/>
			</ShareInfoCard>
		);
	}
}
