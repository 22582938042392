import { Component } from 'preact';
import { withText, Text, Localizer } from 'preact-i18n';
import cx from 'classnames';
import { withProps } from 'recompose';
import get from 'lodash-es/get';
import { KeyCodes, Icon, Button, CloseOnClickOrKeydown } from '@zimbra/blocks';
import { getEmail } from '../../utils/contacts';
import ZimletSlot from '../zimlet-slot';
import ContactSuggestion from '../contact-suggestion';
import ContactTag from './contact-tag';
import withAutoComplete from '../../graphql-decorators/autocomplete';
import ActionMenu, { DropDownWrapper } from '../action-menu';
import ActionMenuGroup from '../action-menu-group';
import ActionMenuItem from '../action-menu-item';
import { callWith } from '@zimbra/util/src/call-with';
import s from './style.less';
const byRank = (a, b) => b.ranking - a.ranking;
const hasEmail = c => c.email;
const isNotContactGroup = c => !c.isGroup;

@withText({
	placeholderWithContact: 'search.placeholderWithContact',
	emailPlaceholderType: 'search.placeholderTypes.email',
	calendarPlaceholderType: 'search.placeholderTypes.calendar',
	contactsPlaceholderType: 'search.placeholderTypes.contacts',
	briefcasePlaceholderType: 'search.placeholderTypes.briefcase'
})
@withAutoComplete()
@withProps(({ contactSuggestions }) => ({
	contactSuggestions: contactSuggestions && contactSuggestions.filter(isNotContactGroup)
}))
export default class SearchInput extends Component {
	state = {
		focused: false,
		hideSuggestions: false,
		selectedContact: null,
		keyboardSelectionIndex: null,
		contactFocused: false
	};

	submit = (query = null, contact = null, email) => {
		this.props.onSubmit(query, contact ? getEmail(contact) : email);
		this.setState({ selectedContact: contact });
		if (contact) {
			this.props.onInput('');
		}
	};

	keyboardSelectedContact = () => {
		const { contactSuggestions } = this.props;
		const { keyboardSelectionIndex } = this.state;

		if (contactSuggestions && keyboardSelectionIndex !== null) {
			return contactSuggestions[keyboardSelectionIndex];
		}

		return null;
	};

	handleKeyDown = e => {
		switch (e.keyCode) {
			case KeyCodes.DOWN_ARROW:
				return this.handleInputDown(e);
			case KeyCodes.UP_ARROW:
				return this.handleInputUp(e);
			case KeyCodes.ESCAPE:
				return this.handleInputEsc(e);
			default:
				return;
		}
	};

	handleKeyUp = e => {
		const { selectionStart, selectionEnd } = e.target;
		const contactFocused = selectionStart === 0 && selectionEnd === 0;

		this.setState({ contactFocused });

		switch (e.keyCode) {
			case KeyCodes.BACKSPACE:
				return this.handleKeyUpBackspace();
		}
	};

	handleInput = ({ target: { value } }) => {
		if (value !== this.props.value) {
			this.props.onInput(value);
		}
	};

	handleInputDown = e => {
		e.preventDefault();
		if (this.props.contactSuggestions) {
			const i = this.state.keyboardSelectionIndex;
			this.setState({
				keyboardSelectionIndex: Math.min(
					i !== null ? i + 1 : 0,
					this.props.contactSuggestions.length - 1
				)
			});
		}
	};

	handleInputUp = e => {
		e.preventDefault();
		if (this.props.contactSuggestions) {
			const i = this.state.keyboardSelectionIndex;
			this.setState({
				keyboardSelectionIndex: Math.max(i !== null ? i - 1 : 0, 0)
			});
		}
	};

	handleKeyUpBackspace = () => {
		if (this.props.email && this.state.contactFocused) {
			this.submit('', null);
		}
	};

	handleInputEsc = () => {
		this.setState({
			focused: !this.state.focused,
			keyboardSelectionIndex: null
		});
	};

	handleSubmit = e => {
		e.preventDefault();
		const contact = this.keyboardSelectedContact();
		const contactEmail = contact ? getEmail(contact) : null;
		const prevEmail = this.props.email;
		const value = contactEmail && contactEmail !== get(prevEmail, 'id') ? '' : this.props.value;

		this.submit(value, contact, prevEmail);
	};

	handleFocus = e => {
		this.setFocus(true);

		const { onFocus } = this.props;
		onFocus && onFocus(e);
	};

	handleBlur = e => {
		this.setFocus(false);

		const { onBlur } = this.props;
		onBlur && onBlur(e);
	};

	handleContactClick = contact => {
		this.submit('', contact);
	};

	handleRemoveContact = () => {
		this.submit(this.props.value, null);
		this.input.focus();
	};

	handleClickClearButton = () => {
		this.props.onClear();
		this.submit();
		this.input.focus();
	};

	setFocus = val => {
		let stateToUpdate = {
			focused: val
		};

		if (this.props.contactSuggestions) {
			stateToUpdate = {
				...stateToUpdate,
				keyboardSelectionIndex: val ? this.state.keyboardSelectionIndex : null
			};
		}

		this.setState(stateToUpdate);
	};

	static defaultProps = {
		onFocus: () => {},
		onBlur: () => {}
	};

	componentDidMount() {
		if (this.props.autofocus) {
			this.input.focus();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.email && !nextProps.email) {
			this.setState({ selectedContact: null });
		}
		if (this.props.autofocus !== true && nextProps.autofocus === true) {
			this.input.focus();
		}
	}

	renderSearchButton = () => (
		<Button
			class={cx(s.searchActions, s.searchButton, this.state.focused && s.activeButton)}
			onClick={this.handleSubmit}
			icon={<Icon name="search" />}
			iconOnly
		/>
	);

	render(
		{
			isMobile,
			disableContactSuggestions,
			email,
			contact,
			pathType,
			contactSuggestions,
			onAdvancedSearch,
			showContactDropdown,
			contactDropdownLabel,
			onSearchFolderChanged,
			onBlur,
			value = '',
			onHideAdvanced,
			showAdvanced
		},
		{ focused, contactFocused, selectedContact, keyboardSelectionIndex }
	) {
		const fieldType =
			!pathType || pathType === 'message' || pathType === 'conversation'
				? this.props.emailPlaceholderType
				: this.props[`${pathType}PlaceholderType`];

		return (
			<div class={cx(s.container, showAdvanced && s.showAdvanced)}>
				<form class={cx(s.form, this.state.focused && s.focus)} onSubmit={this.handleSubmit}>
					{showContactDropdown && (
						<ActionMenu
							monotone
							into={false}
							actionButtonClass={s.contactDropdown}
							label={contactDropdownLabel}
						>
							<DropDownWrapper>
								<ActionMenuGroup>
									<ActionMenuItem onClick={callWith(onSearchFolderChanged, 'myContacts')}>
										<Text id="search.scope.myContacts" />
									</ActionMenuItem>
									<ActionMenuItem onClick={callWith(onSearchFolderChanged, 'galContacts')}>
										<Text id="search.scope.galContacts" />
									</ActionMenuItem>
								</ActionMenuGroup>
							</DropDownWrapper>
						</ActionMenu>
					)}
					{this.renderSearchButton()}
					{email && (
						<ContactTag
							contact={contact || selectedContact}
							email={email}
							focused={contactFocused}
							onRemove={this.handleRemoveContact}
						/>
					)}
					<ZimletSlot name="searchInputPlaceholder" pathType={pathType}>
						{zimletResponses => {
							const zimletText = zimletResponses && zimletResponses.filter(Boolean)[0];
							return (
								<Localizer>
									<input
										type="text"
										ref={ref => (this.input = ref)}
										placeholder={
											<Text id="search.placeholder" fields={{ type: zimletText || fieldType }} />
										}
										class={s.input}
										value={value}
										onKeyDown={this.handleKeyDown}
										onKeyUp={this.handleKeyUp}
										onInput={this.handleInput}
										onFocus={this.handleFocus}
										onBlur={this.handleBlur}
										autocomplete="off"
									/>
								</Localizer>
							);
						}}
					</ZimletSlot>
					{!isMobile && value && (
						<Localizer>
							<Button
								aria-label={<Text id="buttons.clear" />}
								onClick={this.handleClickClearButton}
								type="button"
								class={cx(s.searchActions, s.clearBtn)}
								icon={<Icon name="close" />}
								iconOnly
							/>
						</Localizer>
					)}

					{!isMobile && onAdvancedSearch && (
						<Localizer>
							<Button
								aria-label={<Text id="search.scope.advanced_search" />}
								onClick={showAdvanced ? onHideAdvanced : onAdvancedSearch}
								type="button"
								class={cx(s.searchActions, s.toggleAdvance)}
								icon={<Icon size="xs" name={showAdvanced ? 'angle-up' : 'angle-down'} />}
								iconOnly
							/>
						</Localizer>
					)}
				</form>

				{!disableContactSuggestions &&
					focused &&
					contactSuggestions &&
					contactSuggestions.length > 0 && (
						<CloseOnClickOrKeydown onClickOutside={onBlur}>
							<div class={s.suggestions}>
								{contactSuggestions
									.filter(hasEmail)
									.sort(byRank)
									.map((c, i) => (
										<ContactSuggestion
											class={cx(
												s.contactSuggestion,
												keyboardSelectionIndex === i && s.contactSuggestionSelected
											)}
											nameClass={s.contactSuggestionName}
											contact={c}
											input={value || ''}
											onClick={this.handleContactClick}
										/>
									))}
							</div>
						</CloseOnClickOrKeydown>
					)}
			</div>
		);
	}
}
