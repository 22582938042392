import { Component, createElement } from 'preact';
import cx from 'classnames';
import style from './style';
import { ListSuggestion } from '../contact-suggestion/list-suggestion';
import { KeyCodes } from '@zimbra/blocks';
import linkState from 'linkstate';
import { writeText } from 'clipboard-polyfill/text';
import { callWith, extractCompositeAddress } from '../../lib/util';

const {
	CARRIAGE_RETURN,
	TAB,
	ESCAPE,
	KEY_SELECT,
	KEY_F10,
	BACKSPACE,
	DELETE,
	LEFT_ARROW,
	RIGHT_ARROW,
	UP_ARROW,
	DOWN_ARROW,
	KEY_SEMICOLON,
	KEY_SEMICOLON_FIREFOX,
	KEY_COMMA,
	SHIFT,
	CTRL,
	META_KEY,
	KEY_A,
	KEY_C,
	KEY_X,
	KEY_V
} = KeyCodes;

export default class TokenInputSuggestion extends Component {
	state = {
		input: '',
		verticalSelectedIndex: null,
		focussed: false,
		isGroup: false,
		selectedGroupId: null,
		showInsertionPoint: false,
		showAutoSuggestion: false
	};

	inputRef = c => (this.input = c);

	autoSuggestRef = c => (this.autoSuggest = c);

	setVerticalSelection = index => {
		if (index && typeof index === 'object' && index.value) {
			this.setAutoSuggestValue(index.value);
			index = index.index;
		}
		if (index === -1) index = null;
		this.setState({ verticalSelectedIndex: index });
	};

	setAutoSuggestValue = value => (this.autoSuggestValue = value);

	add = () => {
		const { input, verticalSelectedIndex } = this.state;
		if (verticalSelectedIndex != null) {
			return this.setState({ commitSelectedIndex: verticalSelectedIndex });
		}

		this.addValue(input);
	};

	addValue = value => {
		if (!value) return;
		const { onAdd, isLocation } = this.props;
		const { input } = this.state;
		let tokenList;

		if (Array.isArray(value)) {
			// if user string contains multiple comositeAddress (i.e "Name" <email> format) then
			// below reduce method convert them to list of compositeAddress
			// i.e if Value = ["alice park alice@testdomain.com bob bob1@testdomain.com"] then
			// updatedUserString = ["alice park alice@testdomain.com", "bob bob1@testdomain.com"]
			const updatedUserString = value.reduce((acc, val) => {
				if (typeof val === 'object') {
					return [...acc, val];
				}
				return [...acc, ...extractCompositeAddress(val)];
			}, []);

			tokenList = updatedUserString.map(contact => this.createValue(contact)).filter(Boolean);
		} else {
			// For contact of type group, render ghost component and fetch contact data of contact group.
			if (value.isGroup && value.type !== 'gal') {
				this.setState({
					isGroup: true,
					selectedGroupId: value.id,
					commitSelectedIndex: null
				});
				return;
			}

			tokenList = this.createValue(value) || [];
		}

		let inputVal = '';
		if (isLocation && input.includes(';')) {
			const arr = input.split(';');
			arr.pop();
			inputVal = arr.join(';').concat(';');
		}

		this.setState(
			{
				input: inputVal,
				commitSelectedIndex: null,
				verticalSelectedIndex: null,
				isGroup: false,
				selectedGroupId: null
			},
			() => {
				onAdd && onAdd({ tokens: tokenList });
			}
		);
	};

	createValue(value) {
		if (this.props.createValue) return this.props.createValue(value);
		return { value };
	}

	handleKey = e => {
		const {
			maxTokens,
			isLocation,
			tokens,
			selected,
			activateSelected,
			onRemove,
			onSelect,
			onActivate,
			onCtrlEnterKeyDown,
			renderAutoSuggest
		} = this.props;

		let { verticalSelectedIndex, input, focussed, showAutoSuggestion } = this.state;
		const selectionOffset =
			this.input && this.input.selectionStart === this.input.selectionEnd
				? this.input.selectionStart
				: -1;
		const valueFromString = this.createValue(input);

		if (!this.state.showAutoSuggestion) {
			this.setState({ showAutoSuggestion: true });
		}

		switch (e.keyCode) {
			case SHIFT:
			case CTRL:
			case META_KEY:
				break;
			case KEY_A:
				if (e.ctrlKey || e.metaKey) {
					if (input !== '') {
						return;
					}
					this.selectAll();
					break;
				}
				return;
			case KEY_C:
				if (e.ctrlKey || e.metaKey) {
					this.copySelection();
					break;
				}
				return;
			case KEY_X:
				if (e.ctrlKey || e.metaKey) {
					this.copySelection();
					onRemove(selected);
					this.setInputFocus();
					break;
				}
				return;
			case KEY_V:
				if (e.ctrlKey || e.metaKey) {
					return;
				}
				return;
			case CARRIAGE_RETURN:
				if (e.ctrlKey) {
					onCtrlEnterKeyDown && onCtrlEnterKeyDown();
					return;
				}
				if (!input.length) return;

				if (
					(verticalSelectedIndex !== null && verticalSelectedIndex >= 0) ||
					Object.prototype.hasOwnProperty.call(valueFromString, 'zimbraCalResType')
				) {
					this.add();
					break;
				} else if (isLocation) {
					break;
				}

				this.addPillsFromInput();
				break;
			case TAB:
			case KEY_COMMA:
			case KEY_SEMICOLON:
			case KEY_SEMICOLON_FIREFOX:
				if (!input.length) return;

				if (
					e.altKey ||
					(e.nativeEvent.key !== ';' && e.nativeEvent.key !== ',' && e.keyCode !== TAB)
				)
					return; //ZBUG-1573

				if (isLocation) {
					Object.prototype.hasOwnProperty.call(valueFromString, 'zimbraCalResType') && this.add();
					return;
				}

				if (
					(verticalSelectedIndex !== null && verticalSelectedIndex >= 0) ||
					Object.prototype.hasOwnProperty.call(valueFromString, 'zimbraCalResType')
				) {
					this.add();
					break;
				}

				this.addPillsFromInput();
				break;
			case ESCAPE:
				if (selected != null) onSelect([]);
				else this.setState({ input: '' });
				// de-activate selection if it was activated:
				if (selected != null && activateSelected) onActivate && onActivate(false);
				if (
					focussed &&
					showAutoSuggestion &&
					verticalSelectedIndex !== null &&
					renderAutoSuggest &&
					input &&
					input.trim().length >= 3
				) {
					e.preventDefault();
					e.stopPropagation();
				}
				break;
			case KEY_SELECT:
			case KEY_F10:
				if (selected != null) onActivate && onActivate(true);
				else return;
				break;
			case BACKSPACE:
				if ((selected && !selected.length) || selected === null) {
					// special case: backspace at leftmost cursor position selects the last tag. hitting it again deletes it.
					if (selectionOffset === 0 && tokens.length) this.moveSelection(-1);
					return;
				}

				onRemove(selected);
				this.setInputFocus();
				this.moveSelection(-1);
				break;
			case DELETE:
				if ((selected && !selected.length) || selected === null) return;
				onRemove(selected);
				this.setInputFocus();
				break;
			case LEFT_ARROW:
				if (e.shiftKey) {
					//Select multiple items as the cursor moves to the left.
					this.moveSelection(-1, true);
					break;
				}
				if (selectionOffset) return;
				this.moveSelection(-1);
				break;
			case RIGHT_ARROW:
				if (e.shiftKey) {
					//Select multiple items as the cursor moves to the right.
					this.moveSelection(1, true);
					break;
				}
				if (selectionOffset || selected == null) return;
				this.moveSelection(1);
				break;
			case UP_ARROW:
				if (verticalSelectedIndex == null) return;
				if (!verticalSelectedIndex--) verticalSelectedIndex = null;
				this.setState({ verticalSelectedIndex });
				break;
			case DOWN_ARROW:
				if (verticalSelectedIndex == null) verticalSelectedIndex = -1;
				verticalSelectedIndex++;
				this.setState({ verticalSelectedIndex });
				break;
			default:
				selected && onSelect([]);
				if (activateSelected) onActivate && onActivate(false);

				if (e.keyCode !== TAB && maxTokens && maxTokens <= tokens.length) {
					// Stop accepting new input when the token input has reached maxTokens
					e.preventDefault();
				}

				return;
		}
		e.preventDefault();
		return false;
	};

	//This is used to maintain focus on input after selecting multiple pills and cutting/deleting
	setInputFocus() {
		setTimeout(() => {
			this.input.focus();
		}, 0);
	}

	//Function to create pills from text in the input tag.
	addPillsFromInput() {
		let { input } = this.state;
		const lastInputChar = input.charAt(input.length - 1);
		let pillsToAdd = [];

		//If last character is a semicolon or comma remove last char
		if (lastInputChar === ',' || lastInputChar === ';') {
			input = input.substring(0, input.length - 1);
		}

		if (input.includes(',') || input.includes(';')) {
			//Code to add multiple pills
			const allPills = input.includes(',') ? input.split(',') : [input];

			allPills.forEach(pill => {
				if (pill.includes(';')) {
					pillsToAdd.push(...pill.split(';'));
				} else {
					pillsToAdd.push(pill);
				}
			});

			pillsToAdd = pillsToAdd.filter(address => address !== '');
		} else if (input !== '') pillsToAdd.push(input);

		// Don't trigger a rerender if we don't have anything to add
		if (pillsToAdd.length > 0) {
			this.addValue(pillsToAdd);
		}
	}

	//Used to copy the tokens selected
	copySelection() {
		const { selected, tokens } = this.props;
		const selectedTokens = tokens.filter(t => selected.includes(t.address));
		const addressList = selectedTokens
			.reduce((acc, value) => `${acc}"${value.name}" <${value.address}>; `, '')
			.trim();

		writeText(addressList);
	}

	//Used to select all tokens in to field
	selectAll() {
		const { tokens, onStateChange } = this.props;
		const selectedItems = tokens.map(t => t.address);
		onStateChange({ selected: selectedItems, activateSelected: true });
	}

	moveSelection(offset, multiselect) {
		const { tokens, selected, onStateChange } = this.props;
		const movedLeft = offset < 0;
		let selectedItems = selected ? [...selected] : [];

		if (!selectedItems.length) {
			//Condition to select the first item.
			if (offset === -1 && tokens.length) selectedItems = [tokens[tokens.length - 1].address];

			if (multiselect) {
				this.initiallyMovedLeft = movedLeft;
			} else {
				this.selectedSingle = true;
			}
		} else if (multiselect) {
			//Condition to handle multiselect

			if (this.selectedSingle) {
				this.initiallyMovedLeft = movedLeft;
				this.selectedSingle = false;
			}

			//Find index of item to select.
			const prevSelectedItem = selected[selected.length - 1];
			const prevSelectedIndex = tokens.findIndex(item => item.address === prevSelectedItem);
			const selectedIndex = prevSelectedIndex + offset;

			//Return when boundary hit
			if (selectedIndex < 0 || selectedIndex > tokens.length - 1) return;

			//Push item to select onto array of selected items.
			if (this.initiallyMovedLeft === movedLeft) {
				selectedItems.push(tokens[selectedIndex].address);
			} else {
				selectedItems.pop();
			}

			//Code to handle the crossover conditon.
			if (!selectedItems.length) {
				selectedItems.push(tokens[selectedIndex].address);
				this.initiallyMovedLeft = movedLeft;
			}
		} else {
			//Condition to handle single select
			this.selectedSingle = true;

			const selectedIndex = Math.max(
				0,
				tokens.findIndex(t => t.address === selectedItems[selectedItems.length - 1]) + offset
			);

			selectedIndex === tokens.length
				? (selectedItems = [])
				: (selectedItems = [tokens[selectedIndex].address]);
		}

		this.setState(
			{
				verticalSelectedIndex: null
			},
			() => {
				onStateChange({ selected: selectedItems, activateSelected: true });
			}
		);
	}

	handleFocus = () => {
		const { selected, onStateChange, onFocus } = this.props;
		if (!this.state.focussed) {
			this.setState({ focussed: true, showAutoSuggestion: true });
			onFocus && onFocus(true);
		}
		if (!this.pause && selected != null) {
			onStateChange({ selected, activateSelected: false });
		}
	};

	handleBlur = () => {
		const { focussed, input } = this.state;
		const { isLocation, onAdd, onFocus } = this.props;
		const tmpToken = this.createValue(input);

		if (!isLocation || (isLocation && focussed && tmpToken && tmpToken.zimbraCalResType)) {
			this.setState({ verticalSelectedIndex: null });
			this.addPillsFromInput();
			this.reset();
		} else if (isLocation && input.length > 0) {
			onAdd && onAdd({ tokens: [input] });
		}
		this.setState({ focussed: false, showAutoSuggestion: false });
		onFocus && onFocus(false);
	};

	reset = () => {
		const { isMouseDown, onStateChange, isLocation } = this.props;
		if (isLocation) return;

		this.setState(
			{
				input: '',
				commitSelectedIndex: null,
				focussed: false,
				verticalSelectedIndex: null,
				showAutoSuggestion: false
			},
			() => {
				!isMouseDown && onStateChange({ selected: [], activateSelected: false });
			}
		);
	};

	removeFocus = () => {
		this.input.blur();
	};

	refocus = e => {
		if (!this.state.focussed) {
			this.pause = true;
			this.input.focus();
			this.pause = false;
			if (e) {
				e.preventDefault();
				e.stopPropagation();
				return false;
			}
		}
	};

	handleInputClick = () => {
		this.handleFocus();
	};

	setInsertionPoint = (visibility, e) => {
		e.preventDefault();
		visibility !== this.state.showInsertionPoint &&
			this.setState({ showInsertionPoint: visibility });
	};

	update = props => {
		let { tokens } = props;
		if (typeof tokens[tokens.length - 1] === 'string') {
			let input = '';
			tokens = tokens.slice();
			input = tokens.pop();
			this.setState({ input });
		} else {
			// Else, ensure the input is empty.
			this.setState({ input: '' });
		}
	};

	hideAutoSuggestions = () => this.setState({ showAutoSuggestion: false });

	componentWillMount() {
		this.update(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.tokens !== this.props.tokens) {
			this.update(nextProps);
		}
	}

	render(
		{
			renderAutoSuggest,
			placeholder = '',
			wasPreviouslySelected,
			previouslySelectedLabel,
			tokenInputStyle,
			inputClassName,
			isLocation,
			isGalOnly,
			type,
			tokens,
			onRemove,
			onDragEnter,
			disabled
		},
		{
			input,
			verticalSelectedIndex,
			commitSelectedIndex,
			focussed,
			showAutoSuggestion,
			isGroup,
			selectedGroupId,
			showInsertionPoint
		}
	) {
		const queryValue = (isLocation && input.includes(';') ? input.split(';').pop() : input).trim();

		// `showAutoSuggestion` will ensure to close autoSuggest and keep focus on input
		const shouldRenderAutoSuggest =
			focussed && showAutoSuggestion && renderAutoSuggest && queryValue?.length >= 3;

		return (
			<div
				class={style.inputWrap}
				onDragOver={callWith(this.setInsertionPoint, true, true)}
				onDragLeave={callWith(this.setInsertionPoint, false, true)}
				onDrop={callWith(this.setInsertionPoint, false, true)}
				onMouseDown={this.reset}
			>
				{showInsertionPoint && <span class={style.insertionPoint} />}
				<input
					disabled={disabled}
					class={cx(style.input, inputClassName, tokenInputStyle)}
					ref={this.inputRef}
					value={input}
					onInput={linkState(this, 'input', 'target.value')}
					onClick={this.handleInputClick}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					onMouseDown={this.removeFocus}
					onKeyDown={this.handleKey}
					onDragEnter={onDragEnter}
					placeholder={tokens.length === 0 ? placeholder : ''}
				/>

				{shouldRenderAutoSuggest &&
					createElement(renderAutoSuggest, {
						tokens,
						wasPreviouslySelected,
						previouslySelectedLabel,
						commitSelectedIndex,
						value: queryValue,
						selectedIndex: verticalSelectedIndex,
						onSelectionChange: this.setVerticalSelection,
						onSelect: this.addValue,
						onRemove,
						isLocation,
						isGalOnly,
						type,
						onClose: this.hideAutoSuggestions
					})}
				{isGroup && <ListSuggestion id={selectedGroupId} onAddContacts={this.addValue} />}
			</div>
		);
	}
}
