import { handleActions } from 'redux-actions';
import { DEFAULT_SORT } from '../../constants/search';
import * as actionCreators from './actions';

const initialState = {
	selectedMsgs: [],
	sortBy: DEFAULT_SORT
};

export default handleActions(
	{
		[actionCreators.updateSelection]: (state, action) => ({
			...state,
			selectedMsgs: action.payload
		}),
		[actionCreators.clearSelection]: state => ({
			...state,
			selectedMsgs: []
		}),
		[actionCreators.setMsgListSortBy]: (state, action) => ({
			...state,
			sortBy: action.payload
		})
	},
	initialState
);
