import { Component } from 'preact';
import { route } from 'preact-router';
import { connect } from 'react-redux';
import { Text } from 'preact-i18n';
import cx from 'classnames';
import queryString from 'query-string';
import moment from 'moment';
import get from 'lodash-es/get';
import withPreference from '../../graphql-decorators/preferences/get-preferences';
import search from '../../graphql-decorators/search';
import { uriSegment } from '../../lib/util';
import { getQuery } from '../../utils/search';
import { getSearchFolder, getSearchQuery, getSearchEmail } from '../../store/url/selectors';
import { setShowAdvanced } from '../../store/navigation/actions';
import { setActiveSearch, resetAdvanceOptions } from '../../store/search/actions';
import SearchAdvanced from '../search-advanced';
import SearchInput from '../search-input';
import { DATE_FORMAT } from '../../constants/search';
import s from './style.less';
import clientConfiguration from '../../enhancers/client-config';
import ZimletSlot from '../zimlet-slot';

const VIEW_TO_SEARCH = {
	conversation: 'email',
	message: 'email'
};

const getValidView = ({ slugs, pathname, view }) => {
	const baseSegment = uriSegment(pathname);
	if (baseSegment === 'search') {
		// To handle /search/email or /search/briefcase
		view = uriSegment(pathname, 1) || view;
	} else {
		// To handle /email or /briefcase
		view = baseSegment || view;
	}

	if (
		view === slugs.email ||
		view === slugs.contacts ||
		view === slugs.calendar ||
		view === slugs.briefcase
	) {
		return { currentView: view };
	}
	return { currentView: slugs.email };
};

@clientConfiguration({ slugs: 'routes.slugs' })
@connect(
	(state, { slugs }) => ({
		query: getSearchQuery(state),
		email: getSearchEmail(state),
		...getValidView({
			slugs,
			pathname: get(state, 'url.location.pathname'),
			view: get(state, 'url.view')
		}),
		overrideGroupMailBy: state.url.routeProps.types,
		overrideAfter: state.url.routeProps.after,
		overrideBefore: state.url.routeProps.before,
		smartFolderQuery:
			get(state, 'search.activeFolder.query') && getQuery(get(state, 'search.activeFolder.query')),
		searchIn: getSearchFolder(state),
		showAdvanced: get(state, 'navigation.showAdvanced'),
		pathname: get(state, 'url.location.pathname'),
		searchPath: get(state, 'url.location.search')
	}),
	{
		setShowAdvanced,
		setActiveSearch,
		resetAdvanceOptions
	}
)
@withPreference(({ data: { getPreferences } }) => ({
	groupMailBy: get(getPreferences, 'zimbraPrefGroupMailBy')
}))
@search({
	skip: ({ email }) => !email,
	options: ({ email }) => ({
		variables: {
			limit: 1,
			needExp: true,
			query: `contact:${email}`,
			types: 'contact'
		}
	}),
	props: ({ data }) => ({
		contacts: (!data.loading && data.search && data.search.contacts) || []
	})
})
export default class Search extends Component {
	state = {
		focused: false,
		folderToSearch: this.props.searchIn,
		query: this.props.query,
		reset: false
	};

	handleSubmit = (query, email) => {
		const {
			currentView,
			overrideGroupMailBy,
			groupMailBy,
			overrideAfter,
			overrideBefore,
			parentView
		} = this.props;
		const { folderToSearch } = this.state;

		const parentUrl = parentView ? `/${parentView}` : '';

		if (query !== null) {
			route(
				`/search${parentUrl}/${VIEW_TO_SEARCH[currentView] || currentView}?${queryString.stringify({
					q: query || undefined,
					e: email || undefined,
					types:
						currentView === 'calendar'
							? 'appointment,task'
							: overrideGroupMailBy || groupMailBy || 'conversation',
					...(currentView !== 'email' && folderToSearch && { folder: folderToSearch }),
					...(currentView === 'calendar' && {
						before: overrideBefore || moment().add(14, 'day').format(DATE_FORMAT),
						after: overrideAfter || moment().format(DATE_FORMAT)
					})
				})}`
			);
		} else {
			this.handleSearchValueChange(query);
		}
	};

	handleSearchValueChange = query => {
		if (query !== this.state.query) {
			this.setState({ query: query || '' });
		}
	};

	handleSearchClear = () => {
		this.props.resetAdvanceOptions();
		this.setState({ query: '', reset: true });
	};

	handleFocus = () => {
		this.setState({ focused: true });
	};

	handleBlur = () => {
		this.setState({ focused: false });
	};

	handleFolderChange = name => {
		const { query, email } = this.props;
		this.setState({ folderToSearch: name }, () => {
			if (query) this.handleSubmit(query, email);
		});
	};

	handleShowAdvanced = () => {
		this.props.setShowAdvanced({ show: true });
	};

	handleHideAdvanced = () => {
		const { smartFolderQuery, query } = this.props;

		this.props.setShowAdvanced({ show: false });

		if (smartFolderQuery) {
			this.props.setActiveSearch(null);
			this.setState({ query });
		}
	};

	getFolderName = name => {
		if (name.search(/\//g) !== -1) {
			const match = name.match(/(?:[^\\/](?!(\/)))+$/g);
			return match[0];
		}
		return <Text id={`folders.${name.replace(' ', '_')}`}>{name}</Text>;
	};

	componentWillReceiveProps({ currentView, searchPath, query, smartFolderQuery }) {
		if (this.props.currentView !== null && this.props.currentView !== currentView) {
			this.setState({
				folderToSearch: undefined,
				query: undefined
			});
		}

		if (this.state.reset && this.props.searchPath !== searchPath) {
			this.setState({ reset: false });
		}

		if (this.props.query !== query) {
			this.setState({ query });
		}

		if (smartFolderQuery && this.props.smartFolderQuery !== smartFolderQuery) {
			this.setState({ query: smartFolderQuery });
		}
	}

	render(
		{
			email,
			contacts,
			currentView,
			showDropDown,
			searchScreen,
			isMobile,
			autofocus,
			showAdvanced,
			zimbraFeatureTaggingEnabled,
			zimbraFeatureSharingEnabled,
			defaultFolderToSearch
		},
		{ focused, folderToSearch, query, reset }
	) {
		return (
			<div className={cx(s.searchContainer, s.headerSearch, searchScreen && s.searchScreen)}>
				<div className={s.search}>
					<div className={cx(s.searchControl, focused && s.focus)}>
						<ZimletSlot
							name="web-search-toggle"
							viewLabel={<Text id={`search.placeholderTypes.${currentView || 'email'}`} />}
							showAdvanced={showAdvanced}
							isMobile={isMobile}
						/>
						<ZimletSlot name="web-search" isMobile={isMobile}>
							{slotContent =>
								slotContent.filter(Boolean).length ? (
									slotContent
								) : (
									<SearchInput
										autofocus={autofocus}
										pathType={currentView}
										value={query}
										email={email}
										contact={get(contacts, '0')}
										onSubmit={this.handleSubmit}
										onFocus={this.handleFocus}
										onBlur={this.handleBlur}
										onHideAdvanced={this.handleHideAdvanced}
										isMobile={isMobile}
										onInput={this.handleSearchValueChange}
										onClear={this.handleSearchClear}
										disableContactSuggestions={
											!focused || (VIEW_TO_SEARCH[currentView] || currentView) !== 'email'
										}
										showAdvanced={currentView === 'email' && showAdvanced}
										onAdvancedSearch={currentView === 'email' && this.handleShowAdvanced}
										showContactDropdown={currentView === 'contacts' && showDropDown}
										contactDropdownLabel={
											(folderToSearch && <Text id={`search.scope.${folderToSearch}`} />) ||
											defaultFolderToSearch
										}
										onSearchFolderChanged={this.handleFolderChange}
									/>
								)
							}
						</ZimletSlot>

						{!isMobile && showAdvanced && (
							<SearchAdvanced
								onHideAdvanced={this.handleHideAdvanced}
								searchValue={query}
								isReset={reset}
								zimbraFeatureTaggingEnabled={zimbraFeatureTaggingEnabled}
								zimbraFeatureSharingEnabled={zimbraFeatureSharingEnabled}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}
