import { Fragment } from 'preact';
import { useState, useCallback } from 'preact/hooks';
import ContactHoverCard from '../contact-hover-card';
import ContactEditorModal from '../contact-editor-modal';

export const ContactHoverCardWrapper = ({ address, ...props }) => {
	const [showEditModal, setShowEditModal] = useState(false);
	const toggleEditModal = useCallback(() => setShowEditModal(value => !value), []);

	return (
		<Fragment>
			<ContactHoverCard address={address} toggleEditModal={toggleEditModal} {...props} />
			{showEditModal && <ContactEditorModal address={address} onClose={toggleEditModal} />}
		</Fragment>
	);
};
