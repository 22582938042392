import { useEffect } from 'preact/hooks';
import { notify } from '../../store/notifications/actions';
import { registerSW } from '../../lib/workbox';
import { useDispatch } from 'react-redux';
import { Text } from 'preact-i18n';

export const ServiceWorkerUpdate = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		const serviceWorkerConfig = {
			displayToast: ({ onAccept }) => {
				if (navigator.serviceWorker) {
					navigator.serviceWorker.getRegistration().then(registration => {
						if (registration.waiting) {
							dispatch(
								notify({
									message: <Text id="app.notifications.updatesAvailable" />,
									duration: 86400,
									action: {
										label: <Text id="buttons.reload" />,
										fn: () => {
											onAccept();
										}
									}
								})
							);
						}
					});
				}
			}
		};

		registerSW(serviceWorkerConfig);
	}, [dispatch]);
	return null;
};
