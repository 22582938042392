export const FONT_SIZES = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];

export const FONT_FAMILY = [
	{ label: 'Roboto', value: 'roboto' },
	{ label: 'Andale Mono', value: 'andale mono, times' },
	{ label: 'Arial', value: 'arial, helvetica, sans-serif' },
	{ label: 'Arial Black', value: 'arial black, avant garde' },
	{ label: 'Book Antiqua', value: 'book antiqua, palatino' },
	{ label: 'Comic Sans MS', value: 'comic sans ms, sans-serif' },
	{ label: 'Courier New', value: 'courier new, courier' },
	{ label: 'Georgia', value: 'georgia, palatino' },
	{ label: 'Helvetica', value: 'helvetica' },
	{ label: 'Impact', value: 'impact, chicago' },
	{ label: 'Symbol', value: 'symbol' },
	{ label: 'Tahoma', value: 'tahoma, arial, helvetica, sans-serif' },
	{ label: 'Terminal', value: 'terminal, monaco' },
	{ label: 'Times New Roman', value: 'times new roman, times' },
	{ label: 'Trebuchet MS', value: 'trebuchet ms, geneva' },
	{ label: 'Verdana', value: 'verdana, geneva' }
];

export const DEFAULT_FONT_COLOR = '#000';
export const DEFAULT_FONT_SIZE = '14px';
export const DEFAULT_FONT_FAMILY = 'roboto';
export const DEFAULT_COMPOSE_DIRECTION = 'LTR';
