import get from 'lodash-es/get';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import compact from 'lodash-es/compact';
import {
	normalizeIdentity,
	normalizeDataSource
} from '../graphql-decorators/account-info/normalized-identities';

function identityFromAddress(i) {
	return (i && i.zimbraPrefFromAddress) || get(i, '_attrs.zimbraPrefFromAddress');
}

function getIdentities(identities) {
	// legacy zimbra-client normalized data used `account.identities`
	return get(identities, 'identity') || identities || [];
}

export function getAccountAddresses(identities) {
	return getIdentities(identities).map(identityFromAddress);
}

export function getPrimaryAccount(accounts) {
	return accounts && accounts.length && (find(accounts, { isPrimaryAccount: true }) || accounts[0]);
}

export function getPrimaryAccountAddress(account, identitiesInfo) {
	const identities = getIdentities(identitiesInfo);
	const identity = find(identities, i => i.id === account.id) || identities[0];
	return identityFromAddress(identity);
}

export function getPrimaryAccountName(account, identitiesInfo) {
	return get(account, 'attrs.displayName') || getPrimaryAccountAddress(account, identitiesInfo);
}

export function getAccountById(dataSources, identitiesInfo, id) {
	const identities = getIdentities(identitiesInfo);
	const identity = find(identities, i => i.id === id);
	if (identity) {
		return identity;
	}
	const imap = find(get(dataSources, 'imap'), i => i.id === id);
	return imap;
}

/**
 * Get the 'from' email address for a given identity or imap datasource on the account
 * @param {*} account account object for the active user
 * @param {*} id of the identity or imap datasource
 * @returns {String} email address for the id
 */
export function getAccountFromAddressForId(dataSources, identitiesInfo, id) {
	const account = getAccountById(dataSources, identitiesInfo, id);
	return account && (identityFromAddress(account) || account.emailAddress);
}

/**
 * Get the 'to' email address for a given identity or imap datasource on the account
 * @param {*} account account object for the active user
 * @param {*} id of the identity or imap datasource
 * @returns {String} email 'to' address for the id
 */
export function getAccountToAddressForId(account, dataSources, identitiesInfo, id) {
	const accountById = getAccountById(dataSources, identitiesInfo, id);
	let address;
	if (accountById?.__typename === 'Identity') {
		address = account.name;
	} else if (accountById?.__typename === 'DataSource') {
		address = accountById.emailAddress;
	}
	return address;
}

/**
 * Return a a function that returns true if the address key on the argument does not match any of the addresses associated with the account identities
 * @param {Object} account
 * @returns {Function}
 */
export function withoutAccountAddresses(identities) {
	return sender => !isAccount(identities, sender);
}

/**
 * Test if the senders address is an account address
 * @param {Object} account
 * @returns {Boolean} true if the address of the sender matches one of the addresses in the account identities, false otherwise
 */
export function isAccount(identities, sender) {
	const accountAddresses = getAccountAddresses(identities);
	return includes(accountAddresses, sender.address);
}

export function getDataSourceAccountById(dataSources, activeAccountId) {
	const imap = get(dataSources, 'imap') || [],
		pop3 = get(dataSources, 'pop3') || [];

	return activeAccountId && [...imap, ...pop3].find(({ id }) => id === activeAccountId);
}

/**
 * Return a compacted account list extracted from datasources
 * @param {Object} account
 * @param {Object} identitiesInfo
 * @param {Object} dataSourcesInfo
 * @param {Object} signaturesData
 * @returns {Array} of account including primary account, personas and external accounts.
 */
export function getAccountsFromDataSources(
	account,
	identitiesInfo,
	dataSourcesInfo,
	signaturesData
) {
	const zimbraFeatureImapDataSourceEnabled = get(
		account,
		'attrs.zimbraFeatureImapDataSourceEnabled'
	);
	const zimbraFeaturePop3DataSourceEnabled = get(
		account,
		'attrs.zimbraFeaturePop3DataSourceEnabled'
	);
	if (identitiesInfo || dataSourcesInfo || signaturesData) {
		return compact([
			...map(identitiesInfo.identity, i => normalizeIdentity(i, account, signaturesData)),
			...map((zimbraFeatureImapDataSourceEnabled && get(dataSourcesInfo, 'imap')) || [], i =>
				normalizeDataSource(i, 'imap', signaturesData)
			),
			...map((zimbraFeaturePop3DataSourceEnabled && get(dataSourcesInfo, 'pop3')) || [], i =>
				normalizeDataSource(i, 'pop3', signaturesData)
			)
		]);
	}
}
