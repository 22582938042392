import { Text, withText } from 'preact-i18n';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, ChoiceInput } from '@zimbra/blocks';
import DateInput from '../../date-input';
import Textarea from '../../textarea';
import AlignedForm from '../../aligned-form';
import AlignedLabel from '../../aligned-form/label';
import FormGroup from '../../form-group';
import style from '../style';
import cx from 'classnames';
import { notify } from '../../../store/notifications/actions';
import { PureComponent } from 'preact/compat';
import { withSendMessage } from '../../../graphql-decorators/send-message';
import Select from '../../select';
import { isLicenseActive } from '../../../utils/license';
import { withPropsOnChange, withProps } from 'recompose';
import { parse as parseDate, format as formatDate } from '../../../utils/date/zimbra-date';
import withPreference from '../../../graphql-decorators/preferences/get-preferences';
import CustomTimePicker from '../../custom-time-picker';

const SEND_STANDARD_EXTERNAL_REPLY = 'sendStandardExternalReply',
	SEND_EXTERNAL_REPLY = 'sendExternalReply',
	DONT_SEND_EXTERNAL_REPLY = 'dontSendExtenalReply';

@connect(null, { notify })
@withText({
	sampleSent: 'settings.vacationResponse.sampleSent',
	vacationResponseSample: 'settings.vacationResponse.vacationResponseSample'
})
@withSendMessage()
@withProps(({ accountData }) => ({
	email: accountData.name,
	name: accountData.attrs.displayName,
	isEnterprise: isLicenseActive(accountData.license)
}))
@withPreference(({ data: { getPreferences } }) => ({
	tz: getPreferences && getPreferences.zimbraPrefTimeZoneId
}))
@withPropsOnChange(['value'], ({ value }) => {
	let selectedExternalReplyValue;
	const sendStandardExternal =
			!value.enableOutOfOfficeExternalReply && !value.outOfOfficeSuppressExternalReply,
		sendExternal = value.enableOutOfOfficeExternalReply && !value.outOfOfficeSuppressExternalReply,
		dontSendExtenal = value.outOfOfficeSuppressExternalReply;

	if (sendStandardExternal) {
		selectedExternalReplyValue = SEND_STANDARD_EXTERNAL_REPLY;
	} else if (sendExternal) {
		selectedExternalReplyValue = SEND_EXTERNAL_REPLY;
	} else if (dontSendExtenal) {
		selectedExternalReplyValue = DONT_SEND_EXTERNAL_REPLY;
	}

	return {
		selectedExternalReplyValue,
		sendExternal
	};
})
export default class VacationResponseSettings extends PureComponent {
	defaultOooValue = this.props.value.enableOutOfOfficeReply;

	state = {
		outOfOfficeUntilDate: !!this.props.value.defaultUntilDate,
		isFromDateInvalid: false,
		isUntilDateInvalid: false
	};

	updateOooReplyEnabled = () => {
		const { onFieldChange, handleValidityOfTabs, activeId } = this.props;
		const toggledValue = !this.props.value.enableOutOfOfficeReply;
		const { outOfOfficeUntilDate, isFromDateInvalid, isUntilDateInvalid } = this.state;

		toggledValue
			? isFromDateInvalid || (outOfOfficeUntilDate && isUntilDateInvalid)
				? handleValidityOfTabs(activeId, true)
				: handleValidityOfTabs(activeId, false)
			: handleValidityOfTabs(activeId, false);
		onFieldChange(['enableOutOfOfficeReply', 'enableOutOfOfficeAlertOnLogin'])({
			target: { value: toggledValue }
		})
			/**
			 * Not changing the value of defaultFromDate & defaultUntilDate when user toggle every time,
			 * just changing when the enableOutOfOfficeReply was false for the very first time
			 * so storing it in defaultOutOFOfficeValue and making it true once i.e is done
			 */
			.then(() => {
				if (!this.defaultOooValue) {
					onFieldChange('defaultFromDate')({
						target: {
							value: formatDate(moment(new Date()).startOf('day'))
						}
					});
				}
			})
			.then(() => {
				if (!this.defaultOooValue) {
					onFieldChange('defaultUntilDate')({
						target: {
							value: ''
						}
					});
					this.setState({ outOfOfficeUntilDate: false });
					this.defaultOooValue = true;
				}
			});
	};
	updateDefaultUntilDate = () => {
		const { outOfOfficeUntilDate, isUntilDateInvalid, isFromDateInvalid } = this.state;
		const { onFieldChange, activeId, handleValidityOfTabs } = this.props;

		if (outOfOfficeUntilDate) {
			onFieldChange('defaultUntilDate')({
				target: { value: '' }
			});

			isUntilDateInvalid && !isFromDateInvalid && handleValidityOfTabs(activeId, false);
			this.setState({ outOfOfficeUntilDate: false });
		} else {
			isUntilDateInvalid && handleValidityOfTabs(activeId, true);
			this.setState({ outOfOfficeUntilDate: true });
		}
	};
	updateOooExternalSender = ({ target: { value: selectedValue } }) => {
		const { value, onFieldChange } = this.props;

		if (selectedValue === SEND_STANDARD_EXTERNAL_REPLY) {
			if (value.enableOutOfOfficeExternalReply && value.outOfOfficeSuppressExternalReply) {
				onFieldChange(['enableOutOfOfficeExternalReply', 'outOfOfficeSuppressExternalReply'])({
					target: { value: !value.enableOutOfOfficeExternalReply }
				});
			} else if (value.enableOutOfOfficeExternalReply) {
				onFieldChange('enableOutOfOfficeExternalReply')({
					target: { value: !value.enableOutOfOfficeExternalReply }
				});
			} else {
				onFieldChange('outOfOfficeSuppressExternalReply')({
					target: { value: !value.outOfOfficeSuppressExternalReply }
				});
			}
		} else if (selectedValue === SEND_EXTERNAL_REPLY) {
			if (value.outOfOfficeSuppressExternalReply) {
				onFieldChange('enableOutOfOfficeExternalReply')({
					target: { value: !value.enableOutOfOfficeExternalReply }
				});
				onFieldChange('outOfOfficeSuppressExternalReply')({
					target: { value: !value.outOfOfficeSuppressExternalReply }
				});
			} else {
				onFieldChange('enableOutOfOfficeExternalReply')({
					target: { value: !value.enableOutOfOfficeExternalReply }
				});
			}
		} else {
			onFieldChange('outOfOfficeSuppressExternalReply')({
				target: { value: !value.outOfOfficeSuppressExternalReply }
			});
		}
	};

	handleSendMsg = () => {
		const { email, name, vacationResponseSample, value, sampleSent, sendMessage } = this.props;
		const message = {
			to: [
				{
					email,
					name
				}
			],
			from: [
				{
					email,
					name
				}
			],
			subject: vacationResponseSample,
			text: value.outOfOfficeReply.trim()
		};

		sendMessage({ message }).then(() => {
			this.props.notify({
				message: sampleSent
			});
		});
	};

	handleExternalSendMsg = () => {
		const {
			email,
			name,
			vacationResponseSample,
			value,
			sampleSent,
			sendMessage,
			notify: notifyAction
		} = this.props;
		const message = {
			to: [
				{
					email,
					name
				}
			],
			from: [
				{
					email,
					name
				}
			],
			subject: vacationResponseSample,
			text: value.outOfOfficeExternalReply.trim()
		};
		sendMessage({ message }).then(() => {
			notifyAction({
				message: sampleSent
			});
		});
	};

	handleFromDateTimeChange = date => this.handleFromDateOnChange(date, true, true);

	handleFromDateOnChange = (date, isValid = true, isTimeChange) => {
		const {
			handleValidityOfTabs,
			activeId,
			onFieldChange,
			value: { defaultUntilDate }
		} = this.props;

		const { isFromDateInvalid, isUntilDateInvalid } = this.state;

		if (!isValid) {
			handleValidityOfTabs(activeId, true);
			this.setState({ isFromDateInvalid: true });
			return;
		} else if (!isTimeChange && isFromDateInvalid) {
			!isUntilDateInvalid && handleValidityOfTabs(activeId, false);
			this.setState({ isFromDateInvalid: false });
		}

		const fromDate = moment(date);
		const untilDate = moment(parseDate(defaultUntilDate));
		if (fromDate.isValid()) {
			if (untilDate.isValid()) {
				if (moment(untilDate).isBefore(fromDate)) {
					onFieldChange('defaultFromDate')({
						target: { value: formatDate(fromDate) }
					}).then(() => {
						onFieldChange('defaultUntilDate')({
							target: {
								value: formatDate(moment(date).endOf('day'))
							}
						});
					});
				} else {
					onFieldChange('defaultFromDate')({
						target: { value: formatDate(fromDate) }
					});
				}
			} else {
				onFieldChange('defaultFromDate')({
					target: { value: formatDate(fromDate) }
				});
			}
		}
	};

	handleUntilDateTimeChange = date => this.handleUntilDateOnChange(date, true, true);

	handleUntilDateOnChange = (date, isValid = true, isTimeChange) => {
		const {
			handleValidityOfTabs,
			activeId,
			onFieldChange,
			value: { defaultUntilDate, defaultFromDate }
		} = this.props;

		const { isUntilDateInvalid, isFromDateInvalid } = this.state;
		if (!isValid) {
			handleValidityOfTabs(activeId, true);
			this.setState({ isUntilDateInvalid: true });
			return;
		} else if (!isTimeChange && isUntilDateInvalid) {
			!isFromDateInvalid && handleValidityOfTabs(activeId, false);
			this.setState({ isUntilDateInvalid: false });
		}

		const untilDate = defaultUntilDate ? moment(date) : moment(date).endOf('day');
		const fromDate = moment(parseDate(defaultFromDate));
		if (fromDate.isValid() && untilDate.isValid()) {
			if (moment(untilDate).isBefore(fromDate)) {
				onFieldChange('defaultUntilDate')({
					target: { value: formatDate(untilDate) }
				}).then(() => {
					onFieldChange('defaultFromDate')({
						target: {
							value: formatDate(moment(date).startOf('day'))
						}
					});
				});
			} else {
				onFieldChange('defaultUntilDate')({
					target: { value: formatDate(untilDate) }
				});
			}
			this.setState({ outOfOfficeUntilDate: true });
		}
	};

	render(
		{ value, onFieldChange, selectedExternalReplyValue, sendExternal },
		{ outOfOfficeUntilDate }
	) {
		const { isEnterprise } = this.props;
		const {
			enableOutOfOfficeReply,
			defaultFromDate,
			defaultUntilDate,
			outOfOfficeExternalReply,
			outOfOfficeReply
		} = value;

		const fromDateValue = enableOutOfOfficeReply
			? (moment(defaultFromDate).isValid() && moment(defaultFromDate).startOf('day')) ||
			  parseDate(defaultFromDate)
			: null;

		const untilDateValue =
			outOfOfficeUntilDate && enableOutOfOfficeReply && defaultUntilDate
				? parseDate(defaultUntilDate)
				: null;

		return (
			<div>
				<div class={cx(style.sectionTitle, style.hideMdUp)}>
					<Text id="settings.vacationResponse.title" />
				</div>
				<div class={style.subsection}>
					<div class={style.subsectionBodyOOO}>
						<ul class={style.list}>
							<li>
								<label>
									<ChoiceInput
										onChange={this.updateOooReplyEnabled}
										checked={enableOutOfOfficeReply}
									/>
									<Text id="settings.vacationResponse.enableOutOfOfficeReply" />
								</label>
							</li>
						</ul>
					</div>
				</div>
				<AlignedForm>
					<FormGroup compact>
						<AlignedLabel
							width="60px"
							class={style.alignLabel}
							textId="settings.vacationResponse.fromDate"
						/>
						<div class={style.datePickerWrapper}>
							<DateInput
								onDateChange={this.handleFromDateOnChange}
								class={style.dateSelector}
								dateValue={fromDateValue}
								disabled={!enableOutOfOfficeReply}
							/>
							<CustomTimePicker
								value={fromDateValue}
								onDateChange={this.handleFromDateTimeChange}
								disabled={!enableOutOfOfficeReply}
							/>
						</div>
					</FormGroup>
					<FormGroup compact>
						<AlignedLabel
							width="60px"
							class={style.alignLabel}
							textId="settings.vacationResponse.untilDate"
						/>
						<div class={style.datePickerWrapper}>
							<DateInput
								onDateChange={this.handleUntilDateOnChange}
								class={style.dateSelector}
								dateValue={untilDateValue}
								disabled={!enableOutOfOfficeReply || !outOfOfficeUntilDate}
							/>
							<CustomTimePicker
								value={untilDateValue}
								onDateChange={this.handleUntilDateTimeChange}
								disabled={!enableOutOfOfficeReply || !defaultUntilDate}
							/>

							<div class={cx(style.endDate, !enableOutOfOfficeReply && style.disabledText)}>
								<label>
									<ChoiceInput
										onChange={this.updateDefaultUntilDate}
										checked={enableOutOfOfficeReply && !outOfOfficeUntilDate}
										disabled={!enableOutOfOfficeReply}
									/>
									<Text id="settings.vacationResponse.noEndDate" />
								</label>
							</div>
						</div>
					</FormGroup>
				</AlignedForm>
				<Textarea
					rows="5"
					wide
					class={style.vacationResponseTextArea}
					value={outOfOfficeReply}
					disabled={!enableOutOfOfficeReply}
					onChange={onFieldChange('outOfOfficeReply')}
				/>
				<Button
					class={style.buttonNoMargin}
					id="settings.vacationResponse.sendMeCopy"
					disabled={!enableOutOfOfficeReply}
					onClick={this.handleSendMsg}
				>
					<Text id="settings.vacationResponse.sendMeCopy" />
				</Button>
				{isEnterprise &&
					[
						<div class={cx(style.subsection, style.vactionSubField)}>
							<div class={cx(style.subsectionTitle, style.forSelect)}>
								<Text id="settings.vacationResponse.externalSendersSubsection" />
							</div>
							<div class={cx(style.subsectionBody, style.vactionInlineField)}>
								<Select
									onChange={this.updateOooExternalSender}
									value={selectedExternalReplyValue}
									disabled={!value.enableOutOfOfficeReply}
									fullWidth
								>
									<option value={SEND_STANDARD_EXTERNAL_REPLY}>
										<Text id="settings.vacationResponse.externalSendersOption.sendStandard" />
									</option>
									<option value={SEND_EXTERNAL_REPLY}>
										<Text id="settings.vacationResponse.externalSendersOption.sendExternal" />
									</option>
									<option value={DONT_SEND_EXTERNAL_REPLY}>
										<Text id="settings.vacationResponse.externalSendersOption.doNotSend" />
									</option>
								</Select>
							</div>
						</div>,
						(outOfOfficeExternalReply || sendExternal) && (
							<div class={style.vacationExternalMessageArea}>
								<Textarea
									rows="5"
									wide
									class={style.vacationResponseTextArea}
									value={outOfOfficeExternalReply}
									disabled={!enableOutOfOfficeReply}
									onChange={onFieldChange('outOfOfficeExternalReply')}
								/>

								<Button
									class={style.buttonNoMargin}
									id="settings.vacationResponse.sendMeCopy"
									disabled={!enableOutOfOfficeReply}
									onClick={this.handleExternalSendMsg}
								>
									<Text id="settings.vacationResponse.sendMeCopy" />
								</Button>
							</div>
						)
					].filter(Boolean)}
			</div>
		);
	}
}
