/**
 * nextPrevItemToSelect return nextItem and Previous item to be selected on selectedIds get moved/deleted
 * @param {Array} listData all items of current folder list
 * @param {Array} selectedIds selected items
 * @param {String} activeId first selected item
 * @returns { Object, Object } nextItem, PrevItem
 */

export const nextPrevItemToSelect = (listData, selectedIds, activeId) => {
	const prevIndexes = selectedIds
		.map(currentId => listData.findIndex(({ id }) => id === currentId))
		.sort((a, b) => a - b);

	const currentSelectedId = activeId || selectedIds?.[0];
	const activeIndex = listData.findIndex(({ id }) => id === currentSelectedId);

	let firstIndex = activeIndex;
	let lastIndex = activeIndex;
	//get the first index before the active mail item that is not in the selected list
	while (prevIndexes.indexOf(--firstIndex) !== -1);
	//get the first index after the active mail item that is not in the selected list
	while (prevIndexes.indexOf(++lastIndex) !== -1);

	// get a slice of items from our new range and remove any items from the slice that are in our selected list
	const itemsSlice = listData
		.slice(Math.max(0, firstIndex), lastIndex + 1)
		.filter(({ id }) => selectedIds.indexOf(id) === -1);

	// use the first and last items of whatever is left
	const prevItem = itemsSlice?.[0];
	const nextItem = itemsSlice?.[itemsSlice.length - 1];

	return { nextItem, prevItem };
};
