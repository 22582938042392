import { PureComponent } from 'preact/compat';
import { connect } from 'react-redux';
import { withText, Text } from 'preact-i18n';
import { callWith } from '../../lib/util';
import clientConfiguration from '../../enhancers/client-config';
import { Button, Icon, Popover } from '@zimbra/blocks';
import ZimletSlot from '../zimlet-slot';
import ContactCardClipboardMenu from '../contact-card-menu';
import Avatar from '../avatar';
import { getPrimaryPhone, getPrimaryEmail, getJobDescription, getName } from '../../utils/contacts';
import { isPossiblySpoofedAddress } from '../../utils/phishing';
import withGetProfilePhoto from '../../graphql-decorators/contact/get-profile-photo';
import cx from 'classnames';
import style from './style';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import getContext from '../../lib/get-context';
import { withProps } from 'recompose';
import withSearch from '../../graphql-decorators/search';
import { withCreateContact } from '../../graphql-decorators/contact';
import { notify } from '../../store/notifications/actions';
import { USER_FOLDER_IDS } from '../../constants';
import appConfiguration from '../../enhancers/app-config';

const SHOW_EVENT_DETAILS_AFTER_HOVER_DELAY = 1000;

@withText('mail.viewer.spoofedAddressWarning')
@getContext(({ zimbraBatchClient }) => ({ zimbraBatchClient }))
export default class ContactHoverCard extends PureComponent {
	render({
		target,
		address,
		toggleActionMenu,
		toggleEditModal,
		spoofedAddressWarning,
		invalidCert,
		verifiedStatusText,
		contact,
		name
	}) {
		return (
			<Popover
				arrow
				placement="top"
				anchor="center"
				hoverDuration={SHOW_EVENT_DETAILS_AFTER_HOVER_DELAY}
				target={target}
			>
				<ContactCardDetails
					address={address}
					toggleEditModal={toggleEditModal}
					toggleActionMenu={toggleActionMenu}
					spoofedAddressWarning={spoofedAddressWarning}
					invalidCert={invalidCert}
					verifiedStatusText={verifiedStatusText}
					contact={contact}
					name={name}
				/>
			</Popover>
		);
	}
}

@clientConfiguration('routes.slugs')
@appConfiguration('zimbraOrigin')
@withSearch({
	options: ({ address }) => ({
		fetchPolicy: 'cache-first',
		variables: {
			query: `field[email]:${address.address || address.email || address}`,
			types: 'contact',
			limit: 1
		}
	}),
	props: ({ data: { search, loading } }) => {
		return {
			isLoadingContact: loading,
			contact: get(search, 'contacts.0')
		};
	}
})
@withGetProfilePhoto({
	withSearchOptions: {
		skip: ({ contact, address }) => !address || contact
	},
	withSearchGalOptions: {
		/* `withSearchGal`: Get thumbnail image explicitly using searchGal request and skip execution
		 * if it already has `thumbnailPhoto`.
		 */
		skip: ({ contact, contactWithImage }) =>
			contactWithImage ||
			get(contact, 'thumbnailPhoto') ||
			get(contact, 'isGalContact') === false ||
			get(contact, 'type') === 'contact'
	}
})
@withCreateContact()
@connect(
	state => ({
		isOffline: get(state, 'network.isOffline')
	}),
	{ notify }
)
/**
 *  Append thumbnail photo with the contact and pass it as contactDetails prop
 *  and use contactDetails further instead of contact.
 */
@withProps(({ contact, contactWithImage }) => {
	return { contactDetails: contactWithImage || contact || {} };
})
@withText({
	searchEmails: 'contacts.hoverCard.searchEmails',
	sendEmail: 'contacts.hoverCard.sendEmail',
	clipboard: 'contacts.hoverCard.clipboard'
})
class ContactCardDetails extends PureComponent {
	getInfo() {
		// TODO: Remove support for "address" and use contact only.
		const { address, name, contactDetails = {} } = this.props;
		const attributes = get(contactDetails, 'attributes') ||
			get(contactDetails, '_attrs') || {
				email: address,
				name: name || address.split('@')[0]
			};
		return {
			contactDetails,
			isSpoofed: isPossiblySpoofedAddress(attributes),
			name: name || getName(attributes),
			email: getPrimaryEmail({ attributes }),
			jobDescription: getJobDescription(attributes),
			phone: getPrimaryPhone({ attributes })
		};
	}

	addToContacts = ({ name, email }) => {
		const { createContact, notify: notifyAction } = this.props;
		const [firstName = '', lastName = ''] = name.split(' ');
		createContact({
			attributes: { fullName: name, firstName, lastName, email },
			folderId: USER_FOLDER_IDS.CONTACTS
		}).then(() => {
			notifyAction({
				message: (
					<Text id="actions.toasts.addSenderToContacts.message.one" fields={{ address: email }} />
				)
			});
		});
	};

	render({
		thumbnailPhoto,
		slugs,
		isOffline,
		sendEmail,
		searchEmails,
		clipboard,
		toggleActionMenu,
		toggleEditModal,
		spoofedAddressWarning,
		isLoadingContact,
		invalidCert,
		verifiedStatusText
	}) {
		const { contactDetails, isSpoofed, name, email, jobDescription, phone } = this.getInfo();
		const { publicCert, isCertificateExpired } = contactDetails;
		return (
			<div class={style.contactCard}>
				<div class={style.details}>
					<h3>
						{isSpoofed && <Icon size="sm" name="warning" title={spoofedAddressWarning} />}
						{name}
					</h3>
					<h4>{jobDescription}</h4>
					<dl class={style.cardInfo}>
						<dt>
							<Icon name="envelope" size="sm" />
						</dt>
						<dd>
							<a href={'mailto:' + email}>{email}</a>
						</dd>
					</dl>
					{phone && (
						<dl class={style.cardInfo}>
							<dt>
								<Icon name="mobile-phone" size="sm" />
							</dt>
							<dd>
								<a href={'tel:' + phone}>{phone}</a>
							</dd>
						</dl>
					)}
					<ZimletSlot name="contact-hover-card-details" email={email} contact={contactDetails} />
				</div>

				<div class={style.avatarWrapper}>
					<Avatar
						class={style.avatar}
						email={email}
						thumbnailBase64={thumbnailPhoto}
						contact={contactDetails}
					/>
				</div>

				<footer class={style.footer}>
					<div class={style.addEditLink}>
						{!isLoadingContact &&
							(!isEmpty(contactDetails) ? (
								<Button onClick={toggleEditModal} disabled={isOffline} styleType="text">
									<Text id="contacts.hoverCard.EDIT" />
								</Button>
							) : (
								<Button
									onClick={callWith(this.addToContacts, { name, email })}
									disabled={isOffline}
									styleType="text"
								>
									<Text id="contacts.hoverCard.ADD" />
								</Button>
							))}
					</div>

					<Button
						title={searchEmails}
						href={`/${slugs.search}/${slugs.email}?q=${encodeURIComponent(
							`to:${email} OR from:${email}`
						)}`}
					>
						<Icon name="search" size="sm" />
					</Button>

					<Button title={sendEmail} href={'mailto:' + email} rel="noopener noreferrer" useAnchor>
						<Icon name="envelope" size="sm" />
					</Button>

					<ContactCardClipboardMenu
						title={clipboard}
						contact={contactDetails}
						email={email}
						jobDescription={jobDescription}
						name={name}
						phone={phone}
						toggleActionMenu={toggleActionMenu}
					/>

					{publicCert && typeof isCertificateExpired === 'boolean' && (
						<div class={cx(invalidCert ? style.sMimePubCertExpired : style.sMimePubCertVerified)}>
							<Icon class={style.smimePubCertShieldIcon} name="verified" size="sm" />
							<Text id={`smime.certificate.${verifiedStatusText}`} />
						</div>
					)}
				</footer>
			</div>
		);
	}
}
