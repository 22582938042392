import inviteDate from './invite-date';
import { htmlToText } from '../../../lib/html-email';
import { NOTES_SEPARATOR } from '../constants';

export default function plainTextInviteEmail({
	subject,
	organizer,
	start,
	end,
	location,
	attendees,
	repeats,
	body,
	replyTextString,
	template,
	headerString
}) {
	return `
${replyTextString || ''}

${headerString ? htmlToText(headerString, template.originalInvitation) : ''}

${template.intro}

${template.subject} ${subject}
${template.organizer} "${organizer.name}" <${organizer.address}>

${template.time} ${inviteDate(start, end)}
${repeats ? template.repeats : ''} ${repeats || ''}
${location.length ? template.location : ''} ${location.length ? location : ''}

${template.invitees} ${(attendees || []).map(a => a.address).join('; ')}

${NOTES_SEPARATOR}

${body ? htmlToText(body) : ''}
`.trim();
}

export function plainTextCounterInviteEmail({
	subject,
	start,
	end,
	body,
	location,
	template,
	intro
}) {
	return `
${intro}

${template.subject} ${subject}

${location && location.length ? template.location : ''} ${location.length ? location : ''}
${template.time} ${inviteDate(start, end)}

${NOTES_SEPARATOR}

${body ? htmlToText(body) : ''}
`.trim();
}
