import forOwn from 'lodash-es/forOwn';
import isNull from 'lodash-es/isNull';
import cloneDeep from 'lodash-es/cloneDeep';
import isArray from 'lodash-es/isArray';
import pull from 'lodash-es/pull';
import values from 'lodash-es/values';
import isObject from 'lodash-es/isObject';
import isEmpty from 'lodash-es/isEmpty';
import {
	FILTER_ACTION_TYPE,
	FILTER_TEST_TYPE,
	FILTER_CONDITION_TYPE
} from '../constants/filter-rules';
import get from 'lodash-es/get';

export function isFilterSupported(rule) {
	// We only support single-action rules.
	if (rule.actions.length > 1) return false;

	// We only support a subset of test types.
	// TODO: we could be stricter on filtering, e.g. for these test types there
	// might exist unsupported header tests set by legacy client.
	const condition = rule.conditions[0];

	const testConditions = values(FILTER_TEST_TYPE);
	return Object.keys(condition).every(key => {
		if (key === 'allOrAny') {
			return condition[key];
		}

		return testConditions.indexOf(key) > -1;
	});
}

export function isBasicFilter(filter) {
	if (advanceFilterAction(filter) || advanceFilterCondition(filter)) {
		return false;
	}
	return true;
}

function advanceFilterAction(filter) {
	//Should return true if there are more than 1 action present apart from 'Stop'.
	//Should return true if the other action is 'Keep in Inbox'.
	//Should return true if multiple type of same filter exist e.g - multiple ForwardTo address.

	const filterActions = Object.keys(filter.actions[0]);
	return (
		filterActions.length > 2 ||
		!filterActions.includes(FILTER_ACTION_TYPE.STOP) ||
		filterActions.includes(FILTER_ACTION_TYPE.KEEP_IN_INBOX) ||
		filter.actions[0][filterActions.filter(f => f !== FILTER_ACTION_TYPE.STOP)[0]].length > 1
	);
}

function advanceFilterCondition(filter) {
	//Should return true if 'headerExists' is present in conditions
	//Should return true if there are more than one header or body present or more than two address values present
	//Should return true when the header is other than 'subject'
	//Should return true when the address feild have more values other than one 'From' or one 'To/CC'

	const filterConditions = Object.keys(filter.conditions[0]);
	const conditionHeader =
		filterConditions.includes(FILTER_TEST_TYPE.HEADER) && get(filter, 'conditions.0.header');
	const conditionAddress =
		filterConditions.includes(FILTER_TEST_TYPE.ADDRESS) && get(filter, 'conditions.0.address');

	const containsMultipleBody =
		filterConditions.includes(FILTER_TEST_TYPE.BODY) && get(filter, 'conditions.0.body').length > 1;
	const headerOtherThanSubject =
		conditionHeader && conditionHeader[0].header !== FILTER_CONDITION_TYPE.SUBJECT;
	const containsSameHeader =
		conditionAddress &&
		conditionAddress.length === 2 &&
		conditionAddress[0].header === conditionAddress[1].header;
	const addressOtherThanFromorToCC =
		conditionAddress &&
		conditionAddress.filter(
			({ header }) => header !== FILTER_CONDITION_TYPE.TOCC && header !== FILTER_CONDITION_TYPE.FROM
		).length;

	return (
		filterConditions.includes(FILTER_TEST_TYPE.HEADER_EXIST) ||
		containsMultipleBody ||
		(conditionAddress && conditionAddress.length > 2) ||
		(conditionHeader && conditionHeader.length > 1) ||
		headerOtherThanSubject ||
		containsSameHeader ||
		addressOtherThanFromorToCC
	);
}

export function pruneEmpty(obj) {
	return (function prune(current) {
		forOwn(current, (value, key) => {
			if (isNull(value) || (isObject(value) && isEmpty(prune(value)))) {
				delete current[key];
			}
		});

		// remove any leftover undefined values from the delete
		// operation on an array
		if (isArray(current)) {
			pull(current, undefined);
		}

		return current;
	})(cloneDeep(obj)); // Do not modify the original object, create a clone instead
}
