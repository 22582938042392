import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { getSMimePublicCertsQuery } from '../../graphql/queries/smime/public-certs.graphql';
import withAccountInfo from '../account-info';
import { isSMIMEFeatureAvailable } from '../../utils/license';
import get from 'lodash-es/get';

export function getSMimePublicCerts() {
	return compose(
		withAccountInfo(({ data: { accountInfo } }) => ({
			isSMimeFeatureEnabled: isSMIMEFeatureAvailable(accountInfo.license),
			zimbraFeatureGalEnabled: get(accountInfo, 'attrs.zimbraFeatureGalEnabled')
		})),
		graphql(getSMimePublicCertsQuery, {
			skip: ({ isSMimeFeatureEnabled, showCertBadge }) => !(isSMimeFeatureEnabled && showCertBadge),
			options: ({ contact, zimbraFeatureGalEnabled }) => ({
				variables: {
					contactAddr: contact && contact.address,
					store: zimbraFeatureGalEnabled ? 'CONTACT,GAL,LDAP' : 'CONTACT,LDAP'
				}
			}),
			props: ({ data }) => ({
				publicCert: get(data, 'getSMimePublicCerts.certs.0.cert.0._content'),
				publicCertLoading: data.loading,
				publicCertError: data.error,
				refetchPublicCert: data.refetch
			})
		})
	);
}
