import 'regenerator-runtime/runtime';
import '@zimbra/polyfill';
import 'core-js/features/array/from';
import 'core-js/features/string/starts-with';
import 'core-js/features/set';
import 'core-js/features/map';
import 'core-js/features/symbol';
import 'core-js/features/weak-map';
import 'core-js/features/promise/finally';
import './style/index.less';
import { render } from 'preact';
import forceHTTPS from './lib/force-https';
import { awaitInstallPrompt } from 'preact-pwa-install';
import App from './components/app';
import { AppConfigProvider } from './context/app-config';
import { TrackingProvider } from './context/tracking';
import ErrorBoundary from './enhancers/error-boundary';

if (module.hot) {
	require('preact/debug');
	module.hot.accept('./components/app', () => requestAnimationFrame(init));
}

forceHTTPS();

/*
This function is used to capture in-coming install prompts from the
browser. Components using the preact-pwa-install installation
capabilities later in the application's lifecycle require this
in case the install prompt is passed very early:
*/
awaitInstallPrompt();

if (navigator.maxTouchPoints > 0) {
	document.documentElement.className = 'touch';
}

const root = document.createElement('div');
root.id = 'zm-x-web';
document.body.appendChild(root);

function init() {
	render(
		<ErrorBoundary>
			<AppConfigProvider>
				<TrackingProvider>
					<App />
				</TrackingProvider>
			</AppConfigProvider>
		</ErrorBoundary>,
		root
	);
}
init();
