import find from 'lodash-es/find';
import get from 'lodash-es/get';
import pick from 'lodash-es/pick';
import mailPort from '../../utils/mail-port';
import { USER_FOLDER_IDS } from '../../constants';

function getSignature(signatureId, signatures) {
	return get(
		find(signatures, s => s.id === signatureId),
		'content.0._content'
	);
}

export function normalizeIdentity(identity, account, signatures) {
	const defaultSignatureId = get(identity, '_attrs.zimbraPrefDefaultSignatureId');
	const defaultSignature = getSignature(defaultSignatureId, get(signatures, 'signature', []));

	const forwardReplySignature = getSignature(
		get(identity, '_attrs.zimbraPrefForwardReplySignatureId'),
		get(signatures, 'signature', [])
	);

	return {
		...identity,
		emailAddress: get(identity, '_attrs.zimbraPrefFromAddress'),
		emailAddressType: get(identity, '_attrs.zimbraPrefFromAddressType'),
		defaultSignature: defaultSignatureId,
		forwardReplySignature: get(identity, '_attrs.zimbraPrefForwardReplySignatureId'),
		fromDisplay: get(identity, '_attrs.zimbraPrefFromDisplay'),
		isPrimaryAccount: identity.id === account.id,
		name: get(identity, '_attrs.zimbraPrefIdentityName'),
		replyToAddress: get(identity, '_attrs.zimbraPrefReplyToAddress'),
		replyToDisplay: get(identity, '_attrs.zimbraPrefReplyToDisplay'),
		replyToEnabled: get(identity, '_attrs.zimbraPrefReplyToEnabled'),
		whenInFolderIds: get(identity, '_attrs.zimbraPrefWhenInFolderIds'),
		whenInFoldersEnabled: get(identity, '_attrs.zimbraPrefWhenInFoldersEnabled'),
		whenSentToAddresses: get(identity, '_attrs.zimbraPrefWhenSentToAddresses'),
		whenSentToEnabled: get(identity, '_attrs.zimbraPrefWhenSentToEnabled'),
		defaultSignatureValue: defaultSignature,
		forwardReplySignatureValue: forwardReplySignature,
		showSignatureEditor: !!defaultSignature
	};
}

export function denormalizeIdentity(identity) {
	return {
		zimbraPrefForwardReplyFormat: identity.forwardReplyFormat || null,
		zimbraPrefFromAddress: identity.emailAddress || null,
		zimbraPrefFromAddressType: identity.emailAddressType || null,
		zimbraPrefDefaultSignatureId: identity.defaultSignature || '',
		zimbraPrefForwardReplySignatureId: identity.forwardReplySignature || null,
		zimbraPrefFromDisplay: identity.fromDisplay,
		zimbraPrefIdentityName: identity.name,
		zimbraPrefMailSignatureStyle: identity.mailSigSignatureStyle || null,
		zimbraPrefSentMailFolder: identity.sentMailFolder || null,
		zimbraPrefReplyToAddress: identity.replyToAddress || '',
		zimbraPrefReplyToDisplay: identity.replyToDisplay || '',
		zimbraPrefReplyToEnabled: identity.replyToEnabled || false,
		zimbraPrefWhenInFolderIds: identity.whenInFolderIds,
		zimbraPrefWhenInFoldersEnabled: identity.whenInFoldersEnabled || false,
		zimbraPrefWhenSentToAddresses: identity.whenSentToAddresses,
		zimbraPrefWhenSentToEnabled: identity.whenSentToEnabled || false
	};
}

export function normalizeDataSource(source, type, signatures) {
	const defaultSignature = getSignature(source.defaultSignature, get(signatures, 'signature', []));

	const forwardReplySignature = getSignature(
		source.forwardReplySignature,
		get(signatures, 'signature', [])
	);
	const useSSL = source.connectionType === 'ssl';

	return {
		...source,
		useSSL,
		useCustomFolder: type === 'pop3' && source.l !== USER_FOLDER_IDS.INBOX.toString(),
		useCustomPort: source.port !== mailPort(type, useSSL),
		password: '',
		accountType: type,
		username: source.username,
		host: source.host,
		emailAddress:
			source.emailAddress ||
			(source.username.indexOf('@') !== -1 && source.username) ||
			`${source.username}@${source.host}`,
		name: source.name,
		replyToEnabled: source.useAddressForForwardReply,
		defaultSignatureValue: defaultSignature,
		forwardReplySignatureValue: forwardReplySignature,
		showSignatureEditor: !!defaultSignature
	};
}

export function denormalizeDataSource(dataSource) {
	return {
		...pick(dataSource, [
			'defaultSignature',
			'fromDisplay',
			'name',
			'replyToAddress',
			'replyToDisplay',
			'importOnly',
			'isEnabled',
			'forwardReplySignature',
			'password',
			'connectionType',
			'accountType',
			'l',
			'leaveOnServer',
			'port',
			'host',
			'username'
		]),
		useAddressForForwardReply: dataSource.replyToEnabled
	};
}
