import { Text } from 'preact-i18n';
import { PureComponent } from 'preact/compat';
import omit from 'lodash-es/omit';
import xor from 'lodash-es/xor';
import {
	specialFolders as computeSpecialFolders,
	customFolders as computeCustomFolders
} from '../../utils/folders';

import { pluck } from '../../lib/util';
import FolderListItem from './item';
import FolderGroup from './group';
import cx from 'classnames';
import { withProps, branch, renderNothing } from 'recompose';
import style from '../folder-list/style';

@branch(({ folders }) => !folders, renderNothing)
@withProps(({ folders, indexFolderName }) => ({
	indexFolder: folders && pluck(folders, 'name', indexFolderName)
}))

//TODO: change it to functional component
export default class FolderListLight extends PureComponent {
	state = {
		collapsibleFolderGroupToggle: this.props.collapsibleFolderGroupToggle || false,
		foldersExpanded: this.props.expandedFolderIds || [],
		selectedFolderIds: this.props.selectedIds || []
	};

	handleFolderClick = folder => {
		const { onFolderClick, isMultiSelect } = this.props;

		// `xor` will merge two arrays and return an array with unique values by checking,
		// If a value exist in both array, remove it from returning array, and if not exist, insert it.
		// i.e. [1, 2, 3, 4] xor [1, 5] => [2, 3, 4, 5]
		const selectedFolderIds = isMultiSelect
			? xor(this.state.selectedFolderIds, [folder.id])
			: [folder.id];

		onFolderClick && onFolderClick(folder, selectedFolderIds);

		this.setState({
			selectedFolderIds
		});
	};

	setFolderExpanded = folderId => {
		this.setState(({ foldersExpanded: prevFoldersExpanded }) => ({
			foldersExpanded:
				(prevFoldersExpanded.includes(folderId) &&
					prevFoldersExpanded.filter(id => id !== folderId)) ||
				prevFoldersExpanded.concat(folderId)
		}));
	};

	folderMap =
		(params = {}) =>
		folder =>
			this.folderLink({
				folder,
				...params
			});

	folderLink = ({ folder, disableCollapse = false, grouped = false }) => {
		const { folders, smartFolders, indexFolder, folderNameProp, isFolderDisabled, isMultiSelect } =
			this.props;

		const { selectedFolderIds, foldersExpanded } = this.state;

		return (
			<FolderListItem
				isMultiSelect={isMultiSelect}
				indexFolder={indexFolder}
				isFolderDisabled={isFolderDisabled}
				folder={folder}
				folders={folder.query ? smartFolders : folders}
				depth={1}
				onFolderClick={this.handleFolderClick}
				selectedFolderIds={selectedFolderIds}
				onToggleExpanded={this.setFolderExpanded}
				disableCollapse={disableCollapse}
				foldersExpanded={foldersExpanded}
				grouped={grouped}
				folderNameProp={folderNameProp}
			/>
		);
	};

	handleToggleFolders = e => {
		e.stopPropagation();
		this.setState(({ collapsibleFolderGroupToggle }) => ({
			collapsibleFolderGroupToggle: !collapsibleFolderGroupToggle
		}));
	};

	handleToggleSharedFolders = e => {
		e.stopPropagation();
		this.setState(({ collapsibleSharedFolderGroup }) => ({
			collapsibleSharedFolderGroup: !collapsibleSharedFolderGroup
		}));
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedIds) {
			this.setState({ selectedFolderIds: nextProps.selectedIds });
		}
	}

	render(
		{
			folders,
			smartFolders,
			isMultiSelect,
			divided,
			label,
			collapsibleCustomGroup,
			collapsibleSmartGroup,
			folderTreeOpen,
			foldersExpanded,
			defaultContextMenu,
			specialFolderList,
			hiddenFolderList = [],
			matchesMediaQuery,
			indexFolderName,
			sharedFolders = [],
			extraChildren,
			...props
		},
		{ collapsibleFolderGroupToggle, collapsibleSharedFolderGroup }
	) {
		if (!folders || folders.length === 0) {
			return null;
		}

		folders = computeCustomFolders(folders, hiddenFolderList);

		const specialFolders = computeSpecialFolders(folders, specialFolderList);
		const customFolders = computeCustomFolders(folders, specialFolderList);

		return (
			<div
				{...omit(props, ['indexFolder', 'indexFolderName'])}
				class={cx(style.folderList, isMultiSelect && style.multiSelect, props.class)}
			>
				{specialFolders.map(this.folderMap())}

				{divided && specialFolders.length > 0 && folders.length > 0 && (
					<div class={style.divider}>
						<Text id="folderlist.folders" />
					</div>
				)}

				{collapsibleCustomGroup ? (
					<FolderGroup
						onToggle={this.handleToggleFolders}
						collapsed={!collapsibleFolderGroupToggle}
						name={
							<div class={style.customFolderToggle}>
								<div class={style.customFolderToggleName}>
									<Text id="folderlist.folders" />
								</div>
							</div>
						}
					>
						{customFolders.map(this.folderMap({ grouped: true }))}
					</FolderGroup>
				) : (
					customFolders.map(this.folderMap())
				)}
				{sharedFolders.length > 0 && (
					<FolderGroup
						onToggle={this.handleToggleSharedFolders}
						collapsed={!collapsibleSharedFolderGroup}
						name={
							<div class={style.customFolderToggle}>
								<div class={style.customFolderToggleName}>
									<Text id="folderlist.sharedFolders" />
								</div>
							</div>
						}
					>
						{sharedFolders.map(this.folderMap({ grouped: true }))}
					</FolderGroup>
				)}
				{extraChildren && extraChildren}
			</div>
		);
	}
}
