import GetIdentities from '../../graphql/queries/identities/get-identities.graphql';
import { useQuery } from '@apollo/client';
import get from 'lodash-es/get';

export const useIdentitiesQuery = () => {
	const { result } = useQuery(GetIdentities);
	return {
		identitiesData: get(result, 'data')
	};
};
