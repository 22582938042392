import get from 'lodash-es/get';
import { useMutation } from '@apollo/client';
import deleteIdentity from './../../graphql/mutations/delete-identity.graphql';
import GetIdentities from './../../graphql/queries/identities/get-identities.graphql';

export function useDeletePersona() {
	const [deleteIdentityMutation] = useMutation(deleteIdentity, {});

	return function (id) {
		return deleteIdentityMutation({
			variables: {
				id
			},
			refetchQueries: [
				{
					query: GetIdentities
				}
			],
			optimisticResponse: {
				__typename: 'Mutation',
				deleteIdentity: true
			},
			update: proxy => {
				const data = proxy.readQuery({ query: GetIdentities });
				const identity = get(data, 'getIdentities.identity') || [];
				identity.find((acc, i) => acc.id === id && identity.splice(i, 1));
				proxy.writeQuery({ query: GetIdentities, data });
			}
		});
	};
}
