import { Spinner } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import cx from 'classnames';
import { isImage } from '../../utils/attachments';
import PdfjsViewer from '../pdfjs-viewer';
import style from './style';
import ZimletSlot from '../zimlet-slot';
import { useCallback, useRef } from 'preact/hooks';

const checkSrc = ({ target, source, src }) =>
	((target && target.src) || source || '').indexOf(src) === -1;

export function Viewer({
	innerStyle,
	attachment,
	src,
	pending,
	error,
	zoom,
	isTextContent,
	textContent,
	updateError,
	updatePending,
	udpateNumPages,
	updateCurrentPage,
	getAttachmentSrc,
	isMobileView,
	disableCaching
}) {
	const inner = useRef(null);
	const translate = zoom <= 2 ? 50 : 100 / zoom;

	const handlePdfPageChange = useCallback(
		pageIndex => {
			updateCurrentPage(pageIndex);
		},
		[updateCurrentPage]
	);

	const handleLoad = useCallback(
		({ target, src: source, pdfDoc }) => {
			if (checkSrc({ target, source, src })) {
				// Ignore attachments that are no longer being viewed
				return;
			}

			if (pdfDoc && pdfDoc.numPages) {
				updateCurrentPage(1);
				udpateNumPages(pdfDoc.numPages);
			}
			updatePending(false);
			updateError(false);
		},
		[src, udpateNumPages, updateCurrentPage, updateError, updatePending]
	);

	const handleError = useCallback(
		({ target, src: source }) => {
			if (checkSrc({ target, source, src })) {
				// Ignore attachments that are no longer being viewed
				return;
			}
			updatePending(false);
			updateError(<Text id="mail.attachmentViewer.unableToLoad" />);
		},
		[src, updateError, updatePending]
	);

	return (
		<div class={cx(style.inner, innerStyle, isMobileView && style.mobileView)} ref={inner}>
			{pending && <Spinner block />}
			<ZimletSlot name="attachment-viewer" attachment={attachment}>
				{slotContent => (
					<span>
						{slotContent.filter(Boolean).length
							? slotContent
							: [
									error ? (
										<span class={style.error}>
											<p>{error}</p>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href={`${getAttachmentSrc(attachment)}&disp=a`}
												//data-name is needed while downloading a file in mobile app.
												data-name={attachment.name || attachment.filename}
												download={attachment.name || attachment.filename}
											>
												<Text id="buttons.download" />
											</a>
										</span>
									) : isImage(attachment) ? (
										<img
											src={src}
											style={{
												transform: `scale(${zoom}) translate(-${translate}%, -${translate}%)`
											}}
											class={cx(pending && style.hidden)}
											onLoad={handleLoad}
											onError={handleError}
										/>
									) : isTextContent ? (
										<pre class={style.textFileViewer}>{textContent}</pre>
									) : (
										<PdfjsViewer
											disableCaching={disableCaching}
											scale={zoom}
											container={inner.current}
											src={src}
											class={cx(pending && style.hidden)}
											onChangePage={handlePdfPageChange}
											onLoadDocument={handleLoad}
											onError={handleError}
										/>
									)
							  ]}
					</span>
				)}
			</ZimletSlot>
		</div>
	);
}
