import Markup from 'preact-markup';
import { useContext, useState, useEffect } from 'preact/hooks';
import ClientConfig from '../../context/client-config';
import style from './style.less';

export default function LoginBackground({ class: cls, children, changePasswordFlow }) {
	const config = useContext(ClientConfig);
	const [html, setHtml] = useState(null);

	useEffect(() => {
		if (config.data.loginBackgroundMarkup && !changePasswordFlow) {
			fetch(config.getAssetURLByClient('login-background-markup.html'))
				.then(res => {
					if (!res.ok) {
						throw 'unable to load login-background-markup.html';
					}

					return res.text();
				})
				.then(res => {
					setHtml(res);
				})
				.catch(() => console.error('unable to load login-background-markup.html'));
		}
	}, [changePasswordFlow, config]);

	if (config.data.loginBackgroundMarkup && !changePasswordFlow) {
		return (
			<div class={style.fullArea}>
				{html && <Markup markup={html} type="html" />}
				{children}
			</div>
		);
	}

	const backgroundImgName = 'login-page-background.png';
	const backgroundURL = `url('${config.getAssetURLByClient(
		backgroundImgName
	)}'), url('${config.getDefaultAssetURL(backgroundImgName)}')`;

	return (
		<div
			class={cls}
			style={{
				'background-image': backgroundURL
			}}
		>
			{children}
		</div>
	);
}
