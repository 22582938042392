import { Component } from 'preact';
import { Button } from '@zimbra/blocks';
import get from 'lodash-es/get';
import { Text, Localizer } from 'preact-i18n';
import { callWith, isValidEmail, isValidDomain } from '../../../lib/util';
import cx from 'classnames';
import ErrorBar from '../../error-bar';
import style from '../style';
import linkState from 'linkstate';
import { withProps } from 'recompose';

export function AddressList({
	error,
	value,
	placeholder,
	stateOfAddress,
	addingAllowOrBlockList,
	removeButton,
	onAllowOrBlock,
	selectAddress,
	selected,
	type,
	maxEmailLimitError
}) {
	return (
		<div class={cx(style.blockSection, type === 'allow' && style.settingsSection)}>
			<div class={style.blockOrAllowTitle}>
				<Text id={`settings.allowedAndBlocked.${type}.title`} />
			</div>
			<div>
				<Text id={`settings.allowedAndBlocked.${type}.message`} />
			</div>

			{maxEmailLimitError && (
				<ErrorBar class={style.addressErrorMessage}>
					<Text id={`settings.allowedAndBlocked.${type}.errorMaxEmailLimitExceeded`} />
				</ErrorBar>
			)}
			{error && (
				<ErrorBar class={style.addressErrorMessage}>
					<Text id="settings.allowedAndBlocked.errorInvalid" />
				</ErrorBar>
			)}

			<input
				type="text"
				class={cx(style.textInput, style.block)}
				placeholder={placeholder}
				value={stateOfAddress}
				onChange={addingAllowOrBlockList}
			/>
			<ul class={style.emailAddressList}>
				{value.sort().map(address => (
					<li
						class={cx(style.emailAddressListEntry, address === selected && style.selected)}
						onClick={callWith(selectAddress, address)}
					>
						{address}
					</li>
				))}
			</ul>
			<div>
				<Button class={style.subsectionBodyButton} onClick={onAllowOrBlock}>
					<Text id={`settings.allowedAndBlocked.${type}.button`} />
				</Button>
				<Button onClick={removeButton} class={style.subsectionBodyButton}>
					<Text id="settings.allowedAndBlocked.remove" />
				</Button>
			</div>
		</div>
	);
}

@withProps(({ accountData }) => ({
	maxEmails: accountData.attrs.zimbraMailBlacklistMaxNumEntries
}))
export default class BlockedAndAllowed extends Component {
	state = {
		addressToBlock: null,
		addressToAllow: null,
		selected: null,
		blockError: false,
		allowError: false,
		blockMaxLengthError: false,
		allowMaxLengthError: false
	};

	selectAddress = address => {
		this.setState({ selected: address });
	};

	handleBlockAddress = () => {
		const { value, onFieldChange, maxEmails } = this.props;
		const { addressToBlock } = this.state;
		const blockedAddresses = get(value, 'whiteBlackList.blockedAddresses');
		if (blockedAddresses && blockedAddresses.length === maxEmails) {
			this.setState({ blockMaxLengthError: true });
			return;
		}
		if (isValidEmail(addressToBlock) || isValidDomain(addressToBlock)) {
			onFieldChange('whiteBlackList')({
				target: {
					value: {
						blockedAddresses:
							Array.isArray(blockedAddresses) && blockedAddresses.concat(addressToBlock),
						allowedAddresses: get(value, 'whiteBlackList.allowedAddresses')
					}
				}
			});
			this.setState({
				addressToBlock: '',
				blockError: false
			});
		} else {
			this.setState({ blockError: true });
		}
	};

	handleAllowAddress = () => {
		const { value, onFieldChange, maxEmails } = this.props;
		const { addressToAllow } = this.state;
		const allowedAddresses = get(value, 'whiteBlackList.allowedAddresses');
		if (allowedAddresses && allowedAddresses.length === maxEmails) {
			this.setState({ allowMaxLengthError: true });
			return;
		}
		if (isValidEmail(addressToAllow) || isValidDomain(addressToAllow)) {
			onFieldChange('whiteBlackList')({
				target: {
					value: {
						allowedAddresses:
							Array.isArray(allowedAddresses) && allowedAddresses.concat(addressToAllow),
						blockedAddresses: get(value, 'whiteBlackList.blockedAddresses')
					}
				}
			});
			this.setState({
				addressToAllow: '',
				allowError: false
			});
		} else {
			this.setState({ allowError: true });
		}
	};

	handleUnblockSelectedAddress = () => {
		const { value, onFieldChange, maxEmails } = this.props;
		const { selected, blockMaxLengthError } = this.state;
		const blockedAddresses = get(value, 'whiteBlackList.blockedAddresses');
		if (selected) {
			const blockedAddressesClone = blockedAddresses.filter(a => selected !== a);
			if (
				blockMaxLengthError &&
				blockedAddressesClone &&
				blockedAddressesClone.length < maxEmails
			) {
				this.setState({ blockMaxLengthError: false });
			}
			onFieldChange('whiteBlackList')({
				target: {
					value: {
						blockedAddresses: blockedAddressesClone || [],
						allowedAddresses: get(value, 'whiteBlackList.allowedAddresses')
					}
				}
			});
			this.setState({ selected: null });
		}
	};

	handleUnallowSelectedAddress = () => {
		const { value, onFieldChange, maxEmails } = this.props;
		const { selected, allowMaxLengthError } = this.state;
		const allowedAddresses = get(value, 'whiteBlackList.allowedAddresses');
		if (selected) {
			const allowedAddressesClone = allowedAddresses.filter(a => selected !== a);
			if (
				allowMaxLengthError &&
				allowedAddressesClone &&
				allowedAddressesClone.length < maxEmails
			) {
				this.setState({ allowMaxLengthError: false });
			}
			onFieldChange('whiteBlackList')({
				target: {
					value: {
						allowedAddresses: allowedAddressesClone || [],
						blockedAddresses: get(value, 'whiteBlackList.blockedAddresses')
					}
				}
			});
			this.setState({ selected: null });
		}
	};

	render(
		{ value: { whiteBlackList }, maxEmails },
		{ selected, blockError, allowError, addressToBlock, blockMaxLengthError, allowMaxLengthError }
	) {
		return (
			<Localizer>
				<AddressList
					type={'block'}
					selected={selected}
					error={blockError}
					maxEmailLimitError={blockMaxLengthError}
					value={whiteBlackList.blockedAddresses}
					maxEmails={maxEmails}
					placeholder={<Text id="settings.emailPlaceholder" />}
					stateOfAddress={addressToBlock}
					addingAllowOrBlockList={linkState(this, 'addressToBlock', 'target.value')}
					removeButton={this.handleUnblockSelectedAddress}
					onAllowOrBlock={this.handleBlockAddress}
					selectAddress={this.selectAddress}
					key={'block_list'}
				/>
				<AddressList
					type={'allow'}
					selected={selected}
					error={allowError}
					maxEmailLimitError={allowMaxLengthError}
					value={whiteBlackList.allowedAddresses}
					maxEmails={maxEmails}
					placeholder={<Text id="settings.emailPlaceholder" />}
					stateOfAddress={this.state.addressToAllow}
					addingAllowOrBlockList={linkState(this, 'addressToAllow', 'target.value')}
					removeButton={this.handleUnallowSelectedAddress}
					onAllowOrBlock={this.handleAllowAddress}
					selectAddress={this.selectAddress}
					key={'allow_list'}
				/>
			</Localizer>
		);
	}
}
