import uniqBy from 'lodash-es/uniqBy';
import compact from 'lodash-es/compact';
import get from 'lodash-es/get';
import last from 'lodash-es/last';
import groupBy from 'lodash-es/groupBy';
import { types as apiClientTypes } from '@zimbra/api-client';
import { hasFlag, getXmlAttribute, isValidEmail, FLAGS } from '../lib/util';
import { isAutoSendDraftMessage, isAutoSendDraftConversation } from '../utils/drafts';
import { isAccount, withoutAccountAddresses } from './account';
import { USER_FOLDER_IDS } from '../constants';
import { REPLY_SUBJECT_PREFIX } from '../constants/mail';
import { getPrimaryEmail } from '../utils/contacts';
import { isInline, isImage, isAttachmentDisposition } from './attachments';
import { HTML_MODE, TEXT_MODE } from '../constants/composer';

const { MailFolderView } = apiClientTypes;

function isDraftMessage(msg) {
	return hasFlag(msg, FLAGS.draft) && !isAutoSendDraftMessage(msg);
}

function isDraftConversation(conv, isMailInTrash, trashFolder) {
	if (isMailInTrash) {
		return (
			conv &&
			conv.numMessages > 0 &&
			hasFlag(conv, FLAGS.draft) &&
			!isAutoSendDraftConversation(conv)
		);
	}

	if (conv.numMessages === 1) {
		return hasFlag(conv, FLAGS.draft) && !isAutoSendDraftConversation(conv);
	}

	const filteredMessage = filterTrashMessage(conv, trashFolder);

	if (filteredMessage?.length) {
		return (
			filteredMessage.find(msg => msg?.flags?.includes(FLAGS.draft)) &&
			!isAutoSendDraftConversation(conv)
		);
	}
}

export function isDraft(mailItem, type, isMailInTrash, trashFolder) {
	return type === MailFolderView.conversation
		? isDraftConversation(mailItem, isMailInTrash, trashFolder)
		: isDraftMessage(mailItem);
}

export function isAutoSendDraft(mailItem, type) {
	return type === MailFolderView.conversation
		? isAutoSendDraftConversation(mailItem)
		: isAutoSendDraftMessage(mailItem);
}

export function filterTrashMessage(conv, trashFolder) {
	return (
		conv &&
		conv.numMessages > 0 &&
		conv.messagesMetaData &&
		conv.messagesMetaData.filter(
			msg =>
				!(
					msg.folderId === get(trashFolder, 'id') ||
					msg.folderId === USER_FOLDER_IDS.TRASH.toString()
				)
		)
	);
}

export function isUnread(mailItem) {
	return hasFlag(mailItem, 'unread');
}

export function isFlagged(mailItem) {
	return hasFlag(mailItem, 'flagged');
}
export function isUrgent(mailItem) {
	return hasFlag(mailItem, 'urgent');
}
export function isSentByMe(mailItem) {
	return hasFlag(mailItem, 'sentByMe');
}
export function isAttachment(mailItem) {
	return hasFlag(mailItem, 'attachment');
}

export function isMessageTrashed(mailItem) {
	return mailItem && String(mailItem.folderId) === String(USER_FOLDER_IDS.TRASH);
}

export function getInvitationRequestId(message) {
	return get(message, 'invitations.0.components.0.method') === 'REQUEST' && message.id;
}

export function getSenders(mailItem) {
	return (
		mailItem.senders ||
		mailItem.from ||
		(mailItem.emailAddresses &&
			uniqBy(mailItem.emailAddresses, 'address').filter(s => s.type === 'f')) ||
		[]
	);
}

export function displaySenders(mailItem, identities, meLabel) {
	if (!mailItem.emailAddresses) {
		return [];
	}

	const filteredAddresses = mailItem.emailAddresses.filter(s => s.type === 'f');

	return compact(
		filteredAddresses.map(s =>
			isAccount(identities, s)
				? meLabel
				: filteredAddresses.length > 2
				? s.displayName || s.name
				: s.name || s.displayName
		)
	);
}

export function displayToAddresses(mailItem, identities, meLabel) {
	if (!mailItem.emailAddresses) return [];

	const filteredAddresses = mailItem.emailAddresses.filter(s => s.type === 't');

	return compact(
		filteredAddresses.map(s =>
			isAccount(identities, s)
				? meLabel
				: filteredAddresses.length > 2
				? s.displayName || s.name
				: s.name || s.displayName
		)
	);
}

export function fromSenders(mailItem, identities) {
	return mailItem.emailAddresses
		? mailItem.emailAddresses
				.filter(s => s.type === 'f')
				.filter(withoutAccountAddresses(identities))
		: [];
}

export function getShareAttribute(mailItem, selector, attr) {
	return getXmlAttribute(get(mailItem, 'share[0].content'), selector, attr);
}

export function isMessageToBeReplied(message) {
	return (message && message.subject && message.subject.includes(REPLY_SUBJECT_PREFIX)) || false;
}

export function isSignedMessage(message) {
	return get(message, 'attributes.isSigned');
}

export function isEncryptedMessage(message) {
	return get(message, 'attributes.isEncrypted');
}

export function isSMIMEMessage(message) {
	return isSignedMessage(message) || isEncryptedMessage(message);
}

export function isCheckedItem(selectedIds, itemId) {
	return (
		(selectedIds &&
			selectedIds.length > 0 &&
			selectedIds.find(listItem => listItem.id === itemId)) ||
		false
	);
}
/**
 * When clicking 'Show Original' from context menu or header action menu,
 * Return message id in case of `message` view else
 * return recent email's id in case of `conversation` view.
 */
export function getIdForShowOriginalMime(message, hasMetadata = true) {
	return (
		message &&
		(message.__typename === 'MessageInfo'
			? message.id
			: hasMetadata
			? get(message, 'messagesMetaData.0.id')
			: get(last(message.messages), 'id'))
	);
}

export const getRecipients = (message, fields) =>
	(fields || ['to', 'cc', 'bcc'])
		.reduce((acc, field) => {
			const recipients = message[field];
			let address;
			if (recipients) {
				recipients.forEach(recipient => {
					if (recipient) {
						address =
							typeof recipient === 'string'
								? recipient
								: getPrimaryEmail(recipient) || recipient.address || '';
						if (address && isValidEmail(address)) {
							acc.push(address.toLowerCase());
						}
					}
				});
			}
			return acc;
		}, [])
		.sort();

/**
 * The below function is common to the  composer component and viewer component .
 * In composer component the state of the inlineAttachments and attachments is been passed to this function.
 * In viewer component the props of the inlineAttachments and attachments is been passed to this function.
 * Function reads the inlineAttachments, attachments, and resturns a array of attachments that need to be displayed as
 * normal attachments . Few of the inlineAttachments that cant be displayed(not supported yet) are to be displayed as
 * normal attachments for the viewing purpose
 * @param {Object} message
 * @param {Array} message.inlineAttachments array of inlineAttachments
 * @param {Array} message.attachments array of Attachments
 * @param {Object[]} [message.invitations] invitations from a meeting
 * @param {Boolean} isCalendarEnabled flag if calendar is enabled for the user
 * @returns {Array} attachments that can be displayed
 */

export const handleAttachmentsToBeDisplayed = (
	{ inlineAttachments, attachments, invitations } = {},
	isCalendarEnabled
) => {
	//TODO : adding the following logic for messages that are encrypted (smime)
	const attachmentToShow = [];

	attachments &&
		attachments.length > 0 &&
		attachments.forEach(item => {
			if (isAttachmentDisposition(item)) {
				if (invitations && isCalendarEnabled && /\.ics$/.test(item.filename)) {
					return;
				}
				attachmentToShow.push(item);
			}
		});

	inlineAttachments &&
		inlineAttachments.length > 0 &&
		inlineAttachments.forEach(item => {
			if (isInline(item) && !isImage(item)) {
				attachmentToShow.push(item);
			}
		});
	return attachmentToShow;
};

/** Function to convert inlineAttachments to external attachments,
 * use case: when user switch from html to plain text and
 * if it converts back to html it persit as external attachments
 */
export const convertInlineToExternalAttachment = (inlineAttachments = {}) => {
	const attachmentToShow = [];

	inlineAttachments &&
		inlineAttachments.length > 0 &&
		inlineAttachments.forEach(item => {
			const attachmentItem = {
				contentDisposition: 'attachment',
				contentType: item.contentType,
				filename: item.filename,
				size: item.size,
				part: item.part
			};
			attachmentToShow.push(attachmentItem);
		});
	return attachmentToShow;
};

export function separateEmailAddresses(message) {
	const grouped = groupBy(message.emailAddresses, 'type');
	return {
		from: grouped.f || message.from || [],
		to: grouped.t || message.to,
		sender: grouped.s || message.sender,
		cc: grouped.c || message.cc,
		bcc: grouped.b || message.bcc,
		replyTo: grouped.r || message.replyTo,
		unknown: grouped.undefined
	};
}

/**
 * Helper function to prepare and return url for new email based on the given screen -size, desktop or mobile.
 * @param {String} urlSlug email slug string from config
 * @param {Boolean} matchesScreenMd Media query boolean value to define screen size, desktop or mobile
 */
export function getNewMailUrl(urlSlug, matchesScreenMd) {
	return matchesScreenMd ? `/${urlSlug}/Drafts/message/new?full=true` : `/${urlSlug}/new`;
}

export function previewMode(message, zimbraPrefMessageViewHtmlPreferred) {
	return zimbraPrefMessageViewHtmlPreferred
		? !message?.attributes?.isEncrypted &&
		  !message?.attributes?.isSigned &&
		  message?.mimeParts?.length &&
		  message.mimeParts[0].contentType === 'text/plain'
			? TEXT_MODE
			: HTML_MODE
		: TEXT_MODE;
}

/**
 * Function to retrive messages as EML from given array of Messages/Conversations
 * @param {*} items Array of message or conversation
 * @param {*} conversation conversation of given messages which passed as items parameter
 * @returns array of message as EML content
 */
export function extractMessagesAsEML(items, conversation = null) {
	return items.reduce((acc, message) => {
		// Check the current item is conversation or not.
		if (message.messagesMetaData) {
			return acc.concat(extractMessagesAsEML(message.messagesMetaData, message));
		}
		// Check that current message is not Draft and local message
		if (!isDraft(message, MailFolderView.message) && !message.local) {
			acc.push({
				...message,
				size: message.size ? message.size : 0,
				name: message?.subject || conversation?.subject,
				contentType: 'message/rfc822'
			});
		}
		return acc;
	}, []);
}
