import { Icon, Spinner } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import style from './style';
import { ENFORCE_HISTORY, COMMON_PASSWORD } from './constants';

export const RuleItem = ({ rule, value, check, checkingRule }) => {
	return (
		<li>
			{rule === COMMON_PASSWORD || rule === ENFORCE_HISTORY ? (
				checkingRule ? (
					<Spinner />
				) : (
					<Icon
						name={check ? 'check' : 'close'}
						{...(check && { class: style.check })}
						fields={{ value }}
					/>
				)
			) : (
				<Icon name={check ? 'check' : 'close'} {...(check && { class: style.check })} />
			)}

			<Text id={`loginScreen.passwordRules.${rule}`} fields={{ value }} />
		</li>
	);
};
