import get from 'lodash-es/get';
import omitBy from 'lodash-es/omitBy';

import { normalize, entities } from '@zimbra/api-client';

import Search from '../graphql/queries/search/search.graphql';
import SearchFragment from '../graphql/fragments/search.graphql';
import GetFolder from '../graphql/queries/folders/get-folder.graphql';
import GetPreferences from '../graphql/queries/preferences/preferences.graphql';
import {
	getAllFolderVariablesInSearch,
	getSearchInFolderVariables
} from '../graphql/utils/graphql-optimistic';

const { MessageInfo } = entities;

const normalizeMessage = normalize(MessageInfo);

export const DEFAULT_SORT_ORDER = 'dateDesc';

export function itemsForKeySeparated(notification, key) {
	const modifiedItems = get(notification, `modified.${key}`, []);
	const createdItems = get(notification, `created.${key}`, []);
	const deletedItems = get(notification, `deleted`, {});
	return { modifiedItems, createdItems, deletedItems };
}

export const EMPTY_EMAIL_ADDRESS = {
	address: null,
	name: null,
	displayName: null,
	type: null,
	__typename: 'EmailAddress'
};

/**
 * Extract the attributes (non-nested object types) from a notification
 * data object to dynamically constructing a fragment.
 */
export function attributeKeys(data) {
	return Object.keys(omitBy(data, v => typeof v === 'object'));
}

// Fragment names are required to be unique
export function generateFragmentName(name, id = '') {
	// Dashes and colons are illegal GraphQL syntax - remove them from the identifier
	return `${name}${id.replace(/-|:/g, '')}${new Date().valueOf()}`;
}

/**
 * Based on the current url from router, reads the router props stored in the redux store.
 * Returns the details about the current folderName that is been view
 *
 * @param {Object} store the redux store
 */
export function getCurrentFolderNameFromUrl(store) {
	const urlDetailsInStore = store.getState().url;
	return get(urlDetailsInStore, 'routeProps.folderName') || false;
}

/**
 * Based on the current url from router, reads the router props stored in the redux store.
 * Returns the details if currently in SearchView
 *
 * @param {Object} store the redux store
 */
export function getIsSearchView(store) {
	const urlDetailsInStore = store.getState().url;
	return get(urlDetailsInStore, 'routeProps.isSearchView') || false;
}

/**
 * Based on the current url from router, reads the router props stored in the redux store.
 * Returns the details about the current active vertical and the path.
 *
 * @param {Object} store the redux store
 */
export function getCurrentUrlDetails(store) {
	const urlDetailsInStore = store.getState().url;
	const view = urlDetailsInStore && urlDetailsInStore.view;

	if (view) {
		switch (view) {
			case 'email':
				return {
					isEmailActive: true,
					path: urlDetailsInStore.routeProps.folderName || 'Inbox'
				};

			case 'contacts':
				return {
					isContactsActive: true
				};

			case 'calendar':
				return {
					isCalendarsActive: true
				};

			case 'briefcase':
				return {
					isBriefcaseActive: true
				};

			default:
				return {};
		}
	}
}

/**
 * Find the node in the specified tree, based on the evaluatorFn provided
 *
 * @param {Object} tree the tree which the node should be searched in
 * @param {Function} evaluatorFn the evaluator function that validates the tree node
 * @returns {Object|undefined} the node found in the tree
 */
function _findNodeInTree(tree, evaluatorFn) {
	if (!tree) return;

	if (evaluatorFn(tree)) {
		return tree;
	}

	if (Array.isArray(tree.folders)) {
		for (let idx = 0, len = tree.folders.length; idx < len; idx++) {
			const folder = tree.folders[idx];
			const found = _findNodeInTree(folder, evaluatorFn);
			if (found) return found;
		}
	}

	if (Array.isArray(tree.linkedFolders)) {
		for (let idx = 0, len = tree.linkedFolders.length; idx < len; idx++) {
			const folder = tree.linkedFolders[idx];
			const found = _findNodeInTree(folder, evaluatorFn);
			if (found) return found;
		}
	}
}

/**
 * Reads the folders query from the cache and returns the data.
 * @param {Object} client instance of apollo client
 */
export function getFolders(client, view) {
	try {
		return client.readQuery({
			query: GetFolder,
			variables: {
				view: view || null
			}
		});
	} catch (ex) {
		console.error('Unable to read the folders query for notifications', ex);
	}
}

/**
 * Finds the folder from the folder tree in the cache and returns the data.
 * @param {Object} client instance of apollo client
 * @param {String} folderPath path of the folder
 */
export function getFolderDetails(client, folderPath, view) {
	const rootFolder = get(getFolders(client, view), 'getFolder.folders.0');
	return _findNodeInTree(rootFolder, folder => folder.absFolderPath === `/${folderPath}`);
}

/**
 * Executes the preferences query and returns the results
 * @param {Object} client instance of apollo client
 */
function getUserPreferences(client) {
	try {
		return client.readQuery({
			query: GetPreferences
		});
	} catch (ex) {
		console.error('Unable to read preferences query', ex);
	}
}
/**
 * Reads the zimbraPrefGroupMailBy from preferences query
 * @param {Object} client instance of apollo client
 */
export function getMailGroupByPreference(client) {
	const userPrefs = getUserPreferences(client);
	return get(userPrefs, 'getPreferences.zimbraPrefGroupMailBy');
}

/**
 * Reads the zimbraPrefSortOrder preference and identifies the sort order for the current folder provided.
 *
 * @param {Object} client instance of apollo client
 * @param {String} folderId id of the folder to read the sort order of
 */
export function getFolderSortOrder(client, folderId) {
	const userPreferences = getUserPreferences(client);
	const sortOrderPreference = get(userPreferences, 'getPreferences.zimbraPrefSortOrder') || '';
	let folderSortOrder = DEFAULT_SORT_ORDER;

	// get the sort order for the current folder
	const splittedPreference = sortOrderPreference.split(',');

	if (splittedPreference.length > 0) {
		const folderPref = splittedPreference.find(pref => pref.startsWith(`${folderId}:`));

		if (folderPref) {
			[, folderSortOrder] = folderPref.split(':');
		}
	}

	return folderSortOrder;
}

/**
 * Traverses through the specified messages and groups them by conversations in a map.
 *
 * @param {Array<Object>} messages array of messages
 */
export function groupMessagesByConversation(messages) {
	return messages.reduce((map, obj) => {
		if (!map[obj.cid]) {
			map[obj.cid] = [];
		}

		map[obj.cid].push(normalizeMessage(obj));

		return map;
	}, {});
}

/**
 * Reads the query variables for the search from the cache, and read the query based
 * on the variables and returns the results.
 *
 * @param {Object} client instance of apollo client
 * @param {String} folderPath path of the folder to read the query of
 * @param {Object} variablesOverrides the variables that need to be overridden for the query
 */
export function readSearchQuery(client, folderPath, variablesOverrides) {
	// extract the query variables from the cache for the query for the active folder
	const queryVars = {
		...getSearchInFolderVariables(client, folderPath),
		...(variablesOverrides && variablesOverrides)
	};

	try {
		return {
			queryData: client.readQuery({
				query: Search,
				variables: queryVars
			}),
			queryVars
		};
	} catch (ex) {
		console.error('Unable to read the query for notifications', queryVars, ex);
		return {};
	}
}

export function readSearchQueries(client, folderPath, variablesOverrides) {
	// extract all the query variables from the active folder's queries
	const cacheFolderVariables = getAllFolderVariablesInSearch(client, folderPath);

	const queriesData = [];
	const queriesVars = [];

	cacheFolderVariables.forEach(cacheFolderVariable => {
		const queryVars = {
			...cacheFolderVariable,
			...(variablesOverrides && variablesOverrides)
		};
		try {
			const data = client.readQuery({
				query: Search,
				variables: queryVars
			});
			if (data !== null) {
				queriesData.push(data);
				queriesVars.push(queryVars);
			}
		} catch (ex) {
			console.warn('Unable to read the query for notifications', queryVars, ex);
		}
	});

	return {
		queriesData,
		queriesVars
	};
}

export function writeSearchQuery(cache, data, variables) {
	try {
		cache.writeQuery({
			query: Search,
			variables,
			data
		});
	} catch (ex) {
		console.error('Exception occurred while writing query', ex, variables);
	}
}

export function readConversationFragment(client, conversationId) {
	try {
		return client.readFragment({
			id: `Conversation:${conversationId}`,
			fragment: SearchFragment,
			fragmentName: 'searchConversationFields'
		});
	} catch (ex) {
		console.error('Unable to read conversation fragment', conversationId);
	}
}
