import cx from 'classnames';
import ExternalHeaderItem from '../external-header-item';
import ExternalHeaderDropdown from '../external-header-dropdown';
import clientConfiguration from '../../enhancers/client-config';
import s from './style.less';

const getItemKey = name => name.replace(/ /g, '_').toLowerCase();

export default clientConfiguration('nav.home,nav.left,nav.right')(
	({ className, home = {}, left = [], right = [], ...rest }) => (
		<div {...rest} className={cx(s.externalHeader, className)}>
			<div className="dib">
				<ExternalHeaderItem href={home.href}>
					{home.logo && (
						<div className={s.externalLogo}>
							<img src={home.logo} alt={home.alt} />
						</div>
					)}
					{home.name}
				</ExternalHeaderItem>
				{left.map(({ name, href, subnav }) =>
					subnav && subnav.length ? (
						<ExternalHeaderDropdown title={name} items={subnav} key={getItemKey(name)} />
					) : (
						<ExternalHeaderItem href={href} key={getItemKey(name)}>
							{name}
						</ExternalHeaderItem>
					)
				)}
			</div>

			<div className={s.externalHeaderRight}>
				{right.map(({ name, href, subnav }) =>
					subnav && subnav.length ? (
						<ExternalHeaderDropdown title={name} items={subnav} key={getItemKey(name)} />
					) : (
						<ExternalHeaderItem href={href} key={getItemKey(name)}>
							{name}
						</ExternalHeaderItem>
					)
				)}
			</div>
		</div>
	)
);
