import cx from 'classnames';
import s from './style';
import { useCallback } from 'preact/hooks';

let inputRef;

export default function ChoiceInput({
	inlineStyle = '',
	type = 'checkbox',
	containerClass = '',
	class: elementClass,
	clearFocusAfterChange,
	onChange,
	...props
}) {
	const handleOnChange = useCallback(
		e => {
			if (clearFocusAfterChange && inputRef) {
				// We need to blur the choice input when user checks the choice input,
				// then only then only keyboard shortcuts will work
				inputRef.blur();
			}
			onChange && onChange(e);
		},
		[clearFocusAfterChange, onChange]
	);

	return (
		<span
			class={cx(s.choiceInputContainer, type === 'radio' && 'radioInput', containerClass)}
			style={inlineStyle}
		>
			<input
				{...props}
				onChange={handleOnChange}
				{...(clearFocusAfterChange && { ref: ref => (inputRef = ref) })}
				type={type}
				class={cx(type === 'radio' ? s.radio : s.checkbox, elementClass)}
			/>
			<i />
		</span>
	);
}
