import { compose } from 'recompose';
import withSearch from '../../graphql-decorators/search';
import withSearchGal from '../../graphql-decorators/search/search-gal';
import get from 'lodash-es/get';
import { CONTACTS } from '../../constants/folders';

/* `withGetProfilePhoto`: First argument is for withSearch skip
 * and 2nd argument is for withSearchGal skip value.
 */
export default function withGetProfilePhoto({ withSearchOptions = {}, withSearchGalOptions = {} }) {
	return compose(
		withSearch({
			options: ({ address, grant: { address: grantAddress } = {} }) => ({
				fetchPolicy: 'cache-first',
				variables: {
					query: `in:"${CONTACTS}", contact:${grantAddress || address}`,
					types: 'contact',
					limit: 1
				}
			}),
			props: ({ data: { search } }) => ({
				contactAlreadyExists: !!get(search, 'contacts'),
				contactWithImage: get(search, 'contacts.0')
			}),
			...withSearchOptions
		}),
		withSearchGal({
			options: ({ address, grant: { address: grantAddress } = {} }) => ({
				fetchPolicy: 'cache-first',
				variables: {
					name:
						get(grantAddress || address, 'address') ||
						get(grantAddress || address, 'email') ||
						grantAddress ||
						address,
					types: 'contact',
					limit: 1
				}
			}),
			props: ({ data: { searchGal } }) => ({
				thumbnailPhoto: get(searchGal, 'contacts.0.attributes.thumbnailPhoto')
			}),
			...withSearchGalOptions
		})
	);
}
