import { hasFlag } from '../lib/util';
import { SEND_AS, SEND_ON_BEHALF } from '../constants/rights';
import get from 'lodash-es/get';
import { Text } from 'preact-i18n';
import { faultCode } from '../utils/errors';

export const isAutoSendDraftMessage = message => hasFlag(message, 'draft') && message.autoSendTime;

// Is this a conversation that isn't "sent", but contains a message that
// is scheduled for sending?
export const isAutoSendDraftConversation = conversation => {
	const messages = get(conversation, 'messages') || get(conversation, 'messagesMetaData');
	return (
		hasFlag(conversation, 'draft') && messages && messages.some(msg => isAutoSendDraftMessage(msg))
	);
};

export const isDelegateType = type => type === SEND_AS || type === SEND_ON_BEHALF;

export const getAccountNameForHeader = ({ accounts, fromAddress, fromType }) => {
	if (isDelegateType(fromType)) {
		return fromAddress;
	}
	const primaryAccount = accounts.find(({ isPrimaryAccount }) => isPrimaryAccount);
	return primaryAccount.emailAddress;
};

export const urlForMessage = (id, urlSlugs, folderName) =>
	`/${urlSlugs.email}/${encodeURIComponent(folderName)}/${urlSlugs.message}/${id}`;

export const notifyMailError = (err, notifyAction, field, genericError) => {
	const errorCode = faultCode(err);

	return notifyAction({
		message: (
			<Text
				id={`faults.${errorCode}`}
				fields={{
					...(field && { fieldName: field })
				}}
			>
				{genericError}
			</Text>
		),
		failure: true
	});
};
