import { Component } from 'preact';
import { Text } from 'preact-i18n';
import linkState from 'linkstate';
import { Button } from '@zimbra/blocks';
import ModalDialog from '../../../modal-dialog';
import TextInput from '../../../text-input';
import { errorMessage } from '../../../../utils/errors';

import style from './style';

export default class AddAppModal extends Component {
	handleAddApp = e => {
		e.preventDefault();

		const { appName } = this.state;

		this.setState({
			errMsg: appName ? null : (
				<Text id="settings.accounts.editAccount.twoFactorAuth.appSpecificPasswords.errors.invalidName" />
			)
		});

		if (appName) {
			this.props
				.createApp(appName)
				.then(({ data: { createAppSpecificPassword: { password } } = {} }) => {
					this.setState({
						generatedAppPassword: password
					});
				})
				.catch(err => {
					this.setState({
						errMsg: errorMessage(err).replace('Fault error: system failure: ', '')
					});
				});
		}
	};

	render({ closeModal }, { appName, generatedAppPassword, errMsg }) {
		return (
			<ModalDialog
				title={
					<Text
						id={`settings.accounts.editAccount.twoFactorAuth.appSpecificPasswords.${
							generatedAppPassword ? 'generatePasscodeScreen' : 'addPasscodeScreen'
						}.title`}
					/>
				}
				class={style.addAppModal}
				contentClass={style.appModalContent}
				footer={false}
				onClose={closeModal}
				error={errMsg}
			>
				<p>
					<Text
						id={`settings.accounts.editAccount.twoFactorAuth.appSpecificPasswords.${
							generatedAppPassword ? 'generatePasscodeScreen' : 'addPasscodeScreen'
						}.desc`}
					/>
				</p>
				{generatedAppPassword ? (
					[
						<p>
							<strong>{generatedAppPassword}</strong>
						</p>,
						<Button class={style.btn} styleType="primary" brand="primary" onClick={closeModal}>
							<Text id="buttons.done" />
						</Button>
					]
				) : (
					<form onSubmit={this.handleAddApp}>
						<TextInput
							class={style.appName}
							value={appName}
							placeholder={
								<Text id="settings.accounts.editAccount.twoFactorAuth.appSpecificPasswords.addPasscodeScreen.input" />
							}
							wide
							autofocus
							onInput={linkState(this, 'appName')}
						/>
						<Button
							class={style.btn}
							styleType="primary"
							brand="primary"
							disabled={!appName}
							onClick={this.handleAddApp}
						>
							<Text id="buttons.next" />
						</Button>
					</form>
				)}
			</ModalDialog>
		);
	}
}
