import partition from 'lodash-es/partition';
import { withAriaId } from '@zimbra/a11y';
import { Text, MarkupText, withText } from 'preact-i18n';
import { ChoiceInput, Icon } from '@zimbra/blocks';
import { PureComponent } from 'preact/compat';
import SmartList from '../smart-list';
import ActionMenu, { DropDownWrapper } from '../action-menu';
import ActionMenuGroup from '../action-menu-group';
import ActionMenuItem from '../action-menu-item';
import cx from 'classnames';
import { DIALOGS_RESTORE, DIALOGS_EXPORT, DIALOGS_IMPORT, DIALOGS_DELETE_LIST } from './constants';
import { callWith, getId } from '../../lib/util';
import { getTagsFromSelection } from './../tags/util';
import { setDataTransferJSON } from '@zimbra/util/src/data-transfer-manager';
import {
	displayAddress,
	getName,
	getPrimaryPhone,
	getPrimaryEmail,
	isInlineContact,
	getPhoneticDispName,
	getPhoneticFirstName,
	getPhoneticLastName
} from '../../utils/contacts';
import withMediaQuery from '../../enhancers/with-media-query';
import { minWidth, screenMd } from '../../constants/breakpoints';
import ContextMenu from '../context-menu';
import { ContactListItemContextMenu } from '../context-menus';
import ZimletSlot from '../zimlet-slot';
import style from './style';
import { USER_FOLDER_IDS, ARROW_DIRECTION_NAMES, ASCENDING, DESCENDING } from '../../constants';
import escape from 'lodash-es/escape';
import { isLocaleJapan } from '../../utils/locale';

const SORT_BY_FIELDS_MAP = {
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',
	EMAIL: 'email',
	DEFAULT: 'displayName'
};

export default class ContactList extends PureComponent {
	state = {
		sort: SORT_BY_FIELDS_MAP.DEFAULT,
		sortDirection: ASCENDING
	};

	doSort = (a, b) => {
		const isJapanLocale = isLocaleJapan(this.props.zimbraPrefLocale);

		const field = this.state.sort,
			dispNameOfA = (isJapanLocale && getPhoneticDispName(a)) || displayAddress(a),
			dispNameOfB = (isJapanLocale && getPhoneticDispName(b)) || displayAddress(b),
			phoneticFNA = getPhoneticFirstName(a),
			phoneticLNA = getPhoneticLastName(a),
			phoneticFNB = getPhoneticFirstName(b),
			phoneticLNB = getPhoneticLastName(b);

		const valueOfA =
				field === SORT_BY_FIELDS_MAP.DEFAULT || !a.attributes[field]
					? dispNameOfA
					: (isJapanLocale &&
							((field === SORT_BY_FIELDS_MAP.FIRST_NAME && phoneticFNA) ||
								(field === SORT_BY_FIELDS_MAP.LAST_NAME && phoneticLNA))) ||
					  a.attributes[field],
			valueOfB =
				field === SORT_BY_FIELDS_MAP.DEFAULT || !b.attributes[field]
					? dispNameOfB
					: (isJapanLocale &&
							((field === SORT_BY_FIELDS_MAP.FIRST_NAME && phoneticFNB) ||
								(field === SORT_BY_FIELDS_MAP.LAST_NAME && phoneticLNB))) ||
					  b.attributes[field];

		if (valueOfA && valueOfB) {
			const comparedResult = valueOfA.localeCompare(valueOfB, undefined, { sensitivity: 'base' });

			if (comparedResult === 0 && field !== SORT_BY_FIELDS_MAP.DEFAULT) {
				if (
					isJapanLocale &&
					[SORT_BY_FIELDS_MAP.FIRST_NAME, SORT_BY_FIELDS_MAP.LAST_NAME].includes(field)
				) {
					// In `Japan` locale, if `firstName` is same for both contacts, `lastName` is considered for comparison.
					// Same criteria applies in case of `lastName` as well.
					const reverseField =
						field === SORT_BY_FIELDS_MAP.FIRST_NAME
							? SORT_BY_FIELDS_MAP.LAST_NAME
							: SORT_BY_FIELDS_MAP.FIRST_NAME;
					const newValueOfA =
						(reverseField === SORT_BY_FIELDS_MAP.FIRST_NAME && phoneticFNA) ||
						(reverseField === SORT_BY_FIELDS_MAP.LAST_NAME && phoneticLNA) ||
						a.attributes[reverseField];
					const newValueOfB =
						(reverseField === SORT_BY_FIELDS_MAP.FIRST_NAME && phoneticFNB) ||
						(reverseField === SORT_BY_FIELDS_MAP.LAST_NAME && phoneticLNB) ||
						b.attributes[reverseField];

					return newValueOfA.localeCompare(newValueOfB, undefined, { sensitivity: 'base' });
				}

				// When valueOfA and valueOfB are same and it's not default sort, we'll sort based on their display name.
				return dispNameOfA.localeCompare(dispNameOfB, undefined, { sensitivity: 'base' });
			}

			return comparedResult;
		}

		if (valueOfA) return 1;
		if (valueOfB) return -1;
	};

	setSortField = sort => {
		this.setState({ sort });
	};

	reverseSortDirection = () => {
		this.setState(({ sortDirection }) => ({
			sortDirection: sortDirection === DESCENDING ? ASCENDING : DESCENDING
		}));
	};

	renderHeader = props => {
		const {
			header,
			showDialog,
			onCopyContacts,
			onPrintContacts,
			selected,
			isGalFolder,
			showDeleteListButton,
			disablePrint,
			contacts
		} = this.props;
		if (header === false || this.props.ContactListHeader === false) {
			return null;
		}
		const Child = this.props.ContactListHeader || ContactListHeader;
		return (
			<Child
				{...props}
				sort={this.state.sort}
				sortDirection={this.state.sortDirection}
				setSortField={this.setSortField}
				reverseSortDirection={this.reverseSortDirection}
				showDialog={showDialog}
				showDeleteListButton={showDeleteListButton}
				onCopyContacts={onCopyContacts}
				onPrintContacts={onPrintContacts}
				selectedCount={selected.length}
				isGalFolder={isGalFolder}
				disablePrint={disablePrint}
				isEmptyList={!contacts || contacts.length === 0}
			/>
		);
	};

	renderContactListItem = ({ item, ...props }) => {
		const Child = this.props.ContactListItem || ContactListItem;
		const {
			renderBadge,
			onAssignToLists,
			onRemoveFromList,
			onDeleteContact,
			onEditDetails,
			onShareContact,
			onCopyContacts,
			onTag,
			onTagRemove,
			onHandleRouteToTag,
			showPermanentlyDelete,
			isEditable,
			isDeletable,
			isAssignable,
			onContextMenuMount,
			selected,
			contacts,
			onPrintContacts,
			disablePrint
		} = this.props;

		if (renderBadge) {
			props.renderBadge = renderBadge;
		}

		const menu = (
			<ContactListItemContextMenu
				onAssignToLists={onAssignToLists}
				onRemoveFromList={onRemoveFromList}
				onDeleteContact={onDeleteContact}
				onEditDetails={onEditDetails}
				onShareContact={onShareContact}
				onCopyContacts={onCopyContacts}
				onTag={
					onTag &&
					callWith(onTag, {
						existingTags: getTagsFromSelection(selected, contacts)
					})
				}
				onTagRemove={onTagRemove}
				onHandleRouteToTag={onHandleRouteToTag}
				showPermanentlyDelete={showPermanentlyDelete}
				isEditable={isEditable}
				isDeletable={isDeletable}
				isAssignable={isAssignable}
				selectedCount={selected.length}
				onPrintContacts={onPrintContacts}
				onMount={callWith(onContextMenuMount, {
					selected,
					selectedId: getId(item),
					contact: item
				})}
				disablePrint={disablePrint}
			/>
		);

		return (
			<ContextMenu menu={menu}>
				<Child {...props} contact={item} />{' '}
			</ContextMenu>
		);
	};

	render(
		{
			contacts,
			selected,
			onSelectionChange,
			class: className,
			isTrashFolder,
			canEditSharedItem,
			showLoaderBar,
			setRef
		},
		{ sort, sortDirection }
	) {
		let sortableContacts = contacts.slice(); // Create new contacts container (prop 'contact' should not be modified).
		if (sort || sortDirection) {
			if (sort !== SORT_BY_FIELDS_MAP.DEFAULT) {
				// We first need to distinguish between contacts not having values for selected `sort` attribute key.
				const segregatedContacts = partition(
					sortableContacts,
					({ attributes }) => !!attributes[sort]
				);

				// Then, we need to sort them seperately.
				sortableContacts = [
					...segregatedContacts[1].sort(this.doSort), // Contacts not having values for selected `sort` attribute key.
					...segregatedContacts[0].sort(this.doSort) // Contacts having values for selected `sort` attribute key.
				];
			} else {
				sortableContacts.sort(this.doSort);
			}

			sortDirection === DESCENDING && sortableContacts.reverse();
		}
		setRef.current = sortableContacts;

		return (
			<SmartList
				class={className}
				items={sortableContacts}
				selected={selected}
				onSelectionChange={onSelectionChange}
				renderItem={this.renderContactListItem}
				header={this.renderHeader}
				rowHeight={72}
				isTrashFolder={isTrashFolder}
				canEditSharedItem={canEditSharedItem}
				virtualized
				showLoaderBar={showLoaderBar}
			/>
		);
	}
}

@withMediaQuery(minWidth(screenMd), 'matchesScreenMd')
@withText({
	aToZ: 'mail.listHeaderMenu.toolTip.aToZ',
	zToA: 'mail.listHeaderMenu.toolTip.zToA'
})
class ContactListHeader extends PureComponent {
	handlePopoverToggle = active => {
		this.setState({ active });
	};

	closePopover = () => {
		this.setState({ active: false });
	};

	showRestoreContacts = () => {
		this.closePopover();
		this.props.showDialog(DIALOGS_RESTORE);
	};

	handleDeleteList = () => {
		this.closePopover();
		this.props.showDialog(DIALOGS_DELETE_LIST);
	};

	showImportContacts = () => {
		this.closePopover();
		this.props.showDialog(DIALOGS_IMPORT);
	};

	showExportContacts = () => {
		this.closePopover();
		this.props.showDialog(DIALOGS_EXPORT);
	};

	render({
		matchesScreenMd,
		actions,
		allSelected,
		sort,
		sortDirection,
		setSortField,
		reverseSortDirection,
		showDeleteListButton,
		onCopyContacts,
		onPrintContacts,
		selectedCount,
		isGalFolder,
		disablePrint,
		aToZ,
		zToA,
		isEmptyList
	}) {
		const sortLabel = <Text id={`contacts.headerDropdown.${sort || 'sortBy'}`} />;

		const printActionItem = (
			<ActionMenuItem onClick={onPrintContacts}>
				<Text id="buttons.print" />
			</ActionMenuItem>
		);
		const contactListZimletSlot = <ZimletSlot name="action-menu-contact-list" />;
		const restoreContactZimletSlot = <ZimletSlot name="action-menu-restore-contacts" />;

		const showPrint = matchesScreenMd && !disablePrint;
		return (
			<header className={cx(style.toolbar, style.leftPanelHeader)}>
				<div class={cx(style.row, style.sort)}>
					<ChoiceInput
						checked={allSelected}
						onChange={actions.toggleSelectAll}
						disabled={isEmptyList}
					/>
					<div>
						<button
							class={style.arrowButton}
							onClick={reverseSortDirection}
							title={sortDirection === DESCENDING ? zToA : aToZ}
						>
							<Icon size="sm" name={ARROW_DIRECTION_NAMES[sortDirection]} />
						</button>
						<ActionMenu label={sortLabel} anchor="end">
							<DropDownWrapper>
								<ActionMenuGroup>
									{Object.values(SORT_BY_FIELDS_MAP).map(type => (
										<ActionMenuItem
											icon={sort === type && 'check'}
											onClick={callWith(setSortField, type)}
											key={type}
										>
											<Text id={`contacts.headerDropdown.${type}`} />
										</ActionMenuItem>
									))}
								</ActionMenuGroup>

								{!isGalFolder && [
									<ActionMenuGroup>
										<ActionMenuItem onClick={this.showImportContacts}>
											<Text id="contacts.headerDropdown.import" />
										</ActionMenuItem>
										<ActionMenuItem onClick={this.showExportContacts}>
											<Text id="contacts.headerDropdown.export" />
										</ActionMenuItem>
									</ActionMenuGroup>,
									<ActionMenuGroup>
										{contactListZimletSlot}
										{restoreContactZimletSlot}
										{showPrint && printActionItem}
									</ActionMenuGroup>
								]}

								{isGalFolder && matchesScreenMd && (
									<ActionMenuGroup>{printActionItem}</ActionMenuGroup>
								)}

								{onCopyContacts && selectedCount > 0 && (
									<ActionMenuGroup>
										<ActionMenuItem onClick={onCopyContacts}>
											{/* Showing same label for single and multiple contacts */}
											<Text id="contacts.actions.copyToContacts" />
										</ActionMenuItem>
									</ActionMenuGroup>
								)}

								{showDeleteListButton && (
									<ActionMenuGroup>
										<ActionMenuItem onClick={this.handleDeleteList}>
											<Text id="contacts.headerDropdown.deleteList" />
										</ActionMenuItem>
									</ActionMenuGroup>
								)}
							</DropDownWrapper>
						</ActionMenu>
					</div>
				</div>
			</header>
		);
	}
}

@withAriaId('contact-li')
class ContactListItem extends PureComponent {
	handleClick = event => {
		const { onClick, contact } = this.props;
		onClick({ contact, event });
	};

	handleDragStart = e => {
		const { contact } = this.props;

		if (!this.props.selected) this.handleClick(e);

		setDataTransferJSON(e, {
			data: {
				type: 'contact',
				id: contact.id,
				contact
			},
			itemCount: this.context.store.getState().contacts.selected.length
		});
	};

	toggle = event => {
		const { onClick } = this.props;
		onClick({ action: 'toggle', event });
	};

	componentDidUpdate(prevProps) {
		const { selected, allSelected } = this.props,
			el = this.base;
		if (!allSelected && selected && !prevProps.selected) {
			el.focus();
		}
	}

	render({ contact, a11yId, selected, renderBadge, isTrashFolder, canEditSharedItem }) {
		const tabindex = selected ? '0' : '-1';
		const attrs = contact.attributes || {};
		const isDeleted = contact.folderId === USER_FOLDER_IDS.TRASH.toString();
		const contactName = getName(attrs) || <Text id="contacts.list.noName" />;
		const primaryEmail = getPrimaryEmail(contact);
		const primaryContact = getPrimaryPhone(contact);
		const isInline = isInlineContact(contact);

		return (
			<div
				class={cx(style.contact, selected && style.selected)}
				draggable={!canEditSharedItem}
				onDragStart={this.handleDragStart}
				tabIndex={tabindex}
			>
				<span>
					<label for={a11yId} />
					<ChoiceInput
						id={a11yId}
						readOnly
						checked={!!selected}
						onChange={this.toggle}
						tabindex={tabindex}
					/>
				</span>
				<div onClick={this.handleClick}>
					<h4>
						{isDeleted && !isTrashFolder && (
							<MarkupText
								id="contacts.list.deleted"
								fields={{
									contactNameMarkup: `<span class=${style.trashedContact}>${escape(
										contactName
									)}</span>`,
									deletedClass: cx(style.deleted, style.list)
								}}
							/>
						)}
						{isInline && (
							<span class={style.danger}>
								<Text id="contacts.list.notInYourContacts" />
							</span>
						)}
						{(!isDeleted || isTrashFolder) && !isInline && <span> {contactName} </span>}
					</h4>

					{primaryEmail && <h5>{primaryEmail}</h5>}
					{primaryContact && <h5>{primaryContact}</h5>}

					{renderBadge && renderBadge(contact)}
				</div>
			</div>
		);
	}
}
