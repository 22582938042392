// Local Store specific constants
module.exports = {
	IS_DEVELOPMENT: process.env.ELECTRON_ENV === 'development',
	IS_WIN: process.platform === 'win32',
	IS_MAC: process.platform === 'darwin',
	DB_PATH_SEPERATOR: '/',
	LOCAL_FOLDER_NAME: 'zd-localfolders',
	LOG_FOLDER: 'logs',
	PST_EXTRACTED_LOG_FILE: 'extraction-result.log',
	REMOTE_ROOT_FOLDER: 11,
	LOCAL_FOLDER_IDS: {
		ROOT: 1,
		INBOX: 2,
		DRAFTS: 3,
		SENT: 4,
		JUNK: 5,
		TRASH: 6
	},
	REMOTE_FOLDER_IDS: {
		ROOT: 1,
		INBOX: 2,
		TRASH: 3,
		JUNK: 4,
		SENT: 5,
		DRAFTS: 6,
		CONTACTS: 7,
		CALENDAR: 10,
		EMAILED_CONTACTS: 13,
		CHAT: 14,
		TASKS: 15,
		BRIEFCASE: 16
	},
	OUTBOX: 'Outbox',
	DRAFTS: 'Drafts',
	SENT: 'Sent',
	INBOX: 'Inbox',
	TRASH: 'Trash',
	ROOT: 'LOCAL_FOLDER_ROOT',
	JUNK: 'Junk',
	FS_FOLDERS: {
		mailLocal: '/zd-localfolders',
		mailTrash: '/zd-localfolders/Trash',
		remoteTrash: '/Trash'
	},
	LOCAL_MAIL_DEFAULT_FOLDERS: ['Inbox', 'Drafts', 'Sent', 'Junk', 'Trash'],
	TABLES: {
		emailAddresses: 'email_addresses',
		folder: 'folder',
		mailItem: 'mail_item',
		config: 'config'
	},
	FLAGS: {
		unread: 'u',
		flagged: 'f',
		replied: 'r',
		sentByMe: 's',
		forwarded: 'w',
		calendarInvite: 'v',
		draft: 'd',
		deleted: 'x',
		notificationSent: 'n',
		hasAttachment: 'a',
		urgent: '!',
		lowPriority: '?',
		priority: '+'
	},
	VIEWS: {
		message: 'message',
		appointment: 'appointment',
		contact: 'contact',
		task: 'task',
		document: 'document'
	},
	SEARCH: {
		attachment: 'attachment'
	},
	LOCAL_PREFS: [
		{
			name: 'AUTO_ARCHIVE',
			value: {
				isEnabled: false,
				onEvery: 30,
				frequency: 'DAYS'
			}
		},
		{
			name: 'PST_IMPORT',
			value: {
				batchSize: 100
			}
		}
	]
};
