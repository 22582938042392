import cx from 'classnames';
import style from './style';

export default function Icon({ name, size = 'md', important, className, class: cls, ...props }) {
	return (
		<span
			role="img"
			{...props}
			class={cx(
				'zimbra-icon',
				name && `zimbra-icon-${name}`,
				style[size],
				important && style.important,
				className,
				cls
			)}
		/>
	);
}
