import { useEffect, useState, useMemo } from 'preact/hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { startPollingRequest, markCompleted } from './../../store/polling-requests/actions';
import { getQueuedRequests, getRemovedRequests } from './../../store/polling-requests/selectors';

function PollRequest({ componentId }) {
	const dispatch = useDispatch();

	const requestResult = useSelector(
		state => state.pollingRequests.requests[componentId],
		shallowEqual
	);

	const requestedData = useMemo(() => requestResult || {}, [requestResult]);

	useEffect(() => {
		let processId;
		const { isRunning, isInQueue, pollingInterval, id, processId: prevTimeout } = requestedData;
		if (isInQueue && !isRunning) {
			prevTimeout && clearTimeout(prevTimeout);
			processId = setTimeout(() => {
				if (!requestedData || !requestedData.requestFunction) return;
				requestedData.requestFunction();
			}, pollingInterval);
			// Set Process Id to clear once process is completed
			dispatch(startPollingRequest({ id, processId }));
		}
	}, [dispatch, requestedData]);

	return null;
}

/**
 * PollingRequests
 *
 * Based on PollingQuery redux state, generate `<PollRequest />` components for each requests.
 */
export default function PollingRequests() {
	const dispatch = useDispatch();
	const [requestsList, setRequestsList] = useState([]);

	const queuedProcesses = useSelector(getQueuedRequests, shallowEqual);
	const removedProcesses = useSelector(getRemovedRequests, shallowEqual);

	useEffect(() => {
		// Remove completed requests from redux and dom
		const queriesToRemove = [];
		Object.keys(removedProcesses).forEach(key => {
			const { processId, id } = removedProcesses[key];
			queriesToRemove.push(id);
			clearTimeout(processId);
			dispatch(markCompleted(id));
		});

		if (queriesToRemove.length) {
			setRequestsList(prevState => {
				return prevState.filter(comp => queriesToRemove.indexOf(comp.key));
			});
		}

		if (queuedProcesses.length) {
			const newRequests = [];

			setRequestsList(prevState => {
				return prevState.filter(comp => queuedProcesses.indexOf(comp.key));
			});

			queuedProcesses.forEach(queuedItemId => {
				newRequests.push(<PollRequest componentId={queuedItemId} key={queuedItemId} />);
			});

			setRequestsList(prevState => prevState.concat(newRequests));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queuedProcesses, removedProcesses]);

	return requestsList;
}
