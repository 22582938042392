import { compose } from 'recompose';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import FilterRuleModal from './filter-modal';
import { callWith } from '../../lib/util';
import { separateEmailAddresses } from '../../utils/mail-item';
import { useState, useCallback, useEffect } from 'preact/hooks';
import orderBy from 'lodash-es/orderBy';
import get from 'lodash-es/get';
import getMailFolders from '../../graphql-decorators/get-mail-folders';
import withTags from '../../graphql-decorators/tags/get-tag';
import MessageQuery from '../../graphql/queries/message.graphql';
import { withFilters, withModifyFilters } from '../../graphql-decorators/preferences/filters';
import { notify } from '../../store/notifications/actions';
import { Text } from 'preact-i18n';

function FiltersContainer({
	value,
	onSave,
	onClose,
	folders,
	sharedFolders,
	tagList,
	modifyFilterRules,
	mailItem,
	isBasicFilterMode,
	notify: notifyMessage,
	filters,
	messageQueryData,
	accountData
}) {
	const [basicFilterMode, setBasicFilterMode] = useState(
		isBasicFilterMode !== undefined ? isBasicFilterMode : true
	);
	const [mailToFilterInfo, setMailToFilterInfo] = useState({});

	useEffect(() => {
		const messageObj = mailItem || messageQueryData;
		if (messageObj) {
			const { from, cc } = separateEmailAddresses(messageObj);
			const mailInfo = {
				from,
				cc,
				subject: messageObj.subject
			};
			setMailToFilterInfo(mailInfo);
		}
	}, [mailItem, messageQueryData]);

	const handleFilterModeChange = useCallback(
		modeValue => {
			setBasicFilterMode(modeValue);
		},
		[setBasicFilterMode]
	);

	const handleFilterSave = useCallback(
		filterRule => {
			const existingFilters = (filters && filters.data) || [];
			onSave
				? onSave(filterRule)
				: modifyFilterRules(existingFilters.concat(filterRule)).then(
						() => {
							onClose();
							notifyMessage({
								message: <Text id="quickFilter.created" />
							});
						},
						error => {
							console.error(error);
							notifyMessage({
								failure: true,
								message: <Text id="error.genericInvalidRequest" />
							});
						}
				  );
		},
		[filters, onSave, modifyFilterRules, onClose, notifyMessage]
	);

	return (
		<FilterRuleModal
			value={value}
			onSave={handleFilterSave}
			onClose={onClose}
			onAdvancedFilterChange={callWith(handleFilterModeChange, false)}
			onSwitchToBasicFilterChange={callWith(handleFilterModeChange, true)}
			isBasicFilterMode={basicFilterMode}
			folders={folders}
			sharedFolders={sharedFolders}
			tagList={tagList}
			mailToFilterInfo={mailToFilterInfo}
			zimbraMailForwardingFeature={accountData?.attrs?.zimbraFeatureMailForwardingInFiltersEnabled}
		/>
	);
}

const Filters = compose(
	connect(null, { notify }),
	getMailFolders(),
	withTags(
		{
			skip: ({ accountData }) => !get(accountData, 'attrs.zimbraFeatureTaggingEnabled')
		},
		({ data: { getTag: tags } }) => ({
			tagList:
				(tags && orderBy(tags, [({ name }) => name.toLowerCase()]).map(({ name }) => name)) || []
		})
	),
	withFilters(),
	withModifyFilters(),
	// Message query fetches data for cases where required fields (like cc address) are not present in mailItem prop.
	graphql(MessageQuery, {
		skip: ({ mailIdToFilter }) => !mailIdToFilter,
		options: ({ mailIdToFilter }) => ({
			fetchPolicy: 'cache-first',
			variables: {
				id: mailIdToFilter,
				max: 250000
			}
		}),
		props: ({ data: { message: messageQueryData } }) => ({
			messageQueryData
		})
	})
)(FiltersContainer);

export default Filters;
