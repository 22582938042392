import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'preact/hooks';
import cx from 'classnames';

import s from '../style.less';
import { Select, Option } from '@zimbra/blocks';
import FormGroup from '../../../form-group';
import AlignedLabel from '../../../aligned-form/label';
import { CalendarOptionItem } from '../../../../utils/calendar';

import { getParsedAppointmentData } from '../../../../store/calendar/selectors';
import { getParsedAppointmentDataThunk } from '../../thunk';
import { setEvent } from '../../../../store/calendar/actions';

export const SelectedCalendar = ({
	tabId,
	disabledElement,
	isForwardInvite,
	isProposeTime,
	appointmentData,
	calendars,
	getSelectedCalendar,
	isPrivateCheck,
	insertableCalendar
}) => {
	const dispatch = useDispatch();
	const { isPrivate, selectedCalendar } = useSelector(state => ({
		isPrivate: getParsedAppointmentData(state, 'isPrivate', tabId),
		selectedCalendar: getParsedAppointmentData(state, 'selectedCalendar', tabId)
	}));

	const handleCalendarChange = useCallback(
		({ value }) => {
			const ownerEmail = null;
			const isPrivateAllowed = isPrivateCheck(value);

			const event = dispatch(getParsedAppointmentDataThunk('event', tabId));

			if (value.owner) {
				event.folderId = `${value.ownerZimbraId}:${value.sharedItemId}`;
			} else {
				event.folderId = value.id;
			}

			dispatch(
				setEvent({
					tabId,
					eventData: {
						event,
						ownerEmail,
						isPrivate: isPrivateAllowed && isPrivate,
						selectedCalendar: getSelectedCalendar(appointmentData, calendars, event),
						isFormDirty: true
					}
				})
			);
		},
		[appointmentData, calendars, dispatch, getSelectedCalendar, isPrivate, isPrivateCheck, tabId]
	);

	const selectedCalendarValue =
		selectedCalendar &&
		(insertableCalendar.find(cal => cal.id === selectedCalendar.id) || selectedCalendar);

	return (
		<FormGroup>
			<AlignedLabel
				class={s.alignedLabel}
				align="left"
				textId="calendar.editModal.fields.calendar"
			/>
			{selectedCalendarValue && (
				<Select
					displayValue={<CalendarOptionItem calendar={selectedCalendarValue} style={s} />}
					value={selectedCalendarValue}
					iconPosition="right"
					iconSize="sm"
					onChange={handleCalendarChange}
					class={cx(s.calendarSelect, disabledElement)}
					dropup
					toggleButtonClass={cx(s.toggleButtonClass, disabledElement)}
					disabled={isForwardInvite || isProposeTime}
				>
					{insertableCalendar.map(cal => (
						<Option value={cal} key={cal.id} class={s.calendarOption}>
							<CalendarOptionItem calendar={cal} style={s} />
						</Option>
					))}
				</Select>
			)}
		</FormGroup>
	);
};
