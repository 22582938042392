import { Component } from 'preact';
import { pushToModalStack, popFromModalStack, isCurrentModal } from '../lib/modal-manager';

/**
 * Wraps child components in `ModalManager` which will plug modal-manager functionality onto the component it wraps.
 * @param {String} clickOutsideHandlerKey This is the name of the onClickOutsideHandler function being used. Or in other words the function that is called to close the modal.
 */

export default function withModalManager(clickOutsideHandlerKey, disableOutsideClick) {
	return function (Child) {
		class ModalManager extends Component {
			/**
			 * This handler is used to intercept the clickOutsideHandler call on props to allow only the upper modal to close when the user hits ESC.
			 * @param {Object} e The event object
			 */
			onClickOutside = e => {
				if (
					isCurrentModal(this.state.modalId) &&
					typeof this.props[clickOutsideHandlerKey] === 'function'
				) {
					this.props[clickOutsideHandlerKey](e);
				}
			};

			componentWillMount() {
				/**
				 * Some Modals(i.e. ActionMenu) should open new Modal and close itself immediately.
				 * In such case, New Modals gets added before previous modal unmounts.
				 * This sequence fails to return correct value of `isCurrentModal` method.
				 * To avoid this, added `setTimeout` to maintain sequence.
				 */
				setTimeout(() => {
					this.setState({ modalId: pushToModalStack() });
				}, 0);
			}

			componentWillUnmount() {
				popFromModalStack();
			}

			render(props, { modalId }) {
				return (
					modalId && (
						<Child
							{...(disableOutsideClick && { onClickOutside: this.onClickOutside })}
							modalId={modalId}
							{...props}
						/>
					)
				);
			}
		}

		return ModalManager;
	};
}
