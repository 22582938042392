export const getAddressString = ({ street, city, state, postalCode }) => {
	const address = [street, city, state].filter(Boolean).join(', ') + ' ' + (postalCode || '');
	return address.trim();
};

export const filterItem = ({ __typename, zimbraCalResType, ...attrs }, searchText) =>
	Object.keys(attrs).find(key => {
		const match = attrs[key] && attrs[key].toLowerCase().indexOf(searchText) >= 0;
		if (match) return match;
	});

export const filterBySearch = (search = '', list = [], hideBusy) => {
	const searchValue = search.trim().toLowerCase();
	if (searchValue.length) {
		return list.filter(r =>
			hideBusy ? !r.isBusy && filterItem(r._attrs, searchValue) : filterItem(r._attrs, searchValue)
		);
	} else if (hideBusy) {
		return list.filter(({ isBusy }) => !isBusy);
	}
	return list;
};
