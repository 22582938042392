import isEmpty from 'lodash-es/isEmpty';
import get from 'lodash-es/get';

import {
	MIN_LENGTH,
	MIN_UPPERCASE,
	MIN_LOWERCASE,
	MIN_PUNCTUATION,
	MIN_NUMERIC,
	MIN_DIGITSORPUNCS,
	ENFORCE_HISTORY,
	COMMON_PASSWORD,
	PASSWORD_ALLOWED_USERNAME,
	INVALID_PASSWORD_MSG_PATTERN
} from './constants';

// Function to check special character
const isAsciiPunc = ch =>
	(ch >= 33 && ch <= 47) || // ! " # $ % & ' ( ) * + , - . /
	(ch >= 58 && ch <= 64) || // : ; < = > ? @
	(ch >= 91 && ch <= 96) || // [ \ ] ^ _ `
	(ch >= 123 && ch <= 126); // { | } ~

// Checking for allowed characters and punctuations and split all different type of characters
export const getSplittedCharsFromPassword = (
	password,
	zimbraPasswordAllowedChars,
	zimbraPasswordAllowedPunctuationChars
) => {
	const uppers = [],
		lowers = [],
		numbers = [],
		punctuations = [],
		invalidChars = [],
		invalidPuncs = [];

	let alphaCount = 0;

	const chars = password.split('');

	chars.forEach(char => {
		const charCode = char.charCodeAt(0);
		let isInvalid = false;

		if (zimbraPasswordAllowedChars) {
			try {
				if (!char.match(new RegExp(zimbraPasswordAllowedChars, 'g'))) {
					invalidChars.push(char);
					isInvalid = true;
				}
			} catch (error) {
				console.error({ error });
			}
		}

		if (!isInvalid) {
			if (charCode >= 65 && charCode <= 90) {
				uppers.push(char);
				alphaCount++;
			} else if (charCode >= 97 && charCode <= 122) {
				lowers.push(char);
				alphaCount++;
			} else if (charCode >= 48 && charCode <= 57) {
				numbers.push(char);
			} else if (zimbraPasswordAllowedPunctuationChars) {
				try {
					char.match(new RegExp(zimbraPasswordAllowedPunctuationChars, 'g'))
						? punctuations.push(char)
						: invalidPuncs.push(char);
				} catch (error) {
					console.error({ error });
				}
			} else if (isAsciiPunc(charCode)) {
				punctuations.push(char);
			}
		}
	});

	return {
		uppers,
		lowers,
		numbers,
		punctuations,
		invalidChars,
		invalidPuncs,
		alphaCount
	};
};

// Creating set for password rules by checking attributes value presense
export const getPasswordRules = ({
	zimbraPasswordMinLength,
	zimbraPasswordMinUpperCaseChars,
	zimbraPasswordMinLowerCaseChars,
	zimbraPasswordMinPunctuationChars,
	zimbraPasswordMinNumericChars,
	zimbraPasswordMinDigitsOrPuncs,
	zimbraPasswordEnforceHistory,
	zimbraPasswordBlockCommonEnabled,
	zimbraPasswordAllowUsername
}) =>
	[
		zimbraPasswordMinLength && { rule: MIN_LENGTH, value: zimbraPasswordMinLength },
		zimbraPasswordMinUpperCaseChars && {
			rule: MIN_UPPERCASE,
			value: zimbraPasswordMinUpperCaseChars
		},
		zimbraPasswordMinLowerCaseChars && {
			rule: MIN_LOWERCASE,
			value: zimbraPasswordMinLowerCaseChars
		},
		zimbraPasswordMinPunctuationChars && {
			rule: MIN_PUNCTUATION,
			value: zimbraPasswordMinPunctuationChars
		},
		zimbraPasswordMinNumericChars && { rule: MIN_NUMERIC, value: zimbraPasswordMinNumericChars },
		zimbraPasswordMinDigitsOrPuncs && {
			rule: MIN_DIGITSORPUNCS,
			value: zimbraPasswordMinDigitsOrPuncs
		},
		zimbraPasswordBlockCommonEnabled && {
			rule: COMMON_PASSWORD,
			value: zimbraPasswordBlockCommonEnabled
		},
		!zimbraPasswordAllowUsername && {
			rule: PASSWORD_ALLOWED_USERNAME,
			value: zimbraPasswordAllowUsername
		},
		zimbraPasswordEnforceHistory && {
			rule: ENFORCE_HISTORY,
			value: zimbraPasswordEnforceHistory
		}
	].filter(Boolean);

export const getBasicRules = ({
	zimbraPasswordMinLength,
	zimbraPasswordMinUpperCaseChars,
	zimbraPasswordMinLowerCaseChars,
	zimbraPasswordMinPunctuationChars,
	zimbraPasswordMinNumericChars,
	zimbraPasswordMinDigitsOrPuncs,
	zimbraPasswordAllowUsername
}) =>
	[
		zimbraPasswordMinLength > 0 && MIN_LENGTH,
		zimbraPasswordMinUpperCaseChars > 0 && MIN_UPPERCASE,
		zimbraPasswordMinLowerCaseChars > 0 && MIN_LOWERCASE,
		zimbraPasswordMinNumericChars > 0 && MIN_NUMERIC,
		zimbraPasswordMinPunctuationChars > 0 && MIN_PUNCTUATION,
		zimbraPasswordMinDigitsOrPuncs > 0 && MIN_DIGITSORPUNCS,
		!zimbraPasswordAllowUsername && PASSWORD_ALLOWED_USERNAME
	].filter(Boolean);

export const getRemoteRules = ({
	zimbraPasswordBlockCommonEnabled,
	zimbraPasswordEnforceHistory
}) =>
	[
		zimbraPasswordBlockCommonEnabled && COMMON_PASSWORD,
		zimbraPasswordEnforceHistory > 0 && ENFORCE_HISTORY
	].filter(Boolean);

export const getQualifiedRules = (accountAttrs, value, passwordBasicRules, username = '') => {
	const {
		zimbraPasswordMinLength,
		zimbraPasswordMinUpperCaseChars,
		zimbraPasswordMinLowerCaseChars,
		zimbraPasswordMinPunctuationChars,
		zimbraPasswordMinNumericChars,
		zimbraPasswordAllowedChars,
		zimbraPasswordAllowedPunctuationChars,
		zimbraPasswordMinDigitsOrPuncs,
		zimbraPasswordAllowUsername
	} = accountAttrs;

	username = username.split('@')[0];

	const { uppers, lowers, numbers, punctuations, invalidChars } = getSplittedCharsFromPassword(
		value,
		zimbraPasswordAllowedChars,
		zimbraPasswordAllowedPunctuationChars
	);

	const qualifiedRules = [];

	if (!isEmpty(passwordBasicRules)) {
		if (zimbraPasswordMinLength > 0 && value.length >= zimbraPasswordMinLength) {
			qualifiedRules.push(MIN_LENGTH);
		}

		if (zimbraPasswordMinUpperCaseChars > 0 && uppers.length >= zimbraPasswordMinUpperCaseChars) {
			qualifiedRules.push(MIN_UPPERCASE);
		}

		if (zimbraPasswordMinLowerCaseChars > 0 && lowers.length >= zimbraPasswordMinLowerCaseChars) {
			qualifiedRules.push(MIN_LOWERCASE);
		}

		if (zimbraPasswordMinNumericChars > 0 && numbers.length >= zimbraPasswordMinNumericChars) {
			qualifiedRules.push(MIN_NUMERIC);
		}

		if (
			zimbraPasswordMinPunctuationChars > 0 &&
			punctuations.length >= zimbraPasswordMinPunctuationChars
		) {
			qualifiedRules.push(MIN_PUNCTUATION);
		}

		if (
			zimbraPasswordMinDigitsOrPuncs > 0 &&
			punctuations.length + numbers.length >= zimbraPasswordMinDigitsOrPuncs
		) {
			qualifiedRules.push(MIN_DIGITSORPUNCS);
		}

		if (!zimbraPasswordAllowUsername && !value.includes(username)) {
			qualifiedRules.push(PASSWORD_ALLOWED_USERNAME);
		}
	}

	return {
		qualifiedRules,
		invalidChars
	};
};

export const getErrorCode = err => {
	const errorObj = get(err, 'graphQLErrors.0.originalError.faults.0.Detail.Error');
	// Handle errors generated by server side code
	let errorCode = errorObj.Code,
		errorMetadata;

	if (errorCode) {
		// Different error for change password screen
		if (errorCode === 'account.AUTH_FAILED') {
			errorCode = 'account.AUTH_FAILED_changePassword';
		} else if (errorCode === 'account.INVALID_PASSWORD') {
			errorMetadata = get(
				errorObj,
				'a.0',
				// parse error name from message for invalid password errors missing attributes
				{ n: (err.message.match(INVALID_PASSWORD_MSG_PATTERN) || ['generic']).pop() }
			);
		}
	}

	return {
		errorCode,
		errorMetadata
	};
};
