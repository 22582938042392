import { Component } from 'preact';
import InlineModalDialog from '../../inline-modal-dialog';
import FilterModalContent from './filter-modal-content';
import flatten from 'lodash-es/flatten';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import concat from 'lodash-es/concat';
import unset from 'lodash-es/unset';
import mergeWith from 'lodash-es/mergeWith';
import omit from 'lodash-es/omit';
import remove from 'lodash-es/remove';
import { Text } from 'preact-i18n';
import {
	NEW_FILTER_RULE,
	FILTER_TEST_TYPE,
	FILTER_ACTION_TYPE,
	FILTER_CONDITION_TYPE
} from '../../../constants/filter-rules';
import { INBOX } from '../../../constants/folders';
import style from './style.less';
import { Button } from '@zimbra/blocks';
import ModalDrawer from '../../modal-drawer';
import ModalDrawerToolbar from '../../modal-drawer-toolbar';
import withMediaQuery from '../../../enhancers/with-media-query';
import { minWidth, screenMd } from '../../../constants/breakpoints';
import { isValidEmail } from '../../../lib/util';
import AdvancedFilterModal from './advanced-filter-modal';
import { getConditionCount, getActionCount } from '../../../utils/settings-filter';
import { shallowEqual } from '../../../lib/pure-component';

const validate = (filterRule, isBasicFilterMode) => {
	if (!filterRule.name.trim()) {
		return <Text id="settings.filterRuleModal.validateFilterName" />;
	}

	const hasRedirectAction = get(filterRule, 'actions.0.redirect');
	if (hasRedirectAction && !hasRedirectAction.every(f => isValidEmail(f.address))) {
		return <Text id="settings.filterRuleModal.validateRedirectAddress" />;
	}

	const testPrefixPath = ['conditions', '0'];
	const tests = flatten(
		Object.keys(get(filterRule, testPrefixPath))
			.filter(testKey => testKey !== FILTER_CONDITION_TYPE.ALLORANY)
			.map(ruleKey => get(filterRule, concat(testPrefixPath, ruleKey)))
	);

	if (!isBasicFilterMode) {
		// Check for value field of a condition.
		// Added check for 'value', since predicate option 'exists and does not exist' does not have a 'value' field.
		if (tests.some(test => 'value' in test && !test.value)) {
			return <Text id="settings.filterRuleModal.validateAllFilterRule" />;
		}
		// If 'Header named' is selected as header then header text field should have a value
		// Added check for 'header', since body test does not have a 'header' field.
		if (tests.some(test => 'header' in test && !test.header)) {
			return <Text id="settings.filterRuleModal.validateHeaderName" />;
		}
	} else {
		// If a rule has a value it is valid, the predicate must exist.
		const hasAtLeastOneTest = tests.some(({ value }) => value);
		if (!hasAtLeastOneTest) {
			return <Text id="settings.filterRuleModal.validateFilterRule" />;
		}
	}
};

// Removes conditions without a value from the filter rule.
const normalize = (filterRule, isBasicFilterMode) => {
	const testPrefixPath = ['conditions', '0'];
	return Object.keys(get(filterRule, testPrefixPath))
		.filter(testKey => testKey !== FILTER_CONDITION_TYPE.ALLORANY)
		.reduce((output, testKey) => {
			const testPath = concat(testPrefixPath, testKey);
			const conditions = get(output, testPath).filter(
				({ value }) => Boolean(value) || (!isBasicFilterMode && value === undefined)
			);
			conditions.length ? set(output, testPath, conditions) : unset(output, testPath);
			return output;
		}, cloneDeep(filterRule));
};
@withMediaQuery(minWidth(screenMd), 'matchesScreenMd')
export default class FilterModal extends Component {
	state = {
		uncommittedValue: null,
		error: null
	};
	handleChange = newUncommittedValue => {
		this.setState({
			uncommittedValue: newUncommittedValue
		});
	};

	handleSave = () => {
		const { isBasicFilterMode } = this.props;
		const { uncommittedValue } = this.state;
		const error = validate(uncommittedValue, isBasicFilterMode);
		const clonedUncommittedValue = cloneDeep(uncommittedValue);
		Object.keys(clonedUncommittedValue.conditions[0]).forEach(key => {
			// Remove custom keys from header test conditions before saving
			if ([FILTER_TEST_TYPE.HEADER, FILTER_TEST_TYPE.HEADER_EXIST].includes(key)) {
				clonedUncommittedValue.conditions[0][key] = clonedUncommittedValue.conditions[0][key].map(
					f => omit(f, ['selectedType', 'key'])
				);
			}
		});
		if (!error) {
			this.setState({ error: null });
			const normalized = normalize(clonedUncommittedValue, isBasicFilterMode);
			this.props.onSave(normalized);
		} else {
			this.setState({ error });
		}
	};

	handleClose = () => {
		this.setState({ error: null });
		this.props.onClose();
	};

	handleCloseDrawer = () => {
		this.setState({ isDrawerMounted: false });
		this.props.onClose();
	};

	handleConditionsMergeForEdit = () => {
		const { value, tagList } = this.props;
		const filter = cloneDeep(value);
		const allActions = filter.actions[0];
		const conditions = mergeWith(
			[],
			NEW_FILTER_RULE.conditions,
			value.conditions,
			(objValue, srcValue, key) => {
				if (key === FILTER_TEST_TYPE.ADDRESS && srcValue && objValue) {
					return [...srcValue, ...objValue];
				} else if (key === FILTER_ACTION_TYPE.STOP) {
					return value.actions[0].stop || null;
				}
			}
		);
		filter.conditions = conditions;
		// If any of the tag is deleted, then on edit filter remove that tag row.
		if (get(allActions, 'tag.length')) {
			let tagActionRemoved = false;
			if (!tagList.length) {
				delete allActions.tag;
				tagActionRemoved = true;
			} else {
				remove(allActions.tag, ({ tagName }) => !tagList.includes(tagName));
				if (getActionCount(value.actions[0]) !== getActionCount(allActions)) {
					tagActionRemoved = true;
				}
			}
			// If there is only one tag action and that tag is deleted, then on edit filter show move to folder
			if (!getActionCount(allActions)) {
				allActions.fileInto = [
					{
						folderPath: INBOX,
						index: getActionCount(allActions)
					}
				];
				delete allActions.tag;
			}
			let actionCount = getActionCount(allActions) - 1;
			// When the tag action row is deleted, change the index of other actions.
			if (tagActionRemoved) {
				Object.keys(allActions).forEach(keyItem => {
					if (keyItem !== FILTER_ACTION_TYPE.STOP) {
						allActions[keyItem].forEach(actionValue => {
							actionValue.index = actionCount;
							actionCount--;
						});
					}
				});
			}
		}
		return filter;
	};

	prepDataForAdvFilterSwitch = uncommittedValue => {
		const clonedUncommittedValue = cloneDeep(uncommittedValue);
		const conditions = clonedUncommittedValue.conditions[0];
		//Removing all untouched conditions in the basic filter and keepng only those conditions
		// whose either value or match case conditions are changed.
		Object.keys(conditions).forEach(key => {
			if (key !== FILTER_CONDITION_TYPE.ALLORANY) {
				conditions[key] = conditions[key].filter(
					({ caseSensitive, value, header }) =>
						// address and body tests having 'Match case' ticked or values will make it through. For headerExists test, header name is mandatory since other fields do not apply.
						caseSensitive || value || (key === FILTER_TEST_TYPE.HEADER_EXIST && header)
				);
			}
		});

		// Adding one basic condition if no condition are present.
		if (!getConditionCount(conditions)) {
			conditions.address.push(
				NEW_FILTER_RULE.conditions[0].address.find(add => add.header === FILTER_CONDITION_TYPE.FROM)
			);
		}
		return clonedUncommittedValue;
	};

	prepareFilterValueFromMail = mailToFilterInfo => {
		const filter = cloneDeep(NEW_FILTER_RULE);
		const conditions = get(filter, 'conditions.0');

		const fromAddress = get(mailToFilterInfo, 'from.0.address'),
			ccAddress = get(mailToFilterInfo, 'cc.0.address'),
			subject = get(mailToFilterInfo, 'subject');

		set(conditions, `${FILTER_TEST_TYPE.ADDRESS}.0.value`, fromAddress);
		set(conditions, `${FILTER_TEST_TYPE.ADDRESS}.1.value`, ccAddress);
		set(conditions, `${FILTER_TEST_TYPE.HEADER}.0.value`, subject);
		set(conditions, `${FILTER_TEST_TYPE.HEADER}.0.caseSensitive`, !!subject);

		filter.conditions = [conditions];

		return filter;
	};

	componentWillMount() {
		const { value, isBasicFilterMode, mailToFilterInfo } = this.props;
		const initialFilterValue = value
			? this.handleConditionsMergeForEdit()
			: mailToFilterInfo
			? this.prepareFilterValueFromMail(mailToFilterInfo)
			: NEW_FILTER_RULE;
		this.setState({
			uncommittedValue: !isBasicFilterMode
				? this.prepDataForAdvFilterSwitch(initialFilterValue)
				: initialFilterValue
		});
	}

	componentWillReceiveProps(nextProps) {
		const { isBasicFilterMode, mailToFilterInfo } = this.props;
		const { mailToFilterInfo: newMailToFilterInfo } = nextProps;

		if (nextProps.isBasicFilterMode !== isBasicFilterMode) {
			this.setState(({ uncommittedValue }) => ({
				error: null,
				...(!nextProps.isBasicFilterMode && {
					uncommittedValue: this.prepDataForAdvFilterSwitch(uncommittedValue)
				})
			}));
		}
		if (!shallowEqual(newMailToFilterInfo, mailToFilterInfo)) {
			this.setState({
				uncommittedValue: this.prepareFilterValueFromMail(newMailToFilterInfo)
			});
		}
	}

	render(
		{
			value,
			matchesScreenMd,
			zimbraMailForwardingFeature,
			folders,
			isBasicFilterMode,
			onAdvancedFilterChange,
			onSwitchToBasicFilterChange,
			sharedFolders,
			tagList
		},
		{ uncommittedValue, error, isDrawerMounted }
	) {
		const [ComponentClass, componentClassProps] = matchesScreenMd
			? [InlineModalDialog, { autofocusChildIndex: 1 }]
			: [
					ModalDrawer,
					{
						mounted: isDrawerMounted,
						toolbar: (
							<ModalDrawerToolbar
								buttons={[
									<Button styleType="primary" brand="primary" onClick={this.handleSave}>
										<Text id="buttons.save" />
									</Button>
								]}
								onClose={this.handleCloseDrawer}
							/>
						),
						contentClass: style.filterModalInner,
						onClickOutside: this.handleClose
					}
			  ];
		return (
			<ComponentClass
				{...componentClassProps}
				dialogClassName={style.settings}
				wrapperClassName={style.filterModalWrapper}
				innerClassName={style.filterModalInner}
				actionLabel="settings.filterRuleModal.saveLabel"
				title={
					matchesScreenMd &&
					(value ? 'settings.filterRuleModal.title' : 'settings.filterRuleModal.addTitle')
				}
				onAction={this.handleSave}
				onClose={this.handleClose}
				error={error}
			>
				{isBasicFilterMode ? (
					<FilterModalContent
						folders={folders}
						sharedFolders={sharedFolders}
						value={uncommittedValue}
						onChange={this.handleChange}
						matchesScreenMd={matchesScreenMd}
						filterMailFeature={zimbraMailForwardingFeature}
						title={value ? 'settings.filterRuleModal.title' : 'settings.filterRuleModal.addTitle'}
						switchToAdvancedFilter={onAdvancedFilterChange}
						tagList={tagList}
					/>
				) : (
					<AdvancedFilterModal
						folders={folders}
						matchesScreenMd={matchesScreenMd}
						title={value ? 'settings.filterRuleModal.title' : 'settings.filterRuleModal.addTitle'}
						value={uncommittedValue}
						onChange={this.handleChange}
						switchToBasicFilter={onSwitchToBasicFilterChange}
						tagList={tagList}
						filterMailFeature={zimbraMailForwardingFeature}
					/>
				)}
			</ComponentClass>
		);
	}
}
