import { Workbox } from 'workbox-window';
import { getBasePath } from './util';

export function registerSW({ displayToast = () => {} }) {
	const serviceWorker = navigator.serviceWorker;
	if (serviceWorker) {
		const workbox = new Workbox(`${getBasePath()}/sw.js`);

		workbox
			.register()
			.then(registration => {
				const updateSW = () => {
					// set up a listener that will reload the page as soon as
					// the previously waiting service worker has taken control.
					workbox.addEventListener('controlling', () => {
						window.location.reload();
					});

					// To fix reload on FireFox
					workbox.addEventListener('externalactivated', () => {
						window.location.reload();
					});

					// Send a message to the waiting service worker,
					// instructing it to activate.
					workbox.messageSkipWaiting();
				};

				const showSkipWaitingPrompt = () => {
					displayToast({
						onAccept: () => {
							if (registration.waiting) {
								updateSW();
							}
						}
					});
				};

				if (registration.waiting && registration.active) {
					// To handle the scenario when service worker is already in waiting state
					// and user doesn't click on reload button of toast
					// but user refreshes web app with browser's reload button
					// then service worker will automatically update itselt.
					updateSW();
				}

				// Add an event listener to detect when the registered
				// service worker has installed but is waiting to activate.
				workbox.addEventListener('waiting', showSkipWaitingPrompt);
				workbox.addEventListener('externalwaiting', showSkipWaitingPrompt);
			})
			.catch(error => console.error('[SW] - error: ', error));
	}
}
