import { cloneElement, Component, Fragment } from 'preact';
import { Text } from 'preact-i18n';
import cx from 'classnames';
import debounce from '@zimbra/util/src/debounce';
import VirtualList from 'preact-virtual-list';
import { KeyCodes, LoaderBar } from '@zimbra/blocks';
import InfiniteScroll from '../infinite-scroll';
import { getId, filterDuplicates } from '../../lib/util';
import findIndex from 'lodash-es/findIndex';
import ZimletSlot from '../zimlet-slot';
import linkref from 'linkref';
import style from './style';
import array from '@zimbra/util/src/array';

export default class SmartList extends Component {
	getId = this.props.getId || getId;

	// Indicates the initial direction of multi select navigation
	isNavigatingDown;
	// Set recently clicked item index
	pointerIndex;
	// Set scroll top to adjust scroll on selection from keyboard navigation
	listScrollTop;

	/** Creates a function that updates selection based on a mutator */
	updateSelection =
		fn =>
		(...args) => {
			const { selected, onSelectionChange, onItemUpdate } = this.props;
			const items = this.props.items.filter(this.getId);
			let newSelected;

			const { list } = this.refs;

			if (items.length) {
				newSelected = filterDuplicates(fn(selected, items, ...args) || selected);
			}

			onItemUpdate && onItemUpdate(selected, newSelected);
			onSelectionChange && onSelectionChange(newSelected, ...args);

			list.scrollTop = this.listScrollTop || list.scrollTop;
		};

	/** Toggle selection of an item on/off based on current state */
	toggleItemSelected = this.updateSelection((selected, items, { item }) => {
		const id = this.getId(item);
		if (selected.indexOf(id) < 0) {
			return selected.concat(id);
		}
		return selected.filter(i => i !== id);
	});

	/** Change selection to contain only a single item. */
	selectItem = this.updateSelection((selected, items, { item }) => [this.getId(item)]);

	/** Toggle selection to contain all items or no items. */
	toggleSelectAll = this.updateSelection((selected, items) =>
		selected.length && selected.length === items.length ? [] : items.map(this.getId)
	);

	// Toggle the direction of navigation and reset offset.
	setFlagsForCrossover = () => {
		this.queuedBumpOffset = 0;
		this.isNavigatingDown = !this.isNavigatingDown;
	};

	getItemsForCrossOver = (position, items) => {
		return this.selectItemRange(items, position, position);
	};

	// This function handles traversal in the opposite direction user started multi select with.
	// It also checks if crossover is about to happen.
	itemsForReverseNavigation = (low, high, selected, items, pointerAtTop = false) => {
		// Crossover is hit. Navigation direction is about to change.
		if (low === high) {
			this.setFlagsForCrossover();
			return this.getItemsForCrossOver(low, items);
		}
		// pop out the most recently selected item from the list.
		selected.length > 1 && pointerAtTop ? selected.shift() : selected.pop();
	};

	/*
		* Move selection cursor by an integer offset (-1 for up, 1 for down).
		* In case of multi select, actions corresponding to up and down arrow are associated with navigation direction.
		* Navigation direction for multi select:
				It is the direction in which user starts adding item in the list with multi select.
		* Effect of navigation direction on up/down arrow actions:
				If user starts multi select in the downward direction then
					shift + down: Add items to the list
					shift + up: De-select most recently added items to the list until crossover condition is hit.
				If user starts multi select in the upward direction then
					shift + up: Add items to the list
					shift + down: De-select most recently added items to the list until crossover condition is hit.
		* Crossover condition:
				It is a condition when de-selection of items arrives at a point where only item in the selected list is the one user started out multi select with.
				From this point on if user continues to navigate in reverse direction, then the navigation direction is toggled and items in the opposite direction would be added to the list.
	*/

	bumpSelection = debounce(
		this.updateSelection((selected, items, multi) => {
			const offset = this.queuedBumpOffset;
			const [low, high] = this.getBounds(selected);
			if (low == null || high == null) return [this.getId(items[0])];

			if (multi) {
				if (offset > 0) {
					if (this.isNavigatingDown) {
						// Halt at the last item in the list
						if (high === items.length - 1) {
							this.queuedBumpOffset = 0;
							return selected;
						}
						const start = low,
							end = high + offset;

						selected = this.selectItemRange(items, start, end);
						// Set pointer index to last selected element for reference
						this.pointerIndex = this.pointerIndex === low ? start : end;
					} else {
						// pointerAtTop will be true if range selected from bottom to top (i.e. index 8 to 3)
						const pointerAtTop = this.pointerIndex === low;
						// If pointer is at top, remove element from top instead of end.
						this.itemsForReverseNavigation(low, high, selected, items, pointerAtTop);
						// Set pointer index to last selected element for reference
						this.pointerIndex = pointerAtTop ? low + offset : high - offset;
					}
				} else if (offset < 0) {
					if (this.isNavigatingDown) {
						this.itemsForReverseNavigation(low, high, selected, items);
						this.pointerIndex = high;
					} else {
						// Halt at the first item in the list
						if (low === 0) {
							this.pointerIndex = 0;
							this.queuedBumpOffset = 0;
							return selected;
						}
						const startRange = low + offset;
						selected = this.selectItemRange(items, startRange, high);
						// Set pointer index to last selected element for reference
						this.pointerIndex = startRange;
					}
				}
			} else {
				/*
				 * After user switches to simple navigation from multi select, base position needs to be identified to perform navigation with respect to.
					 It is the index of most recent item added to the list which is also dependent on the navigation direction.
				 */
				let basePosition = this.isNavigatingDown ? high : low;
				if (offset > 0) {
					basePosition = basePosition === items.length - 1 ? basePosition : basePosition + offset;
				} else if (offset < 0) {
					basePosition = basePosition === 0 ? basePosition : basePosition + offset;
				}
				selected = filterDuplicates(this.selectItemRange(items, basePosition, basePosition));
			}
			this.queuedBumpOffset = 0;
			return multi ? selected : selected.slice(selected.length - 1);
		}),
		1000 / 144
	);

	queuedBumpSelection = (offset, multi) => {
		this.queuedBumpOffset = (this.queuedBumpOffset || 0) + offset;
		this.bumpSelection(multi);
	};

	/** Move the selection start to the first list item, optionally with multi-select. */
	selectFirst = this.updateSelection((selected, items, multi) => {
		const [low] = this.getBounds(selected);
		if (!multi) return [this.getId(items[0])];
		return selected.concat(this.selectItemRange(items, 0, low || 1));
	});

	/** Move the selection end to the last list item, optionally with multi-select. */
	selectLast = this.updateSelection((selected, items, multi) => {
		const [high] = this.getBounds(selected);
		if (!multi) return [this.getId(items[items.length - 1])];
		return selected.concat(this.selectItemRange(items, high || items.length - 2, items.length - 1));
	});

	/** Computes the smallest and largest index within the current selection. */
	getBounds(selection) {
		const items = this.props.items.filter(this.getId);
		let lowest, highest;
		for (let i = 0; i < items.length; i++) {
			const id = this.getId(items[i]);
			if (selection.indexOf(id) !== -1) {
				if (typeof lowest === 'undefined' || i < lowest) lowest = i;
				if (typeof highest === 'undefined' || i > highest) highest = i;
			}
		}
		const low = lowest == null ? highest : lowest;
		const high = highest == null ? lowest : highest;
		const isContinualRange = selection.length - 1 === high - low;

		return [low, high, isContinualRange];
	}

	/** Create a selection of items ID's for the given index range */
	selectItemRange(items, start = 0, end = 0) {
		const selected = [];
		for (let i = start; i <= end; i++) {
			const selectedId = this.getId(items[i]);
			selectedId && selected.push(selectedId);
		}
		return selected;
	}

	/** Get an item from .items by its ID */
	getItem(id) {
		const { items } = this.props;
		for (let i = items.length; i--; ) {
			if (this.getId(items[i]) === id) {
				return items[i];
			}
		}
	}

	/** Handle keyboard input within the list */
	onKey = e => {
		const { selection, selected, onSelectionChange, items, virtualized } = this.props;
		let key = e.keyCode;
		if (key === KeyCodes.UP_ARROW && e.metaKey) key = KeyCodes.HOME;
		if (key === KeyCodes.DOWN_ARROW && e.metaKey) key = KeyCodes.END;
		const [, , isContinualRange] = this.getBounds(selected);
		const isFirstEleNull = items[0] === null;
		if (!virtualized) {
			this.activeDescendant = document.activeElement.getAttribute('aria-activedescendant');
		}
		let nextItem = document.getElementById(this.activeDescendant);
		switch (key) {
			case KeyCodes.UP_ARROW:
				if (selected.length === 1) {
					this.isNavigatingDown = false;
				}
				// If its not continued selection, select last clicked element and continue navigation
				// Also, if first element is null, select pointer + 1st element
				if (!isContinualRange) {
					const selectedItem =
						items[isFirstEleNull ? this.pointerIndex + 1 : this.pointerIndex]?.id;
					onSelectionChange(array(selectedItem), true);
					this.isNavigatingDown = false;
				}
				if (nextItem && !virtualized) {
					nextItem = nextItem.previousElementSibling;
					this.focusItem(nextItem);
				}
				this.queuedBumpSelection(-1, e.shiftKey);
				break;
			case KeyCodes.DOWN_ARROW:
				if (selected.length === 1) {
					this.isNavigatingDown = true;
				}
				// If its not continued selection, select last clicked element and continue navigation
				// Also, if first element is null, select pointer + 1st elements
				if (!isContinualRange) {
					const selectedItem =
						items[isFirstEleNull ? this.pointerIndex + 1 : this.pointerIndex]?.id;
					onSelectionChange(array(selectedItem), true);
					this.isNavigatingDown = true;
				}
				if (nextItem && !virtualized) {
					nextItem = nextItem.nextElementSibling;
					this.focusItem(nextItem);
				}
				this.queuedBumpSelection(1, e.shiftKey);
				break;
			case KeyCodes.CARRIAGE_RETURN:
				selection &&
					this.handleItemClick({
						item: this.getItem(selection[selection.length - 1]),
						event: e
					});
				return;
			case KeyCodes.HOME:
				!virtualized && this.focusFirstItem();
				this.selectFirst(e.shiftKey);
				break;
			case KeyCodes.END:
				!virtualized && this.focusLastItem();
				this.selectLast(e.shiftKey);
				break;
			default:
				return;
		}

		e.preventDefault();
		e.stopPropagation();
		return false;
	};

	/** Action methods available to the header as props.actions */
	actions = {
		selectItem: this.selectItem,
		toggleItemSelected: this.toggleItemSelected,
		toggleSelectAll: this.toggleSelectAll
	};

	/**
	 * Handles clicking/activation of an item (passed as onClick to item components)
	 * If any item clicked with Shift/Control, it should select elements based on following condition
	 * 1) Shift + Click: Select all elements in reference to previously selected item
	 * 2) Control/Command + Click: Select current item in addition to previously selected items
	 * 3) Shift + Control/Command + Click: Consider Control/Command + Click (as per legacy)
	 * 4) Control/Command + Shift + Click: Consider Control/Command + Click (as per legacy)
	 * */
	handleItemClick = ({ action, event, item, ...rest }) => {
		const { items, onSelectionChange, onItemUpdate, selected } = this.props;
		const { shiftKey, ctrlKey, metaKey } = event; // metaKey, ctrlKey
		const prevPointer = this.pointerIndex;
		const itemArray = items.filter(this.getId);
		// Find the indexes of last selected item
		this.pointerIndex = findIndex(itemArray, anItem => anItem && anItem.id === item.id);

		// To achieve #1, #3 & #4. Refer function comment.
		if (shiftKey && !ctrlKey && !metaKey) {
			// In case, Item is clicked along with Shift key
			// Get boundaries of selection. Also, check if selection is continual or not.
			const [low, high, isContinualRange] = this.getBounds(selected);

			let rangeStart = low;
			let rangeEnd = this.pointerIndex;

			if (this.pointerIndex >= low && this.pointerIndex <= high) {
				// If new element selection is within current selection range
				// i.e. Selected Range: 3 to 6 (low:3, high: 6) | Next click: 4 (pointerIndex: 4)
				if (isContinualRange) {
					// If it's continual range, select range based on low, pointerIndex and high values
					rangeStart = low === prevPointer ? low : this.pointerIndex;
					rangeEnd = high === prevPointer ? high : this.pointerIndex;
				} else if (low <= this.pointerIndex) {
					rangeStart = this.pointerIndex < prevPointer ? this.pointerIndex : prevPointer;
					rangeEnd = this.pointerIndex > prevPointer ? this.pointerIndex : prevPointer;
				} else {
					rangeStart = this.pointerIndex;
					rangeEnd = low < this.pointerIndex ? this.pointerIndex : low;
				}
				this.isNavigatingDown = false;
			} else if (this.pointerIndex >= high) {
				// i.e. Selected Range: 3 to 6 (low:3, high: 6) | Next click: 8 (pointerIndex: 8)
				rangeStart = isContinualRange ? low : prevPointer;
				rangeEnd = this.pointerIndex;
				this.isNavigatingDown = true;
			} else if (this.pointerIndex <= low) {
				// i.e. Selected Range: 3 to 6 (low:3, high: 6) | Next click: 1 (pointerIndex: 1)
				rangeStart = this.pointerIndex;
				rangeEnd = isContinualRange ? high : prevPointer;
				this.isNavigatingDown = false;
			}
			// For mail items, 1st element in array will be null for sticky labels like "Today", "Yesterday", etc.
			// To maintain indexing, we are increasing it in case of mail items where we will find first element as null.
			if (itemArray[0] === null) {
				rangeStart++;
				rangeEnd++;
			}

			onSelectionChange &&
				onSelectionChange(this.selectItemRange(itemArray, rangeStart, rangeEnd), true);
			onItemUpdate && onItemUpdate(selected, this.selectItemRange(itemArray, rangeStart, rangeEnd));
		} else {
			action = action || event.action || 'selectItem';
			// metaKey: MAC Command key, ctrlKey: Windows Control key or Contact selection
			if (ctrlKey || metaKey) {
				action = 'toggle';
			}
			if (action === 'toggle') action = 'toggleItemSelected';
			this.actions[action]({ item, ...rest });
		}
	};

	/** Handlers are wrapped to ensure a .item property is available on them. Wrapper handler creation is memoized by item id. */
	clickHandlers = {};
	createItemClickHandler = item => e => {
		if (e instanceof Event) {
			e = { event: e };
		}
		this.handleItemClick({ item, ...e });
	};

	// To set focus on given element and caluclate scroll position accordingly
	focusItem = function (element) {
		const { list } = this.refs;
		if (element) {
			list.setAttribute('aria-activedescendant', element.id);
			this.activeDescendant = element.id;

			if (list.scrollHeight > list.clientHeight) {
				const scrollBottom = list.clientHeight + list.scrollTop;
				const elementBottom = element.offsetTop + element.offsetHeight;
				if (elementBottom > scrollBottom) {
					this.listScrollTop = elementBottom - list.clientHeight;
				} else if (element.offsetTop < list.scrollTop) {
					this.listScrollTop = element.offsetTop;
				}
			}
		}
	};

	// Focus on the first item
	focusFirstItem = () => {
		const firstItem = this.refs.list.querySelector('[role="option"]');
		if (firstItem) {
			this.focusItem(firstItem);
		}
	};

	//  Focus on the last item
	focusLastItem = () => {
		const itemList = this.refs.list.querySelectorAll('[role="option"]');
		if (itemList.length) {
			this.focusItem(itemList[itemList.length - 1]);
		}
	};

	static defaultProps = {
		selected: []
	};

	componentDidMount() {
		const { setRef, virtualized } = this.props;
		setRef && setRef(this.refs.list);
		if (!virtualized) {
			this.activeDescendant = this.refs.list.getAttribute('aria-activedescendant');
		}
	}

	/** Renders a single list item */
	renderItem = (item, index) => {
		const { renderItem, ListItem, items, selected, isTrashFolder, canEditSharedItem } = this.props,
			id = this.getId(item),
			allSelected = selected.length && selected.length === items.length,
			itemProps = {
				item,
				selected: selected.indexOf(id) !== -1,
				onClick:
					this.clickHandlers[id] || (this.clickHandlers[id] = this.createItemClickHandler(item)),
				allSelected,
				isTrashFolder,
				canEditSharedItem
			};
		if (renderItem) {
			return [
				...(index === 0
					? [<ZimletSlot name="top-mail-ad-item" props class={style.listTopper} />]
					: []),
				renderItem(itemProps, index)
			];
		}
		return <ListItem {...itemProps} />;
	};

	render({
		items,
		renderItem,
		ListItem,
		selected,
		header,
		empty,
		virtualized,
		rowHeight,
		vanilla,
		innerClass,
		noItemsClass,
		noItemsMessage,
		infinite,
		onScroll,
		hasMore,
		loadMore,
		showLoaderBar,
		handleSubScroll,
		...props
	}) {
		const allSelected = selected.length && selected.length === items.length;
		const childProps = {
			actions: this.actions,
			selected,
			items,
			allSelected
		};

		if (typeof header === 'function') {
			header = [header(childProps)];
		} else if (header && header.type) {
			header = cloneElement(header, childProps);
		}

		const listProps = {
			class: cx(!vanilla && style.inner, innerClass),
			onKeyDown: this.onKey
		};

		return (
			<div class={cx(!vanilla && style.smartList, props.class, style.headerSearch)}>
				{header}
				{virtualized && items && items.length > 0 ? (
					infinite ? (
						<InfiniteScroll
							rowHeight={rowHeight}
							overscanCount={250}
							data={items}
							renderRow={this.renderItem}
							hasMore={hasMore}
							loadMore={loadMore}
							isFetchingData={showLoaderBar}
							{...listProps}
							ref={linkref(this, 'list')}
						/>
					) : (
						<VirtualList
							rowHeight={rowHeight}
							overscanCount={250}
							data={items}
							renderRow={this.renderItem}
							{...listProps}
							ref={linkref(this, 'list')}
						/>
					)
				) : (
					items && (
						<ul
							{...listProps}
							tabindex="0"
							role="listbox"
							ref={linkref(this, 'list')}
							onScroll={handleSubScroll && debounce(handleSubScroll, 200)}
						>
							{showLoaderBar && (
								<div class={style.progressBar}>
									<LoaderBar />
								</div>
							)}
							{!virtualized && items && items.length > 0
								? items.map((item, index) => this.renderItem(item, index))
								: empty || (
										<Fragment>
											<ZimletSlot name="top-mail-ad-item" props class={style.listTopper} />
											<div class={cx(!vanilla && style.noItems, noItemsClass)}>
												{noItemsMessage || <Text id="lists.empty" />}
											</div>
										</Fragment>
								  )}
						</ul>
					)
				)}
			</div>
		);
	}
}
