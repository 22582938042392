import { Fragment } from 'preact';
import ActionMenu, { DropDownWrapper } from '../action-menu';
import ActionMenuGroup from '../action-menu-group';
import ActionMenuItem from '../action-menu-item';
import { Icon } from '@zimbra/blocks';
import style from './style.less';

export default function ExternalHeaderDropdown({ title, items }) {
	return (
		<ActionMenu
			label={
				<Fragment>
					{title}
					<Icon class={style.caret} name="caret-down" size="xs" />
				</Fragment>
			}
			actionButtonClass={style.actionButton}
			popoverClass={style.popover}
			labelClass={style.label}
			arrow={false}
			hoverDuration={50}
			into={false}
		>
			<DropDownWrapper>
				<ActionMenuGroup>
					{items.map(({ name, href }) => (
						<ActionMenuItem
							className={style.item}
							href={href}
							key={name.replace(/ /g, '_').toLowerCase()}
							target="_blank"
							rel="noopener noreferrer"
							isExternal
						>
							{name}
						</ActionMenuItem>
					))}
				</ActionMenuGroup>
			</DropDownWrapper>
		</ActionMenu>
	);
}
