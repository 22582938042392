import { useCallback, useState } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { Text, Localizer } from 'preact-i18n';
import style from './style';
import { Icon, KeyCodes } from '@zimbra/blocks';
import { MsgList } from './msg-list';
import get from 'lodash-es/get';
import { callWith } from '@zimbra/util/src/call-with';
import ModalDrawerToolbar from '../modal-drawer-toolbar';
import ResponsiveModal from '../responsive-modal';

export function DumpsterContentDialog({
	onClose,
	items = [],
	onNext,
	loading,
	onSelectionChange,
	overlayClass,
	handleSortBy,
	sortBy,
	onScroll,
	onSearch,
	searchQuery,
	isNextDisable
}) {
	const [query, setQuery] = useState(searchQuery);
	const selected = useSelector(state => get(state, 'dumpster.selectedMsgs', []));

	const disablePrimary = !(selected.length > 0) || isNextDisable;

	const pending = loading && items.length === 0;

	const handleOnInputQuery = useCallback(
		e => {
			const inputQuery = get(e.target, 'value');
			inputQuery !== query && setQuery(inputQuery);
		},
		[query]
	);

	const handleKeyDown = useCallback(
		e => {
			if (e.keyCode === KeyCodes.CARRIAGE_RETURN) {
				onSearch(get(e.target, 'value'));
			}
		},
		[onSearch]
	);

	return (
		<ResponsiveModal
			drawerProps={{
				toolbar: (
					<ModalDrawerToolbar
						actionLabel="buttons.next"
						cancelLabel="buttons.cancel"
						onAction={onNext}
						onClose={onClose}
						disablePrimary={disablePrimary}
						pending={pending}
					/>
				)
			}}
			dialogProps={{
				class: style.dialog,
				contentClass: style.msgDialogContent,
				footerClass: style.footer,
				...(overlayClass && { overlayClass }),
				disableOutsideClick: true
			}}
			scrollable
			title={<Text id="dialogs.dumpsterDialog.msgListTitle" />}
			actionLabel="buttons.next"
			cancelLabel="buttons.cancel"
			onAction={onNext}
			onClose={onClose}
			disablePrimary={disablePrimary}
			pending={pending}
		>
			<div class={style.query} onKeyDown={handleKeyDown}>
				<Localizer>
					<input
						class={style.query}
						placeholder={<Text id="dialogs.dumpsterDialog.searchPlaceholder" />}
						value={query}
						onInput={handleOnInputQuery}
					/>
				</Localizer>
				<Icon
					name={'search'}
					class={style.searchInputIcon}
					onClick={callWith(onSearch, query)}
					size="md"
				/>
			</div>
			<div class={style.msgListPane}>
				{items && items.length > 0 ? (
					<MsgList
						sortBy={sortBy}
						items={items}
						onSelectionChange={onSelectionChange}
						handleSortBy={handleSortBy}
						onScroll={onScroll}
					/>
				) : (
					!loading && (
						<span class={style.noMsgs}>
							{query ? (
								<Text id="dialogs.dumpsterDialog.noSearchResult" />
							) : (
								<Text id="dialogs.dumpsterDialog.noDumpsterResult" />
							)}
						</span>
					)
				)}
			</div>
		</ResponsiveModal>
	);
}
