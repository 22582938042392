import { gql } from '@apollo/client';
import get from 'lodash-es/get';
import { normalize, entities } from '@zimbra/api-client';
import { findDataId } from '../graphql/utils/graphql-optimistic';
import SearchQuery from '../graphql/queries/search/search.graphql';
import cloneDeep from 'lodash-es/cloneDeep';
import contactFields from '../graphql/fragments/contact.graphql';
import folderFields from '../graphql/fragments/folder.graphql';
import foldersData from '../graphql/fragments/folder-data.graphql';
import GetFolder from '../graphql/queries/folders/get-folder.graphql';
import update from 'immutability-helper';

import {
	itemsForKeySeparated,
	getCurrentUrlDetails,
	generateFragmentName,
	attributeKeys
} from './common';

import { processCreatedConversations, processModifiedConversations } from './conversations';
import {
	processCreatedMessages,
	processModifiedMessages,
	processDeletedMessages
} from './messages';
import { processModifiedBriefcaseDocuments } from './briefcase';
import uniqBy from 'lodash-es/uniqBy';
import { USER_FOLDER_IDS } from '../constants';
import { OUTBOX } from '../constants/folders';

const { Contact, FolderEntity, Mailbox, Tag } = entities;

const normalizeFolder = normalize(FolderEntity);
const normalizeContact = normalize(Contact);
const normalizeTag = normalize(Tag);
const normalizeMailbox = normalize(Mailbox);

const contactProperties = [
	'id',
	'date',
	'folderId',
	'revision',
	'fileAsStr',
	'sortField',
	'tags',
	'tagNames',
	'memberOf',
	'members'
];
const contactAttrProperties = [
	'anniversary',
	'assistantPhone',
	'birthday',
	'callbackPhone',
	'carPhone',
	'company',
	'companyPhone',
	'email',
	'email2',
	'firstName',
	'fullName',
	'homeCity',
	'homeCountry',
	'homeEmail',
	'homeFax',
	'homePhone',
	'homePostalCode',
	'homeState',
	'homeStreet',
	'homeURL',
	'imAddress',
	'imAddress1',
	'imAddress2',
	'imAddress3',
	'imAddress4',
	'imAddress5',
	'jobTitle',
	'lastName',
	'middleName',
	'maidenName',
	'namePrefix',
	'nameSuffix',
	'department',
	'mobilePhone',
	'nickname',
	'otherCity',
	'otherCountry',
	'otherFax',
	'otherPhone',
	'otherPostalCode',
	'otherState',
	'otherStreet',
	'otherURL',
	'pager',
	'phone',
	'phoneticCompany',
	'phoneticFirstName',
	'phoneticLastName',
	'workCity',
	'workCountry',
	'workEmail',
	'workFax',
	'workPhone',
	'workPostalCode',
	'workState',
	'workStreet',
	'workURL',
	'image',
	'thumbnailPhoto',
	'notes',
	'userCertificate',
	'other'
];

const hasUnreadDescendent = folder => {
	if (
		folder[
			folder.id === USER_FOLDER_IDS.DRAFTS || folder.name === OUTBOX
				? 'nonFolderItemCount'
				: 'unread'
		] > 0
	) {
		return true;
	}

	const folderArray = get(folder, 'folders') || [];
	for (let i = 0; i < folderArray.length; i++) {
		if (hasUnreadDescendent(folderArray[i])) {
			return true;
		}
	}

	return false;
};

const setUnreadDescendentFlag = folder => {
	const folderArray = get(folder, 'folders') || [];

	folder = {
		...folder,
		unreadDescendent: hasUnreadDescendent(folder)
	};

	if (folderArray) {
		folder = {
			...folder,
			folders: folderArray.map(setUnreadDescendentFlag)
		};
	}

	return folder;
};

function itemsForKey(notification, key) {
	const modifiedItems = get(notification, `modified.${key}`, []);
	const createdItems = get(notification, `created.${key}`, []);
	return [...modifiedItems, ...createdItems];
}

function addNewItemToList(itemList, item, sortBy, mapFindIndex) {
	if (item.id) {
		const index = mapFindIndex
			? itemList.findIndex(mapFindIndex)
			: itemList.findIndex(({ id }) => id === item.id);

		// Concat only if it's new item
		if (index === -1) {
			itemList = [item].concat(itemList);
		} else {
			itemList.splice(index, 1, item);
		}
	} else if (sortBy === 'nameAsc' && item.fileAsStr && itemList.length > 0) {
		const index = itemList.findIndex(
			cnt =>
				item.fileAsStr.localeCompare(cnt.fileAsStr, undefined, {
					sensitivity: 'base'
				}) === 0
		);
		if (index !== -1) {
			itemList.splice(index, 1, item);
		} else {
			itemList.push(item);
		}
	}

	return itemList;
}

function getVariablesFromDataId(dataId) {
	try {
		return JSON.parse(dataId.replace(/^[^(]+\((.*)\)$/, '$1'));
	} catch (e) {}
}

/**
 * Extract the attributes (non-nested object types) from a notification
 * data object to dynamically constructing a fragment.
 */
// function attributeKeysWithObjects(data) {
// 	let str = '';

// 	if (!data) return str;

// 	const keys = Object.keys(data);

// 	if (keys.length > 0) {
// 		keys.forEach(v => {
// 			if (Array.isArray(data[v]) && Object.keys(data[v][0]).length > 0) {
// 				str += `${v} { ${attributeKeysWithObjects(data[v][0])} } `;
// 			} else {
// 				str += `${v} `;
// 			}
// 		});
// 	}

// 	return str;
// }

export class ZimbraNotifications {
	constructor(options) {
		if (options.processNotifications === false) {
			this.processNotifications = false;
		} else {
			this.processNotifications = true;
		}

		this.cache = options.cache;
		this.getApolloClient = options.getApolloClient;

		this.notifier = get(options, 'batchClient.notifier');
		if (this.notifier) {
			this.bindNotificationHandler();
		}

		// adds redux store as a class instance variable, used to read information about the current active URL's router props
		this.store = options.store;

		window.store = options.store;
	}

	bindNotificationHandler = () => {
		this.notifier.addNotifyHandler(this.notificationHandler);
	};

	destroy = () => {
		this.notifier.removeNotifyHandler(this.notificationHandler);
	};

	startProcessing = () => {
		this.processNotifications = true;
	};

	stopProcessing = () => {
		this.processNotifications = false;
	};

	notificationHandler = notification => {
		// return if the notification should not be processed.
		// used in the case when the app is not yet ready to handle the notification and there is a notification in the API
		// happens in case of migrations or other backend activities which might generate notifications
		if (!this.processNotifications) {
			// eslint-disable-next-line no-console
			console.info('[Cache] Ignored Notification', notification, new Date());
			return;
		}
		// eslint-disable-next-line no-console
		console.info('[Cache] Handling Notification', notification, new Date());

		const currentUrlDetails = getCurrentUrlDetails(this.store);

		this.handleMailboxNotifications(notification);
		this.handleFolderNotifications(notification);

		// handle conversation and message notifications only if active vertical is email
		if (currentUrlDetails.isEmailActive) {
			// Modify both conversation and message search query
			// so that UI will shows updated data when user switch between conversation and message view
			this.handleConversationNotifications(notification);
			this.handleMessageNotifications(notification);
			this.handleMessageDeleteNotification(notification);
		} else if (currentUrlDetails.isContactsActive) {
			this.handleContactNotifications(notification);
		} else if (currentUrlDetails.isBriefcaseActive) {
			this.handleBriefcaseNotifications(notification);
		}

		this.handleTagsNotifications(notification);
	};

	/**
	 * Processes the specified notification items with the processor function passed in batches with timeout, by queueing them in the
	 * JavaScript event loop. To prevent the freezing in UI.
	 *
	 * @param {Array<any>} items array of notification items that need to be batched
	 * @param {Function} processorFn function which processes the items
	 * @returns
	 * @memberof ZimbraNotifications
	 */
	batchProcessItems = (items, processorFn) => {
		if (!items || (items && items.length === 0)) {
			return;
		}

		const BATCH_SIZE = 50;
		const LENGTH = items.length;
		const TIMEOUT = 100;
		const ITERATIONS = Math.ceil(LENGTH / BATCH_SIZE);

		let i;
		for (i = 0; i < ITERATIONS; i++) {
			const start = i * BATCH_SIZE;
			const end = Math.min(start + BATCH_SIZE, LENGTH);

			const batch = items.slice(start, end);

			// When the timed out function executes, the variables accessed inside the function have to be available through closure
			// Otherwise, the latest values of the variables would be used, which can have been updated by the loop iterations that executed
			// after the timeout was set and before it was executed.
			setTimeout(
				// eslint-disable-next-line no-shadow
				((i, ITERATIONS, batch) => () => {
					processorFn(batch);
					// broadcast updates in the last iteration
					if (i === ITERATIONS - 1) {
						this.broadcastCacheUpdates();
					}
				})(i, ITERATIONS, batch),
				TIMEOUT
			);
		}
	};

	broadcastCacheUpdates = () => {
		this.getApolloClient().queryManager.broadcastQueries();
	};

	// Find the actual folder of the shared folder
	findSharedItemId = item => {
		const cachedData = get(this.cache, 'data.data');
		const allFolders = Object.keys(cachedData).filter(f => f.includes('Folder:'));
		const [ownerZimbraId, sharedItemId] = item.split(':');
		for (const folderId in allFolders) {
			if (Object.prototype.hasOwnProperty.call(allFolders, folderId)) {
				const folder = cachedData[allFolders[folderId]];
				//Find the folder where ownerZimbraId:sharedItemId equals to id
				if (folder.ownerZimbraId === ownerZimbraId && folder.sharedItemId === sharedItemId) {
					return folder.id;
				}
			}
		}
	};

	handleBriefcaseNotifications = notifications => {
		const { modifiedItems } = itemsForKeySeparated(notifications, 'doc');
		this.batchProcessItems(modifiedItems, () =>
			processModifiedBriefcaseDocuments({
				store: this.store,
				client: this.getApolloClient(),
				cache: this.cache,
				modifiedItems
			})
		);
	};

	handleContactNotifications = notification => {
		const items = itemsForKey(notification, 'cn');
		this.batchProcessItems(items, this.processContactNotifications);
	};

	handleConversationNotifications = notifications => {
		const items = itemsForKeySeparated(notifications, 'c');

		if (items.createdItems && items.createdItems.length > 0) {
			this.batchProcessItems(items.createdItems, () =>
				processCreatedConversations({
					store: this.store,
					client: this.getApolloClient(),
					cache: this.cache,
					notifications,
					items: items.createdItems
				})
			);
		}

		if (items.modifiedItems && items.modifiedItems.length > 0) {
			this.batchProcessItems(items.modifiedItems, () =>
				processModifiedConversations({
					store: this.store,
					client: this.getApolloClient(),
					cache: this.cache,
					notifications,
					items: items.modifiedItems
				})
			);
		}
	};

	handleFolderNotifications = notification => {
		const modifiedItems =
			get(notification, 'modified.folder') || get(notification, 'modified.link');
		this.batchProcessItems(modifiedItems, this.processFolderNotifications);
	};

	handleMailboxNotifications = notification => {
		this.batchProcessItems(get(notification, 'modified.mbx'), this.processMailboxNotifications);
	};

	handleMessageDeleteNotification = notifications => {
		let deletedItems = get(notifications, 'deleted.id');

		if (deletedItems && deletedItems.length > 0) {
			deletedItems = deletedItems.split(',');
			this.batchProcessItems(deletedItems, () =>
				processDeletedMessages({
					store: this.store,
					client: this.getApolloClient(),
					items: deletedItems,
					isTag: false
				})
			);
		}
	};

	handleMessageNotifications = notifications => {
		const { createdItems, modifiedItems } = itemsForKeySeparated(notifications, 'm');

		if (createdItems && createdItems.length > 0) {
			this.batchProcessItems(createdItems, () =>
				processCreatedMessages({
					store: this.store,
					client: this.getApolloClient(),
					cache: this.cache,
					notifications,
					items: createdItems
				})
			);
		}

		if (modifiedItems && modifiedItems.length > 0) {
			this.batchProcessItems(modifiedItems, () =>
				processModifiedMessages({
					store: this.store,
					client: this.getApolloClient(),
					cache: this.cache,
					notifications,
					items: modifiedItems
				})
			);
		}
	};

	handleTagsNotifications = notification => {
		const modifiedItems = get(notification, 'modified.tag');
		this.batchProcessItems(modifiedItems, this.processTagsNotifications);
	};

	findTagContact =
		regexToMatch =>
		({ id }) =>
			id.match(regexToMatch);

	getIdWithPrefix = ({ id }, prefix) => (id.indexOf(prefix) === 0 ? id : `${prefix}:${id}`);

	processContactNotifications = items => {
		const typeGroup = '#type:group';
		const notTypeGroup = `NOT ${typeGroup}`;

		items.forEach(i => {
			const searchResponseData = {};
			const item = normalizeContact(i);
			const defaultAbsFolderPath = 'Contacts';
			let folder;

			try {
				folder = this.cache.readFragment({
					id: `Folder:${item.folderId}`,
					fragment: gql`
						fragment ${generateFragmentName('folderName', item.folderId)} on Folder {
							name
							absFolderPath
						}
					`
				});
			} catch (exception) {
				console.error(exception);
				return;
			}

			// @TODO: Create query for Tags. As of now, we get TagNames with comma separate string.
			// This depends on new approach of handling multiple tag rquests
			const absFolderPath = (folder && folder.absFolderPath?.slice(1)) || defaultAbsFolderPath;
			// Creating query based on absFolderPath instead of folder name, To update sub folder cache.
			const query =
				absFolderPath === 'Trash'
					? `in:\\\\"${absFolderPath}\\\\"`
					: item.attributes && item.attributes.type === 'group'
					? typeGroup
					: `in:\\\\"${absFolderPath}\\\\" ${notTypeGroup}`;

			const queryRegex = new RegExp(query);

			const id = findDataId(this.cache, 'ROOT_QUERY.search', dataId => {
				// check if query does not contain NOT #type:group but contains #type:group
				if (query.indexOf(notTypeGroup) === -1 && query.indexOf(typeGroup) !== -1) {
					// if yes, then dataId should also not contain NOT #type:group and contain #type:group
					return dataId.indexOf(notTypeGroup) === -1 && queryRegex.test(dataId);
				}
				return queryRegex.test(dataId);
			});

			// Cache entry for search request doesn't exist, ignore
			if (!id) return;

			const queryVars = getVariablesFromDataId(id) || {};
			const { sortBy } = queryVars;

			if (!searchResponseData[query]) {
				/**
				 * readQuery without try...catch breaks the operation on exception.
				 * Read contacts from search results fragment and
				 * handle any exceptions occurred while reading message.
				 * */
				try {
					searchResponseData[query] = this.cache.readQuery({
						query: SearchQuery,
						variables: { ...queryVars }
					});
				} catch (exception) {
					console.error(exception);
					return;
				}
			}

			// If we write any extra properties inside graphql cache then it break readFragment operation
			// and it always returns null https://www.apollographql.com/docs/react/caching/cache-interaction/#readfragment
			delete item.i4uid;
			delete item.f;

			const dataToWrite = {
				__typename: 'Contact',
				...item,
				attributes: {
					__typename: 'ContactAttributes',
					...item.attributes
				}
			};

			try {
				const cachedContact = this.cache.readFragment({
					id: this.getIdWithPrefix(item, 'Contact'),
					fragment: contactFields,
					fragmentName: 'contactFields'
				});

				if (cachedContact) {
					this.cache.writeFragment({
						id: this.cache.identify(dataToWrite),
						fragment: gql`
								fragment ${generateFragmentName('contactNotification', item.id)} on Contact {
									${attributeKeys(item).join('\n')}
									attributes {
										${attributeKeys(item.attributes).join('\n')}
									}
								}
							`,
						data: dataToWrite
					});
				} else {
					// Fill null values for properties which don't exist
					for (let index = 0, len = contactProperties.length; index < len; index++) {
						if (!dataToWrite[contactProperties[index]]) {
							dataToWrite[contactProperties[index]] = null;
						}
					}

					for (let index = 0, len = contactAttrProperties.length; index < len; index++) {
						if (!dataToWrite.attributes[contactAttrProperties[index]]) {
							dataToWrite.attributes[contactAttrProperties[index]] = null;
						}
					}

					this.cache.writeFragment({
						id: this.cache.identify(dataToWrite),
						fragment: contactFields,
						fragmentName: 'contactFields',
						data: dataToWrite
					});
				}
			} catch (e) {
				console.error('contact updation failed', e);
			}

			// to get the updated data from cache so that it can be updated in the query
			const updatedItem = this.cache.readFragment({
				id: this.getIdWithPrefix(item, 'Contact'),
				fragment: contactFields,
				fragmentName: 'contactFields'
			});

			if (updatedItem) {
				this.cache.writeQuery({
					query: SearchQuery,
					variables: { ...queryVars },
					data: update(searchResponseData[query], {
						search: {
							contacts: {
								$apply: contactsData => {
									const contacts = cloneDeep(contactsData);

									// If it's tag notification, pass custom findIndex to identify index
									// of item. This is required when multiple notification is trying to readfrom cache,
									// `searchResponse[query].search.contacts` gets `id` `Contact:id` instead of
									// `id` for 2nd onwards requests.

									const updateData = addNewItemToList(
										contacts || [],
										updatedItem,
										sortBy,
										(updatedItem.tags || updatedItem.tagNames) &&
											this.findTagContact(new RegExp(`^(Contact:)*(${updatedItem.id})+`))
									);

									return uniqBy(updateData, 'id');
								}
							}
						}
					})
				});
			}
		});
	};

	processFolderNotifications = items => {
		items.forEach(i => {
			const item = normalizeFolder(i);
			const itemId = item.id.includes(':') ? this.findSharedItemId(item.id) : item.id;

			try {
				this.cache.writeFragment({
					id: `Folder:${itemId}`,
					fragment: gql`
						fragment ${generateFragmentName('folderNotification', item.id)} on Folder {
							${attributeKeys(item)}
						}
					`,
					data: {
						__typename: 'Folder',
						...item
					}
				});
			} catch (exception) {
				console.error(exception);
				return;
			}

			// if we get unread flag in the notification of the folder then only
			// setting the unreadDescendent flag
			if ('unread' in item) {
				const folder = this.cache.readFragment({
					id: `Folder:${itemId}`,
					fragment: folderFields
				});

				const absFolderPath = get(folder, 'absFolderPath');
				let folderData;

				try {
					folderData = this.cache.readQuery({
						query: GetFolder,
						variables: {
							view: null
						}
					});
				} catch (exception) {
					console.error(exception);
					return;
				}
				const folders = get(folderData, 'getFolder.folders.0');

				let folderToUpdate;

				if (folders.folders.length > 0) {
					folderToUpdate = folders.folders.find(
						({ absFolderPath: absPath }) =>
							absPath === absFolderPath || absFolderPath.startsWith(`${absPath}/`)
					);
				} else if (!folderToUpdate && folders.linkedFolders.length > 0) {
					folderToUpdate = folders.linkedFolders.find(
						({ absFolderPath: absPath }) =>
							absPath === absFolderPath || absFolderPath.startsWith(`${absPath}/`)
					);
				}

				if (folderToUpdate) {
					folderToUpdate = setUnreadDescendentFlag(folderToUpdate);
					try {
						this.cache.writeFragment({
							id: `Folder:${folderToUpdate.id}`,
							fragment: foldersData,
							fragmentName: 'foldersData',
							data: {
								__typename: 'Folder',
								...folderToUpdate
							}
						});
					} catch (exception) {
						console.error(exception);
						return;
					}
				}
			}
		});
	};

	processMailboxNotifications = items => {
		const mbxItems = normalizeMailbox(
			items.reduce((acc, i) => {
				/**
				 * Below step is required to flatten array of data into flattened object.
				 * E.g items would be [{ s: 23342 }, { t: "afdsfs" }] which will be flattened to { s: 23342, t: "afdsfs" }
				 */
				acc = {
					...acc,
					...i
				};

				return acc;
			}, {})
		);

		if (Object.keys(mbxItems).length) {
			const accInfoRegExp = /^AccountInfo/;
			const id = findDataId(this.cache, 'AccountInfo', dataId => accInfoRegExp.test(dataId));

			if (id) {
				this.cache.writeFragment({
					id,
					fragment: gql`
							fragment ${generateFragmentName('mailboxNotification')} on AccountInfo {
								${attributeKeys(mbxItems)}
							}
						`,
					data: {
						__typename: 'AccountInfo',
						...mbxItems
					}
				});
			}
		}
	};

	processTagsNotifications = items => {
		items.forEach(i => {
			const item = normalizeTag(i);
			this.cache.writeFragment({
				id: `Tag:${item.id}`,
				fragment: gql`
						fragment ${generateFragmentName('tagsNotification', item.id)} on Tag {
							${attributeKeys(item)}
						}
					`,
				data: {
					__typename: 'Tag',
					...item
				}
			});
		});
	};
}
