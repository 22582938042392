import { useCallback, useState } from 'preact/hooks';
import { Text } from 'preact-i18n';
import { Button, ChoiceInput, Select as InlineSelect, Option, Icon } from '@zimbra/blocks';
import cx from 'classnames';
import DelegatesList from './delegates';
import { FONT_FAMILY, FONT_SIZES } from '../../../constants/fonts';
import { HTML_MODE, TEXT_MODE } from '../../../constants/composer';
import Select from '../../select';
import style from '../style';
import get from 'lodash-es/get';

import { isSMIMEFeatureAvailable } from '../../../utils/license';

const DELEGATE_SEND_SETTINGS_INPUT = [
	{ value: 'owner', label: 'settings.writingEmail.delegates.sendSettingsInput.owner' },
	{ value: 'sender', label: 'settings.writingEmail.delegates.sendSettingsInput.sender' },
	{ value: 'both', label: 'settings.writingEmail.delegates.sendSettingsInput.both' },
	{ value: 'none', label: 'settings.writingEmail.delegates.sendSettingsInput.none' }
];

const COMPOSE_DIR_OPTIONS = [
	{
		labelKey: 'leftToRight',
		value: 'LTR'
	},
	{
		labelKey: 'rightToLeft',
		value: 'RTL'
	}
];

const createComposeDirOptions = () =>
	COMPOSE_DIR_OPTIONS.map(({ labelKey, value }) => (
		<option value={value} key={value}>
			<Text id={`settings.writingEmail.composeDirectionLabels.${labelKey}`} />
		</option>
	));

const createChoiceIput = (inputs, onChange, prefValue) =>
	inputs.map(({ value, label }) => (
		<li>
			<label class={style.label}>
				<ChoiceInput type="radio" value={value} onChange={onChange} checked={prefValue === value} />
				<span class={style.labelText}>
					<Text id={label} />
				</span>
			</label>
		</li>
	));

export default function WritingEmailSettings({ value, onFieldChange, accountData }) {
	const readReceiptsEnabled = get(accountData, 'attrs.zimbraFeatureReadReceiptsEnabled');
	const powerpasteEnabled = get(accountData, 'attrs.zimbraFeaturePowerPasteEnabled');
	const isSMIMEEnable = isSMIMEFeatureAvailable(get(accountData, 'license'));

	const [fontColor, setFontColor] = useState(value.defaultRichTextColor);

	const handleComposeMode = useCallback(
		({ target: { value: zimbraPrefComposeFormat } }) => {
			onFieldChange('zimbraPrefComposeFormat')({
				target: {
					value: zimbraPrefComposeFormat === HTML_MODE ? TEXT_MODE : HTML_MODE
				}
			});
		},
		[onFieldChange]
	);

	const handleFontSizeChange = useCallback(
		({ value: fontSizeValue }) => {
			onFieldChange('defaultRichTextSize')({
				target: {
					value: fontSizeValue
				}
			});
		},
		[onFieldChange]
	);

	const handleFontFamilyChange = useCallback(
		({ value: fontValue }) => {
			onFieldChange('defaultRichTextFont')({
				target: {
					value: fontValue
				}
			});
		},
		[onFieldChange]
	);

	const handleFontColorChange = useCallback(
		({ target: { value: colorValue } }) => {
			setFontColor(colorValue);
			onFieldChange('defaultRichTextColor')({
				target: {
					value: colorValue
				}
			});
		},
		[onFieldChange]
	);

	const renderInlineSelect = selectOption => (
		<InlineSelect
			iconPosition="right"
			iconSize="xs"
			showTooltip={false}
			icon="angle-down"
			class={style.selectButton}
			dropup
			toggleButtonClass={style.toggleButtonClass}
			onChange={selectOption === 'fontSize' ? handleFontSizeChange : handleFontFamilyChange}
			value={selectOption === 'fontSize' ? value.defaultRichTextSize : value.defaultRichTextFont}
		>
			{selectOption === 'fontSize'
				? FONT_SIZES.map(val => (
						<Option class={style.dropdownOption} value={val} key={val} title={val} />
				  ))
				: FONT_FAMILY.map(({ label: fontLabel, value: fontValue }) => (
						<Option
							class={style.dropdownOption}
							value={fontValue}
							key={fontLabel}
							title={fontLabel}
							style={{ fontFamily: fontValue }}
						/>
				  ))}
		</InlineSelect>
	);

	return (
		<div>
			<div class={cx(style.sectionTitle)}>
				<Text id="settings.writingEmail.title" />
			</div>
			<div class={cx(style.subsection, style.notYetImplemented)}>
				<div class={style.subsectionTitle}>
					<Text id="settings.writingEmail.whenSendingMessageSubsection" />
				</div>
				<div class={style.subsectionBody}>
					<ul class={style.list}>
						<li>
							<label>
								<ChoiceInput
									onChange={onFieldChange('whenSendingMessageAddToContacts')}
									checked={value.whenSendingMessageAddToContacts}
								/>
								<Text id="settings.writingEmail.addToContactsLabel" />
							</label>
						</li>
						<li>
							<label>
								<ChoiceInput
									onChange={onFieldChange('whenSendingMessageGenerateLinkPreviews')}
									checked={value.whenSendingMessageGenerateLinkPreviews}
								/>

								<Text id="settings.writingEmail.generateLinkPreview" />
							</label>
						</li>
					</ul>
				</div>
			</div>
			<div class={style.subsection}>
				<div class={style.subsectionTitle}>
					<Text id="settings.writingEmail.undoSendSubsection" />
				</div>
				<div class={style.subsectionBody}>
					<label>
						<ChoiceInput
							checked={value.undoSendEnabled}
							onChange={onFieldChange('undoSendEnabled')}
						/>

						<Text id="settings.writingEmail.enable" />
					</label>
				</div>
			</div>
			{readReceiptsEnabled && (
				<div class={style.subsection}>
					<div class={style.subsectionTitle}>
						<Text id="settings.writingEmail.requestReadReceipt" />
					</div>
					<div class={style.subsectionBody}>
						<label>
							<ChoiceInput
								checked={value.requestReadReceipt}
								onChange={onFieldChange('requestReadReceipt')}
							/>
							<Text id="settings.writingEmail.enableRequestReadReceipt" />
						</label>
					</div>
				</div>
			)}
			<div class={style.subsection}>
				<div class={style.subsectionTitle}>
					<Text id="settings.writingEmail.saveToSent" />
				</div>
				<div class={style.subsectionBody}>
					<label>
						<ChoiceInput checked={value.saveToSent} onChange={onFieldChange('saveToSent')} />
						<Text id="settings.writingEmail.enable" />
					</label>
				</div>
			</div>
			<div class={cx(style.subsection, style.notYetImplemented)}>
				<div class={cx(style.subsectionTitle, style.forSelect)}>
					<Text id="settings.writingEmail.defaultSendingAccountSubsection" />
				</div>
				<div class={style.subsectionBody}>
					<Select disabled>
						<option>
							<Text id="settings.writingEmail.primary" fields={{ email: 'john.doe@example.com' }} />
						</option>
					</Select>
				</div>
			</div>
			<div class={cx(style.subsection, style.notYetImplemented)}>
				<div class={style.subsectionTitle}>
					<Text id="settings.writingEmail.sendOnlyAddressSubsection" />
				</div>
				<div class={style.subsectionBody}>
					<div>
						<input type="text" class={style.textInput} disabled />
						<Button>
							<Text id="buttons.verify" />
						</Button>
					</div>
					<Text id="settings.writingEmail.editSignature" />
				</div>
			</div>
			<div class={style.subsection}>
				<div class={style.subsectionTitle}>
					<Text id="settings.writingEmail.delegates.delegates" />
				</div>
				<div class={style.subsectionBody}>
					<div>
						<DelegatesList />
					</div>
				</div>
			</div>
			<div class={style.subsection}>
				<div class={cx(style.subsectionTitle, style.forSelect)}>
					<Text id="settings.writingEmail.delegates.sendSettingsSubsection" />
				</div>
				<div class={style.subsectionBody}>
					<ul class={style.list}>
						{createChoiceIput(
							DELEGATE_SEND_SETTINGS_INPUT,
							onFieldChange('delegatedSendSaveTarget'),
							value.delegatedSendSaveTarget
						)}
					</ul>
				</div>
			</div>
			<div class={cx(style.sectionTitle)}>
				<Text id="settings.writingEmail.composerTitle" />
			</div>
			<div class={style.composerDefaultSection}>
				<div>
					<Text id="settings.writingEmail.composerDefaultText" />
				</div>
				<div class={style.composerDefaultValues}>
					{renderInlineSelect('fontSize')}
					{renderInlineSelect('fontFamily')}
					<span class={style.textColorpicker}>
						<input
							type="color"
							value={value.defaultRichTextColor}
							onChange={handleFontColorChange}
							class={style.colorPicker}
						/>
						<span style={{ color: fontColor }}>
							<Text id="settings.writingEmail.fontColorLabel" />
							<Icon name="angle-down" size="xs" class={style.dropDownIcon} />
						</span>
					</span>
				</div>
			</div>
			{!isSMIMEEnable && (
				<div class={style.subsection}>
					<div class={cx(style.subsectionTitle, style.plainTextSetting)}>
						<Text id="settings.writingEmail.composeAsPlainText" />
					</div>
					<div class={style.subsectionBody}>
						<label>
							<ChoiceInput
								value={value.zimbraPrefComposeFormat}
								checked={value.zimbraPrefComposeFormat === TEXT_MODE}
								onChange={handleComposeMode}
							/>

							<Text id="settings.writingEmail.enable" />
						</label>
					</div>
				</div>
			)}
			<div class={cx(style.subsection)}>
				<div class={cx(style.subsectionTitle, style.forSelect)}>
					<Text id="settings.writingEmail.composeDirection" />
				</div>
				<div class={style.subsectionBody}>
					<Select
						onChange={onFieldChange('defaultComposeDirection')}
						value={value.defaultComposeDirection}
					>
						{createComposeDirOptions()}
					</Select>
				</div>
			</div>
			{powerpasteEnabled && (
				<div class={cx(style.subsection)}>
					<div class={cx(style.subsectionTitle)}>
						<Text id="settings.writingEmail.advancedPaste" />
					</div>
					<div class={style.subsectionBody}>
						<label>
							<ChoiceInput
								checked={value.powerPastePrefEnabled}
								onChange={onFieldChange('powerPastePrefEnabled')}
							/>
							<Text id="settings.writingEmail.enable" />
						</label>
					</div>
				</div>
			)}
		</div>
	);
}
