import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';

const initialState = {
	selectedIds: [],
	navigatedId: undefined,
	counterId: null,
	counterAccepted: false,
	msgAttachmentGroups: [],
	deletedConversationItems: []
};

export default handleActions(
	{
		[actionCreators.clearSelected]: state =>
			!state.selectedIds.length
				? state
				: {
						...state,
						selectedIds: []
				  },
		[actionCreators.setSelected]: (state, action) => {
			const { selectedIds, navigatedId } = state;
			const { item, isNavigated } = action.payload;
			let selectedIdsArray = [];

			if (item) {
				selectedIdsArray = selectedIds.filter(i => i.id !== item.id);
				selectedIdsArray.push(item);
			}
			return {
				...state,
				selectedIds: selectedIdsArray,
				navigatedId: isNavigated ? item?.id : navigatedId
			};
		},
		[actionCreators.setDeletedConversationItems]: (state, action) => ({
			...state,
			deletedConversationItems: action.payload
		}),
		[actionCreators.clearDeletedConversationItems]: state => ({
			...state,
			deletedConversationItems: []
		}),
		[actionCreators.toggleSelected]: (state, action) => {
			const {
				item,
				item: { id: itemId },
				isChecked
			} = action.payload;
			const { selectedIds } = state;
			return {
				...state,
				selectedIds: isChecked
					? selectedIds.concat([item])
					: selectedIds.filter(i => i.id !== itemId),
				navigatedId: itemId
			};
		},
		[actionCreators.toggleAllSelected]: (state, action) => ({
			...state,
			selectedIds: state.selectedIds.length === 0 ? action.payload.items : [],
			navigatedId: undefined
		}),
		[actionCreators.setCounterAcceptedMailId]: (state, action) => ({
			...state,
			counterId: action.payload
		}),
		[actionCreators.setCounterAccepted]: (state, action) => ({
			...state,
			counterAccepted: action.payload
		}),
		[actionCreators.addAttachmentGroup]: (state, action) => {
			const { msgAttachmentGroups } = state;
			const { origMsgId, attachments } = action.payload;
			const attachmentGroups = msgAttachmentGroups.filter(group => group.origMsgId !== origMsgId);

			if (attachments.length > 0) {
				attachmentGroups.push(action.payload);
			}

			return {
				...state,
				msgAttachmentGroups: attachmentGroups
			};
		},
		[actionCreators.removeAttachmentsFromGroup]: (state, action) => {
			const { msgAttachmentGroups } = state;

			const updatedAttachmentGroups = msgAttachmentGroups.map(group =>
				group.origMsgId === action.payload
					? {
							origMsgId: action.payload,
							attachments: [],
							attachmentsRemoved: true
					  }
					: group
			);

			return {
				...state,
				msgAttachmentGroups: updatedAttachmentGroups
			};
		},
		[actionCreators.removeAttachmentGroup]: (state, action) => {
			const { msgAttachmentGroups } = state;

			const remainingGroups = msgAttachmentGroups.filter(
				group => group.origMsgId !== action.payload
			);

			return {
				...state,
				msgAttachmentGroups: remainingGroups
			};
		}
	},
	initialState
);
