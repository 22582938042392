import { Fragment } from 'preact';
import { Text } from 'preact-i18n';
import {
	BRIEFCASE_VIEW,
	CALENDAR_VIEW,
	CONTACTS_VIEW,
	MAIL_VIEW,
	TASK_VIEW
} from '../../constants/views';
import { TRASH } from '../../constants/folders';
import FormatBytesTranslation from '../format-bytes-translation';

function FolderInfoTooltip({ folder, view }) {
	return (
		<Fragment>
			<span>
				{folder.name === TRASH ? (
					<Text id="folderlist.item.items" />
				) : view === MAIL_VIEW ? (
					<Text id="folderlist.item.mailView.messages" />
				) : view === CALENDAR_VIEW ? (
					<Text id="folderlist.item.calendarView.appointments" />
				) : view === CONTACTS_VIEW ? (
					<Text id="folderlist.item.contactsView.contacts" />
				) : view === BRIEFCASE_VIEW ? (
					<Text id="folderlist.item.briefcaseView.files" />
				) : view === TASK_VIEW ? (
					<Text id="folderlist.item.taskView.tasks" />
				) : (
					<Text id="folderlist.item.items" />
				)}
				<div>{folder.nonFolderItemCount || 0}</div>
			</span>
			{view === MAIL_VIEW && folder.unread > 0 && (
				<span>
					<div>
						<Text id="folderlist.item.mailView.unread" />
					</div>
					<div>{folder.unread}</div>
				</span>
			)}
			{(view === MAIL_VIEW || folder.name === TRASH) && folder.nonFolderItemCountTotal > 0 && (
				<span>
					<div>
						<Text id="folderlist.item.size" />
					</div>
					<div>
						<FormatBytesTranslation bytes={folder.nonFolderItemCountTotal || 0} decimals={1} />
					</div>
				</span>
			)}
		</Fragment>
	);
}

export default FolderInfoTooltip;
