import { Fragment } from 'preact';
import { Text } from 'preact-i18n';
import { useState, useCallback } from 'preact/hooks';
import get from 'lodash-es/get';
import { flattenFolders } from '../../../utils/folders';
import style from '../style';
import GranteeList from './grantee-list';
import { HeaderField, ItemField } from './common';
import { useFoldersQuery } from '../../../hooks/folders';
import memoize from 'lodash-es/memoize';

const SHARING_TYPES = ['sharedWithMe', 'sharedByMe'];

function ShareItem({ item, folderName, granteeList = [], matchesScreenMd }) {
	const { ownerEmail, granteeName, rights, view: viewType } = item;

	const [expanded, setExpanded] = useState(false);

	const toggleDetails = useCallback(() => {
		setExpanded(value => !value);
	}, []);

	return (
		<div class={style.sharingItem}>
			<HeaderField
				name={folderName}
				viewType={viewType || 'unknown'}
				onToggleDetails={toggleDetails}
				matchesScreenMd={matchesScreenMd}
			/>
			{expanded &&
				(granteeList.length > 0 ? (
					<GranteeList granteeList={granteeList} viewType={viewType} />
				) : (
					<Fragment>
						<ItemField field={<Text id="settings.sharing.owner" />} value={ownerEmail} />
						<ItemField field={<Text id="settings.sharing.sharedWith" />} value={granteeName} />
						<ItemField
							field={<Text id="settings.sharing.myRole" />}
							value={
								<Text id={`shareItemModal.fields.permissions.options.${rights.replace(' ', '_')}`}>
									{rights}
								</Text>
							}
						/>
					</Fragment>
				))}
		</div>
	);
}

const getShareToMeItems = memoize((shareInfo, matchesScreenMd, linkedFolders) =>
	shareInfo.reduce((results, item) => {
		const { mid, ownerId, folderId } = item;
		const folder = linkedFolders.find(f => [mid, `${ownerId}:${folderId}`].includes(f.id));

		if (folder) {
			results.push(
				<ShareItem folderName={folder.name} item={item} matchesScreenMd={matchesScreenMd} />
			);
		}

		return results;
	}, [])
);

const getSharedByMeItems = memoize((folders, matchesScreenMd) =>
	folders.reduce((results, item) => {
		if (item.acl) {
			const granteeList = get(item, 'acl.grant') || [];

			if (granteeList.length > 0) {
				results.push(
					<ShareItem
						item={item}
						granteeList={granteeList}
						folderName={item.name}
						matchesScreenMd={matchesScreenMd}
					/>
				);
			}
		}

		return results;
	}, [])
);

function SharingSettings({ shareInfo = [], matchesScreenMd }) {
	const { data } = useFoldersQuery({
		variables: {
			view: null
		}
	});

	const rootFolder = get(data, 'getFolder.folders.0'); // this we need to check if user shared his entire mailbox so extracting it.
	const linkedFolders = flattenFolders([...(get(rootFolder, 'linkedFolders') || [])]);
	const userFolders = flattenFolders([...(get(rootFolder, 'folders') || [])]);
	const allFolders = userFolders.concat(rootFolder);

	const shareToMeItems =
		linkedFolders.length > 0 &&
		shareInfo.length > 0 &&
		getShareToMeItems(shareInfo, matchesScreenMd, linkedFolders);

	const sharedByMeItems = rootFolder && getSharedByMeItems(allFolders, matchesScreenMd);

	return (
		<div class={style.sharing}>
			{SHARING_TYPES.map(type => {
				const arrayOfItems = type === 'sharedWithMe' ? shareToMeItems : sharedByMeItems;

				return (
					<Fragment>
						<div class={style.shareType}>
							<Text id={`settings.sharing.${type}`} />
						</div>
						{arrayOfItems.length > 0 ? (
							<div class={style.shareInfo}>{arrayOfItems}</div>
						) : (
							<div class={style.nothingToShow}>
								<Text id={`settings.sharing.nothingToShow`} />
							</div>
						)}
					</Fragment>
				);
			})}
		</div>
	);
}

export default SharingSettings;
