import { Text } from 'preact-i18n';
import { PureComponent } from 'preact/compat';
import get from 'lodash-es/get';
import omit from 'lodash-es/omit';
import update from 'immutability-helper';
import {
	specialFolders as computeSpecialFolders,
	customFolders as computeCustomFolders,
	filteredFolders,
	sharedFolderDropKey,
	specialBriefcaseFileSharedFolder
} from '../../utils/folders';
import linkstate from 'linkstate';
import { pluck, callWith } from '../../lib/util';
import { Icon } from '@zimbra/blocks';
import FolderListItem from './item';
import FolderListGroup from './group';
import NewFolder from './new-folder';
import FolderInput from '../folder-input';
import ZimletSlot from '../zimlet-slot';
import { connect } from 'react-redux';
import cx from 'classnames';
import { defaultProps, withProps, branch, renderNothing } from 'recompose';
import style from './style';
import { BRIEFCASE_VIEW, MAIL_VIEW } from '../../constants/views';
import { OUTBOX, CONTACTS } from '../../constants/folders';
import { closeCompose } from '../../store/email/actions';
import { notify, clear } from '../../store/notifications/actions';
import { setTrashFolder } from '../../store/trash-folder/actions';
import { setJunkFolder } from '../../store/junk-folder/actions';
import withMediaQuery from '../../enhancers/with-media-query';
import { minWidth, screenSmMax, screenMd } from '../../constants/breakpoints';
import {
	getMailboxMetadata,
	withSetMailboxMetaData
} from '../../graphql-decorators/mailbox-metadata';
import { normalizeFoldersExpanded, serializeFoldersExpanded } from '../../utils/prefs';
import { isOfflineModeEnabled } from '../../utils/offline';
import withCreateFolderMutation from '../../graphql-decorators/create-folder';
import {
	getAllowedFoldersForMove,
	getAllowedLocalFoldersForMove,
	addDropKey
} from '../../utils/mail-list';
import {
	getFolderNameValidationStatus,
	INVALID_FOLDER_NAME_ERRORS,
	FOLDER_NAME_CHAR_LIMIT
} from './util';
import SearchQuery from '../../graphql/queries/search/search.graphql';
import { faultCode, errorMessage } from '../../utils/errors';
import withPreference from '../../graphql-decorators/preferences/get-preferences';
import { withModifyPrefs } from '../../graphql-decorators/preferences';
import { PREF_TAG_TREE_OPEN } from '../../constants/user-prefs';
import CreateTagModal from '../tags/create-tags-modal';
import { setPstStatus } from './../../store/pst-import/actions';
import { CONVERSATION } from '../../constants/types';
import ActionMutation from '../../graphql/queries/action.graphql';
import { USER_FOLDER_IDS, USER_ROOT_FOLDER_ID } from '../../constants';
import { graphql } from '@apollo/client/react/hoc';
import { isActiveFolder } from '../../utils/routing';
import { getCurrentUrl, route } from 'preact-router';

import { setShowAdvanced } from '../../store/navigation/actions';
import { setActiveSearch } from '../../store/search/actions';
import { clearSelected } from '../../store/mail/actions';
import { hide } from '../../store/sidebar/actions';

import withTagAction from '../../graphql-decorators/tags/tag-action';
import { withModifyContactList } from '../../graphql-decorators/contact/create-modify-list';

@branch(({ folders }) => !folders, renderNothing)
@defaultProps({ view: MAIL_VIEW })
@withSetMailboxMetaData()
@withTagAction()
@withModifyContactList()
@withModifyPrefs()
@withCreateFolderMutation()
@graphql(ActionMutation, {
	props: ({ mutate }) => ({ action: mutate })
})
@withMediaQuery(minWidth(screenSmMax))
@withMediaQuery(minWidth(screenMd), 'matchesScreenMd')
@connect(
	state => ({
		isOffline: get(state, 'network.isOffline'),
		trashFolder: get(state, 'trashFolder.folderInfo'),
		junkFolder: get(state, 'junkFolder.folderInfo'),
		activeAccountId: get(state, 'activeAccount.id'),
		pstFileName: get(state, 'importPst.pstFileName'),
		pstRootFolderId: get(state, 'importPst.pstRootFolderId'),
		pstLoading: get(state, 'importPst.loading'),
		pstData: get(state, 'importPst.data'),
		pstError: get(state, 'importPst.error'),
		reloadLocalFolderList: get(state, 'importPst.reloadLocalFolderList'),
		emailAddress: get(state, 'email.account.name'),
		checkedTags: get(state, 'tags.checkedTags')
	}),
	{
		closeCompose,
		notifyAction: notify,
		clear,
		setTrashFolder,
		setJunkFolder,
		setPstStatus,
		notify,
		setShowAdvanced,
		setActiveSearch,
		hide,
		clearSelected
	}
)
@withProps(({ folders, indexFolderName }) => ({
	indexFolder: folders && pluck(folders, 'name', indexFolderName)
}))
@withPreference(({ data: { getPreferences: prefs } }) => ({
	zimbraPrefWebClientOfflineBrowserKey: get(prefs, 'zimbraPrefWebClientOfflineBrowserKey') || '',
	zimbraPrefTagTreeOpen: get(prefs, 'zimbraPrefTagTreeOpen')
}))
@getMailboxMetadata()
export default class FolderList extends PureComponent {
	prevTargetItem = null;
	expandTimeOut = null;

	state = {
		isAddingNewFolder: false,
		isAddingNewTag: false,
		isFindingFolder: false,
		searchQuery: '',
		isAddingNewLocalFolder: false,
		toggleLocalTreeOpen: !!(this.props.localFolders && this.props.localFolders.length > 0),
		localFolderArray:
			this.props.localFolders &&
			getAllowedLocalFoldersForMove(
				this.props.arrayOfFlags,
				this.props.localFolders,
				this.props.pstLoading && this.props.pstRootFolderId
			),
		sourceFolder: null
	};

	getTrashFolder({ folders, trashFolder }) {
		const trash = folders.find(f => f.name === 'Trash');

		if (trashFolder && trash && trashFolder.id !== trash.id) {
			this.props.setTrashFolder(trash);
		}
	}

	getJunkFolder({ folders, junkFolder }) {
		const junk = folders.find(f => f.name === 'Junk');

		if (junkFolder && junk && junkFolder.id !== junk.id) {
			this.props.setJunkFolder(junk);
		}
	}

	handleAfterAction = isLocalFolder => {
		if (isLocalFolder) {
			this.props.refetchLocalFolders();
		} else {
			this.props.refetchFolders();
		}
	};

	folderMap =
		(params = {}) =>
		folder =>
			this.folderLink({
				folder,
				foldersExpanded: this.props.foldersExpanded,
				menu: this.props.defaultContextMenu,
				...params
			});

	folderLink = ({
		folder,
		menu,
		foldersExpanded = {},
		disableCollapse = false,
		grouped = false
	}) => {
		const {
			zimbraDumpsterEnabled,
			zimbraFeatureSharingEnabled,
			urlSlug,
			badgeProp,
			onDrop,
			dropEffect,
			view,
			folders,
			urlSuffixProp,
			smartFolders,
			indexFolder,
			folderNameProp,
			customContextMenus,
			defaultShowEllipsis,
			zimbraPrefWebClientOfflineBrowserKey,
			tags,
			downloadInProgress,
			refetchFolders,
			screen,
			isGalFolder,
			action,
			refresh,
			handleCheck,
			isCheckEnabled,
			inlineCheckBoxStyle,
			contextMenu,
			folderDetails,
			folderListItemClass,
			subFolderClass,
			isOffline,
			emailAddress,
			notify: notifyAction,
			setShowAdvanced: setShowAdvancedAction,
			setActiveSearch: setActiveSearchAction,
			hide: hideAction,
			clearSelected: clearSelectedAction,
			tagAction,
			matchesScreenMd,
			modifyContactList,
			checkedTags,
			pstLoading,
			pstFileName
		} = this.props;

		const { localFolderArray, sourceFolder } = this.state;
		const showPstLoading = pstFileName === folder.name && pstLoading;

		return (
			<FolderListItem
				isOffline={isOffline}
				emailAddress={emailAddress}
				notify={notifyAction}
				setShowAdvanced={setShowAdvancedAction}
				setActiveSearch={setActiveSearchAction}
				hide={hideAction}
				clearSelected={clearSelectedAction}
				tagAction={tagAction}
				matchesMediaQuery={matchesScreenMd}
				modifyContactList={modifyContactList}
				key={folder.id}
				folderDetails={folderDetails}
				zimbraDumpsterEnabled={zimbraDumpsterEnabled}
				zimbraFeatureSharingEnabled={zimbraFeatureSharingEnabled}
				action={action}
				afterAction={this.handleAfterAction}
				createFolder={this.createFolder}
				indexFolder={indexFolder}
				folder={folder}
				tags={tags}
				folders={folder.query ? smartFolders : folders}
				menu={menu}
				view={view}
				screen={screen}
				depth={1}
				urlSlug={urlSlug}
				urlSuffixProp={urlSuffixProp}
				badgeProp={badgeProp}
				onDrop={!folder.query && onDrop}
				onItemClick={this.handleItemClick}
				folderToDrop={sourceFolder}
				dropEffect={dropEffect}
				folderDrop={this.folderDrop}
				onFolderDragStart={linkstate(this, 'sourceFolder')}
				onFolderDrop={linkstate(this, 'sourceFolder')}
				onDragOverExpandFolder={this.handleDragOverExpandFolder}
				foldersExpanded={foldersExpanded}
				onToggleExpanded={this.setFolderExpanded}
				disableCollapse={disableCollapse}
				customContextMenus={customContextMenus}
				defaultShowEllipsis={defaultShowEllipsis}
				grouped={grouped}
				onRouteOnFolderActive={this.handleRouteOnFolderActive}
				folderNameProp={folderNameProp}
				isSearchFolder={Boolean(folder.query)}
				hideFolder={
					folder.name === OUTBOX && !isOfflineModeEnabled(zimbraPrefWebClientOfflineBrowserKey)
				}
				localFolders={localFolderArray}
				showPstLoading={showPstLoading}
				onCreateLocalFolder={this.createFolder}
				downloadInProgress={downloadInProgress}
				handleMoveToLocal={this.handleMoveToLocal}
				refetchFolders={refetchFolders}
				isGalFolder={isGalFolder}
				refresh={refresh}
				isCheckEnabled={isCheckEnabled}
				handleCheck={handleCheck}
				inlineCheckBoxStyle={inlineCheckBoxStyle}
				contextMenu={contextMenu}
				folderListItemClass={folderListItemClass}
				subFolderClass={subFolderClass}
				{...((folder.externalAccount ||
					(!folder.local && folder.id === USER_FOLDER_IDS.INBOX.toString())) && {
					onIconClick: this.onIconClickHandler
				})}
				{...(isCheckEnabled &&
					checkedTags.length && { isTagChecked: checkedTags.includes(folder.id) })}
			/>
		);
	};

	onIconClickHandler = (folder, e) => {
		e.preventDefault();
		e.stopPropagation();
		if (folder?.externalAccount) {
			this.props.onIconClick(folder);
		} else {
			return this.handleItemClick();
		}
	};

	handleItemClick = () => {
		const { refresh, isOffline } = this.props;
		if (!isOffline && refresh) {
			return refresh();
		}
	};

	setFolderTreeOpen = (key, val) => {
		this.props.setMailboxMetadata({
			[key]: val
		});
	};

	setSmartFolderTreeOpen = val => {
		if (this.props.smartFolderTreeOpen !== val) {
			this.props.setMailboxMetadata({
				zimbraPrefSmartFolderTreeOpen: val
			});
		}
	};

	setFolderExpanded = (id, val) => {
		const { zimbraPrefFoldersExpanded } = this.props.mailboxMetadata;
		this.props.setMailboxMetadata({
			zimbraPrefFoldersExpanded: serializeFoldersExpanded({
				...normalizeFoldersExpanded(zimbraPrefFoldersExpanded),
				[id]: val
			})
		});
	};

	validateFolderName = (name, isLocalFolder) => {
		const { isValid, notifyMessageID } = getFolderNameValidationStatus(name, isLocalFolder);

		if (!isValid) {
			const message =
				notifyMessageID !== INVALID_FOLDER_NAME_ERRORS.LENGTH_EXCEED_WARNING ? (
					<Text id={`notifications.${notifyMessageID}`} />
				) : (
					<Text
						id={`notifications.${notifyMessageID}`}
						fields={{ count: FOLDER_NAME_CHAR_LIMIT }}
					/>
				);

			this.props.notifyAction({
				failure: true,
				message
			});
		}
		return isValid;
	};

	handleOpenNewTopLevelFolder = () => {
		this.setState({
			isAddingNewFolder: true,
			isFindingFolder: false
		});

		if (!this.props.folderTreeOpen) {
			this.setFolderTreeOpen('zimbraPrefCustomFolderTreeOpen', true);
		}
	};

	handleOpenNewTopLevelLocalFolder = () => {
		this.setState({
			isAddingNewLocalFolder: true,
			toggleLocalTreeOpen: true
		});
	};

	handleFolderSearchOpen = () => {
		this.setState({
			isFindingFolder: true,
			isAddingNewFolder: false
		});

		if (!this.props.folderTreeOpen) {
			this.setFolderTreeOpen('zimbraPrefCustomFolderTreeOpen', true);
		}
	};

	handleSmartFolderSearchOpen = () => {
		this.setState({
			isFindingSmartFolder: true
		});
		this.setSmartFolderTreeOpen(true);
	};

	handleFolderPlusClick = e => {
		e.stopPropagation();
		this.handleOpenNewTopLevelFolder();
	};

	handleLocalFolderPlusClick = e => {
		e.stopPropagation();
		this.handleOpenNewTopLevelLocalFolder();
	};

	handleFolderSearchClick = e => {
		e.stopPropagation();
		this.handleFolderSearchOpen();
	};

	handleSmartFolderSearchClick = e => {
		e.stopPropagation();
		this.handleSmartFolderSearchOpen();
	};

	handleTagPlusClick = e => {
		e.stopPropagation();
		this.setState({
			isAddingNewTag: true
		});
	};

	handleRouteOnFolderActive = (sourceFolder, destFolder) => {
		const { urlSlug } = this.props;
		if (isActiveFolder(sourceFolder, getCurrentUrl(), urlSlug)) {
			const folderName =
				destFolder && destFolder.absFolderPath && destFolder.absFolderPath.substr(1);
			const newFolderPath = encodeURIComponent(
				folderName ? `${folderName}/${sourceFolder.name}` : sourceFolder.name
			);

			route(`/${urlSlug}/${newFolderPath}`, true);
		}
	};

	handleCreateTopLevelFolder = (isLocalFolder = false, name) => {
		const { view, selectedAccount } = this.props;

		if (!this.validateFolderName(name, isLocalFolder)) {
			return;
		}

		if (isLocalFolder) {
			this.setState({
				toggleLocalTreeOpen: true,
				isAddingNewLocalFolder: false
			});
		} else {
			this.setState({ isAddingNewFolder: false });
		}

		return this.createFolder({
			name,
			isLocalFolder,
			deletable: isLocalFolder,
			view,
			parentFolderId: get(selectedAccount, 'folderId', null)
		});
	};

	handleMoveToLocal = folder => {
		const { name, absFolderPath } = folder;
		const { downloadEmails, deleteFolder, notifyAction } = this.props;
		this.context.client
			.query({
				query: SearchQuery,
				variables: {
					types: 'message',
					query: `in:"${absFolderPath}"`,
					recip: 2,
					sortBy: 'dateDesc',
					limit: 1000
				}
			})
			.then(({ data }) => {
				const messages = get(data, 'search.messages');
				const ids = messages && messages.map(message => message.id);
				ids
					? downloadEmails(ids, name, folder)
					: folder && !folder.folders
					? deleteFolder(folder, true)
					: notifyAction({
							message: <Text id="local_folder.folderMove.success" fields={{ folder: name }} />
					  });
			});
	};

	setLocalFolderDroppableValue = value => {
		this.setState(state => {
			const localFolderArray = state.localFolderArray;
			return {
				localFolderArray: update(localFolderArray, {
					0: {
						$merge: {
							droppable: !value
						}
					}
				})
			};
		});
	};

	createFolder = options =>
		this.props
			.createFolder({
				variables: options,
				context: {
					local: options.isLocalFolder
				}
			})
			.then(() => {
				const { isLocalFolder } = options;
				if (isLocalFolder) {
					// Reload folder list to reflect the newly created folder
					this.props.refetchLocalFolders();
				} else {
					this.props.refetchFolders();
				}
			})
			.catch(err => {
				const errCode = faultCode(err);
				let localFolderError;

				// if it's not a fault error, handle local store error.
				if (!errCode) {
					localFolderError = errorMessage(err);
				}

				this.props.notifyAction({
					failure: true,
					message: localFolderError ? (
						<Text id={localFolderError} />
					) : errCode === 'mail.ALREADY_EXISTS' ? (
						<Text id={`faults.${errCode}_folder`} fields={{ name: options.name }} />
					) : (
						err
					)
				});
			});

	handleCloseCreateTopLevelFolder = () => {
		this.setState({ isAddingNewFolder: false });
	};

	handleCloseCreateTopLevelLocalFolder = () => {
		this.setState({
			isAddingNewLocalFolder: false
		});
	};

	handleFolderSearchClose = () => {
		this.setState({
			isFindingFolder: false,
			searchQuery: ''
		});
	};

	handleSmartFolderSearchClose = () => {
		this.setState({
			isFindingSmartFolder: false,
			searchSmartQuery: ''
		});
	};

	handleToggleFolders = () => {
		this.setFolderTreeOpen('zimbraPrefCustomFolderTreeOpen', !this.props.folderTreeOpen);
	};

	handleToggleSmartFolders = () => {
		this.setSmartFolderTreeOpen(!this.props.smartFolderTreeOpen);
	};

	handleToggleSharedFolders = () => {
		this.setFolderTreeOpen('zimbraPrefSharedFolderTreeOpen', !this.props.sharedFolderTreeOpen);
	};

	handleToggleLocalFolders = () => {
		this.setState(state => ({
			toggleLocalTreeOpen: !state.toggleLocalTreeOpen
		}));
	};

	handleToggleTags = () => {
		const { modifyPrefs, zimbraPrefTagTreeOpen } = this.props;

		modifyPrefs({
			[PREF_TAG_TREE_OPEN]: !zimbraPrefTagTreeOpen
		});
	};

	folderDrop = (sourceFolder, destFolder) => {
		this.props
			.action({
				variables: {
					type: 'FolderAction',
					op: 'move',
					id: sourceFolder.id,
					folderId: (destFolder && destFolder.id) || USER_ROOT_FOLDER_ID
				}
			})
			.then(() => {
				this.handleAfterAction();
				this.handleRouteOnFolderActive(sourceFolder, destFolder);
			});

		if (!destFolder) {
			this.setFolderTreeOpen('zimbraPrefCustomFolderTreeOpen', true);
		} else {
			this.setFolderExpanded(destFolder.id, true);
		}
	};

	/**
	 * This function is common when user dragover a folder
	 * and expanding the folder if not expanded
	 */
	handleDragOverExpandFolder = (target, folderId) => {
		if (target !== this.prevTargetItem) {
			if (this.expandTimeOut) this.clearExpandTimeOut();

			if (target) {
				this.expandTimeOut = setTimeout(() => {
					if (this.expandTimeOut)
						if (folderId === USER_ROOT_FOLDER_ID) {
							this.setFolderTreeOpen('zimbraPrefCustomFolderTreeOpen', true);
						} else {
							// trigger setMailboxMetadata request only if the folder has children and is not expanded
							const folder = this.props.folders.find(({ id }) => id === folderId);
							const children = get(folder, 'folders');
							const { foldersExpanded } = this.props;

							if (children && children.length && !foldersExpanded[folder.id]) {
								this.setFolderExpanded(folderId, true);
							}
						}
					this.clearExpandTimeOut();
				}, 1000);
			}

			this.prevTargetItem = target;
		}
	};

	clearExpandTimeOut() {
		clearTimeout(this.expandTimeOut);
		this.expandTimeOut = null;
	}

	handleTagModalClose = () =>
		this.setState({
			isAddingNewTag: false
		});

	static defaultProps = {
		onActiveFolderClick: () => {},
		smartFolders: []
	};

	componentWillMount() {
		this.getTrashFolder(this.props);
		this.getJunkFolder(this.props);
	}

	componentWillReceiveProps(nextProps) {
		const {
			activeAccountId,
			localFolders,
			reloadLocalFolderList,
			refetchLocalFolders,
			arrayOfFlags,
			pstLoading,
			pstFileName,
			pstData,
			pstRootFolderId
		} = nextProps;
		if (typeof process.env.ELECTRON_ENV !== 'undefined' && this.props.view === MAIL_VIEW) {
			if (reloadLocalFolderList && refetchLocalFolders) {
				refetchLocalFolders();
				const { setPstStatus: updatePstStatus } = this.props;
				updatePstStatus({
					isLoading: pstLoading,
					data: pstData,
					error: null,
					reloadLocalFolderList: false,
					pstFileName
				});
			}
		}

		if (activeAccountId !== get(this.props, 'activeAccountId')) {
			this.getTrashFolder(nextProps);
			this.getJunkFolder(nextProps);
		}

		if (localFolders !== this.state.localFolderArray) {
			this.setState({
				localFolderArray:
					localFolders &&
					getAllowedLocalFoldersForMove(arrayOfFlags, localFolders, pstLoading && pstRootFolderId)
			});
		}
	}

	render(
		{
			folders,
			smartFolders,
			sharedFolders = [],
			divided,
			label,
			badgeProp,
			urlSlug,
			slugs,
			onDrop,
			dropEffect,
			collapsibleCustomGroup,
			collapsibleSmartGroup,
			folderTreeOpen,
			foldersExpanded,
			defaultContextMenu,
			specialFolderList,
			hiddenFolderList = [],
			smartFolderTreeOpen,
			showSmartFolders,
			matchesMediaQuery,
			indexFolderName,
			arrayOfFlags,
			view,
			disableLocalFolderForMove,
			tags,
			isTagsSupported,
			zimbraPrefTagTreeOpen,
			sharedFolderTreeOpen,
			isOffline,
			pstLoading,
			viewType,
			isCheckEnabled,
			groupHeaders,
			zimletSlot,
			expanded,
			handleToggleFolders,
			folderGroupName,
			tagGroupClass,
			toggleClass,
			sectionFolders,
			zimbraFeatureSharingEnabled,
			...props
		},
		{
			isAddingNewFolder,
			isFindingFolder,
			isFindingSmartFolder,
			searchQuery,
			searchSmartQuery,
			toggleLocalTreeOpen,
			isAddingNewLocalFolder,
			localFolderArray,
			isAddingNewTag,
			sourceFolder
		}
	) {
		if (!folders || folders.length === 0) {
			return null;
		}
		if ((indexFolderName === CONTACTS || viewType === CONVERSATION) && folders && folders.length) {
			folders = addDropKey(folders, true);
			if (sharedFolders && sharedFolders.length) {
				sharedFolders = sharedFolderDropKey(sharedFolders);
			}
		} else {
			folders = getAllowedFoldersForMove(arrayOfFlags, folders);
		}
		// Remove hidden folders
		folders = computeCustomFolders(folders, hiddenFolderList);

		const sharedFileFolder =
			zimbraFeatureSharingEnabled && specialBriefcaseFileSharedFolder(folders);

		const specialFolders = computeSpecialFolders(folders, specialFolderList);
		const customFolders = computeCustomFolders(folders, specialFolderList);

		const isOfflineDesktopApp = isOffline && typeof process.env.ELECTRON_ENV !== 'undefined';

		if (
			disableLocalFolderForMove !== undefined &&
			disableLocalFolderForMove === get(localFolderArray, '0.droppable')
		) {
			this.setLocalFolderDroppableValue(disableLocalFolderForMove);
		}

		return (
			<div
				{...omit(props, [
					'indexFolder',
					'indexFolderName',
					'view',
					'mailboxMetadata',
					'contextMenu'
				])}
				class={cx(style.folderList, props.class)}
			>
				{specialFolders.map(this.folderMap())}

				<ZimletSlot name="folder-list-middle" />

				{divided && specialFolders.length > 0 && folders.length > 0 && (
					<div class={style.divider}>
						<Text id="folderlist.folders" />
					</div>
				)}

				{smartFolders.length && collapsibleSmartGroup ? (
					<FolderListGroup
						onToggle={this.handleToggleSmartFolders}
						collapsed={!smartFolderTreeOpen}
						menu={false}
						name={
							<div class={style.customFolderToggle}>
								<div class={style.customFolderToggleName}>
									<Text id="folderlist.saved_searches" />
								</div>
								<div class={style.folderGroupAction} onClick={this.handleSmartFolderSearchClick}>
									<Icon name="search" size="sm" />
								</div>
							</div>
						}
					>
						{isFindingSmartFolder && (
							<FolderInput
								value={searchSmartQuery}
								onClose={this.handleSmartFolderSearchClose}
								onInput={linkstate(this, 'searchSmartQuery')}
								class={style.topLevelInput}
								closeOnBlur={false}
								placeholderTextId="mail.folders.FIND_PLACEHOLDER"
								icon="search"
							/>
						)}

						{searchSmartQuery
							? filteredFolders(smartFolders, searchSmartQuery).map(
									this.folderMap({ disableCollapse: true, menu: false })
							  )
							: smartFolders.map(this.folderMap({ grouped: true, menu: false }))}
					</FolderListGroup>
				) : (
					smartFolders.map(this.folderMap({ menu: false }))
				)}

				{divided && specialFolders.length > 0 && folders.length > 0 && (
					<div class={style.divider}>
						<Text id="folderlist.folders" />
					</div>
				)}
				{collapsibleCustomGroup ? (
					isCheckEnabled ? (
						groupHeaders.map(key => {
							const renderItem = sectionFolders(key).map(this.folderMap({ grouped: true }));
							const zimletSlotItem = zimletSlot(key);
							return matchesMediaQuery ? (
								<FolderListGroup
									onToggle={callWith(handleToggleFolders, key)}
									collapsed={!expanded.includes(key)}
									name={folderGroupName(key)}
									toggleClass={toggleClass}
								>
									{renderItem}
									{zimletSlotItem}
								</FolderListGroup>
							) : (
								[renderItem, zimletSlotItem]
							);
						})
					) : (
						<FolderListGroup
							onToggle={this.handleToggleFolders}
							onCreateFolder={this.handleOpenNewTopLevelFolder}
							onFindFolder={this.handleFolderSearchOpen}
							collapsed={!folderTreeOpen}
							customFolders={customFolders}
							sourceFolder={sourceFolder}
							folderDrop={this.folderDrop}
							onFolderDrop={linkstate(this, 'sourceFolder')}
							onRouteOnFolderActive={this.handleRouteOnFolderActive}
							onDragOverExpandFolder={this.handleDragOverExpandFolder}
							name={
								<div class={style.customFolderToggle}>
									<div class={style.customFolderToggleName}>
										<Text id="folderlist.folders" />
									</div>
									{matchesMediaQuery && (
										<div class={style.folderGroupAction} onClick={this.handleFolderSearchClick}>
											<Icon name="search" size="sm" />
										</div>
									)}
									{!isOfflineDesktopApp && (
										<div class={style.folderGroupAction} onClick={this.handleFolderPlusClick}>
											<Icon name="plus" size="sm" />
										</div>
									)}
								</div>
							}
						>
							<ZimletSlot name="folder-group" />

							{isAddingNewFolder && (
								<NewFolder
									class={style.topLevelInput}
									onSubmit={callWith(this.handleCreateTopLevelFolder, false, true)}
									onClose={this.handleCloseCreateTopLevelFolder}
								/>
							)}

							{isFindingFolder && (
								<FolderInput
									value={searchQuery}
									onClose={this.handleFolderSearchClose}
									onInput={linkstate(this, 'searchQuery')}
									class={style.topLevelInput}
									closeOnBlur={false}
									placeholderTextId="mail.folders.FIND_PLACEHOLDER"
									icon="search"
								/>
							)}

							{searchQuery
								? filteredFolders(customFolders, searchQuery).map(
										this.folderMap({ disableCollapse: true })
								  )
								: customFolders.map(this.folderMap({ grouped: true }))}
						</FolderListGroup>
					)
				) : (
					customFolders.map(this.folderMap())
				)}

				{sharedFolders.length > 0 && (
					<FolderListGroup
						onToggle={this.handleToggleSharedFolders}
						collapsed={!sharedFolderTreeOpen}
						name={
							<div class={style.customFolderToggle}>
								<div class={style.customFolderToggleName}>
									<Text id="folderlist.sharedFolders" />
								</div>
							</div>
						}
					>
						{sharedFolders.map(this.folderMap({ grouped: true }))}
					</FolderListGroup>
				)}
				{sharedFileFolder && view === BRIEFCASE_VIEW && [sharedFileFolder].map(this.folderMap())}
				{typeof process.env.ELECTRON_ENV !== 'undefined' && view === MAIL_VIEW && (
					<FolderListGroup
						onToggle={this.handleToggleLocalFolders}
						collapsed={!toggleLocalTreeOpen}
						onCreateFolder={this.handleOpenNewTopLevelLocalFolder}
						name={
							<div class={style.customFolderToggle}>
								<div class={style.customFolderToggleName}>
									<Text id="folderlist.local_folder" />
								</div>
								<div class={style.folderGroupAction} onClick={this.handleLocalFolderPlusClick}>
									<Icon name="plus" size="sm" />
								</div>
							</div>
						}
					>
						{isAddingNewLocalFolder && (
							<NewFolder
								class={style.topLevelInput}
								onSubmit={callWith(this.handleCreateTopLevelFolder, true, true)}
								onClose={this.handleCloseCreateTopLevelLocalFolder}
							/>
						)}
						{localFolderArray && localFolderArray.map(this.folderMap({ grouped: true }))}
					</FolderListGroup>
				)}
				{isTagsSupported && (
					<FolderListGroup
						onToggle={this.handleToggleTags}
						collapsed={!zimbraPrefTagTreeOpen}
						toggleClass={toggleClass}
						tagGroupClass={tagGroupClass}
						name={
							<div class={style.customFolderToggle}>
								<div class={style.customFolderToggleName}>
									<Text id="folderlist.tags" />
								</div>
								{!isOffline && (
									<div class={style.folderGroupAction} onClick={this.handleTagPlusClick}>
										<Icon name="plus" size="sm" />
									</div>
								)}
							</div>
						}
					>
						{tags && tags.map(this.folderMap({ grouped: true }))}
					</FolderListGroup>
				)}
				{isAddingNewTag && <CreateTagModal onClose={this.handleTagModalClose} tags={tags} />}
				<ZimletSlot name="folder-list-end" />
			</div>
		);
	}
}
