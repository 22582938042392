import { useCallback } from 'preact/hooks';
import { Text } from 'preact-i18n';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash-es/get';
import { setAdvanceOptions } from '../../../store/search/actions';
import { getTranslatedFolderName } from '../../folder-list/util';

import { Checkbox } from './checkbox';
import { specialFolders, customFolders } from '../../../utils/folders';
import ActionMenu from '../../action-menu';
import { FolderSearch } from './folder-search';
import style from '../style';

const FolderDropdown = ({ folders, sharedFolders, selectedFolderId, closeDropdown, onChange }) => {
	const folderGroups = [specialFolders(folders), customFolders(folders), sharedFolders];

	const handleSearch = useCallback(
		value => {
			onChange(value);
			closeDropdown();
		},
		[closeDropdown, onChange]
	);

	return (
		<FolderSearch
			folders={folders}
			folderGroups={folderGroups}
			onSearch={handleSearch}
			selectedFolderId={selectedFolderId}
		/>
	);
};

export const SearchIn = ({ folders, sharedFolders }) => {
	const dispatch = useDispatch();
	const { selectedFolder } = useSelector(state => get(state, 'search'));

	const onSearchInChanged = useCallback(
		folder => dispatch(setAdvanceOptions({ selectedFolder: folder })),
		[dispatch]
	);

	return (
		<div class={style.searchIn}>
			<div className={style.searchInItem}>
				<ActionMenu
					label={
						(selectedFolder && getTranslatedFolderName(selectedFolder)) || (
							<Text id="search.allMail" />
						)
					}
					class={style.menu}
				>
					<FolderDropdown
						folders={folders}
						sharedFolders={sharedFolders}
						onChange={onSearchInChanged}
						selectedFolderId={get(selectedFolder, 'id')}
					/>
				</ActionMenu>
			</div>
			{['subject', 'contains'].map(item => (
				<Checkbox key={item} field={item} />
			))}
		</div>
	);
};
