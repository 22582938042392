import cx from 'classnames';
import { Button } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import style from './style.less';
import AdvancedFilterActionInput from './advanced-action-input';

export default function AdvancedActionContainer({
	allActions,
	onActionChange,
	onFolderOrAddressChange,
	onDeleteAction,
	onAddNewAction,
	folders,
	tagList,
	filterMailFeature
}) {
	return (
		<div class={style.advancedActionModal}>
			<div class={cx(style.subsectionTitle, style.thenLabel)}>
				<Text id="settings.filterRuleModal.thenLabel" />
			</div>
			<div>
				{allActions &&
					allActions.map(action => (
						<AdvancedFilterActionInput
							action={action}
							onChange={onActionChange}
							onFolderOrAddressChange={onFolderOrAddressChange}
							onDeleteAction={onDeleteAction}
							folders={folders}
							tagList={tagList}
							filterMailFeature={filterMailFeature}
						/>
					))}
			</div>
			<div class={cx(style.subsectionTitle, style.thenLabel, style.advancedFilterSetting)}>
				<Button styleType="text" onClick={onAddNewAction}>
					<Text id="settings.filterRuleModal.addMoreAction" />
				</Button>
			</div>
		</div>
	);
}
