import { graphql } from '@apollo/client/react/hoc';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
import cloneDeep from 'lodash-es/cloneDeep';
import memoize from 'lodash-es/memoize';
import { compose } from 'recompose';

import { MAIL_VIEW, CONVERSATION_VIEW } from '../../constants/views';
import withAccountInfo from '../account-info';
import { USER_FOLDER_IDS } from '../../constants';
import GetFolder from '../../graphql/queries/folders/get-folder.graphql';
import { canMoveMailInto, recursivelyFilterFolders } from '../../utils/folders';

const EMPTY_ARRAY = [];

const setMoveableFolderFlag = folder => {
	const { name, oname, permissions, folders } = folder;

	const droppable = canMoveMailInto(oname || name, permissions);

	if (droppable !== folder.droppable) {
		folder = {
			...folder,
			droppable
		};
	}

	if (folders) {
		folder = {
			...folder,
			folders: folders.map(setMoveableFolderFlag)
		};
	}

	return folder;
};

const getFolders = memoize(
	folders =>
		folders &&
		folders.filter(
			f =>
				(!f.view || f.view === MAIL_VIEW || f.view === CONVERSATION_VIEW) &&
				parseInt(f.id, 10) !== USER_FOLDER_IDS.CHAT // We don't want to show "Chats" folder
		)
);

const getSharedFolders = memoize(folders =>
	recursivelyFilterFolders(cloneDeep(folders), MAIL_VIEW).map(setMoveableFolderFlag)
);

// Some remote folders do not have a `view` attribute, and should be
// displayed as mail folders. This includes POP3 accounts.
export default function getMailFolders(config = {}) {
	const { options: { fetchPolicy = 'cache-and-network', ...restOptions } = {}, ...restConfig } =
		config;

	return compose(
		withAccountInfo(({ data: { accountInfo } }) => ({
			zimbraFeatureSharingEnabled: get(accountInfo, 'attrs.zimbraFeatureSharingEnabled')
		})),
		graphql(GetFolder, {
			...restConfig,
			options: ({ isOffline }) => ({
				...restOptions,
				variables: {
					view: null
				},
				fetchPolicy: isOffline ? 'cache-only' : fetchPolicy
			}),
			props: ({ data, ownProps: { zimbraFeatureSharingEnabled } }) => {
				if (data.error) {
					console.error('Error getting mail folder data', data.error);
				}

				const context = get(config, 'options.context') || {};
				const isLocalFoldersRequested = get(config, 'options.variables.local');

				if (context.local && isLocalFoldersRequested !== false) {
					const localFolders = get(data, 'getFolder.folders.0.folders');
					return {
						localFolders: {
							data: localFolders,
							error: get(data, 'error'),
							loading: get(data, 'loading'),
							refetch: get(data, 'refetch')
						},
						refetchLocalFolders: get(data, 'refetch')
					};
				}

				const folders = get(data, 'getFolder.folders.0.folders') || EMPTY_ARRAY;

				const sharedFolders = zimbraFeatureSharingEnabled
					? getSharedFolders(get(data, 'getFolder.folders.0.linkedFolders'))
					: EMPTY_ARRAY;

				const smartFolders = get(data, 'getFolder.folders.0.search') || EMPTY_ARRAY;

				return {
					folders: getFolders(folders),
					...(smartFolders && { smartFolders }),
					...(sharedFolders && { sharedFolders }),
					foldersError: get(data, 'error'),
					foldersLoading: get(data, 'loading'),
					inboxFolder: folders && find(folders, f => /^Inbox$/i.test(f.name)),
					refetchFolders: get(data, 'refetch')
				};
			},
			...config
		})
	);
}
