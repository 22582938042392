import AlignedLabel from '../../../../aligned-form/label';
import style from '../../../style';

export default function AccountAlignedLabel({ textId, cssClass, width, noCSS }) {
	return (
		<AlignedLabel
			align="left"
			textId={textId}
			width={width || '175px'}
			{...(!noCSS && { class: cssClass ? cssClass : style.optionalLabel })}
		/>
	);
}
