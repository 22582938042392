import renderToString from 'preact-render-to-string';
import inviteDate from './invite-date';
import { NOTES_SEPARATOR } from '../constants';

export const htmlInviteEmail = ({
	subject,
	organizer,
	start,
	end,
	location,
	attendees,
	repeats,
	body,
	replyTextHTML,
	template,
	intro,
	headerString
}) =>
	renderToString(
		<html lang={template.lang.iso} dir={template.lang.dir}>
			<body id="htmlmode">
				{replyTextHTML && (
					<div dangerouslySetInnerHTML={{ __html: replyTextHTML }} /> // eslint-disable-line react/no-danger
				)}
				{headerString && (
					<div dangerouslySetInnerHTML={{ __html: headerString }} /> // eslint-disable-line react/no-danger
				)}
				<h3>{intro || template.intro}</h3>
				<div>
					<table border="0">
						<tr>
							<th align="left">{template.subject}</th>
							<td>{subject}</td>
						</tr>
						{template.organizer && organizer && (
							<tr>
								<th align="left">{template.organizer}</th>
								<td>
									"{organizer.name}" &lt;{organizer.address}&gt;
								</td>
							</tr>
						)}
						<tr>
							<th align="left">{template.time}</th>
							<td>{inviteDate(start, end)}</td>
						</tr>
						{repeats && (
							<tr>
								<th align="left">{template.repeats}</th>
								<td>{repeats}</td>
							</tr>
						)}
						{location && location.length > 0 && (
							<tr>
								<th align="left">{template.location}</th>
								<td>{location}</td>
							</tr>
						)}
						{attendees && attendees.length > 0 && (
							<tr>
								<th align="left">{template.invitees}</th>
								<td>{attendees.map(a => a.address).join('; ')}</td>
							</tr>
						)}
					</table>
				</div>
				{
					// eslint-disable-next-line preact-i18n/no-text-as-children
					<div>{NOTES_SEPARATOR}</div>
				}
				<br />
				{
					<div dangerouslySetInnerHTML={{ __html: body }} /> // eslint-disable-line react/no-danger
				}
			</body>
		</html>
	);

export const htmlEventEmail = ({ body, template }) =>
	renderToString(
		<html lang={template.lang.iso} dir={template.lang.dir}>
			<body id="htmlmode">
				{
					<div dangerouslySetInnerHTML={{ __html: body }} /> // eslint-disable-line react/no-danger
				}
			</body>
		</html>
	);
