import { Localizer, Text } from 'preact-i18n';
import ActionMenu, { DropDownWrapper } from '../action-menu';
import ActionMenuGroup from '../action-menu-group';
import ActionMenuItem from '../action-menu-item';
import ZimletSlot from '../zimlet-slot';
import s from './style.less';
import cx from 'classnames';

export default function ActionMenuSettings({
	onClickSettings,
	onClickAbout,
	onClickLanguage,
	popoverClass,
	actionButtonClass,
	iconClass,
	userHelpPath
	/**
	 * onClickEmailSupport,
	 * etc...
	 */
}) {
	return (
		<Localizer>
			<ActionMenu
				icon="cog"
				actionButtonClass={actionButtonClass}
				popoverClass={cx(s.popover, popoverClass)}
				iconSize="md"
				iconClass={iconClass}
				iconOnly
				arrow={false}
				anchor="end"
				title={<Text id="header.SETTINGS_AND_MORE" />}
			>
				<DropDownWrapper>
					<ActionMenuGroup>
						<ZimletSlot name="settings-dropdown-item" />
						<ActionMenuItem
							className={s.item}
							onClick={onClickSettings}
							icon="cog"
							nonOfflineDesktopFeature
						>
							<Text id="header.SETTINGS" />
						</ActionMenuItem>
						<ActionMenuItem
							className={s.item}
							onClick={onClickLanguage}
							icon="language"
							nonOfflineDesktopFeature
						>
							<Text id="header.LANGUAGE" />
						</ActionMenuItem>
						<ActionMenuItem
							className={s.item}
							icon="question-circle"
							href={userHelpPath}
							target="_blank"
							rel="noopener noreferrer"
							isExternal
							nonOfflineDesktopFeature
						>
							<Text id="header.help" />
						</ActionMenuItem>
						<ZimletSlot name="settings-dropdown-item-end" />
					</ActionMenuGroup>
					<ActionMenuGroup>
						<ActionMenuItem className={s.item} onClick={onClickAbout} icon="about">
							<Text id="header.ABOUT" />
						</ActionMenuItem>
						<ZimletSlot name="gear-menu" />
					</ActionMenuGroup>
				</DropDownWrapper>
			</ActionMenu>
		</Localizer>
	);
}
