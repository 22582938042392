import { useCallback } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'preact-i18n';
import get from 'lodash-es/get';
import { ChoiceInput } from '@zimbra/blocks';
import { callWith } from '@zimbra/util/src/call-with';
import ActionMenu, { DropDownWrapper } from '../../action-menu';
import ActionMenuGroup from '../../action-menu-group';
import ActionMenuItem from '../../action-menu-item';
import { setAdvanceOptions } from '../../../store/search/actions';
import { TagsField } from './tags';
import style from '../style';
import { UNREAD, FLAGGED, MORE_FILTER_OPTIONS, FILTER_EXCLUSION_TERM } from '../constants';

const getFilteredOptions = (arr = [], op) => {
	const term = FILTER_EXCLUSION_TERM[op] || [];
	if (term.length && arr.length) {
		return arr.filter(f => term.indexOf(f) === -1);
	}
	return arr;
};

export const FilterBy = ({ zimbraFeatureTaggingEnabled }) => {
	const dispatch = useDispatch();
	const statusValues = useSelector(state => get(state, 'search.statusValues', []));
	const statusCount =
		statusValues.length && statusValues.filter(a => [UNREAD, FLAGGED].indexOf(a) === -1).length;

	const handleStatus = useCallback(
		status => {
			if (!status) {
				dispatch(setAdvanceOptions({ statusValues: [] }));
				return;
			}

			let arr = statusValues;
			const index = arr.indexOf(status);

			if (index === -1) {
				arr = getFilteredOptions(arr, status);
				arr = arr.concat(status);
			} else {
				arr.splice(index, 1);
			}

			dispatch(setAdvanceOptions({ statusValues: [...arr] }));
		},
		[dispatch, statusValues]
	);

	return (
		<div class={style.filteredBy}>
			{[UNREAD, FLAGGED].map(item => (
				<div className={style.filterItem}>
					<ChoiceInput
						id={item}
						onChange={callWith(handleStatus, item)}
						checked={statusValues.indexOf(item) >= 0}
					/>
					<label for={item}>
						<Text id={`search.advanced.filterFields.${item}`} />
					</label>
				</div>
			))}

			{zimbraFeatureTaggingEnabled && <TagsField />}

			<div className={style.filterItem}>
				<ActionMenu
					monotone
					anchor="end"
					into={false}
					label={
						<Text
							id="search.advanced.filterFields.moreLabel"
							plural={statusCount}
							fields={{ count: statusCount }}
						/>
					}
				>
					<DropDownWrapper>
						<ActionMenuGroup class={style.actionMenu}>
							<ActionMenuItem onClick={callWith(handleStatus, false)}>
								<Text id="buttons.clear" />
							</ActionMenuItem>
							{MORE_FILTER_OPTIONS.map(option => (
								<ActionMenuItem
									onClick={callWith(handleStatus, option)}
									icon={statusValues.indexOf(option) !== -1 && 'check'}
								>
									<Text id={`search.advanced.filterFields.more.${option}`} />
								</ActionMenuItem>
							))}
						</ActionMenuGroup>
					</DropDownWrapper>
				</ActionMenu>
			</div>
		</div>
	);
};
