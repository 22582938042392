import { Component } from 'preact';
import { Localizer, Text } from 'preact-i18n';
import s from './style.less';

import { parseAddress } from '../../lib/util';
import Avatar from '../avatar';
import CloseButton from '../close-button';
import PermissionsSelect from './permissions-select';
import withGetProfilePhoto from '../../graphql-decorators/contact/get-profile-photo';
import get from 'lodash-es/get';

/**
 * By default fetch profile pics from contact list.
 * TODO: (If required,) Get profile pic from respective list(contact or GAL).
 * Currently not getting any flag to distinguish both.
 */
@withGetProfilePhoto({
	withSearchOptions: {
		skip: ({ grant }) => !grant || !get(grant, 'address')
	},
	withSearchGalOptions: {
		skip: ({ contactWithImage, grant }) => contactWithImage || !grant || !get(grant, 'address')
	}
})
class GrantRow extends Component {
	handleRemoveGrant = () => {
		this.props.onRemoveEmailGrant(this.props.grant);
	};

	handleChangePermissions = e => {
		this.props.onUpdateGrant({
			...this.props.grant,
			permissions: e.target.value
		});
	};

	render({ grant, contactWithImage, thumbnailPhoto, screen }) {
		const parsed = parseAddress(grant.address || grant.zimbraId);

		return (
			<tr class={s.accessTableRow}>
				<td>
					<div class={s.contactPreview}>
						<Avatar
							class={s.avatar}
							thumbnailBase64={thumbnailPhoto}
							contact={contactWithImage}
							email={parsed.address}
						/>
						<div class={s.contactDetails}>
							<div class={s.contactPreviewName}>{parsed.name}</div>
							<div class={s.contactPreviewAddress}>{parsed.address}</div>
						</div>
					</div>
				</td>
				<td>
					<PermissionsSelect
						granteeType={grant.granteeType}
						value={grant.permissions}
						onChange={this.handleChangePermissions}
						screen={screen}
					/>
				</td>
				<td>
					<Localizer>
						<CloseButton
							aria-label={<Text id="buttons.remove" />}
							onClick={this.handleRemoveGrant}
						/>
					</Localizer>
				</td>
			</tr>
		);
	}
}

const ShareAccessSettings = ({ emailGrants, onRemoveEmailGrant, onUpdateGrant, screen }) => (
	<div>
		<div class={s.sectionTitle}>
			<Text id="shareItemModal.accessSection" />
		</div>
		<div class={s.section}>
			<table class={s.accessTable}>
				{emailGrants.length ? (
					emailGrants.map(g => (
						<GrantRow
							grant={g}
							onRemoveEmailGrant={onRemoveEmailGrant}
							onUpdateGrant={onUpdateGrant}
							screen={screen}
						/>
					))
				) : (
					<tr>
						<td>
							<Text id="shareItemModal.noAccess" />
						</td>
					</tr>
				)}
			</table>
		</div>
	</div>
);

export default ShareAccessSettings;
