import moment from 'moment';
import get from 'lodash-es/get';
import { WEEK_DAYS } from '../components/calendar/constants';

export function adjustTimeToLocalTimeZone({ start, end, allDay }, toPreposition) {
	const startUtc = get(start, '0.utc');
	const endUtc = get(end, '0.utc');
	const startDateString = get(start, '0.date');
	const endDateString = get(end, '0.date');
	let startDate;
	let endDate;

	if (startUtc && endUtc) {
		startDate = moment.utc(startUtc).toDate();
		endDate = moment.utc(endUtc).toDate();
	} else if (allDay) {
		// Added this condition since we are not sending time zone for allDay event.
		// So instead of using UTC time used local time.
		startDate = moment(startDateString).toDate();
		endDate = moment(endDateString).toDate();
	} else {
		startDate = moment.utc(startDateString).toDate();
		endDate = moment.utc(endDateString).toDate();
	}

	const startMoment = moment(startDate);
	const endMoment = moment(endDate);

	const dateFormatLeftHandSide = startMoment.format('[mediumLLLL]');
	const dateFormatRightHandSide = startMoment.isSame(endMoment, 'day')
		? endMoment.format('LT')
		: endMoment.format('[mediumLLLL]'); // LT will display hh:mm A

	// ll will display the short date (Feb 13, 2019) and LL will display full date (February 13, 2019).
	const startDayLL = startMoment.format('ll');
	const endDayLL = endMoment.format('ll');

	const dateFormatAllDay =
		startDayLL !== endDayLL ? `${startDayLL} ${toPreposition} ${endDayLL}` : startDayLL;

	return allDay
		? dateFormatAllDay
		: `${dateFormatLeftHandSide} ${toPreposition} ${dateFormatRightHandSide}`;
}

export function getInviteesParticipationStatus(allInvitees) {
	const inviteesType = {};

	const sortedInviteeStatusKey = ['AC', 'TE', 'DE', 'NE'];

	sortedInviteeStatusKey.forEach(inviteeStatus => {
		const invitee = allInvitees.filter(
			({ participationStatus }) => participationStatus === inviteeStatus
		);
		if (invitee.length > 0) {
			inviteesType[inviteeStatus] = invitee;
		}
	});

	return inviteesType;
}

export function reCalculateStartEndDate(
	eventStartDate,
	eventEndDate,
	byDay,
	userSelectedStart = null
) {
	if (byDay?.length) {
		const updatedStartDate = userSelectedStart || eventStartDate;
		const today = moment(updatedStartDate).isoWeekday();
		const byDayIndex = byDay.map(({ day }) => WEEK_DAYS.indexOf(day)).sort((a, b) => a - b);

		// if today is Fri and user has selected [Sat, Mon] then nearestFutureDay is Sat
		// if today is Fri and user has selected [Mon, Wed] then nearestFutureDay is Mon
		const nearestFutureDay =
			byDayIndex.find(selectedDay => selectedDay >= today) ||
			byDayIndex.find(selectedDay => selectedDay < today);
		let newStartDate = null;

		if (today <= nearestFutureDay) {
			newStartDate = moment(updatedStartDate).isoWeekday(nearestFutureDay);
		} else {
			newStartDate = moment(updatedStartDate).add(1, 'weeks').isoWeekday(nearestFutureDay).toDate();
		}
		const diff = moment(eventStartDate).diff(newStartDate);

		return {
			start: newStartDate,
			end: moment(eventEndDate).subtract(diff).toDate().setSeconds(0)
		};
	}
	return;
}
