import { Fragment } from 'preact';
import { useState, useCallback, useEffect } from 'preact/hooks';
import ModalDrawerToolbar from '../modal-drawer-toolbar';
import { Button, Icon, ChoiceInput, LoaderBar } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import cx from 'classnames';
import { useCalendarResourcesWithFreeBusyQuery } from '../../hooks/search-calendar-resources';
import TextInput from '../text-input';
import xorWith from 'lodash-es/xorWith';
import isEqual from 'lodash-es/isEqual';
import { callWith } from '@zimbra/util/src/call-with';
import ResponsiveModal from '../responsive-modal';
import { filterBySearch, getAddressString } from './utils';
import style from './style';

const LocationStats = ({ textId, value }) => (
	<span>
		<Text id={`locationPicker.stats.${textId}`} fields={{ value }} />
	</span>
);

const LocationDetails = ({
	fullName,
	isBusy,
	zimbraCalResSite,
	zimbraCalResBuilding,
	zimbraCalResFloor,
	zimbraCalResRoom,
	zimbraCalResCapacity,
	...rest
}) => (
	<div class={style.locationDetails}>
		{fullName && <strong>{fullName}</strong>}
		{isBusy && <span class={style.busyLabel}>{<Text id="locationPicker.reserved" />}</span>}
		<div class={style.locationStats}>
			{zimbraCalResSite && <LocationStats value={zimbraCalResSite} textId="site" />}
			{zimbraCalResBuilding && <LocationStats value={zimbraCalResBuilding} textId="building" />}
			{zimbraCalResFloor && <LocationStats value={zimbraCalResFloor} textId="floor" />}
			{zimbraCalResRoom && <LocationStats value={zimbraCalResRoom} textId="room" />}
			{zimbraCalResCapacity && <LocationStats value={zimbraCalResCapacity} textId="capacity" />}
		</div>
		{getAddressString(rest)}
	</div>
);

export default function LocationPicker({ start, end, locations, onClose, onSave }) {
	const { result: calendarResourcesData, loading } = useCalendarResourcesWithFreeBusyQuery({
		start,
		end
	});
	const [searchText, setSearchText] = useState('');
	const [selectedLocations, setLocations] = useState([]);
	const [calendarResources, setCalendarResources] = useState([]);
	const [isDrawerMounted, setIsDrawerMounted] = useState(false);
	const [hideBusy, setHideBusy] = useState(false);

	useEffect(() => {
		if (calendarResourcesData.length) {
			if (locations) {
				const newList = calendarResourcesData.filter(({ name }) => locations.indexOf(name) >= 0);
				setLocations(newList);
			}
			setCalendarResources(calendarResourcesData);
		}
	}, [calendarResourcesData, locations]);

	const handleCloseDrawer = useCallback(() => {
		setIsDrawerMounted(false);
		onClose();
	}, [onClose]);

	const toggleHideReserved = useCallback(
		value => {
			const filteredList = filterBySearch(
				searchText,
				value ? calendarResources : calendarResourcesData,
				value
			);

			setCalendarResources(filteredList);
			setHideBusy(value);
		},
		[searchText, calendarResources, calendarResourcesData]
	);

	const onSearchTextChange = useCallback(
		event => {
			const searchValue = event.target.value;
			setSearchText(searchValue);

			const filteredList = filterBySearch(searchValue, calendarResourcesData, hideBusy);
			setCalendarResources(filteredList);
		},
		[hideBusy, calendarResourcesData]
	);

	const onAction = useCallback(() => onSave(selectedLocations), [onSave, selectedLocations]);

	const handleLocationSelection = useCallback(
		location => {
			const newList = xorWith([location], selectedLocations, isEqual);
			setLocations(newList);
		},
		[selectedLocations]
	);

	const LocationItem = useCallback(
		({ item }) => {
			const { name, isBusy, _attrs } = item;
			const checked = selectedLocations.find(f => f.name === name);

			return (
				<li
					onClick={callWith(handleLocationSelection, item)}
					class={cx(checked && style.selected, isBusy && style.busy)}
				>
					<ChoiceInput checked={!!checked} />
					<LocationDetails {..._attrs} isBusy={isBusy} />
				</li>
			);
		},
		[handleLocationSelection, selectedLocations]
	);

	return (
		<ResponsiveModal
			drawerProps={{
				mounted: isDrawerMounted,
				toolbar: (
					<ModalDrawerToolbar
						buttons={[
							<Button styleType="primary" brand="primary" onClick={onAction}>
								<Text id="buttons.done" />
							</Button>
						]}
						onClose={handleCloseDrawer}
					/>
				)
			}}
			dialogProps={{
				autofocusChildIndex: 1,
				buttons: [
					<Button styleType="primary" brand="primary" onClick={onAction}>
						<Text id="buttons.done" />
					</Button>
				],
				contentClass: style.locationModalContent,
				innerClass: style.locationModal
			}}
			title={<Text id="locationPicker.title" />}
			onClose={onClose}
		>
			<div class={style.contentWrapper}>
				{loading ? (
					<LoaderBar />
				) : calendarResourcesData.length > 0 ? (
					<Fragment>
						<label class={style.showAvailableOnly}>
							<ChoiceInput checked={hideBusy} onChange={callWith(toggleHideReserved, !hideBusy)} />
							<Text id="locationPicker.hideReserved" />
						</label>
						<div class={style.searchWrapper}>
							<Icon name="search" class={style.searchIcon} />
							<TextInput
								class={style.searchInput}
								value={searchText}
								onInput={onSearchTextChange}
								wide
							/>
						</div>

						{calendarResources.length ? (
							<ul class={style.locationList}>
								{calendarResources.map(item => (
									<LocationItem key={item.id} item={item} />
								))}
							</ul>
						) : (
							<div class={style.noResult}>
								<Text id="lists.empty" />
							</div>
						)}
					</Fragment>
				) : (
					<div class={style.noResult}>
						<Text id="lists.empty" />
					</div>
				)}
			</div>
		</ResponsiveModal>
	);
}
