import { Fragment } from 'preact';
import { useCallback } from 'preact/hooks';
import { Text } from 'preact-i18n';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash-es/get';
import moment from 'moment';
import DateInput from '../../date-input';
import { callWith } from '@zimbra/util/src/call-with';
import ActionMenu, { DropDownWrapper } from '../../action-menu';
import ActionMenuGroup from '../../action-menu-group';
import ActionMenuItem from '../../action-menu-item';
import { setAdvanceOptions } from '../../../store/search/actions';
import style from '../style';
import {
	ANYTIME,
	LAST7,
	LAST30,
	SENT_BEFORE,
	SENT_AFTER,
	SENT_ON,
	CUSTOM_DATE,
	DATE_OPTIONS
} from '../constants';

export const DateFields = () => {
	const dispatch = useDispatch();
	const { dateTypeValue, fromDate, onDate, untilDate } = useSelector(state => get(state, 'search'));

	const onDateChange = useCallback(
		option => {
			const obj = {
				dateTypeValue: option || ANYTIME
			};

			if (option === LAST7) {
				obj.fromDate = moment().subtract(7, 'days');
				obj.untilDate = moment();
			} else if (option === LAST30) {
				obj.fromDate = moment().subtract(30, 'days');
				obj.untilDate = moment();
			}

			dispatch(setAdvanceOptions(obj));
		},
		[dispatch]
	);

	const handleFromOnDateChange = useCallback(
		date => {
			const fromDateValue = moment(date);
			const untilDateValue = moment(untilDate);

			if (fromDateValue.isValid() && untilDateValue.isValid()) {
				if (moment(untilDateValue).isBefore(fromDateValue)) {
					dispatch(
						setAdvanceOptions({
							fromDate: fromDateValue,
							untilDate: moment(date).endOf('day')
						})
					);
				} else {
					dispatch(setAdvanceOptions({ fromDate: fromDateValue }));
				}
			}
		},
		[untilDate, dispatch]
	);

	const handleOnDateChange = useCallback(
		date => {
			const onDateValue = moment(date);

			dispatch(setAdvanceOptions({ onDate: onDateValue }));
		},
		[dispatch]
	);

	const handleUntilDateOnChange = useCallback(
		date => {
			const untilDateValue = moment(date).endOf('day');
			const fromDateValue = moment(fromDate);

			if (fromDateValue.isValid() && untilDateValue.isValid()) {
				if (moment(untilDateValue).isBefore(fromDateValue)) {
					dispatch(
						setAdvanceOptions({
							untilDate: untilDateValue,
							fromDate: untilDateValue
						})
					);
				} else {
					dispatch(setAdvanceOptions({ untilDate: untilDateValue }));
				}
			}
		},
		[fromDate, dispatch]
	);

	return (
		<Fragment>
			<ActionMenu
				class={style.actionMenu}
				monotone
				anchor="end"
				into={false}
				label={<Text id={`search.advanced.dateRange.${dateTypeValue || ANYTIME}`} />}
				actionButtonClass={style.actionButtonClass}
			>
				<DropDownWrapper>
					<ActionMenuGroup class={style.actionMenu}>
						{DATE_OPTIONS.map(item => (
							<ActionMenuItem
								onClick={callWith(onDateChange, item)}
								icon={dateTypeValue === item && 'check'}
							>
								<Text id={`search.advanced.dateRange.${item}`} />
							</ActionMenuItem>
						))}
					</ActionMenuGroup>
				</DropDownWrapper>
			</ActionMenu>
			{dateTypeValue === SENT_ON ? (
				<DateInput
					onDateChange={handleOnDateChange}
					class={style.inLineFieldRight}
					dateValue={onDate}
				/>
			) : (
				<DateInput
					onDateChange={handleFromOnDateChange}
					class={style.inLineFieldRight}
					dateValue={fromDate}
					disabled={!(dateTypeValue === CUSTOM_DATE || dateTypeValue === SENT_AFTER)}
				/>
			)}
			<span class={dateTypeValue !== CUSTOM_DATE && style.disableText}>
				<Text id="search.advanced.dateTo" />
			</span>
			<DateInput
				onDateChange={handleUntilDateOnChange}
				class={style.inLineFieldLeft}
				dateValue={untilDate}
				disabled={!(dateTypeValue === SENT_BEFORE || dateTypeValue === CUSTOM_DATE)}
			/>
		</Fragment>
	);
};
