export const STATUS_BUSY = 'B';
export const STATUS_FREE = 'F';

export const VIEW_AGENDA = 'agenda';
export const VIEW_DAY = 'day';
export const VIEW_WEEK = 'week';
export const VIEW_WORK_WEEK = 'work_week';
export const VIEW_MONTH = 'month';
export const VIEW_YEAR = 'year';
export const VIEW_YEARS = 'years';
export const ROUTE_EVENT = 'event';
export const ROUTE_EDIT = 'edit';
export const ROUTE_COPY = 'copy';
export const ROUTE_SINGLE = 'single';
export const ROUTE_NEW = 'new';
export const ROUTE_ALL = 'all';
export const EVENT_PRINT = 'eventPrint';
export const ROUTE_DETAILS = 'details';
export const ROUTE_FORWARD = 'forward';
export const ROUTE_PROPOSE_TIME = 'propose_time';

export const SOURCE_CALENDAR = 'calendar';

export const CALENDAR_VIEWS = [
	VIEW_DAY,
	VIEW_WORK_WEEK,
	VIEW_WEEK,
	VIEW_MONTH,
	VIEW_YEAR,
	VIEW_AGENDA
];

export const CALENDAR_PRINT_VIEWS = [VIEW_DAY, VIEW_WEEK, VIEW_MONTH, VIEW_AGENDA];

export const MODAL_ACTIONS = {
	createCalendar: 'createCalendar',
	addHoliday: 'addHoliday',
	createSharedCalendar: 'createSharedCalendar',
	eventDetails: 'eventDetails',
	importCalendarModal: 'importCalendarModal',
	exportCalendarModal: 'exportCalendarModal',
	confirmDeleteModal: 'confirmDeleteModal',
	printCalendarModal: 'printCalendarModal',
	shareCalendar: 'shareCalendar',
	tagChooserModal: 'tagChooserModal'
};

export const FORMAT_HHMM = 'HHmm';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const EVENT_SELECTION_TYPE = {
	event: 'event',
	all: 'all'
};
export const NOTES_SEPARATOR = '*~*~*~*~*~*~*~*~*~*';

export const WEEK_DAYS = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
