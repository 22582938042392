import { Component } from 'preact';
import { Text, withText } from 'preact-i18n';
import get from 'lodash-es/get';
import uniq from 'lodash-es/uniq';
import xor from 'lodash-es/xor';
import isEmpty from 'lodash-es/isEmpty';
import debounce from 'lodash-es/debounce';
import { Button, AnimatedButton } from '@zimbra/blocks';
import PasswordInput from '../password-input';
import { graphql } from '@apollo/client/react/hoc';
import ResetPasswordMutation from '../../graphql/queries/reset-password.graphql';
import linkState from 'linkstate';
import style from './style';
import { RuleItem } from './rule-item';
import { ENFORCE_HISTORY, COMMON_PASSWORD } from './constants';

import {
	getQualifiedRules,
	getPasswordRules,
	getBasicRules,
	getRemoteRules,
	getErrorCode
} from './utils';
@withText('loginScreen.resetPass.passwordNotMatching')
@graphql(ResetPasswordMutation, {
	props: ({ mutate }) => ({
		resetPassword: variables => mutate({ variables, fetchPolicy: 'no-cache' })
	})
})
export default class ResetPasswordForm extends Component {
	state = {
		username: this.props.username,
		password: this.props.password,
		allPasswordRules: []
	};

	shakeContinueButton = () => {
		this.setState({
			shake: true,
			loading: false
		});
	};

	afterContinueShake = () => {
		this.setState({
			shake: false
		});
	};

	handleError = (err, isDryRun = false) => {
		const { onError } = this.props;

		const {
			accountAttrs: { zimbraPasswordBlockCommonEnabled, zimbraPasswordEnforceHistory }
		} = this.state;

		// Get error code and corresponding count (if returned)
		const { errorCode, errorMetadata } = getErrorCode(err);

		if (errorCode && onError) {
			if (isDryRun) {
				let isCommonPassword;

				if (get(errorMetadata, 'n') === 'zimbraPasswordBlockCommonEnabled') {
					if (zimbraPasswordBlockCommonEnabled) {
						isCommonPassword = COMMON_PASSWORD;
					} else {
						onError(
							<Text
								id={`faults.${errorCode}${errorMetadata ? `.${errorMetadata.n}` : ''}`}
								fields={{
									count: errorMetadata && errorMetadata._content
								}}
							/>
						);
					}
				}

				const isRecentlyUsed =
					zimbraPasswordEnforceHistory > 0 &&
					(isCommonPassword || errorCode === 'account.PASSWORD_RECENTLY_USED') &&
					ENFORCE_HISTORY;

				const unqualifiedRemoteRules = [isCommonPassword, isRecentlyUsed].filter(Boolean);

				this.setState({
					unqualifiedRemoteRules,
					checkingRule: false
				});
			} else {
				onError(
					<Text
						id={`faults.${errorCode}${errorMetadata ? `.${errorMetadata.n}` : ''}`}
						fields={{
							count: errorMetadata && errorMetadata._content
						}}
					/>
				);
			}
		} else {
			onError && onError(<Text id="error.genericInvalidRequest" />);
		}
	};

	submit = e => {
		const { onError, login, passwordNotMatching, changePasswordFlow } = this.props,
			{ username, loginNewPassword, loginConfirmNewPassword } = this.state;

		if (loginNewPassword !== loginConfirmNewPassword) {
			onError && onError(passwordNotMatching);

			this.shakeContinueButton();
			return;
		}

		this.setState({
			loading: true,
			password: loginNewPassword
		});

		onError && onError('');

		this.props
			.resetPassword({
				password: loginNewPassword
			})
			.then(() =>
				login({
					username,
					password: loginConfirmNewPassword
				})
			)
			.catch(err => {
				this.shakeContinueButton();

				!changePasswordFlow && console.error(err);

				// Get error code and corresponding count (if returned)
				this.handleError(err);
			});

		e.preventDefault();
	};

	getUnqualifiedBasicRules = value => {
		const { onError } = this.props;
		const { accountAttrs } = this.state;

		const { qualifiedRules, invalidChars } = getQualifiedRules(
			accountAttrs,
			value,
			this.state.passwordBasicRules,
			this.state.username
		);

		if (onError) {
			if (!isEmpty(invalidChars)) {
				const uniqInvalidChars = uniq(invalidChars);
				onError(
					<Text
						id={'loginScreen.errors.invalidCharacters'}
						plural={uniqInvalidChars.length}
						fields={{
							chars: uniqInvalidChars.join(', ')
						}}
					/>
				);
			} else {
				onError(false);
			}
		}

		return xor(qualifiedRules, this.state.passwordBasicRules);
	};

	handlePasswordInput = e => {
		e.stopPropagation();

		const { value } = e.target;

		const {
			accountAttrs: { zimbraPasswordEnforceHistory, zimbraPasswordBlockCommonEnabled }
		} = this.state;

		const unqualifiedBasicRules = this.getUnqualifiedBasicRules(value);
		const newState = {
			loginNewPassword: value,
			unqualifiedBasicRules
		};

		if (zimbraPasswordEnforceHistory || zimbraPasswordBlockCommonEnabled) {
			if (isEmpty(unqualifiedBasicRules)) {
				newState.checkingRule = true;
				this.debouncedCheckPassword(value);
			} else {
				isEmpty(this.state.unqualifiedBasicRules) && this.debouncedCheckPassword.cancel();
				newState.checkingRule = false;
			}
		}
		this.setState(newState);
	};

	handleCheckPassword = value => {
		this.props
			.resetPassword({
				dryRun: true,
				password: value
			})
			.then(() => {
				this.setState({
					unqualifiedRemoteRules: [],
					checkingRule: false
				});
			})
			.catch(err => this.handleError(err, true));
	};

	debouncedCheckPassword = debounce(this.handleCheckPassword, 2000);

	componentDidMount() {
		this.props
			.resetPassword({
				getPasswordRules: true
			})
			.then(response => {
				const accountAttrs = get(response, 'data.resetPassword.attrs._attrs');

				this.setState({
					accountAttrs,
					unqualifiedBasicRules: getBasicRules(accountAttrs),
					unqualifiedRemoteRules: getRemoteRules(accountAttrs),
					allPasswordRules: getPasswordRules(accountAttrs),
					passwordBasicRules: getBasicRules(accountAttrs),
					passwordRemoteRules: getRemoteRules(accountAttrs)
				});
			})
			.catch(err => {
				this.handleError(err, true);
			});
	}

	componentWillReceiveProps(nextProps) {
		const { username, password } = nextProps;

		if (username && this.props.username !== username) {
			this.setState({ username });
		}
		if (password && this.props.password !== password) {
			this.setState({ password });
		}
	}

	render(
		{ a11yId, account, onClose, onCancelResetPassword, ...props },
		{
			loginNewPassword,
			loginConfirmNewPassword,
			loading,
			shake,
			checkingRule,
			unqualifiedBasicRules,
			unqualifiedRemoteRules,
			allPasswordRules
		}
	) {
		const newPassInputId = 'loginNewPassword',
			confirmPassInputId = 'loginConfirmNewPassword';

		return (
			<form {...props} onSubmit={this.submit} action="javascript:" method="POST">
				<label for={newPassInputId}>
					<Text id="loginScreen.labels.newPass" />
				</label>
				<PasswordInput
					autofocus
					autocomplete="new-password"
					name={newPassInputId}
					value={loginNewPassword}
					onInput={this.handlePasswordInput}
					disabled={loading}
				/>

				<ul class={style.rules}>
					{allPasswordRules.map(({ rule, value }) => (
						<RuleItem
							key={rule}
							rule={rule}
							value={value}
							check={
								unqualifiedBasicRules.indexOf(rule) === -1 &&
								unqualifiedRemoteRules.indexOf(rule) === -1
							}
							checkingRule={checkingRule}
						/>
					))}
				</ul>

				<label for={confirmPassInputId}>
					<Text id="loginScreen.labels.confirmPass" />
				</label>
				<PasswordInput
					autocomplete="new-password"
					name={confirmPassInputId}
					value={loginConfirmNewPassword}
					onInput={linkState(this, 'loginConfirmNewPassword')}
					disabled={loading}
				/>

				<div class={style.buttonsContainer}>
					<AnimatedButton
						class={style.continue}
						disabled={
							!loginNewPassword ||
							!loginConfirmNewPassword ||
							!isEmpty(unqualifiedBasicRules) ||
							!isEmpty(unqualifiedRemoteRules) ||
							loading
						}
						shake={shake}
						loading={loading}
						type="submit"
						styleType="primary"
						brand="primary"
						title={<Text id="buttons.continue" />}
						afterShake={this.afterContinueShake}
					/>

					<Button onClick={onCancelResetPassword}>
						<Text id="buttons.cancel" />
					</Button>
				</div>
			</form>
		);
	}
}
