import { graphql } from '@apollo/client/react/hoc';
import GetPreferences from '../../graphql/queries/preferences/preferences.graphql';

export default function withPreference(mapProps = props => props, config = {}) {
	const { options: { fetchPolicy = 'cache-first', ...restOptions } = {}, ...restConfig } = config;

	return graphql(GetPreferences, {
		...restConfig,
		options: ({ isOffline }) => ({
			...restOptions,
			fetchPolicy: isOffline ? 'cache-only' : fetchPolicy
		}),
		props: result => mapProps(result)
	});
}
