import memoize from 'lodash-es/memoize';
import { HTML_MODE, TEXT_MODE } from '../constants/composer';
import { deepClone } from '../lib/util';

// stylesheet to inject into HTML messages
const STYLESHEET = `
	html, body { overflow:visible; height:auto; line-height:1.3; width:auto; padding:0; margin:0; background:none; }
	body { margin:10px; }
	blockquote {
		margin: 10px 0 10px 10px;
		padding: 0 0 0 10px;
		border-left: 3px solid #BBB;
	}
`;

// max length for generated textual content previews
const MAX_EXCERPT_LENGTH = 60;

// regex of element names/partials not permitted in email.
const BLOCKED_ELEMENTS = /(script|link|embed|object|iframe|frameset|video|audio|input|textarea)/;

// attributes to strip
const BLOCKED_ATTRIBUTES = /(^srcdoc$|^on|[^a-z-])/i;

// attributes to treat as URLs (resolving cid: references)
const URL_ATTRIBUTES = /^(src|href|background)$/i;

const ATTR_MAP = {
	dfsrc: 'src',
	'data-src': 'src'
};

// attributes to treat as URLs (resolving cid: references)
const JS_ATTRIBUTES = /^(src|href)$/i;

const EMPTY_ARRAY = [];

/** Convert a given text or HTML excerpt to a pure text one, capped at 40 charaters. */
const ensureTextExcerpt = memoize(text => {
	text = String(text || '');
	if (text.match(/(<\/?[a-z][a-z0-9:-]*(\s+.*?)?>|&[a-z0-9#]{2,};)/i)) {
		text = htmlToText(text);
	}
	if (text.length > MAX_EXCERPT_LENGTH) {
		text = text.substring(0, MAX_EXCERPT_LENGTH);
	}
	return text;
});

/** Convert HTML to text via DOMParser */
const htmlToText = memoize((html, origMsgHeader = '') => {
	html = html.replace(/<div><br><\/div>/gi, '<div></div>');
	// collapse non-semantic whitespace, then inject whitespace after paragraphs, headings and line breaks:
	html = html
		.replace(/(^\s+|\s+$|\s*\n+\s*)/g, ' ')
		.replace(/<br\s*\/?>/gm, '\r\n')
		.replace(/\n*(<(p)>)\n*/gi, '\n\n')
		.replace(/\n*(<(div|blockquote|li|ul|ol|h[1-6])>|<[h]r(\s.*)?>)\n*/gi, '\n$1');

	const doc = new DOMParser().parseFromString(html, 'text/html');
	const body = doc.body;
	const styleNode = body.querySelector('style');
	if (styleNode) {
		const parentNode = styleNode.parentNode;
		parentNode.removeChild(styleNode);
	}
	const originalMessageTag = doc.getElementById('MESSAGE_DATA_MARKER');

	if (originalMessageTag && origMsgHeader) {
		originalMessageTag.insertBefore(
			doc.createTextNode(`\n-----${origMsgHeader}-----\n\n`),
			originalMessageTag.firstChild
		);
	}

	// Inject list markers (numeric or bullets) into all list items before serializing:
	const listItems = doc.getElementsByTagName('li');
	for (let i = 0; i < listItems.length; i++) {
		const li = listItems[i],
			parent = li.parentNode,
			index = parent.__index == null ? (parent.__index = 0) : ++parent.__index,
			text = parent.nodeName === 'OL' ? `${index + 1}.` : '-';
		li.insertBefore(doc.createTextNode('  ' + text + ' '), li.firstChild);
	}

	const text = doc.body.textContent.endsWith('\n')
		? doc.body.textContent
		: doc.body.textContent + '\n';

	return text;
});

export { ensureTextExcerpt, htmlToText };

/** Given a message object (`{ html, text, attachments, inlineAttachments }`), produce a sanitized HTML body.
 *	@param {Message} message
 *	@param {object} options
 *	@param {boolean} [options.allowImages=true]
 */
export function getEmailHTMLDocument(
	{ id, html, text, attachments, inlineAttachments, isDecodedMessage },
	mode = HTML_MODE,
	options
) {
	options = options || {};
	options.resources = options.resources || [];

	let sanitized = '';
	html = html || '';
	if (mode === TEXT_MODE || !html) {
		if (!text && html && !isDecodedMessage) {
			text = removeHTMLFormatting(html);
		} else if (text) {
			text = textToHTML(text);
		}

		if (text) {
			sanitized = '<body style="white-space:pre-wrap;">' + text + '</body>';
		}
	} else {
		const opts = {
			...options,
			attachments: deepClone([].concat(attachments || [], inlineAttachments || []))
		};
		for (let i = opts.attachments.length; i--; ) opts.attachments[i].messageId = id;

		const dom = new DOMParser().parseFromString(html, 'text/html');
		walk(dom, opts);
		sanitized = dom.documentElement.innerHTML;
	}
	return `<!DOCTYPE html>\n<html><style>${STYLESHEET}</style>${sanitized}</html>`;
}

export function getEmailBody({ id, html, text, excerpt, attachments, inlineAttachments }, options) {
	options = options || {};
	options.resources = options.resources || [];

	let sanitized = '';
	html = html || '';
	if (!html) {
		if (!text && excerpt) {
			text = ensureTextExcerpt(excerpt);
		}
		if (text) {
			sanitized =
				'<body style="white-space:pre-wrap;">' +
				text.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br />') +
				'</body>';
		}
	} else {
		const opts = {
			...options,
			attachments: deepClone([].concat(attachments || [], inlineAttachments || []))
		};
		for (let i = opts.attachments.length; i--; ) opts.attachments[i].messageId = id;

		const dom = new DOMParser().parseFromString(html, 'text/html');
		walk(dom, opts);
		sanitized = dom.body.innerHTML;
	}
	return sanitized;
}

/** @private attempt to find an attachment matching the given CID. */
function getAttachmentByCid(cid, attachments) {
	if (cid) {
		cid = String(cid)
			.toLowerCase()
			.replace(/^\s*?cid:\s*?/gi, '');
		if (attachments.length > 0) {
			for (let i = attachments.length; i--; ) {
				const { contentId, base64, type, contentType } = attachments[i];
				if (String(contentId).toLowerCase().replace(/[<>]/g, '') === cid) {
					if (!base64 && contentId) {
						// @TODO use drop fallback
						return attachments[i].url;
					} else if (base64) {
						return `data:${type || contentType};base64,${base64}`;
					}
				}
			}
		}
	}
	return false;
}

function sanitizeCss(css, attachments) {
	css = css.replace(/@import/gi, '@import-not-supported');
	css = css.replace(
		/url\(\s*?(['"]?)\s*?cid:\s*?(.*?)\s*?\1\s*?\)/gi,
		(s, b, cid) => `url("${getAttachmentByCid(cid, attachments) || ''}")`
	);
	return css;
}

/** @private sanitize a DOM */
function walk(node, opts) {
	const type = node.nodeType,
		att = (opts && opts.attachments) || EMPTY_ARRAY;

	if (type === 3) {
		return;
	}

	if (!node.childNodes) return;

	const n = String(node.nodeName).toLowerCase();
	if (type === 8 || n.match(BLOCKED_ELEMENTS)) {
		return node.parentNode.removeChild(node);
	}

	if (n === 'style') {
		for (let i = node.childNodes.length; i--; ) {
			node.childNodes[i].textContent = sanitizeCss(node.childNodes[i].textContent || '', att);
		}
	}

	if (n === 'a') {
		node.setAttribute('target', '_blank');
		node.setAttribute('rel', 'noopener noreferrer');
	}

	if (node.attributes) {
		const attrs = Array.prototype.slice.call(node.attributes);
		for (let i = attrs.length; i--; ) {
			let { name, value } = attrs[i],
				lcName = String(name).toLowerCase().trim();

			if (ATTR_MAP[lcName]) {
				node.removeAttribute(name);
				lcName = name = ATTR_MAP[lcName];
				node.setAttribute(name, value);
			}

			if (lcName === 'style') {
				node.setAttribute(name, sanitizeCss(value));
				continue;
			}

			if (lcName.match(BLOCKED_ATTRIBUTES)) {
				node.removeAttribute(name);
				continue;
			}

			if (lcName.match(URL_ATTRIBUTES) && value) {
				const matches = String(value).match(/^\s*?cid\s*?:\s*(.+?)\s*$/i);
				if (matches) {
					node.setAttribute('data-cid', matches[1]);
					const attachment = getAttachmentByCid(matches[1], att) || '';
					node.setAttribute(name, attachment);
					if (attachment && attachment !== '') {
						opts.resources.push({ type: 'img', mode: 'attachment', url: attachment });
					}
				} else if (lcName !== 'href') {
					opts.resources.push({ type: 'img', mode: 'external', url: value });
					// strip image src if disabled
					if (opts.allowImages === false) node.removeAttribute(name);
				}
			}

			if (lcName.match(JS_ATTRIBUTES)) {
				const matches = String(value).match(/^\s*?javascript\s*?:\s*?(.*)$/i);
				if (matches) node.removeAttribute(name);
			}
		}
	}

	for (let i = node.childNodes.length; i--; ) walk(node.childNodes[i], opts);
}

export function findElementByIdInEmail(dom, id) {
	return dom.querySelector(`[data-safe-id="${id}"], #${id}`);
}

export function insertAtCaret(win = window, html, rootElement) {
	// see https://stackoverflow.com/a/6691294/4545366
	const doc = win.document;
	let sel = win.getSelection(),
		range = sel && sel.getRangeAt && sel.rangeCount !== 0 && sel.getRangeAt(0);

	if (
		!range ||
		(rootElement &&
			!(rootElement === range.startContainer || rootElement.contains(range.startContainer)))
	) {
		(rootElement.body || doc.body).focus();
	}

	if (doc.queryCommandSupported('InsertHtml')) {
		// The easiest path, supported by all non-IE
		doc.execCommand('InsertHtml', 0, html);
	} else if (sel && sel.getRangeAt && sel.rangeCount) {
		// IE > 9
		range = sel.getRangeAt(0);
		range.deleteContents();

		// Range.createContextualFragment() would be useful here but is
		// only relatively recently standardized and is not supported in
		// some browsers (IE9, for one)
		const el = doc.createElement('div');
		el.innerHTML = html;
		const frag = doc.createDocumentFragment();
		let node, lastNode;
		while ((node = el.firstChild)) {
			lastNode = frag.appendChild(node);
		}
		range.insertNode(frag);

		// Preserve the selection
		if (lastNode) {
			range = range.cloneRange();
			range.setStartAfter(lastNode);
			range.collapse(true);
			sel.removeAllRanges();
			sel.addRange(range);
		}
	} else if ((sel = doc.selection) && sel.type !== 'Control') {
		// IE < 9
		const originalRange = sel.createRange();
		originalRange.collapse(true);
		sel.createRange().pasteHTML(html);
	}
}

//https://stackoverflow.com/questions/27003900/position-cursor-after-element-when-inserting-into-contenteditable
export function placeCaretAfterElement(win, element) {
	if (win.getSelection) {
		let sel = win.getSelection();
		const doc = win.document;
		if (sel.getRangeAt && sel.rangeCount) {
			const range = sel.getRangeAt(0);

			const textNode = doc.createTextNode(' ');
			range.setStartAfter(element);
			range.insertNode(textNode);
			range.setStartAfter(textNode);
			range.collapse(true);
			sel = win.getSelection();
			sel.removeAllRanges();
			sel.addRange(range);
		}
	}
}

//https://stackoverflow.com/questions/15157435/get-last-character-before-caret-position-in-javascript
export function getCharacterPrecedingCaret(rootElement) {
	let sel, range;
	if (window.getSelection) {
		sel = window.getSelection();
		if (sel.rangeCount > 0) {
			range = sel.getRangeAt(0).cloneRange();
			range.collapse(true);
			range.setStart(rootElement, 0);
			return range.toString().slice(-1);
		}
	}
}

export function findElementParent(dom, elementId, parentTags) {
	const element = findElementByIdInEmail(dom, elementId);
	let tempNode = element;
	while (tempNode && (tempNode = tempNode.parentNode)) {
		if (tempNode.nodeType === 1 && parentTags.indexOf(tempNode.nodeName.toLowerCase()) > -1) {
			return tempNode;
		}
	}
	return element;
}

export function moveSelectionOutOfNonEditableArea(rootElement) {
	const sel = window.getSelection(),
		range = sel && sel.getRangeAt && sel.rangeCount !== 0 && sel.getRangeAt(0);
	let ancestor = range && range.commonAncestorContainer;

	if (!ancestor || !rootElement.contains(ancestor)) {
		return;
	}

	while (ancestor && (ancestor = ancestor.parentElement) && ancestor !== rootElement) {
		// When clicking on a contenteditable area, selection will go to the text node closest to the click.
		// It is possible that selection falls to a text node that is a child of a `contenteditable="false"`
		// container. In that case, move the caret to be after the non-editable container.
		if (ancestor.getAttribute && ancestor.getAttribute('contenteditable') === 'false') {
			placeCaretAfterElement(window, ancestor);
		}
	}
}

/**
 * Watch for a node to be delete from the DOM and call the onDelete handler when it is
 * @param {Node} node The DOM Node to watch for its deletion
 * @param {function} onDelete Callback, called with node as it's only argument
 */
export function addNodeDeleteHandler(node, onDelete) {
	const observer = new MutationObserver((mutations, o) => {
		mutations.some(({ removedNodes }) => {
			for (let i = removedNodes.length; i--; ) {
				if (removedNodes.item(i) === node) {
					onDelete(node);
					o.disconnect();
					return true;
				}
			}
		});
	});
	observer.observe(node.parentNode, { childList: true });
}

//To remove formatting and get html body with signature
export function removeHTMLFormatting(html) {
	const { innerText, signature } = getHTMLSignatureObjet(html);
	if (innerText) {
		const htmlBody = textToHTML(innerText);
		return signature ? `${htmlBody}${signature}` : htmlBody;
	} else if (signature) {
		return signature;
	}
	return html;
}

//convert text to html
export function textToHTML(text) {
	text = text
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/(?:\r\n|\r|\n)/g, '<br>');
	const tempDiv = document.createElement('DIV');
	tempDiv.innerHTML = text;
	return tempDiv.outerHTML;
}

//To get html and unformatted signature seperately for plain text mode
export function getHTMLSignatureObjet(html) {
	let unformattedSignature, innerText;
	const tempDiv = document.createElement('DIV');
	tempDiv.innerHTML = html;
	const signatureDiv = tempDiv.querySelector('[id^="signature-content-"]');
	if (signatureDiv) {
		tempDiv.innerHTML = tempDiv.innerHTML.replace(signatureDiv.outerHTML, '');
		const signatureHTML = signatureDiv.innerHTML;
		const signatureText = htmlToText(signatureHTML).trim();
		innerText = htmlToText(tempDiv.innerHTML);
		unformattedSignature = signatureDiv.outerHTML.replace(signatureHTML, textToHTML(signatureText));
	} else {
		innerText = htmlToText(tempDiv.innerHTML);
	}

	return {
		innerText,
		signature: unformattedSignature
	};
}
