import { useEffect, useRef } from 'preact/hooks';
import { hasFlag } from '../../lib/util';
import style from './style';
import { MsgListItem } from './msg-item';
import cx from 'classnames';

export const MsgItemWrapper = ({
	items = [],
	item,
	isAttachment,
	isUrgent,
	emailAddresses,
	onClick,
	allSelected,
	isUnread = false,
	isFlag = false,
	isSelected
}) => {
	const msgItem = useRef(null);

	const tabindex = isSelected ? '0' : '-1';
	const isEvent = items.some(singleMsgItem => hasFlag(singleMsgItem, 'v'));

	useEffect(
		() => !allSelected && isSelected && msgItem && msgItem.current.focus(),
		[allSelected, isSelected]
	);

	return (
		<li
			class={cx(style.message, isSelected && style.selected, isUnread && style.unread)}
			ref={msgItem}
			tabindex={tabindex}
		>
			<MsgListItem
				item={item}
				isEvent={isEvent}
				isAttachment={isAttachment}
				isUrgent={isUrgent}
				isFlag={isFlag}
				emailAddresses={emailAddresses}
				selected={isSelected}
				onClick={onClick}
			/>
		</li>
	);
};
