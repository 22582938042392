import moment from 'moment';
import isEmpty from 'lodash-es/isEmpty';
import { parseAddress } from '../../lib/util';

import { ANY, ANYTIME, SENT_BEFORE, SENT_AFTER, SENT_ON } from './constants';

const getDateValue = (field, dateType, returned = false) =>
	!(ANYTIME === dateType || returned) ? encodeURI(moment(field).format('MM/DD/YYYY')) : undefined;

const getValues = (arr, isAddress = false) =>
	!isEmpty(arr)
		? encodeURI(arr.map(item => (isAddress ? item.address : item)).join(','))
		: undefined;

const getSelectedFolder = selected =>
	!isEmpty(selected) ? selected.absFolderPath.replace(/^\//g, '') : undefined;

const getAttachmentObject = arr =>
	arr.reduce((result, type) => {
		if (type === ANY) {
			result.hasAttachment = 'true';
		} else {
			const firstLetter = type.charAt(0).toUpperCase();
			result[`has${firstLetter + type.slice(1)}`] = 'true';
		}
		return result;
	}, {});

export const getQueryObject = ({
	q,
	types,
	selectedFolder,
	attachmentTypes,
	subject,
	contains,
	dateTypeValue,
	tagValues,
	statusValues,
	fromDate,
	untilDate,
	onDate,
	fromValue,
	toValue
}) => ({
	q,
	types,
	...(selectedFolder && { folder: getSelectedFolder(selectedFolder) }),
	...(subject && { subject: q }),
	...(contains && { contains: q }),
	after: getDateValue(
		fromDate,
		dateTypeValue,
		[SENT_BEFORE, SENT_ON].indexOf(dateTypeValue) !== -1
	),
	before: getDateValue(
		untilDate,
		dateTypeValue,
		[SENT_AFTER, SENT_ON].indexOf(dateTypeValue) !== -1
	),
	date: getDateValue(onDate, dateTypeValue, dateTypeValue !== SENT_ON),
	...(dateTypeValue && dateTypeValue !== ANYTIME && { dateTypeValue }),
	from: getValues(fromValue, true),
	to: getValues(toValue, true),
	tag: getValues(tagValues),
	status: getValues(statusValues),
	hasRefine: true,
	...getAttachmentObject(attachmentTypes)
});

const getActiveSearchFolder = (folders, activeFolderName) =>
	!isEmpty(activeFolderName) &&
	folders.find(folder => folder.absFolderPath === `/${activeFolderName}`);

const getFieldValue = (string, forAddress = false) =>
	string.split(',').map(item => (forAddress ? parseAddress(item) : decodeURI(item)));

export const getDataforAdvaceSearch = (
	{
		subject,
		contains,
		dateTypeValue,
		hasRefine,
		to,
		from,
		tag,
		status,
		after,
		before,
		date,
		activeFolder,
		hasAttachment,
		hasImage,
		hasPdf,
		hasDoc,
		hasSlides,
		hasSheet,
		hasMovie,
		hasCompressed,
		hasApplication,
		hasHtml,
		hasMailMessage,
		hasAudio,
		hasTextCalendar,
		attachmentTypes,
		showMoreTypes
	},
	{ allFolders, searchIn }
) => ({
	subject,
	contains,
	dateTypeValue,
	hasRefine,
	toValue: !isEmpty(to) ? getFieldValue(to, true) : [],
	fromValue: !isEmpty(from) ? getFieldValue(from, true) : [],
	tagValues: !isEmpty(tag) ? getFieldValue(tag) : [],
	statusValues: !isEmpty(status) ? getFieldValue(status) : [],
	fromDate: !isEmpty(after) ? moment(after, 'MM/DD/YYYY').toDate() : new Date(),
	untilDate: !isEmpty(before) ? moment(before, 'MM/DD/YYYY').toDate() : new Date(),
	onDate: !isEmpty(date) ? moment(date, 'MM/DD/YYYY').toDate() : new Date(),
	selectedFolder: getActiveSearchFolder(allFolders, activeFolder || searchIn),
	attachmentTypes:
		attachmentTypes ||
		[
			hasAttachment && 'any',
			hasImage && 'image',
			hasPdf && 'pdf',
			hasDoc && 'doc',
			hasSlides && 'slides',
			hasSheet && 'sheet',
			hasMovie && 'movie',
			hasCompressed && 'compressed',
			hasApplication && 'application',
			hasHtml && 'html',
			hasMailMessage && 'mailMessage',
			hasAudio && 'audio',
			hasTextCalendar && 'textCalendar'
		].filter(Boolean),
	showMoreTypes:
		showMoreTypes || hasApplication || hasHtml || hasMailMessage || hasAudio || hasTextCalendar
});
