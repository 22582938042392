import get from 'lodash-es/get';

// as dispactch is not used have replaced that with _ (underscore symbol)

export const getSelectedDocumentIdsThunk = () => (_, getState) =>
	get(getState(), 'briefcase.selectedIds', []);

export const getSelectedFolderThunk = () => (_, getState) =>
	get(getState(), 'briefcase.selectedFolder', {});

export const getUrlAndDefaultSortThunk = () => (_, getState) => ({
	url: get(getState(), 'url') || false,
	defaultSort: get(getState(), 'briefcase.sortBy')
});
