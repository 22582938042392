import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'preact/hooks';
import { Text } from 'preact-i18n';

import s from '../style.less';
import FormGroup from '../../../form-group';
import AlignedLabel from '../../../aligned-form/label';
import SelectOption from '../../../select';

import { getParsedAppointmentData } from '../../../../store/calendar/selectors';
import { setEvent } from '../../../../store/calendar/actions';

const SHOW_AS_OPTIONS = ['F', 'T', 'B', 'O'];

export const SetShowAs = ({ tabId, isForwardInvite, isProposeTime, disabledElement }) => {
	const dispatch = useDispatch();
	const showAsValue = useSelector(state => getParsedAppointmentData(state, 'showAsValue', tabId));

	const handleShowAsValue = useCallback(
		({ target: { value } }) => {
			dispatch(
				setEvent({
					tabId,
					eventData: {
						showAsValue: value,
						isFormDirty: true
					}
				})
			);
		},
		[dispatch, tabId]
	);

	return (
		<FormGroup>
			<AlignedLabel class={s.alignedLabel} align="left" textId="calendar.editModal.fields.showAs" />
			<SelectOption
				value={showAsValue}
				onChange={handleShowAsValue}
				disabled={isForwardInvite || isProposeTime}
				class={disabledElement}
			>
				{SHOW_AS_OPTIONS.map(k => (
					<option value={k} key={k}>
						<Text id={`calendar.editModal.fields.showAsOptions.${k}`} />
					</option>
				))}
			</SelectOption>
		</FormGroup>
	);
};
