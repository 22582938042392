import cx from 'classnames';
import { displayAddress } from '../../utils/contacts';
import { Icon } from '@zimbra/blocks';
import Avatar from '../avatar';
import s from './style.less';
import ContactHoverCard from '../contact-hover-card';

const ContactTag = ({ email, contact, onRemove, focused }) => {
	const contactTagTarget = (
		<div class={cx(s.contactTag, focused && s.focused)}>
			<Avatar class={s.contactTagAvatar} email={email} contact={contact} />
			<div class={s.contactTagName}>{contact ? displayAddress(contact) : email}</div>
			<div onClick={onRemove}>
				<Icon name="close" class={s.close} />
			</div>
		</div>
	);
	return (
		<ContactHoverCard
			address={email}
			contact={contact}
			name={contact ? displayAddress(contact) : email}
			target={contactTagTarget}
		/>
	);
};
export default ContactTag;
