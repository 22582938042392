import { Component, Fragment } from 'preact';
import { Text } from 'preact-i18n';
import cx from 'classnames';
import { compose } from 'recompose';
import { filterFoldersWithGroups } from '../../utils/contact-folders';
import {
	isTopLevelFolder,
	canShareFolder,
	hasWritePermOnFolder,
	canMarkReadOnFolder
} from '../../utils/folders';
import ActionMenuGroup from '../action-menu-group';
import ActionMenuItem from '../action-menu-item';
import ContextMenuMoveFolder from '../context-menu-move-folder';
import ColorPicker from '../color-picker';
import { callWith, emailIsInJunk } from '../../lib/util';
import { MODAL_ACTIONS } from '../calendar/constants';
import withMediaQuery from '../../enhancers/with-media-query';
import { minWidth, screenMd } from '../../constants/breakpoints';
import { OUTBOX, TRASH, DRAFTS } from './../../constants/folders';
import { MAIL_VIEW } from './../../constants/views';
import { USER_FOLDER_IDS, INTERNAL_GAL_PATH, MAX_FOLDER_DEPTH } from './../../constants';
import { graphql } from '@apollo/client/react/hoc';
import { TaskQuery } from '../../graphql/queries/tasks.graphql';
import { isTaskDone, isTaskOverdue, postponeTask, getDueDate } from '../../utils/tasks';
import get from 'lodash-es/get';
import { connect, useSelector } from 'react-redux';
import s from './style.less';
import ZimletSlot from '../zimlet-slot';
import { getReadUnreadFlag } from '../../utils/mail-list';
import { clearSelected, setSelected } from '../../store/mail/actions';
import { useCallback, useState, useEffect } from 'preact/hooks';
import { useDownloadHandler } from '../briefcase/hooks/download';
import { DeleteWrapper } from '../briefcase/wrapper-functions/delete';
import { StarWrapper } from '../briefcase/wrapper-functions/star';
import { useRenameHandler } from '../briefcase/hooks/rename';
import { useNavigateToNextItem } from '../briefcase/hooks/navigate-next-item';
import useMakeCurrentVersion from '../briefcase/hooks/make-current-version';

const isTrashedFolder = path => {
	const trashRegex = new RegExp(`^/${TRASH}/`);
	return trashRegex.test(path);
};

const MarkReadOption = ({ onMarkFolderRead, folder, disabled, nonOfflineDesktopFeature }) => {
	const { unread } = folder;

	return (
		<ActionMenuItem
			onClick={onMarkFolderRead}
			disabled={disabled || !unread || unread === 0 || !canMarkReadOnFolder(folder)}
			nonOfflineDesktopFeature={nonOfflineDesktopFeature}
		>
			<Text id="mail.contextMenu.MARK_READ" />
		</ActionMenuItem>
	);
};

const ExportOptionItem = ({ onExport, folder, matchesScreenMd }) => {
	return (
		matchesScreenMd && (
			<ActionMenuItem onClick={callWith(onExport, folder)} nonOfflineDesktopFeature>
				<Text id="mail.contextMenu.EXPORT" />
			</ActionMenuItem>
		)
	);
};

const ImportOption = ({ onImportFolder }) => (
	<ActionMenuItem onClick={onImportFolder} nonOfflineDesktopFeature>
		<Text id="mail.contextMenu.import" />
	</ActionMenuItem>
);

const EmptyOption = ({ onEmptyFolder, textId, disabled, nonOfflineDesktopFeature }) => (
	<ActionMenuItem
		onClick={onEmptyFolder}
		disabled={disabled}
		nonOfflineDesktopFeature={nonOfflineDesktopFeature}
	>
		<Text id={textId} />
	</ActionMenuItem>
);

export const ShareFolderOption = ({ onShare, disabled }) =>
	onShare && (
		<ActionMenuItem onClick={onShare} disabled={disabled} nonOfflineDesktopFeature>
			<Text id="mail.contextMenu.shareFolder" />
		</ActionMenuItem>
	);

const CreateSubFolderOption = ({ onCreateSubFolder, disabled, isLocal }) => (
	<ActionMenuItem
		onClick={onCreateSubFolder}
		disabled={disabled}
		nonOfflineDesktopFeature={!isLocal}
	>
		<Text id="mail.contextMenu.CREATE_SUBFOLDER" />
	</ActionMenuItem>
);

export const InboxContextMenu = ({
	onMarkFolderRead,
	folder,
	disabled,
	onImportFolder,
	onCreateSubFolder,
	onShare,
	onExport,
	onEmptyFolder
}) => (
	<ActionMenuGroup>
		<MarkReadOption onMarkFolderRead={onMarkFolderRead} disabled={disabled} folder={folder} />
		{!folder.local && <ImportOption onImportFolder={onImportFolder} />}
		{!folder.local && <ShareFolderOption disabled={disabled} onShare={onShare} />}
		<CreateSubFolderOption onCreateSubFolder={onCreateSubFolder} isLocal={folder.local} />
		{!folder.local && <ExportOption onExport={onExport} folder={folder} />}
		{/*
			@TODO Zimbra SOAP does not support moving all email in a given folder
			which is required for this menu option

			<ActionMenuItem onClick={onArchive}>
				Archive all emails
			</ActionMenuItem>
		*/}
		<EmptyOption
			onEmptyFolder={onEmptyFolder}
			textId="mail.contextMenu.EMPTY_FOLDER"
			disabled={!folder.nonFolderItemCount || !hasWritePermOnFolder(folder)}
		/>
	</ActionMenuGroup>
);

export const SpamContextMenu = ({
	onEmptyFolder,
	disabled,
	onMarkFolderRead,
	folder,
	onImportFolder,
	onExport
}) => (
	<ActionMenuGroup>
		<MarkReadOption onMarkFolderRead={onMarkFolderRead} disabled={disabled} folder={folder} />
		{!folder.local && folder.id !== USER_FOLDER_IDS.JUNK.toString() && !folder.local && (
			<ImportOption onImportFolder={onImportFolder} />
		)}
		<EmptyOption
			onEmptyFolder={onEmptyFolder}
			disabled={disabled}
			textId="mail.contextMenu.EMPTY_JUNK"
		/>
		{!folder.local && <ExportOption onExport={onExport} folder={folder} />}
	</ActionMenuGroup>
);

export const TrashContextMenu = ({
	onEmptyFolder,
	disabled,
	onMarkFolderRead,
	folder,
	onImportFolder,
	onExport,
	onRecoverMail
}) => (
	<ActionMenuGroup>
		<MarkReadOption
			onMarkFolderRead={onMarkFolderRead}
			disabled={disabled}
			folder={folder}
			nonOfflineDesktopFeature
		/>
		{folder.id !== USER_FOLDER_IDS.TRASH.toString() && !folder.local && (
			<ImportOption onImportFolder={onImportFolder} />
		)}
		<EmptyOption
			onEmptyFolder={onEmptyFolder}
			textId="mail.contextMenu.EMPTY_TRASH"
			disabled={disabled}
			nonOfflineDesktopFeature={!folder.local}
		/>
		{!folder.local && <ExportOption onExport={onExport} folder={folder} />}
		{!folder.local && onRecoverMail && (
			<ActionMenuItem onClick={onRecoverMail} nonOfflineDesktopFeature>
				<Text id="mail.contextMenu.recoverDiscardedMail" />
			</ActionMenuItem>
		)}
	</ActionMenuGroup>
);

export const BriefcaseTrashFolderMenu = ({ onEmptyFolder, disabled, folder }) => (
	<ActionMenuGroup>
		{folder.id === USER_FOLDER_IDS.TRASH.toString() && (
			<EmptyOption
				onEmptyFolder={onEmptyFolder}
				textId="mail.contextMenu.EMPTY_TRASH"
				disabled={disabled}
				nonOfflineDesktopFeature
			/>
		)}
	</ActionMenuGroup>
);

export const SpecialFolderContextMenu = ({
	onMarkFolderRead,
	folder,
	disabled,
	onImportFolder,
	onShare,
	onCreateSubFolder,
	onExport,
	onEmptyFolder
}) => {
	return (
		<ActionMenuGroup>
			<MarkReadOption onMarkFolderRead={onMarkFolderRead} disabled={disabled} folder={folder} />
			{folder.parentFolderId === USER_FOLDER_IDS.ROOT.toString() ? (
				folder.name !== DRAFTS &&
				folder.name !== OUTBOX &&
				[
					!folder.local && <ImportOption onImportFolder={onImportFolder} />,
					<CreateSubFolderOption
						onCreateSubFolder={onCreateSubFolder}
						disabled={!hasWritePermOnFolder(folder)}
						isLocal={folder.local}
					/>
				].filter(Boolean)
			) : !folder.local ? (
				<ImportOption onImportFolder={onImportFolder} />
			) : (
				folder.name !== DRAFTS && (
					<CreateSubFolderOption
						onCreateSubFolder={onCreateSubFolder}
						disabled={!hasWritePermOnFolder(folder)}
						isLocal={folder.local}
					/>
				)
			)}
			{!folder.local &&
				(folder.name.toLowerCase() !== OUTBOX.toLowerCase() ||
					folder.parentFolderId !== USER_FOLDER_IDS.ROOT.toString()) && (
					<ShareFolderOption onShare={onShare} disabled={!canShareFolder(folder)} />
				)}
			{!folder.local && folder.name !== 'Outbox' && (
				<ExportOption onExport={onExport} folder={folder} />
			)}
			<EmptyOption
				onEmptyFolder={onEmptyFolder}
				textId="mail.contextMenu.EMPTY_FOLDER"
				disabled={!folder.nonFolderItemCount || !hasWritePermOnFolder(folder)}
			/>
		</ActionMenuGroup>
	);
};

export const FolderGroupContextMenu = ({ onCreateFolder, onFindFolder }) => (
	<ActionMenuGroup>
		<ActionMenuItem onClick={onCreateFolder}>
			<Text id="mail.contextMenu.CREATE_FOLDER" />
		</ActionMenuItem>
		<ActionMenuItem onClick={onFindFolder}>
			<Text id="mail.contextMenu.FIND_FOLDER" />
		</ActionMenuItem>
	</ActionMenuGroup>
);

export const ContactListContextMenu = ({ onRenameFolder, onDeleteFolder }) => (
	<ActionMenuGroup>
		<ActionMenuItem onClick={onRenameFolder}>
			<Text id="contacts.contextMenu.RENAME_LIST" />
		</ActionMenuItem>
		<ActionMenuItem onClick={onDeleteFolder}>
			<Text id="contacts.contextMenu.DELETE_LIST" />
		</ActionMenuItem>
	</ActionMenuGroup>
);

export const TagListContextMenu = ({
	onRenameFolder,
	onDeleteFolder,
	isOffline,
	onMarkFolderRead,
	folder,
	view,
	disabled
}) => (
	<div>
		{view === MAIL_VIEW && (
			<ActionMenuGroup>
				<MarkReadOption onMarkFolderRead={onMarkFolderRead} disabled={disabled} folder={folder} />
			</ActionMenuGroup>
		)}
		<ActionMenuGroup>
			<ActionMenuItem onClick={onRenameFolder} disabled={isOffline}>
				<Text id="tags.contextMenu.editTag" />
			</ActionMenuItem>
			<ActionMenuItem onClick={onDeleteFolder} disabled={isOffline}>
				<Text id="tags.contextMenu.deleteTag" />
			</ActionMenuItem>
		</ActionMenuGroup>
	</div>
);

const OUTBOX_REGEXP = new RegExp(`/${OUTBOX}$`);
@connect(null, { clearSelected, setSelected })
@withMediaQuery(minWidth(screenMd), 'matchesScreenMd')
export class MailContextMenu extends Component {
	onMarkReadFlag = () => {
		const { onMarkRead, selectedIds } = this.props;
		onMarkRead();
		clearSelected();
		selectedIds.forEach(item => {
			item.flags = item.flags && item.flags.replace('u', '');
			setSelected({ item });
		});
	};

	onMarkUnreadFlag = () => {
		const { onMarkUnread, selectedIds } = this.props;
		onMarkUnread();
		clearSelected();
		selectedIds.forEach(item => {
			item.flags = (item.flags ? item.flags.replace('u', '') : '') + 'u';
			setSelected({ item });
		});
	};

	componentWillMount() {
		const { onMount } = this.props;
		onMount && onMount();
	}

	render({
		isOffline,
		isDraftInTrash,
		onStar,
		onClearStar,
		onTag,
		onBlock,
		onMarkSpam,
		onArchive,
		onDelete,
		onShowOriginal,
		onAddSenderContacts,
		onPrint,
		onNewEvent,
		matchesScreenMd,
		folderName,
		localFolder,
		isFolderWritable,
		canMarkAsSpam,
		canArchive,
		canBlock,
		canShowOriginalMsg,
		selectedIds,
		onNewFilter,
		disabled,
		isFlagged,
		item
	}) {
		const isOutboxMessage =
			folderName && (folderName === OUTBOX || folderName.match(OUTBOX_REGEXP));
		const readUnreadFlag = getReadUnreadFlag(selectedIds);
		const starUnstarFlag =
			selectedIds.length > 1 ? 'multiple' : isFlagged ? 'starred' : 'unStarred';
		return (
			<div>
				<ActionMenuGroup>
					<ActionMenuItem
						onClick={this.onMarkReadFlag}
						disabled={!isFolderWritable || isDraftInTrash || readUnreadFlag === 'allRead'}
					>
						<Text id="mail.contextMenu.MARK_AS_READ" />
					</ActionMenuItem>
					<ActionMenuItem
						onClick={this.onMarkUnreadFlag}
						disabled={!isFolderWritable || isDraftInTrash || readUnreadFlag === 'allUnRead'}
					>
						<Text id="mail.contextMenu.MARK_AS_UNREAD" />
					</ActionMenuItem>
					{(starUnstarFlag === 'multiple' || starUnstarFlag === 'unStarred') && (
						<ActionMenuItem
							onClick={onStar}
							disabled={(isOffline && isOutboxMessage) || !isFolderWritable || isDraftInTrash}
						>
							<Text id="mail.contextMenu.STAR" />
						</ActionMenuItem>
					)}
					{(starUnstarFlag === 'multiple' || starUnstarFlag === 'starred') && (
						<ActionMenuItem
							onClick={onClearStar}
							disabled={(isOffline && isOutboxMessage) || !isFolderWritable || isDraftInTrash}
						>
							<Text id="mail.contextMenu.CLEAR_STAR" />
						</ActionMenuItem>
					)}
					{onTag && (
						<ActionMenuItem
							onClick={onTag}
							disabled={isOffline || localFolder || !isFolderWritable || isDraftInTrash}
						>
							<Text id="tags.contextMenu.tag" />
						</ActionMenuItem>
					)}
					<ActionMenuItem onClick={onBlock} disabled={isOffline || isDraftInTrash || !canBlock}>
						<Text id="mail.contextMenu.BLOCK" />
					</ActionMenuItem>
				</ActionMenuGroup>
				<ActionMenuGroup>
					<ActionMenuItem onClick={onPrint} disabled={isOffline || isDraftInTrash}>
						<Text id="mail.contextMenu.PRINT" />
					</ActionMenuItem>
					<ActionMenuItem onClick={onNewEvent} disabled={!onNewEvent} nonOfflineDesktopFeature>
						<Text id="mail.contextMenu.newEvent" />
					</ActionMenuItem>
					{onNewFilter && (
						<ActionMenuItem onClick={onNewFilter} disabled={disabled} nonOfflineDesktopFeature>
							<Text id="mail.contextMenu.newFilter" />
						</ActionMenuItem>
					)}
				</ActionMenuGroup>
				<ActionMenuGroup>
					{canMarkAsSpam && (
						<ActionMenuItem onClick={onMarkSpam} disabled={!isFolderWritable || isDraftInTrash}>
							<Text id={`mail.contextMenu.MARK_AS_${emailIsInJunk(item) ? 'NOT_SPAM' : 'SPAM'}`} />
						</ActionMenuItem>
					)}
					{canArchive && (
						<ActionMenuItem
							onClick={onArchive}
							disabled={!onArchive || !isFolderWritable || isDraftInTrash}
						>
							<Text id="mail.contextMenu.ARCHIVE" />
						</ActionMenuItem>
					)}
					<ActionMenuItem onClick={onDelete} disabled={!isFolderWritable}>
						<Text id="mail.contextMenu.DELETE_MESSAGE" />
					</ActionMenuItem>
					{matchesScreenMd && (
						<ActionMenuItem
							onClick={onShowOriginal}
							disabled={isOffline || localFolder || !canShowOriginalMsg || isDraftInTrash}
						>
							<Text id="mail.contextMenu.showOriginal" />
						</ActionMenuItem>
					)}
				</ActionMenuGroup>
				<ActionMenuGroup>
					<ActionMenuItem onClick={onAddSenderContacts} disabled={isOffline || isDraftInTrash}>
						<Text id="mail.contextMenu.ADD_SENDER_CONTACTS" />
					</ActionMenuItem>
				</ActionMenuGroup>
			</div>
		);
	}
}

export class MailFolderContextMenu extends Component {
	state = {
		showMoveFolderPicker: false
	};

	toggleShowMoveFolderPicker = e => {
		e.stopPropagation();
		this.setState({ showMoveFolderPicker: !this.state.showMoveFolderPicker });
	};

	handleMoveToTopLevel = () => {
		this.props.onMoveFolder();
	};

	render(
		{
			folder,
			folders,
			onMoveFolder,
			isSearchFolder,
			onRenameFolder,
			onDeleteFolder,
			onCreateSubFolder,
			onEditSearch,
			onExport,
			localFolders,
			onMarkFolderRead,
			disabled,
			onImportFolder,
			onShare,
			depth,
			onEmptyFolder
		},
		{ showMoveFolderPicker }
	) {
		const allFoldercontextMenuSlot = this.context.zimlets.isSlotActive(
			'slot::mail-folder-context-menu'
		);
		const sharedFolderContextMenuSlot =
			folder.sharedItemId &&
			this.context.zimlets.isSlotActive('slot::mail-shared-folder-context-menu');

		const disableEmptyOption =
			!folder.nonFolderItemCount ||
			!hasWritePermOnFolder(folder) ||
			(folder.externalAccount && folder.externalAccount.accountType === 'pop3');

		return (
			<div class={cx(s.defaultContainer, showMoveFolderPicker && s.showingPicker)}>
				{showMoveFolderPicker ? (
					<div>
						<ActionMenuGroup class={s.moveFolderGroup}>
							<ContextMenuMoveFolder
								activeFolder={folder}
								folders={folders}
								onMove={onMoveFolder}
								onCancelMove={this.toggleShowMoveFolderPicker}
								localFolders={localFolders}
							/>
						</ActionMenuGroup>
						<ActionMenuGroup>
							<ActionMenuItem
								onClick={this.handleMoveToTopLevel}
								disabled={isTopLevelFolder(folder)}
							>
								<Text id="mail.contextMenu.PLACE_TOP_LEVEL" />
							</ActionMenuItem>
						</ActionMenuGroup>
					</div>
				) : isSearchFolder ? (
					<div>
						<ActionMenuGroup>
							<ActionMenuItem onClick={onRenameFolder}>
								<Text id="mail.contextMenu.RENAME_SEARCH" />
							</ActionMenuItem>
							{onEditSearch && (
								<ActionMenuItem onClick={onEditSearch}>
									<Text id="mail.contextMenu.EDIT_PROPERTIES" />
								</ActionMenuItem>
							)}
							<ActionMenuItem onClick={onDeleteFolder} disabled={folder.folder}>
								<Text id="mail.contextMenu.DELETE_SEARCH" />
							</ActionMenuItem>
						</ActionMenuGroup>
					</div>
				) : (
					<div>
						<ActionMenuGroup>
							<MarkReadOption
								onMarkFolderRead={onMarkFolderRead}
								disabled={disabled}
								folder={folder}
							/>
							{!folder.local && !folder.broken && <ImportOption onImportFolder={onImportFolder} />}
						</ActionMenuGroup>
						<ActionMenuGroup>
							<ActionMenuItem onClick={onRenameFolder} disabled={folder.broken}>
								<Text id="mail.contextMenu.RENAME_FOLDER" />
							</ActionMenuItem>
							<ActionMenuItem
								onClick={this.toggleShowMoveFolderPicker}
								disabled={folder.broken || folder.permissions}
							>
								<Text id="mail.contextMenu.MOVE_FOLDER" />
							</ActionMenuItem>
							<ActionMenuItem
								onClick={onDeleteFolder}
								disabled={folder.local && isTrashedFolder(folder.absFolderPath)}
							>
								<Text id="mail.contextMenu.DELETE_FOLDER" />
							</ActionMenuItem>
							<CreateSubFolderOption
								onCreateSubFolder={onCreateSubFolder}
								disabled={
									folder.broken || !hasWritePermOnFolder(folder) || depth >= MAX_FOLDER_DEPTH
								}
								isLocal={folder.local}
							/>
							{!folder.local && !folder.broken && (
								<ShareFolderOption onShare={onShare} disabled={!canShareFolder(folder)} />
							)}
							{!folder.local && !folder.broken && (
								<ExportOption onExport={onExport} folder={folder} />
							)}
							<EmptyOption
								onEmptyFolder={onEmptyFolder}
								textId="mail.contextMenu.EMPTY_FOLDER"
								disabled={disableEmptyOption}
							/>
						</ActionMenuGroup>
						{(allFoldercontextMenuSlot || sharedFolderContextMenuSlot) && (
							<ActionMenuGroup>
								{allFoldercontextMenuSlot && (
									<ActionMenuItem>
										<ZimletSlot name="mail-folder-context-menu" folder={folder} />
									</ActionMenuItem>
								)}
								{sharedFolderContextMenuSlot && (
									<ActionMenuItem>
										<ZimletSlot name="mail-shared-folder-context-menu" folder={folder} />
									</ActionMenuItem>
								)}
							</ActionMenuGroup>
						)}
					</div>
				)}
			</div>
		);
	}
}

export const CalendarMainContextMenu = ({ openModal }) => (
	<div>
		<ActionMenuGroup>
			<ActionMenuItem onClick={callWith(openModal, MODAL_ACTIONS.createCalendar)}>
				<Text id="calendar.actions.NEW_CALENDAR" />
			</ActionMenuItem>
		</ActionMenuGroup>
		<ActionMenuGroup>
			<ActionMenuItem onClick={callWith(openModal, MODAL_ACTIONS.createSharedCalendar)}>
				<Text id="calendar.actions.ADD_SHARED_CALENDAR" />
			</ActionMenuItem>
			<ZimletSlot name="calendar-subscription-menu" />
		</ActionMenuGroup>
	</div>
);

export const CalendarContextMenu = withMediaQuery(
	minWidth(screenMd),
	'matchesScreenMd'
)(
	({
		colorValue,
		onEdit,
		onShare,
		onImport,
		onExport,
		onDelete,
		onUnlink,
		onChangeColor,
		disableShare,
		disableDelete,
		matchesScreenMd
	}) => (
		<div>
			<ActionMenuGroup>
				{onEdit && (
					<ActionMenuItem onClick={onEdit} narrow>
						<Text id="calendar.contextMenu.editCalendar" />
					</ActionMenuItem>
				)}
				{onShare && (
					<ActionMenuItem onClick={onShare} disabled={disableShare} narrow>
						<Text id="calendar.contextMenu.share" />
					</ActionMenuItem>
				)}
				{onImport && matchesScreenMd && (
					<ActionMenuItem onClick={onImport} narrow>
						<Text id="calendar.sidebar.actions.IMPORT_CALENDAR" />
					</ActionMenuItem>
				)}
				{onExport && matchesScreenMd && (
					<ActionMenuItem onClick={onExport} narrow>
						<Text id="calendar.sidebar.actions.EXPORT_CALENDAR" />
					</ActionMenuItem>
				)}
			</ActionMenuGroup>

			{onChangeColor && (
				<ActionMenuGroup>
					<ColorPicker class={s.colorPicker} value={colorValue} onChange={onChangeColor} />
				</ActionMenuGroup>
			)}

			{onDelete && (
				<ActionMenuGroup>
					<ActionMenuItem onClick={onDelete} disabled={disableDelete} narrow>
						<Text id="calendar.contextMenu.deleteCalendar" />
					</ActionMenuItem>
				</ActionMenuGroup>
			)}

			{onUnlink && (
				<ActionMenuGroup>
					<ActionMenuItem onClick={onUnlink} narrow>
						<Text id="calendar.contextMenu.unlink" />
					</ActionMenuItem>
				</ActionMenuGroup>
			)}
		</div>
	)
);

export const OtherCalendarContextMenu = ({
	colorValue,
	onEdit,
	onUnlink,
	onImport,
	onChangeColor,
	disableImport,
	calendar,
	onShare
}) => {
	const isCalBroken = calendar.broken;

	return (
		<div>
			<ActionMenuGroup>
				<ActionMenuItem onClick={onEdit} narrow disabled={isCalBroken}>
					<Text id="calendar.contextMenu.editCalendar" />
				</ActionMenuItem>

				{onImport && (
					<ActionMenuItem onClick={onImport} narrow disabled={disableImport || isCalBroken}>
						<Text id="calendar.sidebar.actions.IMPORT_CALENDAR" />
					</ActionMenuItem>
				)}

				{onShare && (
					<ActionMenuItem
						onClick={onShare}
						disabled={!canShareFolder(calendar) || isCalBroken}
						narrow
					>
						<Text id="calendar.contextMenu.share" />
					</ActionMenuItem>
				)}
			</ActionMenuGroup>

			{onChangeColor && (
				<ActionMenuGroup>
					<ColorPicker class={s.colorPicker} value={colorValue} onChange={onChangeColor} />
				</ActionMenuGroup>
			)}

			{onUnlink && (
				<ActionMenuGroup>
					<ActionMenuItem onClick={onUnlink} narrow>
						<Text id="calendar.contextMenu.unlink" />
					</ActionMenuItem>
				</ActionMenuGroup>
			)}
		</div>
	);
};

export const OtherCalendarsSectionContextMenu = ({ onAddFriendsCalendarClicked }) => (
	<div>
		<ActionMenuGroup>
			{onAddFriendsCalendarClicked && (
				<ActionMenuItem onClick={onAddFriendsCalendarClicked}>
					<Text id="calendar.contextMenu.linkShared" />
				</ActionMenuItem>
			)}
			<ZimletSlot name="calendar-subscription-menu" />
		</ActionMenuGroup>
	</div>
);

export function BriefcaseFolderContextMenu({
	folder,
	folders,
	onMoveFolder,
	onRenameFolder,
	onDeleteFolder,
	onCreateSubFolder,
	onShare
}) {
	const [showMoveFolderPicker, updateShowMoveFolderPicker] = useState(false);

	const toggleShowMoveFolderPicker = useCallback(e => {
		e.stopPropagation();
		updateShowMoveFolderPicker(show => !show);
	}, []);

	const isBroken = folder.broken;

	return (
		<div class={cx(s.defaultContainer, showMoveFolderPicker && s.showingPicker)}>
			{showMoveFolderPicker ? (
				<Fragment>
					<ActionMenuGroup class={s.moveFolderGroup}>
						<ContextMenuMoveFolder
							activeFolder={folder}
							folders={folders}
							onMove={onMoveFolder}
							onCancelMove={toggleShowMoveFolderPicker}
						/>
					</ActionMenuGroup>
					<ActionMenuGroup>
						<ActionMenuItem onClick={onMoveFolder} disabled={isTopLevelFolder(folder)}>
							<Text id="mail.contextMenu.PLACE_TOP_LEVEL" />
						</ActionMenuItem>
					</ActionMenuGroup>
				</Fragment>
			) : (
				<ActionMenuGroup>
					<ActionMenuItem onClick={onRenameFolder} disabled={isBroken}>
						<Text id="mail.contextMenu.RENAME_FOLDER" />
					</ActionMenuItem>
					<ActionMenuItem
						onClick={toggleShowMoveFolderPicker}
						disabled={folder.permissions || isBroken}
					>
						<Text id="mail.contextMenu.MOVE_FOLDER" />
					</ActionMenuItem>
					<ShareFolderOption onShare={onShare} disabled={!canShareFolder(folder) || isBroken} />
					<ActionMenuItem onClick={onDeleteFolder}>
						<Text id="mail.contextMenu.DELETE_FOLDER" />
					</ActionMenuItem>
					<ActionMenuItem
						onClick={onCreateSubFolder}
						disabled={!hasWritePermOnFolder(folder) || isBroken}
					>
						<Text id="mail.contextMenu.CREATE_SUBFOLDER" />
					</ActionMenuItem>
				</ActionMenuGroup>
			)}
		</div>
	);
}

export class ContactFolderContextMenu extends Component {
	state = {
		showMoveFolderPicker: false
	};

	toggleShowMoveFolderPicker = e => {
		e.stopPropagation();
		this.setState({ showMoveFolderPicker: !this.state.showMoveFolderPicker });
	};

	handleMoveToTopLevel = () => {
		this.props.onMoveFolder();
	};

	render(
		{ folder, folders, onMoveFolder, onRenameFolder, onDeleteFolder, onShare, onCreateSubFolder },
		{ showMoveFolderPicker }
	) {
		const isGalFolder = folder.absFolderPath && folder.absFolderPath.includes(INTERNAL_GAL_PATH);
		const isTrashedContactFolder = folder.id === USER_FOLDER_IDS.TRASH.toString();
		const isFolderBroken = folder.broken; // this happens when folder is shared no-more

		return (
			<div class={cx(s.defaultContainer, showMoveFolderPicker && s.showingPicker)}>
				{showMoveFolderPicker ? (
					<div>
						<ActionMenuGroup class={s.moveFolderGroup}>
							<ContextMenuMoveFolder
								activeFolder={folder}
								folders={filterFoldersWithGroups(folders)}
								onMove={onMoveFolder}
								onCancelMove={this.toggleShowMoveFolderPicker}
							/>
						</ActionMenuGroup>
						<ActionMenuGroup>
							<ActionMenuItem
								onClick={this.handleMoveToTopLevel}
								disabled={isTopLevelFolder(folder)}
							>
								<Text id="mail.contextMenu.PLACE_TOP_LEVEL" />
							</ActionMenuItem>
						</ActionMenuGroup>
					</div>
				) : (
					<div>
						<ActionMenuGroup>
							<ActionMenuItem onClick={onRenameFolder} disabled={isFolderBroken}>
								<Text id="mail.contextMenu.RENAME_FOLDER" />
							</ActionMenuItem>
							<ActionMenuItem
								onClick={this.toggleShowMoveFolderPicker}
								disabled={folder.local || isFolderBroken}
							>
								<Text id="mail.contextMenu.MOVE_FOLDER" />
							</ActionMenuItem>
							<ActionMenuItem
								onClick={onDeleteFolder}
								disabled={isTrashedFolder(folder.absFolderPath)}
							>
								<Text id="mail.contextMenu.DELETE_FOLDER" />
							</ActionMenuItem>
							{!isGalFolder && !isTrashedContactFolder && (
								<ShareFolderOption
									onShare={onShare}
									disabled={!canShareFolder(folder) || isFolderBroken}
								/>
							)}
							<ActionMenuItem onClick={onCreateSubFolder} disabled={isFolderBroken}>
								<Text id="mail.contextMenu.CREATE_SUBFOLDER" />
							</ActionMenuItem>
						</ActionMenuGroup>
					</div>
				)}
			</div>
		);
	}
}

export function ContactFolderShareOnlyContextMenu({ folder, onShare }) {
	return (
		onShare && (
			<div class={s.defaultContainer}>
				<div>
					<ActionMenuGroup>
						<ShareFolderOption onShare={onShare} disabled={!canShareFolder(folder)} />
					</ActionMenuGroup>
				</div>
			</div>
		)
	);
}

export function BriefcasesFolderContextMenu({ folder, onCreateSubFolder, onShare }) {
	return (
		<div class={s.defaultContainer}>
			<ActionMenuGroup>
				<CreateSubFolderOption
					onCreateSubFolder={onCreateSubFolder}
					disabled={!hasWritePermOnFolder(folder)}
					isLocal={folder.local}
				/>
				<ShareFolderOption onShare={onShare} disabled={!canShareFolder(folder)} />
			</ActionMenuGroup>
		</div>
	);
}

export function ContactsFolderContextMenu({ folder, onShare, onCreateSubFolder }) {
	return (
		<div class={s.defaultContainer}>
			<div>
				<ActionMenuGroup>
					<ShareFolderOption onShare={onShare} disabled={!canShareFolder(folder)} />
					<CreateSubFolderOption
						onCreateSubFolder={onCreateSubFolder}
						disabled={!hasWritePermOnFolder(folder)}
						isLocal={folder.local}
					/>
				</ActionMenuGroup>
			</div>
		</div>
	);
}

export const ContactsTrashContextMenu = ({ onEmptyFolder, disabled }) => (
	<ActionMenuGroup>
		<EmptyOption
			onEmptyFolder={onEmptyFolder}
			textId="mail.contextMenu.EMPTY_TRASH"
			disabled={disabled}
			nonOfflineDesktopFeature
		/>
	</ActionMenuGroup>
);

export function BriefcaseListItemContextMenu(
	{ onMount, selectedList, documentList, isFileSharedFolder },
	{ zimbraBatchClient, client }
) {
	const documentListLength = documentList.length;
	useEffect(() => onMount && onMount(), [onMount]);
	const isOffline = useSelector(state => get(state, 'network.isOffline'));
	const { handleDownload } = useDownloadHandler({ zimbraBatchClient, client, selectedList });
	const { handleRenameItemId, disableRename } = useRenameHandler({ client, documentList });
	const selectNextItem = useNavigateToNextItem();
	const isAnyVersionItemSelected = selectedList.some(({ isLatest }) => isLatest === false);
	const { isEarliearVersionSelected, handlerMakeCurrentVersion } =
		useMakeCurrentVersion(selectedList);

	return (
		<ActionMenuGroup>
			<ActionMenuItem
				onClick={handleDownload}
				disabled={
					isOffline || documentListLength === 0 || !!(documentListLength > 1 && isFileSharedFolder)
				}
			>
				<Text id="buttons.download" />
			</ActionMenuItem>
			{!isAnyVersionItemSelected && !isFileSharedFolder && (
				<Fragment>
					<ActionMenuItem
						onClick={handleRenameItemId}
						disabled={isOffline || documentListLength !== 1 || disableRename}
					>
						<Text id="buttons.rename" />
					</ActionMenuItem>
					<StarWrapper documentList={documentList}>
						{({ handleStar, enableStar }) => (
							<Fragment>
								<ActionMenuItem
									onClick={callWith(handleStar, { flag: true })}
									disabled={isOffline || !enableStar || documentListLength === 0}
								>
									<Text id="mail.contextMenu.STAR" />
								</ActionMenuItem>
								<ActionMenuItem
									onClick={callWith(handleStar, { flag: false })}
									disabled={isOffline || !enableStar || documentListLength === 0}
								>
									<Text id="mail.contextMenu.CLEAR_STAR" />
								</ActionMenuItem>
							</Fragment>
						)}
					</StarWrapper>
				</Fragment>
			)}
			{!isFileSharedFolder && (
				<DeleteWrapper documentList={documentList} selectNextItem={selectNextItem}>
					{({ handleDelete, enableDelete }) => (
						<ActionMenuItem
							onClick={handleDelete}
							disabled={isOffline || !enableDelete || documentListLength === 0}
						>
							<Text id="buttons.delete" />
						</ActionMenuItem>
					)}
				</DeleteWrapper>
			)}
			{isEarliearVersionSelected && !isFileSharedFolder && (
				<ActionMenuItem onClick={handlerMakeCurrentVersion} disabled={isOffline}>
					<Text id="buttons.makeCurrentVersion" />
				</ActionMenuItem>
			)}
			<ZimletSlot
				name="briefcase-document-context-menu"
				disableRename={disableRename}
				selectedList={selectedList}
				isFileSharedFolder={isFileSharedFolder}
			/>
		</ActionMenuGroup>
	);
}

export function BriefcaseListFolderContextMenu({
	onMount,
	onShare,
	folderList,
	handleRename,
	handleCreateFolder,
	folder
}) {
	const folderListLength = folderList.length;

	useEffect(() => onMount && onMount(), [onMount]);

	return (
		<Fragment>
			<ActionMenuGroup>
				<ShareFolderOption
					onShare={onShare}
					disabled={!canShareFolder(folder) || folderListLength !== 1}
				/>
				<ActionMenuItem onClick={handleRename} disabled={folderListLength !== 1}>
					<Text id="buttons.rename" />
				</ActionMenuItem>

				<ActionMenuItem
					onClick={handleCreateFolder}
					disabled={!hasWritePermOnFolder(folder) || folderListLength !== 1}
				>
					<Text id="mail.contextMenu.CREATE_SUBFOLDER" />
				</ActionMenuItem>
			</ActionMenuGroup>
			<ActionMenuGroup>
				<DeleteWrapper>
					{({ handleDelete }) => (
						<ActionMenuItem onClick={handleDelete}>
							<Text id="buttons.delete" />
						</ActionMenuItem>
					)}
				</DeleteWrapper>
			</ActionMenuGroup>
		</Fragment>
	);
}

@connect(
	state => ({
		isOffline: get(state, 'network.isOffline')
	}),
	null
)
@withMediaQuery(minWidth(screenMd), 'matchesScreenMd')
export class ContactListItemContextMenu extends Component {
	componentWillMount() {
		this.props.onMount && this.props.onMount();
	}

	render({
		isDeletable,
		isAssignable,
		onEditDetails,
		onAssignToLists,
		onDeleteContact,
		onRemoveFromList,
		showPermanentlyDelete,
		isEditable,
		onShareContact,
		onCopyContacts,
		onTag,
		isOffline,
		onPrintContacts,
		matchesScreenMd,
		disablePrint
	}) {
		return (
			<div>
				<ActionMenuGroup>
					{isEditable && (
						<ActionMenuItem onClick={onEditDetails} disabled={isOffline}>
							<Text id="contacts.contextMenu.editDetails" />
						</ActionMenuItem>
					)}

					{isAssignable && (
						<ActionMenuItem onClick={onAssignToLists} disabled={isOffline}>
							<Text id="contacts.contextMenu.assignToLists" />
						</ActionMenuItem>
					)}

					{onTag && (
						<ActionMenuItem onClick={onTag} disabled={isOffline}>
							<Text id="tags.contextMenu.tag" />
						</ActionMenuItem>
					)}

					{onRemoveFromList && isAssignable && (
						<ActionMenuItem onClick={onRemoveFromList}>
							<Text id="contacts.contextMenu.removeFromList" />
						</ActionMenuItem>
					)}
					{isDeletable && (
						<ActionMenuItem onClick={onDeleteContact} disabled={isOffline}>
							{showPermanentlyDelete ? (
								<Text id="contacts.contextMenu.permanentlyDeleteContact" />
							) : (
								<Text id="contacts.contextMenu.deleteContact" />
							)}
						</ActionMenuItem>
					)}
					{isAssignable && (
						<ActionMenuItem onClick={onShareContact}>
							<Text id="contacts.contextMenu.shareContact" />
						</ActionMenuItem>
					)}

					{onCopyContacts && (
						<ActionMenuItem onClick={onCopyContacts} disabled={isOffline}>
							<Text id="contacts.actions.copyToContacts" />
						</ActionMenuItem>
					)}

					{matchesScreenMd && (
						<ActionMenuItem onClick={onPrintContacts} disabled={isOffline || disablePrint}>
							<Text id="buttons.print" />
						</ActionMenuItem>
					)}
				</ActionMenuGroup>
			</div>
		);
	}
}

export const TaskListContextMenu = ({ onRename, onAddTask, onDelete }) => (
	<ActionMenuGroup>
		<ActionMenuItem onClick={callWith(onRename)} disabled={!onRename}>
			<Text id="tasks.contextMenu.renameTaskList" />
		</ActionMenuItem>
		<ActionMenuItem onClick={callWith(onAddTask)}>
			<Text id="tasks.NEW_TASK" />
		</ActionMenuItem>
		<ActionMenuItem onClick={callWith(onDelete)} disabled={!onDelete}>
			<Text id="tasks.contextMenu.deleteTaskList" />
		</ActionMenuItem>
	</ActionMenuGroup>
);

@graphql(TaskQuery, {
	options: ({ task: { inviteId } }) => ({
		variables: {
			id: inviteId
		}
	})
})
export class TaskItemContextMenu extends Component {
	render({ onEdit, onDelete, onChange, onToggleDone, task }) {
		const isDone = isTaskDone(task);
		const isOverdue = isTaskOverdue(task);
		// Create a task postponed one day from the due date, or one day from today
		const postponedTask = postponeTask(task, isOverdue ? Date.now() : undefined);

		return (
			<ActionMenuGroup>
				<ActionMenuItem onClick={callWith(onEdit, task.inviteId)}>
					<Text id="tasks.contextMenu.editTask" />
				</ActionMenuItem>
				<ActionMenuItem
					onClick={callWith(onChange, postponedTask)}
					disabled={!getDueDate(task) || isDone}
				>
					<Text id={`tasks.contextMenu.postpone${isOverdue ? 'UntilTomorrow' : 'OneDay'}`} />
				</ActionMenuItem>
				<ActionMenuItem onClick={callWith(onToggleDone, task)}>
					<Text id={`tasks.contextMenu.mark${isDone ? 'Not' : ''}Done`} />
				</ActionMenuItem>
				<ActionMenuItem onClick={callWith(onDelete, task)}>
					<Text id="tasks.contextMenu.deleteTask" />
				</ActionMenuItem>
			</ActionMenuGroup>
		);
	}
}

const ExportOption = compose(withMediaQuery(minWidth(screenMd), 'matchesScreenMd'))(
	ExportOptionItem
);
