import { useSelector } from 'react-redux';
import SmartList from '../../smart-list';
import style from './style';
import { IntlContext } from 'preact-i18n';
import { displaySenders, isUrgent, isAttachment } from '../../../utils/mail-item';
import { MsgListHeader } from './msg-list-header';
import { MsgItemWrapper } from '../../msg-list-light/msg-item-wrapper';
import { useCallback, useContext } from 'preact/hooks';
import { useIdentitiesQuery } from '../../../hooks/identities';
import get from 'lodash-es/get';

export function MsgList({ onSelectionChange, items, handleSortBy, sortBy, onScroll }) {
	const { identitiesData } = useIdentitiesQuery();
	const identitiesInfo = get(identitiesData, 'getIdentities');

	const value = useContext(IntlContext);
	const meLabel = value?.intl?.dictionary?.mail?.viewer?.me;

	const selected = useSelector(state => get(state, 'dumpster.selectedMsgs', []));

	function renderListItem(args) {
		const { item, onClick, allSelected, selected: isSelected } = args;

		if (!item || item.type) {
			return item;
		}

		return (
			<MsgItemWrapper
				item={item}
				isAttachment={isAttachment(item)}
				isUrgent={isUrgent(item)}
				emailAddresses={displaySenders(item, identitiesInfo, meLabel)}
				onClick={onClick}
				allSelected={allSelected}
				isSelected={isSelected}
			/>
		);
	}

	/** Fix to resolve fluctuation issue shows in ui of header sort,
	 * using useCallback to prevent rendering.
	 */
	const renderHeader = useCallback(
		({ actions, allSelected, selected: selections }) => (
			<MsgListHeader
				actions={actions}
				allSelected={allSelected}
				onSort={handleSortBy}
				sortBy={sortBy}
				isIndeterminate={selections.length > 0 && !allSelected}
			/>
		),
		[handleSortBy, sortBy]
	);

	return (
		<SmartList
			class={style.smartMessageList}
			innerClass={style.scrollableInner}
			onSelectionChange={onSelectionChange}
			selected={selected}
			items={items}
			renderItem={renderListItem}
			header={renderHeader}
			handleSubScroll={onScroll}
		/>
	);
}
