import { useText } from 'preact-i18n';
import { Icon } from '@zimbra/blocks';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { useModifyPrefs } from '../../graphql-decorators/preferences';
import { splitSortValue, joinSortValue } from '../../utils/sort';
import { serializeFoldersExpanded } from '../../utils/prefs';
import { ARROW_DIRECTION_NAMES, ASCENDING, DESCENDING } from '../../constants';
import { callWith } from '@zimbra/util/src/call-with';
import cx from 'classnames';

function listHeaderActionMenu(style, BaseComponent) {
	return function (props) {
		const { sortValue, zimbraPrefSortOrder, folderId, isFolderView, onSort } = props;
		const [{ sortBy, sortOrder, arrowDirectionName, buttonToolTip }, setState] = useState({});
		const modifyPrefs = useModifyPrefs();

		const { aToZ } = useText('mail.listHeaderMenu.toolTip.aToZ');
		const { zToA } = useText('mail.listHeaderMenu.toolTip.zToA');
		const { smallToBig } = useText('mail.listHeaderMenu.toolTip.smallToBig');
		const { bigToSmall } = useText('mail.listHeaderMenu.toolTip.bigToSmall');
		const { newToOld } = useText('mail.listHeaderMenu.toolTip.newToOld');
		const { oldToNew } = useText('mail.listHeaderMenu.toolTip.oldToNew');

		const isOffline = useSelector(state => state.network.isOffline);
		const isDisable = isOffline && typeof process.env.ELECTRON_ENV !== 'undefined';

		useEffect(() => {
			const [currentSortBy, currentSortOrder] = splitSortValue(sortValue);

			let currentButtonToolTip;
			if (['name', 'rcpt', 'subj'].includes(currentSortBy)) {
				currentButtonToolTip = currentSortOrder === ASCENDING ? aToZ : zToA;
			} else if (currentSortBy === 'size') {
				currentButtonToolTip = currentSortOrder === ASCENDING ? smallToBig : bigToSmall;
			} else {
				currentButtonToolTip = currentSortOrder === ASCENDING ? oldToNew : newToOld;
			}
			setState({
				sortBy: currentSortBy,
				sortOrder: currentSortOrder,
				arrowDirectionName: ARROW_DIRECTION_NAMES[currentSortOrder],
				buttonToolTip: currentButtonToolTip
			});
		}, [aToZ, bigToSmall, newToOld, oldToNew, smallToBig, sortValue, zToA]);

		const updateSort = useCallback(
			([updatedSortBy, updatedSortOrder]) => {
				const updatedSortValue = joinSortValue(
					updatedSortBy || sortBy,
					updatedSortOrder || sortOrder
				);

				if (!isFolderView) {
					onSort(updatedSortValue);
				} else {
					modifyPrefs({
						zimbraPrefSortOrder: serializeFoldersExpanded({
							...zimbraPrefSortOrder,
							[folderId]: updatedSortValue
						})
					});
				}
			},
			[folderId, isFolderView, modifyPrefs, onSort, sortBy, sortOrder, zimbraPrefSortOrder]
		);

		return (
			<div>
				<button
					title={buttonToolTip}
					class={style.arrowButtonClass}
					onClick={callWith(updateSort, [false, sortOrder === ASCENDING ? DESCENDING : ASCENDING])}
				>
					<Icon
						size="sm"
						name={arrowDirectionName}
						class={cx(style.arrowIconClass, isDisable && style.disabled)}
					/>
				</button>
				<BaseComponent
					{...props}
					onSortBySelection={updateSort}
					sortBy={sortBy}
					modifyPrefs={modifyPrefs}
				/>
			</div>
		);
	};
}

export function withListHeaderActionMenu(style) {
	return BaseComponent => listHeaderActionMenu(style, BaseComponent);
}
