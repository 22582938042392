import { useClientConfig } from '../hooks/client-config';

/**
 * A decorator that returns value from config correspond to passed input as a string or an object
 * @param {String | Object({key[String]: String})} args
 */
export default function clientConfiguration(args) {
	return function (Child) {
		return function Config(props) {
			const derivedProps = useClientConfig(args);

			return <Child {...props} {...derivedProps} />;
		};
	};
}
