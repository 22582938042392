import { createPortal } from 'preact/compat';
import split from '../../lib/split-point';
import { Spinner } from '@zimbra/blocks';
import style from './loading.less';

export default split(
	() => import(/* webpackMode: "lazy", webpackChunkName: "settings" */ './settings-modal'),
	'settings',
	createPortal(
		<div class={style.loading}>
			<Spinner block class={style.spinner} />
		</div>,
		document.body
	)
);
