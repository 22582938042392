import { Component } from 'preact';
import { connect } from 'react-redux';
import { Link } from 'preact-router';
import { Text } from 'preact-i18n';
import { branch, renderNothing } from 'recompose';
import cx from 'classnames';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';

import clientConfiguration from '../../enhancers/client-config';
import { MAIL_VIEW } from '../../constants/views';

import { setActiveAccountId } from '../../store/active-account/actions';
import { clearSelected } from '../../store/mail/actions';
import {
	addPollingRequest,
	stopPollingRequest,
	moveRequestToQueue,
	initPollingRequest
} from './../../store/polling-requests/actions';

import {
	withImportExternalAccountData,
	getExternalAccountImportStatus
} from '../../graphql-decorators/external-account';
import withActionMutation from '../../graphql-decorators/with-action-mutation';
import { types as apiClientTypes } from '@zimbra/api-client';

import { getDataTransferJSON } from '@zimbra/util/src/data-transfer-manager';

import { callWith } from '../../lib/util';
import { findFolder } from '../../utils/folders';
import { getNewMailUrl } from '../../utils/mail-item';
import { errorMessage } from './../../utils/errors';

import { Icon } from '@zimbra/blocks';
import ImportRefreshIcon from '../folder-list/import-refresh-icon';

import Sidebar from '../sidebar';
import FolderList from '../folder-list';
import { MailFolderContextMenu } from '../context-menus';
import withMediaQuery from '../../enhancers/with-media-query';
import { minWidth, screenMd } from '../../constants/breakpoints';
import ZimletSlot from '../zimlet-slot';

import s from './style.less';
import { notify } from '../../store/notifications/actions';
import { showNotificationModal } from '../../store/notification-modal/actions';
import { LeftSideAdSlot } from '../ad-slots';
import { getFilteredItemsForLocalFolder, desktopRefetchFolder } from '../../utils/local-folder';
import withIdentities from '../../graphql-decorators/get-identities';
import SidebarPrimaryButton from '../sidebar-primary-button';
import { MiniCalendarView } from '../mini-calendar-view';

const { ActionOps, ActionType } = apiClientTypes;

const INBOX_REGEX = /^inbox$/i;

@withMediaQuery(minWidth(screenMd))
@clientConfiguration({ urlSlug: 'routes.slugs.email' })
@connect(
	state => ({
		activeAccountId: get(state, 'activeAccount.id'),
		isOffline: get(state, 'network.isOffline')
	}),
	{
		setActiveAccountId,
		clearSelected,
		addPollingRequest,
		initPollingRequest,
		moveRequestToQueue,
		stopPollingRequest,
		showNotificationModal,
		notify
	}
)
@branch(({ accountId, folders }) => !folders || !accountId, renderNothing)
@withIdentities(result => ({ identitiesInfo: get(result, 'data.getIdentities') }))
@withImportExternalAccountData()
@withActionMutation()
export default class MailSidebar extends Component {
	state = {
		accountList: []
	};

	accountSelectorList = () => {
		const { accountId, folders, identitiesInfo, dataSourcesInfo, inboxFolder } = this.props;

		const primaryIdentity = find(get(identitiesInfo, 'identity') || [], i => i.id === accountId);
		return [
			{
				id: accountId,
				isPrimary: true,
				title: get(primaryIdentity, '_attrs.zimbraPrefIdentityName'),
				folderId: null,
				navigateTo: '/email/Inbox',
				unread: get(inboxFolder, 'unread')
			},
			...dataSourcesInfo.imap
				.filter(i => i.l !== get(inboxFolder, 'id'))
				.map(i => {
					const sourceFolders = get(findFolder(folders, i.l), 'folders') || [];
					const primaryFolder =
						find(sourceFolders, f => INBOX_REGEX.test(f.name)) || sourceFolders[0];

					return {
						id: i.id,
						title: i.name,
						folderId: i.l,
						failingSince: i.failingSince,
						lastError: get(i, 'lastError._content'),
						...(primaryFolder && {
							navigateTo: `/email/${encodeURIComponent(
								get(primaryFolder, 'absFolderPath', '').replace('/', '')
							)}`
						}),
						unread: get(primaryFolder, 'unread')
					};
				})
		];
	};

	foldersForAccount = accounts => {
		const { activeAccountId, folders } = this.props;
		const activeAccount = find(accounts, ['id', activeAccountId]);
		if (!activeAccount) {
			return folders;
		}

		// Exclude IMAP sub-folders for the primary account
		const excludedDataSourceFolderIds = activeAccount.isPrimary
			? accounts.filter(a => a.id !== activeAccountId).map(a => a.folderId)
			: [];

		// Return the filtered folder list for the primary account, or the root
		// folder for the IMAP account
		return activeAccount.isPrimary
			? folders.filter(f => !includes(excludedDataSourceFolderIds, f.id.toString()))
			: get(findFolder(folders, activeAccount.folderId), 'folders') || [];
	};

	// Multi-account support is enabled when the user has a primary account and
	// at least one IMAP account that is synced to an account folder (not the Inbox)
	enableAccountSelector = () =>
		this.props.zimbraFeatureImapDataSourceEnabled &&
		(get(this.props, 'dataSourcesInfo.imap') || []).filter(
			source => source.l !== get(this.props, 'inboxFolder.id')
		).length > 0;

	handleDrop = e => {
		const data = getDataTransferJSON(e);
		const folder = e.targetList;

		if (data && data.ids) {
			const {
				action: performAction,
				uploadEmails,
				isInLocalFolder,
				refresh,
				changeActiveMessage,
				checkAndLoadMoreResults,
				isOffline,
				refetchLocalFolders,
				refetchFolders
			} = this.props;

			const { isDraggingSelected, isLocal } = data;

			if (isInLocalFolder && (!folder.local || folder.local === null) && !isOffline) {
				const srcMsgIds = data.ids.map(m => m.id);
				uploadEmails({
					srcMsgIds,
					destFolderId: folder.id,
					destFolderName: folder.name,
					isDraggingSelected
				});
			} else if (folder.local && !isInLocalFolder && !isOffline) {
				const { downloadEmails, notify: notifyAction } = this.props;
				const { msgIdsToDownload } = getFilteredItemsForLocalFolder(data.ids, data.type);

				if (!msgIdsToDownload) {
					notifyAction({
						message: <Text id="local_folder.movedSuccessfuly.none" />
					});
					return;
				}
				msgIdsToDownload &&
					downloadEmails(msgIdsToDownload, folder.name, folder).then((addMessageResponses = []) => {
						const messageIdsToBeDeleted = addMessageResponses.map(({ id }) => id).filter(Boolean);
						if (messageIdsToBeDeleted && messageIdsToBeDeleted.length) {
							isDraggingSelected && changeActiveMessage(messageIdsToBeDeleted);
							performAction({
								removeFromList: true,
								type: ActionType.message,
								ids: messageIdsToBeDeleted,
								op: ActionOps.delete
							}).then(() => {
								refresh && refresh();
								notifyAction({
									message: (
										<Text
											id="local_folder.movedSuccessfuly"
											plural={msgIdsToDownload.length}
											fields={{ folder: folder.name, count: msgIdsToDownload.length }}
										/>
									)
								});
							});
						}
					});
			} else {
				const ids = data.ids.map(d => d.id);
				isDraggingSelected && ids && changeActiveMessage(ids);
				// Check if there are more messages into folder if yes the load them.
				// We need to call this before performAction gets completed as after it gets completed we will not have any search results and no cursor to load next items.
				checkAndLoadMoreResults();
				performAction({
					removeFromList: true,
					type: ActionType[data.type],
					ids,
					op: ActionOps.move,
					folderId: folder.id,
					destFolderLocal: folder.local,
					local: folder.local || (typeof process.env.ELECTRON_ENV !== 'undefined' && isOffline),
					isLocal
				}).then(() =>
					desktopRefetchFolder({
						refetchFolders,
						refetchLocalFolders,
						local: folder.local || isInLocalFolder,
						isOffline
					})
				);
			}
		}
	};

	getImportStatus = ({ id: accountId, accountType, accountName }) => {
		const {
			refresh,
			notify: notifyAction,
			showNotificationModal: notificationModal,
			moveRequestToQueue: moveToQueue,
			stopPollingRequest: stopPolling
		} = this.props;

		return getExternalAccountImportStatus(this.context.client)
			.then(({ data: { getImportStatus } }) => {
				const { isRunning, success, error } =
					find(getImportStatus[accountType], ({ id }) => id === accountId) || {};

				// if it's still running, move request back to queue
				if (isRunning) {
					moveToQueue(accountId);
					return;
				}

				// If its not running, terminate Request polling
				stopPolling(accountId);
				success && refresh();
				error
					? notificationModal({
							title: <Text id={'mail.externalAccount.refresh.error'} fields={{ accountName }} />,
							message: errorMessage(error)
					  })
					: notifyAction({
							message: <Text id={'mail.externalAccount.refresh.success'} fields={{ accountName }} />
					  });
			})
			.catch(error => {
				// If got an error, terminate Request polling
				stopPolling(accountId);
				notificationModal({
					title: <Text id={'mail.externalAccount.refresh.error'} fields={{ accountName }} />,
					message: errorMessage(error)
				});
			});
	};

	// Refresh external account POP3 / IMAP
	handleExternalFolderRefresh = ({ externalAccount: { accountType, id, accountName } = {} }) => {
		if (!accountType || !id) return false;

		const {
			importExternalAccount,
			notify: notifyAction,
			addPollingRequest: addRequest,
			initPollingRequest: initPolling
		} = this.props;

		// Initiate request so it can show refresh icon spinning from start,
		// Request polling is not started yet.
		addRequest({
			id,
			requestFunction: callWith(this.getImportStatus, { id, accountType, accountName }),
			pollingInterval: 2000,
			addPollingDelay: 2000
		});

		importExternalAccount({
			accountType,
			id
		})
			.then(() => {
				// Init for request polling
				initPolling(id);
			})
			.catch(error => {
				notifyAction({
					message: errorMessage(error),
					failure: !!error
				});
			});
	};

	handleSelectAccount = account => {
		if (!account.navigateTo) {
			return;
		}

		this.props.setActiveAccountId(account.id);
		this.props.clearSelected();
		this.props.refetchFolders();
	};

	handleSidebarScroll = e => {
		this.setState(prevState => {
			const prevIsSidebarScrolled = prevState.isSidebarScrolled;
			const isSidebarScrolled = e.target.scrollTop !== 0;
			if (prevIsSidebarScrolled !== isSidebarScrolled) {
				return {
					isSidebarScrolled
				};
			}
		});
	};

	handleDesktopRefetchFolder = () => {
		const { refetchFolders, refetchLocalFolders } = this.props;
		desktopRefetchFolder({
			refetchFolders,
			refetchLocalFolders,
			local: true,
			isOffline: true
		});
	};

	componentDidMount = () => {
		if (typeof process.env.ELECTRON_ENV !== 'undefined') {
			const {
				rendererIpc,
				constants: { ipcNames }
			} = require('@zimbra/electron-app');

			// Bind IPC listeners on given channel.
			rendererIpc.listenToIpcEvent(ipcNames.refreshFolders, this.handleDesktopRefetchFolder);
		}
	};

	componentWillReceiveProps = nextProps => {
		if (nextProps.accountId && !nextProps.activeAccountId) {
			this.props.setActiveAccountId(nextProps.accountId);
		}
	};

	componentWillUnmount() {
		if (typeof process.env.ELECTRON_ENV !== 'undefined') {
			const {
				rendererIpc,
				constants: { ipcNames }
			} = require('@zimbra/electron-app');

			// Unbind IPC listeners on given channel.
			rendererIpc.removeIpcListener(ipcNames.refreshFolders, this.handleDesktopRefetchFolder);
		}
	}

	render(
		{
			accountId,
			activeAccountId,
			matchesMediaQuery,
			refresh,
			onIconClick,
			urlSlug,
			folders,
			folder,
			localFolders,
			smartFolders,
			sharedFolders,
			refetchFolders,
			refetchLocalFolders,
			arrayOfFlags,
			downloadEmails,
			downloadInProgress,
			deleteFolder,
			disableLocalFolderForMove,
			tags,
			viewType,
			zimbraFeatureTaggingEnabled,
			zimbraFeatureSharingEnabled,
			handleNewTab,
			zimbraDumpsterEnabled,
			zimbraPrefCalendarAlwaysShowMiniCal
		},
		{ isSidebarScrolled }
	) {
		if (!accountId || !folders || folders.length === 0) {
			return <Sidebar into="#sidebar_portal" header={false} />;
		}

		const enableAccountSelector = this.enableAccountSelector();
		const accounts = enableAccountSelector && this.accountSelectorList();

		return (
			<Sidebar into="#sidebar_portal" header={!matchesMediaQuery}>
				{matchesMediaQuery && (
					<div class={cx(s.composeButtonWrapper, isSidebarScrolled && s.boxShadow)}>
						<SidebarPrimaryButton
							textId="buttons.newMessage"
							onClick={callWith(handleNewTab, {
								url: getNewMailUrl(urlSlug, matchesMediaQuery),
								type: 'message',
								id: undefined
							})}
						/>
					</div>
				)}
				<div class={s.sidebarListWrapper} onscroll={this.handleSidebarScroll}>
					{enableAccountSelector && accounts && (
						<div>
							<div class={s.accountList}>
								{accounts.map(a => (
									<Link
										// Ideally there shouldn't be a Link component without href (preact-router fails in that case)
										// but in this specific scenario link will be only present when external data source doesn't have any errors
										// so for error scenarios passing # string, which should redirect user to root url
										href={a.navigateTo || '#'}
										class={cx(
											s.account,
											a.id === activeAccountId && s.active,
											!a.navigateTo && s.failing
										)}
										onClick={callWith(this.handleSelectAccount, a)}
										disabled={!a.navigateTo}
										title={a.failingSince ? get(a, 'lastError._content') : a.title}
									>
										{a.title} {a.unread ? `(${a.unread})` : ''}
										{a.failingSince && <Icon name="warning" size="xs" class={s.warningIcon} />}
										{!a.isPrimary && (
											<ImportRefreshIcon
												accountId={a.id}
												shouldVisible={a.id === activeAccountId}
												buttonClass={s.showIcon}
												{...(a.id === activeAccountId && {
													onClick: callWith(this.handleExternalFolderRefresh, {
														externalAccount: {
															id: a.id,
															accountName: a.title,
															accountType: 'imap'
														}
													})
												})}
											/>
										)}
									</Link>
								))}
							</div>
							<div class={s.accountSeparator} />
						</div>
					)}
					<FolderList
						zimbraDumpsterEnabled={zimbraDumpsterEnabled}
						zimbraFeatureSharingEnabled={zimbraFeatureSharingEnabled}
						folders={enableAccountSelector ? this.foldersForAccount(accounts) : folders}
						localFolders={localFolders}
						smartFolders={smartFolders}
						sharedFolders={sharedFolders}
						refetchFolders={refetchFolders}
						refetchLocalFolders={refetchLocalFolders}
						indexFolderName={INBOX_REGEX}
						urlSlug={urlSlug}
						refresh={refresh}
						onIconClick={
							onIconClick || (get(folder, 'externalAccount') && this.handleExternalFolderRefresh)
						}
						defaultContextMenu={MailFolderContextMenu}
						defaultShowEllipsis
						dropEffect="move"
						view={MAIL_VIEW}
						screen={MAIL_VIEW}
						onDrop={this.handleDrop}
						collapsibleCustomGroup
						collapsibleSmartGroup
						showSmartFolders
						arrayOfFlags={arrayOfFlags}
						downloadEmails={downloadEmails}
						downloadInProgress={downloadInProgress}
						deleteFolder={deleteFolder}
						disableLocalFolderForMove={disableLocalFolderForMove}
						isTagsSupported={zimbraFeatureTaggingEnabled}
						tags={tags}
						viewType={viewType}
						selectedAccount={
							get(accounts, 'length') && accounts.find(({ id }) => id === activeAccountId)
						}
					/>
					{matchesMediaQuery && <div class={s.accountSeparator} />}
				</div>
				<ZimletSlot name="mail-sidebar-footer" />
				{zimbraPrefCalendarAlwaysShowMiniCal && <MiniCalendarView />}
				<LeftSideAdSlot />
			</Sidebar>
		);
	}
}
