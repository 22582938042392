import { createElement } from 'preact';

/**
 * A decorator that takes in a `mapContextToProps` function that runs given
 * context as its only argument and passes the result down as addtional props
 *
 * @param {Function} mapContextToProps
 * @returns {Function}
 */
export default mapContextToProps => Child => {
	return function GetContext(props, context) {
		return createElement(Child, {
			...props,
			...mapContextToProps(context)
		});
	};
};
