import { graphql } from '@apollo/client/react/hoc';
import AccountInfoQuery from '../../graphql/queries/preferences/account-info.graphql';

export default function withAccountInfo(mapProps, config = {}) {
	const { options: { fetchPolicy, ...restOptions } = {}, ...restConfig } = config;

	return graphql(AccountInfoQuery, {
		...restConfig,
		options: ({ isOffline }) => ({
			...restOptions,
			fetchPolicy: fetchPolicy || (isOffline ? 'cache-only' : 'cache-first')
		}),
		props: result => mapProps(result)
	});
}
