import { Text } from 'preact-i18n';
import cx from 'classnames';
import style from './style.less';
import { callWith } from '@zimbra/util/src/call-with';

export default function InEventNotification({
	onLinkClick,
	notificationText,
	linkText,
	fullWidth
}) {
	return (
		<div class={cx(style.inEventNotification, fullWidth && style.fullWidth)}>
			<Text id={notificationText} />
			&nbsp;
			{onLinkClick && (
				<button class={style.anchorLink} onClick={callWith(onLinkClick, true)}>
					<Text id={linkText} />
				</button>
			)}
		</div>
	);
}
