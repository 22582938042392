import { Icon } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import { useState, useCallback } from 'preact/hooks';
import style from './style.less';
import cx from 'classnames';
import { APPOINTMENT_VIEW, CALENDAR_VIEW } from '../../../../constants/views';
import PermissionsSelect from '../../../share-dialog/permissions-select';
import { ItemField } from './../common';

function GranteeItem({ grantee, viewType }) {
	const { address, granteeType, permissions, zimbraId } = grantee;
	const [expanded, setExpanded] = useState(false);
	const onToggleDetails = useCallback(() => {
		setExpanded(value => !value);
	}, []);

	return (
		<div class={cx(style.granteeItem, expanded && style.expandedGrantee)}>
			<div class={style.aclHeader}>
				<div class={style.aclAddress}>
					<button onClick={onToggleDetails}>
						<Icon size="xs" name={`${expanded ? 'angle-down' : 'angle-right'}`} />
					</button>
					{granteeType === 'pub' ? (
						<Text id="settings.sharing.sharedWithPublic" />
					) : granteeType === 'guest' ? (
						zimbraId
					) : (
						address
					)}
				</div>
				{/* <button>
					<Icon size="sm" name="close" />
				</button> */}
			</div>
			{expanded && (
				<PermissionsSelect
					granteeType={granteeType}
					value={permissions}
					disabled
					screen={viewType === APPOINTMENT_VIEW && CALENDAR_VIEW}
					class={style.permissionsSelect}
				/>
				// <div class={style.resendLink}>
				// 	<Text id="settings.sharing.resend" />
				// </div>
			)}
		</div>
	);
}

function GranteeList({ granteeList, viewType }) {
	const list = (
		<div class={style.granteeList}>
			{granteeList.map(grantee => (
				<GranteeItem grantee={grantee} viewType={viewType} />
			))}
		</div>
	);

	return <ItemField field={<Text id="settings.sharing.sharedWith" />} value={list} />;
}

export default GranteeList;
