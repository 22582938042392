import { Text } from 'preact-i18n';
import get from 'lodash-es/get';
import { PureComponent } from 'preact/compat';
import TinyMceComposer from '../../tinymce-composer';
import { ADDITIONAL_SIGNATURE_ACTIONS } from '../constants';
import Select from '../../select';
import style from '../style';
import ZimletSlot from '../../zimlet-slot';
import { EDITOR_TYPE_SIGNATURE } from '../../../constants/tinymce';
import { htmlToText } from '../../../lib/html-email';
import ErrorAlert from '../../error-alert';

export function SignatureBox({ signatureName, value, onChange, powerPasteEnabled }) {
	return (
		<div class={style.signatureSection}>
			<label>
				<Text id={signatureName} />
			</label>
			<div class={style.signatureBox}>
				<TinyMceComposer
					value={value}
					onEditorChange={onChange}
					powerPasteEnabled={powerPasteEnabled}
					editorType={EDITOR_TYPE_SIGNATURE}
				/>
			</div>
		</div>
	);
}

class SignaturesSettings extends PureComponent {
	triggerAccountChanged = e => {
		const index = get(e, 'nativeEvent.target.selectedIndex');

		this.setState({
			selectedAccountIndex: index
		});
		this.props.accountChangeEvent(this.props.accounts[index].id);
	};

	saveDefaultSignature = (e, body) => {
		const { accounts, accountData, updateAccountSettings } = this.props;
		const zimbraMailSignatureMaxLength = accountData.attrs.zimbraMailSignatureMaxLength;

		if (htmlToText(body).length > zimbraMailSignatureMaxLength) {
			this.setState({ defaultSignatureMaxError: true });
		} else {
			this.setState({ defaultSignatureMaxError: false });
			updateAccountSettings(
				{
					defaultSignatureValue: body
				},
				accounts[this.state.selectedAccountIndex].id
			);
		}
	};
	saveReplyForwardSignature = (e, body) => {
		const { accounts, accountData, updateAccountSettings } = this.props;
		const zimbraMailSignatureMaxLength = accountData.attrs.zimbraMailSignatureMaxLength;

		if (htmlToText(body).length > zimbraMailSignatureMaxLength) {
			this.setState({ replySignatureMaxError: true });
		} else {
			this.setState({ replySignatureMaxError: false });
			updateAccountSettings(
				{
					forwardReplySignatureValue: body
				},
				accounts[this.state.selectedAccountIndex].id
			);
		}
	};

	constructor(props) {
		super(props);

		this.state = {
			selectedAccountIndex: 0
		};
	}

	render(
		{ accounts, accountData, additionalSignatures, additionalSignatureActions, preferenceInfo },
		{ selectedAccountIndex, replySignatureMaxError, defaultSignatureMaxError }
	) {
		const { defaultSignatureValue, forwardReplySignatureValue, id } =
			accounts[selectedAccountIndex];

		// Zimbra 9 contains powerpaste value in `getInfo.pasteitcleanedEnabled`, while
		// Zimbra X contains value in getInfo attrs `zimbraFeaturePowerPasteEnabled` and `zimbraPrefPowerPasteEnabled`.
		const powerPasteEnabled =
			accountData.pasteitcleanedEnabled !== null
				? accountData.pasteitcleanedEnabled
				: get(accountData, 'attrs.zimbraFeaturePowerPasteEnabled') &&
				  get(preferenceInfo, 'zimbraPrefPowerPasteEnabled');

		const zimbraMailSignatureMaxLength = accountData.attrs.zimbraMailSignatureMaxLength;

		const defaultSignatureContent = defaultSignatureValue || '';
		const forwardReplySignatureContent = forwardReplySignatureValue || '';
		return (
			<div>
				<div class={style.signatureTitle}>
					<Text id="settings.signatures.signatureTitle" />
				</div>
				{accounts.length > 1 && (
					<div class={style.signatureSection}>
						<label class={style.forSelect}>
							<Text id="settings.signatures.account" />
						</label>
						<Select
							value={this.state.selectedAccountIndex}
							onChange={this.triggerAccountChanged}
							fullWidth
						>
							{accounts.map((account, index) => (
								<option value={index}>
									{(account.fromDisplay !== null ? account.fromDisplay : account.name) +
										' <' +
										account.emailAddress +
										'>'}
								</option>
							))}
						</Select>
					</div>
				)}

				<SignatureBox
					signatureName={`settings.signatures.defaultSignature`}
					value={defaultSignatureContent}
					onChange={this.saveDefaultSignature}
					key={`Default signature: ${id}`}
					powerPasteEnabled={powerPasteEnabled}
				/>
				{defaultSignatureMaxError && (
					<ErrorAlert>
						<Text
							id="settings.signatures.signatureMaxLengthError"
							fields={{ length: zimbraMailSignatureMaxLength }}
						/>
					</ErrorAlert>
				)}
				<SignatureBox
					signatureName={`settings.signatures.replyForwardSignature`}
					value={forwardReplySignatureContent}
					onChange={this.saveReplyForwardSignature}
					key={`Forward reply signature: ${id}`}
					powerPasteEnabled={powerPasteEnabled}
				/>
				{replySignatureMaxError && (
					<ErrorAlert>
						<Text
							id="settings.signatures.signatureMaxLengthError"
							fields={{ length: zimbraMailSignatureMaxLength }}
						/>
					</ErrorAlert>
				)}
				<ZimletSlot
					name="additional-signature-settings"
					style={style}
					additionalSignatures={additionalSignatures}
					additionalSignatureActions={additionalSignatureActions}
					additionalSignatureActionTypes={ADDITIONAL_SIGNATURE_ACTIONS}
					powerPasteEnabled={powerPasteEnabled}
					zimbraMailSignatureMaxLength={zimbraMailSignatureMaxLength}
				/>
			</div>
		);
	}
}

export default SignaturesSettings;
