import CloseButton from '../close-button';
import { Tooltip, CloseOnClickOrKeydown, Icon } from '@zimbra/blocks';
import style from './style.less';
import cx from 'classnames';
import { useState, useCallback } from 'preact/hooks';

export default function HelpTooltip({ children, position = 'left', customStyle }) {
	const [visible, setVisible] = useState(false);
	const showTooltip = useCallback(() => {
		setVisible(true);
	}, []);

	const dismiss = useCallback(e => {
		e.stopPropagation();
		setVisible(false);
	}, []);

	return (
		<div class={style.tooltipContainer}>
			<button onClick={showTooltip}>
				<Icon size="sm" name="question-circle" />
			</button>
			{visible && (
				<CloseOnClickOrKeydown onClickOutside={dismiss}>
					<Tooltip position={position} class={cx(style.tooltip, customStyle)}>
						{children}
						<CloseButton onClick={dismiss} class={style.close} />
					</Tooltip>
				</CloseOnClickOrKeydown>
			)}
		</div>
	);
}
