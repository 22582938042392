import { Localizer, Text } from 'preact-i18n';
import { useCallback } from 'preact/hooks';
import style from './style';
import { Icon, ChoiceInput } from '@zimbra/blocks';
import ListItemEmailAddresses from '../mail-list-item/list-item-email-addresses';
import CalendarIcon from '../calendar-icon';
import EmailTime from '../email-time';

export const MsgListItem = ({
	item,
	isEvent,
	isAttachment,
	selected,
	isUrgent,
	isFlag,
	emailAddresses,
	onClick
}) => {
	const { excerpt = '', date, subject } = item;

	const handleClick = useCallback(event => onClick({ item, event }), [item, onClick]);

	const handleCheckBoxSelect = useCallback(
		event => onClick({ action: 'toggle', event, item }),
		[onClick, item]
	);

	return (
		<div class={style.narrowListItem}>
			<div class={style.messageActions}>
				<Localizer>
					<div class={style.selectItem}>
						<ChoiceInput
							aria-label={<Text id="mail.selectEmail" />}
							checked={!!selected}
							readonly
							containerClass={style.wideArea}
							tabindex="-1"
							onChange={handleCheckBoxSelect}
						/>
					</div>
				</Localizer>
			</div>
			<div class={style.messageContent} onClick={handleClick}>
				<div class={style.row}>
					<div class={style.sender}>
						<ListItemEmailAddresses emailAddresses={emailAddresses} />
					</div>
					<div class={style.messageTime}>
						{isUrgent && <Icon name="priority-high" size="sm" />}
						{isAttachment && <Icon class={style.attachment} size="sm" name="paperclip" />}
						<EmailTime time={date} />
					</div>
				</div>
				<div class={style.row}>
					<h4 class={style.subject} title={subject}>
						{isEvent && <CalendarIcon />}
						{subject || <Text id="mail.noSubject" />}
					</h4>
					{isFlag && <Icon size="sm" name="star" class={style.starred} />}
				</div>
				<div class={style.row}>
					<div class={style.excerpt}>{excerpt}</div>
				</div>
			</div>
		</div>
	);
};
