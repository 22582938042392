import { graphql } from '@apollo/client/react/hoc';
import GetDataSources from '../../graphql/queries/datasource/get-datasource.graphql';

export default function withDataSources(mapProps, config = {}) {
	const { options: { fetchPolicy = 'cache-first', ...restOptions } = {}, ...restConfig } = config;

	return graphql(GetDataSources, {
		...restConfig,
		options: ({ isOffline }) => ({
			...restOptions,
			fetchPolicy: isOffline ? 'cache-only' : fetchPolicy
		}),
		props: result => mapProps(result)
	});
}
