import { Text } from 'preact-i18n';
import style from './style';

export function MiniCalendarSections({ events = [], renderItem, sectionLabel }) {
	return (
		<li class={style.miniCalGrouping}>
			{/* USing fallback text here, in case of sectionLabel as 'all Day' gives value from locale otherwise
			calculates from moment */}
			<Text class={style.miniCalSections} id={sectionLabel}>
				{sectionLabel}
			</Text>
			{events.map(renderItem)}
		</li>
	);
}
