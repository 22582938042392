import get from 'lodash-es/get';
import Select from '../../select';

export default function TagAction({ value, tagList, onChange }) {
	const selectedTagName = get(value, 'actions.0.tag.0.tagName') || get(value, 'tagName');

	return (
		<Select value={selectedTagName} onChange={onChange} fullWidth>
			{tagList.map(name => (
				<option value={name} key={name}>
					{name}
				</option>
			))}
		</Select>
	);
}
