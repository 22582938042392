import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import getContext from '../lib/get-context';
import omitProps from '../enhancers/omit-props';
import LogoutMutation from '../graphql/queries/logout.graphql';
import { clearOfflineData } from '../utils/offline';
import csrfStorage from '../utils/csrf';
import withClientInfo from './with-client-info';
import { compose } from 'recompose';
import appConfiguration from '../enhancers/app-config';
import withTracking from '../enhancers/tracking';
import { rendererIpc } from '@zimbra/electron-app';
import { setLoginVisible } from '../store/network/actions';

function clearDataAndRedirect(tracker, context, url) {
	tracker.setVirtualPageView({ path: '/logout' });
	csrfStorage.clear(context);
	clearOfflineData(context).then(() => {
		window.location.href = url;
	});
}

export default function withLogout(name = 'logout') {
	return compose(
		withTracking(),
		getContext(context => ({ context })),
		// ZIMBRA_DOMAIN is been deprecated will be removed in future keeping it here for backward compatibility
		appConfiguration({ hostName: 'ZIMBRA_DOMAIN' }),
		connect(null, { setLoginVisible }),
		withClientInfo(
			({ data }) => ({
				zimbraWebClientLogoutURL: data?.clientInfo?.attributes?.zimbraWebClientLogoutURL,
				zimbraWebClientSkipLogoff: data?.clientInfo?.attributes?.zimbraWebClientSkipLogoff
			}),
			{
				fetchPolicy: 'cache-first'
			}
		),
		graphql(LogoutMutation, {
			props: ({
				mutate,
				ownProps: {
					context,
					zimbraWebClientLogoutURL,
					zimbraWebClientSkipLogoff,
					tracker,
					setLoginVisible: setLoginVisibility
				}
			}) => ({
				[name]: (...args) => {
					if (zimbraWebClientSkipLogoff && typeof process.env.ELECTRON_ENV === 'undefined') {
						// When SSO setup
						clearDataAndRedirect(tracker, context, zimbraWebClientLogoutURL);
					} else {
						// NOT SSO setup
						mutate(...args)
							.catch(Object)
							.then(res => {
								if (
									typeof process.env.ELECTRON_ENV !== 'undefined' &&
									process.platform === 'win32'
								) {
									rendererIpc.abortPSTExtraction();
								}

								if (typeof process.env.ELECTRON_ENV !== 'undefined') {
									setLoginVisibility(true);
								} else if (zimbraWebClientLogoutURL) {
									clearDataAndRedirect(tracker, context, zimbraWebClientLogoutURL);
								} else {
									clearDataAndRedirect(
										tracker,
										context,
										typeof window.cordova !== 'undefined' ? '/modern' : '/'
									);
								}

								return res;
							});
					}
				}
			})
		}),
		omitProps('context')
	);
}
