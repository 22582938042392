import { PureComponent } from 'preact/compat';
import cx from 'classnames';
import Search from '../search';

import HeaderActions from '../header-actions';

import ClientLogo from '../client-logo';

import s from './style.less';
import { connect } from 'react-redux';
import { getView } from '../../store/url/selectors';
import { Text } from 'preact-i18n';
import AppNavigation from '../app-navigation';

const PrimaryLogo = () => (
	<div className={s.primaryLogo}>
		<ClientLogo href="/" />
	</div>
);

@connect(state => ({
	currentView: getView(state)
}))
export default class Header extends PureComponent {
	render({
		className,
		currentView,
		zimbraFeatureGalEnabled,
		zimbraPrefLocale,
		zimbraFeatureTaggingEnabled,
		zimbraFeatureSharingEnabled,
		refetchAccount,
		...props
	}) {
		const defaultFolderToSearch =
			currentView === 'contacts' ? (
				<Text id="search.scope.myContacts" />
			) : (
				<Text id="search.allMail" />
			);

		return (
			<div {...props} role="banner" className={cx(s.header, className, s.headerSearch)}>
				<PrimaryLogo />
				<AppNavigation />
				<Search
					showDropDown={currentView === 'contacts' ? zimbraFeatureGalEnabled : true}
					defaultFolderToSearch={defaultFolderToSearch}
					zimbraFeatureTaggingEnabled={zimbraFeatureTaggingEnabled}
					zimbraFeatureSharingEnabled={zimbraFeatureSharingEnabled}
				/>
				<HeaderActions zimbraPrefLocale={zimbraPrefLocale} refetchAccount={refetchAccount} />
			</div>
		);
	}
}
