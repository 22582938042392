import { Text } from 'preact-i18n';
import { callWith, isValidEmail } from '../../../../lib/util';
import ErrorAlert from '../../../error-alert';
import FormGroup from '../../../form-group';
import Label from '../../../label';
import TextInput from '../../../text-input';
import ExternalServerSettings from './components/external-server-settings';
import style from '../../style';
import { useCallback } from 'preact/hooks';

export default function AddAccountView({
	onFormDataChange,
	formData,
	testDataSource,
	error,
	matchesScreenXs,
	zimbraFeatureImapDataSourceEnabled,
	zimbraFeaturePop3DataSourceEnabled
}) {
	const changeEmail = useCallback(
		e => {
			onFormDataChange('emailAddress')(e, () => {
				if (isValidEmail(e.target.value)) {
					const [username] = e.target.value.split('@');
					onFormDataChange('username')(username);
				}
			});
		},
		[onFormDataChange]
	);

	return (
		<form action="javascript:" onSubmit={testDataSource}>
			{error && (
				<ErrorAlert>
					<Text id={error}>{error}</Text>
				</ErrorAlert>
			)}
			<div class={style.marginBottomLg}>
				<FormGroup rows>
					<Label id="settings.accounts.addAccount.email" large />
					<TextInput
						type="email"
						onChange={changeEmail}
						value={formData.emailAddress}
						autocomplete="off"
						placeholderId="settings.emailPlaceholder"
						required
						wide
					/>
				</FormGroup>
				<FormGroup rows large separator>
					<Label id="settings.accounts.addAccount.password" large />
					<TextInput
						type="password"
						autocorrect="off"
						autocapitalize="off"
						autocomplete="new-password"
						onChange={callWith(onFormDataChange, 'password')()}
						value={formData.password}
						placeholderId="settings.accounts.addAccount.passwordPlaceholder"
						required
						wide
					/>
				</FormGroup>
			</div>
			<ExternalServerSettings
				formData={formData}
				onFormDataChange={onFormDataChange}
				matchesScreenXs={matchesScreenXs}
				zimbraFeatureImapDataSourceEnabled={zimbraFeatureImapDataSourceEnabled}
				zimbraFeaturePop3DataSourceEnabled={zimbraFeaturePop3DataSourceEnabled}
			/>
		</form>
	);
}
