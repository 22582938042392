import get from 'lodash-es/get';
import queryString from 'query-string';

export function getParsedSearch(state) {
	const search = get(state, 'url.location.search');
	const newQueryString = decodeURIComponent(search).replace(/^\??mailto:(\?to=)?/, 'to=');
	return queryString.parse(newQueryString.replace('?', '&'));
}

export function getRouteProps(state) {
	return state.url.routeProps || {};
}

export function getSearchQuery(state) {
	const { q } = getParsedSearch(state);
	return `${q || ''}`.trim();
}

export function getSearchEmail(state) {
	const { e } = getParsedSearch(state);
	return `${e || ''}`.trim();
}

export function getSearchFolder(state) {
	const { folder } = getParsedSearch(state);
	return `${folder || ''}`.trim();
}

export function getQueryFolder(state) {
	const { foldername } = getParsedSearch(state);
	return `${foldername || ''}`.trim();
}

export function getAdvancedSearchOptions(state) {
	const {
		subject,
		contains,
		after,
		before,
		date,
		from,
		to,
		tag,
		status,
		hasAttachment,
		hasImage,
		hasPdf,
		hasDoc,
		hasSlides,
		hasSheet,
		hasMovie,
		hasCompressed,
		hasApplication,
		hasHtml,
		hasMailMessage,
		hasAudio,
		hasTextCalendar,
		dateTypeValue
	} = queryString.parse(state.url.location.search);
	const opts = {
		subject: `${subject || ''}`.trim(),
		contains: `${contains || ''}`.trim(),
		before: `${before || ''}`.trim(),
		after: `${after || ''}`.trim(),
		date: `${date || ''}`.trim(),
		dateTypeValue: `${dateTypeValue || 'anytime'}`.trim(),
		from: `${from || ''}`.trim(),
		to: `${to || ''}`.trim(),
		tag: `${tag || ''}`.trim(),
		status: `${status || ''}`.trim(),
		attachmentTypes: [
			hasAttachment && 'any',
			hasImage && 'image',
			hasPdf && 'pdf',
			hasDoc && 'doc',
			hasSlides && 'slides',
			hasSheet && 'sheet',
			hasMovie && 'movie',
			hasCompressed && 'compressed',
			hasApplication && 'application',
			hasHtml && 'html',
			hasMailMessage && 'mailMessage',
			hasAudio && 'audio',
			hasTextCalendar && 'textCalendar'
		].filter(Boolean),
		showMoreTypes: hasApplication || hasHtml || hasMailMessage || hasAudio || hasTextCalendar
	};

	return opts;
}

export function getView(state) {
	return state.url.view;
}
