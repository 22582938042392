import { useState, useCallback } from 'preact/hooks';
import { Icon, Popover } from '@zimbra/blocks';

import { callWith } from '@zimbra/util/src/call-with';

import ActionMenuItem from '../action-menu-item';
import { DropDownWrapper } from '../action-menu';
import { useMediaQuery } from '../../enhancers/use-media-query';
import { minWidth, screenMd } from '../../constants/breakpoints';
import s from './style.less';

export const NestedActionMenuItem = ({ children, position, icon, title }) => {
	const { desktopView } = useMediaQuery(minWidth(screenMd), 'desktopView');
	const [active, setDropdownStatus] = useState(false);

	const toggleDropdown = useCallback(value => {
		setDropdownStatus(value);
	}, []);

	const handleClick = useCallback(
		e => {
			if (!active) {
				toggleDropdown(true);
				e.stopPropagation();
			}
		},
		[active, toggleDropdown]
	);

	return (
		<Popover
			class={s.nestedMenuItem}
			toggleClass={s.nestedMenuToggle}
			active={active}
			onToggle={toggleDropdown}
			placement={position}
			target={
				<ActionMenuItem class={s.item} icon={icon} onClick={handleClick}>
					{title} <Icon name="chevron-right" class={s.chevron} />
				</ActionMenuItem>
			}
			{...(desktopView && {
				hoverDuration: 250
			})}
		>
			<DropDownWrapper closeDropdown={callWith(toggleDropdown, false)}>{children}</DropDownWrapper>
		</Popover>
	);
};
