import { Fragment } from 'preact';
import style from './style';
import cx from 'classnames';
import { Button, Icon } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import ContactPickerContact from './contact';
import { callWith } from '../../lib/util';
import { useCallback, useState, useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setSelected, setChosen } from '../../store/contact-chooser/actions';
import { getDataFromReduxStoreThunk } from '../../utils/thunk';

export default function ChosenContactList({ getContact, addressFieldType, additionalContacts }) {
	const dispatch = useDispatch();
	const [destinationListSelected, setDestinationListSelected] = useState([]);
	const chosen = useSelector(state => state?.contactChooser?.chosen);

	const chooseContacts = useCallback(
		addressType => {
			const sourceListSelected = dispatch(getDataFromReduxStoreThunk('contactChooser.selected'));
			sourceListSelected.map(selectedContact => {
				const id = selectedContact;
				if (chosen[addressType].indexOf(id) === -1) {
					chosen[addressType] = chosen[addressType].concat(id);
				}
			});

			dispatch(setChosen({ ...chosen }));
			dispatch(setSelected([]));
		},
		[dispatch, chosen]
	);

	const removeChosenHelper = () => {
		let { to, cc, bcc } = chosen;

		destinationListSelected.forEach(selectedContact => {
			to = to.filter(c => c !== selectedContact);
			cc = cc.filter(c => c !== selectedContact);
			bcc = bcc.filter(c => c !== selectedContact);
		});

		return { to, cc, bcc };
	};

	function removeChosenContacts() {
		dispatch(setChosen(removeChosenHelper()));
		setDestinationListSelected([]);
	}

	const selectChosenContact = useCallback(
		contact => {
			const { id } = contact;

			setDestinationListSelected(prevState => {
				if (destinationListSelected.indexOf(id) === -1) {
					return prevState.concat(id);
				}
				return prevState.filter(c => c !== id);
			});
		},
		[destinationListSelected]
	);

	const renderChosenContact = useCallback(() => {
		const returnStructure = ['to', 'cc', 'bcc'].map(field => (
			<Fragment>
				{chosen[field].length > 0 && (
					<div class={style.additionalSelected}>
						<Text id={`compose.${field}`} />
					</div>
				)}
				{chosen[field].map(contactId => (
					<ContactPickerContact
						contact={getContact(contactId)}
						onClick={selectChosenContact}
						selected={(destinationListSelected || []).indexOf(contactId) !== -1}
						hideAvatar
					/>
				))}
			</Fragment>
		));

		return returnStructure;
	}, [chosen, destinationListSelected, getContact, selectChosenContact]);

	useEffect(() => {
		const { to, cc, bcc } = additionalContacts;

		const chosenArray = {
			to: to.map(({ id }) => id),
			cc: cc.map(({ id }) => id),
			bcc: bcc.map(({ id }) => id)
		};

		dispatch(setChosen({ ...chosen, ...chosenArray }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const chosenContactsCount = chosen.to.length || chosen.cc.length || chosen.bcc.length;

	return (
		<Fragment>
			<div class={style.contactPickerButtons}>
				<Button onClick={callWith(chooseContacts, 'to', true)} styleType="primary" brand="primary">
					<Text id="compose.to" />
				</Button>
				{addressFieldType && (
					<Fragment>
						<Button onClick={callWith(chooseContacts, 'cc', true)}>
							<Text id="compose.cc" />
						</Button>
						<Button onClick={callWith(chooseContacts, 'bcc', true)}>
							<Text id="compose.bcc" />
						</Button>
					</Fragment>
				)}
				<Button onClick={removeChosenContacts} disabled={destinationListSelected.length === 0}>
					<Icon name="trash" size="sm" />
				</Button>
			</div>
			<div class={style.contactList}>
				<div class={cx(style.content, !chosenContactsCount && style.hideSelectAll)}>
					{chosenContactsCount ? (
						renderChosenContact()
					) : (
						<span class={style.noContacts}>
							<Text id="lists.empty" />
						</span>
					)}
				</div>
			</div>
		</Fragment>
	);
}
