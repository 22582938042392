import { gql } from '@apollo/client';
import get from 'lodash-es/get';
import { normalize, entities, types as apiClientTypes } from '@zimbra/api-client';
import {
	generateFragmentName,
	attributeKeys,
	getFolderDetails,
	getCurrentUrlDetails,
	readSearchQuery,
	readSearchQueries,
	EMPTY_EMAIL_ADDRESS,
	writeSearchQuery
} from './common';
import { optimisticSetSearchFolder, resolveFolderName } from '../graphql/utils/graphql-optimistic';
import { route } from 'preact-router';
import { DESCENDING } from '../constants';

const { MessageInfo } = entities;
const { MailFolderView } = apiClientTypes;

const normalizeMessage = normalize(MessageInfo);

const emptyMessage = {
	changeDate: null,
	conversationId: null,
	date: null,
	emailAddresses: null,
	excerpt: null,
	flags: null,
	folderId: null,
	id: null,
	modifiedSequence: null,
	revision: null,
	size: null,
	sortField: null,
	subject: null,
	tagNames: null,
	tags: null,
	autoSendTime: null,
	local: false,
	senderDate: null,
	part: null,
	__typename: 'MessageInfo'
};

export function processCreatedMessages({ store, client, cache, items }) {
	// for created messages these are the things that we need to do:
	// 1. Get the current folder's details
	// 2. Read the all message query from cache
	// 3. Start iterating through the items and put the created ones on the top of the query, if they belong to current folder
	// 4. Write the query back to cache

	// reverse the items as they are in ascending order of time
	items = items.reverse();

	const currentUrlDetails = getCurrentUrlDetails(store);

	// get the active folder's details, so that we can only process the notifications for the current active folder only
	const currentFolderDetails = getFolderDetails(client, currentUrlDetails.path);

	if (!currentFolderDetails) {
		console.warn('Folder not found', currentUrlDetails.path, 'Skipping items', items);
		return;
	}

	// read the all message search query from the cache
	const { queriesData, queriesVars } = readSearchQueries(client, currentUrlDetails.path, {
		types: MailFolderView.message
	});

	// return if the cache did not return the data for the query
	if (queriesData.length === 0) return;

	// update cache of each search query
	queriesData.forEach((queryData, index) => {
		const messagesInQuery = get(queryData, 'search.messages') || [];

		// create a map from the returned items from conversation query used to read the items
		// from the query, as the index based reads in the map (O(1)) are faster than array (O(n))
		const messagesQueryMap = new Map();
		messagesInQuery.forEach(message => messagesQueryMap.set(message.id, message));

		const messagesToBeAdded = [];

		// reversing items as the new messages are at bottom in the notification
		items.forEach(i => {
			let item = normalizeMessage(i);

			// if the query already has the message, return
			if (messagesQueryMap.has(item.id)) {
				return;
			}

			item = {
				...emptyMessage,
				...item
			};

			// check if message belongs to the current folder, if yes add it to messagesToBeAdded
			if (item.folderId === currentFolderDetails.id) {
				// update the email address types
				if (item.emailAddresses && item.emailAddresses.length > 0) {
					item.emailAddresses = item.emailAddresses.map(addr => ({
						...EMPTY_EMAIL_ADDRESS,
						...addr
					}));
				}

				messagesToBeAdded.push(item);
			}
		});

		if (messagesToBeAdded.length > 0) {
			const updatedData = {
				...queryData,
				search: {
					...queryData.search,
					messages:
						queriesVars[index]?.sortBy?.indexOf(DESCENDING) > -1
							? [...messagesToBeAdded, ...Array.from(messagesQueryMap.values())]
							: [...Array.from(messagesQueryMap.values()), ...messagesToBeAdded]
				}
			};

			writeSearchQuery(cache, updatedData, queriesVars[index]);
		}
	});
}

export function processModifiedMessages({ cache, items }) {
	// perform the write fragments only if the active view is conversation view
	items.forEach(i => {
		const item = normalizeMessage(i);
		cache.writeFragment({
			id: `MessageInfo:${item.id}`,
			fragment: gql`
						fragment ${generateFragmentName('messageNotification', item.id)} on MessageInfo {
							${attributeKeys(item)}
						}
					`,
			data: {
				__typename: 'MessageInfo',
				...item
			}
		});
	});
}

export function processDeletedMessages({ store, client, items: deletedMessages, isTag }) {
	const currentUrlDetails = getCurrentUrlDetails(store);

	// get the active folder's details, so that we can only process the notifications for the current active folder only
	const currentFolderDetails = getFolderDetails(client, currentUrlDetails.path);

	if (!currentFolderDetails) {
		console.warn('Folder not found', currentUrlDetails.path, 'Skipping items', deletedMessages);
		return;
	}

	// read the current folder's search query from the cache
	const { queryData } = readSearchQuery(client, currentUrlDetails.path);

	// return if the cache did not return the data for the query
	if (!queryData) return;

	const messagesInQuery = get(queryData, 'search.messages') || [];

	const key = 'messages';
	const folderName = resolveFolderName(client, currentFolderDetails, isTag);

	deletedMessages.forEach(i => {
		if (messagesInQuery.some(message => message.id === i)) {
			// Remove messages from the search results for folderName
			optimisticSetSearchFolder(
				client,
				folderName,
				false,
				({ data }) => ({
					[key]:
						data.search[key] && data.search[key].filter(({ id }) => !~deletedMessages.indexOf(id))
				}),
				isTag
			);
			get(store.getState().url, 'routeProps.id') === i && route(`/email/${folderName}`);
		}
	});
}
