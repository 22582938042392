import { SearchGal } from '../../graphql/queries/contacts/search-gal.graphql';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import get from 'lodash-es/get';
import withAccountInfo from '../account-info';

export default function withSearchGal(config = {}) {
	const { skip, ...restConfig } = config;

	return compose(
		withAccountInfo(({ data: { accountInfo } }) => ({
			zimbraFeatureGalEnabled: get(accountInfo, 'attrs.zimbraFeatureGalEnabled')
		})),
		graphql(SearchGal, {
			props: ({ data: { searchGal } }) => ({
				galContacts: get(searchGal, 'contacts') || []
			}),
			...restConfig,
			skip: ({ zimbraFeatureGalEnabled, ...args }) => {
				if (zimbraFeatureGalEnabled) {
					return typeof skip === 'function' ? skip(args) : skip;
				}
				return true;
			}
		})
	);
}
