import get from 'lodash-es/get';
import { BRIEFCASE } from '../constants/folders';
import {
	DEFAULT_SORT_ORDER,
	getCurrentFolderNameFromUrl,
	getFolderDetails,
	getFolderSortOrder,
	getIsSearchView,
	readSearchQuery,
	writeSearchQuery
} from './common';
import { BRIEFCASE_VIEW } from '../constants/views';
import { normalize, entities } from '@zimbra/api-client';

const { Document } = entities;

const normalizeDocument = normalize(Document);

export const processModifiedBriefcaseDocuments = ({ store, client, cache, modifiedItems }) => {
	const isSearchView = getIsSearchView(store);

	if (isSearchView) {
		return;
	}
	let currentFolderName = getCurrentFolderNameFromUrl(store);
	currentFolderName = currentFolderName || BRIEFCASE;

	// get the active folder's details, so that we can only process the notifications for the current active folder only
	const currentFolderDetails = getFolderDetails(client, currentFolderName, BRIEFCASE_VIEW);

	if (!currentFolderDetails) {
		console.error('Folder not found', currentFolderName);
		return;
	}

	const folderSortOrder = getFolderSortOrder(client, currentFolderDetails.id);

	// do not handle the notifications if the sortOrder is not same as dateDesc
	if (folderSortOrder !== DEFAULT_SORT_ORDER) {
		return;
	}

	// read the current folder's search query from the cache
	const { queryData, queryVars } = readSearchQuery(client, currentFolderName);

	// return if the cache did not return the data for the query
	if (!queryData) {
		return;
	}

	const documentInQuery = get(queryData, 'search.documents') || [];

	const documentQueryMap = new Map();

	documentInQuery.forEach(doc => documentQueryMap.set(doc.id, doc));

	const updatedItems = [];

	let queryToBeUpdated = false;

	modifiedItems.forEach(i => {
		const item = normalizeDocument(i);

		// this flag will decide if we want to move the item to top
		// if the item date and version has changed and that item is present in cache we will
		// move to top otherwise just update the cache data for the single item that is modified
		let itemTobeMoved = false;

		// read the document from the search query
		let documentInCache = documentQueryMap.get(item.id);

		// if the modified item is from a different folder other than the
		// currentFolder dont update , it shall be updated once we visit that folder
		if (!documentInCache) {
			return;
		}

		// update the search query if we have any non-skipped item
		queryToBeUpdated = true;

		// as when we get updated data for any acl we dont have __typename property i.e
		// why adding typename as the when we normalize the item we dont get the data
		const acl = get(item, 'acl');
		const grant = get(item, 'acl.grant');
		let aclData;
		// this will check if there is any data related to acl in modified item
		if (acl) {
			if (grant) {
				aclData = {
					grant: grant.map(data => ({ ...data, __typename: 'ACLGrant' })),
					__typename: 'ACL'
				};
			} else {
				aclData = { grant: null, __typename: 'ACL' };
			}
		}

		if (item.date && item.version) {
			itemTobeMoved = true;
			documentInCache = {
				...documentInCache,
				...item,
				...(acl && { acl: aclData }),
				sortField: item.date.toString()
			};
		} else {
			documentInCache = { ...documentInCache, ...item, ...(acl && { acl: aclData }) };
		}

		if (itemTobeMoved) {
			// as the document date and version is updated so move it to top
			updatedItems.push(documentInCache);
			documentQueryMap.delete(item.id);
		} else {
			// updating the document in place otherwise
			documentQueryMap.set(item.id, documentInCache);
		}
	});

	if (queryToBeUpdated) {
		const updatedData = {
			...queryData,
			search: {
				...queryData.search,
				documents: [...updatedItems, ...Array.from(documentQueryMap.values())]
			}
		};

		writeSearchQuery(cache, updatedData, queryVars);
	}
};
