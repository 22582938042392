import { usePreferences } from '../../../hooks/preferences';
import get from 'lodash-es/get';
import { useSelector } from 'react-redux';
import { BRIEFCASE } from '../../../constants/folders';
import { getSortOrderForFolder, getDocumentListData, getDocumentsWithVersions } from '../util';
import {
	getFolder,
	getFolderNameFunction,
	getFoldersAndSharedFolders
} from '../../../utils/folders';
import { useBriefcaseFoldersQuery, useBriefcaseSearchQuery } from '../../../hooks/briefcase';
import { DEFAULT_SORT } from '../../../constants/search';
import { useClientConfig } from '../../../hooks/client-config';
import { nextPrevItemToSelect } from '../../../utils/common';
import { useCallback } from 'preact/hooks';

const useReduxData = () => {
	const [url, sortBy, selected] = useSelector(state => [
		get(state, 'url', false),
		get(state, 'briefcase.sortBy'),
		get(state, 'briefcase.selectedIds', [])
	]);

	const isSearchView = get(url, 'routeProps.isSearchView', false);
	const query = get(url, 'routeProps.q');

	return { url, sortBy, selected, isSearchView, query };
};

const useFoldersData = ({ folderName, fetchPolicy }) => {
	const { data: foldersData } = useBriefcaseFoldersQuery({
		fetchPolicy: fetchPolicy || 'cache-only'
	});
	const { folders, sharedFolders } = getFoldersAndSharedFolders(foldersData);
	const currentFolder = getFolder(
		[...folders, ...sharedFolders],
		getFolderNameFunction(folderName)
	);

	return { folders, sharedFolders, currentFolder };
};

const useSortValue = ({ sortBy, currentFolder, isSearchView, fetchPolicy }) => {
	const { data } = usePreferences({ fetchPolicy: fetchPolicy || 'cache-only' });
	const zimbraPrefSortOrder = get(data, 'getPreferences.zimbraPrefSortOrder');

	const sortValue =
		(isSearchView && sortBy) ||
		(currentFolder && getSortOrderForFolder(zimbraPrefSortOrder, currentFolder)) ||
		DEFAULT_SORT;

	return { sortValue };
};

const useBriefcaseDocuments = ({ isSearchView, folderName, query, sortValue, fetchPolicy }) => {
	const documentQuery = isSearchView ? query : `in:"${folderName}"`;
	const { documents } = useBriefcaseSearchQuery({
		documentQuery,
		sortValue,
		fetchPolicy: fetchPolicy || 'cache-only'
	});
	return { documents };
};

const useBriefcaseView = () => {
	const view = useSelector(state => get(state, 'url.view', false));
	const { slugs } = useClientConfig({ slugs: 'routes.slugs' });
	const inBriefcase = view && view === slugs.briefcase;

	return { inBriefcase };
};

export const useBriefcaseSelectedItems = ({ selectedFolderName, fetchPolicy } = {}) => {
	const { url, sortBy, selected, isSearchView, query } = useReduxData();
	const folderName = selectedFolderName || get(url, 'routeProps.folderName') || BRIEFCASE;
	const { folders, sharedFolders, currentFolder } = useFoldersData({ folderName, fetchPolicy });
	const { sortValue } = useSortValue({ sortBy, currentFolder, isSearchView, fetchPolicy });
	const { inBriefcase } = useBriefcaseView();
	const { documents } = useBriefcaseDocuments({
		isSearchView,
		folderName,
		query,
		sortValue,
		fetchPolicy
	});
	let listData = getDocumentListData({
		documents,
		...(((inBriefcase && !isSearchView) || (!inBriefcase && !selectedFolderName)) && {
			currentFolder
		})
	});

	const expandedIds = useSelector(state => state.briefcase.expandedIds);
	listData = getDocumentsWithVersions(listData, expandedIds);

	const selectedList = listData.filter(({ id }) => selected.includes(id));

	const itemToBeSelectAfterDelete = useCallback(() => {
		const selectedIds = selectedList.map(list => list.id);
		const { nextItem, prevItem } = nextPrevItemToSelect(listData, selectedIds);
		return nextItem || prevItem || {};
	}, [listData, selectedList]);

	return { selectedList, folders, sharedFolders, currentFolder, itemToBeSelectAfterDelete };
};
