import { createAction } from 'redux-actions';

export const toggleSelected = createAction('mail toggle.selected');
export const toggleAllSelected = createAction('mail toggle.allSelected');
export const clearSelected = createAction('mail clearSelected');
export const setSelected = createAction('mail setSelected');
export const setCounterAcceptedMailId = createAction('mail setCounterAcceptedMailId');
export const setCounterAccepted = createAction('mail setCounterAccepted');

export const addAttachmentGroup = createAction('mail addAttachmentGroup');
export const removeAttachmentGroup = createAction('mail removeAttachmentGroup');
export const removeAttachmentsFromGroup = createAction('mail removeAttachmentsFromGroup');

export const setDeletedConversationItems = createAction('mail setDeletedConversationItems');
export const clearDeletedConversationItems = createAction('mail clearDeletedConversationItems');
