import style from './style';
import cx from 'classnames';
import { Text, Localizer } from 'preact-i18n';
import { useState, useCallback, useContext } from 'preact/hooks';
import ClientConfig from '../../context/client-config';
import { Link } from 'preact-router';

const Logo = ({ cls, isSecondaryLogo, title }) => {
	const [error, setError] = useState(false);
	const handleMissingIcon = useCallback(() => setError(true), [setError]);
	const config = useContext(ClientConfig);
	return (
		<span class={cx(style.logo, cls)}>
			<Localizer>
				<img
					src={config.getAssetURLByClient(
						`${config.data.hasSecondaryLogo && isSecondaryLogo ? 'secondarylogo' : 'logo'}.svg`
					)}
					onError={handleMissingIcon}
					alt={error && <Text id="app.errors.logo_missing" />}
					{...(title && { title })}
				/>
			</Localizer>
		</span>
	);
};

export default function ClientLogo({ class: cls, isSecondaryLogo, href, title, target }) {
	return href ? (
		target ? (
			<a href={href} {...(title && { title })} target={target} rel="noopener noreferrer">
				<Logo isSecondaryLogo={isSecondaryLogo} cls={cls} />
			</a>
		) : (
			<Link href={href} {...(title && { title })}>
				<Logo isSecondaryLogo={isSecondaryLogo} cls={cls} />
			</Link>
		)
	) : (
		<Logo title={title} isSecondaryLogo={isSecondaryLogo} cls={cls} />
	);
}
