import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';

const initialState = {
	loading: false,
	data: {},
	error: null,
	canceled: false,
	selectedDate: null,
	latestCompletedSyncDate: null,
	showDiskSpaceWarning: false
};

export default handleActions(
	{
		[actionCreators.setSyncStatus]: (state, action) => ({
			...state,
			loading: action.payload.isLoading,
			data: action.payload.data,
			error: action.payload.error,
			canceled: action.payload.canceled
		}),
		[actionCreators.setSyncSelectedDate]: (state, action) => ({
			...state,
			selectedDate: action.payload
		}),
		[actionCreators.setLatestCompletedSyncDate]: (state, action) => ({
			...state,
			latestCompletedSyncDate: action.payload
		}),
		[actionCreators.setDiskSpaceWarning]: (state, action) => ({
			...state,
			showDiskSpaceWarning: action.payload
		})
	},
	initialState
);
