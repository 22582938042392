import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';
import update from 'immutability-helper';

const initialState = {
	loading: false,
	data: {},
	error: null,
	pstFileName: undefined,
	reloadLocalFolderList: false,
	pstRootFolderId: undefined
};

export default handleActions(
	{
		[actionCreators.setPstStatus]: (state, action) => {
			return {
				...state,
				loading: action.payload.isLoading,
				data: action.payload.data,
				error: action.payload.error,
				reloadLocalFolderList: action.payload.reloadLocalFolderList,
				pstFileName: action.payload.pstFileName
			};
		},
		[actionCreators.setPstRootFolder]: (state, action) => {
			const { pstRootFolderId } = action.payload;
			return update(state, { pstRootFolderId: { $set: pstRootFolderId } });
		}
	},
	initialState
);
