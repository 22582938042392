import './style.less';
export const ZmEditorToolbar = ({
	leftItems,
	rightItems,
	toolbarWrapperId,
	class: cls,
	children
}) => {
	return (
		<div class={cls} zmEditorToolbar>
			{children}
			{leftItems && <div zmEditorToolbarLeft>{leftItems}</div>}
			<div id={toolbarWrapperId} zmEditorToolbarWrapper />
			{rightItems && <div zmEditorToolbarRight>{rightItems}</div>}
		</div>
	);
};
