import qs from 'query-string';
import flatMap from 'lodash-es/flatMap';

// High Fidelity Document Preview for Network Edition only - see https://wiki.zimbra.com/wiki/High_Fidelity_Document_Preview
const HFDP_PREVIEW_SUPPORTED_MIMETYPES =
	/(?:(?:^(?:image\/|text\/plain|text\/csv))|(?:ms-?(?:powerpoint|excel|word))|(?:(?:wordprocessing|spreadsheet|presentation)ml)|(?:opendocument\.(?:presentation|spreadsheet|text))|pdf|rtf$)/i;

// High Fidelity Document Preview for Network Edition only - see https://wiki.zimbra.com/wiki/High_Fidelity_Document_Preview
const HFDP_PREVIEW_SUPPORTED_MIMETYPES_ONLY_OFFICE =
	/(?:(?:^(?:image\/|text\/plain|text\/csv))|(?:ms-?(?:powerpoint|excel|word|xpsdocument))|(?:(?:wordprocessing|spreadsheet|presentation)ml)|(?:opendocument\.(?:presentation|spreadsheet|text))|pdf|rtf|xml$)/i;

const PREVIEW_SUPPORTED_MIMETYPES = /(?:(?:^(?:image\/|text\/plain|text\/csv))|pdf)/i;

const regexForText = /text\/(?!csv).*/i;

/* Annotated:
	((^(image\/|text\/plain|text\/csv))                   - Any image, or plaintext, or csv
	(ms-?(powerpoint|excel|word))                         - MS Office documents
	((wordprocessing|spreadsheet|presentation)ml)         - open office documents
	(opendocument\.(presentation|spreadsheet|text))       - open office documents
	pdf$                                                  - pdf files
*/

export function hasAttachmentPreview(attachment, hfdpEnabled, isDocumentEditorEnabled) {
	return (
		attachment &&
		(attachment.contentType || attachment.type) &&
		(hfdpEnabled
			? isDocumentEditorEnabled
				? HFDP_PREVIEW_SUPPORTED_MIMETYPES_ONLY_OFFICE
				: HFDP_PREVIEW_SUPPORTED_MIMETYPES
			: PREVIEW_SUPPORTED_MIMETYPES
		).test(attachment.contentType || attachment.type)
	);
}

export function encodeAttachment(attachment) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			attachment.base64 = reader.result.replace(/^(.+);base64,/, '');
			resolve(attachment);
		};
		reader.onerror = reject;
		reader.readAsDataURL(attachment);
	});
}

export function isImage(attachment) {
	return (
		attachment &&
		(attachment.contentType || attachment.type) &&
		(attachment.contentType || attachment.type).indexOf('image/') === 0
	);
}

export function isTextFile(attachment) {
	return (
		attachment &&
		(attachment.contentType || attachment.type) &&
		regexForText.test(attachment.contentType || attachment.type)
	);
}
export const isImageDeep = item => isImage(item.attachment);

export function isInline(attachment) {
	const contentDisposition = String(attachment.cd || attachment.contentDisposition);
	return (
		contentDisposition === 'inline' ||
		((attachment.cid || attachment.contentId) && contentDisposition !== 'attachment')
	);
}

/**
 * Function is used to check if the attachments is not a inline attachment
 * @param {Object} attachment which has properties of the attachments
 * @returns {Boolean} return false if attachment is a inline attachment
 */
export function isAttachmentDisposition(attachment) {
	return !isInline(attachment);
}

/**
 * Given a file, force it to a given contentDisposition
 */
export function forceContentDisposition(attachment, contentDisposition) {
	return contentDisposition === 'attachment'
		? { ...attachment, contentDisposition, contentId: undefined }
		: contentDisposition === 'inline'
		? { ...attachment, contentDisposition }
		: attachment;
}

/**
 * Return true for attachments that should not be displayed to the user.
 */
export function isHiddenAttachmentType({ filename }) {
	// If a attachment has no name or ends in `ics`, hide it from the user.
	return !filename || /\.ics$/.test(filename);
}

/**
 * Given an attachment URL, add max_width/max_height parameters
 * @param {String} url
 * @param {Object} dimensions
 * @property {Number} dimensions.width
 * @property {Number} dimensions.height
 * @returns {String}                      Returns the {@param url} with added max_width/max_height parameters
 */
export function addDimensionConstraintParameters(url, { width, height }) {
	const [pathname, query] = url.split('?');
	const params = query ? qs.parse(query) : {};

	if (width) {
		params.max_width = width;
	}

	if (height) {
		params.max_height = height;
	}

	return `${pathname}?${qs.stringify(params)}`;
}

export function getAttachments(messages) {
	if (!Array.isArray(messages)) return [];
	return flatMap(messages, message =>
		(message.attachments || message.inlineAttachments || []).map(attachment => ({
			attachment,
			message
		}))
	);
}

//upload url for uploading files to server , for uploading attachments in message the upload URL is /service/upload?fmt=extended,raw
export const fileUploadURL = '/service/upload?lbfums=';
