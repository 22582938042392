import PropTypes from 'prop-types';
import { Text } from 'preact-i18n';

import ModalDialog from '../modal-dialog';
import { getTranslatedFolderName } from '../folder-list/util';
import { TRASH, JUNK } from '../../constants/folders';

const EmptyFolderDialog = ({ onConfirm, onClose, folder, folderNameProp = 'name' }) => {
	const { name } = folder;
	const descriptionKey =
		name === TRASH
			? `dialogs.emptyFolder.DESCRIPTION_TRASH`
			: `dialogs.emptyFolder.DESCRIPTION_GENERAL`;

	const titleKey =
		name === TRASH || name === JUNK
			? `dialogs.emptyFolder.DIALOG_TITLE`
			: `dialogs.emptyFolder.DIALOG_TITLE_GENERAL`;

	const fieldsObject = {
		name: getTranslatedFolderName(folder, folderNameProp)
	};
	return (
		<ModalDialog
			title={<Text id={titleKey} fields={fieldsObject} />}
			onAction={onConfirm}
			onClose={onClose}
		>
			<p>
				<Text id={descriptionKey} fields={fieldsObject} />
			</p>
		</ModalDialog>
	);
};

EmptyFolderDialog.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	folder: PropTypes.object.isRequired
};

export default EmptyFolderDialog;
