import { Component } from 'preact';
import { withText } from 'preact-i18n';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import ports from '../../../constants/ports';
import ActiveAccountsView from './active-accounts';
import AddEditPersonaView from './add-edit-persona';
import AddEditAccountView from './add-edit-account';
import Breadcrumb from '../../breadcrumb';
import { getInputValue } from '../../../lib/util';
import withDiscoverRights from '../../../graphql-decorators/account-info/discover-rights';
import withCreateIdentity from '../../../graphql-decorators/create-identity';
import mailPort from '../../../utils/mail-port';
import { USER_FOLDER_IDS } from '../../../constants';
import {
	withAddExternalAccount,
	withModifyExternalAccount,
	withImportExternalAccountData
} from '../../../graphql-decorators/external-account';
import withCreateFolderMutation from '../../../graphql-decorators/create-folder';
import { SEND_AS, SEND_ON_BEHALF } from '../../../constants/rights';
import { EXTERNAL_ACCOUNT_TYPES } from '../constants';
import style from '../style';

const NEW_ACCOUNT_FORM_DATA = {
	destFolderName: '',
	emailAddress: '',
	host: '',
	leaveOnServer: true,
	port: ports.imap.crypt,
	username: '',
	useSSL: true,
	useCustomPort: false,
	useCustomFolder: true
};

@withDiscoverRights()
@withCreateIdentity()
@withAddExternalAccount()
@withModifyExternalAccount()
@withImportExternalAccountData()
@withCreateFolderMutation()
@withText({
	accountsTitle: 'settings.accounts.title',
	newAccount: 'settings.accounts.newAccount',
	newPersona: 'settings.accounts.newPersona',
	editDetails: 'settings.accounts.editDetails',
	confirmRemoval: 'settings.accounts.confirmRemoval',
	sendOBO: 'settings.accounts.sendOnBehalf'
})
export default class AccountsSettings extends Component {
	state = {
		activeScreen: 'active',
		accountsList: [],
		selectedAccountId: '',
		signature: ''
	};

	switchActiveScreen = ([newView, editAccountId]) => {
		this.setState({ activeScreen: newView });

		if (editAccountId) {
			this.props.accountChangeEvent(editAccountId);
		}
	};

	onSelectedAccountFormChange = (target, accountId) => e =>
		this.setState({
			accountsList: [].concat(
				...this.state.accountsList,
				Object.assign({}, this.state.accountsList[accountId], {
					[target]: getInputValue(e)
				})
			)
		});

	onFormDataChange =
		target =>
		(e, cb = () => {}) => {
			this.setState(
				prevState => ({
					accountFormData: {
						...prevState.accountFormData,
						[target]: getInputValue(e)
					}
				}),
				cb
			);
		};

	createFolderByName = (baseFolderName, duplicateCount = 0) => {
		const folderName =
			duplicateCount > 0 ? `${baseFolderName} - ${duplicateCount + 1}` : baseFolderName;
		const folder = find(this.props.folders, ['absFolderPath', `/${folderName}`]);

		return folder
			? this.createFolderByName(baseFolderName, duplicateCount + 1)
			: this.props.createFolder({
					variables: {
						name: folderName,
						fetchIfExists: true
					}
			  });
	};

	handleExternalAccountAction = ({ isEditView, data }) => {
		this.setState({
			accountFormError: null
		});

		const { addExternalAccount, modifyExternalAccount, importExternalAccount, folders } =
			this.props;

		const {
			useSSL,
			useCustomFolder,
			accountType,
			username,
			port: customPort,
			useCustomPort
		} = data;
		const connectionType = useSSL ? 'ssl' : 'cleartext';
		const port = useCustomPort ? customPort : mailPort(accountType, useSSL);

		let folderId = USER_FOLDER_IDS.INBOX;
		const createFolderPromise = [];

		if (useCustomFolder) {
			const accountFolder = folders.find(
				f => f.absFolderPath && f.absFolderPath.replace('/', '') === username
			);

			if (accountFolder) {
				folderId = accountFolder.id;
			} else {
				createFolderPromise.push(
					this.createFolderByName(username).then(({ data: { createFolder } }) => {
						folderId = createFolder.id;
					})
				);
			}
		}

		if (isEditView) {
			data.connectionType = connectionType;
			data.port = port;

			if (accountType === 'pop3') {
				data.l = folderId;
			}

			return Promise.all(createFolderPromise).then(() =>
				modifyExternalAccount(data).then(() => {
					this.setState({
						activeScreen: 'list',
						accountFormData: { ...NEW_ACCOUNT_FORM_DATA, accountType }
					});
				})
			);
		}

		const { emailAddress, host, leaveOnServer, password } = data;

		return Promise.all(createFolderPromise).then(() =>
			addExternalAccount({
				accountType,
				emailAddress,
				host,
				password,
				port,
				username,
				connectionType,
				isEnabled: true,
				l: folderId,
				leaveOnServer: accountType === 'pop3' ? leaveOnServer : true,
				name: username
			})
				.then(({ data: { addExternalAccount: id } }) =>
					id !== undefined
						? importExternalAccount({
								accountType,
								id
						  })
						: Promise.resolve()
				)
				.then(() => {
					this.setState({
						activeScreen: 'list',
						accountFormData: { ...NEW_ACCOUNT_FORM_DATA, accountType }
					});
				})
		);
	};

	handleSubmitNewPersona = data => {
		const { createIdentity } = this.props;
		const {
			formData: {
				emailAddress,
				fromDisplay,
				name,
				replyToEnabled,
				replyToAddress,
				replyToDisplay,
				emailAddressType,
				whenInFolderIds,
				whenInFoldersEnabled,
				whenSentToAddresses,
				whenSentToEnabled
			}
		} = data;

		this.setState({
			accountFormError: null
		});

		return createIdentity({
			name,
			emailAddress,
			fromDisplay,
			replyToEnabled,
			replyToAddress,
			replyToDisplay,
			emailAddressType,
			whenInFolderIds,
			whenInFoldersEnabled,
			whenSentToAddresses,
			whenSentToEnabled
		}).then(() => {
			this.setState({
				activeScreen: 'list'
			});
		});
	};

	handleAfterNavigation = () => {
		this.switchActiveScreen(['list']);
	};

	constructor(props) {
		super(props);
		const { zimbraFeatureImapDataSourceEnabled, zimbraFeaturePop3DataSourceEnabled } = get(
			props,
			'accountData.attrs'
		);

		const accountType = zimbraFeatureImapDataSourceEnabled
			? EXTERNAL_ACCOUNT_TYPES.IMAP
			: zimbraFeaturePop3DataSourceEnabled
			? EXTERNAL_ACCOUNT_TYPES.POP
			: '';

		this.state = {
			activeScreen: 'list',
			accountFormData: { ...NEW_ACCOUNT_FORM_DATA, accountType },
			accountsList: [],
			signature: ''
		};
	}

	componentDidMount() {
		this.props.afterNavigation(this.handleAfterNavigation);
	}

	componentWillReceiveProps = nextProps => {
		if (nextProps.accounts !== this.props.accounts) {
			this.setState({ accountsList: nextProps.accounts });
		}
	};

	render(
		{
			onFieldChange,
			value,
			updateAccountSettings,
			accounts,
			accountData,
			accountsTitle,
			newAccount,
			newPersona,
			handleValidityOfTabs,
			delegatedRights,
			sendOBO,
			savedAccounts,
			activeId
		},
		{ activeScreen, accountFormData }
	) {
		// for ordering & separation purposes
		const {
				zimbraMailAlias,
				zimbraIdentityMaxNumEntries,
				zimbraFeatureIdentitiesEnabled,
				zimbraFeatureImapDataSourceEnabled,
				zimbraFeaturePop3DataSourceEnabled
			} = accountData.attrs,
			externalAccounts = [],
			delegatedTargets = get(delegatedRights, 'targets'),
			delegatedAccounts = [],
			sendTypes = {
				sendAs: SEND_AS,
				sendOBO: SEND_ON_BEHALF
			};

		delegatedTargets &&
			delegatedTargets.forEach(({ target, right }) => {
				target &&
					target.forEach(targetedAccount => {
						const email = get(targetedAccount, 'email[0].emailAddress');
						const option = right === SEND_ON_BEHALF ? `${sendOBO} ${email}` : email;
						delegatedAccounts.push(option);
					});
			});

		let primaryAccount;
		const personas = accounts.filter(account => {
			if (!account.accountType && !account.isPrimaryAccount) {
				return account;
			} else if (account.isPrimaryAccount) primaryAccount = account;
			else externalAccounts.push(account);
		});
		const externalAccountEmails = externalAccounts.map(account => account.emailAddress);
		const accountsSorted = [primaryAccount, ...(externalAccounts || []), ...(personas || [])];

		let { mailForwardingAddress, mailLocalDeliveryDisabled } = value;
		const fromAccounts = [accountData.name, ...(zimbraMailAlias || [])];

		const activeAccountsProps = {
			accounts: accountsSorted,
			switchActiveScreen: this.switchActiveScreen,
			maxPersonasReached: personas.length + 1 === zimbraIdentityMaxNumEntries,
			personasEnabled: zimbraFeatureIdentitiesEnabled,
			fromAccounts
		};

		const addAccountProps = {
			onFormDataChange: this.onFormDataChange,
			formData: accountFormData,
			zimbraFeatureImapDataSourceEnabled,
			zimbraFeaturePop3DataSourceEnabled
		};

		mailForwardingAddress = mailForwardingAddress
			? Array.isArray(mailForwardingAddress)
				? mailForwardingAddress
				: mailForwardingAddress.split(',').map(email => email.trim())
			: [];

		const editAccountProps = {
			savedAccounts,
			listOfAccounts: accounts,
			updateAccountSettings,
			onSelectedAccountFormChange: this.onSelectedAccountFormChange,
			onFieldChange,
			mailForwardingAddress,
			mailLocalDeliveryDisabled,
			handleMailForwardingActiveChange: this.handleMailForwardingActiveChange,
			accountData,
			handleValidityOfTabs,
			externalEmails: externalAccountEmails,
			fromAccounts,
			externalAccounts: externalAccountEmails,
			delegatedAccounts,
			sendTypes,
			sendOBOText: sendOBO,
			activeId
		};

		const renderActiveAccounts = () => (
			<ActiveAccountsView
				onExternalAccountAction={this.handleExternalAccountAction}
				editAccountProps={editAccountProps}
				{...activeAccountsProps}
			/>
		);

		const renderAccount = () => {
			return (
				<div class={style.w100}>
					<Breadcrumb
						items={[
							{ display: accountsTitle, value: 'list' },
							{ display: newAccount, value: 'add' }
						]}
						switchView={this.switchActiveScreen}
					/>
					<AddEditAccountView
						viewData={addAccountProps}
						onExternalAccountAction={this.handleExternalAccountAction}
					/>
				</div>
			);
		};

		// @TODO: Need to refactor - Currently it's occupying unnecessary space even though its not being used.
		const renderPersona = () => (
			<div class={style.w100}>
				<Breadcrumb
					items={[
						{ display: accountsTitle, value: 'list' },
						{
							display: newPersona,
							value: 'addPersona'
						}
					]}
					switchView={this.switchActiveScreen}
				/>
				<AddEditPersonaView
					onSubmit={this.handleSubmitNewPersona}
					handleValidityOfTabs={handleValidityOfTabs}
					externalAccounts={externalAccountEmails}
					fromAccounts={fromAccounts}
					delegatedAccounts={delegatedAccounts}
					sendTypes={sendTypes}
					switchView={this.switchActiveScreen}
				/>
			</div>
		);

		switch (activeScreen) {
			case 'list':
				return renderActiveAccounts();
			case 'add':
				return renderAccount();
			case 'addPersona':
				return renderPersona();
			default:
				break;
		}
	}
}
