import withMediaQuery from '../../../enhancers/with-media-query';
import { minWidth, screenMd } from '../../../constants/breakpoints';
import { Component } from 'preact';
import ModalDialog from './../../modal-dialog';
import ModalDrawer from './../../modal-drawer';
import ModalDrawerToolbar from './../../modal-drawer-toolbar';
import { Button } from '@zimbra/blocks';
import { Text, withText } from 'preact-i18n';
import style from './style';
import TextInput from '../../text-input';
import ColorPicker from './../../color-picker';
import isNil from 'lodash-es/isNil';
import {
	getFolderNameValidationStatus,
	INVALID_FOLDER_NAME_ERRORS,
	FOLDER_NAME_CHAR_LIMIT
} from '../../folder-list/util';
import { TAG_ACTIONS } from '../../../constants/tags';
import withTagAction from '../../../graphql-decorators/tags/tag-action';
import withTagCreate from '../../../graphql-decorators/tags/tag-create';
import get from 'lodash-es/get';

@withMediaQuery(minWidth(screenMd), 'matchesScreenMd')
@withText({
	duplicateTagError: 'tags.dialogs.newTag.errorDuplicateTag',
	invalidCharacterError: 'notifications.specialCharWarning',
	charLimitError: (
		<Text id="tags.dialogs.newTag.errorCharLimit" fields={{ count: FOLDER_NAME_CHAR_LIMIT }} />
	),
	inputPlaceholder: 'tags.dialogs.newTag.inputPlaceholder'
})
@withTagCreate()
@withTagAction()
export default class CreateTagModal extends Component {
	state = {
		id: get(this.props, 'tag.id'),
		tagName: get(this.props, 'tag.name') || '',
		tagColor: get(this.props, 'tag.color') || 1,
		error: null,
		isDrawerMounted: false
	};
	onChangeColor = value => {
		this.setState({ tagColor: value });
	};

	handleCloseDrawer = () => {
		const { onClose } = this.props;
		this.setState({ isDrawerMounted: false });
		onClose();
	};

	validate = (tagName, tags) => {
		let error;
		const { invalidCharacterError, duplicateTagError, charLimitError, tag } = this.props;
		const previousName = get(tag, 'name');
		const nameCharacterValidation = getFolderNameValidationStatus(tagName, false, true);
		if (!nameCharacterValidation.isValid) {
			const { notifyMessageID } = nameCharacterValidation;
			if (notifyMessageID === INVALID_FOLDER_NAME_ERRORS.SPECIAL_CHAR_WARNING) {
				error = invalidCharacterError;
			} else if (notifyMessageID === INVALID_FOLDER_NAME_ERRORS.LENGTH_EXCEED_WARNING) {
				error = charLimitError;
			}
		} else if (
			previousName !== tagName &&
			tags.some(({ name }) => name.toLowerCase() === tagName.toLowerCase())
		) {
			// skip this check if modal is opened in edit phase as matching name can be found in tags array if user has not modified name field.
			error = duplicateTagError;
		}
		return error;
	};

	getAction = ({ tagName, tagColor }, tag) => {
		const actionArray = [];
		const {
			tag: { color, name },
			tagAction
		} = this.props;
		tagName !== name &&
			actionArray.push(tagAction({ ...tag, name: tagName, color: tagColor }, TAG_ACTIONS.RENAME));
		tagColor !== color &&
			actionArray.push(tagAction({ ...tag, name: tagName, color: tagColor }, TAG_ACTIONS.COLOR));
		return actionArray;
	};

	onAction = () => {
		const { tags, onClose, createTag, tag } = this.props;
		const { tagName, tagColor, id } = this.state;
		const error = this.validate(tagName, tags);
		if (!error) {
			// if id is not present, fire create else fire edit request.
			!id && createTag({ name: tagName, color: tagColor });
			id &&
				Promise.all(this.getAction(this.state, tag)).catch(err => {
					console.error(err);
				});
			onClose();
		} else {
			this.setState({ error });
		}
	};

	onTagNameChange = event => {
		const updatedVal = event.target.value;
		const { tags } = this.props;
		const { error } = this.state;
		// Reset error if input field is in error state and user modifies input.
		this.setState({
			tagName: updatedVal,
			...(error &&
				!this.validate(updatedVal, tags) && {
					error: null
				})
		});
	};

	render(
		{ matchesScreenMd, inputPlaceholder, tag, onClose },
		{ isDrawerMounted, error, tagName, tagColor }
	) {
		const isNew = !tag,
			disablePrimary = tagName.trim().length === 0 || isNil(tagColor);

		const [ComponentClass, componentClassProps] = matchesScreenMd
			? [ModalDialog, { autofocusChildIndex: 1 }]
			: [
					ModalDrawer,
					{
						mounted: isDrawerMounted,
						toolbar: (
							<ModalDrawerToolbar
								buttons={[
									<Button
										styleType="primary"
										brand="primary"
										onClick={this.onAction}
										disabled={disablePrimary}
									>
										<Text id="buttons.save" />
									</Button>
								]}
								onClose={this.handleCloseDrawer}
							/>
						)
					}
			  ];

		return (
			<ComponentClass
				{...componentClassProps}
				title={`tags.dialogs.newTag.${isNew ? 'dialogTitle' : 'editDialogTitle'}`}
				onClose={onClose}
				disablePrimary={disablePrimary}
				matchesScreenMd={matchesScreenMd}
				buttons={[
					<Button
						styleType="primary"
						brand="primary"
						onClick={this.onAction}
						disabled={disablePrimary}
					>
						<Text id="buttons.save" />
					</Button>
				]}
				class={style.createTagModal}
				contentClass={style.createTagModalContent}
				error={error}
			>
				<div class={style.contentWrapper}>
					<TextInput
						value={tagName}
						onInput={this.onTagNameChange}
						placeholder={inputPlaceholder}
						wide
						autofocus
						invalid={!!error}
					/>
					<div class={style.colorPickerContainer}>
						<div class={style.colorLabel}>
							<Text id="tags.dialogs.newTag.colorLabel" />
						</div>
						<div class={style.colorPicker}>
							<ColorPicker onChange={this.onChangeColor} value={tagColor} />
						</div>
					</div>
				</div>
			</ComponentClass>
		);
	}
}
