import { Text } from 'preact-i18n';
import ActionMenu, { DropDownWrapper } from '../action-menu';
import ActionMenuGroup from '../action-menu-group';
import ActionMenuItem from '../action-menu-item';
import { callWith } from '../../lib/util';
import { withListHeaderActionMenu } from '../../enhancers/with-list-header-action-menu';
import style from './style';

function HeaderActionMenu({ sortBy, onSortBySelection, sortMenuOrderList }) {
	return (
		<ActionMenu label={<Text id={`mail.listHeaderMenu.sortBy.${sortBy}`} />} anchor="end">
			<DropDownWrapper>
				<ActionMenuGroup>
					{sortMenuOrderList.map(type => (
						<ActionMenuItem
							{...(type === sortBy && { icon: 'check' })}
							onClick={callWith(onSortBySelection, [type])}
							key={type}
						>
							<Text id={`mail.listHeaderMenu.sortBy.${type}`} />
						</ActionMenuItem>
					))}
				</ActionMenuGroup>
			</DropDownWrapper>
		</ActionMenu>
	);
}

export default withListHeaderActionMenu(style)(HeaderActionMenu);
