import { graphql } from '@apollo/client/react/hoc';
import SetCustomMetadataMutation from '../../graphql/mutations/set-custom-metadata.graphql';
import GetCustomMetadataQuery from '../../graphql/queries/custom-metadata.graphql';

export function withGetCustomMetaData(variables, mapProps) {
	return graphql(GetCustomMetadataQuery, {
		options: () => ({ variables }),
		props: result => mapProps(result)
	});
}

export function withSetCustomMetaData() {
	return graphql(SetCustomMetadataMutation, {
		props: ({ mutate }) => ({
			setCustomMetadata: variables => {
				return mutate({
					variables: {
						customMetaData: variables
					}
				});
			}
		})
	});
}
