import { Component } from 'preact';
import { graphql } from '@apollo/client/react/hoc';
import get from 'lodash-es/get';
import { Text } from 'preact-i18n';
import cx from 'classnames';
import { withStateHandlers } from 'recompose';
import { Icon } from '@zimbra/blocks';
import { callWith } from '../../../../lib/util';
import NakedButton from '../../../naked-button';
import GetAppSpecificPasswords from '../../../../graphql/queries/two-factor-auth/get-app-specific-passwords.graphql';
import CreateAppSpecificPassword from '../../../../graphql/mutations/two-factor-auth/create-app-specific-password.graphql';
import RevokeAppSpecificPassword from '../../../../graphql/mutations/two-factor-auth/revoke-app-specific-password.graphql';

import AppSpecificPasswordsList from './list';
import AddAppModal from './modal';

import style from '../../style';

@graphql(GetAppSpecificPasswords, {
	props: ({
		data: { getAppSpecificPasswords: { appSpecificPasswords, maxAppPasswords } = {} }
	}) => {
		const appPasswords = get(appSpecificPasswords, 'passwordData') || [];
		const maxNumOfAppPasswords = get(maxAppPasswords, '0._content');

		return {
			appPasswords,
			maxNumOfAppPasswords
		};
	}
})
@graphql(CreateAppSpecificPassword, {
	props: ({ mutate }) => ({
		createAppSpecificPassword: appName =>
			mutate({
				variables: {
					appName
				},
				fetchPolicy: 'no-cache',
				refetchQueries: [
					{
						query: GetAppSpecificPasswords
					}
				]
			})
	})
})
@graphql(RevokeAppSpecificPassword, {
	props: ({ mutate }) => ({
		revokeAppSpecificPassword: appName =>
			mutate({
				variables: {
					appName
				},
				refetchQueries: [
					{
						query: GetAppSpecificPasswords
					}
				]
			})
	})
})
@withStateHandlers(
	{
		showCreateAppPasswordModal: false
	},
	{
		toggleShowCreateAppPasswordModal: () => value => ({ showCreateAppPasswordModal: value })
	}
)
export default class AppSpecificPasswords extends Component {
	render({
		appPasswords,
		maxNumOfAppPasswords,
		toggleShowCreateAppPasswordModal,
		showCreateAppPasswordModal,
		createAppSpecificPassword,
		revokeAppSpecificPassword
	}) {
		return (
			<div class={style.subsection}>
				<div class={style.subsectionTitle}>
					<Text id="settings.accounts.editAccount.twoFactorAuth.appSpecificPasswords.title" />
				</div>
				<div class={style.subsectionBody}>
					{appPasswords.length > 0 && (
						<AppSpecificPasswordsList
							appPasswords={appPasswords}
							removeApp={revokeAppSpecificPassword}
						/>
					)}
					{appPasswords.length < maxNumOfAppPasswords && (
						<NakedButton
							class={cx(style.nakedButton, style.textButtonBold)}
							onClick={callWith(toggleShowCreateAppPasswordModal, true)}
						>
							<Icon name="plus" />
							<Text id="buttons.addAppSpecificPasscode" />
						</NakedButton>
					)}
				</div>
				{showCreateAppPasswordModal && (
					<AddAppModal
						createApp={createAppSpecificPassword}
						closeModal={callWith(toggleShowCreateAppPasswordModal, false)}
					/>
				)}
			</div>
		);
	}
}
