import { useEffect } from 'preact/hooks';

export const useCommandHandlers = (shortcutHandlers, context) => {
	const handlers = typeof shortcutHandlers === 'function' ? shortcutHandlers() : shortcutHandlers;

	useEffect(() => {
		context.shortcutCommandHandler.addCommandHandlers(handlers);

		return () => {
			if (handlers) {
				context.shortcutCommandHandler.removeCommandHandlers(handlers);
			}
		};
	}, [context.shortcutCommandHandler, handlers]);
};
