import { Component } from 'preact';
import { getName } from '../../utils/contacts';
import { ChoiceInput } from '@zimbra/blocks';
import Avatar from '../avatar';
import cx from 'classnames';
import style from './style';

export default class ContactPickerContact extends Component {
	select = () => {
		const { contact, onClick } = this.props;
		onClick(contact);
	};

	render({
		contact,
		contact: { attributes, attributes: { email } = {} } = {},
		selected,
		hideAvatar
	}) {
		const contactName = attributes && getName(attributes);

		return (
			<div class={cx(style.contact, selected && style.selected)} onClick={this.select}>
				<div class={style.flexItem}>
					<ChoiceInput checked={selected} readOnly />
				</div>
				{!hideAvatar && (
					<div class={style.flexItem}>
						<Avatar class={style.avatar} contact={contact} />
					</div>
				)}
				{
					<div class={cx(style.flexItem)}>
						{contactName && <span class={cx(style.contactName)}>{contactName}</span>}
						{email && <span class={cx(style.contactEmail)}>{email}</span>}
					</div>
				}
			</div>
		);
	}
}
