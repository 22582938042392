import { Text } from 'preact-i18n';
import moment from 'moment';
import cx from 'classnames';
import ErrorTooltip from '../../error-tooltip';
import TimePicker from '../../time-picker';
import HelpTooltip from '../../help-tooltip';
import { PureComponent } from 'preact/compat';
import cloneDeep from 'lodash-es/cloneDeep';
import mapValues from 'lodash-es/mapValues';
import get from 'lodash-es/get';
import style from '../style';
import withMediaQuery from '../../../enhancers/with-media-query';
import { minWidth, screenXsMax } from '../../../constants/breakpoints';
import { filterNonInsertableCalendars, CalendarOptionItem } from '../../../utils/calendar';
import { withGetRights } from '../../../graphql-decorators/rights';
import { Select, Option } from '@zimbra/blocks';
import { withCalendars } from '../../../graphql-decorators/calendar';
import SelectOption from '../../select';
import FreeBusyViewPermission from './freebusyview-permission';
import { isValidEmail } from '../../../lib/util';
import ZimletSlot from '../../zimlet-slot';
import {
	StartOfWeek,
	AutoAddAppointments,
	ShowEventTimeZone,
	EnableDelegation,
	EnableDeclinedEvents,
	ReminderEmail,
	ShowReminder,
	ShowDefaultRemindOptions,
	ShowReminderForPastDue
} from './subsections';
import momentTz from 'moment-timezone';

@withCalendars()
@withGetRights()
@withMediaQuery(minWidth(screenXsMax), 'matchesScreenXsMax')
export default class CalendarAndRemindersSettings extends PureComponent {
	state = {
		timeOfDay: cloneDeep(this.props.value.timeOfDay),
		timeError: { visible: false, message: '' }
	};

	updateStartTime = timeSlot => {
		const { timeOfDay } = this.state;
		if (moment(timeSlot, 'LT').isSameOrAfter(moment(timeOfDay[1].end, 'LT'))) {
			this.setState({
				timeError: {
					visible: true,
					message: 'Start of day cannot be after the end of day.'
				}
			});
			return;
		}
		const timeOfDayCopy = mapValues(timeOfDay, day => ({
			...day,
			start: moment(timeSlot, 'LT')
		}));
		this.setState({ timeOfDay: timeOfDayCopy, timeError: { visible: false, message: '' } }, () => {
			this.props.onFieldChange('timeOfDay')({
				target: { value: timeOfDayCopy }
			});
		});
	};

	updateAppleIcalDelegationEnabled = () => {
		const toggledValue = !this.props.value.enableAppleIcalDelegation;
		this.props.onFieldChange('enableAppleIcalDelegation')({
			target: { value: toggledValue }
		});
	};

	toggleDeclinedEvents = () => {
		const { onFieldChange, value } = this.props;
		const toggledValue = !value.showDeclinedEvents;
		onFieldChange('showDeclinedEvents')({
			target: { value: toggledValue }
		});
	};

	updateEndTime = timeSlot => {
		const { timeOfDay } = this.state;
		if (moment(timeSlot, 'LT').isSameOrBefore(moment(timeOfDay[1].start, 'LT'))) {
			this.setState({
				timeError: {
					visible: true,
					message: 'End of day cannot be before the start of day.'
				}
			});
			return;
		}

		const timeOfDayCopy = mapValues(timeOfDay, day => ({
			...day,
			end: moment(timeSlot, 'LT')
		}));
		this.setState({ timeOfDay: timeOfDayCopy, timeError: { visible: false, message: '' } }, () => {
			this.props.onFieldChange('timeOfDay')({
				target: { value: timeOfDayCopy }
			});
		});
	};

	selectCalendar = (id, calendars) => calendars.find(cal => cal.id === id);

	updateEventStartEndTimezoneShow = () => {
		const { onFieldChange } = this.props;
		const toggledValue = !get(this.props, 'value.showEventStartEndTimezone');
		onFieldChange('showEventStartEndTimezone')({
			target: { value: toggledValue }
		});
	};

	onCalendarChangeHandler = e => {
		const insertableCals = filterNonInsertableCalendars(this.props.calendars);

		this.setState({
			selectedCal: this.selectCalendar(e.value.id, insertableCals)
		});

		this.props.onFieldChange('defaultCalendar')({
			target: {
				value: e.value.id
			}
		});
	};

	handleReminderEmailInput = ({ target: { value } }) => {
		const { activeId, handleValidityOfTabs, onFieldChange } = this.props;
		const isError = !(!value || isValidEmail(value));

		this.setState(
			{
				isInvalidReminderEmail: isError
			},
			() => {
				onFieldChange('calendarReminderEmail')({
					target: {
						value
					}
				});

				handleValidityOfTabs(activeId, isError);
			}
		);
	};

	handleReminderTimeChange = ({ target: { value } }) => {
		const { onFieldChange } = this.props;
		let remindValue;

		if (value === 'never') remindValue = 0;
		else {
			const [, intervalValue, intervalType] = value.match(/(\d*)([mhdws])/);
			switch (intervalType) {
				case 's':
					remindValue = -1;
					break;
				case 'm':
					remindValue = intervalValue;
					break;
				case 'h':
					remindValue = intervalValue * 60;
					break;
				case 'd':
					remindValue = intervalValue * 60 * 24;
					break;
				case 'w':
					remindValue = intervalValue * 60 * 24 * 7;
					break;
				default:
					break;
			}
		}

		onFieldChange('zimbraPrefCalendarApptReminderWarningTime')({
			target: {
				value: parseInt(remindValue, 10)
			}
		});
	};

	renderDefaultCalenderDropdown = () => {
		const { value, matchesScreenXsMax, calendars } = this.props;
		const { selectedCal } = this.state;
		const insertableCals = filterNonInsertableCalendars(calendars);
		const defaultCalendar = value.defaultCalendar && value.defaultCalendar.toString();
		const defaultExists = insertableCals.find(({ id }) => id === defaultCalendar);

		return (
			<div class={style.subsection}>
				<div class={cx(style.subsectionTitle, style.forSelect)}>
					<Text id="settings.calendarAndReminders.defaultCalendarSubsection" />
				</div>
				<div class={style.subsectionBody}>
					<Select
						displayValue={
							insertableCals.length < 1 ? (
								'...'
							) : (
								<CalendarOptionItem
									calendar={
										selectedCal ||
										this.selectCalendar(
											(defaultExists && defaultCalendar) || insertableCals[0].id,
											insertableCals
										)
									}
									style={style}
								/>
							)
						}
						value={
							selectedCal ||
							this.selectCalendar(
								(defaultExists && defaultCalendar) ||
									(insertableCals.length > 0 && insertableCals[0].id),
								insertableCals
							)
						}
						iconPosition="right"
						iconSize="sm"
						onChange={this.onCalendarChangeHandler}
						class={style.calendarSelect}
						toggleButtonClass={style.toggleButtonClass}
						disabled={insertableCals.length < 2}
					>
						{insertableCals.map(cal => (
							<Option value={cal} key={cal.id} class={style.calendarOption}>
								<CalendarOptionItem calendar={cal} style={style} />
							</Option>
						))}
					</Select>
					<HelpTooltip
						position={matchesScreenXsMax ? 'left' : 'right'}
						customStyle={style.defaultCalTooltip}
					>
						<p class={style.helpTooltipTitle}>
							<Text id="settings.calendarAndReminders.defaultCalendarSubsection" />
						</p>
						<p>
							<Text id="settings.calendarAndReminders.defaultCalHelpTip" />
						</p>
					</HelpTooltip>
				</div>
			</div>
		);
	};

	renderTimezoneSelection = () => {
		const { value, onFieldChange } = this.props;
		return (
			<div class={style.subsection}>
				<div class={cx(style.subsectionTitle, style.forSelect)}>
					<Text id="settings.calendarAndReminders.timeZoneSubsection" />
				</div>
				<div class={cx(style.subsectionBody, style.flexContainer)}>
					<SelectOption value={value.timeZone} onChange={onFieldChange('timeZone')}>
						{momentTz &&
							momentTz.tz.names().map(TimeZone => <option value={TimeZone}>{TimeZone}</option>)}
					</SelectOption>
					<HelpTooltip>
						<p>
							<Text id="settings.calendarAndReminders.timezoneHelpTip" />
						</p>
					</HelpTooltip>
				</div>
			</div>
		);
	};

	render(
		{ getRightsQuery: { getRights }, onFieldChange, value },
		{ timeError, timeOfDay, isInvalidReminderEmail }
	) {
		const {
			startOfWeek,
			autoAddAppointmentsToCalendar,
			showEventStartEndTimezone,
			enableAppleIcalDelegation,
			showDeclinedEvents,
			calendarReminderEmail,
			zimbraPrefCalendarToasterEnabled,
			zimbraPrefCalendarApptReminderWarningTime,
			zimbraPrefCalendarShowPastDueReminders
		} = value;
		const startTime = moment(timeOfDay[1].start).format('LT');
		const endTime = moment(timeOfDay[1].end).format('LT');

		return (
			<div>
				<div>
					<div class={style.sectionTitle}>
						<Text id="settings.calendarAndReminders.generalSettingsTitle" />
					</div>
					{timeError.visible && <ErrorTooltip message={timeError.message} />}
					{this.renderDefaultCalenderDropdown()}
					<StartOfWeek startOfWeek={startOfWeek} onFieldChange={onFieldChange} />
					<DateSelector
						time={startTime}
						onUpdateTime={this.updateStartTime}
						label="startOfDaySubsection"
					/>
					<DateSelector
						time={endTime}
						onUpdateTime={this.updateEndTime}
						label="endOfDaySubsection"
					/>
					{this.renderTimezoneSelection()}
					<AutoAddAppointments
						autoAddAppointmentsToCalendar={autoAddAppointmentsToCalendar}
						onFieldChange={onFieldChange}
					/>
					<ShowEventTimeZone
						showEventStartEndTimezone={showEventStartEndTimezone}
						updateEventStartEndTimezoneShow={this.updateEventStartEndTimezoneShow}
					/>
					<EnableDelegation
						enableAppleIcalDelegation={enableAppleIcalDelegation}
						updateAppleIcalDelegationEnabled={this.updateAppleIcalDelegationEnabled}
					/>
					<EnableDeclinedEvents
						showDeclinedEvents={showDeclinedEvents}
						toggleDeclinedEvents={this.toggleDeclinedEvents}
					/>

					<div class={style.sectionTitle}>
						<Text id="settings.calendarAndReminders.eventReminderSettingsTitle" />
					</div>
					<ReminderEmail
						calendarReminderEmail={calendarReminderEmail}
						isInvalidReminderEmail={isInvalidReminderEmail}
						handleReminderEmailInput={this.handleReminderEmailInput}
					/>
					<ShowReminder
						zimbraPrefCalendarToasterEnabled={zimbraPrefCalendarToasterEnabled}
						onFieldChange={onFieldChange}
					/>
					<ShowDefaultRemindOptions
						zimbraPrefCalendarApptReminderWarningTime={zimbraPrefCalendarApptReminderWarningTime}
						handleReminderTimeChange={this.handleReminderTimeChange}
					/>
					<ShowReminderForPastDue
						zimbraPrefCalendarShowPastDueReminders={zimbraPrefCalendarShowPastDueReminders}
						onFieldChange={onFieldChange}
					/>
					<ZimletSlot
						name="calendar-and-reminders-video-call-preferences"
						onFieldChange={onFieldChange}
						value={value}
					/>
					<div class={style.sectionTitle}>
						<Text id="settings.calendarAndReminders.freeBusyPermission" />
					</div>
					<div>
						<Text id="settings.calendarAndReminders.freeBusySectionDescription" />
						<p>
							<Text id="settings.calendarAndReminders.freeBusySectionNote" />
						</p>
					</div>
					<FreeBusyViewPermission grantedRights={get(getRights, 'access')} />
				</div>
			</div>
		);
	}
}

const DateSelector = ({ time, onUpdateTime, label }) => {
	return (
		<div class={style.subsection}>
			<div class={cx(style.subsectionTitle, style.forSelect)}>
				<Text id={`settings.calendarAndReminders.${label}`} />
			</div>
			<div class={style.subsectionBody}>
				<TimePicker displayedTime={time} onUpdateTime={onUpdateTime} />
			</div>
		</div>
	);
};
