/********************************************************
Utility to detect browser tab inactivity
- isHidden = idle state of browser tab
- idleSince = idle duration in minutes
*********************************************************/

class PageVisibilityHandler {
	isHidden;
	idleSince = 0;
	counter;
	constructor() {
		let stateKey, eventKey;

		const visibility = {
			hidden: 'visibilitychange',
			webkitHidden: 'webkitvisibilitychange',
			mozHidden: 'mozvisibilitychange',
			msHidden: 'msvisibilitychange'
		};
		for (stateKey in visibility) {
			if (stateKey in document) {
				eventKey = visibility[stateKey];
				break;
			}
		}

		document.addEventListener(
			eventKey,
			() => {
				this.isHidden = document[stateKey];
				this.updateTime();
			},
			false
		);
		this.isHidden = document[stateKey];
		this.updateTime();
	}
	updateTime = () => {
		if (this.isHidden) {
			this.counter = setInterval(() => {
				this.idleSince++;
			}, 60000);
		} else {
			this.counter && clearInterval(this.counter);
			this.idleSince = 0;
		}
	};
}

export const PageVisibility = new PageVisibilityHandler();
