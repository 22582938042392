import { Fragment } from 'preact';
import PropTypes from 'prop-types';
import CollapsibleControl from '../collapsible-control';

import s from '../folder-list/style.less';

const FolderListGroup = ({ children, name, onToggle, collapsed }) => (
	<Fragment>
		<div class={s.groupToggle} onClick={onToggle}>
			<CollapsibleControl collapsed={collapsed} class={s.folderCollapsibleControl} />
			{name}
		</div>
		{!collapsed && <div class={s.groupChildren}>{children}</div>}
	</Fragment>
);

FolderListGroup.defaultProps = {
	name: 'Folders',
	collapsed: false
};

FolderListGroup.propTypes = {
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
	onToggle: PropTypes.func.isRequired,
	collapsed: PropTypes.bool,
	children: PropTypes.node
};

export default FolderListGroup;
