import { useCallback, useEffect } from 'preact/hooks';

export const AppBeforeUnload = ({ accountName }, { zimbraBatchClient }) => {
	const terminateSesion = useCallback(() => {
		zimbraBatchClient.endSessionBeaconRequest({ accountName });

		return null; // To prevent confirmation dialog for leaving app
	}, [zimbraBatchClient, accountName]);

	useEffect(() => {
		addEventListener('beforeunload', terminateSesion);

		return () => removeEventListener('beforeunload', terminateSesion);
	}, [terminateSesion]);

	return null;
};
