import { useCallback } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { showKeyboardShortcuts, hideKeyboardShortcuts } from '../../store/settings/actions';
import ModalDialog from '../modal-dialog';
import { Text } from 'preact-i18n';
import style from './style';
import { useCommandHandlers } from '../../keyboard-shortcuts/command-handlers';

const KeyboardShortcutsModal = (_, context) => {
	const visible = useSelector(state => state.settings.keyboardShortcutsVisible);
	const dispatch = useDispatch();

	const shortcuts = context.shortcutCommandHandler.getShortcuts();

	const showShortcutsModal = useCallback(() => {
		dispatch(showKeyboardShortcuts());
	}, [dispatch]);

	const hideShortcutsModal = useCallback(() => {
		dispatch(hideKeyboardShortcuts());
	}, [dispatch]);

	const handlers = [
		{
			context: 'all',
			command: 'SHOW_KEYBOARD_SHORTCUTS',
			handler: showShortcutsModal
		}
	];

	useCommandHandlers(handlers, context);

	return (
		visible && (
			<div>
				<ModalDialog
					title="shortcutsModal.title"
					onClose={hideShortcutsModal}
					buttons={false}
					cancelButton={false}
				>
					<div>
						{Object.keys(shortcuts).map(shortcutContext => (
							<div class={style.context}>
								<h2>
									<Text id={`shortcuts.context.${shortcutContext}`}>{shortcutContext}</Text>
								</h2>
								<Commands commands={shortcuts[shortcutContext]} />
							</div>
						))}
					</div>
				</ModalDialog>
			</div>
		)
	);
};

function Commands({ commands }) {
	return (
		<ul class={style.commandListWrapper}>
			{commands.map(([shortcut, displayName]) => (
				<li>
					<span class={style.shortcut}>{shortcut}</span>
					<span class={style.displayName}>
						<Text id={`shortcuts.${displayName}`}>{displayName}</Text>
					</span>
				</li>
			))}
		</ul>
	);
}

export default KeyboardShortcutsModal;
