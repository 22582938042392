import { parse, stringify } from 'flatted';
import { createTransform } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';

const transformCircular = createTransform(
	inboundState => stringify(inboundState),
	outboundState => parse(outboundState)
);

export const persistConfig = {
	key: 'root',
	storage: storage('reduxPersistor'),
	transforms: [transformCircular],
	whitelist: ['navigation', 'url']
};
