import { Component } from 'preact';
import get from 'lodash-es/get';
import { withProps } from 'recompose';
import { connect } from 'react-redux';
import isString from 'lodash-es/isString';
import cloneDeep from 'lodash-es/cloneDeep';
import { Text } from 'preact-i18n';
import array from '@zimbra/util/src/array';
import ContactPickerDialog from './dialog';
import withAccountInfo from '../../graphql-decorators/account-info';
import withPreference from '../../graphql-decorators/preferences/get-preferences';
import withGetHAB from '../../graphql-decorators/contact/get-hab-groups';
import withGetContactFolders from '../../graphql-decorators/contact/folders';
import { isLicenseActive } from '../../utils/license';
import withSearch from '../../graphql-decorators/search';
import {
	getMailboxMetadata,
	withSetMailboxMetaData
} from '../../graphql-decorators/mailbox-metadata';
import { addressToContact } from '../../utils/contacts';
import { USER_FOLDER_IDS, INTERNAL_GAL_PATH } from '../../constants';
import getContext from '../../lib/get-context';
import { isOfflineModeEnabled } from '../../utils/offline';
import { getFolderData } from '../../graphql/utils/graphql-optimistic';

@connect(
	state => ({
		isOffline: get(state, 'network.isOffline')
	}),
	null
)
@getContext(({ client }) => ({ client }))
@withAccountInfo(({ data: { accountInfo: account } }) => ({
	isEnterprise: isLicenseActive(account.license),
	zimbraFeatureGalEnabled: account.attrs.zimbraFeatureGalEnabled,
	zimbraFeatureSharingEnabled: account?.attrs?.zimbraFeatureSharingEnabled,
	habRootGroupId: get(account, 'habRoots.hab[0]._content')
}))
@withPreference(({ data: { getPreferences } }) => ({
	offlineModeEnabled: isOfflineModeEnabled(
		get(getPreferences, 'zimbraPrefWebClientOfflineBrowserKey')
	)
}))
@withGetContactFolders({
	props: ({
		data: { getFolder },
		ownProps: { isEnterprise, zimbraFeatureGalEnabled, zimbraFeatureSharingEnabled }
	}) => {
		const sharedContactFolders = getFolder?.folders?.[0]?.linkedFolders;
		let sharedFolders = [];
		if (zimbraFeatureSharingEnabled && sharedContactFolders) {
			sharedFolders = sharedContactFolders.filter(
				({ absFolderPath }) => !absFolderPath.includes(INTERNAL_GAL_PATH)
			);
		}

		let folders = getFolder?.folders?.[0]?.folders || [];
		if (isEnterprise && zimbraFeatureGalEnabled) {
			folders = cloneDeep(folders);
			!folders.find(fol => fol.id === 'galContacts') &&
				folders.push({ id: 'galContacts', name: <Text id="contacts.picker.galContacts" /> });
		}

		return {
			sharedFolders,
			folders: folders.filter(({ id }) => parseInt(id, 10) !== USER_FOLDER_IDS.TRASH)
		};
	}
})
@withGetHAB({
	skip: ({ habRootGroupId }) => !habRootGroupId,
	options: ({ habRootGroupId }) => ({
		variables: {
			habRootGroupId
		}
	})
})
@withSearch({
	options: () => ({
		variables: {
			query: 'in:"Contacts" #type:group',
			types: 'contact',
			sortBy: 'nameAsc',
			limit: 1000,
			needExp: true
		}
	}),
	props: ({ data: { search } }) => ({
		contactGroups: get(search, 'contacts') || []
	})
})
@getMailboxMetadata()
@withSetMailboxMetaData()
@withProps(({ folders, client, isOffline, offlineModeEnabled }) => ({
	...(isOffline &&
		offlineModeEnabled && {
			folders: folders.filter(f => {
				if (Object.values(USER_FOLDER_IDS).includes(Number(f.id))) return true;
				const folderContacts = getFolderData(
					client,
					'ROOT_QUERY.search',
					f.name,
					'NOT #type:group'
				);
				return folderContacts && folderContacts.contacts && folderContacts.contacts.length;
			})
		})
}))
export default class ContactPicker extends Component {
	state = {
		additionalContacts: {},
		folder: get(this.props, 'mailboxMetadata.zimbraPrefContactSourceFolderID')
	};

	setFolder = folder => {
		this.props.setMailboxMetadata({
			zimbraPrefContactSourceFolderID: folder
		});

		this.setState({ folder });
	};

	prepareContactObject = contacts =>
		array(contacts)
			.filter(addr => !isString(addr))
			.map(addressToContact);

	componentWillMount() {
		const { contacts, recipients: { to, cc, bcc } = {} } = this.props;
		const contactsTo = this.prepareContactObject(to || contacts);
		const contactsCc = this.prepareContactObject(cc);
		const contactsBcc = this.prepareContactObject(bcc);

		this.setState({
			additionalContacts: { to: contactsTo, cc: contactsCc, bcc: contactsBcc }
		});
	}

	render(
		{
			pending,
			folders,
			sharedFolders,
			contactGroups,
			into,
			habGroups,
			addressFieldType,
			zimbraFeatureGalEnabled,
			onClose,
			onSave
		},
		{ additionalContacts, folder }
	) {
		return (
			<ContactPickerDialog
				additionalContacts={additionalContacts}
				folder={folder}
				sharedFolders={sharedFolders}
				setFolder={this.setFolder}
				pending={pending}
				folders={folders}
				contactGroups={contactGroups}
				into={into}
				habGroups={habGroups}
				addressFieldType={addressFieldType}
				zimbraFeatureGalEnabled={zimbraFeatureGalEnabled}
				onClose={onClose}
				onSave={onSave}
			/>
		);
	}
}
