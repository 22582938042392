import moment from 'moment';
import s from './style.less';

export function GridlineLabelSpacer({ labelHeight }) {
	return <div class={s.tableGridlineLabelSpacer} style={{ height: labelHeight }} />;
}

export default function GridlineLabels({ cellWidth, labelHeight, labels }) {
	return (
		<div style={{ height: labelHeight, paddingLeft: cellWidth / 2 }} class={s.tableGridlineLabels}>
			{labels.map((label, idx) => {
				const hourLabelFormat =
					label === '12'
						? moment.locale() === 'hi_custom'
							? '[formatLTShort]'
							: '[formatHour]'
						: '[formatHourShort]';
				return (
					<div
						style={
							idx === labels.length - 1
								? { width: cellWidth / 2, textAlign: 'right', whiteSpace: 'nowrap' }
								: { width: cellWidth, whiteSpace: 'nowrap' }
						}
					>
						{moment(label, 'HH').format(hourLabelFormat)}
					</div>
				);
			})}
		</div>
	);
}
