const serverPrefFileName = 'server_prefs.json';
const serverPrefHashFileName = 'server-data.hash';
const userPrefFileName = 'user_prefs.json';
const doNotAskAgainMailtoPref = 'doNotAskAgainMailtoPref';

const appConstants = {
	serverPrefFileName,
	serverPrefHashFileName,
	userPrefFileName,
	doNotAskAgainMailtoPref
};

module.exports = appConstants;
