import s from './style.less';

export default function Gridlines({ labels, cellWidth, labelHeight, onClick }) {
	return (
		<div class={s.tableGridlines} onClick={onClick}>
			{labels.map((label, idx) => (
				<div style={{ left: (idx + 1) * cellWidth, top: labelHeight }} class={s.tableGridline} />
			))}
		</div>
	);
}
