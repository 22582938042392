import get from 'lodash-es/get';
import { normalize, entities, types as apiClientTypes } from '@zimbra/api-client';

import Search from '../graphql/queries/search/search.graphql';

import {
	itemsForKeySeparated,
	getCurrentUrlDetails,
	getFolderDetails,
	groupMessagesByConversation,
	readSearchQueries,
	EMPTY_EMAIL_ADDRESS,
	writeSearchQuery,
	readConversationFragment
} from './common';
import { ensureTextExcerpt } from '../lib/html-email';

import { isDraft, isSentByMe } from '../utils/mail-item';
import { DESCENDING } from '../constants';

const { Conversation } = entities;
const { MailFolderView } = apiClientTypes;

const normalizeConversation = normalize(Conversation);

const EMPTY_CONVERSATION = {
	changeDate: null,
	conversationId: null,
	date: null,
	emailAddresses: null,
	excerpt: null,
	flags: null,
	folderId: null,
	id: null,
	messagesMetaData: null,
	modifiedSequence: null,
	numMessages: null,
	revision: null,
	size: null,
	sortField: null,
	subject: null,
	tagNames: null,
	tags: null,
	__typename: 'Conversation'
};

export function processCreatedConversations({ store, client, cache, notifications, items }) {
	// reverse the items as they are in ascending order of time
	items = items.reverse();

	const currentUrlDetails = getCurrentUrlDetails(store);

	// extract the created messages from the conversation, as we would be updating the conversations
	// query with the new messages along with new conversations created
	const { createdItems: createdMessages, deletedItems: deletedConversations } =
		itemsForKeySeparated(notifications, 'm');

	// get the active folder's details, so that we can only process the notifications for the current active folder only
	const currentFolderDetails = getFolderDetails(client, currentUrlDetails.path);

	if (!currentFolderDetails) {
		console.error('Folder not found', currentUrlDetails.path);
		return;
	}
	// create a map of message where key is the conversation id and value is an array of messages for that conversation
	const createdMessagesByConversations = groupMessagesByConversation(createdMessages);

	// read the conversation type all search query from the cache
	const { queriesData, queriesVars } = readSearchQueries(client, currentUrlDetails.path, {
		types: MailFolderView.conversation
	});

	// return if the cache did not return the data for the query
	if (queriesData.length === 0) {
		return;
	}

	// update cache of each search query
	queriesData.forEach((queryData, index) => {
		const conversationsInQuery = get(queryData, 'search.conversations') || [];

		// create a map from the returned items from conversation query used to read the items
		// from the query, as the index based reads in the map (O(1)) are faster than array (O(n))
		const conversationsQueryMap = new Map();
		conversationsInQuery.forEach(conversation =>
			conversationsQueryMap.set(conversation.id, conversation)
		);

		const updatedItems = [];
		let conversationInPlaceUpdated = false;

		items.forEach(i => {
			// add sort field as the notifications data does not contain it
			i.sf = i.d.toString();

			let item = normalizeConversation(i);
			let matchedDeletedConversationId;

			// skip if the conversation is already in the query, this could happen when a refetch of search
			// brings the notification data for created conversations as well
			if (conversationsQueryMap.has(item.id)) {
				return;
			}

			// traverse through deleted items and see if any new conversation is created with the same subject
			// this happens when a new message is received in a conversation which has a negative id, as the conversation
			// with negative id is a virtual conversation and has only one message in it,
			// when a new message is added in that conversation, the id of the conversation is changed, hence we need to remove
			// the old conversation and add the new one which we receive in created conversations.
			const deletedConversationsIds = deletedConversations.id
				? deletedConversations.id.split(',')
				: [];

			if (deletedConversationsIds.length > 0) {
				deletedConversationsIds.forEach(deletedConversationId => {
					if (parseInt(deletedConversationId, 10) > 0) return;
					// get the deleted conversation from cache so that we can merge the messages metadata
					// as the new conversation received in the notification does not have the data about old message
					const cacheConv = conversationsQueryMap.get(deletedConversationId);

					// perform the logic only if we find such a conversation, based on subject
					if (cacheConv && cacheConv.subject === item.subject) {
						item = {
							...cacheConv,
							...item
						};

						matchedDeletedConversationId = deletedConversationId;
						// remove the deleted conversation from the cache
						// conversationsQueryMap.delete(deletedConversationId);
					}
				});
			}

			// add in the missing fields as null values
			item = {
				...EMPTY_CONVERSATION,
				...item
			};

			// check if there were any new messages created for this conversation
			// reversing as they need to be sorted based on date descending
			const newMessagesForConversation = (createdMessagesByConversations[item.id] || []).reverse();

			let conversationToBeAdded = false;
			let anyNonDraftMessageCreated = false;

			if (newMessagesForConversation && newMessagesForConversation.length > 0) {
				item.messagesMetaData = newMessagesForConversation
					.map(msg => {
						const { date, folderId, flags, id, autoSendTime = null } = msg;
						// check if any non-draft message is present, if yes, we need to add this conversation on top of the list
						// otherwise, it is a virtual conversation that is getting converted to actual after receiving another message
						if (matchedDeletedConversationId) {
							anyNonDraftMessageCreated = !isDraft(msg, MailFolderView.message);
						}

						return {
							id,
							date,
							folderId,
							flags,
							autoSendTime,
							__typename: 'MessageInfo'
						};
					})
					.concat(item.messagesMetaData || []);

				// check if any message is in the current folder, if yes, only then we need to add this conversation in the query
				conversationToBeAdded = item.messagesMetaData.find(
					msg => msg.folderId.toString() === currentFolderDetails.id
				);

				if (!conversationToBeAdded) return;

				item.excerpt =
					newMessagesForConversation[0].excerpt ||
					ensureTextExcerpt(
						newMessagesForConversation[0].html ||
							newMessagesForConversation[0].text ||
							newMessagesForConversation[0].subject
					);
			}

			// add typename
			if (item.emailAddresses && item.emailAddresses.length > 0) {
				item.emailAddresses = item.emailAddresses.map(addr => ({
					...EMPTY_EMAIL_ADDRESS,
					...addr
				}));
			}

			// check if we need to add the conversation on top of the list, or we need to update the conversation in-place
			if (matchedDeletedConversationId) {
				if (anyNonDraftMessageCreated) {
					updatedItems.push(item);
					conversationsQueryMap.delete(matchedDeletedConversationId);
				} else {
					conversationInPlaceUpdated = true;
					conversationsQueryMap.set(matchedDeletedConversationId, {
						...item
					});
				}
			} else {
				updatedItems.push(item);
				conversationsQueryMap.delete(matchedDeletedConversationId);
			}
		});

		// update only if there are any new items
		if (updatedItems.length > 0 || conversationInPlaceUpdated) {
			if (queryData) {
				const updatedDt = {
					...queryData,
					search: {
						...queryData.search,
						conversations:
							queriesVars[index]?.sortBy?.indexOf(DESCENDING) > -1
								? [...updatedItems, ...Array.from(conversationsQueryMap.values())]
								: [...Array.from(conversationsQueryMap.values()), ...updatedItems]
					}
				};

				cache.writeQuery({
					query: Search,
					variables: queriesVars[index],
					data: updatedDt
				});
			}
		}
	});
}

export function processModifiedConversations({ store, client, cache, notifications, items }) {
	// reverse the items as they are in ascending order of time
	items = items.reverse();

	const currentUrlDetails = getCurrentUrlDetails(store);

	// get the created messages from this notifications, conversation is considered to be modified
	// if it has any new message
	const { createdItems: createdMessages } = itemsForKeySeparated(notifications, 'm');

	// get the active folder's details, so that we can only process the notifications for the current active folder only
	const currentFolderDetails = getFolderDetails(client, currentUrlDetails.path);

	if (!currentFolderDetails) {
		console.error('Folder not found', currentUrlDetails.path);
		return;
	}

	// create a map of message where key is the conversation id and value is an array of messages for that conversation
	const createdMessagesMapByConversations = groupMessagesByConversation(createdMessages.reverse());

	// read the current folder's search query from the cache
	const { queriesData, queriesVars } = readSearchQueries(client, currentUrlDetails.path, {
		types: MailFolderView.conversation
	});

	// return if the cache did not return the data for the query
	if (queriesData.length === 0) return;

	queriesData.forEach((queryData, index) => {
		const conversationsInQuery = get(queryData, 'search.conversations') || [];

		const conversationQueryMap = new Map();

		conversationsInQuery.forEach(conversation =>
			conversationQueryMap.set(conversation.id, conversation)
		);

		const updatedItems = [];

		let queryToBeUpdated = false;

		items.forEach(i => {
			const item = normalizeConversation(i);

			// check if there were any new messages created for this conversation
			const newMessagesForConversation = createdMessagesMapByConversations[item.id] || [];

			// read the conversation from the search query
			let conversationInCache = conversationQueryMap.get(item.id);

			// this flag decides when to move the conversation on the top in the list,
			// if a draft is created and we received the notification for that, we don't need to move
			// the conversation on the top, for other messages received we should move it on top
			let conversationToBeMoved = false;

			// if conversation is not present in the search query, then check if there was any new message created for the conversation and belongs to the current folder,
			// if yes, then we need to read the conversation from cache to be added in current folder's query
			// we should return otherwise
			if (!conversationInCache) {
				if (
					newMessagesForConversation.find(
						m => m.folderId.toString() === currentFolderDetails.id.toString()
					)
				) {
					conversationToBeMoved = true;
					conversationInCache = readConversationFragment(client, item.id);
				} else {
					return;
				}
			}

			// if conversation is not in the cache, and we have received a message which needs to be part of the current folder
			// then create a conversation, which will patched with the data of a conversation we received in notification
			if (!conversationInCache && conversationToBeMoved) {
				const firstMessageOfConversation = newMessagesForConversation[0];
				conversationInCache = {
					...EMPTY_CONVERSATION,
					date: firstMessageOfConversation.date,
					subject: firstMessageOfConversation.subject,
					sortField: firstMessageOfConversation.date.toString()
				};
			}

			// update the search query if we have any non-skipped item
			queryToBeUpdated = true;

			const mergedConversation = {
				...conversationInCache,
				...item
			};

			if (newMessagesForConversation && newMessagesForConversation.length > 0) {
				if (mergedConversation.messagesMetaData) {
					mergedConversation.messagesMetaData = newMessagesForConversation
						.map(msg => {
							const { date, folderId, flags, id, autoSendTime = null } = msg;

							// skip the draft message as it is handled by saveDraft mutation
							if (isDraft(msg, MailFolderView.message)) return;

							// move the conversation to the top in the list if any of the messages is non-draft
							if (!conversationToBeMoved) {
								conversationToBeMoved = !isSentByMe(msg);
							}

							return {
								id,
								date,
								folderId,
								flags,
								autoSendTime,
								__typename: 'MessageInfo'
							};
						})
						.filter(Boolean)
						.concat(mergedConversation.messagesMetaData);
				}

				mergedConversation.excerpt = newMessagesForConversation[0].excerpt;
				mergedConversation.date = newMessagesForConversation[0].date;

				// add type names
				if (mergedConversation.emailAddresses && mergedConversation.emailAddresses.length > 0) {
					mergedConversation.emailAddresses = mergedConversation.emailAddresses.map(addr => ({
						...EMPTY_EMAIL_ADDRESS,
						...addr
					}));
				}
			}

			if (conversationToBeMoved) {
				// as the conversation has received a new message through notifications, moving it on top
				updatedItems.push(mergedConversation);
				conversationQueryMap.delete(item.id);
			} else {
				// updating the conversation in place otherwise
				conversationQueryMap.set(item.id, {
					...mergedConversation
				});
			}
		});

		if (queryToBeUpdated) {
			const updatedData = {
				...queryData,
				search: {
					...queryData.search,
					conversations:
						queriesVars[index]?.sortBy?.indexOf(DESCENDING) > -1
							? [...updatedItems, ...Array.from(conversationQueryMap.values())]
							: [...Array.from(conversationQueryMap.values()), ...updatedItems]
				}
			};

			writeSearchQuery(cache, updatedData, queriesVars[index]);
		}
	});
}
