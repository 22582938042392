import { Component } from 'preact';
import { Text, withText } from 'preact-i18n';
import get from 'lodash-es/get';
import uniq from 'lodash-es/uniq';
import xor from 'lodash-es/xor';
import isEmpty from 'lodash-es/isEmpty';
import debounce from 'lodash-es/debounce';
import { Button, AnimatedButton } from '@zimbra/blocks';
import PasswordInput from '../password-input';
import { graphql } from '@apollo/client/react/hoc';
import ChangePasswordMutation from '../../graphql/queries/change-password.graphql';
import withAccountInfo from '../../graphql-decorators/account-info';
import linkState from 'linkstate';
import style from './style';
import { setAuthTokenExpired } from '../../store/active-account/actions';
import { connect } from 'react-redux';
import { clearOfflineData } from '../../utils/offline';
import appConfiguration from '../../enhancers/app-config';
import { RuleItem } from './rule-item';
import { ENFORCE_HISTORY, COMMON_PASSWORD } from './constants';
import {
	getQualifiedRules,
	getPasswordRules,
	getBasicRules,
	getRemoteRules,
	getErrorCode
} from './utils';

@withAccountInfo(
	({ data: { accountInfo } }) => ({
		accountAttrs: accountInfo?.attrs || {}
	}),
	{
		options: {
			fetchPolicy: 'cache-only'
		}
	}
)
@connect(null, { setAuthTokenExpired })
@appConfiguration('useCsrf,useJwt')
@withText('loginScreen.resetPass.passwordNotMatching')
@graphql(ChangePasswordMutation, {
	props: ({ mutate }) => ({
		changePassword: variables => mutate({ variables, fetchPolicy: 'no-cache' })
	})
})
export default class ChangePasswordForm extends Component {
	state = {
		username: this.props.username,
		password: this.props.password,
		unqualifiedBasicRules: getBasicRules(this.props.accountAttrs),
		unqualifiedRemoteRules: getRemoteRules(this.props.accountAttrs)
	};

	allPasswordRules = getPasswordRules(this.props.accountAttrs);

	passwordBasicRules = getBasicRules(this.props.accountAttrs);
	passwordRemoteRules = getRemoteRules(this.props.accountAttrs);

	handleError = (err, isDryRun = false) => {
		const {
			accountAttrs: { zimbraPasswordBlockCommonEnabled, zimbraPasswordEnforceHistory },
			onError
		} = this.props;

		// Get error code and corresponding count (if returned)
		const { errorCode, errorMetadata } = getErrorCode(err);

		if (errorCode && onError) {
			if (isDryRun) {
				let isCommonPassword;

				if (get(errorMetadata, 'n') === 'zimbraPasswordBlockCommonEnabled') {
					if (zimbraPasswordBlockCommonEnabled) {
						isCommonPassword = COMMON_PASSWORD;
					} else {
						onError(
							<Text
								id={`faults.${errorCode}${errorMetadata ? `.${errorMetadata.n}` : ''}`}
								fields={{
									count: errorMetadata && errorMetadata._content
								}}
							/>
						);
					}
				}

				const isRecentlyUsed =
					zimbraPasswordEnforceHistory > 0 &&
					(isCommonPassword || errorCode === 'account.PASSWORD_RECENTLY_USED') &&
					ENFORCE_HISTORY;

				const unqualifiedRemoteRules = [isCommonPassword, isRecentlyUsed].filter(Boolean);

				this.setState({
					unqualifiedRemoteRules,
					checkingRule: false
				});
			} else {
				onError(
					<Text
						id={`faults.${errorCode}${errorMetadata ? `.${errorMetadata.n}` : ''}`}
						fields={{
							count: errorMetadata && errorMetadata._content
						}}
					/>
				);
			}
		} else {
			onError && onError(<Text id="error.genericInvalidRequest" />);
		}
	};

	handleCheckPassword = value => {
		const { username, password } = this.state;
		this.props
			.changePassword({ username, loginNewPassword: value, password, dryRun: true })
			.then(() =>
				this.setState({
					unqualifiedRemoteRules: [],
					checkingRule: false
				})
			)
			.catch(err => this.handleError(err, true));
	};

	debouncedCheckPassword = debounce(this.handleCheckPassword, 2000);

	shakeContinueButton = () => {
		this.setState({
			shake: true,
			loading: false
		});
	};

	afterContinueShake = () => {
		this.setState({
			shake: false
		});
	};

	getUnqualifiedBasicRules = value => {
		const { accountAttrs, onError } = this.props;

		const { qualifiedRules, invalidChars } = getQualifiedRules(
			accountAttrs,
			value,
			this.passwordBasicRules,
			this.state.username
		);

		if (onError) {
			if (!isEmpty(invalidChars)) {
				const uniqInvalidChars = uniq(invalidChars);
				onError(
					<Text
						id={'loginScreen.errors.invalidCharacters'}
						plural={uniqInvalidChars.length}
						fields={{
							chars: uniqInvalidChars.join(', ')
						}}
					/>
				);
			} else {
				onError(false);
			}
		}

		return xor(qualifiedRules, this.passwordBasicRules);
	};

	handlePasswordInput = e => {
		e.stopPropagation();

		const {
			accountAttrs: { zimbraPasswordEnforceHistory, zimbraPasswordBlockCommonEnabled }
		} = this.props;

		const { value } = e.target;
		const unqualifiedBasicRules = this.getUnqualifiedBasicRules(value);
		const newState = {
			loginNewPassword: value,
			unqualifiedBasicRules
		};

		if (zimbraPasswordEnforceHistory || zimbraPasswordBlockCommonEnabled) {
			if (isEmpty(unqualifiedBasicRules)) {
				newState.checkingRule = true;
				this.debouncedCheckPassword(value);
			} else {
				isEmpty(this.state.unqualifiedBasicRules) && this.debouncedCheckPassword.cancel();
				newState.checkingRule = false;
			}
		}
		this.setState({ ...newState });
	};

	submit = e => {
		this.setState({ loading: true });

		const {
				onError,
				onLogin,
				onClose,
				login,
				passwordNotMatching,
				useCsrf,
				useJwt,
				twoFactorEnabled
			} = this.props,
			{ username, loginNewPassword, loginConfirmNewPassword, password } = this.state;

		if (loginNewPassword !== loginConfirmNewPassword) {
			onError && onError(passwordNotMatching);

			this.shakeContinueButton();
			return;
		}

		onError && onError('');

		this.props
			.changePassword({ username, password, loginNewPassword })
			.then(() => {
				if (twoFactorEnabled) {
					return clearOfflineData(this.context).then(() => {
						setAuthTokenExpired(true);
						window.location.reload();
					});
				}
				return login({
					username,
					password: loginConfirmNewPassword,
					...(useJwt && { tokenType: 'JWT' }),
					csrfTokenSecured: useCsrf,
					...(typeof process.env.ELECTRON_ENV !== 'undefined' && { ignoreSameSite: true })
				});
			})
			.then(() => {
				if (twoFactorEnabled) {
					return;
				}
				onLogin();

				if (onClose) {
					onClose();
				}
			})
			.catch(err => {
				this.shakeContinueButton();

				this.handleError(err);
			});

		e.preventDefault();
	};

	componentWillReceiveProps({ username, password }) {
		if (username && this.props.username !== username) {
			this.setState({ username });
		}
		if (password && this.props.password !== password) {
			this.setState({ password });
		}
	}

	render(
		{ a11yId, onClose, ...props },
		{
			password,
			loginNewPassword,
			loginConfirmNewPassword,
			loading,
			shake,
			unqualifiedBasicRules,
			unqualifiedRemoteRules,
			checkingRule
		}
	) {
		const newPassInputId = 'loginNewPassword',
			confirmPassInputId = 'loginConfirmNewPassword',
			currentPassInputId = 'loginCurrentPassword';

		return (
			<form {...props} onSubmit={this.submit} action="javascript:" method="POST">
				<div>
					<label for={currentPassInputId}>
						<Text id="loginScreen.labels.currentPass" />
					</label>
					<PasswordInput
						autofocus
						autocomplete="new-password"
						name={currentPassInputId}
						value={password}
						onInput={linkState(this, 'password')}
						disabled={loading}
					/>
				</div>

				<label for={newPassInputId}>
					<Text id="loginScreen.labels.newPass" />
				</label>
				<PasswordInput
					autocomplete="new-password"
					name={newPassInputId}
					value={loginNewPassword}
					onInput={this.handlePasswordInput}
					disabled={loading}
				/>

				<ul class={style.rules}>
					{this.allPasswordRules.map(({ rule, value }) => (
						<RuleItem
							key={rule}
							rule={rule}
							value={value}
							check={
								unqualifiedBasicRules.indexOf(rule) === -1 &&
								unqualifiedRemoteRules.indexOf(rule) === -1
							}
							checkingRule={checkingRule}
						/>
					))}
				</ul>

				<label for={confirmPassInputId}>
					<Text id="loginScreen.labels.confirmPass" />
				</label>
				<PasswordInput
					autocomplete="new-password"
					name={confirmPassInputId}
					value={loginConfirmNewPassword}
					onInput={linkState(this, 'loginConfirmNewPassword')}
					disabled={loading}
				/>

				<div class={style.buttonsContainer}>
					<AnimatedButton
						class={style.continue}
						disabled={
							!password ||
							!loginNewPassword ||
							!isEmpty(unqualifiedBasicRules) ||
							!isEmpty(unqualifiedRemoteRules) ||
							!loginConfirmNewPassword ||
							loading
						}
						shake={shake}
						loading={loading}
						type="submit"
						styleType="primary"
						brand="primary"
						title={<Text id="buttons.continue" />}
						afterShake={this.afterContinueShake}
					/>

					{onClose && (
						<Button onClick={onClose}>
							<Text id="buttons.cancel" />
						</Button>
					)}
				</div>
			</form>
		);
	}
}
