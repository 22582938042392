import style from './style';
import cx from 'classnames';
import { compose, withPropsOnChange, branch } from 'recompose';
import { ChoiceInput } from '@zimbra/blocks';
import { Text, Localizer } from 'preact-i18n';
import ContactPickerContact from './contact';
import without from 'lodash-es/without';
import map from 'lodash-es/map';
import useLinkState from 'linkstate/hook';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'preact/hooks';
import withSearch from '../../graphql-decorators/search';
import withGetContacts from '../../graphql-decorators/contact/get-contacts';
import withGetDlMembers from '../../graphql-decorators/contact/get-dl-members';
import {
	getContactGroupFolderId,
	getHabGroupFolderId,
	getName,
	getSharedGroupFolderId
} from '../../utils/contacts';
import withSearchGal from '../../graphql-decorators/search/search-gal';
import {
	CONTACT_GROUP_PREFIX,
	HAB_GROUP_PREFIX,
	SHAREDFOLDER_GROUP_PREFIX
} from '../../constants/contacts';
import { setSelected } from '../../store/contact-chooser/actions';
import { getHabGroup } from '../../utils/folders';

function SourceContactList({ contacts, contactsById, folder, galContacts = [] }) {
	const dispatch = useDispatch();
	const [query, updateQuery] = useLinkState('');
	const selected = useSelector(state => state?.contactChooser?.selected || []);
	const matchesQuery = contact => {
		const ctx = [...getContactEmails(contact), getName(contact.attributes)].join(' ');
		return ctx.indexOf(query) !== -1;
	};
	const getContactEmail = contact => contact?.attributes?.email;

	const isEmailType = useCallback(key => /^(email)|(homeEmail)|(workEmail)[0-9]*$/.test(key), []);

	// Get all emails set for given contact
	const getContactEmails = useCallback(
		contact => {
			const emails = [];
			Object.keys(contact?.attributes).forEach(key => {
				if (isEmailType(key)) {
					emails.push(contact.attributes[key]);
				}
			});

			contact?.attributes?.other?.forEach(({ key, value }) => {
				if (isEmailType(key)) {
					emails.push(value);
				}
			});
			return emails;
		},
		[isEmailType]
	);
	const isSelected = ({ id: contactId }) => selected.indexOf(contactId) !== -1;
	contacts = [].concat(...contacts, ...galContacts).filter(getContactEmail);
	const contactsCount = contacts.length;
	const contactFolderSelectedCount = contacts.filter(isSelected).length;

	const selectAllContacts = useCallback(
		event => {
			let selectedResult = selected;
			if (event.target.checked && contactFolderSelectedCount === 0) {
				contacts.forEach(contact => {
					const { id } = contact;
					if (selectedResult.indexOf(id) === -1) {
						selectedResult = selectedResult.concat(id);
						contactsById[id] = contact;
					}
				});
			} else {
				const contactIds = map(contacts, 'id');
				selectedResult = without(selectedResult, ...contactIds);
			}

			dispatch(setSelected(selectedResult));
		},
		[selected, contactFolderSelectedCount, contactsById, contacts, dispatch]
	);

	const selectContact = useCallback(
		contact => {
			const { id } = contact;
			let selectedResult = selected;

			if (selectedResult.indexOf(id) === -1) {
				// `isGalContact` will help to avoid unnecessary searchGal call
				// for hover-card while selecting emails using contact-chooser.
				contact = {
					...contact,
					isGalContact: folder === 'galContacts'
				};
				selectedResult = selectedResult.concat(id);
				contactsById[id] = contact;
			} else {
				selectedResult = selectedResult.filter(c => c !== id);
			}

			dispatch(setSelected(selectedResult));
		},
		[selected, contactsById, dispatch, folder]
	);

	const renderContact = useCallback(
		contact => {
			const { id } = contact;
			return (
				<ContactPickerContact
					contact={contact}
					selected={selected.indexOf(id) !== -1}
					onClick={selectContact}
					hideAvatar
				/>
			);
		},
		[selected, selectContact]
	);

	if (query) {
		contacts = contacts.filter(matchesQuery);
	}

	return (
		<div class={style.contactList}>
			<div class={cx(style.contactListHeader)}>
				<ChoiceInput
					id="selectAllContacts"
					checked={!!contactsCount && contactFolderSelectedCount === contactsCount}
					onChange={selectAllContacts}
				/>
				<Localizer>
					<input
						class={style.query}
						placeholder={<Text id="contacts.picker.searchPlaceholder" />}
						value={query}
						onInput={updateQuery}
					/>
				</Localizer>
			</div>
			<div class={cx(style.content, !contactsCount && style.hideSelectAll)}>
				{contactsCount ? (
					contacts.map(renderContact)
				) : (
					<span class={style.noContacts}>
						<Text id="lists.empty" />
					</span>
				)}
			</div>
		</div>
	);
}
export default compose(
	withPropsOnChange(['folder'], ({ folder }) => ({
		isContactGroup: folder && folder.startsWith(CONTACT_GROUP_PREFIX),
		isHabDl: folder && folder.startsWith(HAB_GROUP_PREFIX),
		isSharedFolder: folder && folder.startsWith(SHAREDFOLDER_GROUP_PREFIX)
	})),
	branch(
		({ folder, zimbraFeatureGalEnabled }) => folder === 'galContacts' && zimbraFeatureGalEnabled,
		withSearchGal({
			options: () => ({
				variables: {
					type: 'account',
					limit: 1000,
					sortBy: 'nameAsc'
				}
			})
		}),
		branch(
			({ isHabDl }) => isHabDl,
			withGetDlMembers({
				skip: ({ habGroups }) => habGroups && habGroups.length <= 0,
				options: ({ folder, habGroups }) => ({
					variables: {
						dl: getHabGroup(habGroups, g => g.id && g.id === getHabGroupFolderId(folder))
							?.attributes?.mail,
						limit: 0,
						offset: 0
					}
				})
			}),
			branch(
				({ isContactGroup }) => isContactGroup,
				withGetContacts({
					skip: ({ isSharedFolder }) => isSharedFolder,
					options: ({ folder }) => ({
						variables: {
							id: getContactGroupFolderId(folder),
							derefGroupMember: true
						}
					})
				}),
				withSearch({
					options: ({ folder, isSharedFolder }) => {
						folder = isSharedFolder
							? getSharedGroupFolderId(folder)
							: getContactGroupFolderId(folder);
						return {
							variables: {
								query:
									(folder ? `${folder.match(/^\d+$/) ? 'inid' : 'in'}:"${folder}" ` : '') +
									'NOT #type:group',
								types: 'contact',
								sortBy: 'nameAsc',
								limit: 1000,
								needExp: true
							}
						};
					},
					props: ({ data: { search } }) => ({
						contacts: search?.contacts || []
					})
				})
			)
		)
	)
)(SourceContactList);
