import toPairs from 'lodash-es/toPairs';
import merge from 'lodash-es/merge';
import findIndex from 'lodash-es/findIndex';
import { OUTBOX, DRAFTS, SENT } from '../constants/folders';
import { FILTER_TEST_TYPE } from '../constants/filter-rules';

// Strips the leading  `/` from a folder path if it exists.
export const normalizePath = (absFolderPath = '') =>
	absFolderPath.charAt(0) === '/' ? absFolderPath.slice(1) : absFolderPath;

export const dropFolderFilter = ({ absFolderPath }) =>
	!absFolderPath.match(`/?(${OUTBOX}|${SENT}|${DRAFTS})$`);

// Finds the selected option to display that corresponds to the current state
// of the filterTest. If the filter test contains the option update, we know
// that this is the currently selected option.
export const findRulePredicateOption = (filterTest, rulePredicateOptions) => {
	// Some predicate keys are optional, set defaults if they do not exist.
	const filterTestWithDefaults = merge({}, { negative: false, part: 'all' }, filterTest);

	const predicateOptionKey = Object.keys(rulePredicateOptions).find(optionKey => {
		const option = rulePredicateOptions[optionKey];
		return toPairs(option.update).every(([key, value]) => filterTestWithDefaults[key] === value);
	});
	return rulePredicateOptions[predicateOptionKey];
};

export const getFilterConditionConfig = ({
	rulePredicateOptions,
	toccLabel,
	fromLabel,
	bodyLabel,
	subjectLabel,
	toLabel,
	ccLabel,
	headerNamedLabel
}) => {
	const LABEL_PREDICATE_OPTIONS = [
		rulePredicateOptions.MATCHES_EXACTLY,
		rulePredicateOptions.DOES_NOT_MATCH_EXACTLY,
		rulePredicateOptions.CONTAINS,
		rulePredicateOptions.DOES_NOT_CONTAIN,
		rulePredicateOptions.MATCHES_WILDCARD,
		rulePredicateOptions.DOES_NOT_MATCH_WILDCARD
	];
	return [
		{
			label: fromLabel,
			labelKey: 'From',
			getRulePath: filterTest => {
				const addressTestIdx = findIndex(
					filterTest[FILTER_TEST_TYPE.ADDRESS],
					({ header }) => header === 'from'
				);
				return [FILTER_TEST_TYPE.ADDRESS, String(addressTestIdx)];
			},
			predicateOptions: LABEL_PREDICATE_OPTIONS
		},
		{
			label: toccLabel,
			labelKey: 'To/CC',
			getRulePath: filterTest => {
				const addressTestIdx = findIndex(
					filterTest[FILTER_TEST_TYPE.ADDRESS],
					({ header }) => header === 'to,cc'
				);
				return [FILTER_TEST_TYPE.ADDRESS, String(addressTestIdx)];
			},
			predicateOptions: LABEL_PREDICATE_OPTIONS
		},
		{
			label: subjectLabel,
			labelKey: 'Subject',
			getRulePath: filterTest => {
				const headerTestIdx = findIndex(
					filterTest[FILTER_TEST_TYPE.HEADER],
					({ header }) => header === 'subject'
				);
				return [FILTER_TEST_TYPE.HEADER, String(headerTestIdx)];
			},
			predicateOptions: LABEL_PREDICATE_OPTIONS
		},
		{
			label: bodyLabel,
			labelKey: 'Body',
			getRulePath: () => [FILTER_TEST_TYPE.BODY, '0'],
			predicateOptions: [
				rulePredicateOptions.BODY_CONTAINS,
				rulePredicateOptions.BODY_DOES_NOT_CONTAIN
			]
		},
		toLabel && {
			label: toLabel,
			labelKey: 'To',
			predicateOptions: LABEL_PREDICATE_OPTIONS
		},
		ccLabel && {
			label: ccLabel,
			labelKey: 'CC',
			predicateOptions: LABEL_PREDICATE_OPTIONS
		},
		headerNamedLabel && {
			label: headerNamedLabel,
			labelKey: 'Header named',
			predicateOptions: [
				...LABEL_PREDICATE_OPTIONS,
				rulePredicateOptions.HEADER_EXIST,
				rulePredicateOptions.HEADER_DOES_NOT_EXIST
			]
		}
	];
};

export function getConditionCount({ address = [], header = [], body = [], headerExists = [] }) {
	return address.length + header.length + body.length + headerExists.length;
}

export function getActionCount({
	fileInto = [],
	discard = [],
	redirect = [],
	flag = [],
	keep = [],
	tag = []
}) {
	return (
		fileInto.length + discard.length + redirect.length + flag.length + keep.length + tag.length
	);
}
