import { Component } from 'preact';
import { graphql } from '@apollo/client/react/hoc';
import GenerateScratchCodes from '../../../graphql/mutations/two-factor-auth/generate-one-time-codes.graphql';
import GetOneTimeCodes from '../../../graphql/queries/two-factor-auth/one-time-codes.graphql';
import ModalDialog from '../../modal-dialog';
import style from '../style';
import get from 'lodash-es/get';
import cx from 'classnames';
import { Text } from 'preact-i18n';
import { callWith } from '../../../lib/util';
import NakedButton from '../../naked-button';
import { Icon } from '@zimbra/blocks';
import { writeText } from 'clipboard-polyfill/text';

@graphql(GenerateScratchCodes, {
	props: ({ mutate }) => ({
		generateScratchCodes: emailAddress =>
			mutate({
				variables: {
					username: emailAddress
				},
				refetchQueries: [
					{
						query: GetOneTimeCodes,
						variables: {
							username: emailAddress
						}
					}
				]
			})
	})
})
export default class OneTimeCodes extends Component {
	state = {
		oneTimeCodes: this.props.oneTimeCodes
	};

	copyToClipboard = oneTimeCodes => {
		writeText(oneTimeCodes.join(' ')).then(() => {
			this.setState({ copiedSecretKey: true });
		});
	};

	generateNewCodes = () => {
		this.setState({ loading: true });
		const { generateScratchCodes, emailAddress } = this.props;
		generateScratchCodes(emailAddress).then(
			({
				data: {
					generateScratchCodes: {
						scratchCodes: { scratchCode }
					}
				}
			}) => {
				this.setState({
					loading: false,
					oneTimeCodes: scratchCode.map(obj => get(obj, '_content')),
					copiedSecretKey: false
				});
			}
		);
	};

	render({ hideOneTimeCodes }, { loading, oneTimeCodes, copiedSecretKey }) {
		return (
			<ModalDialog
				title="settings.accounts.editAccount.twoFactorAuth.oneTimeCodes.title"
				onClose={hideOneTimeCodes}
				actionLabel="buttons.generateScratchCodes"
				onAction={this.generateNewCodes}
				cancelButton={false}
				disableOutsideClick
				pending={loading}
				contentClass={loading && style.hideContent}
				footerClass={loading && style.hideContent}
				class={cx(style.oneTimeCodeDialog, loading && style.loadingDialogContent)}
			>
				{oneTimeCodes.length > 0 && (
					<div class={style.copyToclipboard}>
						{/*  code to be implemented when printing the one time codes is to be considered
					<NakedButton
						class={style.printBtn}
						id="copy-btn"
						onClick={callWith(this.copyToClipboard, oneTimeCodesToDisplay)}
					>
						<Icon name="print" />
						<Text id="buttons.print" />
					</NakedButton> */}
						<NakedButton
							class={style.copyBtn}
							id="copy-btn"
							onClick={callWith(this.copyToClipboard, oneTimeCodes)}
						>
							<Icon name="copy-to-clipboard" />
							<Text id={`notificationModal.${copiedSecretKey ? 'copied' : 'copyToClipboard'}`} />
						</NakedButton>
					</div>
				)}
				<div class={style.oneTimeCodeContainer}>
					{oneTimeCodes.map(code => (
						<div class={style.oneTimeCode}>{code}</div>
					))}
				</div>
			</ModalDialog>
		);
	}
}
