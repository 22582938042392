import style from './style.less';
import { findRulePredicateOption } from '../../../utils/settings-filter';
import { ChoiceInput } from '@zimbra/blocks';
import CloseButton from '../../close-button';
import { callWith } from '@zimbra/util/src/call-with';
import cx from 'classnames';
import { Text } from 'preact-i18n';
import Select from '../../select';
import { FILTER_TEST_TYPE } from '../../../constants/filter-rules';

const headerMapping = {
	From: 'from',
	'To/CC': 'to,cc',
	Subject: 'subject',
	To: 'to',
	CC: 'cc',
	Body: 'body',
	'Header named': 'header'
};

export default function AdvancedFilterConditionInput({
	condition,
	filterConditionConfig,
	rulePredicateOptions,
	matchesScreenMd,
	onRuleMatchCaseChange,
	onConditionLabelChange,
	onRulePredicateChange,
	onRuleValueChange,
	onDeleteCondition,
	onHeaderNameChanged,
	headerInputPlaceholder
}) {
	const selectedPredicateOption = findRulePredicateOption(condition, rulePredicateOptions);
	/*
		when 'Header named' is selected then, there is a change in UI/UX structure.
		e.g.
		1. Addition of text field to specify custom header name and change in UI styles accordingly (isCustomNamedHeader)
		2. There are two options(exists, does not exist) added to predicate list and
		when one of these two predicate are selected we need to hide the value field (hasExistsPredicate)
	*/

	const isCustomNamedHeader = condition.selectedType === FILTER_TEST_TYPE.HEADER;
	const hasExistsPredicate = condition.key === FILTER_TEST_TYPE.HEADER_EXIST; // HEADER_EXIST represents both 'exists' and 'does not exist'.

	return (
		<div
			class={cx(
				style.subsection,
				style.advancedMainBody,
				isCustomNamedHeader && style.namedHeaderBody
			)}
		>
			<div class={style.advancedSubsection}>
				<div class={cx(style.subsectionTitle, style.inputSelectField)}>
					<Select
						value={condition.selectedType || condition.header || 'body'}
						fullWidth
						class={style.actionDropDown}
						onChange={callWith(onConditionLabelChange, condition, true)}
					>
						{filterConditionConfig.map(option => {
							return (
								<option value={headerMapping[option.labelKey]} key={option.labelKey}>
									{option.label}
								</option>
							);
						})}
					</Select>
				</div>
				{isCustomNamedHeader && (
					<div class={style.subsectionBody}>
						<input
							class={cx(
								style.textInput,
								style.textInputOfFilter,
								style.inputField,
								isCustomNamedHeader && style.namedHeaderInput
							)}
							type="text"
							placeholder={headerInputPlaceholder}
							value={condition.header}
							onChange={onHeaderNameChanged(condition)}
						/>
					</div>
				)}
			</div>
			<div class={cx(style.subsectionBody, style.advancedConditionSubsectionBody)}>
				<div
					class={cx(
						style.half,
						style.inline,
						style.inputSelectField,
						isCustomNamedHeader && style.namedHeaderSelect
					)}
				>
					<Select
						value={selectedPredicateOption.value}
						class={style.actionDropDown}
						onChange={callWith(onRulePredicateChange, condition, true)}
						fullWidth
					>
						{(
							filterConditionConfig.find(
								filter =>
									headerMapping[filter.labelKey] === (condition.selectedType || condition.header)
							) || filterConditionConfig.find(filter => filter.labelKey === 'Body')
						).predicateOptions.map(option => (
							<option value={option.value}>{option.label}</option>
						))}
					</Select>
				</div>
				{!hasExistsPredicate && (
					<input
						class={cx(
							style.textInput,
							style.textInputOfFilter,
							style.inputField,
							isCustomNamedHeader && style.namedHeaderInput
						)}
						type="text"
						value={condition.value}
						onChange={callWith(onRuleValueChange, condition, true)}
					/>
				)}
				{matchesScreenMd &&
					!isCustomNamedHeader &&
					renderMatchCaseLabel(condition, onRuleMatchCaseChange)}
			</div>
			<CloseButton
				class={style.advancedCloseButton}
				onClick={callWith(onDeleteCondition, { item: condition, isCondition: true })}
				inverse
			/>
			{!matchesScreenMd &&
				!isCustomNamedHeader &&
				renderMatchCaseLabel(condition, onRuleMatchCaseChange)}
		</div>
	);
}

function renderMatchCaseLabel(rule, onChange) {
	return (
		<label class={cx(style.checkbox, rule.caseSensitive && style.checked)}>
			<ChoiceInput
				containerClass={style.inputSelectField}
				onChange={callWith(onChange, rule)}
				checked={rule.caseSensitive}
			/>
			<Text id="settings.filterRuleModal.caseSensitive" />
		</label>
	);
}
