import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';

const initialState = {
	activeFolder: null,
	selectedFolder: null,
	fromDate: null,
	untilDate: null,
	onDate: null,
	dateTypeValue: 'anytime',
	subject: false,
	contains: false,
	toValue: [],
	fromValue: [],
	tagValues: [],
	statusValues: [],
	attachmentTypes: [],
	showMoreTypes: false
};

export default handleActions(
	{
		[actionCreators.setActiveSearch]: (state, action) => ({
			...state,
			activeFolder: action.payload
		}),
		[actionCreators.setAdvanceOptions]: (state, action) => ({
			...state,
			...action.payload
		}),
		[actionCreators.resetAdvanceOptions]: state => ({
			...state,
			...initialState
		})
	},
	initialState
);
