import * as preact from 'preact';
import * as preactHooks from 'preact/hooks';
import * as preactCompat from 'preact/compat';
import * as preactContextProvider from 'preact-context-provider';
import * as preacti18n from 'preact-i18n';
import * as preactRenderToString from 'preact-render-to-string';
import * as allBlocks from '@zimbra/blocks';
import * as apolloClient from '@apollo/client';
import * as apolloReactHoc from '@apollo/client/react/hoc';
import * as apolloReactComponents from '@apollo/client/react/components';
import moment from 'moment';

/* @zimbra/util */
import array from '@zimbra/util/src/array';
import { getDataTransferJSON, setDataTransferJSON } from '@zimbra/util/src/data-transfer-manager';
import { callWith } from '@zimbra/util/src/call-with';

import { errorMessage, faultCode } from '../../utils/errors';
import { getName } from '../../utils/contacts';
import { PageVisibility } from '../../lib/page-visibility';
import { openExternalBrowser } from '../../utils/open-external-browser';
import { getPlatformType } from '../../utils/get-platform-type';
import { htmlToText } from '../../lib/html-email';
import { route, Link } from 'preact-router';
import { Provider, connect, useSelector, useDispatch, batch } from 'react-redux';
import * as reduxActions from 'redux-actions';
import Match from 'preact-router/match';
import * as recompose from 'recompose';
import installer from 'preact-pwa-install';
import AppointmentsQuery from '../../graphql/queries/calendar/appointments.graphql';
import CalendarCreateMutation from '../../graphql/queries/calendar/calendar-create.graphql';
import GetDocumentShareURLQuery from '../../graphql/queries/document/get-document-share-url.graphql';
import GetMailboxMetadataQuery from '../../graphql/queries/mailbox-metadata.graphql';
import SetMailboxMetadataMutation from '../../graphql/mutations/set-mailbox-metadata.graphql';
import {
	withCalendars,
	withCreateAppointment,
	withCreateCalendar
} from '../../graphql-decorators/calendar';
import withMediaQuery from '../../enhancers/with-media-query';
import withTabManager from '../../enhancers/tab-manager';
import clientConfiguration from '../../enhancers/client-config';
import withTracking from '../../enhancers/tracking';
import { withIntlConsumer } from '../../enhancers/with-intl-consumer';
import * as breakpoints from '../../constants/breakpoints';
import { ATTENDEE_ROLE, PARTICIPATION_STATUS } from '../../constants/calendars';
import { PLATFORM_TYPES } from '../../constants/platform-types';
import withSearch from '../../graphql-decorators/search';
import { pruneEmpty } from '../../utils/filter';
import { withCreateContact } from '../../graphql-decorators/contact';
import withContactAction from '../../graphql-decorators/contact/contact-action';
import withAccountInfo from '../../graphql-decorators/account-info';
import withIdentities from '../../graphql-decorators/get-identities';
import withDataSources from '../../graphql-decorators/get-datasource';
import withGetSmimeCertificateInfo from '../../graphql-decorators/smime/smime-cert-info';
import withSaveSmimeCertificate from '../../graphql-decorators/smime/save-smime-cert';
import withPreference from '../../graphql-decorators/preferences/get-preferences';
import {
	withSetCustomMetaData,
	withGetCustomMetaData
} from '../../graphql-decorators/custom-metadata';
import withActionMutation from '../../graphql-decorators/with-action-mutation';
import withTags from '../../graphql-decorators/tags/get-tag';
import withTagCreate from '../../graphql-decorators/tags/tag-create';
import withTagAction from '../../graphql-decorators/tags/tag-action';

import MenuItem from '../../components/menu-item';
import Sidebar from '../../components/sidebar';
import MailSidebar from '../../components/mail-sidebar';
import FolderList from '../../components/folder-list';
import SmartList from '../../components/smart-list';
import ActionMenuMoveFolder from '../../components/action-menu-move-folder';
import ActionButton from '../../components/action-button';
import ConfirmModalDialog from '../../components/confirm-modal-dialog';
import ModalDialog from '../../components/modal-dialog';
import CaptureBeforeUnload from '../../components/capture-before-unload';
import ActionMenuGroup from '../../components/action-menu-group';
import ActionMenuItem from '../../components/action-menu-item';
import DraggableCard from '../../components/draggable-card';
import ContextMenu from '../../components/context-menu';
import ActionMenu, { DropDownWrapper } from '../../components/action-menu';
import ContactList from '../../components/contacts/list';
import ContactEditor from '../../components/contacts/editor';
import HelpButton from '../../components/help-button';
import AppointmentEdit from '../../components/calendar/appointment-edit';
import ModalDrawer from '../../components/modal-drawer';
import ModalDrawerToolbar from '../../components/modal-drawer-toolbar';
import BackArrow from '../../components/back-arrow';
import Select from '../../components/select';
import NakedButton from '../../components/naked-button';
import TextInput from '../../components/text-input';
import TinyMceComposer from '../../components/tinymce-composer';
import InfiniteScroll from '../../components/infinite-scroll';
import ColorPicker from './../../components/color-picker';
import FormGroup from '../../components/form-group';
import AlignedLabel from '../../components/aligned-form/label';
import withIntlWrapper from '../../enhancers/zimlet-intl-wrapper';
import AddMore from '../../components/settings/add-more';
import ResponsiveModal from '../../components/responsive-modal';
import { NestedActionMenuItem } from '../../components/nested-action-menu-item';
import PdfjsViewer from '../../components/pdfjs-viewer';
import ZimletSlot from '../../components/zimlet-slot';
import { supportedMimes } from '../../components/attachment-badge/attachment-badge';
import FolderListLight from '../../components/folder-list-light';
import { GenericMobileUIToolbar, GenericMobileUISidebar } from '../../components/default-home-page';
import { useFoldersQuery } from '../../hooks/folders';
import { useClientConfig } from '../../hooks/client-config';
import { useTracking } from '../../hooks/tracking';
import { useSendMessageMutation } from '../../hooks/send-message';
import { useAccountInfo } from '../../hooks/account-info';
import { usePreferences } from '../../hooks/preferences';
import { writeText } from 'clipboard-polyfill/text';
import AddressField from '../../components/address-field';
import {
	useDocumentAction,
	useBriefcaseFoldersQuery,
	useSaveDocumentMutation
} from '../../hooks/briefcase';
import SearchFragment from '../../graphql/fragments/search.graphql';
import SendShareNotificationMutation from '../../graphql/queries/shares/send-notification.graphql';
import { zimletEventEmitter } from './zimlet-event-emitter';
import { ZIMBRA_ZIMLET_EVENTS } from '../../constants/zimbra-zimlet-events';
import { useMediaQuery } from '../../enhancers/use-media-query';
import Avatar from '../../components/avatar';
import { ContactHoverCardWrapper } from '../../components/contact-card-wrapper';
import CertificateModal from '../../components/smime-and-encryption/certificate-modal';
import { SMIMEOperationDropDown } from '../../components/composer/smime-operation-dropdown';
import { smimeHandler } from '@zimbra/electron-app';
import { isServerSMIMEFeatureAvailable } from '../../utils/license';
import ErrorAlert from '../../components/error-alert';

export default {
	preact,
	'preact/hooks': preactHooks,
	'preact/compat': preactCompat,
	'preact-context-provider': preactContextProvider,
	'preact-i18n': preacti18n,
	'preact-render-to-string': preactRenderToString,
	'react-redux': { Provider, connect, useSelector, useDispatch, batch },
	'redux-actions': reduxActions,
	'@apollo/client': apolloClient,
	'@apollo/client/react/hoc': apolloReactHoc,
	'@apollo/client/react/components': apolloReactComponents,
	moment,
	'preact-router': {
		// Zimlets must share same router instance to route within the app
		route,
		Link
	},
	'preact-router/match': {
		Match
	},
	recompose,
	'preact-pwa-install': {
		default: installer
	},
	'clipboard-polyfill': {
		writeText
	},
	'@zimbra-client/blocks': allBlocks,
	'@zimbra-client/components': {
		MenuItem,
		Sidebar,
		MailSidebar,
		FolderList,
		SmartList,
		ActionMenuMoveFolder,
		ActionButton,
		CaptureBeforeUnload,
		ConfirmModalDialog,
		ModalDialog,
		ActionMenu,
		DropDownWrapper,
		ActionMenuItem,
		ActionMenuGroup,
		DraggableCard,
		ContextMenu,
		InfiniteScroll,
		ContactList,
		ContactEditor,
		HelpButton,
		AppointmentEdit,
		ColorPicker,
		ModalDrawer,
		ModalDrawerToolbar,
		BackArrow,
		Select,
		NakedButton,
		TextInput,
		AddMore,
		TinyMceComposer,
		FormGroup,
		AlignedLabel,
		ResponsiveModal,
		NestedActionMenuItem,
		PdfjsViewer,
		FolderListLight,
		GenericMobileUIToolbar,
		GenericMobileUISidebar,
		ZimletSlot,
		AddressField,
		Avatar,
		ContactHoverCardWrapper,
		CertificateModal,
		SMIMEOperationDropDown,
		ErrorAlert
	},
	'@zimbra-client/util': {
		array,
		getDataTransferJSON,
		setDataTransferJSON,
		breakpoints,
		callWith,
		pruneEmpty,
		PageVisibility,
		zimletEventEmitter,
		smimeHandler,
		isServerSMIMEFeatureAvailable,
		htmlToText
	},
	'@zimbra-client/browser': {
		openExternalBrowser
	},
	'@zimbra-client/platform': {
		getPlatformType,
		PLATFORM_TYPES
	},
	'@zimbra-client/util/contacts': {
		getName
	},
	'@zimbra-client/graphql': {
		AppointmentsQuery,
		GetDocumentShareURLQuery,
		GetMailboxMetadataQuery,
		SetMailboxMetadataMutation,
		SearchFragment,
		withCreateAppointment,
		withCalendars,
		withSearch,
		withAccountInfo,
		withPreference,
		withIdentities,
		withDataSources,
		withGetSmimeCertificateInfo,
		withSaveSmimeCertificate,
		withCreateContact,
		withContactAction,
		CalendarCreateMutation,
		withCreateCalendar,
		withSetCustomMetaData,
		withGetCustomMetaData,
		withActionMutation,
		withTags,
		withTagCreate,
		withTagAction,
		SendShareNotificationMutation
	},
	'@zimbra-client/enhancers': {
		withMediaQuery,
		withTabManager,
		withIntlWrapper,
		withZimletIntlWrapper: withIntlWrapper,
		clientConfiguration,
		withTracking,
		withIntlConsumer
	},
	'@zimbra-client/constants': {
		ATTENDEE_ROLE,
		PARTICIPATION_STATUS,
		supportedMimes,
		ZIMBRA_ZIMLET_EVENTS
	},
	'@zimbra-client/errors': {
		errorMessage,
		faultCode
	},
	'@zimbra-client/hooks': {
		useClientConfig,
		useTracking,
		useMediaQuery,
		usePreferences,
		useSendMessageMutation
	},
	'@zimbra-client/hooks/graphql': {
		useFoldersQuery,
		useAccountInfo,
		useDocumentAction,
		useBriefcaseFoldersQuery,
		useSaveDocumentMutation
	}
};
