import { createAction } from 'redux-actions';

export const addPollingRequest = createAction('polling add');

export const initPollingRequest = createAction('polling init');
export const startPollingRequest = createAction('polling start');
export const moveRequestToQueue = createAction('polling moveToQueue');

export const stopPollingRequest = createAction('polling stop');
export const markCompleted = createAction('polling complete');
