import { Button, Icon } from '@zimbra/blocks';
import { withText } from 'preact-i18n';
import { AriaSpeak } from '@zimbra/a11y';
import cx from 'classnames';
import style from './style';

const AttachmentViewerToolbar = ({
	attachment,
	page,
	maxPages,
	onDownload,
	onFullScreen,
	onClose,
	fullScreen,
	closeLabel,
	downloadLabel,
	fullScreenLabel
}) => {
	const filename = attachment && (attachment.filename || attachment.name);
	return (
		<div class={style.toolbar}>
			{filename && <AriaSpeak message={filename} />}
			{filename && (
				<div class={style.title}>
					<div class={style.filename}>
						<span title={filename}>{filename}</span>
					</div>
					{typeof page !== 'undefined' && (
						<div class={style.pages}>
							<span>{page}</span> / {maxPages}
						</div>
					)}
				</div>
			)}
			<div class={style.attachmentActions}>
				{onDownload && (
					<Button
						aria-label={downloadLabel}
						title={downloadLabel}
						class={style.actionButton}
						onClick={onDownload}
						iconOnly
					>
						<Icon name="download" />
					</Button>
				)}

				<Button
					aria-label={fullScreenLabel}
					title={fullScreenLabel}
					class={cx(style.hideSmDown, style.actionButton)}
					onClick={onFullScreen}
					iconOnly
				>
					<Icon name={`arrows-alt${fullScreen ? '-inverse' : ''}`} />
				</Button>

				{!fullScreen && onClose && (
					<Button
						aria-label={closeLabel}
						title={closeLabel}
						class={style.actionButton}
						onClick={onClose}
						iconOnly
					>
						<Icon name="arrow-right-thin" />
					</Button>
				)}
			</div>
		</div>
	);
};

export default withText({
	downloadLabel: 'buttons.download',
	fullScreenLabel: 'buttons.fullScreen',
	closeLabel: 'buttons.close'
})(AttachmentViewerToolbar);
