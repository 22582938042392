import { Button, Icon } from '@zimbra/blocks';
import { withText } from 'preact-i18n';
import style from './style';
import { useCallback } from 'preact/hooks';

const AttachmentViewerControls = ({
	onPreviousAttachment,
	onNextAttachment,
	previousLabel,
	zoomOutLabel,
	zoomInLabel,
	nextLabel,
	zoom,
	updateZoom
}) => {
	const zoomStep = 0.1;
	const maxZoom = 3;
	const minZoom = 0.5;

	const handleZoomIn = useCallback(() => {
		const nextZoom = Math.min(maxZoom, (zoom + zoomStep).toFixed(2));
		nextZoom !== zoom && updateZoom(nextZoom);
	}, [updateZoom, zoom]);

	const handleZoomOut = useCallback(() => {
		const nextZoom = Math.max(minZoom, (zoom - zoomStep).toFixed(2));
		nextZoom !== zoom && updateZoom(nextZoom);
	}, [updateZoom, zoom]);

	return (
		<div class={style.controls}>
			{onPreviousAttachment && (
				<Button aria-label={previousLabel} onClick={onPreviousAttachment}>
					<Icon size="sm" name="chevron-left" />
				</Button>
			)}
			<Button aria-label={zoomOutLabel} onClick={handleZoomOut}>
				<Icon size="sm" name="search-minus" />
			</Button>
			<Button aria-label={zoomInLabel} onClick={handleZoomIn}>
				<Icon size="sm" name="search-plus" />
			</Button>
			{onNextAttachment && (
				<Button aria-label={nextLabel} onClick={onNextAttachment}>
					<Icon size="sm" name="chevron-right" />
				</Button>
			)}
		</div>
	);
};
export default withText({
	previousLabel: 'buttons.previous',
	nextLabel: 'buttons.next',
	zoomOutLabel: 'buttons.zoomOut',
	zoomInLabel: 'buttons.zoomIn'
})(AttachmentViewerControls);
