import { Component } from 'preact';
import cx from 'classnames';
import { connect } from 'react-redux';
import ModalDialog from '../modal-dialog';
import ModalDrawer from '../modal-drawer';
import ModalDrawerToolbar from '../modal-drawer-toolbar';
import { Button, ChoiceInput, Spinner } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import withMediaQuery from '../../enhancers/with-media-query';
import { minWidth, screenMd } from '../../constants/breakpoints';
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '../../constants/locale';
import style from './style';
import { graphql } from '@apollo/client/react/hoc';
import GetAvailableLocales from '../../graphql/queries/get-locales.graphql';
import linkState from 'linkstate';
import withTracking from '../../enhancers/tracking';
@withTracking()
@graphql(GetAvailableLocales, {
	props: ({ data: { getAvailableLocales, loading } }) => ({
		availableLocales:
			getAvailableLocales &&
			getAvailableLocales.filter(locale => SUPPORTED_LOCALES.indexOf(locale.id) !== -1),
		isAvailableLocalesLoading: loading
	})
})
@withMediaQuery(minWidth(screenMd), 'matchesScreenMd')
@connect(state => ({
	locale: state.locale || DEFAULT_LOCALE
}))
export class LanguageModal extends Component {
	state = {
		prefValue: this.props.locale
	};

	handleCloseDrawer = () => {
		this.setState({ isDrawerMounted: false });
		this.props.onClose();
	};

	onSave = e => {
		e.stopPropagation();
		const { prefValue } = this.state;
		prefValue && this.props.onLanguageChange(prefValue);
	};

	componentDidMount() {
		this.props.tracker.setVirtualPageView({ path: '/language' });
	}

	render(
		{ onClose, matchesScreenMd, availableLocales, isAvailableLocalesLoading },
		{ isDrawerMounted, prefValue }
	) {
		const [ComponentClass, componentClassProps] = matchesScreenMd
			? [ModalDialog, { autofocusChildIndex: 1 }]
			: [
					ModalDrawer,
					{
						mounted: isDrawerMounted,
						toolbar: (
							<ModalDrawerToolbar
								buttons={[
									<Button
										styleType="primary"
										brand="primary"
										onClick={this.onSave}
										disabled={!availableLocales}
									>
										<Text id="buttons.save" />
									</Button>
								]}
								onClose={this.handleCloseDrawer}
							/>
						)
					}
			  ];
		const betaLanguages = [
			'ar',
			'bg',
			'ca',
			'cs',
			'da',
			'eu',
			'fr_CA',
			'hr',
			'hu',
			'lo',
			'ms',
			'no',
			'ro',
			'sl',
			'sv',
			'ta',
			'tr',
			'uk',
			'ru',
			'nl',
			'pt',
			'sk',
			'ko',
			'zh_HK',
			'zh_TW'
		];

		return (
			<ComponentClass
				{...componentClassProps}
				class={style.languageModal}
				contentClass={style.languageModalContent}
				title={`languageModal.title`}
				disablePrimary={!availableLocales}
				buttons={[
					<Button
						styleType="primary"
						brand="primary"
						onClick={this.onSave}
						disabled={!availableLocales}
					>
						<Text id="buttons.save" />
					</Button>
				]}
				onClickOutside={onClose}
			>
				<ul class={style.languageList}>
					{availableLocales && !isAvailableLocalesLoading ? (
						availableLocales.map(({ id, localName, name }) => {
							const isBeta = betaLanguages.includes(id);
							return (
								<li key={id}>
									<label class={style.label}>
										<ChoiceInput
											type="radio"
											value={id}
											onChange={linkState(this, 'prefValue', 'target.value')}
											checked={prefValue === id}
										/>
										<span class={cx(isBeta && style.labelTextBeta)}>{localName}</span>
										<span class={style.labelText}>{` - ${name}`}</span>
									</label>
								</li>
							);
						})
					) : (
						<li class={style.loaderWrapper}>
							<Spinner block class={style.spinner} />
						</li>
					)}
				</ul>
			</ComponentClass>
		);
	}
}
