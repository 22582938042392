import { graphql } from '@apollo/client/react/hoc';
import get from 'lodash-es/get';
import GetHAB from '../../graphql/queries/contacts/get-hab.graphql';

export default function withGetHAB(_config = {}) {
	return graphql(GetHAB, {
		..._config,
		props: ({ data: { getHAB } }) => ({ habGroups: get(getHAB, 'habGroups') || [] })
	});
}
