import moment from 'moment';

const LONG = 'ddd[,] MMM D LT',
	SHORT = 'LT';

/**
 * Format two dates with context around how to format each part
 * of the range.
 */
export function formatDayRange(start, end) {
	const s = moment(start);
	const e = moment(end);

	if (s.isSame(end, 'day')) {
		return `${s.format(LONG)} - ${e.format(SHORT)}`;
	}

	return `${s.format(LONG)} - ${e.format(LONG)}`;
}

/**
 * Function to enumerate and get array of days between two dates
 * same as https://date-fns.org/v2.8.1/docs/eachDayOfInterval
 * @param {Moment|Date|String} start start date of range
 * @param {Moment|Date|String} end end date of range
 * @return {Array<Date>} array of date objects
 */
export function enumerateDaysBetweenDates(start, end) {
	const now = moment(start),
		dates = [];

	while (now.isSameOrBefore(moment(end))) {
		dates.push(moment(now).toDate());
		now.add(1, 'days');
	}

	return dates;
}
