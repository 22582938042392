import renderToString from 'preact-render-to-string';
import get from 'lodash-es/get';
import moment from 'moment';
import { Fragment } from 'preact';
import { sanitize } from '../lib/html-viewer';
import momentTz from 'moment-timezone';

function getAddresses(type, addresses, template) {
	if (!addresses) {
		return;
	}

	const getHeaderValue = ({ name, displayName, address }) =>
		`${displayName || name || ''} <${address}>`.trim();

	if (Array.isArray(addresses)) {
		const headerValue = addresses.reduce(
			(value, header) => `${value}${value && '; '}${getHeaderValue(header)}`,
			''
		);

		return getHeader(type, headerValue.trim(), template);
	}
	return getHeader(type, getHeaderValue(addresses), template);
}

function getHeader(header, headerValue, template) {
	return (
		<Fragment>
			<b>{get(template, header, header)} </b>
			{headerValue}
			<br />
		</Fragment>
	);
}

export default function getEmailHeaders({ from, to, cc, date, subject, template, ...rest }) {
	const when = get(rest, 'invitations.0.components.0.start.0.date');
	const where = get(rest, 'invitations.0.components.0.location');
	const tzOfInv = get(rest, 'invitations.0.components.0.start.0.timezone');
	const tzAbbrForWhen = when && momentTz.tz(when, tzOfInv || momentTz.tz.guess()).zoneAbbr();
	const tzAbbrForDate = momentTz.tz(date, momentTz.tz.guess()).zoneAbbr();

	const headerString = renderToString(
		<Fragment>
			{from && getAddresses('from', from, template)}
			{to && getAddresses('to', to, template)}
			{cc && getAddresses('cc', cc, template)}
			{date && getHeader('date', `${moment(date).format('LLLL')} ${tzAbbrForDate}`, template)}
			{subject && getHeader('subject', subject, template)}
			{when && getHeader('when', `${moment(when).format('LLLL')} ${tzAbbrForWhen}`, template)}
			{where && getHeader('where', where, template)}
			<br />
		</Fragment>
	);

	return `<hr id="MESSAGE_DATA_MARKER" />${sanitize(headerString)}`;
}
