import { gql } from '@apollo/client';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { isValidEmail, removeFlag, addFlag } from '../../lib/util';
import { ensureTextExcerpt } from '../../lib/html-email';
import { withProps, compose } from 'recompose';
import { route } from 'preact-router';
import partition from 'lodash-es/partition';
import get from 'lodash-es/get';
import pickBy from 'lodash-es/pickBy';
import identity from 'lodash-es/identity';
import { isOfflineId } from '../../utils/offline';
import {
	optimisticAddToFolder,
	optimisticWriteMessage,
	findFolderInCache
} from '../../graphql/utils/graphql-optimistic';
import { cloneWithoutTypeName } from '../../graphql/utils/graphql';
import MessageQuery from '../../graphql/queries/message.graphql';
import SaveDraftMutation from '../../graphql/queries/save-draft-mutation.graphql';
import { OUTBOX, DRAFTS } from '../../constants/folders';
import { USER_FOLDER_IDS } from '../../constants';
import { types as apiClientTypes } from '@zimbra/api-client';
import { downloadMessage } from '../../graphql/queries/smime/download-message.graphql';
import { isSMIMEMessage } from '../../utils/mail-item';
import findIndex from 'lodash-es/findIndex';
import withPreference from '../preferences/get-preferences';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import { useSendMessageMutation } from '../../hooks/send-message';

const { MessageFlags } = apiClientTypes;

function collapseAddresses(list, type) {
	if (!list) {
		return [];
	}
	const out = [];
	for (let i = 0; i < list.length; i++) {
		const sender = list[i];

		// Skip invalid senders.
		if (isValidEmail(sender.email || sender.address)) {
			out.push({
				address: sender.email || sender.address,
				name: sender.name,
				type: type[0] // @TODO verify this is t/f/b/c
			});
		}
	}
	return out;
}

function formatAttachment({ __typename, ...attachment }, contentDisposition = 'attachment') {
	let { contentId, attachmentId, messageId, draftId } = attachment;
	messageId = messageId || draftId;

	if (attachmentId) {
		return {
			contentDisposition,
			contentId,
			attachments: { attachmentId }
		};
	}

	if (messageId) {
		return {
			contentId,
			attachments: {
				existingAttachments: [
					{
						messageId,
						part: attachment.part
					}
				]
			}
		};
	}

	return { ...attachment, contentDisposition };
}

// TODO: Move this into the api-client
export function convertMessageToZimbra(message, { requestReadReceipt, isOfflineDesktopApp } = {}) {
	let {
		id,
		folderId,
		inReplyTo,
		attachmentId,
		entityId,
		from,
		sender,
		to,
		cc,
		bcc,
		subject,
		flags,
		origId,
		draftId,
		replyType,
		attachments,
		autoSendTime,
		replyTo
	} = message;

	if (!isOfflineDesktopApp && (isOfflineId(draftId) || isOfflineId(id))) {
		// Never send offlineIds to the server
		id = draftId = null;
	}

	const fromData = collapseAddresses(from, 'from');
	const senderData = collapseAddresses(sender, 'sender');

	if (requestReadReceipt) {
		fromData.push({
			...fromData[0],
			type: 'n'
		});
	}

	const out = {
		id,
		origId,
		folderId,
		attachmentId,
		replyType,
		inReplyTo,
		flags,
		autoSendTime,
		draftId,
		entityId,
		subject,
		emailAddresses: [
			...collapseAddresses(to, 'to'),
			...collapseAddresses(cc, 'cc'),
			...collapseAddresses(bcc, 'bcc'),
			...collapseAddresses(replyTo, 'reply'),
			...senderData,
			...fromData
		]
	};

	// remove attachments, inlineAttachments and mimeParts when message is already uploaded (i.e. in case of sign/sign and encrypt message)
	if (attachmentId) {
		return out;
	}

	out.mimeParts = buildMimeParts(message);

	if (!isSMIMEMessage(message) && !isOfflineDesktopApp) {
		delete message.inlineAttachments;
	}

	if (attachments && attachments.length) {
		let documentIds, messageIds;

		const [documentType = [], attachmentType = []] = partition(attachments, {
			__typename: 'Document'
		});

		const [messageType = [], existingAttachmentType = []] = partition(attachmentType, {
			__typename: 'MessageInfo'
		});

		const [withAttachmentIds = [], withoutAttachmentId = []] = partition(
			existingAttachmentType,
			'attachmentId'
		);

		if (documentType && documentType.length > 0) {
			documentIds = documentType.map(documents => ({ id: documents?.id }));
		}

		if (messageType && messageType.length > 0) {
			messageIds = messageType.map(messsage => ({ id: messsage?.id }));
		}

		out.attachments = {
			existingAttachments: withoutAttachmentId.map(({ part, messageId }) => {
				const updatedMessageId = messageId || id;
				return {
					part,
					messageId: isOfflineId(updatedMessageId) ? origId : updatedMessageId
				};
			}),
			...(documentType.length && {
				documents: documentIds
			}),
			...(messageType.length && {
				messages: messageIds
			}),
			...(withAttachmentIds.length && {
				attachmentId: withAttachmentIds.map(attachment => attachment.attachmentId).join(',')
			})
		};
	}

	if (message.inlineAttachments && message.inlineAttachments.length) {
		out.inlineAttachments = [...cloneWithoutTypeName(message.inlineAttachments)];
	}
	return out;
}

export function withDelayedSendMessage({ name = 'sendMessageWithDelay' } = {}) {
	return compose(
		withSaveDraft({ name }),
		withProps(props => ({
			[name]: ({ message, delay, requestReadReceipt }) =>
				props[name]({
					message: {
						...message,
						autoSendTime: delay
					},
					requestReadReceipt
				})
		}))
	);
}

export function withSaveDraft({ name = 'saveDraft', context, updaterFn } = {}) {
	return compose(
		withPreference(
			({ data: { getPreferences } }) => ({
				zimbraPrefMessageViewHtmlPreferred: get(
					getPreferences,
					'zimbraPrefMessageViewHtmlPreferred'
				)
			}),
			{
				options: {
					fetchPolicy: 'cache-only'
				}
			}
		),
		connect(state => ({
			isOffline: get(state, 'network.isOffline')
		})),
		graphql(SaveDraftMutation, {
			props: ({ ownProps: { zimbraPrefMessageViewHtmlPreferred, isOffline }, mutate }) => ({
				[name]: ({
					message,
					base64Message,
					requestReadReceipt,
					smimeChangedToSignAndEnc,
					accountName,
					destFolderId
				}) => {
					if (!message.id && message.draftId) {
						message.id = message.draftId;
					}

					const isOfflineDesktopApp = typeof process.env.ELECTRON_ENV !== 'undefined' && isOffline;

					return mutate({
						context: {
							offlineQueueName: `saveDraft:${message.id}`,
							cancelQueues: `sendMessage:${message.id}`,
							local: isOfflineDesktopApp,
							...(typeof context === 'function' ? context(message) : context)
						},
						variables: {
							message: convertMessageToZimbra(message, { requestReadReceipt, isOfflineDesktopApp }),
							accountName
						},
						optimisticResponse: {
							saveDraft: {
								__typename: 'SaveDraftOptResponse',
								message: createOptimisticMessageInfo({
									...message,
									flags: MessageFlags.draft,
									date: Date.now()
								})
							}
						},
						update: (cache, { data }) => {
							if (isOfflineDesktopApp) return;
							const { saveDraft } = data;
							if (saveDraft.__typename !== 'SaveDraftOptResponse') {
								const id = get(saveDraft, 'message.0.id');
								const { pathname } = window.location;

								base64Message &&
									cache.writeQuery({
										query: downloadMessage,
										variables: {
											id
										},
										data: {
											downloadMessage: {
												id,
												content: base64Message,
												isSecure: true,
												__typename: 'SMimeMessage'
											}
										}
									});

								if (pathname && id && ~pathname.indexOf(message.id) && isOfflineId(message.id)) {
									// When an active draft is saved with an offline ID, reroute to the real ID.
									route(pathname.replace(message.id, id), true);
								} else {
									try {
										const messageMetaDataFragment = gql`
											fragment messageMetaDataFragment on Conversation {
												messagesMetaData {
													id
													date
													folderId
													flags
													autoSendTime
												}
											}
										`;

										const {
											id: convMessageId,
											conversationId,
											date,
											folderId,
											flags,
											autoSendTime
										} = get(saveDraft, 'message.0');

										const conversationMetaData = cache.readFragment({
											id: `Conversation:${conversationId}`,
											fragment: messageMetaDataFragment
										});

										if (conversationMetaData) {
											const { messagesMetaData } = conversationMetaData;

											const draftIndex = findIndex(
												messagesMetaData,
												item => item.id === convMessageId,
												0
											);

											const metaDataObj = {
												id: convMessageId,
												date,
												folderId,
												flags,
												autoSendTime,
												__typename: 'MessageInfo'
											};
											let newData = {};

											if (draftIndex === -1) {
												newData = update(conversationMetaData, {
													messagesMetaData: {
														$push: [metaDataObj]
													}
												});
											} else {
												newData = update(conversationMetaData, {
													messagesMetaData: {
														$apply: mData => {
															mData[draftIndex] = metaDataObj;
														}
													}
												});
											}

											cache.writeFragment({
												id: `Conversation:${conversationId}`,
												fragment: messageMetaDataFragment,
												data: newData
											});
										}
									} catch (e) {}
								}
							}
							const outbox = findFolderInCache(cache, folder => folder.name === OUTBOX);
							if (!outbox) {
								return;
							}

							const messageInfo = cloneDeep(saveDraft.message);

							if (!messageInfo.folderId) {
								messageInfo.folderId = USER_FOLDER_IDS.DRAFTS;
							}

							if (messageInfo.autoSendTime) {
								// TODO: If offline undo send becomes too complex, disable it entirely while offline.
								messageInfo.flags = removeFlag(
									addFlag(messageInfo.flags, MessageFlags.unread),
									MessageFlags.draft
								);
								messageInfo.folderId = outbox.id;
							}

							let cachedMessage;
							try {
								// Check if the message has already been written to cache
								cachedMessage = cache.readQuery({
									query: MessageQuery,
									variables: {
										id: messageInfo.id,
										html: zimbraPrefMessageViewHtmlPreferred,
										max: 250000
									}
								}).message;
							} catch (e) {}

							// If message is not in cache, add it. If a draft has `autoSendTime`,
							// consider it as sent and put it in the Outbox.
							if (!cachedMessage || destFolderId) {
								optimisticAddToFolder(
									cache,
									messageInfo.autoSendTime ? OUTBOX : destFolderId ? { id: destFolderId } : DRAFTS,
									messageInfo
								);
							}

							optimisticWriteMessage(
								cache,
								messageInfo,
								zimbraPrefMessageViewHtmlPreferred,
								smimeChangedToSignAndEnc
							);

							if (updaterFn?.after) {
								updaterFn.after(cache, { data: { saveDraft } });
							}
						}
					});
				}
			})
		})
	);
}

export function withUpdateDraftsById({ name = 'updateDraftsById' } = {}) {
	return compose(
		withSaveDraft(),
		withApollo,
		withProps(({ saveDraft, client, zimbraPrefMessageViewHtmlPreferred, account }) => ({
			[name]: (ids, destFolderId) =>
				Promise.all(
					ids.map(id => {
						try {
							const { message } = client.readQuery(
								{
									query: MessageQuery,
									variables: {
										id,
										html: zimbraPrefMessageViewHtmlPreferred,
										max: 250000
									}
								},
								true
							);

							const { __typename, ...newMessage } = message;

							return saveDraft({
								message: {
									...newMessage,
									folderId: destFolderId
								},
								destFolderId,
								accountName: account?.name
							});
						} catch (e) {
							console.error('Could not find message to save as draft:', id);
						}
					})
				)
		}))
	);
}

export function withClearAutoSend({ name = 'clearAutoSend' } = {}) {
	return graphql(SaveDraftMutation, {
		props: ({ mutate }) => ({
			[name]: message => {
				// When a message is saved, use it's draftId
				// When a draft is re-saved, it already has an ID
				if (message.draftId) {
					message.id = message.draftId;
				}

				return mutate({
					context: {
						offlineQueueName: `saveDraft:${message.id}`
					},
					variables: {
						message: convertMessageToZimbra({
							...message,
							autoSendTime: null
						})
					}
				});
			}
		})
	});
}

function mapToEmailAddress(type = null) {
	return ({ address = null, displayName = null, name = null, ...rest }) => ({
		__typename: 'EmailAddress',
		address,
		name,
		displayName: displayName || name,
		...rest,
		type
	});
}

function buildMimePartBody(contentType, content) {
	return {
		body: null,
		content,
		contentDisposition: null,
		contentId: null,
		contentType,
		filename: null,
		messageId: null,
		mimeParts: null,
		part: null,
		size: null,
		url: null,
		base64: null,
		truncated: null
	};
}

function buildMimeParts(message, addTypename) {
	const { html, text, inlineAttachments } = message;

	const mimeParts = [];

	// We need to provide html part of mime in a properly wrapped html and body tags
	// then only backend appends domain disclaimer.
	const wrappedHtml = `<html><body>${html}</body></html>`;

	const textPart = buildMimePartBody('text/plain', text || '');

	const htmlPart = buildMimePartBody('text/html', wrappedHtml || '');

	if (addTypename) {
		textPart.__typename = htmlPart.__typename = 'MimePart';
	}

	if (html && text) {
		// if we have HTML, put the `text` & `html` parts into an `alternative` part
		// with the text part first but the html part flagged as body.
		htmlPart.body = true;
		mimeParts.push({
			...buildMimePartBody('multipart/alternative', null),
			...(addTypename && { __typename: 'MimePart' }),
			mimeParts: [textPart, htmlPart]
		});
	} else {
		// otherwise there is no need for a `related` part, we just drop `text`
		// into `alternative` or `mixed` (depending if there are attachments).
		const part = html ? htmlPart : textPart;
		part.body = true;
		part.base64 = null;
		mimeParts.push({
			...part
		});
	}

	// If there are inline attachments; then create a `multipart/related` part
	// and append the `text/html` part and all inline attachment mimeParts to it.
	if (
		inlineAttachments &&
		inlineAttachments.length &&
		get(mimeParts, '0.mimeParts.1') === htmlPart
	) {
		mimeParts[0].mimeParts[1] = {
			...buildMimePartBody('multipart/related', null),
			...(addTypename && { __typename: 'MimePart' }),
			mimeParts: [
				htmlPart,
				...inlineAttachments.map(attachment => ({
					...buildMimePartBody(null, null),
					...pickBy(formatAttachment(attachment, 'inline'), identity),
					...(addTypename && { __typename: 'MimePart' })
				}))
			]
		};
	}
	return mimeParts;
}

// TODO: Can this be imported from @zimbra/api-client?
const emptyMessageInfo = {
	__typename: 'MessageInfo',
	id: null,
	origId: null,
	size: null,
	date: null,
	senderDate: null,
	folderId: null,
	subject: null,
	emailAddresses: null,
	excerpt: null,
	conversationId: null,
	flags: null,
	tags: null,
	tagNames: null,
	revision: null,
	changeDate: null,
	modifiedSequence: null,
	invitations: null,
	sortField: null,
	mimeParts: null,
	to: null,
	from: null,
	cc: null,
	bcc: null,
	sender: null,
	replyTo: null,
	html: null,
	text: null,
	attachments: null,
	inlineAttachments: null,
	share: null,
	replyType: null,
	attributes: null,
	decryptionErrorCode: null,
	isEncrypted: null,
	isSigned: null,
	certificate: null,
	autoSendTime: null,
	local: null,
	part: null
};

/**
 * Change all values of undefined in an object to null.
 * Needed to tell graphql that fields are intentionally blank.
 */
function fillUndefinedWithNull(obj) {
	return Object.keys(obj).reduce(
		(acc, key) => ({
			...acc,
			[key]: typeof obj[key] === 'undefined' ? null : obj[key]
		}),
		{}
	);
}

/**
 * Given a message from the client, return a message as the server would
 */
export function createOptimisticMessageInfo(message) {
	const tos = message.to ? message.to.map(mapToEmailAddress('t')) : [];
	const froms = message.from ? message.from.map(mapToEmailAddress('f')) : [];
	const senders = message.sender ? message.sender.map(mapToEmailAddress('s')) : [];
	const ccs = message.cc ? message.cc.map(mapToEmailAddress('c')) : [];
	const bccs = message.bcc ? message.bcc.map(mapToEmailAddress('b')) : [];
	const replyTos = message.replyTo ? message.replyTo.map(mapToEmailAddress('r')) : [];
	const excerpt = ensureTextExcerpt(message.html || message.text);
	const emailAddresses = [...tos, ...froms, ...ccs, ...bccs, ...senders, ...replyTos];

	let { flags, date = null } = message;
	flags = MessageFlags.sentByMe + (flags || '');

	if (message.attachments && message.attachments.length) {
		flags += MessageFlags.hasAttachment;
		message.attachments = message.attachments.map(attachment => ({
			...buildMimePartBody(null, null),
			...pickBy(attachment, identity),
			__typename: 'MimePart'
		}));
	}

	if (message.inlineAttachments && message.inlineAttachments.length) {
		if (flags.indexOf(MessageFlags.hasAttachment) === -1) {
			flags += MessageFlags.hasAttachment;
		}
		message.inlineAttachments = message.inlineAttachments.map(attachment => ({
			...buildMimePartBody(null, null),
			...pickBy(formatAttachment(attachment, 'inline'), identity),
			__typename: 'MimePart'
		}));
	}

	const messageToReturn = {
		__typename: 'MessageInfo',
		...emptyMessageInfo,
		...message,
		flags,
		mimeParts: buildMimeParts(message, true),
		excerpt,
		conversationId: `-${message.id}`,
		date,
		from: froms,
		sender: senders,
		to: tos,
		cc: ccs,
		bcc: bccs,
		replyTo: replyTos,
		emailAddresses
	};

	// remove attachments, inlineAttachments and mimeParts when message is alredy uploaded (i.e. in case of sign/sign and enctrypt message)
	if (message.attachmentId) {
		messageToReturn.attachments = null;
		messageToReturn.mimeParts = null;
		messageToReturn.inlineAttachments = null;
	}

	return fillUndefinedWithNull(messageToReturn);
}

export function withSendMessageMutation() {
	return BaseComponent => {
		return function Consumer(props) {
			const sendMessage = useSendMessageMutation(props);
			return <BaseComponent {...props} sendMessage={sendMessage} />;
		};
	};
}

export function withSendMessage() {
	return compose(
		withPreference(
			({ data: { getPreferences } }) => ({
				zimbraPrefMessageViewHtmlPreferred: get(
					getPreferences,
					'zimbraPrefMessageViewHtmlPreferred'
				)
			}),
			{
				options: {
					fetchPolicy: 'cache-only'
				}
			}
		),
		connect(state => ({
			isOffline: get(state, 'network.isOffline')
		})),
		withSendMessageMutation()
	);
}
