import { Component } from 'preact';
import style from './style';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { Button, ModalDialog } from '@zimbra/blocks';
import { callWith } from '@zimbra/util/src/call-with';
import { ROUTE_EVENT, ROUTE_DETAILS } from '.././calendar/constants';
import clientConfiguration from '../../enhancers/client-config';
import get from 'lodash-es/get';
import cx from 'classnames';
import moment from 'moment';
import { Text, withText } from 'preact-i18n';
import DismissCalendarItemMutation from '../../graphql/queries/calendar/dismiss-calendar-item.graphql';
import SnoozeCalendarItemMutation from '../../graphql/queries/calendar/snooze-calendar-item.graphql';
import { PARTICIPATION_STATUS } from '../../constants/calendars';
import { shallowEqual } from 'recompose';
import { withCalendars } from '../../graphql-decorators/calendar';
import { withReminders } from '../../graphql-decorators/reminders';
import { closeEventTabThunk } from '../../components/calendar/thunk';
import debounce from 'lodash-es/debounce';
import {
	getReminderValue,
	getReminderInterval,
	getNonSharedActiveCalendars
} from '../../utils/calendar';
import { SnoozeTimeButton } from '../snooze-time-button';
import { setFocusToZimbra } from '../../utils/notifications';

const SNOOZE_TIME = '1m';

function getSnoozeTime({ id, start }, snoozeMinutes) {
	const eventStartTime = moment(start);
	// Set time with exact minutes to simplify calculations and trigger reminder on exact minute i.e. date has 12:10:38 time, make it 12:10:00
	const reminderTime = moment().startOf('minute');

	let minutesToSnooze = getReminderValue(getReminderInterval(snoozeMinutes));

	// diffInMinutes is difference between current time and event start time
	const diffInMinutes = moment.duration(eventStartTime.diff(reminderTime)).asMinutes();

	// i.e. An event is starting in 5 mins with 'remind before 5 mins',
	// Then, If user snooze event before 3 mins, next reminder should be displayed in 2 mins, instead of (default) 5 mins.
	if (diffInMinutes > 0 && diffInMinutes < minutesToSnooze) {
		minutesToSnooze = diffInMinutes;
	}

	// Set next reminder minutes according to `snoozeTime`
	reminderTime.set({ minute: reminderTime.minutes() + minutesToSnooze });

	return {
		id,
		until: reminderTime.valueOf()
	};
}

@withText({
	nowText: 'notifications.dates.now',
	inText: 'notifications.dates.in',
	overdueText: 'notifications.dates.overdue'
})
@clientConfiguration({ calendarUrlSlug: 'routes.slugs.calendar' })
@connect(
	state => ({
		isOffline: get(state, 'network.isOffline')
	}),
	{ closeEventTabThunk }
)
@graphql(SnoozeCalendarItemMutation, {
	props: ({ mutate }) => ({
		snoozeCalendarItem: (appointment, task) => mutate({ variables: { appointment, task } })
	})
})
@graphql(DismissCalendarItemMutation, {
	props: ({ mutate }) => ({
		dismissCalendarItem: (appointment, task) => mutate({ variables: { appointment, task } })
	})
})
@withCalendars(undefined, {
	options: {
		fetchPolicy: 'cache-and-network'
	}
})
@withReminders(({ data: { getReminders, refetch: refetchAppointments } }) => ({
	appointments: get(getReminders, 'appointments') || [],
	refetchAppointments
}))
export default class Reminders extends Component {
	state = {
		appointments: []
	};

	pollIntervalTime = 60000;
	notifyObject = {};

	generateNotification = appts => {
		// Supports Notification API
		if (window.Notification && Notification.permission === 'granted') {
			appts &&
				appts.length > 0 &&
				appts.forEach(({ id, name, remainingTime }) => {
					if (remainingTime) {
						const timer = remainingTime;
						const options = {
							body: timer,
							requireInteraction: true,
							tag: id,
							icon: this.props.getAssetURLByClient('icon.png')
						};
						const notice = new Notification(name, options);

						notice.addEventListener('click', () =>
							setFocusToZimbra().then(() => {
								this.closeNotification(id);
							})
						);
						this.notifyObject[id] = notice;
					}
				});
		}
	};

	readableTimeslot(start, end) {
		const startValue = moment(start);
		const endValue = moment(end);
		return `${startValue.format('[formatDateTimeMedium]')} - ${endValue.format(
			startValue.isSame(endValue, 'date') ? 'LT' : '[formatDateTimeMedium]'
		)}`;
	}

	getReadableCountdownString = start => {
		const { overdueText, nowText, inText } = this.props;
		let timeDifference = start - Date.now();
		const startDate = moment(start);

		if (timeDifference < 0) {
			timeDifference = Math.abs(timeDifference);

			if (Math.floor(timeDifference / 1000 / 60) > 5) {
				return `${overdueText} ${startDate.fromNow(true)}`;
			}

			return nowText;
		}

		return `${inText} ${startDate.fromNow(true)}`;
	};

	addRemainingTime = appointments =>
		appointments.map(appt => {
			if (new Date(appt.alarm) <= Date.now()) {
				return {
					...appt,
					remainingTime: this.getReadableCountdownString(
						appt.isRecurring ? appt.alarmInstanceStart : appt.start
					)
				};
			}
			return appt;
		});

	updateAppointments(appointments = []) {
		if (appointments.length) {
			const updatedAppts = this.addRemainingTime(appointments);
			this.setState(state => ({
				appointments: updatedAppts,
				dismissed: updatedAppts.length > 0 ? false : state.dismissed
			}));
			this.props.zimbraPrefCalendarToasterEnabled && this.generateNotification(updatedAppts);
		}
	}

	updateDisplayedAppointment = id => {
		this.setState(oldState => {
			const apts = oldState.appointments.filter(({ id: apptId }) => apptId !== id);
			const displayingApts = this.getDisplayingAppointments(oldState.appointments).filter(
				({ id: apptId }) => apptId !== id
			);
			return { dismissed: !displayingApts.length, appointments: apts };
		});
	};

	getDisplayingAppointments = appointments =>
		appointments.filter(({ alarm }) => new Date(alarm) <= Date.now());

	/**
	 * Update appointment state and close modal dialogue.
	 *
	 * @param {Object|Array} appointments are Array of Object in case of SnoozeAll/DismissAll & Object in case of snooze/Dismiss
	 */
	updateAndCloseAppointments = appointments =>
		((Array.isArray(appointments) && appointments) || [appointments]).forEach(({ id }) => {
			this.updateDisplayedAppointment(id);
			this.closeNotification(id);
		});

	/**
	 * Snooze or dismiss appointments based on passed `snoozeOrDismissFn` function.
	 *
	 * @param {Object|Array} appointments are Array of Object in case of SnoozeAll/DismissAll & Object in case of snooze/Dismiss
	 * @param {Function} snoozeOrDismissFn receives mutation function for snooze or dismiss
	 */
	snoozeOrDismiss = (appointments, snoozeOrDismissFn) => {
		snoozeOrDismissFn(appointments).catch(err => {
			console.error(err);
		});
		this.updateAndCloseAppointments(appointments);
	};

	dismissEvent = id =>
		this.snoozeOrDismiss({ id, dismissedAt: Date.now() }, this.props.dismissCalendarItem);

	dismissAll = () => {
		const appointmentsToDisplay = this.getDisplayingAppointments(this.state.appointments);
		// Filter appointments which needs to be dismissed
		const appointments = appointmentsToDisplay.map(({ id }) => ({
			id,
			dismissedAt: Date.now()
		}));

		this.snoozeOrDismiss(appointments, this.props.dismissCalendarItem);
	};

	snoozeEvent = ({ id, start, snoozeTime = SNOOZE_TIME }) => {
		this.snoozeOrDismiss(getSnoozeTime({ id, start }, snoozeTime), this.props.snoozeCalendarItem);
	};

	snoozeAll = ({ snoozeTime = SNOOZE_TIME } = {}) => {
		const appointmentsToDisplay = this.getDisplayingAppointments(this.state.appointments);
		// Filter appointments which needs to be snoozed
		const appointments = appointmentsToDisplay.map(({ id, start }) =>
			getSnoozeTime({ id, start }, snoozeTime)
		);

		this.snoozeOrDismiss(appointments, this.props.snoozeCalendarItem);
	};

	closeNotification(apptId) {
		this.notifyObject[apptId] && this.notifyObject[apptId].close();
		delete this.notifyObject[apptId];
	}

	filterCurrentAppointments = appointments =>
		appointments
			.filter(
				({ participationStatus, alarm, alarmData }) =>
					participationStatus !== PARTICIPATION_STATUS.declined && alarm && alarmData
			)
			.map(appointment => ({
				name: appointment.name,
				alarm: new Date(appointment.alarmData[0].nextAlarm),
				alarmInstanceStart: new Date(appointment.alarmData[0].alarmInstStart),
				alarmInstanceEnd: new Date(appointment.alarmData[0].alarmInstStart + appointment.duration),
				start: new Date(appointment.instances[0].start),
				end: new Date(appointment.instances[0].start + appointment.duration),
				id: appointment.id,
				inviteId: appointment.inviteId,
				utcRecurrenceId: appointment.instances[0].utcRecurrenceId,
				isRecurring: appointment.isRecurring
			}));

	filterApppointmentsOnPreferences(nextProps) {
		let { appointments: newAppointments = [] } = nextProps;
		if (newAppointments.length !== 0) {
			if (!nextProps.zimbraPrefCalendarShowPastDueReminders) {
				newAppointments = newAppointments.filter(appt => {
					return new Date(appt.instances[0].start) >= Date.now();
				});
			}
			this.setState(
				{
					appointments: this.filterCurrentAppointments(newAppointments)
				},
				() => {
					this.updateAppointments(this.state.appointments);
				}
			);
		}
	}

	clickOnEventHeader = ({ url, title, id }) => {
		const { calendarUrlSlug, closeEventTabThunk: closeEvent, handleNewTab } = this.props;

		handleNewTab({
			url,
			title,
			type: 'event',
			id,
			onClose: closeEvent,
			view: calendarUrlSlug
		});

		this.snoozeAll();
	};

	/**
	 * Will check whether it should refetch new appointments or not
	 * based on it's loading status and active non-shared calendars
	 *
	 * @memberof Reminders
	 *
	 * NOTE: Shared calendar appointments reminders is not implemented yet
	 * so PREAPPS-4908 is not showing any reminders for shared calendar events.
	 *
	 * @TODO: Once PREAPPS-3335 implemented, `getNonSharedActiveCalendars` should be removed.
	 *
	 */
	shouldRefetchAppointments = () => {
		const { loading, calendars } = this.props;
		return !loading && getNonSharedActiveCalendars(calendars).length > 0;
	};

	/**
	 * Binds the interval every minute to search through the appointments for which the reminder'
	 * needs to be shown in the current minute
	 *
	 * @memberof Reminders
	 */
	updateRemindersToBeShown = () => {
		const currentTime = new Date();
		const nextMinuteDifference = 60 - currentTime.getSeconds();

		// This timeout will ensure that this logic will run at the beginning of every minute
		setTimeout(() => {
			// update the timers from the appointments
			this.updateAppointments(this.state.appointments);

			// Update the same every one minute afterwards
			this.pollInterval = setInterval(() => {
				this.updateAppointments(this.state.appointments);
			}, this.pollIntervalTime);
		}, nextMinuteDifference * 1000);
	};

	/**
	 * Binds the notifications event and listens for updates in the appointments.
	 * Refetches the appointments if there is any update from notifications.
	 *
	 * @param {Object} notifications the notifications received from the api
	 * @memberof Reminders
	 */
	handleAppointmentNotifications = notifications => {
		// act if appointment was created or modified
		const createdAppointments = get(notifications, 'created.appt');
		const modifiedAppointments = get(notifications, 'modified.appt');

		if (
			(createdAppointments && createdAppointments.length > 0) ||
			(modifiedAppointments && modifiedAppointments.length > 0)
		) {
			this.delayedRefetchAppointment();
		}
	};

	/**
	 * Binds the refresh event and listens for refresh notification.
	 * Refetches the appointments if there is any refresh notifications.
	 *
	 * @param {Object} notifications the notifications received from the api
	 * @memberof Reminders
	 */
	handleRefreshNotifications = () =>
		this.shouldRefetchAppointments() && this.props.refetchAppointments();

	/**
	 * Unbinds the notification and refresh events
	 *
	 * @memberof Reminders
	 */
	unbindNotificationEvents = () => {
		// Removed the notifications and refresh handler attached
		const notifier = get(this.context, 'zimbraBatchClient.notifier');
		if (notifier) {
			notifier.removeNotifyHandler(this.handleAppointmentNotifications);
			notifier.removeRefreshHandler(this.handleRefreshNotifications);
		}
	};

	/**
	 * Binds the notification and refresh events
	 *
	 * @memberof Reminders
	 */
	bindNotificationEvents = () => {
		// bind to notifications on appointment created and refresh, so that we can refetch them again
		const notifier = get(this.context, 'zimbraBatchClient.notifier');
		if (notifier) {
			notifier.addNotifyHandler(this.handleAppointmentNotifications);
			notifier.addRefreshHandler(this.handleRefreshNotifications);
		}
	};

	componentDidMount() {
		this.delayedRefetchAppointment = debounce(
			() => this.shouldRefetchAppointments() && this.props.refetchAppointments(),
			5000
		);
		this.bindNotificationEvents();
		this.updateRemindersToBeShown();
		// Browser supports notifications and the user hasn't yet given us permission
		if (window.Notification && Notification.permission === 'default') {
			Notification.requestPermission();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!shallowEqual(this.props.appointments, nextProps.appointments)) {
			this.filterApppointmentsOnPreferences(nextProps);
		}
	}

	componentWillUnmount() {
		// Remove our periodic event listeners
		this.delayedRefetchAppointment.cancel();
		this.unbindNotificationEvents();
		clearInterval(this.pollInterval);
	}

	render({ calendarUrlSlug, into }, { dismissed, appointments }) {
		if (appointments.length === 0) {
			return null;
		}
		const showAppointments = appointments.filter(({ alarm }) => new Date(alarm) <= Date.now());
		const hasAppts = showAppointments.length > 0;
		const multipleAppts = showAppointments.length > 1;

		return (
			hasAppts &&
			!dismissed && (
				<ModalDialog onClickOutside={this.dismissAll} class={style.modalDialog} into={into}>
					<div className={style.apptWindow}>
						<div className={style.apptHeading}>
							<h5>
								<Text id="calendar.notifications.appointmentReminder" />
							</h5>
						</div>
						<ul className={cx(style.apptContainer, { [style.multi]: multipleAppts })}>
							{showAppointments.map(
								({
									id,
									inviteId,
									isRecurring,
									utcRecurrenceId,
									name,
									start,
									end,
									remainingTime,
									alarmInstanceStart,
									alarmInstanceEnd
								}) => (
									<li key={id} className={style.appt}>
										<a
											onClick={callWith(
												this.clickOnEventHeader,
												{
													url: `/${calendarUrlSlug}/${ROUTE_EVENT}/${ROUTE_DETAILS}/${inviteId}?utcRecurrenceId=${utcRecurrenceId}&start=${start.valueOf()}&end=${end.valueOf()}`,
													title: name,
													id: inviteId
												},
												true
											)}
										>
											<h4 className={style.apptName}>{name}</h4>
										</a>
										<div className={style.apptMetaActionsContainer}>
											<div className={style.apptMeta}>
												<i>
													{isRecurring
														? this.readableTimeslot(alarmInstanceStart, alarmInstanceEnd)
														: this.readableTimeslot(start, end)}
												</i>
												{<b>{remainingTime}</b>}
											</div>
											<div className={style.apptActions}>
												<SnoozeTimeButton
													iconOnly
													monotone
													arrow={false}
													actionButtonClass={style.snoozeClockButton}
													handleSnooze={this.snoozeEvent}
													actionMenuClass={style.actionMenu}
													id={id}
													start={start}
													snoozeButtonClass={style.snoozeButton}
													snoozeClass={style.snoozeReminderButton}
													buttonLabel={'buttons.snooze'}
												/>
												<Button class={style.actionBtn} onClick={callWith(this.dismissEvent, id)}>
													<Text id="buttons.dismiss" />
												</Button>
											</div>
										</div>
									</li>
								)
							)}
						</ul>
						{multipleAppts && (
							<div className={style.allActions}>
								<SnoozeTimeButton
									iconOnly
									monotone
									arrow={false}
									handleSnooze={this.snoozeAll}
									actionButtonClass={style.snoozeClockButton}
									toggleClass={style.toggleButton}
									actionMenuClass={style.actionMenu}
									snoozeButtonClass={style.snoozeButton}
									snoozeClass={style.snoozeReminderButton}
									buttonLabel={'buttons.snoozeAll'}
								/>
								<Button class={style.actionBtn} onClick={this.dismissAll}>
									<Text id="buttons.dismissAll" />
								</Button>
							</div>
						)}
					</div>
				</ModalDialog>
			)
		);
	}
}
