import Toolbar from '../toolbar';
import { Icon } from '@zimbra/blocks';
import Sidebar from '../sidebar';
import { useMediaQuery } from '../../enhancers/use-media-query';
import { minWidth, screenMd } from '../../constants/breakpoints';
import ToolbarSidebarButton from '../toolbar/sidebar-button';
import ToolbarTitle from '../toolbar/title';
import { Fragment } from 'preact';
import style from './index.less';
import { useSelector } from 'react-redux';
import { useClientConfig } from '../../hooks/client-config';
import { Text } from 'preact-i18n';
import ZimletSlot from '../zimlet-slot';

export const GenericMobileUIToolbar = () => {
	const view = useSelector(state => state.url.view);
	return (
		<Toolbar>
			<ToolbarSidebarButton />
			<ToolbarTitle text={`appNavigation.${view}`} />
		</Toolbar>
	);
};

export const GenericMobileUISidebar = () => {
	const { desktopView } = useMediaQuery(minWidth(screenMd), 'desktopView');

	return (
		document.getElementById('sidebar_portal') && (
			<Sidebar into="#sidebar_portal" header={!desktopView} />
		)
	);
};

const SlugIcon = ({ view }) => {
	const { icon } = useSelector(state => state.navigation.verticals[view] || {});

	return (
		<div class={style.wrapper}>
			<Icon name={icon} class={style.icon} view={view} />
		</div>
	);
};

const LandingView = ({ view }) => {
	const { slugs } = useClientConfig('routes.slugs');

	return (
		<Fragment>
			{view !== slugs.integrations && (
				<div class={style.ladingContentWrapper}>
					<h1 class={style.heading}>
						<Text id={`appNavigation.${view}`} />
					</h1>
					<div class={style.columnWrapper}>
						<div class={style.columns}>
							<div>
								<p>
									<Text id={`verticalLandingPage.${view}.subText1`} />
								</p>
								<ul class={style.descriptionList}>
									{view === slugs.cloudapps ? (
										<li>
											<Text id={`verticalLandingPage.${view}.listItem1`} />
										</li>
									) : (
										<ZimletSlot props name={`${view}-landing-page-description`} />
									)}
								</ul>
							</div>
						</div>
						<div class={style.columns}>
							<div>
								<ZimletSlot props name={`${view}-landing-page-videos`} />
							</div>
						</div>
					</div>
				</div>
			)}
			{
				/* removing for chat as per Josh Landsman. Others will likely be removed later */
				view !== 'chatapps' && <SlugIcon view={view} />
			}
		</Fragment>
	);
};

export default function DefaultHomePage({ view }) {
	const { desktopView } = useMediaQuery(minWidth(screenMd), 'desktopView');

	return (
		<Fragment>
			<GenericMobileUISidebar />
			{!desktopView && <GenericMobileUIToolbar />}
			<LandingView view={view} />
		</Fragment>
	);
}
