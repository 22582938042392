export const TAG_ACTIONS = {
	COLOR: 'color',
	RENAME: 'rename',
	DELETE: 'delete',
	MARK_AS_READ: 'read'
};

export const TAG_TYPE = 'Tags';
export const TAG_TYPENAME = 'Tag';
export const TAG_OPERATION = 'tag';
