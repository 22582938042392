import { Icon } from '@zimbra/blocks';
import NakedButton from './../naked-button';
import cx from 'classnames';
import style from './style.less';

export default function RefreshIcon({
	buttonClass,
	isRefreshing,
	iconClass,
	onClick,
	size = 'sm'
}) {
	return (
		<NakedButton class={cx(style.refresh, isRefreshing && style.refreshing, buttonClass)}>
			<Icon
				name="refresh"
				size={size}
				class={cx(style.refreshIcon, iconClass)}
				{...(onClick && { onClick })}
			/>
		</NakedButton>
	);
}
