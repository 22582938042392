import { Fragment } from 'preact';
import { useState, useCallback, useEffect } from 'preact/hooks';
import cx from 'classnames';
import { Text } from 'preact-i18n';
import get from 'lodash-es/get';
import TextInput from '../../../text-input';
import { callWith, isValidEmail, getInputValue } from '../../../../lib/util';
import { Button } from '@zimbra/blocks';
import isEmpty from 'lodash-es/isEmpty';
import ErrorAlert from '../../../error-alert';
import ImportPST from '../import-pst';
import MobileConfig from '../mobile-config';
import Export from '../export';
import ImportModal from '../../../import-modal';
import style from '../../style';
import TwoFactorAuthSettings from '../two-factor-auth-settings';
import AlignedForm from '../../../aligned-form';
import FormGroup from '../../../form-group';
import Label from '../../../label';
import Select from '../../../select';
import ComboBox from '../../../combo-box';
import DomainAddressList from '../../domain-address-list';
import ExternalServerSettings from './components/external-server-settings';
import AccountAlignedLabel from './components/account-aligned-label';
import AccountChoiceInput from './components/account-choice-input';
import ConfirmDeleteAccountView from '../confirm-delete-account';
import { setAccountChangesTrackerData } from '../../../../store/settings/actions';
import { useDispatch } from 'react-redux';

const ADDIONAL_SETTINGS_PROPS = [
	'accountType',
	'username',
	'host',
	'password',
	'port',
	'useCustomFolder',
	'leaveOnServer'
];

function AcccountFormGroup({
	matchesScreenXs,
	triggerUpdateAccountSettings,
	value,
	updating,
	textId
}) {
	const labelWidth = matchesScreenXs ? '100%' : '175px';

	return (
		<FormGroup class={style.formGroup} rows={matchesScreenXs} compact={!matchesScreenXs}>
			<AccountAlignedLabel textId={textId} width={labelWidth} />
			<TextInput
				onChange={callWith(triggerUpdateAccountSettings, updating)()}
				value={value}
				class={style.optionalInput}
			/>
		</FormGroup>
	);
}

function returnSendAsSelected(sendOBOText, delegatedAccounts, emailAddress) {
	return delegatedAccounts.find(account => {
		if (account.indexOf(sendOBOText) > -1 && account.indexOf(emailAddress) > -1) {
			return account;
		}
	});
}

function isEditingOn(
	savedSelectedAccount,
	selectedAccount,
	lastProperty = false,
	lastValue = false
) {
	let arePropertiesChanged = false;

	for (let index = 0; index < ADDIONAL_SETTINGS_PROPS.length; index++) {
		const prop = ADDIONAL_SETTINGS_PROPS[index];

		if (lastProperty && prop === lastProperty && savedSelectedAccount[prop] === lastValue) {
			continue;
		}

		if (savedSelectedAccount[prop] !== selectedAccount[prop]) {
			arePropertiesChanged = true;
			break;
		}
	}

	return arePropertiesChanged;
}

function EditAccountView({
	selectedAccount: {
		name,
		fromDisplay,
		replyToAddress,
		replyToDisplay,
		replyToEnabled,
		isPrimaryAccount,
		emailAddress,
		emailAddressType,
		failingSince,
		lastError
	},
	sendOBOText,
	onFieldChange,
	mailLocalDeliveryDisabled,
	accountData,
	selectedAccount,
	clientName,
	matchesScreenXs,
	fromAccounts,
	externalAccounts,
	delegatedAccounts,
	sendTypes: { sendOBO, sendAs },
	updateAccountSettings,
	selectedAccountId,
	activeId,
	handleValidityOfTabs,
	savedSelectedAccount,
	testDataSource,
	error = null,
	mailForwardingAddress
}) {
	const dispatch = useDispatch();
	const [editingServerSettings, setEditingServerSettings] = useState(error === null ? null : true);
	const [emailForwardingAddresses, setEmailForwardingAddresses] = useState(mailForwardingAddress);
	const [showMailForwardingAddress, setShowMailForwardingAddress] = useState(
		mailForwardingAddress.length > 0
	);
	const [emailError, setEmailError] = useState(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const selectedFromAddress =
		emailAddress && emailAddressType === sendOBO
			? returnSendAsSelected(sendOBOText, delegatedAccounts, emailAddress)
			: emailAddress;

	// Show ImportPST component only for desktop and windows platform only
	const showPSTImportSection =
		typeof process.env.ELECTRON_ENV !== 'undefined' && process.platform !== 'darwin';

	const isCalendarEnabled = get(accountData, 'attrs.zimbraFeatureCalendarEnabled');
	const zimbraFeatureImapDataSourceEnabled = get(
		accountData,
		'attrs.zimbraFeatureImapDataSourceEnabled'
	);
	const zimbraFeaturePop3DataSourceEnabled = get(
		accountData,
		'attrs.zimbraFeaturePop3DataSourceEnabled'
	);
	const ownerEmails = [accountData.name, ...(accountData?.attrs?.zimbraMailAlias || [])];

	const triggerUpdateAccountSettings = useCallback(
		property =>
			(e, cb = () => {}) => {
				let value = getInputValue(e);

				if (property === 'name' || property === 'replyToAddress') {
					dispatch(
						setAccountChangesTrackerData({
							key: property,
							value: !!value
						})
					);
				}
				if (e.target && e.target.type === 'checkbox') {
					updateAccountSettings({ [property]: e.target.checked }, selectedAccountId, cb);
				} else if (property === 'emailAddress') {
					//need to set the correct type (sendAs or sendOBO)
					const emailArr = value.split(' ');
					value = emailArr[emailArr.length - 1];

					updateAccountSettings(
						{
							emailAddress: value,
							emailAddressType: emailArr.length > 1 ? sendOBO : sendAs
						},
						selectedAccountId,
						cb
					);
				} else {
					updateAccountSettings(
						{
							[property]: value
						},
						selectedAccountId,
						cb
					);
				}

				if (property === 'replyToAddress') {
					const isError = value ? !isValidEmail(value) : false;

					if (!isError && !editingServerSettings) {
						handleValidityOfTabs(activeId, false);
					} else {
						handleValidityOfTabs(activeId, true);
					}

					setEmailError(isError);
				} else if (ADDIONAL_SETTINGS_PROPS.includes(property)) {
					if (savedSelectedAccount[property] !== value) {
						if (!editingServerSettings) {
							handleValidityOfTabs(activeId, true);
							setEditingServerSettings(true);
						}
					} else {
						const isEditing = isEditingOn(savedSelectedAccount, selectedAccount, property, value);

						if (!isEditing) {
							setEditingServerSettings(false);

							if (!emailError) {
								handleValidityOfTabs(activeId, false);
							}
						}
					}
				}
			},
		[
			activeId,
			dispatch,
			editingServerSettings,
			emailError,
			handleValidityOfTabs,
			savedSelectedAccount,
			selectedAccount,
			selectedAccountId,
			sendAs,
			sendOBO,
			updateAccountSettings
		]
	);

	const handleMailForwardingActiveChange = useCallback(() => {
		// Clear the value when we are unchecking the field.
		if (showMailForwardingAddress) {
			onFieldChange('mailForwardingAddress')({
				target: { value: '' }
			}).then(() => {
				onFieldChange('mailLocalDeliveryDisabled')({
					target: { value: true }
				});
			});
			setShowMailForwardingAddress(false);
		} else {
			setShowMailForwardingAddress(true);

			onFieldChange('mailForwardingAddress')({
				target: { value: emailForwardingAddresses.join(',') }
			});
		}

		dispatch(
			setAccountChangesTrackerData({
				key: 'forward-address',
				value: !showMailForwardingAddress
			})
		);
	}, [
		dispatch,
		emailForwardingAddresses,
		onFieldChange,
		setShowMailForwardingAddress,
		showMailForwardingAddress
	]);

	// handleMailForwardingChange handles the addition of comma-separated list of email addresses to the existing list - 'this.props.mailForwardingAddress'
	const handleMailForwardingChange = useCallback(
		uniqueEmails => {
			onFieldChange('mailForwardingAddress')({
				target: {
					value: uniqueEmails && uniqueEmails.join(',')
				}
			}).then(() => {
				if (uniqueEmails.length === 0) {
					onFieldChange('mailLocalDeliveryDisabled')({
						target: { value: true }
					});
				}
				setEmailForwardingAddresses(uniqueEmails);
				setShowMailForwardingAddress(uniqueEmails.length > 0);

				dispatch(
					setAccountChangesTrackerData({
						key: 'forward-address',
						value: uniqueEmails.length > 0
					})
				);
			});
		},
		[dispatch, onFieldChange]
	);

	const handleRemoveMailbox = useCallback(() => {
		setShowConfirmDelete(!showConfirmDelete);
	}, [showConfirmDelete]);

	const handleLocalDeliveryDisabled = useCallback(() => {
		onFieldChange('mailLocalDeliveryDisabled')({
			target: { value: !mailLocalDeliveryDisabled }
		}).then(() => {
			dispatch(
				setAccountChangesTrackerData({
					key: 'keep-a-copy-here',
					value: !mailLocalDeliveryDisabled
				})
			);
		});
	}, [dispatch, mailLocalDeliveryDisabled, onFieldChange]);

	const addressList =
		(emailForwardingAddresses &&
			emailForwardingAddresses.length &&
			emailForwardingAddresses.sort()) ||
		[];

	useEffect(() => {
		if (editingServerSettings === null) {
			// added this to check and update validity of accounts tab
			// when user revisits the editing account should get executed only once
			// tried to mimic componentDidMount

			if (isEditingOn(savedSelectedAccount, selectedAccount)) {
				handleValidityOfTabs(activeId, true);
				setEditingServerSettings(true);
			}
		}
	}, [
		activeId,
		editingServerSettings,
		handleValidityOfTabs,
		savedSelectedAccount,
		selectedAccount
	]);

	useEffect(() => {
		if (
			(emailForwardingAddresses === undefined || isEmpty(emailForwardingAddresses)) &&
			Array.isArray(mailForwardingAddress) &&
			mailForwardingAddress.length > 0
		) {
			setEmailForwardingAddresses(mailForwardingAddress);
			setShowMailForwardingAddress(true);
		}
	}, [
		emailForwardingAddresses,
		mailForwardingAddress,
		setEmailForwardingAddresses,
		setShowMailForwardingAddress
	]);

	return (
		<div class={cx(style.accountSubsection, style.marginBottomLg)}>
			<div class={cx(style.subsectionBody, style.editSubsection)}>
				{failingSince && (
					<ErrorAlert>
						{get(lastError, '_content') || <Text id="settings.accounts.errors.existingIsFailing" />}
					</ErrorAlert>
				)}
				<div class={style.editAccountTitle}>
					{isPrimaryAccount ? fromAccounts[0] : selectedFromAddress}
					<div class={style.description}>
						<Text
							id={`settings.accounts.editAccount.${
								isPrimaryAccount ? 'primaryDescription' : 'externalDescription'
							}`}
						/>
					</div>
				</div>
				<AlignedForm class={style.alignedForm}>
					<AcccountFormGroup
						triggerUpdateAccountSettings={triggerUpdateAccountSettings}
						matchesScreenXs={matchesScreenXs}
						value={name}
						updating="name"
						textId="settings.accounts.editAccount.fromDisplayPrimary"
					/>
					{isPrimaryAccount && (
						<FormGroup rows class={style.sectionHeading}>
							<Label id="settings.accounts.addPersona.fromSettingsLabel" large />
							<div class={cx(style.description, style.fromDescription)}>
								<Text id="settings.accounts.editAccount.fromDescription" />
							</div>
						</FormGroup>
					)}
					<AcccountFormGroup
						triggerUpdateAccountSettings={triggerUpdateAccountSettings}
						matchesScreenXs={matchesScreenXs}
						value={fromDisplay}
						updating="fromDisplay"
						textId="settings.accounts.addPersona.fromNameLabel"
					/>

					{isPrimaryAccount && (
						<FormGroup class={style.formGroup} rows={matchesScreenXs} compact={!matchesScreenXs}>
							<AccountAlignedLabel textId="settings.accounts.editAccount.primaryFromAddressLabel" />
							<Select
								value={selectedFromAddress || fromAccounts[0]}
								onChange={callWith(triggerUpdateAccountSettings, 'emailAddress')()}
								fullWidth
							>
								{[...fromAccounts, ...delegatedAccounts].map(account => (
									<option value={account}>{account}</option>
								))}
							</Select>
						</FormGroup>
					)}
				</AlignedForm>
				<AlignedForm class={style.alignedForm}>
					<FormGroup rows={matchesScreenXs} compact={!matchesScreenXs} class={style.sectionHeading}>
						<Label id="settings.accounts.addPersona.replyToSettingsLabel" large />
					</FormGroup>
					<FormGroup class={style.formGroup} rows={matchesScreenXs} compact={!matchesScreenXs}>
						<div class={cx(style.subsection, style.expandedSubsection)}>
							{matchesScreenXs ? (
								<AccountChoiceInput
									onChange={callWith(triggerUpdateAccountSettings, 'replyToEnabled')()}
									checked={replyToEnabled}
									textId="settings.accounts.addPersona.replyToLabel"
								/>
							) : (
								<Fragment>
									<AccountAlignedLabel
										textId="settings.accounts.addPersona.replyToLabel"
										cssClass={cx(style.optionalLabel, style.multiline)}
									/>
									<AccountChoiceInput
										onChange={callWith(triggerUpdateAccountSettings, 'replyToEnabled')()}
										checked={replyToEnabled}
										textId="settings.accounts.addPersona.replyToToggle"
									/>
								</Fragment>
							)}
						</div>
					</FormGroup>
					<AcccountFormGroup
						triggerUpdateAccountSettings={triggerUpdateAccountSettings}
						matchesScreenXs={matchesScreenXs}
						value={replyToDisplay}
						updating="replyToDisplay"
						textId="settings.accounts.addPersona.replyToNameLabel"
					/>
					<FormGroup class={style.formGroup} rows={matchesScreenXs} compact={!matchesScreenXs}>
						<AccountAlignedLabel textId="settings.accounts.addPersona.replyToEmailLabel" />
						<ComboBox
							textInputVal={replyToAddress}
							textOnChange={callWith(triggerUpdateAccountSettings, 'replyToAddress')()}
							textPlaceholderId="settings.accounts.addPersona.replyToPlaceholder"
							type="email"
							selectOnChange={callWith(triggerUpdateAccountSettings, 'replyToAddress')()}
							textClass={style.optionalInput}
							invalid={emailError}
						>
							{[...fromAccounts, ...externalAccounts].map(account => (
								<option value={account}>{account}</option>
							))}
						</ComboBox>
					</FormGroup>
				</AlignedForm>
				{!isPrimaryAccount && (
					<form action="javascript:" onSubmit={testDataSource} class={style.externalServerSettings}>
						<FormGroup
							rows={matchesScreenXs}
							compact={!matchesScreenXs}
							class={style.sectionHeading}
						>
							<Label id="settings.accounts.editAccount.externalServerSettings" large />
						</FormGroup>
						{error && (
							<ErrorAlert>
								<Text id={error}>{error}</Text>
							</ErrorAlert>
						)}
						<ExternalServerSettings
							formData={selectedAccount}
							onFormDataChange={triggerUpdateAccountSettings}
							matchesScreenXs={matchesScreenXs}
							editingServerSettings={editingServerSettings}
							isEditing
							zimbraFeatureImapDataSourceEnabled={zimbraFeatureImapDataSourceEnabled}
							zimbraFeaturePop3DataSourceEnabled={zimbraFeaturePop3DataSourceEnabled}
						/>
					</form>
				)}
			</div>
			<TwoFactorAuthSettings
				accountData={accountData}
				emailAddress={emailAddress}
				selectedAccount={selectedAccount}
			/>
			{isPrimaryAccount && [
				<div class={style.primaryAccountSection}>
					<div class={style.sectionTitle}>
						<Text id="settings.accounts.mailForwardingTitle" />
					</div>
					<DomainAddressList
						onUpdateList={handleMailForwardingChange}
						list={addressList}
						errorId="settings.accounts.errors.forwardInvalidEmail"
						selfEmailPreventErrorId="settings.accounts.errors.selfEmailPrevent"
						placeholderId="settings.emailPlaceholder"
						ownerEmails={ownerEmails}
						emailOnly
						preventSelfEmail
					/>

					<label class={style.compactCheckboxSection}>
						<AccountChoiceInput
							onChange={handleMailForwardingActiveChange}
							checked={showMailForwardingAddress}
							disabled={emailForwardingAddresses.length === 0}
							textId="settings.accounts.mailForwardingLabel"
							noWrapper
						/>
					</label>
					<label class={style.compactCheckboxSection}>
						<AccountChoiceInput
							onChange={handleLocalDeliveryDisabled}
							checked={mailLocalDeliveryDisabled || emailForwardingAddresses.length === 0}
							disabled={!showMailForwardingAddress || emailForwardingAddresses.length === 0}
							textId="settings.accounts.mailStoreAndForward"
							noWrapper
						/>
					</label>
				</div>,
				<Export emailAddress={emailAddress} isCalendarEnabled={isCalendarEnabled} />,
				<ImportModal importLoadingState isCalendarEnabled={isCalendarEnabled} />,
				showPSTImportSection && <ImportPST />,
				<MobileConfig isCalendarEnabled={isCalendarEnabled} />
			]}
			{!isPrimaryAccount && (
				<Fragment>
					<Button styleType="primary" brand="danger" onClick={handleRemoveMailbox} alignLeft>
						<Text id="buttons.removeMailbox" />
					</Button>
					<div class={style.confirmationSpan}>
						<Text
							id="settings.accounts.editAccount.removeMailboxHelper"
							fields={{ domain: clientName }}
						/>
					</div>
				</Fragment>
			)}

			{showConfirmDelete && (
				<ConfirmDeleteAccountView
					id={selectedAccountId}
					folderId={get(selectedAccount, 'l')}
					accountName={get(selectedAccount, 'name')}
					accountType={get(selectedAccount, 'accountType')}
					accountData={accountData}
					onCancel={handleRemoveMailbox}
				/>
			)}
		</div>
	);
}

export default EditAccountView;
