import { graphql } from '@apollo/client/react/hoc';
import get from 'lodash-es/get';
import GetIdentities from '../../graphql/queries/identities/get-identities.graphql';
import { denormalizeIdentity } from './../../graphql-decorators/account-info/normalized-identities';
import CreateIdentityMutation from '../../graphql/mutations/create-identity.graphql';

export default function withCreateIdentity() {
	return graphql(CreateIdentityMutation, {
		props: ({ mutate }) => ({
			createIdentity: newIdentity =>
				mutate({
					variables: {
						name: newIdentity.name,
						attrs: denormalizeIdentity(newIdentity)
					},
					refetchQueries: [
						{
							query: GetIdentities
						}
					],
					optimisticResponse: {
						__typename: 'Mutation',
						createIdentity: []
					},
					update: proxy => {
						const data = proxy.readQuery({ query: GetIdentities });
						const identities = get(data, 'getIdentities');
						const identity = {
							id: newIdentity.name, //temp id since we get the id after the identity is created
							name: newIdentity.name,
							_attrs: {
								...denormalizeIdentity(newIdentity),
								zimbraPrefIdentityId: newIdentity.name,
								__typename: 'IdentityAttrs'
							},
							__typename: 'Identity'
						};

						//temp id since we get the id after the identity is created
						identities.identity.push(identity);
						proxy.writeQuery({ query: GetIdentities, data });
					}
				})
		})
	});
}
