import memoize from 'lodash-es/memoize';
import TagAction from '../../graphql/queries/tags/tag-actions.graphql';
import TagsQuery from '../../graphql/queries/tags/tags.graphql';
import { graphql } from '@apollo/client/react/hoc';
import { TAG_ACTIONS } from '../../constants/tags';

const getActionVariables = ({ id, name, color }, action) => {
	const variables = { id, op: action };
	switch (action) {
		case TAG_ACTIONS.COLOR:
			variables.color = color;
			break;
		case TAG_ACTIONS.RENAME:
			variables.name = name;
			break;
		case TAG_ACTIONS.MARK_AS_READ:
			variables.folderId = id;
			break;
	}
	return variables;
};

const memoizedTagAction = memoize(
	mutate => (tag, action) =>
		mutate({
			variables: {
				action: getActionVariables(tag, action)
			},
			optimisticResponse: {
				__typename: 'Mutation',
				tagAction: true
			},
			update: cache => {
				const { id } = tag;
				const data = cache.readQuery({ query: TagsQuery });
				let { getTag } = data;
				const index = getTag.findIndex(({ id: tagId }) => id === tagId);
				if (index === -1) {
					return;
				} else if (action === TAG_ACTIONS.DELETE) {
					getTag = [...getTag.slice(0, index), ...getTag.slice(index + 1)];
				} else {
					getTag = [
						...getTag.slice(0, index),
						{ ...tag, folderId: null },
						...getTag.slice(index + 1)
					];
				}
				cache.writeQuery({
					query: TagsQuery,
					data: {
						...data,
						getTag
					}
				});
			}
		})
);

export default function withTagAction() {
	return graphql(TagAction, {
		props: ({ mutate }) => ({
			tagAction: memoizedTagAction(mutate)
		})
	});
}
