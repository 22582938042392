import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'preact/hooks';
import { Text } from 'preact-i18n';
import cx from 'classnames';
import moment from 'moment';
import isString from 'lodash-es/isString';

import s from '../style.less';
import { Button } from '@zimbra/blocks';
import FormGroup from '../../../form-group';
import AvailabilityIndicator from '../../../availability-indicator';
import { CALENDAR_USER_TYPE } from '../../../../constants/calendars';

import { getParsedAppointmentData } from '../../../../store/calendar/selectors';
import { setEvent } from '../../../../store/calendar/actions';

export const AttendeesAvailabilityIndicator = ({
	tabId,
	isForwardInvite,
	handleForwardAttendeesChange,
	handleAttendeesChange,
	handleLocationChange,
	locations,
	matchesScreenXs,
	attendees,
	allAttendees,
	forwardAttendees,
	identitiesInfo,
	isProposeTime
}) => {
	const dispatch = useDispatch();

	const { event, availabilityVisible } = useSelector(state => ({
		event: getParsedAppointmentData(state, 'event', tabId),
		availabilityVisible: getParsedAppointmentData(state, 'availabilityVisible', tabId)
	}));

	const schedulerCandidates = allAttendees.concat(
		locations.filter(
			loc =>
				loc.zimbraCalResType === 'Location' || loc.calendarUserType === CALENDAR_USER_TYPE.resource
		)
	);

	const showAvailabilityButtonVisible =
		!availabilityVisible && schedulerCandidates.some(a => !isString(a));

	const handleStartChange = useCallback(
		(date, isValid = true) => {
			const stateToUpdate = {
				isFormDirty: true,
				isStartDateValid: isValid
			};

			if (isValid) {
				const diff = moment(event.start).diff(date);
				stateToUpdate.event = {
					...event,
					start: date,
					end: moment(event.end).subtract(diff).toDate().setSeconds(0)
				};
			}

			dispatch(
				setEvent({
					tabId,
					eventData: stateToUpdate
				})
			);
		},
		[dispatch, event, tabId]
	);

	const handleToggleAvailabilty = useCallback(() => {
		dispatch(
			setEvent({
				tabId,
				eventData: {
					availabilityVisible: !availabilityVisible
				}
			})
		);
	}, [availabilityVisible, dispatch, tabId]);

	return (
		<FormGroup
			class={(availabilityVisible || showAvailabilityButtonVisible) && s.availabilityIndicatorGroup}
		>
			{!matchesScreenXs &&
				(availabilityVisible ? (
					<AvailabilityIndicator
						event={event}
						attendees={schedulerCandidates}
						onAttendeesChange={
							isForwardInvite ? handleForwardAttendeesChange : handleAttendeesChange
						}
						onLocationsChange={handleLocationChange}
						onStartChange={handleStartChange}
						onClose={handleToggleAvailabilty}
						isForwardInvite={isForwardInvite}
						prevAttendees={attendees}
						forwardAttendees={forwardAttendees}
						identitiesInfo={identitiesInfo}
						isProposeTime={isProposeTime}
					/>
				) : (
					showAvailabilityButtonVisible && (
						<Button
							class={cx(s.fieldOffset, s.availabilityButton)}
							onClick={handleToggleAvailabilty}
						>
							<Text id="calendar.editModal.buttons.showAvailability" />
						</Button>
					)
				))}
		</FormGroup>
	);
};
