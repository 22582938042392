import GrantedRights from '../../vhtml-components/calendar-delegation/granted-rights';
import { GRANTED, REVOKED } from '../../constants/rights';
import SendMessageMutation from '../../graphql/queries/send-message-mutation.graphql';
import withIdentities from '../get-identities';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { convertMessageToZimbra } from '../../graphql-decorators/send-message';
import get from 'lodash-es/get';
import { withIntlConsumer } from '../../enhancers/with-intl-consumer';

const mapPropsIntl = props => ({
	template: props?.intl?.dictionary?.vhtmlComponents?.delegation
});

export default function withSendRightsEmailNotifications() {
	return compose(
		withIntlConsumer(mapPropsIntl),
		withIdentities(result => ({
			zimbraPrefFromAddress: get(
				result,
				'data.getIdentities.identity.0._attrs.zimbraPrefFromAddress'
			)
		})),
		graphql(SendMessageMutation, {
			props: ({ mutate, ownProps }) => {
				function sendRightsEmailNotificationFactory(grantedOrRevoked) {
					const owner = get(ownProps, 'zimbraPrefFromAddress');
					const subject = get(ownProps, 'template.subject', '')
						.replace(/{{owner}}/, owner)
						.replace(/{{grantedOrRevoked}}/, grantedOrRevoked);

					return ({ grantee, rights }) =>
						mutate({
							variables: {
								message: convertMessageToZimbra({
									subject,
									from: [
										{
											address: owner
										}
									],
									to: [
										{
											address: grantee
										}
									],
									// eslint-disable-next-line new-cap
									html: GrantedRights({
										template: ownProps.template,
										rights,
										grantee,
										owner,
										grantedOrRevoked
									})
								})
							}
						});
				}

				return {
					sendGrantRightsEmailNotification: sendRightsEmailNotificationFactory(GRANTED),
					sendRevokeRightsEmailNotification: sendRightsEmailNotificationFactory(REVOKED)
				};
			}
		})
	);
}
