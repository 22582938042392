import { gql } from '@apollo/client';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import memoize from 'lodash-es/memoize';
import { USER_FOLDER_IDS } from '../constants/';
import { doMailSort } from '../utils/search';
import ActionMutation from '../graphql/queries/action.graphql';
import SearchFragment from '../graphql/fragments/search.graphql';
import {
	optimisticAddFolderItemCount,
	optimisticRemoveFromFolder
} from '../graphql/utils/graphql-optimistic';

import { types as apiClientTypes } from '@zimbra/api-client';

import { FLAGS } from '../lib/util';
import update from 'immutability-helper';

const { ActionOps, ActionTypeName } = apiClientTypes;

const ConversationWithFlagsFragment = gql`
	fragment ConversationWithMessagesAndFlags on Conversation {
		id
		flags
		folderId
		tags
		... on messages {
			id
			flags
			folderId
			autoSendTime
			tags
		}
	}
`;

const MessageInfoWithFlagsFragment = gql`
	fragment MessageInfoWithFlags on MessageInfo {
		id
		flags
		folderId
		autoSendTime
		tags
	}
`;

const memoizedActionMutation = memoize(
	(mutate, trashFolderId, isOffline, client) =>
		({ removeFromList, local, tagToAdd, tagToRemove, view, ...variables }) => {
			const isLocalFolder = variables.isLocal === null ? false : variables.isLocal;
			const isConversation = variables.type === ActionTypeName.ConvAction;
			const typename = isConversation ? 'Conversation' : 'MessageInfo';

			const getMailListItemFragmentOptions = id => ({
				id: `${typename}:${id}`,
				fragment: SearchFragment,
				fragmentName: `search${isConversation ? 'Conversation' : 'Message'}Fields`
			});
			const ids = (typeof variables.id !== 'undefined' && [variables.id]) || variables.ids || [];

			const localContext =
				local || (isOffline && typeof process.env.ELECTRON_ENV !== 'undefined')
					? { local: true }
					: { local: false };

			return mutate({
				context: {
					...localContext
				},
				variables,
				optimisticResponse: {
					__typename: 'Mutation',
					action: true
				},
				update: proxy => {
					const isTagOp = [ActionOps.tag, ActionOps.untag].indexOf(variables.op) !== -1;

					const fragment = isConversation
						? ConversationWithFlagsFragment
						: MessageInfoWithFlagsFragment;

					ids
						.map(id =>
							proxy.readFragment({
								id: `${typename}:${id}`,
								fragment
							})
						)
						.forEach(item => {
							if (!item) {
								return;
							}

							if (isTagOp) {
								// Write new tags to cache
								updateTags({
									proxy,
									fragment,
									item,
									tagToAdd,
									tagToRemove,
									view,
									isConversation
								});
							} else {
								//write the new flags to the cache
								updateFlags({
									proxy,
									fragment,
									item,
									variables,
									trashFolderId,
									localContext,
									local: isLocalFolder
								});
							}
							//update each message in a conversation as well
							(item.messages || []).forEach(m => {
								if (isTagOp) {
									updateTags({
										proxy,
										fragment: MessageInfoWithFlagsFragment,
										item: m,
										tagToAdd,
										tagToRemove,
										view
									});
								} else {
									updateFlags({
										proxy,
										fragment: MessageInfoWithFlagsFragment,
										item: m,
										variables,
										trashFolderId,
										localContext,
										local: isLocalFolder
									});
								}
							});
						});
				},
				updateQueries: {
					search: (prevResult, optss) => {
						// switch on `op`?
						const key = isConversation ? 'conversations' : 'messages';
						if (prevResult.search[key]) {
							if (removeFromList) {
								// Remove the item from list if the options include `removeFromList`
								prevResult = {
									...prevResult,
									search: {
										...prevResult.search,
										[key]: prevResult.search[key].filter(result => !~ids.indexOf(result.id))
									}
								};
							} else if (variables.op === ActionOps.move || variables.op === ActionOps.unspam) {
								// If not removing from list and the action is a `move` || `unspam`, read the items
								// being moved and add them to the search results.
								// This enables optimistic undo on trash/move actions
								const mailItems = ids.map(id =>
									client.readFragment(getMailListItemFragmentOptions(id))
								);

								const nextSearch = [...prevResult.search[key], ...mailItems].sort(
									doMailSort.bind(null, optss.queryVariables.sortBy)
								);

								prevResult = {
									...prevResult,
									search: {
										...prevResult.search,
										[key]: nextSearch
									}
								};
							}
						}
						return prevResult;
					}
				}
			});
		}
);

export default function withActionMutation() {
	return compose(
		connect(state => ({
			trashFolderId: get(state, 'trashFolder.folderInfo.id'),
			isOffline: state.network.isOffline
		})),
		withApollo,
		graphql(ActionMutation, {
			props: ({ mutate, ownProps: { trashFolderId, isOffline, client } }) => ({
				action: memoizedActionMutation(mutate, trashFolderId, isOffline, client)
			})
		})
	);
}

/*
	This function adds or removes to/from existing tags.
	Since inbound tags are in the form of string having comma separated value, it needs to be converted into array first to make modifications easy.
*/
function updateTags({ proxy, fragment, item, tagToAdd, tagToRemove, view }) {
	const { id, tags, __typename } = item;
	let convertedTags = tags ? tags.split(',') : [];
	if (tagToAdd) {
		convertedTags.indexOf(tagToAdd.id) === -1 && convertedTags.push(tagToAdd.id);
	} else if (tagToRemove) {
		convertedTags = convertedTags.filter(tagId => tagId !== tagToRemove.id);
		optimisticRemoveFromFolder(
			proxy,
			{ id: tagToRemove.id },
			item,
			view === ActionTypeName.conversation,
			true
		);
	}

	proxy.writeFragment({
		id: `${__typename}:${id}`,
		fragment,
		data: {
			...item,
			__typename,
			id,
			tags: convertedTags.join(',') // Convert from array to string having comma separated values
		}
	});
}

function updateFlags({ proxy, fragment, item, variables, trashFolderId, localContext, local }) {
	const { op, flags: newFlags } = variables;
	const { id, flags, __typename } = item;
	const currentFlags = typeof flags !== 'string' ? '' : flags;

	const dataToUpdate = {};

	switch (op) {
		case ActionOps.read: {
			if (currentFlags.indexOf(FLAGS.unread) !== -1) {
				dataToUpdate.flags = {
					$set: currentFlags.replace(FLAGS.unread, '')
				};

				// Decrease unread count for the parent folder
				optimisticAddFolderItemCount(
					proxy,
					{ id: item.folderId },
					{ unread: -1, localContext, local }
				);
			}
			break;
		}
		case ActionOps.unread: {
			if (currentFlags.indexOf(FLAGS.unread) === -1) {
				dataToUpdate.flags = {
					$set: currentFlags + FLAGS.unread
				};

				// Increase unread count for the parent folder
				optimisticAddFolderItemCount(
					proxy,
					{ id: item.folderId },
					{ unread: 1, localContext, local }
				);
			}
			break;
		}
		case ActionOps.update: {
			dataToUpdate.flags = {
				$set: newFlags
			};
			break;
		}
		case ActionOps.unflag: {
			dataToUpdate.flags = {
				$set: currentFlags.replace(FLAGS.flagged, '')
			};
			break;
		}
		case ActionOps.flag: {
			if (currentFlags.indexOf(FLAGS.flagged) === -1) {
				dataToUpdate.flags = {
					$set: currentFlags + FLAGS.flagged
				};
			}
			break;
		}
		case ActionOps.trash: {
			// If an unread message is being trashed, decrease the item count.
			optimisticAddFolderItemCount(
				proxy,
				{ id: item.folderId },
				{
					nonFolderItemCount: -1,
					...(currentFlags.includes(FLAGS.unread) && { unread: -1, localContext, local })
				}
			);

			dataToUpdate.folderId = {
				$set: trashFolderId || USER_FOLDER_IDS.TRASH
			};
			break;
		}
	}

	//If the flags changed, write the new data to the cache
	if (Object.keys(dataToUpdate).length > 0) {
		proxy.writeFragment({
			id: `${__typename}:${id}`,
			fragment,
			data: update(item, dataToUpdate)
		});
	}
}
