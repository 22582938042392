import { Component } from 'preact';
import { connect } from 'react-redux';
import { Text } from 'preact-i18n';
import { Button } from '@zimbra/blocks';
import { absoluteUrl } from '../../../lib/util';
import Select from '../../select';
import style from '../style';
import { startAttachmentDownloadProcess } from '../../../lib/save-as';
import linkState from 'linkstate';
import get from 'lodash-es/get';
import withTracking from '../../../enhancers/tracking';

const DESCRIPTION_LIST = ['IMAP', 'CalDAV', 'CardDAV'];
const OPTION_LIST = [
	{ value: 'all', label: 'settings.accounts.mobileConfig.optionList.all' },
	{ value: 'dav', label: 'settings.accounts.mobileConfig.optionList.dav' },
	{ value: 'caldav', label: 'settings.accounts.mobileConfig.optionList.caldav' },
	{ value: 'carddav', label: 'settings.accounts.mobileConfig.optionList.carddav' },
	{ value: 'imap', label: 'settings.accounts.mobileConfig.optionList.imap' }
];
const MANUAL_URL_LIST = ['Calendar', 'Contacts'];

@withTracking()
@connect(({ email = {} }) => ({
	accountName: get(email, 'account.name')
}))
class MobileConfig extends Component {
	state = {
		mobileConfigType: this.props.isCalendarEnabled ? 'all' : 'carddav'
	};

	handleDownload = () => {
		const { tracker } = this.props;

		const { mobileConfigType } = this.state;
		const value = `/service/home/~/?fmt=mobileconfig&configType=${mobileConfigType}`;

		tracker.setUserAction({ action: 'settings-account-download' });
		const uri = this.getURI(value);
		startAttachmentDownloadProcess(uri);
	};

	getURI = value => this.context.zimbraBatchClient.resolve(value);

	getManualLink = emailAddress => absoluteUrl(this.getURI(`/dav/${emailAddress}`));

	render({ accountName, isCalendarEnabled }, { mobileConfigType }) {
		const uriLink = this.getManualLink(accountName);
		return (
			<div class={style.mobileConfigSection}>
				<div class={style.sectionTitle}>
					<Text id="settings.accounts.mobileConfig.title" />
				</div>
				<div class={style.description}>
					<p>
						<Text
							id={`settings.accounts.mobileConfig.description.${
								this.props.isCalendarEnabled ? 'default' : 'calDisabled'
							}`}
						/>
						<ul>
							{DESCRIPTION_LIST.filter(
								value => isCalendarEnabled || value !== DESCRIPTION_LIST[1]
							).map(value => (
								<li>
									<Text id={`settings.accounts.mobileConfig.description.list.${value}`} />
								</li>
							))}
						</ul>
					</p>
				</div>
				<div class={style.optionText}>
					<p>
						<Text id="settings.accounts.mobileConfig.optionText" />
					</p>
					<div class={style.subsectionBody}>
						<Select
							fullWidth
							value={mobileConfigType}
							onChange={linkState(this, 'mobileConfigType', 'target.value')}
						>
							{OPTION_LIST.filter(
								({ value }) => isCalendarEnabled || ['imap', 'carddav'].includes(value)
							).map(({ value, label }) => (
								<option value={value}>
									<Text id={label} />
								</option>
							))}
						</Select>
					</div>
				</div>
				<div class={style.manualLinks}>
					<p>
						<Text
							id={`settings.accounts.mobileConfig.urlText.${
								this.props.isCalendarEnabled ? 'default' : 'calDisabled'
							}`}
						/>
					</p>
					<ul>
						{MANUAL_URL_LIST.filter(value => isCalendarEnabled || value !== MANUAL_URL_LIST[0]).map(
							value => {
								return (
									<li>
										<Text
											id={`settings.accounts.mobileConfig.urlLink.${value.toLowerCase()}`}
											fields={{ uri: `${uriLink}/${value}` }}
										/>
									</li>
								);
							}
						)}
					</ul>
				</div>
				<div class={style.button}>
					<Button styleType="primary" brand="primary" onClick={this.handleDownload}>
						<Text id="buttons.download" />
					</Button>
				</div>
			</div>
		);
	}
}

export default MobileConfig;
