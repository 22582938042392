import GetFolder from '../../graphql/queries/folders/get-folder.graphql';
import { useQuery, useMutation } from '@apollo/client';
import { FolderActionMutation } from '../../graphql/queries/folders/folders.graphql';
import foldersData from '../../graphql/fragments/folder-data.graphql';
import createFolder from '../../graphql/queries/folders/create-folder.graphql';

export const useFoldersQuery = ({ variables, ...options }) => {
	return useQuery(GetFolder, {
		variables,
		...options
	});
};

export const useFolderActionMutation = () => {
	return useMutation(FolderActionMutation);
};

export const useCreateFolderMutation = () => {
	return useMutation(createFolder);
};

export const useRenameFolderActionMutation = () => {
	const [folderAction] = useFolderActionMutation();
	return function ({ variables, folder }) {
		return folderAction({
			variables,
			update: cache => {
				const updatedFolder = cache.readFragment({
					id: `Folder:${folder.parentFolderId}`,
					fragment: foldersData,
					fragmentName: 'foldersData'
				});

				let { folders } = updatedFolder;
				folders = folders
					.filter(f => folder.id !== f.id)
					.concat(folder)
					.sort((a, b) => (a.name > b.name ? 1 : -1));

				cache.writeFragment({
					id: `Folder:${folder.parentFolderId}`,
					fragment: foldersData,
					fragmentName: 'foldersData',
					data: {
						...updatedFolder,
						folders
					}
				});
			}
		});
	};
};

export const useCreateFolderHandlerMutation = () => {
	const [createFolderAction] = useCreateFolderMutation();
	return function ({ variables, folder }) {
		return createFolderAction({
			variables,
			update: (cache, { data: { createFolder: createdFolder } }) => {
				let { folders, id } = folder;
				folders = (folders && folders.concat({ ...createdFolder, folders: null })) || [
					{ ...createdFolder, folders: null }
				];

				cache.writeFragment({
					id: `Folder:${id}`,
					fragment: foldersData,
					fragmentName: 'foldersData',
					data: {
						...folder,
						folders: folders.sort((a, b) => (a.name > b.name ? 1 : -1))
					}
				});
			}
		});
	};
};
