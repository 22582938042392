import { useCallback } from 'preact/hooks';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import get from 'lodash-es/get';
import { Text } from 'preact-i18n';
import style from './style';
import ActionButton from '../action-button';
import { dateToFromNow } from './mini-calendar-util';
import { updateDateSelection } from '../../store/mini-calendar/actions';

export function Header() {
	const selectedDate =
		useSelector(state => get(state, 'miniCal.selectedDate')) || moment().startOf('day');
	const dispatch = useDispatch();

	const headerLabel = dateToFromNow(selectedDate);

	const onPrev = useCallback(
		() => dispatch(updateDateSelection(moment(selectedDate).add(-1, 'days'))),
		[dispatch, selectedDate]
	);

	const onNext = useCallback(
		() => dispatch(updateDateSelection(moment(selectedDate).add(1, 'days'))),
		[dispatch, selectedDate]
	);

	return (
		<div class={style.miniCalHeader}>
			<Text id={headerLabel}>{headerLabel}</Text>
			<div>
				<ActionButton
					icon="angle-left"
					iconSize="xs"
					monotone
					onClick={onPrev}
					className={style.navButton}
				/>
				<ActionButton
					icon="angle-right"
					iconSize="xs"
					monotone
					onClick={onNext}
					className={style.navButton}
				/>
			</div>
		</div>
	);
}
