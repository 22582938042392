import { useCallback, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash-es/get';
import { Text, MarkupText } from 'preact-i18n';
import style from '../style';
import { Button, Icon } from '@zimbra/blocks';
import { withAriaId } from '@zimbra/a11y';
import DateInput from '../../date-input';
import { rendererIpc } from '@zimbra/electron-app';
import { setSyncStatus } from '../../../store/sync/actions';
import moment from 'moment';
import cx from 'classnames';
import { SYNC_ERROR } from '../../desktop-sync/constants';

function OfflineDesktopSettings() {
	const dispatch = useDispatch();
	const syncDate = useSelector(state => get(state, 'sync.selectedDate'));
	const latestCompletedSyncDate = useSelector(state => get(state, 'sync.latestCompletedSyncDate'));
	const progress = useSelector(state => get(state, 'sync.loading'));
	const error = useSelector(state => get(state, 'sync.error'));
	const canceled = useSelector(state => get(state, 'sync.canceled'));
	const isOffline = useSelector(state => get(state, 'network.isOffline'));
	const syncSelectedDate = syncDate || latestCompletedSyncDate;

	const [showApplySync, setApplySyncEnable] = useState(canceled);
	const [selectedDate, setSelectedDate] = useState(syncSelectedDate);

	const handleApply = useCallback(() => {
		if (isOffline) {
			dispatch(setSyncStatus({ isLoading: false, canceled: false, error: true }));
		} else {
			dispatch(setSyncStatus({ isLoading: true, canceled: false, error: false }));
			rendererIpc.updateSyncProcess({ syncDate: selectedDate });
		}
	}, [selectedDate, isOffline, dispatch]);

	const handleRestart = useCallback(() => {
		dispatch(setSyncStatus({ isLoading: true, canceled: false, error: false }));
		rendererIpc
			.handleDiskSpaceWarning()
			.then(diskSpace => {
				const restartSynMinSize = 20000000; //20MB
				if (diskSpace?.free >= restartSynMinSize) {
					if (canceled & error) {
						handleApply();
					} else if (isOffline) {
						dispatch(setSyncStatus({ isLoading: false, canceled: false, error: true }));
					} else {
						rendererIpc.resumeSyncProcess();
					}
				} else {
					dispatch(
						setSyncStatus({ isLoading: false, canceled: false, error: SYNC_ERROR.DISK_SPACE })
					);
				}
			})
			// eslint-disable-next-line no-console
			.catch(e => console.log('Error to calculate free disk space: ', e));
	}, [isOffline, canceled, handleApply, dispatch, error]);

	const handleCancel = useCallback(() => {
		setApplySyncEnable(true);
		rendererIpc.updateSyncProcess();
	}, []);

	const handleDateChange = useCallback(
		(date, isValid = true) => {
			if (isValid) {
				setApplySyncEnable(!(moment(date).isAfter(moment(selectedDate)) || moment(date).isAfter()));
			} else {
				setApplySyncEnable(false);
			}
			setSelectedDate(moment(date).valueOf());
		},
		[selectedDate]
	);

	return (
		<div class={style.offlineDesktopSection}>
			<div class={style.sectionTitle}>
				<Text id="settings.offlineDesktop.title" />
			</div>
			<MarkupText id="settings.offlineDesktop.description" />
			<div class={style.settingsSection}>
				<div class={style.subsection}>
					<div class={cx(style.subsectionTitle, style.alignSectionLabel)}>
						<Text id="settings.offlineDesktop.label" />
					</div>
					<div class={style.subsectionBody}>
						<DateInput
							onDateChange={handleDateChange}
							class={style.dateSelector}
							dateValue={selectedDate}
							disabled={progress}
						/>
						{progress && (
							<div class={style.infoSection}>
								<Text id="settings.offlineDesktop.downloading" />
							</div>
						)}

						{!progress && (
							<Button
								class={style.buttonWithMargin}
								onClick={handleApply}
								styleType="primary"
								disabled={error || !showApplySync}
							>
								<Text id="buttons.apply" />
							</Button>
						)}
						{progress && !error && (
							<Button class={style.buttonNoMargin} onClick={handleCancel}>
								<Text id="buttons.cancel" />
							</Button>
						)}
					</div>
				</div>
				{!progress && !canceled && (error || syncSelectedDate) && (
					<div class={style.subsection}>
						<div class={cx(style.subsectionTitle, style.alignSectionLabel)}>
							<Text id="settings.offlineDesktop.syncStatusLabel" />
						</div>
						<div class={style.subsectionBody}>
							{error ? (
								<div class={style.syncInfo}>
									<Icon
										name="close-circle"
										size="md"
										class={cx(style.danger, style.syncStatusIcon)}
									/>

									{error === SYNC_ERROR.DISK_SPACE
										? [
												<Text id="settings.offlineDesktop.spaceError" />,
												<div>
													<Text id="settings.offlineDesktop.diskSpaceInfo" />
												</div>
										  ]
										: [
												<Text id="settings.offlineDesktop.internetError" />,
												<div>
													<Text id="settings.offlineDesktop.syncReconnectInfo" />
												</div>
										  ]}
								</div>
							) : (
								<div class={style.syncInfo}>
									<Icon
										name="check-circle"
										size="md"
										class={cx(style.success, style.syncStatusIcon)}
									/>
									<Text
										id="settings.offlineDesktop.syncSuccess"
										fields={{
											date: moment(syncSelectedDate).format('L')
										}}
									/>
								</div>
							)}

							{error === SYNC_ERROR.DISK_SPACE && (
								<Button class={style.buttonNoMargin} onClick={handleRestart}>
									<Text id="buttons.restart" />
								</Button>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default withAriaId('offlineDesktop')(OfflineDesktopSettings);
