let exportVar;

import { Component } from 'preact';
import { connect } from 'react-redux';
import { Text } from 'preact-i18n';
import { Button } from '@zimbra/blocks';
import style from '../style';
import { toggle } from './../../../store/settings/actions';
import chooseFiles from 'choose-files';
import ErrorAlert from '../../error-alert';
import { setPstStatus } from './../../../store/pst-import/actions';

if (typeof process.env.ELECTRON_ENV !== 'undefined') {
	@connect(null, { toggle, setPstStatus })
	class ImportPST extends Component {
		state = {
			filePath: '',
			fileName: ''
		};

		handlePSTImport = () => {
			const { rendererIpc } = require('@zimbra/electron-app');
			const { filePath, fileName } = this.state;

			this.props.setPstStatus({
				pstFileName: fileName
			});

			rendererIpc.startPSTExtraction(filePath);
			this.props.toggle();
		};

		choosePstFileCallback = ([file]) => {
			if (file) {
				const format = file.name.match(/\.(\w+)$/)[1];
				this.setState({
					filePath: file.path,
					fileName: file.name,
					error: format !== 'pst'
				});
			}
		};

		choosePSTFile = () => {
			chooseFiles({ multiple: false, accept: '.pst' }, this.choosePstFileCallback);
		};

		render() {
			const { fileName, error } = this.state;
			return (
				<div class={style.importPSTSection}>
					<div class={style.sectionTitle}>
						<Text id="settings.accounts.import.pst.title" />
					</div>
					<div class={style.description}>
						<p>
							<Text id="settings.accounts.import.pst.description" />
						</p>
						<p class={style.lineBreak}>
							<Text id="settings.accounts.import.pst.note" />
						</p>
					</div>
					{error && (
						<div>
							<ErrorAlert>
								<Text id="importModal.errorFileFormat" />
							</ErrorAlert>
						</div>
					)}
					<div class={style.buttons}>
						<Button styleType="default" onClick={this.choosePSTFile} class={style.fileChooseBtn}>
							<Text id="buttons.choosePSTFile" />
						</Button>
						{fileName && <span>{fileName}</span>}
						<div class={style.button}>
							<Button
								styleType="primary"
								brand="primary"
								onClick={this.handlePSTImport}
								disabled={!fileName || error}
							>
								<Text id="buttons.import" />
							</Button>
						</div>
					</div>
				</div>
			);
		}
	}
	exportVar = ImportPST;
}

export default exportVar;
