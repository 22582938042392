import emitter from 'mitt';
//import qs from 'query-string';
import delve from 'lodash-es/get';
import debounce from 'lodash-es/debounce';
import realm from './realm';
import createCache from './cache';
// import compat from './compat';  //commented out for now. Can be deleted when we are super sure we won't do any backwards compat
import draftForMessage from '../../utils/draft-for-message';
import { htmlToText } from '../../lib/html-email';
import { colorForCalendar, filterNonEditableCalendars } from '../../utils/calendar';
import { newAlarm } from '../../utils/event';
import moment from 'moment';
import shims from './shims';

const utils = {
	draftForMessage,
	moment,
	htmlToText,
	colorForCalendar,
	newAlarm,
	filterNonEditableCalendars
};

const SHOW_ZIMLETS_URL_FLAG = /[?&#]zimletSlots=show(?:&|$)/;

export default function zimletManager({
	zimbra,
	store,
	config,
	jwtToken, // eslint-disable-line no-unused-vars
	csrfToken,
	showZimletSlots,
	keyBindings,
	shortcutCommandHandler,
	zimbraBatchClient,
	getApolloClient
}) {
	const { zimbraOrigin } = config;
	const exports = emitter();
	exports.initialized = false;
	const oninit = deferred();
	let initializing = false;
	exports.failedInitializations = 0;

	let initializeEventTriggered = false;

	//Show zimlet slots
	if (
		showZimletSlots ||
		(typeof location !== 'undefined' && String(location).match(SHOW_ZIMLETS_URL_FLAG))
	) {
		exports.showZimletSlots = true;
	}

	const plugins = {};

	let idCounter = 0;

	const zimbraContextGlobals = {
		ZIMLETS_VERSION: '2.0.0'
	};

	const zimbraRealm = realm({
		name: 'Zimlet Manager',
		scope: zimbraContextGlobals
	}).then(r => (zimbraRealm.sync = r));

	function getAccount() {
		return delve(store.getState(), 'email.account') || {};
	}

	exports.invokePlugin = function invokePlugin(name, ...args) {
		const list = plugins[name],
			results = [];
		let res;
		if (list) {
			for (let i = 0; i < list.length; i++) {
				try {
					if (typeof list[i].handler === 'function') {
						res = list[i].handler(...args);
					} else {
						res = list[i].handler;
					}
				} catch (err) {
					err.sourceZimlet = list[i].zimletName;
					res = err;
				}
				results.push(res);
			}
		}
		return results;
	};

	const debouncedZimletsInitialized = debounce(() => {
		exports.emit('zimlets-initialized');
		initializeEventTriggered = true;
	}, 500);

	// Create a zimlet-specific registry for adding/removing named plugins (eg: slots)
	function createPlugins(zimletName) {
		return {
			register(name, handler) {
				const list = plugins[name] || (plugins[name] = []);
				list.push({ zimletName, handler });
				exports.emit('plugins::changed', name);

				// after the zimlets-initialized event has already triggered, trigger the event again
				// for all the registrations that happen afterwards
				if (initializeEventTriggered) {
					debouncedZimletsInitialized();
				}
			},
			unregister(name, handler) {
				const list = plugins[name];
				if (list) {
					for (let i = list.length; i--; ) {
						if (list[i].zimletName === zimletName && list[i].handler === handler) {
							list.splice(i, 1);
							exports.emit('plugins::changed', name);
							break;
						}
					}
				}
			},
			unregisterAll() {
				let name;
				for (name in plugins) {
					if (Object.prototype.hasOwnProperty.call(plugins, name)) {
						const list = plugins[name];
						let changed = false;
						for (let i = list.length; i--; ) {
							if (list[i].zimletName === zimletName) {
								list.splice(i, 1);
								changed = true;
							}
						}
						if (changed) {
							exports.emit('plugins::changed', name);
						}
					}
				}
			}
		};
	}

	function createStyler(zimletName) {
		let tag;
		return {
			set(css) {
				css = String(css || '');
				if (!tag) {
					tag = document.createElement('style');
					tag.id = `zimlet-style-${zimletName}`;
					tag.appendChild(document.createTextNode(css));
					document.head.appendChild(tag);
				} else {
					tag.firstChild.nodeValue = css;
				}
			},
			remove() {
				if (tag) tag.parentNode.removeChild(tag);
				tag = null;
			}
		};
	}

	function runZimlets(code, options = {}) {
		const zm = options.zimlet || (options.config && options.config.zimlet) || {};
		const name = zm.name || options.name || `zimlet_${++idCounter}`;
		let factory;
		let container;

		const zimletContext = {
			zimbraOrigin,
			zimlets: exports,
			zimbra,
			zimletRedux: store.zimletRedux, // TODO: remove on breaking release
			getApolloClient,
			getAccount,
			csrfToken,
			config: options.config,
			plugins: createPlugins(name),
			resourceUrl: `/service/zimlet/${encodeURIComponent(name)}/`,
			cache: createCache(name),

			shims,
			utils: Object.assign({}, utils),
			styles: createStyler(name),
			keyBindings,
			zimbraBatchClient,
			shortcutCommandHandler,
			store,
			meta: {
				build: {
					version: PKG_VERSION,
					hash: BUILD_COMMIT_HASH,
					timestamp: BUILD_TIMESTAMP
				}
			}
		};

		if (options.context) Object.assign(zimletContext, options.context);

		return zimbraRealm
			.then(c => {
				container = c;

				// @note: compat is disabled/removed for now
				zimletContext.isCompat = false;
				// zimletContext.isCompat = options.compat === true || (options.compat !== false && exports.compat.isCompatZimlet(name, zm));
				// if (zimletContext.isCompat) {
				// 	container.expose(exports.compat.getGlobals(zimletContext));
				// }

				// Expose the global zimlet(factory) register function:
				container.expose({
					zimlet: f => {
						factory = f;
					}
				});

				// Expose any additional custom scope items:
				if (options.scope) container.expose(options.scope);

				// Actually run the zimlet code:
				return container.eval(code, {
					wrap: !zimletContext.isCompat,
					sourceUrl: options.url
				});
			})
			.then(context => {
				context.zimletContext = zimletContext;

				// overwrite the container's zimlet() method with one that performs an update instead of an init:
				container.expose({
					zimlet: f => {
						//eslint-disable-next-line no-console
						console.log(` 🔄 Zimlet ${name} restarted.`);
						context._shutdown();
						factory = f;
						context._setup();
						context.init();
					}
				});

				// Get a list of methods that can be invoked on a zimlet:
				context.getHandlerMethods = () =>
					Object.keys(context.handler || {}).reduce(
						(acc, n) => ((acc[n] = context.handler[n].bind(context)), acc),
						{}
					);

				// Invoke a method on the zimlet's public (returned) interface:
				context.invoke = (method, ...args) => {
					if (!context.handler) throw Error(`No method ${method}()`);
					const path = ['handler'].concat(method.split('.'));
					method = path.pop();
					const ctx = delve(context, path);
					return ctx[method](...args);
				};

				// Initialize the zimlet if it hasn't already been initialized.
				context.init = () => {
					if (context.initialized !== true) {
						context.initialized = true;
						if (context.init) return context.invoke('init');
					}
				};

				// Inform zimlet of shutdown, remove all plugins & stylesheets, then kill it.
				// Note: this intentionally does not destroy the container, since it is used for soft restarts (eg: HMR)
				context._shutdown = () => {
					try {
						if (context.unload) context.invoke('unload');
						if (context.destroy) context.invoke('destroy');
					} catch (err) {
						console.error('Error shutting down zimlet: ' + err);
					}
					zimletContext.plugins.unregisterAll();
					zimletContext.styles.remove();
					context.initialized = false;
				};

				// (re-)initialize the zimlet by invoking its register factory
				context._setup = () => {
					try {
						const handlerObj = zm.handlerObject;
						if (factory) {
							//eslint-disable-next-line new-cap
							context.handler = new factory(zimletContext);
						} else if (handlerObj) {
							context.handler = new context.globals[handlerObj]();
						}
					} catch (err) {
						return Promise.reject(Error(`Failed to construct handlerObject: ${err}`));
					}
				};

				return context._setup() || context;
			});
	}

	exports.initialize = function initialize() {
		if (initializing === false && !exports.initialized) {
			initializing = false;
			exports.initialized = true;

			oninit.resolve(exports);
			exports.emit('init', exports);
			return Promise.resolve();
		}
		return oninit;
	};

	exports.destroy = function destroy() {
		if (zimbraRealm.sync) {
			zimbraRealm.sync.destroy();
		}
	};

	exports.setJwtToken = token => {
		jwtToken = token;
	};

	exports.setCsrfToken = token => {
		csrfToken = token;
	};

	/**
	 * Load a bundle containing one or more Zimlets. Zimlets must be seperated by
	 * the token defined below in {@function seperateConsolidatedZimlets}
	 */
	exports.loadZimletByUrls = function loadZimlet(url, options = {}) {
		options.url = url;
		let credentials = 'same-origin';
		const headers = options.headers || {};

		if (/^\/[^/]/.test(url)) {
			// Resolve relative URLs to absolute with the zimbraOrigin, and include credentials
			url = zimbraOrigin + url;
			credentials = 'include';

			if (jwtToken) {
				headers.Authorization = `Bearer ${jwtToken}`;
			}

			if (csrfToken) {
				headers['X-Zimbra-Csrf-Token'] = csrfToken;
			}
		}

		return fetch(url, { credentials, headers })
			.then(r => {
				if (r.ok) {
					return r.text();
				}
				const error = new Error(r.statusText || r.status);
				error.response = r;
				return Promise.reject(error);
			})
			.then(code => seperateConsolidatedZimlets(code))
			.then(codes =>
				Promise.all(
					codes.map(({ name, code }) =>
						exports.runZimlet(`${name || options.name || url}`, code, options)
					)
				)
			)
			.then(zimletsData => {
				debouncedZimletsInitialized();
				return zimletsData;
			});
	};

	const runCache = {};
	exports.runZimlet = function runZimlet(name, code, options = {}) {
		if (name in runCache) {
			//eslint-disable-next-line no-console
			console.log(`Zimlet "${name}" has already been started.`);
			return runCache[name];
		}

		return (runCache[name] = exports
			.initialize()
			.then(() => runZimlets(code, options))
			.then(zimlet => ({
				name,
				zimlet
			})));
	};

	exports.isSlotActive = function (slot = '') {
		// ensure that if the slot exists that the array is not empty
		// an empty array occurs when unregister has been called on the slot
		return !!plugins[slot] && plugins[slot].length !== 0;
	};

	// exports.compat = compat({
	// 	zimlets: exports,
	// 	zimbra,
	// 	store,
	// 	zimbraOrigin
	// });

	setTimeout(exports.initialize, 1000);

	return exports;
}

//const array = obj => Array.isArray(obj) ? obj : [].concat(obj);

function deferred() {
	let resolve, reject;
	const me = new Promise((realResolve, realReject) => {
		resolve = realResolve;
		reject = realReject;
	});
	me.resolve = resolve;
	me.reject = reject;
	return me;
}

function seperateConsolidatedZimlets(consolidatedCode) {
	// Seperated by a comment with trailing whitespace, newline, Zimlet:, close comment, newline.
	// e.g. /*
	//       * Zimlet: ... */

	const names = consolidatedCode
		.split(/\n/)
		.filter(line => /\*\sZimlet:.*File:.*\*\//.test(line))
		.map(line => line.replace(/.*Zimlet: (.*)File:.*/, '$1'));

	return consolidatedCode
		.split(/\/\*\s\n\s*\*\sZimlet:.*\*\/\n/)
		.filter(Boolean)
		.map((code, index) => ({
			name: names[index],
			code
		}));
}
