import { useSearchCalendarResourcesQuery } from './calendar-resources';
import { useFreeBusyQuery } from '../free-busy';
import { useState, useEffect, useMemo } from 'preact/hooks';
import moment from 'moment';
import get from 'lodash-es/get';
import sortBy from 'lodash-es/sortBy';

export const useCalendarResourcesWithFreeBusyQuery = ({ start, end }) => {
	const [result, setResult] = useState([]);

	const { data: CalendarResourcesData, loading: CalendarResourcesLoading } =
		useSearchCalendarResourcesQuery({
			variables: {
				attrs:
					'description,email,fullName,zimbraCalResLocationDisplayName,zimbraCalResSite,zimbraCalResBuilding,zimbraCalResFloor,zimbraCalResRoom,zimbraCalResCapacity,zimbraCalResType,zimbraCalResContactEmail,zimbraCalResContactPhone,zimbraCalResContactName,street,l,st,postalCode,co',
				offset: 0,
				limit: 50,
				needExp: true,
				searchFilter: {
					conds: {
						cond: {
							attr: 'zimbraCalResType',
							op: 'eq',
							value: 'Location'
						}
					}
				}
			},
			fetchPolicy: 'cache-first'
		});

	const calresource = useMemo(
		() => get(CalendarResourcesData, 'searchCalendarResources.calresource') || [],
		[CalendarResourcesData]
	);

	const { data: FreeBusyData, loading: FreeBusyLoading } = useFreeBusyQuery({
		skip: !calresource.length,
		variables: {
			names: calresource.map(({ name }) => name),
			start: moment(start).valueOf(),
			end: moment(end).valueOf()
		},
		fetchPolicy: 'network-only'
	});

	useEffect(() => {
		const freeBusy = get(FreeBusyData, 'freeBusy') || [];
		if (freeBusy.length) {
			const finalCalresource = calresource.map(r => {
				const match = freeBusy.find(i => i.id === r.name);
				return {
					...r,
					isBusy: Boolean(match.busy)
				};
			});
			setResult(sortBy(finalCalresource, [({ _attrs: { fullName } }) => fullName.toLowerCase()]));
		}
	}, [FreeBusyData, CalendarResourcesData, calresource]);

	return {
		result,
		loading: CalendarResourcesLoading || FreeBusyLoading
	};
};
