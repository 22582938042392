import { graphql } from '@apollo/client/react/hoc';
import getSMimeCertInfoQuery from '../../graphql/queries/smime/smime-cert-info.graphql';

export default function withGetSmimeCertificateInfo(mapProps = () => ({}), config = {}) {
	const { options: { fetchPolicy, ...restOptions } = {}, ...restConfig } = config;

	return graphql(getSMimeCertInfoQuery, {
		...restConfig,
		options: ({ isOffline }) => ({
			...restOptions,
			fetchPolicy: isOffline ? 'cache-only' : fetchPolicy
		}),
		props: result => mapProps(result)
	});
}
