import { Component, cloneElement } from 'preact';
import cx from 'classnames';
import { createPortal } from 'preact/compat';

import { CloseOnClickOrKeydown, Manager, Reference, Popper } from '@zimbra/blocks';

import s from './style.less';

const ALT = 'Alt';
const F10 = 'F10';

/**
 * `FixedPopover` implements a `position: fixed` popover with outside click handling
 * that works similar to absolute positioning, but circumvents some of the issues
 * when rendering absolutely positioned elements in certain types of containers.
 */
export default class FixedPopover extends Component {
	state = {
		isOpen: false,
		mouseEventX: null,
		mouseEventY: null
	};

	static mounts = [];

	handlePositioning = (e, preventDefault = true) => {
		const { popover } = this.props;
		const { isOpen, mouseEventX, mouseEventY } = this.state;

		if (preventDefault) {
			e.preventDefault && e.preventDefault();
			e.stopPropagation && e.stopPropagation();
		}

		// Allow a user access to the system menu when invoking the menu twice
		// in the same location.
		if ((isOpen && mouseEventX === e.x && mouseEventY === e.y) || !popover) {
			return;
		}

		this.setState({
			isOpen: true,
			// this gives the position where the mouse event took place
			mouseEventX: e.x,
			mouseEventY: e.y
		});
	};

	handleClose = () => {
		this.setState({ isOpen: false });
	};

	handleBackdropClick = e => {
		if (this.backdrop === e.target) {
			this.setState({ isOpen: false });
		}
	};

	handleBackdropMouseDown = e => {
		if (e.which === 3) {
			this.handleBackdropClick(e);
		}
	};

	handleKeyDown = e => {
		// Close all open FixedPopovers
		if (e.key === ALT || e.key === F10) {
			FixedPopover.mounts.forEach(fixedPopoverElement => {
				if (fixedPopoverElement.state.isOpen) {
					fixedPopoverElement.setState({ isOpen: false });
				}
			});
		}
	};

	static defaultProps = {
		enableClick: true,
		enableContextMenu: false,
		disabled: false,
		persistent: false,
		popoverProps: {},
		useRectPositioning: false,
		disableBackdrop: false
	};

	componentWillMount() {
		// Add an event listener to
		if (this.props.enableContextMenu) {
			if (FixedPopover.mounts.length === 0) {
				addEventListener('keydown', this.handleKeyDown);
			}

			FixedPopover.mounts.push(this);
		}
	}

	componentWillUnmount() {
		if (this.props.enableContextMenu) {
			const thisIndex = FixedPopover.mounts.indexOf(this);
			if (thisIndex !== -1) {
				FixedPopover.mounts.splice(thisIndex, 1);
			}

			if (FixedPopover.mounts.length === 0) {
				removeEventListener('keydown', this.handleKeyDown);
			}
		}
	}

	renderPopover = ({ ref, placement, style }) => {
		const { popoverClass, popoverProps, persistent, popover, isPopover } = this.props;
		const { top } = style;

		return (
			<div
				ref={ref}
				style={style}
				data-placement={placement}
				{...popoverProps}
				class={cx(s.popover, isPopover && (top === 'auto' ? s.isBelow : s.isAbove), popoverClass)}
				{...(!persistent && {
					onClick: this.handleClose
				})}
			>
				{typeof popover === 'function'
					? popover({ onClose: this.handleClose })
					: cloneElement(popover, { onClose: this.handleClose })}
			</div>
		);
	};

	render(
		{
			disableBackdrop,
			enableClick,
			enableContextMenu,
			render,
			children,
			popover,
			popoverClass,
			popoverProps,
			disabled,
			persistent,
			isPopover,
			propagateClicks,
			useRectPositioning,
			...rest
		},
		{ isOpen, mouseEventX, mouseEventY }
	) {
		if (disabled) {
			return children;
		}

		return (
			<Manager>
				<div
					{...rest}
					{...(enableClick && { onClick: this.handlePositioning })}
					onContextMenu={enableContextMenu && this.handlePositioning}
					ref={ref => (this.container = ref)}
				>
					{render
						? render({
								isOpen,
								close: this.handleClose,
								open: this.handlePositioning,
								openContextMenu: this.handlePositioning,
								...rest
						  })
						: children}
					<Reference>
						{({ ref }) => (
							// this will be treated has the reference element
							// setting its postion based where the mouse click event took place
							<span
								ref={ref}
								style={{ top: mouseEventY, left: mouseEventX }}
								role="button"
								class={s.referenceElement}
							/>
						)}
					</Reference>
					{isOpen &&
						popover &&
						createPortal(
							<div
								class={!disableBackdrop && s.backdrop}
								onMouseDown={this.handleBackdropMouseDown}
								onClick={this.handleBackdropClick}
								ref={ref => (this.backdrop = ref)}
								{...(enableContextMenu && { onContextMenu: this.handlePositioning })}
							>
								<CloseOnClickOrKeydown onClickOutside={this.handleClose}>
									<Popper
										// the placement used is bottom-end so all popover will be in the
										// bottom-end of the element if the popover goes outside window it will be adjusted above accordingly
										placement="bottom-end"
										modifiers={[
											{
												name: 'preventOverflow',
												options: {
													altAxis: true
												}
											}
										]}
									>
										{this.renderPopover}
									</Popper>
								</CloseOnClickOrKeydown>
							</div>,
							document.body
						)}
				</div>
			</Manager>
		);
	}
}
