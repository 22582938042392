import { useCallback } from 'preact/hooks';
import Icon from '../icon';
import cx from 'classnames';
import style from './style';

const VerticalListItem = ({ item, onRemoveItem }) => {
	const handleRemove = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();
			onRemoveItem(item);
		},
		[onRemoveItem, item]
	);

	return (
		<li title={item}>
			{item}
			{onRemoveItem && (
				<Icon name="close" size="sm" class={style.removeIcon} onClick={handleRemove} />
			)}
		</li>
	);
};

const VerticalList = ({ items, onRemoveItem, class: cls, hideRemoveForSingle }) => {
	if (items.length === 0) {
		return null;
	}

	const hideRemove = hideRemoveForSingle && items.length === 1;
	return (
		<ul class={cx(style.verticalList, cls)}>
			{items.map(item => (
				<VerticalListItem item={item} key={item} onRemoveItem={!hideRemove && onRemoveItem} />
			))}
		</ul>
	);
};

export default VerticalList;
