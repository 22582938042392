import { Text } from 'preact-i18n';

import Select from '../select';
import { CALENDAR_VIEW } from '../../constants/views';

// (r)ead
// (w)rite
// (i)nsert
// (d)elete
// (a)dminister
// workflow action (x)
// view (p)rivate
// view (f)reebusy
// (c)reate subfolder

const INTERNAL_PERMISSIONS_OPTIONS = [
	'r',
	'rf',
	'rp',
	'rw',
	'rwidx',
	'rwidxp',
	'rwidxa',
	'rwidxap'
];

const EXTERNAL_PERMISSIONS_OPTIONS = ['r', 'rf', 'rp', 'rw', 'rwidx', 'rwidxp'];
const GENERIC_FOLDER_SHARING_PERMISSIONS_OPTIONS = ['r', 'rwidx', 'rwidxa'];

const PermissionsSelect = ({ inline, granteeType = 'guest', screen, value, ...rest }) => {
	const permissionsOptions =
		screen === CALENDAR_VIEW
			? granteeType === 'usr'
				? INTERNAL_PERMISSIONS_OPTIONS
				: EXTERNAL_PERMISSIONS_OPTIONS
			: GENERIC_FOLDER_SHARING_PERMISSIONS_OPTIONS;

	const updatedPermissions = !permissionsOptions.includes(value)
		? permissionsOptions.concat(value)
		: permissionsOptions;

	return (
		<Select value={value} {...rest} noBorder noHover>
			{updatedPermissions.map(k => (
				<option value={k} key={k}>
					{inline ? (
						<Text id={`shareItemModal.fields.permissionsInline.options.${k.replace(' ', '_')}`}>
							{' '}
							{k}{' '}
						</Text>
					) : (
						<Text id={`shareItemModal.fields.permissions.options.${k.replace(' ', '_')}`}>
							{' '}
							{k}{' '}
						</Text>
					)}
				</option>
			))}
		</Select>
	);
};

export default PermissionsSelect;
