import { Component } from 'preact';
import { Text } from 'preact-i18n';
import ModalDialog from '../modal-dialog';
import withLogout from '../../graphql-decorators/logout';

@withLogout()
export default class LicenseModalDialog extends Component {
	handleRoute = () => {
		this.props.logout();
	};
	render() {
		return (
			<ModalDialog
				title="about.modal.title.zxLicense"
				cancelButton={false}
				onAction={this.handleRoute}
				onClose={this.handleRoute}
			>
				<p>
					<Text id="about.modal.licenseCheck" />
				</p>
			</ModalDialog>
		);
	}
}
