import { useState, useEffect } from 'preact/hooks';
import RefreshIcon from './refresh-icon';

export default function FolderRefreshIcon({
	shouldVisible = false,
	isRefreshing = false,
	buttonClass,
	onClick
}) {
	const [refreshing, setRefreshing] = useState(isRefreshing);

	useEffect(() => {
		setRefreshing(isRefreshing);
	}, [isRefreshing]);

	const onClickHandler = e => {
		e.preventDefault();
		e.stopPropagation();
		setRefreshing(true);
		// `onClick` may or may not be promise
		onClick(e)?.then(() => {
			setRefreshing(false);
		});
	};

	return (
		(shouldVisible || refreshing) && (
			<RefreshIcon
				buttonClass={buttonClass}
				isRefreshing={refreshing}
				{...(!refreshing && { onClick: onClickHandler })}
			/>
		)
	);
}
