export const LICENSE_GOOD = 'OK';
export const LICENSE_NOT_INSTALLED = 'NOT_INSTALLED';
export const LICENSE_NOT_ACTIVATED = 'NOT_ACTIVATED';
export const LICENSE_FUTURE = 'IN_FUTURE';
export const LICENSE_EXPIRED = 'EXPIRED';
export const LICENSE_BAD = 'INVALID';
export const LICENSE_GRACE = 'LICENSE_GRACE_PERIOD';
export const LICENSE_ACTIV_GRACE = 'ACTIVATION_GRACE_PERIOD';

export const LICENSE_SMIME = 'SMIME';
