import { RetryLink } from '@apollo/client/link/retry';
import { ApolloLink } from '@apollo/client';
import get from 'lodash-es/get';

// Wrapper link to forward errors to the RetryLink
// See https://github.com/apollographql/apollo-link/issues/541#issuecomment-392166160
export default function RetryAllLink(config) {
	const retryLink = new RetryLink(config);

	return new ApolloLink((operation, forward) => {
		const context = operation.getContext();
		return forward(operation).map(data => {
			// In case of server returning 500 error, this retry link was again sending second request with same params, which is bound to fail so we are making sure, in case of 500 error we will not queue same request for retry
			const isNetworkError = get(data, 'errors.0.originalError.response.status') === 500;
			if (context.retry !== false && !isNetworkError && get(data, 'errors', []).length > 0) {
				retryLink.request(operation, forward);
			}
			return data;
		});
	});
}
