import { Component } from 'preact';

export default function withMediaQuery(mq, propName = 'matchesMediaQuery') {
	if (!window.matchMedia) {
		console.error('window.matchMedia is unsupported.');
		return BaseComponent => BaseComponent;
	}

	return BaseComponent =>
		class WithMediaQuery extends Component {
			state = {
				matchesMediaQuery: window.matchMedia(mq).matches
			};

			handleMediaQueryChange = e => {
				this.setState({ matchesMediaQuery: e.matches });
			};

			componentDidMount() {
				this.mediaQuery = window.matchMedia(mq);
				if ('addEventListener' in this.mediaQuery) {
					this.mediaQuery.addEventListener('change', this.handleMediaQueryChange);
				} else if ('addListener' in this.mediaQuery) {
					this.mediaQuery.addListener(this.handleMediaQueryChange);
				}
			}

			componentWillUnmount() {
				if (this.mediaQuery) {
					if ('removeEventListener' in this.mediaQuery) {
						this.mediaQuery.removeEventListener('change', this.handleMediaQueryChange);
					} else if ('removeListener' in this.mediaQuery) {
						this.mediaQuery.removeListener(this.handleMediaQueryChange);
					}
				}
			}

			render(props, { matchesMediaQuery }) {
				return <BaseComponent {...props} {...{ [propName]: matchesMediaQuery }} />;
			}
		};
}
