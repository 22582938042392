import { Spinner } from '@zimbra/blocks';
import cx from 'classnames';
import style from './style';
import { Text } from 'preact-i18n';

const stopAndPrevent = e => {
	const composerBodyWrapper = document.querySelector('div[class*="composer_bodyWrapper"]');
	const readPane = document.querySelectorAll(`div[class*='viewer_viewer']`);
	const allowInputNodeType = ['text', 'password', 'email'];
	const target = e.target;

	const selectionNode = String(document.getSelection()) === '' ? false : document.getSelection();

	const isComposerBody = target === composerBodyWrapper;
	const isInComposerBody = composerBodyWrapper?.contains(target);
	const isInViewPane = readPane && Array.from(readPane).some(panel => panel.contains(target));
	const isTextAreaNode = target.nodeName === 'TEXTAREA';
	const isInputWithAllowType =
		target.nodeName === 'INPUT' && allowInputNodeType.includes(target.type.toLowerCase());
	const isElementEditable = target.contentEditable === 'true';
	const isAnchorWithHref =
		target.nodeName === 'A' && target.href && target.host !== window.location.host;
	const isInSelectionText = selectionNode && selectionNode.containsNode(target, true);

	const allowBrowserContextMenu =
		isComposerBody ||
		isInViewPane ||
		isInComposerBody ||
		isTextAreaNode ||
		isInputWithAllowType ||
		isElementEditable ||
		isAnchorWithHref ||
		isInSelectionText;

	if (!allowBrowserContextMenu) {
		e.preventDefault();
		e.stopPropagation();
	}
};

export const AppShell = props => (
	<div {...props} class={cx(style.app, props.class)} onContextMenu={stopAndPrevent} />
);

export const Loader = () => (
	<AppShell>
		<div class={style.loading}>
			<Spinner class={style.spinner} />
			<div class={style.text}>
				<Text id="app.loading" />
			</div>
		</div>
	</AppShell>
);

export default Loader;
