import { useEffect, useCallback, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash-es/get';
import { updateSelection, clearSelection, setMsgListSortBy } from '../../store/dumpster/actions';
import { notify } from '../../store/notifications/actions';
import { FolderDialog } from './folder-dialog';
import { DumpsterContentDialog } from './dumpster-content-dialog';
import { Text } from 'preact-i18n';
import { types as apiClientTypes } from '@zimbra/api-client';
import style from './style';
import { itemActionVariables } from '../../hooks/action/utils';
import { useDumpsterSearchQuery, useDumpsterActionMutation } from '../../hooks/dumpster';
import { getSelectedMsgIDsThunk } from './thunk';

const { ActionOps } = apiClientTypes;
const SCROLL_BUFFER = 500;

export const Dumpster = ({ onClose }) => {
	const sortBy = useSelector(state => get(state, 'dumpster.sortBy'));
	const dispatch = useDispatch();

	const [showFolderDialog, setFolderDialogVisibility] = useState(false);
	const [selectedFolder, setSelectedFolder] = useState();
	const [searchQuery, setSearchQuery] = useState();
	const [isNextDisable, setNextDisable] = useState(true);

	const dumpsterItemAction = useDumpsterActionMutation();

	const { items, loading, more } = useDumpsterSearchQuery(sortBy, searchQuery);

	const handleSelectionChange = useCallback(
		selected => {
			setNextDisable(false);
			dispatch(updateSelection(selected));
		},
		[dispatch]
	);

	const handleSearchSubmit = useCallback(
		query => {
			query !== searchQuery && setSearchQuery(query);
		},
		[searchQuery]
	);

	const toggleFolderDialogVisibility = useCallback(
		() => setFolderDialogVisibility(!showFolderDialog),
		[showFolderDialog]
	);

	const handleNext = useCallback(() => {
		const selectedMsgs = dispatch(getSelectedMsgIDsThunk());
		selectedMsgs && selectedMsgs.length > 0 && toggleFolderDialogVisibility();
	}, [dispatch, toggleFolderDialogVisibility]);

	const handleSelectedFolder = useCallback(folder => setSelectedFolder(folder), []);

	const handleSortBy = useCallback(
		sortByValue => dispatch(setMsgListSortBy(sortByValue)),
		[dispatch]
	);

	const handleListScroll = useCallback(
		e => {
			const offset = e.target.scrollTop;
			const max = e.target.scrollHeight - e.target.offsetHeight;

			if (offset > max - window.innerHeight / 2 - SCROLL_BUFFER && more && !loading) {
				more();
			}
		},
		[loading, more]
	);

	const handleRecoverItems = useCallback(() => {
		const selectedMsgs = dispatch(getSelectedMsgIDsThunk());
		const count = selectedMsgs.length || 1;
		const folder = selectedFolder && selectedFolder.name;

		setFolderDialogVisibility(!showFolderDialog);
		setNextDisable(true);

		dumpsterItemAction({
			variables: itemActionVariables({
				id: selectedMsgs.join(','),
				op: ActionOps.recover,
				folderId: selectedFolder && selectedFolder.id
			}),
			sortBy,
			searchQuery,
			ids: selectedMsgs
		})
			.then(() => {
				dispatch(clearSelection());
				dispatch(
					notify({
						message: (
							<Text id="actions.toasts.move.message" plural={count} fields={{ count, folder }} />
						)
					})
				);
			})
			.finally(() => {
				setNextDisable(false);
			});
	}, [dispatch, selectedFolder, showFolderDialog, dumpsterItemAction, sortBy, searchQuery]);

	useEffect(() => {
		dispatch(clearSelection());
		return () => {
			dispatch(clearSelection());
		};
	}, [dispatch]);

	return [
		<DumpsterContentDialog
			{...(showFolderDialog && { overlayClass: style.hideOverlay })}
			onClose={onClose}
			items={items}
			loading={loading}
			onNext={handleNext}
			onSelectionChange={handleSelectionChange}
			sortBy={sortBy}
			onScroll={handleListScroll}
			handleSortBy={handleSortBy}
			onSearch={handleSearchSubmit}
			searchQuery={searchQuery}
			isNextDisable={isNextDisable}
		/>,
		showFolderDialog && (
			<FolderDialog
				onClose={toggleFolderDialogVisibility}
				handleSelectedFolder={handleSelectedFolder}
				handleRecoverItems={handleRecoverItems}
				selectedFolder={selectedFolder}
			/>
		)
	];
};

export default Dumpster;
