import { Text } from 'preact-i18n';
import { USER_ROOT_FOLDER_ID, USER_FOLDER_IDS } from '../../constants';
import { CUSTOM_FOLDERS } from '../../constants/folders';

export const FOLDER_NAME_CHAR_LIMIT = 128;

export const INVALID_FOLDER_NAME_ERRORS = {
	INVALID_NAME: 'invalidNameWarning',
	SPECIAL_CHAR_WARNING: 'specialCharWarning',
	LOCAL_FOLDER_SPECIAL_CHAR_WARNING: 'localSpecialCharWarning',
	LENGTH_EXCEED_WARNING: 'lengthExceedWarning'
};

export function getFolderNameValidationStatus(name, isLocalFolder = false, isTag = false) {
	const status = {
		isValid: true
	};
	const validateCharacter = isLocalFolder
		? !name.match(/^[A-Za-z0-9_.\s]+$/)
		: isTag
		? name.match(/[:"\\/]/)
		: name.match(/[:"/]/);
	if (!name.trim().length) {
		status.isValid = false;
		status.notifyMessageID = INVALID_FOLDER_NAME_ERRORS.INVALID_NAME;
	} else if (validateCharacter) {
		status.isValid = false;
		status.notifyMessageID = isLocalFolder
			? INVALID_FOLDER_NAME_ERRORS.LOCAL_FOLDER_SPECIAL_CHAR_WARNING
			: INVALID_FOLDER_NAME_ERRORS.SPECIAL_CHAR_WARNING;
	} else if (name.length > FOLDER_NAME_CHAR_LIMIT) {
		status.isValid = false;
		status.notifyMessageID = INVALID_FOLDER_NAME_ERRORS.LENGTH_EXCEED_WARNING;
	}

	return status;
}

export function isSystemFolder({ deletable, parentFolderId, name }) {
	return (
		deletable === false || (CUSTOM_FOLDERS.includes(name) && parentFolderId === USER_ROOT_FOLDER_ID)
	);
}

export function getTranslatedFolderName(folder, folderNameProp) {
	let name = folder.oname || folder.name || folder.fileAsStr;

	if (folderNameProp) {
		name = typeof folderNameProp === 'function' ? folderNameProp(folder) : folder[folderNameProp];
	}

	const isSystemFolderFlag = isSystemFolder(folder);

	return isSystemFolderFlag ? <Text id={`folders.${name.replace(/ /g, '_')}`}>{name}</Text> : name;
}

export const updateFoldersPathRecursively = ({ folder, updatedFolderPath, currentFolderPath }) => {
	const { folders, absFolderPath } = folder;

	return {
		...folder,
		absFolderPath: absFolderPath.replace(currentFolderPath, updatedFolderPath),
		...(folders && {
			folders: folders.map(f =>
				updateFoldersPathRecursively({ folder: f, updatedFolderPath, currentFolderPath })
			)
		})
	};
};

export const checkIfFolderDroppable = ({
	destFolder: {
		permissions: destFolderPermissions,
		id: destFolderId,
		parentFolderId: destFolderParentFolderId,
		folders: destFolderSubFolders,
		absFolderPath: destFolderAbsFolderPath,
		local: destFolderIsLocal
	},
	sourceFolder: {
		id: sourceFolderId,
		parentFolderId: sourceFolderParentFolderId,
		name: sourceFolderName,
		absFolderPath: sourceFolderAbsFolderPath,
		local: sourceFolderIsLocal
	}
}) => {
	if (
		destFolderIsLocal !== sourceFolderIsLocal ||
		destFolderPermissions ||
		// to check if the destination is Draft
		Number(destFolderId) === USER_FOLDER_IDS.DRAFTS ||
		// to check if the destination is Junk
		Number(destFolderId) === USER_FOLDER_IDS.JUNK ||
		// to check if source and destination are same
		destFolderId === sourceFolderId ||
		// is for avoiding folder to be dropped in its child folders
		(destFolderAbsFolderPath.includes(sourceFolderAbsFolderPath) &&
			// to check condtion like /Inbox/abc (destinationFolder)
			// && /abc (sourceFolder which is at root level) which makes first condtion true
			destFolderAbsFolderPath.match(/[^/""]+/g)[0] ===
				sourceFolderAbsFolderPath.match(/[^/""]+/g)[0] &&
			// to check conditions like /Inbox/abc/root12 (destinationFolder)
			// & /Inbox/abc/root1 (sourceFolder) here root1 should be droppable
			destFolderParentFolderId !== sourceFolderParentFolderId) ||
		// to check if the user tries to drop folder into its parentFolder
		sourceFolderParentFolderId === destFolderId
	) {
		return false;
	} else if (
		//if destination folder is Trash allowing user to drops
		//folder in trash as trash will handle duplicate name folders
		!(Number(destFolderId) === USER_FOLDER_IDS.TRASH) &&
		destFolderSubFolders
	) {
		// this condition is to check if the destination Folder has first level subFolders
		// with same name has sourceFolder
		return !destFolderSubFolders.some(({ name }) => name === sourceFolderName);
	}
	return true;
};
