import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'preact/hooks';

import FormGroup from '../../../form-group';
import TextInput from '../../../text-input';
import { getParsedAppointmentData } from '../../../../store/calendar/selectors';
import { setEvent } from '../../../../store/calendar/actions';
import { updateTabThunk } from '../../../../utils/thunk';

export const AppointmentTitle = ({ placeholderTitle, tabId, isProposeTime }) => {
	const dispatch = useDispatch();
	const event = useSelector(state => getParsedAppointmentData(state, 'event', tabId));
	const { name } = event;

	const handleEventNameChange = useCallback(
		({ target: { value } }) => {
			dispatch(
				setEvent({
					tabId,
					eventData: {
						event: {
							...event,
							name: value
						},
						isFormDirty: true
					}
				})
			);
		},
		[dispatch, event, tabId]
	);

	const handleEventNameFocusChange = useCallback(
		({ target: { value } }) => {
			dispatch(
				updateTabThunk({
					tabId,
					title: value
				})
			);
		},
		[dispatch, tabId]
	);

	return (
		<FormGroup>
			<TextInput
				placeholderId={placeholderTitle}
				value={name}
				onInput={handleEventNameChange}
				wide
				autofocus
				disabled={isProposeTime}
				onBlur={handleEventNameFocusChange}
			/>
		</FormGroup>
	);
};

AppointmentTitle.defaultProps = {
	placeholderTitle: 'calendar.editModal.title'
};
