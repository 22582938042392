import { Fragment } from 'preact';
import { createPortal, PureComponent } from 'preact/compat';
import { ContainerSize } from '@zimbra/blocks';
import { empty } from '../../lib/util';
import { screenSmMax, minWidth, screenMd } from '../../constants/breakpoints';
import get from 'lodash-es/get';
import cx from 'classnames';
import AppNavigation from '../app-navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sidebarActionCreators from '../../store/sidebar/actions';
import HeaderActions from '../header-actions';
import style from './style';
import ClientLogo from '../client-logo';
import withAccountInfo from '../../graphql-decorators/account-info';
import withPreference from '../../graphql-decorators/preferences/get-preferences';
import {
	getMailboxMetadata,
	withSetMailboxMetaData
} from '../../graphql-decorators/mailbox-metadata';
import PreviewResizeControl from '../preview-resize-control';
import {
	FOLDER_TREE_SASH_MIN_VALUE,
	FOLDER_TREE_SASH_MAX_VALUE,
	FOLDER_TREE_SASH_MIN_VALUE_WITH_AD_ZIMLET
} from '../../constants/mailbox-metadata';
import ZimletSlot from '../zimlet-slot';
import withMediaQuery from '../../enhancers/with-media-query';
import OfflineLabel from '../offline-label';
import TabNavigation from '../app-navigation/tab-navigation';
import clientConfiguration from '../../enhancers/client-config';

@connect(
	({ sidebar, url, email = {} }) => ({
		url: url.location.pathname,
		view: url.view,
		account: email.account,
		username: get(email, 'account.name'),
		displayName: get(email, 'account.attrs.displayName'),
		sidebar: sidebar.visible
	}),
	dispatch => bindActionCreators(sidebarActionCreators, dispatch)
)
@withAccountInfo(({ data: { accountInfo } }) => ({
	zimbraFeatureCalendarEnabled: get(accountInfo, 'attrs.zimbraFeatureCalendarEnabled')
}))
@withPreference(({ data: { getPreferences } }) => ({
	zimbraPrefLocale: get(getPreferences, 'zimbraPrefLocale')
}))
@getMailboxMetadata()
@withSetMailboxMetaData()
@withMediaQuery(minWidth(screenMd), 'desktopView')
@clientConfiguration({ slugs: 'routes.slugs' })
export default class Sidebar extends PureComponent {
	state = {
		width: window.innerWidth,
		minSidebarWidth: FOLDER_TREE_SASH_MIN_VALUE,
		modifiedFolderTreeSash: FOLDER_TREE_SASH_MIN_VALUE
	};

	onResize = ({ width }) => {
		this.setState({ width });
	};

	closeSidebar = () => {
		this.props.hide();
	};

	handleResizeStart = () => {
		const { mailboxMetadata } = this.props;
		const { modifiedFolderTreeSash, minSidebarWidth } = this.state;
		const folderTreeSashDragStart =
			modifiedFolderTreeSash || mailboxMetadata.zimbraPrefFolderTreeSash || minSidebarWidth;

		this.setState({ folderTreeSashDragStart });
	};

	handleResize = offset => {
		const { folderTreeSashDragStart, minSidebarWidth } = this.state;
		const updatedFolderTreeSash = folderTreeSashDragStart + offset;

		if (
			updatedFolderTreeSash >= minSidebarWidth &&
			updatedFolderTreeSash <= FOLDER_TREE_SASH_MAX_VALUE
		) {
			this.setState({
				modifiedFolderTreeSash: updatedFolderTreeSash
			});
		}
	};

	handleResizeEnd = () => {
		this.props.setMailboxMetadata({
			zimbraPrefFolderTreeSash: this.state.modifiedFolderTreeSash
		});
	};

	updateMinWidth = () => {
		const isActive = this.context.zimlets.isSlotActive('slot::adSlot-left-300-250');
		this.setState(({ minSidebarWidth }) => ({
			minSidebarWidth: isActive ? FOLDER_TREE_SASH_MIN_VALUE_WITH_AD_ZIMLET : minSidebarWidth,
			modifiedFolderTreeSash: get(this.props, 'mailboxMetadata.zimbraPrefFolderTreeSash', 0)
		}));
	};

	componentWillMount() {
		this.updateMinWidth();
	}

	componentDidMount() {
		this.context.zimlets.on('zimlets-initialized', () => {
			this.updateMinWidth();
		});
	}

	// @TODO sidebar would be better triggered via a querystring param
	// for mobile back button support
	componentWillReceiveProps({ url, sidebar }) {
		if (url !== this.props.url && this.props.sidebar && sidebar) {
			this.closeSidebar();
		}
	}

	componentWillUnmount() {
		this.closeSidebar();
	}

	render(
		{
			header,
			footer,
			children,
			username,
			displayName,
			sidebar,
			modal,
			show,
			hide,
			zimbraPrefLocale,
			mailboxMetadata,
			zimbraFeatureCalendarEnabled,
			into,
			view,
			desktopView,
			slugs,
			...props
		},
		{ modifiedFolderTreeSash, width, minSidebarWidth }
	) {
		displayName = displayName || String(username || '').replace(/@.+$/, '');

		const sidebarWidth =
			modifiedFolderTreeSash > minSidebarWidth ? modifiedFolderTreeSash : minSidebarWidth;

		// Modal for [0, @screen-md)

		if (typeof modal !== 'boolean') {
			modal = width <= screenSmMax;
		}

		let content = (
			<div
				class={cx(
					style.sidebar,
					modal && style.modal,
					sidebar && style.showing,
					props.class,
					modal && props.modalClass,
					!modal && props.inlineClass
				)}
				style={!modal && { width: `${sidebarWidth}px` }}
			>
				<div class={style.backdrop} onClick={hide} />
				<div class={style.inner}>
					{header && (
						<div>
							<div class={style.header}>
								<div class={style.sidebarLogo}>
									<ClientLogo />
								</div>
								<HeaderActions
									closeSidebar={this.closeSidebar}
									zimbraPrefLocale={zimbraPrefLocale}
								/>
							</div>
							<AppNavigation class={style.mobileAppNav} onItemClick={this.closeSidebar} />
						</div>
					)}
					{!empty(footer) ? footer : null}
					{!desktopView && <TabNavigation sidebar />}
					<div class={style.content}>{children}</div>
					{!desktopView && (
						<Fragment>
							{view === slugs.integrations && <ZimletSlot props class={style.slot} name="menu" />}
							<ZimletSlot props class={style.slot} name={`${view}-tab-item`} />
							<OfflineLabel />
						</Fragment>
					)}
				</div>
				{!modal && (
					<div class={style.resizeControl}>
						<PreviewResizeControl
							onDragStart={this.handleResizeStart}
							onDrag={this.handleResize}
							onDragEnd={this.handleResizeEnd}
							horizontalResizer
						/>
					</div>
				)}
			</div>
		);

		if (modal) {
			content = createPortal(content, document.querySelector(into || 'body'));
		}

		content = (
			<ContainerSize
				class={cx(style.wrap, !modal && style.inline)}
				onBeforeResize={this.onResize}
				defer
			>
				{content}
			</ContainerSize>
		);

		return content;
	}
}
