import { Button, Popover, Spinner } from '@zimbra/blocks';
import { useBriefcaseFoldersQuery, useSaveDocumentMutation } from '../../hooks/briefcase';
import { useCallback, useState } from 'preact/hooks';
import CloseButton from '../close-button';
import FolderListLight from '../folder-list-light';
import { Text } from 'preact-i18n';
import { USER_FOLDER_IDS } from '../../constants';
import get from 'lodash-es/get';
import { notify } from '../../store/notifications/actions';
import { saveDocumentVariables, getDocument } from '../../hooks/briefcase/utils';
import style from './style';
import { useDispatch } from 'react-redux';
import { getFolder, getFolderNameFunction, getFoldersAndSharedFolders } from '../../utils/folders';
import { gql } from '@apollo/client';

function isFolderDisabled({ droppable }) {
	return droppable;
}

export const AddToBriefcase = ({ active, onClose, attachment }, { client }) => {
	const dispatch = useDispatch();
	const { data: foldersData, loading } = useBriefcaseFoldersQuery();
	const { folders, sharedFolders } = getFoldersAndSharedFolders(foldersData);
	const [{ selectedFolderName, selectedFolderID }, updateFolder] = useState({});
	const [saveDocument] = useSaveDocumentMutation();

	const handleFolderClick = useCallback(
		({ absFolderPath, id }) => {
			const folderName = absFolderPath.replace(/^\//g, '');

			if (selectedFolderID !== id) {
				updateFolder({ selectedFolderName: folderName, selectedFolderID: id });
			}
		},
		[selectedFolderID]
	);

	const handleAttachToBriefcase = useCallback(() => {
		const { filename, messageId: id, part } = attachment;

		getDocument(client, selectedFolderName, filename).then(({ data: { search } }) => {
			const documents = get(search, 'documents') || [];
			const selectedFolder = getFolder(
				[...folders, ...sharedFolders],
				getFolderNameFunction(selectedFolderName)
			);
			let messageId = id;

			client
				.query({
					query: gql`
						query {
							accountInfo {
								id
							}
						}
					`
				})
				.then(({ data }) => {
					const accountId = get(data, 'accountInfo.id');

					if (selectedFolder && selectedFolder.ownerZimbraId) {
						messageId = `${accountId}:${messageId}`;
					}

					saveDocument({
						variables: saveDocumentVariables({
							messageId,
							attachmentPart: part,
							filename,
							...((documents.length > 0 && {
								documentId: documents[0].id,
								version: documents[0].version
							}) || { folderId: selectedFolderID })
						})
					})
						.then(() =>
							dispatch(
								notify({
									message: <Text id="briefcase.attachToBriefcase.uploadSuccess" />
								})
							)
						)
						.catch(() =>
							dispatch(
								notify({
									message: <Text id="briefcase.attachToBriefcase.uploadFailed" />,
									failure: true
								})
							)
						);
					onClose && onClose(true);
				});
		});
	}, [
		attachment,
		client,
		dispatch,
		folders,
		onClose,
		saveDocument,
		selectedFolderID,
		selectedFolderName,
		sharedFolders
	]);

	return (
		<Popover
			title={<Text id="briefcase.attachToBriefcase.dialogTitle" />}
			placement="top"
			anchor="start"
			active={active}
		>
			<div class={style.addToBriefcaseModal}>
				<div class={style.header}>
					<Text id="briefcase.attachToBriefcase.dialogTitle" />
					<CloseButton class={style.closeButton} onClick={onClose} />
				</div>
				<div class={style.content}>
					{loading ? (
						<Spinner block />
					) : (
						<FolderListLight
							folders={folders.filter(({ id }) => USER_FOLDER_IDS.TRASH !== Number(id))}
							onFolderClick={handleFolderClick}
							isFolderDisabled={isFolderDisabled}
							sharedFolders={sharedFolders}
							collapsibleCustomGroup
						/>
					)}
				</div>
				<div class={style.footer}>
					<Button
						type="submit"
						class={style.button}
						disabled={!selectedFolderID}
						styleType="primary"
						brand="primary"
						onClick={handleAttachToBriefcase}
					>
						<Text id="buttons.save" />
					</Button>
					<Button type="button" class={style.button} onClick={onClose}>
						<Text id="buttons.cancel" />
					</Button>
				</div>
			</div>
		</Popover>
	);
};
