let smimeHandler;

if (typeof process.env.ELECTRON_ENV !== 'undefined') {
	const EncodeAction = require('./actions/encode');
	const DecodeAction = require('./actions/decode');
	const GetIdentityAction = require('./actions/get-identity');
	const GetInfoAction = require('./actions/get-info');
	const GetCertificateAction = require('./actions/get-certificate');

	const { IS_MAC, IS_WIN } = require('./constants');

	smimeHandler = function ({ operation, ...params }) {
		if (!IS_MAC && !IS_WIN) {
			console.error('SMIME is only available for mac and windows os');
			return;
		}

		let action;
		switch (operation) {
			case 'get-ident':
				// Input: email, usage
				// Output: isTrusted, certificate, certData (base64 format), isExpired
				action = new GetIdentityAction(params);
				break;
			case 'get-cert':
				// Input: certData (base64 format)
				// Output: certificate, isExpired
				action = new GetCertificateAction(params);
				break;
			case 'encode':
				// Input: certificates, data, sign, encrypt
				// Output: signed/encrypted mime, base64 signed/encrypted mime
				action = new EncodeAction(params);
				break;
			case 'decode':
				// Input: data
				// Output: decrypted/verified mime
				action = new DecodeAction(params);
				break;
			case 'get-info':
				// Output: name, platform, digest, isfips
				action = new GetInfoAction(params);
				break;
			case 'read-file':
				break;
			case 'save-file':
				break;
		}

		return action && action.execute();
	};
}

module.exports = smimeHandler;
