import { Fragment } from 'preact';
import { Icon } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import style from './style.less';
import cx from 'classnames';
import { APPOINTMENT_VIEW, CONTACTS_VIEW, BRIEFCASE_VIEW } from '../../../../constants/views';
import SharingOptions from './../options';

export function ItemField({ field, value }) {
	return (
		<div class={style.sharingSubsection}>
			<div class={style.subsectionTitle}>{field}</div>
			{value}
		</div>
	);
}

function GetIcon({ viewType }) {
	switch (viewType) {
		case APPOINTMENT_VIEW:
			return (
				<Fragment>
					<span class={style.calIconText}>{new Date().getDate()}</span>
					<Icon name="calendar-o" />
				</Fragment>
			);
		case CONTACTS_VIEW:
			return <Icon name="address-book" />;
		case BRIEFCASE_VIEW:
			return <Icon name="briefcase" />;
		default:
			return <Icon name="envelope" />;
	}
}

export function HeaderField({ name, viewType, onToggleDetails, matchesScreenMd }) {
	const fieldElement = (
		<div class={style.headerKey}>
			<div class={cx(!matchesScreenMd && style.name)} onClick={onToggleDetails}>
				{name}
			</div>
			{!matchesScreenMd && <SharingOptions onToggleDetails={onToggleDetails} />}
		</div>
	);

	const valueElement = (
		<div class={style.headerBody}>
			<div class={style.viewTypeIcon}>
				<GetIcon viewType={viewType} />
				<span class={style.viewTypeText}>
					<Text id={`settings.sharing.view.${viewType === 'unknown' ? 'account' : viewType}`} />
				</span>
			</div>
			{matchesScreenMd && <SharingOptions onToggleDetails={onToggleDetails} />}
		</div>
	);

	return <ItemField field={fieldElement} value={valueElement} />;
}
