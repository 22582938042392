import get from 'lodash-es/get';
import { graphql } from '@apollo/client/react/hoc';
import ContactActionMutation from '../../graphql/queries/contacts/contact-action.graphql';
import SearchQuery from '../../graphql/queries/search/search.graphql';
import AutoCompleteQuery from '../../graphql/queries/contacts/auto-complete.graphql';
import GetContact from '../../graphql/queries/contacts/get-contact.graphql';
import {
	getAllContactSearchQueryVariables,
	getAllContactListSearchQueryVariables,
	getAutoSuggestionQueryVariables,
	getAllGetContactQueryVariables
} from '../../graphql/utils/graphql-variables';
import { optimisticUpdateContactsTag } from '../../graphql/utils/graphql-optimistic';
import contactFragment from '../../graphql/fragments/contact.graphql';
import { types as apiClientTypes } from '@zimbra/api-client';
import update from 'immutability-helper';
const { ActionOps } = apiClientTypes;

/**
 * This function adds or removes to/from existing tags.
 * Since inbound tags are in the form of string having comma separated value,
 * it needs to be converted into array first to make modifications easy.
 */
function updateTags({ proxy, fragment, fragmentName, item, tagToAdd, tagToRemove }) {
	const { id, tags, __typename } = item;
	let convertedTags = tags ? tags.split(',') : [];

	if (tagToAdd) {
		convertedTags.indexOf(tagToAdd.id) === -1 && convertedTags.push(tagToAdd.id);
	} else if (tagToRemove) {
		convertedTags = convertedTags.filter(tagId => tagId !== tagToRemove.id);
		optimisticUpdateContactsTag(proxy, tagToRemove.name, id);
	}
	proxy.writeFragment({
		id: `${__typename}:${id}`,
		fragment,
		fragmentName,
		data: {
			...item,
			__typename,
			id,
			tags: convertedTags.join(',') // Convert from array to string having comma separated values
		}
	});
}

export default function withContactAction() {
	return graphql(ContactActionMutation, {
		props: ({ mutate, result: { client } }) => ({
			contactAction: ({ removeFromList, local, tagToAdd, tagToRemove, view, ...variables }) => {
				const ids = (typeof variables.id !== 'undefined' && [variables.id]) || variables.ids || [];

				return mutate({
					variables,
					refetchQueries: () => {
						// NOTE: This will refetch queries that are no longer valid (e.g. refetching a folder that has moved)
						//       This causes a "NO_SUCH_FOLDER" error but it is not causing issues in the UI.
						//       This should be optimized to only refetch folders/lists/contacts that were changed by the action.
						const refetchQueryList = [
							...getAllContactSearchQueryVariables(client.cache).map(cacheVars => ({
								query: SearchQuery,
								variables: cacheVars
							})),
							...getAllContactListSearchQueryVariables(client.cache).map(cacheVars => ({
								query: SearchQuery,
								variables: cacheVars
							})),
							...getAutoSuggestionQueryVariables(client.cache).map(cacheVars => ({
								query: AutoCompleteQuery,
								variables: cacheVars
							})),
							...getAllGetContactQueryVariables(client.cache).map(cacheVars => ({
								query: GetContact,
								variables: cacheVars
							}))
						];

						return refetchQueryList;
					},
					optimisticResponse: {
						contactAction: {
							action: {
								id: ids.join(','),
								op: variables.op,
								__typename: 'ActionOpResponse'
							},
							__typename: 'Mutation'
						}
					},
					update: proxy => {
						// If it's one of the Tag operations, update tags
						if ([ActionOps.tag, ActionOps.untag].indexOf(variables.op) !== -1) {
							ids
								.map(id =>
									proxy.readFragment({
										id: `Contact:${id}`,
										fragment: contactFragment,
										fragmentName: 'contactFields'
									})
								)
								.forEach(item => {
									if (!item) return;

									// Write new tags to cache
									updateTags({
										proxy,
										fragment: contactFragment,
										fragmentName: 'contactFields',
										item,
										tagToAdd,
										tagToRemove,
										view
									});
								});
						}

						if ([ActionOps.move, ActionOps.delete].indexOf(variables.op) !== -1) {
							const queryVariables = getAllContactSearchQueryVariables(client.cache).map(
								cacheVars => ({
									query: SearchQuery,
									variables: cacheVars
								})
							);

							queryVariables.forEach(queryVariable => {
								let data;
								try {
									data = proxy.readQuery({
										query: SearchQuery,
										variables: queryVariable.variables
									});
								} catch (e) {
									return;
								}

								const prevContacts = data.search.contacts;
								if (!prevContacts?.length) return;

								ids.forEach(id => {
									const movedContactIndex = data.search.contacts.findIndex(
										contact => contact.id === id
									);
									if (movedContactIndex === -1) return;

									data = update(data, {
										search: {
											contacts: {
												$splice: [[movedContactIndex, 1]]
											}
										}
									});
								});

								try {
									proxy.writeQuery({
										query: SearchQuery,
										variables: queryVariable.variables,
										data
									});
								} catch (e) {}
							});
						}
					},

					updateQueries: {
						search: (prevResult, data) => {
							if (removeFromList) {
								// If Tag matches with the current query, then only remove from contact list
								const isTagQuery =
									data.queryVariables.query.indexOf(`(tag:"${variables.tagNames}")`) >= 0;

								if (isTagQuery && (get(prevResult, 'search.contacts') || []).length) {
									// Remove the item from list if the options include `removeFromList`
									prevResult.search.contacts = prevResult.search.contacts.filter(
										result => !~ids.indexOf(result.id)
									);
								}
							}
							return prevResult;
						}
					}
				});
			}
		})
	});
}
