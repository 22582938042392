import { Component } from 'preact';
import { Text, withText } from 'preact-i18n';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import ModalDialog from './../modal-dialog';
import style from './style';
import { notify } from './../../store/notifications/actions';
import { Button } from '@zimbra/blocks';
import { importFile } from '../../lib/import';
import ErrorAlert from './../error-alert';
import { updateImportSettingState } from './../../store/account-settings/actions';
import withTracking from '../../enhancers/tracking';

@withTracking()
@withText({
	errorFileFormat: 'importModal.errorFileFormat',
	errorImportUnknown: 'importModal.errorImportUnkown'
})
@connect(
	store => ({
		username: get(store, 'email.account.name'),
		importSettingsState: get(store, 'importModal.importSettingsState')
	}),
	{
		notify,
		updateImportSettingState
	}
)
export default class ImportModal extends Component {
	chooseFile = () => {
		const target = this.fileInput;
		const { errorFileFormat } = this.props;
		if (target) {
			const file = target.files[0];
			const format = file.name.match(/\.(\w+)$/)[1];
			this.setState({
				file,
				loading: false,
				error: format !== 'tgz' ? errorFileFormat : ''
			});
		}
	};

	clickFileInput = () => this.fileInput.click();
	stopPropagation = ev => ev.stopPropagation();

	handleImport = () => {
		const { file, error } = this.state;
		if (error) return;

		const {
			username,
			errorImportUnknown,
			folder,
			notify: notifyAction,
			refetchFolders,
			onClose,
			updateImportSettingState: updateImportState,
			importLoadingState,
			tracker
		} = this.props;

		this.setState({ loading: true });
		importLoadingState &&
			updateImportState(true) &&
			notifyAction({
				message: <Text id="actions.toasts.import.importStart" />
			});

		tracker.setUserAction({ action: 'settings-account-import' });

		importFile(
			file,
			'tgz',
			encodeURIComponent(username),
			importLoadingState
				? ''
				: folder.absFolderPath && encodeURIComponent(folder.absFolderPath.replace(/^\/|\/$/g, '')),
			importLoadingState ? '' : encodeURIComponent('message,conversation'),
			this.context.zimbraBatchClient
		)
			.then(() => {
				this.updateFileInputValue();
				importLoadingState ? updateImportState(false) : refetchFolders();
				notifyAction({
					message: <Text id="actions.toasts.import.importComplete" />
				});
				this.setState({ loading: false, file: '' }, onClose);
			})
			.catch(err => {
				this.updateFileInputValue();
				console.error(err);
				const errorMessage = get(err, 'message');
				this.setState({
					error: errorMessage ? errorMessage : errorImportUnknown,
					loading: false,
					file: ''
				});
			});
	};

	updateFileInputValue() {
		if (this.fileInput) {
			this.fileInput.value = '';
		}
	}

	componentDidMount() {
		/* During importing user can traverse on any other option of settings and app and if again comeback on account's import option the file name should persist there like legacy. That's why calling handleImportedFile here. */
		const { importSettingsState } = this.props;
		if (importSettingsState) {
			this.chooseFile();
		} else {
			this.updateFileInputValue();
		}
	}

	componentWillReceiveProps({ importSettingsState }) {
		if (!importSettingsState && importSettingsState !== this.props.importSettingsState) {
			this.updateFileInputValue();
			this.setState({ file: '' });
		}
	}

	renderSettingsImport = () => {
		const { file, error } = this.state;
		const { importSettingsState, isCalendarEnabled } = this.props;
		return (
			<div class={style.importSettingSection}>
				<div class={style.sectionTitle}>
					<Text id="settings.accounts.import.title" />
				</div>
				<div class={style.importDescription}>
					<Text
						id={`settings.accounts.import.description.${
							isCalendarEnabled ? 'default' : 'calDisabled'
						}`}
					/>
				</div>
				{error && (
					<div>
						<ErrorAlert>{error}</ErrorAlert>
					</div>
				)}
				<input
					ref={input => (this.fileInput = input)}
					type="file"
					hidden="hidden"
					onChange={this.chooseFile}
					onClick={this.stopPropagation}
				/>
				<Button
					styleType="default"
					onClick={this.clickFileInput}
					disabled={importSettingsState}
					class={style.chooseFileWrapper}
				>
					<Text id="importModal.chooseFile" />
				</Button>
				{file && <span>{file.name}</span>}
				<div>
					<Button
						class={style.button}
						styleType="primary"
						brand="primary"
						onClick={this.handleImport}
						disabled={error || !file || importSettingsState}
					>
						<Text id="buttons.import" />
					</Button>
				</div>
			</div>
		);
	};

	render({ onClose, importLoadingState }, { file, loading, error }) {
		return importLoadingState ? (
			this.renderSettingsImport()
		) : (
			<ModalDialog
				title="importModal.dialogTitle"
				actionLabel="buttons.import"
				onAction={this.handleImport}
				onClose={onClose}
				disablePrimary={!file || file.length === 0 || error}
				pending={loading}
				error={error}
			>
				<p>
					<Text id="importModal.promptText" />
				</p>
				<input
					ref={input => (this.fileInput = input)}
					type="file"
					hidden="hidden"
					onChange={this.chooseFile}
					onClick={this.stopPropagation}
				/>
				<div>
					<Button styleType="default" onClick={this.clickFileInput} class={style.chooseFileWrapper}>
						<Text id="importModal.chooseFile" />
					</Button>
					{file && <span>{file.name}</span>}
				</div>
			</ModalDialog>
		);
	}
}
