export default {
	imap: {
		crypt: '993',
		nocrypt: '143'
	},
	pop3: {
		crypt: '995',
		nocrypt: '110'
	}
};
