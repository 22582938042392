import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';

const initialState = {
	selectedDate: null,
	height: 150
};

export default handleActions(
	{
		[actionCreators.updateDateSelection]: (state, action) => {
			return {
				...state,
				selectedDate: action.payload
			};
		},
		[actionCreators.updateHeight]: (state, action) => {
			return {
				...state,
				height: action.payload
			};
		}
	},
	initialState
);
