import { graphql } from '@apollo/client/react/hoc';
import GetDistributionListMembers from '../../graphql/queries/contacts/get-dl-members.graphql';

export default function withGetDlMembers(_config = {}) {
	return graphql(GetDistributionListMembers, {
		..._config,
		props: ({ data: { getDistributionListMembers, loading } }) => {
			const contactGroupMembers = (getDistributionListMembers || []).map(member => {
				member = { ...member, ...member.attributes };
				const id = `contact::${member.commonName}::${member.name}`,
					fullName = `${member.commonName}, ${member.name.split('@')[0]}`,
					parts = fullName.split(' ');
				return {
					id,
					attributes: {
						email: member.name,
						fullName,
						firstName: parts[0],
						lastName: parts.slice(1).join(' '),
						type: 'contact'
					}
				};
			});

			return {
				contacts: contactGroupMembers,
				loading
			};
		}
	});
}
