import s from './style.less';
import moment from 'moment';
import { ROUTE_EVENT, ROUTE_DETAILS } from '../calendar/constants';
import { callWith } from '../../lib/util';

export function MiniCalendarEvents({
	event,
	calendarUrlSlug,
	handleNewTab,
	closeEventTabThunkAction
}) {
	const handleEventDetails = ({ inviteId, instances, duration, start, end, name }) => {
		const instance = instances
			? instances[0]
			: {
					start: moment(start).valueOf(),
					end: moment(end).valueOf()
			  };

		handleNewTab(
			{
				url: `/${calendarUrlSlug}/${ROUTE_EVENT}/${ROUTE_DETAILS}/${inviteId}${`?utcRecurrenceId=${instance.utcRecurrenceId}`}&start=${
					instance.start
				}&end=${instance.start + duration}`,
				title: name,
				type: 'event',
				subType: 'details',
				id: inviteId,
				onClose: closeEventTabThunkAction
			},
			calendarUrlSlug
		);
	};
	return (
		<div class={s.miniCalEvents} onClick={callWith(handleEventDetails, event)}>
			{event.name}
		</div>
	);
}
