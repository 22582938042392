import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { getNonSharedActiveCalendars } from '../../utils/calendar';
import RemindersQuery from '../../graphql/queries/calendar/reminders.graphql';
const PAST_DAYS = 7;
const FUTURE_DAYS = 14;

export function withReminders(mapProps = props => props, config = {}) {
	const { options = {} } = config;

	return graphql(RemindersQuery, {
		skip: ({ calendars, isOffline }) =>
			isOffline || getNonSharedActiveCalendars(calendars).length === 0,
		options:
			typeof options === 'function'
				? options
				: ({ calendars }) => {
						const { fetchPolicy = 'cache-first', variables = {} } = options;

						// taking the time from start of the day, i.e. at 12 midnight
						// so that the query from the cache can be read instead of sending a new request
						const currentDate = moment().startOf('day');
						return {
							fetchPolicy,
							variables: {
								calExpandInstStart: currentDate.subtract(PAST_DAYS, 'day').valueOf(),
								calExpandInstEnd: currentDate.add(FUTURE_DAYS, 'day').valueOf(),
								query: getNonSharedActiveCalendars(calendars)
									.map(({ id }) => `inid:"${id}"`)
									.join(' OR '),
								...variables
							}
						};
				  },
		props: result => mapProps(result)
	});
}
