import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import get from 'lodash-es/get';
import { zimbraFormat } from '../../utils/calendar';
import CounterAppointmentMutation from '../../graphql/mutations/counter-appointment.graphql';
import DeclineCounterAppointmentMutation from '../../graphql/mutations/decline-counter-appointment.graphql';
import { plainTextCounterInviteEmail } from '../../components/calendar/emails/plain-text-invite-email';
import { htmlInviteEmail } from '../../components/calendar/emails/html-invite-email';
import { withIntlConsumer } from '../../enhancers/with-intl-consumer';

function counterAppointmentMutationVariables({
	message,
	proposedTime,
	declineProposedTime,
	event,
	notes,
	eventStartTimeZone,
	eventEndTimeZone,
	eventInstance,
	intl
}) {
	if (message) {
		const appointment = get(message, 'invitations.0.components.0');
		const toAddress = get(message, 'from.0.address');
		const {
			organizer,
			allDay,
			componentNum,
			name,
			uid,
			calendarItemId,
			start: appointmentStart,
			end: appointmentEnd,
			location
		} = appointment;
		const { modifiedSequence, revision } = message;
		const organizerAddress = get(organizer, 'address');
		const organizerName = get(organizer, 'name');
		const organizerSentby = get(organizer, 'sentBy');
		const organizerObject = {
			address: organizerAddress,
			name: organizerName,
			sentBy: organizerSentby
		};
		const start = proposedTime ? event.start : appointmentStart;
		const end = proposedTime ? event.end : appointmentEnd;
		const startVal = proposedTime
			? {
					date: zimbraFormat(start, allDay),
					...(!allDay && { timezone: eventStartTimeZone })
			  }
			: {
					date: get(start, '0.date'),
					timezone: get(start, '0.timezone')
			  };
		const endVal = proposedTime
			? allDay && zimbraFormat(end, allDay) === startVal
				? null
				: {
						date: zimbraFormat(end, allDay),
						...(!allDay && { timezone: eventEndTimeZone })
				  }
			: !end
			? null
			: {
					date: get(end, '0.date'),
					timezone: get(end, '0.timezone')
			  };
		const emailAddresses = [];
		proposedTime
			? emailAddresses.push({
					address: organizer.address,
					type: 't'
			  })
			: emailAddresses.push({
					address: toAddress,
					type: 't'
			  });
		const ms = modifiedSequence ? modifiedSequence : revision;

		const modifyFields = calendarItemId
			? {
					id: calendarItemId,
					...(ms && { modifiedSequence: ms }),
					...(revision && { revision })
			  }
			: {};

		const intro = proposedTime
			? get(intl.dictionary, 'calendar.editModal.counterInvite.proposedTimeSubject')
			: get(intl.dictionary, 'calendar.editModal.counterInvite.declineProposedTimeSubject');

		const options = {
			start: proposedTime ? start : get(start, '0.date'),
			end: proposedTime ? end : get(end, '0.date'),
			template: get(intl.dictionary, 'template'),
			intro,
			location,
			subject: name,
			body: notes
		};

		const textPart = plainTextCounterInviteEmail(options);
		const htmlPart = htmlInviteEmail(options);

		const mimeParts = {
			contentType: 'multipart/alternative',
			mimeParts: [
				{
					contentType: 'text/plain',
					content: textPart || ''
				},
				{
					contentType: 'text/html',
					content: htmlPart || ''
				}
			]
		};

		const subject = proposedTime ? `${proposedTime}: ${name}` : `${declineProposedTime}: ${name}`;
		let exceptId;
		if (eventInstance) {
			exceptId = {
				date: eventInstance
			};
		}

		return {
			counterAppointmentInvite: {
				...modifyFields,
				...(componentNum && { componentNum }),
				message: {
					subject,
					invitations: {
						components: [
							{
								name,
								...(allDay && { allDay }),
								organizer: organizerObject,
								...(exceptId && { exceptId }),
								start: startVal,
								end: endVal,
								uid
							}
						]
					},
					emailAddresses,
					mimeParts
				}
			}
		};
	}

	return null;
}

const appointmentMutationFactory = (mutationName, propName) => () =>
	compose(
		withIntlConsumer(),
		graphql(mutationName, {
			props: ({ ownProps: { intl }, mutate }) => ({
				[propName]: ({
					message,
					proposedTime,
					declineProposedTime,
					event,
					notes,
					eventStartTimeZone,
					eventEndTimeZone,
					eventInstance
				}) => {
					const mutationVariables = counterAppointmentMutationVariables({
						message,
						declineProposedTime,
						proposedTime,
						event,
						notes,
						eventStartTimeZone,
						eventEndTimeZone,
						eventInstance,
						intl
					});

					const mutateOptions = {
						variables: mutationVariables
					};

					return mutate(mutateOptions);
				}
			})
		})
	);

export const withCounterAppointment = appointmentMutationFactory(
	CounterAppointmentMutation,
	'counterAppointment'
);
export const withDeclineCounterAppointment = appointmentMutationFactory(
	DeclineCounterAppointmentMutation,
	'declineCounterAppointment'
);
