import { useCallback } from 'preact/hooks';
import { Text } from 'preact-i18n';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash-es/get';
import orderBy from 'lodash-es/orderBy';
import { callWith } from '@zimbra/util/src/call-with';
import ActionMenu, { DropDownWrapper } from '../../action-menu';
import ActionMenuGroup from '../../action-menu-group';
import ActionMenuItem from '../../action-menu-item';
import { setAdvanceOptions } from '../../../store/search/actions';
import { useTagsQuery } from '../../../hooks/tags';
import style from '../style';

export const TagsField = () => {
	const { data } = useTagsQuery({
		fetchPolicy: 'cache-first'
	});
	const tags =
		(get(data, 'getTag') && orderBy(get(data, 'getTag'), [({ name }) => name.toLowerCase()])) || [];

	const dispatch = useDispatch();
	const tagValues = useSelector(state => get(state, 'search.tagValues', []));

	const handleTags = useCallback(
		tag => {
			if (!tag) {
				dispatch(setAdvanceOptions({ tagValues: [] }));
				return;
			}

			let arr = tagValues;
			const index = arr.indexOf(tag);

			if (index === -1) {
				arr = arr.concat(tag);
			} else {
				arr.splice(index, 1);
			}

			dispatch(setAdvanceOptions({ tagValues: [...arr] }));
		},
		[dispatch, tagValues]
	);

	return (
		<div className={style.filterItem}>
			<ActionMenu
				monotone
				anchor="end"
				into={false}
				label={
					<Text
						id="search.advanced.filterFields.tagsLabel"
						plural={tagValues.length}
						fields={{ count: tagValues.length }}
					/>
				}
			>
				<DropDownWrapper>
					<ActionMenuGroup class={style.actionMenu}>
						<ActionMenuItem onClick={callWith(handleTags, false)}>
							<Text id="buttons.clear" />
						</ActionMenuItem>
						{tags.map(({ name }) => (
							<ActionMenuItem
								onClick={callWith(handleTags, name)}
								icon={tagValues.indexOf(name) !== -1 && 'check'}
							>
								{name}
							</ActionMenuItem>
						))}
					</ActionMenuGroup>
				</DropDownWrapper>
			</ActionMenu>
		</div>
	);
};
