import union from 'lodash-es/union';
import { USER_FOLDER_IDS } from '../constants/index';
import { FLAGS } from '../lib/util';
import { DRAFTS, SENT, TRASH, JUNK, INBOX } from '../constants/folders';
import { types as apiClientTypes } from '@zimbra/api-client';
import { LOCAL_FOLDER_IDS } from '@zimbra/electron-app';

const { ActionOps } = apiClientTypes;
//function to get individual flags for a group of selected mails
export function getIndividualFlag(selectedIds, flagOfCurrentMessage, initialIteration = 0) {
	let flags = [];

	if (selectedIds.length > initialIteration) {
		const flagsArray = selectedIds.map(m => m.flags);
		flags = getSelectFlags(flagsArray);
	} else if (flagOfCurrentMessage) {
		// flagOfCurrentMessage can be String or Array
		if (flagOfCurrentMessage.includes(FLAGS.draft)) {
			flags.push(FLAGS.draft);
		} else if (flagOfCurrentMessage.includes(FLAGS.sentByMe)) {
			flags.push(FLAGS.sentByMe);
		}
	}
	return flags;
}

export function getSelectFlags(messageFlags) {
	let flagsArray = [];

	messageFlags.forEach(messageFlag => {
		// Union flags only if message flag is not `undefined`
		if (messageFlag) {
			const individualFlag = [];
			messageFlag.includes(FLAGS.draft) && individualFlag.push(FLAGS.draft);
			// Set `sentByMe` flag only if it is not 'Draft'. As Draft mail always contain "sd" flag.
			messageFlag.includes(FLAGS.sentByMe) &&
				!messageFlag.includes(FLAGS.draft) &&
				individualFlag.push(FLAGS.sentByMe);
			messageFlag.includes(FLAGS.calendarInvite) && individualFlag.push(FLAGS.calendarInvite);
			individualFlag.length > 0 && (flagsArray = union(individualFlag, flagsArray));
		} else {
			// For inbox and other mail items (delegates, shared folder, etc) where flag is null,
			// set '' flag to identify received emails
			flagsArray.push('');
		}
	});
	return flagsArray;
}

export function getReadUnreadFlag(selectedIds) {
	let unreadMail = 0;
	let readMail = 0;
	selectedIds.forEach(({ flags }) => {
		if (flags && flags.indexOf('u') !== -1) {
			unreadMail++;
		} else {
			readMail++;
		}
	});
	if (unreadMail === selectedIds.length) return 'allUnRead';
	if (readMail === selectedIds.length) return 'allRead';
}

export function getAllowedFoldersForMove(flagsArray, folders) {
	if (!flagsArray) {
		// If flag is not set, make all folders droppable
		return addDropKey(folders, true);
	}

	// If selected items are non-draft then it can be moved to all folders except `Drafts` folder
	if (flagsArray.indexOf(FLAGS.draft) === -1) {
		return addDropKey(folders, false, [USER_FOLDER_IDS.DRAFTS.toString()]);
	}

	// If all selected items is Draft then it can be moved to `Draft` and `Trash folder only
	if (flagsArray.length === 1 && flagsArray[0] === FLAGS.draft) {
		const droppableFolders = [USER_FOLDER_IDS.TRASH.toString(), USER_FOLDER_IDS.DRAFTS.toString()];
		return addDropKey(folders, true, droppableFolders);
	}
	// If selected items contain draft and non-draft items, it can be moved to `Trash` folder only
	return addDropKey(folders, true, [USER_FOLDER_IDS.TRASH.toString()]);
}

export function addDropKey(folders, flag, foldersToCheck) {
	return folders.map(f => ({
		...f,
		// Set `droppable` as `flag` in case:
		// IF 1) foldersToCheck is not set OR
		//    2) If foldersToCheck has value and it contains current folder
		// ELSE set toggled value of flag
		droppable:
			!foldersToCheck || foldersToCheck?.indexOf(f.sharedItemId || f.id) !== -1 ? flag : !flag,
		folders: f.folders ? addDropKey(f.folders, flag, foldersToCheck) : null
	}));
}

export function dertermineNonSelectedFolders(curentFolder, op) {
	let flags = '-dtjs';
	if ((curentFolder === INBOX && op === ActionOps.unspam) || curentFolder === JUNK) {
		flags = '-dts';
	} else if (curentFolder === SENT) {
		flags = '-dtj';
	} else if (curentFolder === TRASH) {
		flags = '-dsj';
	} else if (curentFolder === DRAFTS) {
		flags = '-stj';
	}
	return flags;
}

export function getAllowedLocalFoldersForMove(flagsArray, folders, folderInLoading) {
	const foldersToCheck = folderInLoading && [folderInLoading.toString()];

	if (!flagsArray) {
		// If flag is not set, make all folders droppable
		return addDropKey(folders, !folderInLoading, foldersToCheck);
	}

	// If selected items are non-draft then it can be moved to all folders except `Drafts` folder
	if (flagsArray.indexOf(FLAGS.draft) === -1) {
		return addDropKey(
			folders,
			false,
			[LOCAL_FOLDER_IDS.DRAFTS.toString()].concat(foldersToCheck || [])
		);
	}

	// If all selected items is Draft then it can be moved to `Draft` and `Trash folder only
	if (flagsArray.length === 1 && flagsArray[0] === FLAGS.draft) {
		const droppableFolders = [
			LOCAL_FOLDER_IDS.TRASH.toString(),
			LOCAL_FOLDER_IDS.DRAFTS.toString()
		];
		return addDropKey(folders, true, droppableFolders);
	}
	// If selected items contain draft and non-draft items, it can be moved to `Trash` folder only
	return addDropKey(folders, true, [LOCAL_FOLDER_IDS.TRASH.toString()]);
}
