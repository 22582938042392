export const OUTBOX = 'Outbox';
export const DRAFTS = 'Drafts';
export const SENT = 'Sent';
export const INBOX = 'Inbox';
export const TRASH = 'Trash';
export const CONTACTS = 'Contacts';
export const EMAILED_CONTACTS = 'Emailed Contacts';
export const JUNK = 'Junk';
export const ARCHIVE = 'Archive';
export const BRIEFCASE = 'Briefcase';
export const FILES_SHARED_WITH_ME = 'Files shared with me';

export const CUSTOM_FOLDERS = [OUTBOX, ARCHIVE];
