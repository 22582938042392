import get from 'lodash-es/get';
import { getView, getSearchQuery, getSearchEmail } from '../../store/url/selectors';
import { VIEW_TO_SEARCH } from './constants';
import {
	CALENDAR_VIEW,
	TASK_VIEW,
	APPOINTMENT_VIEW,
	CONVERSATION_VIEW
} from '../../constants/views';

export const getAdvanceSearchData = () => (_, getState) => {
	const state = getState();
	const currentView = getView(state);

	return {
		search: get(state, 'search'),
		query: getSearchQuery(state),
		email: getSearchEmail(state),
		view: VIEW_TO_SEARCH[currentView] || currentView,
		types: currentView === CALENDAR_VIEW ? `${APPOINTMENT_VIEW},${TASK_VIEW}` : CONVERSATION_VIEW
	};
};
