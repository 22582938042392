const smimeHandler = require('./smime');

const rendererIpc = require('./ipc/renderer-ipc');
const { ipcNames } = require('./ipc/constants');
const { DB_PATH_SEPERATOR, FS_FOLDERS, LOCAL_FOLDER_IDS } = require('./local-store/constants');
const appConstants = require('./app-constants');

/**
 * Initializes the desktop app config. Responsibile for following tasks:
 * 1. Sets the account name (email id) of the logged in user in the FileSystemAgent
 * 2. Creates the account folder if it does not exist in the file system
 * 3. Registers all the renderer process IPC listeners
 * 4. Creates the database connection with the sqlite file based on the account name parameter
 *
 * @param {String} accountName email address of the user who has logged in
 *
 * @returns {Promise} which gets resolved once the connection to the database has been established
 */

const registerIpcListeners = () => {
	if (typeof process.env.ELECTRON_ENV !== 'undefined') {
		rendererIpc.bindListeners();
	}
};

const unregisterIpcListeners = () => {
	if (typeof process.env.ELECTRON_ENV !== 'undefined') {
		rendererIpc.unbindListeners();
	}
};

module.exports = {
	smimeHandler,
	registerIpcListeners,
	unregisterIpcListeners,
	rendererIpc,
	DB_PATH_SEPERATOR,
	FS_FOLDERS,
	LOCAL_FOLDER_IDS,
	constants: {
		ipcNames,
		...appConstants
	}
};
