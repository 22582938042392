import { Fragment } from 'preact';
import { ChoiceInput } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import style from '../../../style';

export default function AccountChoiceInput({ onChange, checked, textId, noWrapper, ...restProps }) {
	return noWrapper ? (
		<Fragment>
			<ChoiceInput onChange={onChange} checked={checked} {...restProps} />
			<Text id={textId} />
		</Fragment>
	) : (
		<div class={style.subsectionBody}>
			<ChoiceInput onChange={onChange} checked={checked} {...restProps} />
			<Text id={textId} />
		</div>
	);
}
