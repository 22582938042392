import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import AppConfigContext from '../app-config';
import { getPageAttributes } from './util';

class Tracker {
	constructor({
		ZIMBRA_MIXPANEL_ID,
		ZIMBRA_TRACKING_ID,
		ZIMBRA_GTM_ID,
		ZIMBRA_TRACKER_PREFIX,
		ZIMBRA_TRACKER_SET_USER_DATA
	}) {
		this.trackers = [];

		if (!ZIMBRA_MIXPANEL_ID && !ZIMBRA_TRACKING_ID && !ZIMBRA_GTM_ID) {
			return;
		}

		if (ZIMBRA_MIXPANEL_ID) {
			import(/* webpackMode: "lazy", webpackChunkName: "mixpanel-action" */ './mix-panel').then(
				data => {
					const { MixPanelTracker } = data;

					this.trackers.push(
						new MixPanelTracker({
							trackerId: ZIMBRA_MIXPANEL_ID,
							ZIMBRA_TRACKER_PREFIX,
							ZIMBRA_TRACKER_SET_USER_DATA
						})
					);
				}
			);
		}
		if (ZIMBRA_TRACKING_ID || ZIMBRA_GTM_ID) {
			import(/* webpackMode: "lazy", webpackChunkName: "gtm-action" */ './google-tag-manager').then(
				data => {
					const { GoogleTagManager } = data;

					this.trackers.push(
						new GoogleTagManager({
							trackerId: ZIMBRA_GTM_ID || ZIMBRA_TRACKING_ID,
							ZIMBRA_TRACKER_PREFIX,
							ZIMBRA_TRACKER_SET_USER_DATA
						})
					);
				}
			);
		}
	}

	setPageView = path => {
		const attributes = getPageAttributes(path);

		this.trackers.forEach(item => {
			item.setPageView(attributes);
		});
	};

	setAccountData = data => {
		this.trackers.forEach(item => {
			item.setAccountData(data);
		});
	};

	setVirtualPageView = data => {
		const attributes = getPageAttributes(data.path);

		this.trackers.forEach(item => {
			item.setVirtualPageView(attributes);
		});
	};

	setUserAction = data => {
		this.trackers.forEach(item => {
			item.setUserAction(data);
		});
	};
}

export const TrackingContext = createContext();

export const TrackingProvider = ({ children }) => {
	const {
		ZIMBRA_MIXPANEL_ID,
		ZIMBRA_TRACKING_ID,
		ZIMBRA_GTM_ID,
		ZIMBRA_TRACKER_PREFIX,
		ZIMBRA_TRACKER_SET_USER_DATA
	} = useContext(AppConfigContext);

	const tracker = new Tracker({
		ZIMBRA_MIXPANEL_ID,
		ZIMBRA_TRACKING_ID,
		ZIMBRA_GTM_ID,
		ZIMBRA_TRACKER_PREFIX,
		ZIMBRA_TRACKER_SET_USER_DATA
	});

	const { Provider } = TrackingContext;
	return <Provider value={{ tracker }}>{children}</Provider>;
};
