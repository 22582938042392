import { Text } from 'preact-i18n';
import style from '../style';
import ZimletSlot from '../../zimlet-slot';
import { withAriaId } from '@zimbra/a11y';
import { defaultLocaleFormat, getDateFormat, TIME_FORMAT_OPTIONS } from '../../../utils/locale';
import AutoArchiveSettings from '../auto-archive-settings';
import DateTimeSettings from '../date-time-settings';
import { DATE_FIELD_NAME, TIME_FIELD_NAME } from '../../../constants/locale';
import DefaultMailApp from './default-mail-app';
import { formatBytesTranslation } from '../../../lib/util';

const SUCCESS = '#007a3e';
const WARNING = '#cf2a2a';

const getGrediant = usageData => {
	return `${usageData > 99 ? WARNING : SUCCESS} 0%, ${usageData > 99 ? WARNING : SUCCESS} 60%, ${
		usageData > 79 ? WARNING : SUCCESS
	} 100%`;
};

function GeneralSettings({
	onFieldChange,
	value,
	activeId,
	handleValidityOfTabs,
	matchesScreenXsMax,
	intl
}) {
	const {
		usedQuota,
		zimbraMailQuota,
		clientType,
		dateFormat,
		timeFormat,
		defaultMailToClient,
		autoArchiveFrequency
	} = value;
	const islimited = zimbraMailQuota !== '0';
	const usage = islimited && Math.min(Math.round((usedQuota / zimbraMailQuota) * 100), 100);
	const { id: usedTextId, value: usedValue } = formatBytesTranslation(usedQuota);
	const { id: limitTextId, value: limitValue } = formatBytesTranslation(zimbraMailQuota);
	const { defaultDateFormatValue, DATE_OPTIONS } = getDateFormat(dateFormat, intl);
	const defaultTimeFormatValue = timeFormat ? timeFormat : defaultLocaleFormat();

	return (
		<div class={style.generalSection}>
			<div class={style.sectionTitle}>
				<Text id="settings.general.storage.title" />
			</div>
			<Text
				id={`settings.general.storage.quota.${islimited ? 'limited' : 'unlimited'}`}
				fields={{
					used: <Text id={usedTextId} fields={{ size: usedValue }} />,
					limit: <Text id={limitTextId} fields={{ size: limitValue }} />
				}}
			/>
			{islimited && (
				<div class={style.usageBar}>
					<div
						class={style.usage}
						style={{
							width: `${usage}%`
						}}
					>
						<div
							class={style.animationBar}
							style={{
								background: `linear-gradient(to right, ${getGrediant(usage)})`
							}}
						>
							<span class={usage > 20 && style.textWhite}>
								{usage ? (
									usage === 100 ? (
										<Text id="settings.general.storage.quota.exceed" />
									) : (
										`${usage}%`
									)
								) : (
									'0%'
								)}
							</span>
						</div>
					</div>
				</div>
			)}

			{typeof process.env.ELECTRON_ENV !== 'undefined' && (
				<AutoArchiveSettings
					activeId={activeId}
					handleValidityOfTabs={handleValidityOfTabs}
					onFieldChange={onFieldChange}
					autoArchiveFrequency={autoArchiveFrequency}
				/>
			)}

			<div class={style.settingsSection}>
				<div class={style.sectionTitle}>
					<Text id="settings.general.dateTimeTitle" />
				</div>
				<DateTimeSettings
					defaulValue={defaultDateFormatValue}
					options={DATE_OPTIONS}
					onFieldChange={onFieldChange}
					fieldName={DATE_FIELD_NAME}
					matchesScreenXsMax={matchesScreenXsMax}
				/>
				<DateTimeSettings
					defaulValue={defaultTimeFormatValue}
					options={TIME_FORMAT_OPTIONS}
					onFieldChange={onFieldChange}
					fieldName={TIME_FIELD_NAME}
				/>
				<ZimletSlot
					name="set-default-client"
					clientType={clientType}
					onFieldChange={onFieldChange}
				/>
				{/* @TODO: Remove check for windows after fix is found for setting ZimbraX as default mailto app in windows */}
				{typeof process.env.ELECTRON_ENV !== 'undefined' && process.platform !== 'win32' && (
					<DefaultMailApp
						onFieldChange={onFieldChange}
						mailToClientDefaultState={defaultMailToClient}
					/>
				)}
			</div>
		</div>
	);
}

export default withAriaId('generalSettings')(GeneralSettings);
