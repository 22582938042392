import { Component } from 'preact';
import { Text } from 'preact-i18n';
import { ChoiceInput } from '@zimbra/blocks';
import { callWith } from '../../../lib/util';
import style from '../style';
import { connect } from 'react-redux';

function removeModern(description) {
	if (!description) return '';
	// Replace content in the last brackets with '' ex. replace (Modern) with ''
	return description.replace(/\(([^)]*)\)*$/, '');
}
@connect(state => ({
	zimletLocalization: state.zimlets.zimletLocalizations
}))
export default class ZimletsSettings extends Component {
	handleZimletPrefChange = zimlet => {
		const zimletIndex = this.props.value.zimletPrefs.findIndex(
			selectedZimlet => selectedZimlet.name === zimlet.name
		);

		const modifiedZimlets = [
			...this.props.value.zimletPrefs.slice(0, zimletIndex),
			{
				name: zimlet.name,
				presence: zimlet.presence === 'disabled' ? 'enabled' : 'disabled',
				label: zimlet.label,
				description: removeModern(zimlet.description)
			},
			...this.props.value.zimletPrefs.slice(zimletIndex + 1)
		];

		this.props.onFieldChange('zimletPrefs')({
			target: {
				value: modifiedZimlets
			}
		});
	};

	render({ value, zimletLocalization }) {
		const zimletList = value.zimletPrefs.map(zimlet => {
			const { presence, label, name, description } = zimlet;
			return (
				<li>
					<div class={style.radioButtonWrapper}>
						<span>
							<ChoiceInput
								checked={presence === 'enabled' || presence === 'mandatory'}
								disabled={presence === 'mandatory'}
								onChange={callWith(this.handleZimletPrefChange, zimlet)}
							/>
						</span>
						<label>
							<div class={presence === 'mandatory' && style.disabledText}>
								{zimletLocalization[name]?.label || label || name}
							</div>
							{description && (
								<div class={style.description}>
									{removeModern(zimletLocalization[name]?.description || description)}
								</div>
							)}
						</label>
					</div>
				</li>
			);
		});

		return (
			<div>
				<div class={style.sectionTitle}>
					<Text id="settings.zimlets.mainTitle" />
				</div>
				<p>
					<Text id={`settings.zimlets.${zimletList.length ? 'helpText' : 'emptyListHelpText'}`} />
				</p>

				<ul class={style.zimletList}> {zimletList} </ul>
			</div>
		);
	}
}
