import { Component } from 'preact';
import { connect } from 'react-redux';
import { Text } from 'preact-i18n';
import { Spinner } from '@zimbra/blocks';
import style from './style';
import { notify, clear } from '../../store/notifications/actions';
import { DEFAULT_NOTIFICATION_DURATION } from '../../constants/notifications';
import { OfflineInfo } from '../offline-info';
import { offlineInfoVisibility } from '../../store/network/actions';

export const OfflineBadge = () => (
	<span class={style.offlineBadge}>
		<Text id="app.offline" />
	</span>
);

@connect(
	({ network }) => ({
		isOffline: network.isOffline,
		isLoggedOut: network?.isLoggedOut,
		showOfflineInfo: network.showOfflineInfo,
		isSyncing: network.isSyncInProgress
	}),
	{ notify, clear, offlineInfoVisibility }
)
export default class OfflineLabel extends Component {
	componentWillReceiveProps({ isOffline, isLoggedOut, isDesktopView }) {
		if (
			isOffline &&
			!isLoggedOut &&
			!this.props.isOffline &&
			isDesktopView &&
			typeof process.env.ELECTRON_ENV !== 'undefined'
		) {
			this.props.offlineInfoVisibility(true);
		}

		if (isOffline !== this.props.isOffline && typeof process.env.ELECTRON_ENV === 'undefined') {
			this.props.notify({
				message: <Text id={isOffline ? 'app.connectionError' : 'app.reconnectMessage'} />,
				duration: isOffline ? 86400 : DEFAULT_NOTIFICATION_DURATION,
				failure: isOffline,
				action: {
					label: <Text id="buttons.ok" />,
					fn: () => {
						this.props.clear();
					}
				}
			});
		}
	}

	render({ isOffline, showOfflineInfo, isSyncing, isLoggedOut }, {}) {
		const showOffline =
			typeof process.env.ELECTRON_ENV !== 'undefined' ? isOffline && !isLoggedOut : isOffline;

		return (
			showOffline &&
			(showOfflineInfo ? (
				<OfflineInfo />
			) : (
				<div class={style.offline}>
					{isSyncing && <Spinner class={style.spinner} />}
					{isOffline && <OfflineBadge />}
				</div>
			))
		);
	}
}
