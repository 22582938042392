import { PureComponent } from 'preact/compat';
import Favico from 'favico.js';
import { updateDesktopBadgeCount } from '../../lib/util';
import clientConfiguration from '../../enhancers/client-config';
import getMailFolders from '../../graphql-decorators/get-mail-folders';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import { branch, renderNothing, withPropsOnChange } from 'recompose';
import withMediaQuery from '../../enhancers/with-media-query';
import { minWidth, screenMd } from '../../constants/breakpoints';

@withMediaQuery(minWidth(screenMd), 'matchesScreenMd')
@branch(({ matchesScreenMd }) => !matchesScreenMd, renderNothing)
@connect(state => ({
	isOffline: get(state, 'network.isOffline'),
	authTokenExpired: get(state, 'activeAccount.authTokenExpired')
}))
@clientConfiguration({
	onlineBgColor: 'faviconBadge.onlineBgColor',
	onlineTextColor: 'faviconBadge.onlineTextColor',
	offlineBgColor: 'faviconBadge.offlineBgColor',
	offlineTextColor: 'faviconBadge.offlineTextColor',
	authTimeoutBgColor: 'faviconBadge.authTimeoutBgColor',
	authTimeoutTextColor: 'faviconBadge.authTimeoutTextColor'
})
@getMailFolders()
@branch(({ inboxFolder }) => !inboxFolder, renderNothing)
@withPropsOnChange(
	['isOffline', 'authTokenExpired', 'inboxFolder'],
	({
		onlineBgColor,
		onlineTextColor,
		offlineBgColor,
		offlineTextColor,
		authTimeoutBgColor,
		authTimeoutTextColor,
		isOffline,
		authTokenExpired,
		inboxFolder
	}) => {
		let badgeBgColor,
			badgeTextColor,
			badgeText = inboxFolder.unread || 0;

		if (authTokenExpired) {
			badgeBgColor = authTimeoutBgColor;
			badgeTextColor = authTimeoutTextColor;
			badgeText = '\u2013';
		} else if (isOffline) {
			badgeBgColor = offlineBgColor;
			badgeTextColor = offlineTextColor;
		} else {
			badgeBgColor = onlineBgColor;
			badgeTextColor = onlineTextColor;
		}

		return {
			badgeBgColor,
			badgeTextColor,
			badgeText
		};
	}
)
export default class FaviconWithBadge extends PureComponent {
	// Reset all instances of favicon
	resetFavicons = () => this.favicon?.reset();

	// Update all favicon instances
	// if isOffline or authTokenExpired props are changed then we have to reinitialize favicons
	// else only update badge number
	updateFavicons = nextProps => {
		if (!this.favicon) {
			return;
		}

		const { isOffline, authTokenExpired, inboxFolder } = nextProps;

		// If there is change in offline status or auth token is expired then
		// we have to re-initialize favicons to change colors
		const reInitialize =
			this.props.isOffline !== isOffline || this.props.authTokenExpired !== authTokenExpired;
		const updateCount = this.props.inboxFolder.unread !== inboxFolder.unread;

		if (reInitialize) {
			this.initializeFavicons(nextProps);
		} else if (updateCount) {
			// Only update badge number
			const count = inboxFolder.unread || 0;

			updateDesktopBadgeCount(count);

			this.favicon.badge(count);
		}
	};

	// Initialize favicon instances
	initializeFavicons = ({ badgeBgColor, badgeTextColor, badgeText, inboxFolder: { unread } }) => {
		this.resetFavicons();

		updateDesktopBadgeCount(unread || 0);

		this.favicon = new Favico({
			animation: 'fade',
			bgColor: badgeBgColor,
			textColor: badgeTextColor
		});
		this.favicon.badge(badgeText);
	};

	componentDidMount() {
		this.initializeFavicons(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.updateFavicons(nextProps);
	}

	componentWillUnmount() {
		this.resetFavicons();
	}

	render() {
		return null;
	}
}
