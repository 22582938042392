export const SPACE_BAR = 32;
export const CARRIAGE_RETURN = 13;
export const LEFT_ARROW = 37;
export const UP_ARROW = 38;
export const RIGHT_ARROW = 39;
export const DOWN_ARROW = 40;
export const ESCAPE = 27;
export const TAB = 9;
export const PAGE_UP = 33;
export const PAGE_DOWN = 34;
export const END = 35;
export const HOME = 3;
export const BACKSPACE = 8;
export const DELETE = 46;
export const SHIFT = 16;
export const CTRL = 17;
export const META_KEY = 91;
export const KEY_F10 = 121;
export const KEY_SELECT = 93;
export const KEY_SEMICOLON = 186;
export const KEY_SEMICOLON_FIREFOX = 59; //Key code for semi-colon in Firefox is different.
export const KEY_COMMA = 188;
export const KEY_A = 65;
export const KEY_C = 67;
export const KEY_X = 88;
export const KEY_V = 86;
