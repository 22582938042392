import ModalDialog from '../modal-dialog';

import { MarkupText } from 'preact-i18n';

const ResetPasswordModal = ({ onClose, onContinue }) => {
	return (
		<ModalDialog
			title={`loginScreen.resetPasswordModal.title`}
			onClickOutside={onClose}
			onClose={onClose}
			onAction={onContinue}
			actionLabel={`buttons.continue`}
		>
			<MarkupText id="loginScreen.resetPasswordModal.content" />
		</ModalDialog>
	);
};

export default ResetPasswordModal;
