import { createAction } from 'redux-actions';

export const setPreviewAttachment = createAction(
	'attachmentPreview setAttachmentPreview',
	(attachment, group) => ({ attachment, group })
);
export const setAttachments = createAction('attachmentPreview setAttachments', group => ({
	group
}));
export const setSelectedAttachment = createAction(
	'attachmentPreview setSelectedAttachment',
	attachment => ({
		attachment
	})
);
export const previewNextPage = createAction('attachmentPreview previewNextPage');
export const previewPreviousPage = createAction('attachmentPreview previewPreviousPage');
export const showNextOnRemove = createAction('attachmentPreview showNextOnRemove', attachment => ({
	attachment
}));
