import get from 'lodash-es/get';
import { route } from 'preact-router';

import { removeEventData, setEventTabClosing } from '../../store/calendar/actions';
import { removeTabThunk } from '../../utils/thunk';

export const closeEventTabThunk = tab => (dispatch, getState) => {
	const { tabId, url: tabUrl } = tab;
	const eventData = get(getState(), `calendar.eventsData.${tabId}`, {});

	if (eventData.isFormDirty) {
		dispatch(setEventTabClosing(true));
		route(tabUrl);
	} else {
		dispatch(removeTabThunk(tab));
		dispatch(removeEventData({ tabId }));
	}
};

export const getParsedAppointmentDataThunk = (path, tabId) => (_, getState) =>
	get(getState(), `calendar.eventsData.${tabId}.${path}`);
