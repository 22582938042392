import cx from 'classnames';

import { hoursDecimal } from '../../utils/free-busy';

import s from './style.less';

export default function RowBody({
	freeBusyStatuses,
	workingHourStatuses,
	cellWidth,
	isAllInvitees
}) {
	function renderStatusSlot(status, start, end) {
		const left = cellWidth * hoursDecimal(start);
		const width = cellWidth * hoursDecimal(end) - left;
		return <div class={s[`${status}Indicator`]} style={{ left, width }} />;
	}
	return (
		<div class={cx(s.tableRowBody)}>
			{freeBusyStatuses && (
				<div class={cx(s.renderFreeBusy, isAllInvitees && s.allInviteesStatus)}>
					{freeBusyStatuses.map(({ status, start, end }) => renderStatusSlot(status, start, end))}
				</div>
			)}
			{workingHourStatuses && (
				<div class={s.renderWorkingHours}>
					{workingHourStatuses.map(({ status, start, end }) =>
						renderStatusSlot(status, start, end)
					)}
				</div>
			)}
		</div>
	);
}
