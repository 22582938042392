import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';

const initialState = {
	importSettingsState: false
};

export default handleActions(
	{
		[actionCreators.updateImportSettingState]: (state, action) => {
			return {
				...state,
				importSettingsState: action.payload
			};
		}
	},
	initialState
);
