let exportVar;

import { Component } from 'preact';
import { Text } from 'preact-i18n';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import { setPstStatus, setPstRootFolder } from './../../store/pst-import/actions';
import { notify, clear } from '../../store/notifications/actions';
import withCreateFolderMutation from '../../graphql-decorators/create-folder';
import { defaultProps } from 'recompose';
import { MAIL_VIEW } from '../../constants/views';
import { faultCode, errorMessage } from '../../utils/errors';
import withActionMutation from '../../graphql-decorators/with-action-mutation';
import { types as apiClientTypes } from '@zimbra/api-client';
const {
	rendererIpc,
	constants: { ipcNames }
} = require('@zimbra/electron-app');

if (typeof process.env.ELECTRON_ENV !== 'undefined') {
	const { ActionType } = apiClientTypes;
	@defaultProps({ view: MAIL_VIEW })
	@connect(
		state => ({
			pstLoading: get(state, 'importPst.loading'),
			pstData: get(state, 'importPst.data'),
			pstError: get(state, 'importPst.error'),
			pstFileName: get(state, 'importPst.pstFileName'),
			pstRootFolderId: get(state, 'importPst.pstRootFolderId')
		}),
		{
			notify,
			clear,
			setPstStatus,
			setPstRootFolder
		}
	)
	@withActionMutation()
	@withCreateFolderMutation()
	class PstImport extends Component {
		updatePStExtractStatus = ({
			isLoading,
			data,
			error,
			reloadLocalFolderList,
			pstFileName,
			pstRootFolderId
		}) => {
			const {
				setPstStatus: updatePstStatus,
				setPstRootFolder: updatePstRootFolder,
				notify: notifyAction,
				clear: clearAction
			} = this.props;
			updatePstStatus({
				isLoading,
				data,
				error,
				reloadLocalFolderList,
				pstFileName
			});

			pstRootFolderId && updatePstRootFolder({ pstFileName, pstRootFolderId });

			error &&
				notifyAction({
					failure: true,
					message: <Text id="local_folder.pstExtract.error" />,
					action: {
						label: <Text id="buttons.ok" />,
						fn: () => {
							clearAction();
						}
					}
				});
		};

		componentDidMount() {
			// Add IPC listeners on component mount for desktop app

			// Bind IPC listeners on given channel.
			// Provide User folder path to main process to extract the data at that location.
			rendererIpc.listenToIpcEvent(ipcNames.setPstProgressStatus, this.updatePStExtractStatus);
		}

		componentWillReceiveProps(nextProps) {
			const { pstError, pstData, pstFileName, pstRootFolderId } = nextProps;
			const {
				action,
				pstFileName: prevPstFileName,
				view,
				pstData: prevPstData,
				notify: notifyAction,
				clear: clearAction,
				createFolder
			} = this.props;

			if (pstError) {
				if (pstRootFolderId) {
					rendererIpc.abortPSTExtraction();
				}
				this.updatePStExtractStatus({
					isLoading: false,
					reloadLocalFolderList: pstRootFolderId,
					error: pstError
				});
			} else if (pstFileName && prevPstFileName !== pstFileName && !pstData?.folderName) {
				//Executes when we don't have result of pst-extraction
				//And user started import and we have pst root folder name.
				const options = {
					name: pstFileName,
					isLocalFolder: true,
					deletable: true,
					view,
					reloadAfterCreate: true
				};
				createFolder({
					variables: options,
					context: {
						local: options.isLocalFolder
					}
				})
					.then(createdFolder => {
						this.updatePStExtractStatus({
							isLoading: true,
							reloadLocalFolderList: true,
							pstFileName,
							pstRootFolderId: createdFolder?.data?.createFolder?.id
						});
					})
					.catch(err => {
						const errCode = faultCode(err);
						let localFolderError;

						// if it's not a fault error, handle local store error.
						if (!errCode) {
							localFolderError = errorMessage(err);
						}
						this.updatePStExtractStatus({
							isLoading: false,
							reloadLocalFolderList: true
						});

						notifyAction({
							failure: true,
							message: localFolderError ? (
								<Text id={localFolderError} />
							) : errCode === 'mail.ALREADY_EXISTS' ? (
								<Text id={`faults.${errCode}_folder`} fields={{ name: options.name }} />
							) : (
								err
							)
						});
					});
			}
			if (prevPstData?.folderName !== pstData?.folderName && pstRootFolderId) {
				//Executes when we have result from extractor.
				const folderName = pstData.folderName;
				const options = folderName && {
					name: folderName,
					isLocalFolder: true,
					deletable: true,
					view,
					reloadAfterCreate: true
				};
				folderName &&
					options.reloadAfterCreate &&
					action({
						type: ActionType.folder,
						op: 'refresh',
						folderId: pstRootFolderId,
						local: true
					}).then(async response => {
						if (response) {
							// refresh the folder list as well as the local folder refresh might discover
							// another folders as well
							this.updatePStExtractStatus({
								isLoading: false,
								data: {},
								error: null,
								reloadLocalFolderList: true
							});

							// Read imported counts from log file and show it in notification
							const data = await rendererIpc.getImportCountFromLog(folderName);
							let counts;

							try {
								counts = JSON.parse(data.trim());
							} catch (ex) {
								throw new Error('Error parsing the pst log file to get imported count', ex);
							}

							notifyAction({
								failure: false,
								message: (
									<Text
										id="local_folder.pstExtract.success"
										fields={{ total: counts.totalMailItems, count: counts.importedData }}
									/>
								),
								action: {
									label: <Text id="buttons.ok" />,
									fn: () => {
										clearAction();
									}
								}
							});
						}
					});
			}
		}

		componentWillUnmount() {
			// Unbind IPC listeners on given channel.
			rendererIpc.removeIpcListener(ipcNames.setPstProgressStatus, this.updatePStExtractStatus);
		}

		render() {
			return null;
		}
	}
	exportVar = PstImport;
}

export default exportVar;
