import { Component } from 'preact';
import linkState from 'linkstate';
import cx from 'classnames';
import { Text } from 'preact-i18n';
import TextInput from '../text-input';
import { Button, ChoiceInput, AnimatedButton } from '@zimbra/blocks';
import style from './style';

export default class TwoFactorAuthForm extends Component {
	shakeButton = () => {
		this.setState({
			shake: true,
			loading: false
		});
	};

	afterBtnShake = () => {
		this.setState({
			shake: false
		});
	};

	handleAuth = () => {
		const { username, password, login } = this.props;
		const { twoFactorCode, deviceTrusted } = this.state;

		this.setState({
			loading: true,
			error: false
		});

		login({
			username,
			password,
			twoFactorCode,
			deviceTrusted
		}).catch(() => {
			this.shakeButton();
		});
	};

	render({ trustedDevicesEnabled, onCancel }, { twoFactorCode, loading, shake, error }) {
		return (
			<div>
				<div class={cx(style.error, error && style.showing)}>
					<div class={style.inner}>{error}</div>
				</div>
				<div>
					<form
						class={style.twoFactorAuthForm}
						onSubmit={this.handleAuth}
						action="javascript:"
						method="POST"
					>
						<div class={style.form}>
							<label>
								<Text id="loginScreen.twoFactorAuth.codeInput" />
							</label>
							<TextInput
								autofocus
								value={twoFactorCode}
								disabled={loading}
								onInput={linkState(this, 'twoFactorCode')}
							/>

							{trustedDevicesEnabled && (
								<label>
									<ChoiceInput type="checkbox" onChange={linkState(this, 'deviceTrusted')} />
									<Text id="loginScreen.twoFactorAuth.trustDevice" />
								</label>
							)}

							<div class={style.buttonsContainer}>
								<AnimatedButton
									class={style.continue}
									disabled={!twoFactorCode || loading}
									shake={shake}
									loading={loading}
									type="submit"
									styleType="primary"
									brand="primary"
									title={<Text id="buttons.verify" />}
									afterShake={this.afterBtnShake}
								/>
								<Button onClick={onCancel}>
									<Text id="buttons.cancel" />
								</Button>
							</div>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
