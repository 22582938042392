import { Component } from 'preact';
import { withText, Text } from 'preact-i18n';
import { ChoiceInput } from '@zimbra/blocks';
import { connect } from 'react-redux';
import linkstate from 'linkstate';

import { getPublicGrant, addPublicGrant, removePublicGrant } from '../../utils/acl';
import toSentence from '../../utils/to-sentence';
import { parseAddress } from '../../lib/util';
import { notify as notifyActionCreator } from '../../store/notifications/actions';

import NakedButton from '../naked-button';
import FormGroup from '../form-group';
import ShareInfoCard from '../share-info-card';
import EmailShareInfoCard from './email-share-info-card';
import { CALENDAR_VIEW } from '../../constants/views';

import s from './style.less';

function grantName(grant) {
	if (grant.address) {
		return grant.address;
	}
	if (grant.zimbraId) {
		const parsed = parseAddress(grant.zimbraId);
		return parsed.name || parsed.address;
	}
	return '<Unknown User>';
}

const ShareList = ({ grants, onManage }) => (
	<div class={s.shareList}>
		<Text
			id="shareItemModal.sharedWith"
			fields={{
				grantees: toSentence(grants.map(grantName), { expandedLimit: 2 })
			}}
		/>
		<NakedButton onClick={onManage} linkColor>
			<Text id="buttons.change" />
		</NakedButton>
	</div>
);

@withText('shareItemModal.notifyCopy')
@connect(null, { notify: notifyActionCreator })
export default class ShareMain extends Component {
	state = {
		enableEmail: false
	};

	handlePublicToggle = ({ target: { checked } }) => {
		const action = checked ? addPublicGrant : removePublicGrant;
		this.props.onACLChange(action(this.props.acl));
		this.setState({
			publicGrant: checked
		});
	};

	handleCopyLinkSuccess = () => {
		this.props.notify({
			message: this.props.notifyCopy
		});
	};

	componentWillMount() {
		const acl = getPublicGrant(this.props.acl);
		this.setState({
			enableEmail: this.props.emailGrants && this.props.emailGrants.length > 0,
			publicGrant: acl
		});
	}

	render(
		{
			aclEmails,
			publicURL,
			onManageAccess,
			emailsToInvite,
			invitePermissions,
			onEmailsToInviteChange,
			onInvitePermissionsChange,
			screen,
			ownerEmail,
			isPublicSharingEnabled,
			emailGrants
		},
		{ enableEmail, publicGrant }
	) {
		// For `Mail` and `Contact` screens, we have only one option which will be kept checked by default.
		const keepInviteOptionChecked = screen !== CALENDAR_VIEW;

		return (
			<div>
				{screen === CALENDAR_VIEW && (
					<FormGroup rows compact={!publicGrant}>
						<label class={!isPublicSharingEnabled && s.disabledSharingLabel}>
							<ChoiceInput
								onChange={this.handlePublicToggle}
								checked={!!publicGrant}
								disabled={!isPublicSharingEnabled}
							/>{' '}
							<Text id="shareItemModal.calendar.enablePublicLabel" />
						</label>
						{publicGrant && (
							<ShareInfoCard
								title={<Text id="shareItemModal.calendar.publicSelectLabel" />}
								url={publicURL}
								onCopySuccess={this.handleCopyLinkSuccess}
							/>
						)}
					</FormGroup>
				)}
				<FormGroup rows compact={!enableEmail}>
					<label>
						<ChoiceInput
							onChange={linkstate(this, 'enableEmail')}
							checked={enableEmail || keepInviteOptionChecked}
							disabled={(emailGrants && emailGrants.length > 0) || keepInviteOptionChecked}
						/>{' '}
						<Text id="shareItemModal.inviteEmailsLabel" />
					</label>
					{(enableEmail || keepInviteOptionChecked) && (
						<div class={s.cardGroup}>
							<EmailShareInfoCard
								ownerEmail={ownerEmail}
								aclEmails={aclEmails}
								emailsToInvite={emailsToInvite}
								invitePermissions={invitePermissions}
								onEmailsToInviteChange={onEmailsToInviteChange}
								onInvitePermissionsChange={onInvitePermissionsChange}
								screen={screen}
							/>
							{emailGrants && emailGrants.length > 0 && (
								<ShareList grants={emailGrants} onManage={onManageAccess} />
							)}
						</div>
					)}
				</FormGroup>
			</div>
		);
	}
}
