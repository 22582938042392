import style from './style.less';
import TextInput from '../text-input';
import Select from '../select';
import cx from 'classnames';

/**
 * This is a combination select + text input
 */
export default function ComboBox({
	textInputVal,
	textOnChange,
	textPlaceholderId,
	textClass,
	type,
	selectOnChange,
	invalid,
	name,
	children
}) {
	return (
		<div class={style.comboTextSelect}>
			<TextInput
				type={type}
				onChange={textOnChange}
				value={textInputVal}
				class={cx(style.input, textClass)}
				placeholderId={textPlaceholderId}
				invalid={invalid}
				name={name}
			/>
			<Select
				name={name}
				onChange={selectOnChange}
				containerClass={style.selectContainer}
				value={textInputVal}
			>
				{children}
			</Select>
		</div>
	);
}
