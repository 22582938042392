import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import getContext from '../lib/get-context';
import omitProps from '../enhancers/omit-props';
import LoginMutation from '../graphql/queries/login.graphql';
import get from 'lodash-es/get';
import csrfStorage from '../utils/csrf';
import jwtStorage from '../utils/jwt';
import appConfiguration from '../enhancers/app-config';

export default function withLogin({ name = 'login' } = {}) {
	return compose(
		getContext(context => ({ context })),
		appConfiguration('useJwt'),
		graphql(LoginMutation, {
			props: ({ mutate, ownProps: { context, useJwt } }) => ({
				[name]: variables =>
					mutate({ variables, fetchPolicy: 'no-cache' }).then(res => {
						if (useJwt) {
							const jwtToken = get(res, 'data.login.authToken.0._content');
							jwtStorage.set(jwtToken, context);
						}

						const csrfToken = get(res, 'data.login.csrfToken._content');
						// If JWT is enabled then we will not get csrf token, so ignore it in that case
						if (csrfToken) {
							csrfStorage.set(csrfToken, context);
						}

						return res;
					})
			})
		}),
		omitProps('context,useJwt')
	);
}
