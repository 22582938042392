import MailAccount from './mail-account';
import AddMore from '../add-more';
import get from 'lodash-es/get';
import style from '../style';
import { callWith } from '../../../lib/util';
import { useCallback, useState } from 'preact/hooks';
import { Fragment } from 'preact';

function AccountsList({
	accounts,
	switchActiveScreen,
	maxPersonasReached,
	onExternalAccountAction,
	editAccountProps,
	personasEnabled,
	fromAccounts
}) {
	const { zimbraFeatureImapDataSourceEnabled, zimbraFeaturePop3DataSourceEnabled } = get(
		editAccountProps,
		'accountData.attrs'
	);
	const [expendedAccount, setExpendedAccount] = useState(false);

	const expendDetails = useCallback(
		id => {
			setExpendedAccount(id && id !== expendedAccount && id);
		},
		[expendedAccount]
	);

	return (
		<div>
			<ul class={style.accountsList}>
				{accounts.map(account => (
					<MailAccount
						key={account.id}
						account={account}
						fromAccounts={fromAccounts}
						onExternalAccountAction={onExternalAccountAction}
						editAccountProps={editAccountProps}
						expendDetails={expendDetails}
						isExpended={expendedAccount === account.id}
					/>
				))}
			</ul>
			{(zimbraFeatureImapDataSourceEnabled || zimbraFeaturePop3DataSourceEnabled) && (
				<Fragment>
					<AddMore
						label="settings.accounts.addAccount.addAnotherAccountLabel"
						onClick={callWith(switchActiveScreen, ['add'])}
					/>
					<br />
				</Fragment>
			)}
			{personasEnabled && (
				<AddMore
					label="settings.accounts.addAccount.addAnotherPersonaLabel"
					onClick={callWith(switchActiveScreen, ['addPersona'])}
					disabled={maxPersonasReached}
				/>
			)}
		</div>
	);
}

export default AccountsList;
