export const LOW_PRIORITY = '9';
export const NORMAL_PRIORITY = '5';
export const HIGH_PRIORITY = '1';

export const COMPLETE_STATUS = 'COMP';
export const NEED_STATUS = 'NEED';
export const IN_PROGRESS_STATUS = 'INPR';
export const WAITING_STATUS = 'WAITING';
export const DEFERRED_STATUS = 'DEFERRED';

export const PRIORITY_VIEW = 'PRIORITY';
export const LIST_VIEW = 'LIST';
export const DUE_DATE_VIEW = 'DUE_DATE';
export const DONE_VIEW = 'DONE';
