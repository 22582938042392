import { graphql } from '@apollo/client/react/hoc';
import memoize from 'lodash-es/memoize';
import CreateContactListMutation from '../../graphql/queries/contacts/create-contact-list.graphql';
import ModifyContactListMutation from '../../graphql/queries/contacts/modify-contact-list.graphql';
import AutoCompleteQuery from '../../graphql/queries/contacts/auto-complete.graphql';
import GetContact from '../../graphql/queries/contacts/get-contact.graphql';
import {
	getAutoSuggestionQueryVariables,
	getAllGetContactQueryVariables
} from '../../graphql/utils/graphql-variables';
import { USER_FOLDER_IDS } from '../../constants';

const memoizedContactListFactory = memoize(
	(mutate, client) =>
		({ groupName, folderId, groupId, ...rest }) => {
			const contactList = {
				...(groupId && { id: groupId }),
				folderId: folderId || USER_FOLDER_IDS.CONTACTS,
				attributes: {
					fileAs: `8:${groupName}`,
					nickname: groupName,
					type: 'group',
					fullName: groupName
				},
				...rest
			};

			return mutate({
				variables: {
					contact: contactList
				},
				refetchQueries: () => {
					const refetchQueryList = [
						...getAutoSuggestionQueryVariables(client.cache).map(cacheVars => ({
							query: AutoCompleteQuery,
							variables: cacheVars
						})),
						...getAllGetContactQueryVariables(client.cache).map(cacheVars => ({
							query: GetContact,
							variables: cacheVars
						}))
					];

					return refetchQueryList;
				}
			});
		}
);

const contactListMutationFactory = (mutationName, propName) => () =>
	graphql(mutationName, {
		props: ({ mutate, result: { client } }) => ({
			[propName]: memoizedContactListFactory(mutate, client)
		})
	});

export const withCreateContactList = contactListMutationFactory(
	CreateContactListMutation,
	'createContactList'
);
export const withModifyContactList = contactListMutationFactory(
	ModifyContactListMutation,
	'modifyContactList'
);
