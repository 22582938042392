import { Component } from 'preact';
import { Text, withText, MarkupText } from 'preact-i18n';
import moment from 'moment';
import get from 'lodash-es/get';
import omit from 'lodash-es/omit';
import isString from 'lodash-es/isString';
import isEmpty from 'lodash-es/isEmpty';
import cx from 'classnames';
import { newAlarm } from '../../../utils/event';
import { displayAddress } from '../../../utils/contacts';
import { withPropsOnChange } from 'recompose';
import AlignedForm from '../../aligned-form';
import AppointmentEditToolbar from './appointment-edit-toolbar';
import wire from 'wiretie';
import { notify } from '../../../store/notifications/actions';
import s from './style.less';
import {
	CALENDAR_USER_TYPE,
	CALENDAR_TYPE,
	CALENDAR_IDS,
	CALENDAR_REPEAT_FREQUENCY
} from '../../../constants/calendars';
import { USER_FOLDER_IDS } from '../../../constants';
import withMediaQuery from '../../../enhancers/with-media-query';
import { maxWidth, screenXs } from '../../../constants/breakpoints';
import CloseButton from '../../close-button';

import { appointmentBody, createLocationPayload } from '../../../graphql-decorators/calendar';
import { connect } from 'react-redux';
import { isValidEmail, getEmail, compareArrayContent } from '../../../lib/util';
import ConfirmModalDialog from '../../confirm-modal-dialog';
import ModalDialog from '../../modal-dialog';
import InEventNotification from './in-event-notification';
import ForwardAppointmentInvite from '../../../graphql/mutations/forward-appointment-invite.graphql';
import ForwardAppointment from '../../../graphql/mutations/forward-appointment.graphql';
import { graphql } from '@apollo/client/react/hoc';
import { route } from 'preact-router';
import withSearchGal from '../../../graphql-decorators/search/search-gal';
import { withCounterAppointment } from '../../../graphql-decorators/calendar/counter-appointment';
import { SOURCE_CALENDAR, WEEK_DAYS } from '../constants';
import clientConfiguration from '../../../enhancers/client-config';
import momentTz from 'moment-timezone';
import { removeTabThunk } from '../../../utils/thunk';
import {
	getReminderInterval,
	getEventSignificiantNonsignificantData
} from '../../../utils/calendar';

import {
	setEventTabClosing,
	setEvent,
	setMultipleEventTabClosing,
	removeEventData
} from '../../../store/calendar/actions';
import { AppointmentTitle } from './components/appointment-title';
import { AppointmentStartTime } from './components/appointment-start-time';
import { AppointmentEndTime } from './components/appointment-end-time';
import { RecurrenceSelect } from './components/recurrence-select';
import { AttendeesChooser } from './components/attendees-chooser';
import { EventNotes } from './components/event-notes';
import { SetReminder } from './components/set-reminder';
import { SetShowAs } from './components/set-show-as';
import { SelectedCalendar } from './components/selected-calendar';

const DesktopHeading = ({ title, onClose, field }) => (
	<div class={s.header}>
		<h2>
			<Text id={title} fields={{ organizerName: field }} />
		</h2>
		<CloseButton class={s.actionButton} onClick={onClose} />
	</div>
);

const MobileHeading = ({ title, field }) => (
	<div class={s.simpleHeader}>
		<h2>
			<Text id={title} fields={{ organizerName: field }} />
		</h2>
	</div>
);

const getSelectedCalendar = (appointmentData, calendars, event = {}) => {
	let ciFolder = get(appointmentData, 'message.invitations.0.components.0.ciFolder');
	ciFolder =
		ciFolder === USER_FOLDER_IDS.TRASH.toString()
			? CALENDAR_IDS[CALENDAR_TYPE.own].DEFAULT
			: ciFolder;
	const folderId = event.folderId || ciFolder;
	const indexOfColon = folderId && folderId.indexOf(':');

	return calendars.find(cal => {
		if (indexOfColon > -1) {
			return `${cal.ownerZimbraId}:${cal.sharedItemId}` === folderId || cal.id === folderId;
		}
		return cal.id === folderId;
	});
};

@withMediaQuery(maxWidth(screenXs), 'matchesScreenXs')
@wire('zimbra', {}, zimbra => ({
	attach: zimbra.attachment.upload
}))
@clientConfiguration({
	calendarUrlSlug: 'routes.slugs.calendar'
})
@connect(
	state => ({
		isOffline: get(state, 'network.isOffline'),
		prevLocation: get(state, 'url.prevLocation.pathname'),
		source: get(state, 'url.routeProps.source'),
		eventInstance: get(state, 'url.routeProps.instance'),
		activeAccountAddress: get(state, 'email.account.name'),
		activeAccountDisplayName: get(state, 'email.account.attrs.displayName'),
		isEventTabClosing: get(state, 'calendar.isEventTabClosing'),
		isMultipleEventTabClosing: get(state, 'calendar.isMultipleEventTabClosing'),
		tabs: get(state, `navigation.verticals.${state.url.view}.tabs`)
	}),
	{
		notify,
		removeTab: removeTabThunk,
		setEventState: setEvent,
		setEventTabClosingState: setEventTabClosing,
		setMultipleEventTabClosing,
		removeEventTabData: removeEventData
	}
)
@withText({
	errorMsg: 'calendar.editModal.FILE_SIZE_EXCEEDED',
	proposedTimeSubject: 'calendar.editModal.counterInvite.proposedTimeSubject'
})
@withPropsOnChange(['preferencesData'], ({ preferencesData }) => ({
	zimbraFeatureViewInHtmlEnabled: get(preferencesData, 'zimbraFeatureViewInHtmlEnabled'),
	zimbraPrefCalendarReminderEmail: get(preferencesData, 'zimbraPrefCalendarReminderEmail'),
	zimbraPrefUseTimeZoneListInCalendar: get(preferencesData, 'zimbraPrefUseTimeZoneListInCalendar'),
	zimbraPrefCalendarApptReminderWarningTime: get(
		preferencesData,
		'zimbraPrefCalendarApptReminderWarningTime'
	)
}))
@withSearchGal({
	skip: ({ appointmentData, calendars }) => {
		const organizer = get(appointmentData, 'message.invitations.0.components.0.organizer');
		const calendarOwner =
			appointmentData && get(getSelectedCalendar(appointmentData, calendars), 'owner');
		return !appointmentData || !!organizer || !calendarOwner;
	},
	options: ({ appointmentData, calendars }) => ({
		variables: {
			name: appointmentData && getSelectedCalendar(appointmentData, calendars).owner,
			types: 'contact',
			limit: 1
		}
	}),
	props: ({ data: { searchGal } }) => {
		const response = get(searchGal, 'contacts.0');
		return { ownerDisplayName: response && displayAddress(response) };
	}
})
@graphql(ForwardAppointment, {
	props: ({ mutate }) => ({
		forwardAppointment: appointmentInvite =>
			mutate({
				variables: { appointmentInvite }
			})
	})
})
@graphql(ForwardAppointmentInvite, {
	props: ({ mutate }) => ({
		forwardAppointmentInvite: appointmentInvite =>
			mutate({
				variables: { appointmentInvite }
			})
	})
})
@withCounterAppointment()
export default class AppointmentEditEvent extends Component {
	alarmsFromState = () => {
		const {
			zimbraPrefCalendarReminderEmail,
			eventData: { remindValue, remindDesktop, isAlarmAsEmailEnabled }
		} = this.props;

		if (remindValue === 'never') {
			return [];
		}

		const interval = getReminderInterval(remindValue);

		const alarms = [];
		if (remindDesktop) {
			alarms.push(newAlarm({ interval, action: 'DISPLAY' }));
		}
		if (isAlarmAsEmailEnabled && zimbraPrefCalendarReminderEmail) {
			alarms.push(
				newAlarm({
					interval,
					action: 'EMAIL',
					attendees: { email: zimbraPrefCalendarReminderEmail }
				})
			);
		}

		return alarms;
	};

	recurrenceFromState = ({
		repeatValue,
		endsOnDate,
		endsAfterRecur,
		customByDayRule,
		customByMonthRule,
		customByMonthDayRule,
		customBySetPosRule,
		customIntervalRule,
		event
	}) => {
		if (repeatValue === 'NONE') {
			return undefined;
		}

		let byDay = get(customByDayRule, 'wkday');
		const byMonthDay = get(customByMonthDayRule, 'dayList');
		const byMonth = get(customByMonthRule, 'monthList');
		const bySetPos = get(customBySetPosRule, 'poslist');

		// if choosing repeat every week we need to pass the day of the week so passing the event's start day and
		// if custom is chosen it will select that day
		if (repeatValue === CALENDAR_REPEAT_FREQUENCY.weekly && !byDay) {
			byDay = [{ day: WEEK_DAYS[moment(event.start).day()] }];
		}

		return {
			add: {
				rule: {
					interval: {
						intervalCount: customIntervalRule || 1
					},
					frequency: repeatValue,
					...(endsOnDate && {
						until: {
							date: endsOnDate
						}
					}),
					...(endsAfterRecur && {
						count: {
							number: endsAfterRecur
						}
					}),
					byday: byDay && { wkday: byDay },
					bymonthday: byMonthDay && { dayList: byMonthDay },
					bymonth: byMonth && { monthList: byMonth },
					bysetpos: bySetPos && { poslist: bySetPos }
				}
			}
		};
	};

	splitFolderId = folderId => {
		const ids = folderId.split(':');
		return ids[1] ? ids[1] : ids[0];
	};

	handleSendInvite = () => {
		const {
			isEventFromMail,
			hasValidAttendee,
			eventData: { attendees = [], isDraft, neverSent },
			setEventState,
			shouldShowInviteModalOrNotification,
			queryParams: { tabid }
		} = this.props;

		if (neverSent && !hasValidAttendee(attendees)) {
			setEventState({
				tabId: tabid,
				eventData: {
					isDraft: true
				}
			});
			this.handleSubmit();
			return;
		}

		const showSendInviteModal = shouldShowInviteModalOrNotification(
			isDraft,
			neverSent,
			attendees,
			false,
			isEventFromMail
		);

		if (showSendInviteModal) {
			setEventState({
				tabId: tabid,
				eventData: {
					showSendInviteModal
				}
			});
		} else {
			this.handleSubmit();
		}
	};

	handleInviteResponse = shouldSendInvite => {
		const {
			setEventState,
			queryParams: { tabid }
		} = this.props;

		const update = {
			showSendInviteModal: false,
			...(shouldSendInvite !== null && {
				isDraft: !shouldSendInvite
			}),
			...(!shouldSendInvite && {
				neverSent: true
			})
		};

		setEventState({
			tabId: tabid,
			eventData: update
		});

		shouldSendInvite !== null && this.handleSubmit(update);
	};

	/**
	 * If event is not 'new' nor is 'organized' by current user and it's
	 * not open to propose new Time, then set `shouldSaveAsLocalCopy` as `true`.
	 * This flag will be used to set `appointment.message.emailAddresses` as `[]` instead of `invitees`
	 * to save event as local copy while modifying an event.
	 */
	shouldSaveAsLocalCopy = (event, isProposeTime, isForwardInvite) =>
		!(get(event, 'new') || get(event, 'isOrganizer') || isProposeTime || isForwardInvite);

	isSignificiantChange = isUnsavedEvent => {
		const {
			eventData,
			appointmentData,
			queryParams,
			editInstance,
			isCopyInvite,
			modifyAttendeeDataForCopy
		} = this.props;

		const updatedEvent = eventData.event;

		if (updatedEvent.new || isUnsavedEvent !== undefined || eventData.isAcceptingProposeNewTime) {
			return true;
		}

		const { significiantEvent, significiantEventData, allDay } =
			getEventSignificiantNonsignificantData(
				appointmentData,
				{},
				queryParams,
				editInstance,
				isCopyInvite,
				modifyAttendeeDataForCopy
			);

		const currentEvent = { ...significiantEvent, ...significiantEventData, allDay };

		const currentAttendees = currentEvent.attendees.map(attendee => attendee.address);
		const updatedAttendess = eventData.attendees.map(attendee => attendee.address);

		const currentLocations = currentEvent.locations || [];
		const updatedLocations = eventData.locations || [];

		const originalEventData = [
			currentEvent.start?.toString(),
			currentEvent.end?.toString(),
			currentEvent.allDay,
			currentEvent.name,
			currentEvent.notes,
			currentEvent.repeatValue,
			currentEvent.eventStartTimeZone,
			currentEvent.eventEndTimeZone,
			currentEvent.endsOnDate,
			currentEvent.endsAfterRecur,
			currentEvent.customByMonthDayRule,
			currentEvent.customByDayRule,
			currentEvent.customByMonthRule,
			currentEvent.customBySetPosRule,
			currentEvent.customIntervalRule,
			currentEvent.isCustomRecurringEvent,
			currentAttendees,
			currentLocations,
			currentEvent.attachments
		];

		const updatedEventData = [
			updatedEvent.start.toString(),
			updatedEvent.end.toString(),
			updatedEvent.allDay,
			updatedEvent.name,
			eventData.notes,
			eventData.repeatValue,
			eventData.eventStartTimeZone,
			eventData.eventEndTimeZone,
			eventData.endsOnDate,
			eventData.endsAfterRecur,
			eventData.customByMonthDayRule,
			eventData.customByDayRule,
			eventData.customByMonthRule,
			eventData.customBySetPosRule,
			eventData.customIntervalRule,
			eventData.isCustomRecurringEvent,
			updatedAttendess,
			updatedLocations,
			eventData.attachments
		];

		if (compareArrayContent(originalEventData, updatedEventData)) {
			return true;
		}

		return false;
	};
	handleSubmit = updateFromShouldSend => {
		let { ownerEmail } = this.props.eventData;
		const {
			eventInstance,
			editInstance,
			primaryAddress,
			calendars,
			userDisplayName,
			isForwardInvite,
			isProposeTime,
			queryParams: { tabid },
			eventData: {
				showAsValue,
				event,
				attendees = [],
				notes,
				isPrivate,
				attachments = [],
				selectedCalendar,
				locations = [],
				isDraft,
				neverSent,
				isFormDirty,
				allInviteesRemoved,
				eventStartTimeZone,
				eventEndTimeZone
			}
		} = this.props;

		let accountName = '';
		let folderIdShared = '';
		let destinationCalId;
		const prevEventFolderId = event.calendarId;
		const prevSelCal = calendars.find(
			cal => cal.id === prevEventFolderId || cal.sharedItemId === prevEventFolderId
		);
		let displayName = userDisplayName;

		// event.recurrence is required to show custom selection but needs to be deleted while editing a single instance of a recurring event.
		editInstance && delete event.recurrence;
		if ((prevSelCal && prevSelCal.owner) || selectedCalendar.owner) {
			if (!event.new) {
				folderIdShared = prevSelCal.sharedItemId || prevSelCal.id;
				// in case calendar of event is changed through edit event form, event should be moved to changed cal.
				destinationCalId = prevSelCal.id !== selectedCalendar.id && selectedCalendar.id;
				accountName = prevSelCal.owner;
			} else {
				accountName = selectedCalendar.owner;
			}
			ownerEmail = selectedCalendar.owner || primaryAddress;
			displayName = '';
		} else {
			ownerEmail = primaryAddress;
			accountName = '';
		}

		const promiseArray = this.getAttachPromises(attachments);
		Promise.all(promiseArray)
			.then(res => {
				const aidArr = res;
				this.props.onAction(
					{
						...event,
						locations: locations.map(location =>
							isString(location)
								? location
								: omit(location, ['isGalContact', 'type', 'thumbnailPhoto', 'id', 'attributes'])
						),
						attendees: attendees.map(att =>
							omit(att, [
								'__typename',
								'id',
								'attributes',
								'isGalContact',
								'type',
								'thumbnailPhoto'
							])
						),
						isPrivate,
						shouldSaveAsLocalCopy: this.shouldSaveAsLocalCopy(
							event,
							isProposeTime,
							isForwardInvite
						),
						newAttachments: aidArr,
						attachments: attachments.filter(att => att.url),
						notes,
						alarms: this.alarmsFromState(),
						folderId: get(event, 'folderId') && this.splitFolderId(event.folderId),
						ownerEmail,
						displayName,
						// When creating exception don't set recurrence info
						...(!editInstance && { recurrence: this.recurrenceFromState(this.props.eventData) }),
						freeBusy: showAsValue,
						editInstance,
						isDraft:
							updateFromShouldSend && updateFromShouldSend.isDraft !== undefined
								? updateFromShouldSend.isDraft
								: isDraft,
						significent: this.isSignificiantChange(updateFromShouldSend?.isDraft),
						neverSent:
							updateFromShouldSend && updateFromShouldSend.neverSent !== undefined
								? updateFromShouldSend.neverSent
								: neverSent,
						eventStartTimeZone,
						eventEndTimeZone,
						eventInstance
					},
					folderIdShared,
					accountName,
					destinationCalId,
					isFormDirty,
					allInviteesRemoved,
					tabid
				);
			})
			.catch(error => {
				console.error(error);
				let messageText = '';
				switch (error.faultCode) {
					case 'service.BLOCKED_FILE_TYPE_UPLOAD': {
						messageText = (
							<Text
								id="dialogs.attachmentWarning.attachmentType"
								fields={{
									restrictedType: error?.file?.type || '<unknown>',
									restrictedFile: error?.file?.name || '<unknown>'
								}}
							/>
						);
						break;
					}
					default: {
						messageText = (
							<Text
								id={`calendar.editModal.notifications.${
									event.new ? 'problemInCreating' : 'problemInModifying'
								}`}
							/>
						);
						break;
					}
				}

				this.props.notify({
					message: messageText,
					failure: true
				});
			});
	};

	getAttachPromises = attachments =>
		attachments &&
		attachments
			.filter(attachment => !attachment.url) // Ignore existing attachments
			.map(attachment =>
				this.props.attach(attachment, {
					filename: attachment.filename || attachment.name,
					contentType: attachment.contentType || attachment.type
				})
			);

	onDiscardChangeAction = () => {
		const {
			onClose,
			queryParams: { tabid, from },
			setEventState,
			isEventTabClosing,
			isMultipleEventTabClosing,
			setEventTabClosingState: setEventTabClosingStateAction,
			removeTab: removeTabAction,
			removeEventTabData: removeEventTabDataAction
		} = this.props;

		isEventTabClosing && setEventTabClosingStateAction(false);
		setEventState({
			tabId: tabid,
			eventData: {
				showDiscardChangeModal: false
			}
		});
		if (isMultipleEventTabClosing) {
			removeTabAction({ type: 'event', tabId: tabid });
			removeEventTabDataAction({ tabId: tabid });
			this.routeToNextUnsavedTab();
		} else onClose(tabid, from);
	};

	onClose = () => {
		const {
			onClose,
			queryParams: { tabid, from }
		} = this.props;
		if (!this.state.isChoosingAttachments) {
			onClose(tabid, from);
		}
	};

	onDiscardModalClose = () => {
		const {
			setEventState,
			queryParams: { tabid },
			isEventTabClosing,
			setEventTabClosingState,
			isMultipleEventTabClosing
		} = this.props;

		setEventState({
			tabId: tabid,
			eventData: {
				showDiscardChangeModal: false
			}
		});

		isEventTabClosing && setEventTabClosingState(false);
		isMultipleEventTabClosing && this.routeToNextUnsavedTab();
	};

	routeToNextUnsavedTab = () => {
		const { tabs, setMultipleEventTabClosing: setMultipleEventTabClosingAction } = this.props;
		if (tabs) {
			if (tabs.length > 1) route(tabs[tabs.length - 2].url);
			else if (tabs.length === 1) {
				route('/');
				setMultipleEventTabClosingAction(false);
			}
		}
	};

	onHandleUnsavedChanges = () => {
		const {
			queryParams: { tabid },
			eventData: { isFormDirty },
			setEventState
		} = this.props;
		if (isFormDirty) {
			setEventState({
				tabId: tabid,
				eventData: {
					showDiscardChangeModal: true
				}
			});
			return;
		}

		this.onClose();
	};

	isPrivateCheck = calendar => !calendar.permissions || calendar.permissions.includes('p');

	handleForwardAppointment = () => {
		const {
			eventData: {
				event: { inviteId }
			},
			forwardAppointment,
			forwardAppointmentInvite,
			prevLocation,
			eventInstance,
			source,
			queryParams: { tabid },
			removeTab: removeTabAction
		} = this.props;

		const message = this.forwardAppointmentMessage();
		const appointmentInvite = {
			id: inviteId,
			message
		};
		const endPoint = source === SOURCE_CALENDAR ? forwardAppointment : forwardAppointmentInvite;

		if (eventInstance) {
			appointmentInvite.exceptId = {
				timezone: moment.tz.guess(),
				date: eventInstance
			};
		}

		endPoint(appointmentInvite)
			.then(() => {
				this.props.notify({
					message: <Text id="calendar.editModal.forward" />
				});
			})
			.catch(err => {
				console.error(err);
				this.props.notify({
					message: get(err, 'message'),
					failure: true
				});
			});

		removeTabAction({ type: 'event', tabId: tabid });
		route(prevLocation || '/');
	};

	handleProposedTime = () => {
		const {
			counterAppointment,
			prevLocation,
			appointmentData,
			proposedTimeSubject,
			eventInstance,
			source,
			calendarUrlSlug,
			notify: notifyAction,
			eventData: { event, notes, eventStartTimeZone, eventEndTimeZone },
			queryParams: { tabid },
			removeTab: removeTabAction
		} = this.props;

		const message = get(appointmentData, 'message');
		counterAppointment({
			message,
			proposedTime: proposedTimeSubject,
			event,
			notes,
			eventStartTimeZone,
			eventEndTimeZone,
			eventInstance
		})
			.then(() => {
				notifyAction({
					message: <Text id="calendar.editModal.counterInvite.proposedTime" />
				});
			})
			.catch(err => {
				console.error(err);
				notifyAction({
					message: get(err, 'message'),
					failure: true
				});
			});

		removeTabAction({ type: 'event', tabId: tabid });

		source === SOURCE_CALENDAR ? route(`/${calendarUrlSlug}`) : route(prevLocation || '/');
	};

	forwardAppointmentMessage = () => {
		const {
			ownerDisplayName,
			activeAccountAddress,
			activeAccountDisplayName,
			appointmentData: { message },
			inviteTemplate,
			eventData: { forwardAttendees, notes, event, locations = [], ownerEmail }
		} = this.props;

		const appointment = get(message, 'invitations.0.components.0');
		let { organizer } = appointment;
		const { start, end, name } = event;

		const attendeesVal = forwardAttendees.filter(
			token => !isString(token) && isValidEmail(getEmail(token.address))
		);
		const emailAddresses = [];

		attendeesVal.map(value =>
			emailAddresses.push({
				address: value.address,
				type: 't'
			})
		);

		if (organizer === null) {
			organizer = {
				address: ownerEmail ? ownerEmail : activeAccountAddress,
				name: ownerDisplayName ? ownerDisplayName : activeAccountDisplayName
			};
		}

		const description = {
			mimeParts: {
				contentType: 'multipart/alternative',
				mimeParts: appointmentBody({
					organizer,
					start,
					end,
					location: createLocationPayload(locations),
					attendees: attendeesVal,
					subject: name,
					body: notes,
					template: inviteTemplate,
					message
				})
			}
		};
		return {
			subject: name,
			emailAddresses,
			...description
		};
	};

	static defaultProps = {
		title: 'calendar.editModal.title',
		editTitle: 'calendar.editModal.editTitle',
		forwardTitle: 'calendar.editModal.forwardTitle',
		proposeTimeTitle: 'calendar.editModal.counterInvite.proposeTimeTitle'
	};

	render(
		{
			title,
			isOffline,
			editTitle,
			inline,
			class: cls,
			className,
			matchesScreenMd,
			matchesScreenXs,
			editInstance,
			calendars,
			formClass,
			disableMobileToolbar = false,
			footerClass,
			hideAttachmentPreview = false,
			isForwardInvite,
			appointmentData,
			forwardTitle,
			createEvent,
			zimbraPrefCalendarReminderEmail,
			zimbraPrefUseTimeZoneListInCalendar,
			identitiesInfo,
			activeAccountAddress,
			activeAccountDisplayName,
			ownerDisplayName,
			proposeTimeTitle,
			isProposeTime,
			eventInstance,
			queryParams,
			isLocation,
			isCopyInvite,
			isEventTabClosing,
			hasValidAttendee,
			eventData: {
				attendees,
				forwardAttendees,
				event,
				showSendInviteModal,
				showDiscardChangeModal,
				showInEventNotification,
				eventStartTimeZone,
				eventEndTimeZone,
				isStartDateValid,
				isEndDateValid,
				ownerEmail
			},
			isMultipleEventTabClosing,
			insertableCalendar,
			accountInfoData: {
				attrs: {
					zimbraMailAttachmentMaxSize,
					zimbraMtaMaxMessageSize,
					zimbraFeatureViewInHtmlEnabled,
					zimbraFeatureDocumentEditingEnabled
				}
			}
		},
		{}
	) {
		const start = moment(event.start);
		const invalidDateRange = start.diff(event.end) > 0;

		const nonResourceAttendees = attendees.filter(
			attendee => attendee.calendarUserType !== CALENDAR_USER_TYPE.resource
		);

		const nonResourceForwardAttendees = forwardAttendees.filter(
			attendee => attendee.calendarUserType !== CALENDAR_USER_TYPE.resource
		);

		const selectedAttendee = isForwardInvite ? nonResourceForwardAttendees : nonResourceAttendees;

		const organizer = get(appointmentData, 'message.invitations.0.components.0.organizer');

		let organizerName;
		if (!organizer) {
			if (ownerEmail && ownerEmail !== activeAccountAddress) {
				organizerName = ownerDisplayName;
			} else {
				organizerName = activeAccountDisplayName;
			}
		} else {
			organizerName = displayAddress(organizer);
		}

		const dialogTitle =
			(isForwardInvite && forwardTitle) ||
			(isProposeTime && proposeTimeTitle) ||
			(event.new && title) ||
			editTitle;

		const disabledElement = (isForwardInvite || isProposeTime) && s.disabledElement;

		const saveButtonVisible =
			event.name &&
			((!isForwardInvite && !selectedAttendee.length) ||
				isProposeTime ||
				(selectedAttendee.length &&
					hasValidAttendee(isForwardInvite ? forwardAttendees : attendees)));

		const localCopyNotify = !isEmpty(event) &&
			this.shouldSaveAsLocalCopy(event, isProposeTime, isForwardInvite) && (
				<InEventNotification
					notificationText="calendar.editModal.notifications.inEventNotificationLocalCopy"
					fullWidth
				/>
			);

		let desktopHeading, mobileHeading;
		if (matchesScreenMd) {
			// desktop view
			desktopHeading = (
				<DesktopHeading
					title={dialogTitle}
					field={organizerName}
					onClose={this.onHandleUnsavedChanges}
				/>
			);
		} else {
			mobileHeading = <MobileHeading title={dialogTitle} field={organizerName} />;
		}

		const areDatesValid = !invalidDateRange && isStartDateValid && isEndDateValid;

		const eventTitle = event.name;

		return (
			<div className={cx(cls, className, s.wrapper, inline && s.inlineWrapper)}>
				{desktopHeading}
				{mobileHeading}
				{localCopyNotify}
				<AlignedForm class={cx(s.formWrapper, formClass)}>
					{showInEventNotification && (
						<InEventNotification
							notificationText="calendar.editModal.notifications.inEventNotificationText"
							linkText="calendar.editModal.notifications.inEventNotificationAction"
							onLinkClick={this.handleInviteResponse}
						/>
					)}
					<AppointmentTitle tabId={queryParams.tabid} isProposeTime={isProposeTime} />
					<AttendeesChooser
						tabId={queryParams.tabid}
						disabledElement={disabledElement}
						isForwardInvite={isForwardInvite}
						isProposeTime={isProposeTime}
						appointmentData={appointmentData}
						isLocation={isLocation}
						identitiesInfo={identitiesInfo}
						matchesScreenXs={matchesScreenXs}
						hasValidAttendee={hasValidAttendee}
						showToAddressField
					/>
					<AppointmentStartTime
						tabId={queryParams.tabid}
						isProposeTime={isProposeTime}
						isForwardInvite={isForwardInvite}
						zimbraPrefUseTimeZoneListInCalendar={zimbraPrefUseTimeZoneListInCalendar}
						eventStartTimeZone={eventStartTimeZone}
						momentTz={momentTz}
					/>
					<AppointmentEndTime
						tabId={queryParams.tabid}
						isProposeTime={isProposeTime}
						isForwardInvite={isForwardInvite}
						zimbraPrefUseTimeZoneListInCalendar={zimbraPrefUseTimeZoneListInCalendar}
						eventEndTimeZone={eventEndTimeZone}
						momentTz={momentTz}
					/>
					<RecurrenceSelect
						eventInstance={eventInstance}
						createEvent={createEvent}
						disabledElement={disabledElement}
						editInstance={editInstance}
						isForwardInvite={isForwardInvite}
						isProposeTime={isProposeTime}
						isPrivateCheck={this.isPrivateCheck}
						recurrenceFromState={this.recurrenceFromState}
						tabId={queryParams.tabid}
					/>

					<AttendeesChooser
						tabId={queryParams.tabid}
						disabledElement={disabledElement}
						isForwardInvite={isForwardInvite}
						isProposeTime={isProposeTime}
						appointmentData={appointmentData}
						isLocation={isLocation}
						identitiesInfo={identitiesInfo}
						matchesScreenXs={matchesScreenXs}
						matchesScreenMd={matchesScreenMd}
						hasValidAttendee={hasValidAttendee}
						renderVideoZimletSlot
					/>

					<EventNotes
						tabId={queryParams.tabid}
						isForwardInvite={isForwardInvite}
						isProposeTime={isProposeTime}
						isOffline={isOffline}
						hideAttachmentPreview={hideAttachmentPreview}
						zimbraMailAttachmentMaxSize={zimbraMailAttachmentMaxSize || 0}
						zimbraMtaMaxMessageSize={zimbraMtaMaxMessageSize || 0}
						zimbraFeatureViewInHtmlEnabled={zimbraFeatureViewInHtmlEnabled}
						zimbraFeatureDocumentEditingEnabled={zimbraFeatureDocumentEditingEnabled}
					/>
					<SetReminder
						tabId={queryParams.tabid}
						zimbraPrefCalendarReminderEmail={zimbraPrefCalendarReminderEmail}
						isForwardInvite={isForwardInvite}
						isProposeTime={isProposeTime}
						disabledElement={disabledElement}
						isCopyInvite={isCopyInvite}
						appointmentData={appointmentData}
					/>

					<SetShowAs
						tabId={queryParams.tabid}
						isForwardInvite={isForwardInvite}
						isProposeTime={isProposeTime}
						disabledElement={disabledElement}
					/>

					<SelectedCalendar
						tabId={queryParams.tabid}
						isForwardInvite={isForwardInvite}
						isProposeTime={isProposeTime}
						disabledElement={disabledElement}
						calendars={calendars}
						insertableCalendar={insertableCalendar}
						appointmentData={appointmentData}
						getSelectedCalendar={getSelectedCalendar}
						isPrivateCheck={this.isPrivateCheck}
					/>
				</AlignedForm>
				<AppointmentEditToolbar
					isMobileActive={!disableMobileToolbar && !matchesScreenMd}
					{...(saveButtonVisible &&
						areDatesValid && {
							onSave: isForwardInvite
								? this.handleForwardAppointment
								: isProposeTime
								? this.handleProposedTime
								: this.handleSendInvite
						})}
					onCancel={this.onHandleUnsavedChanges}
					footerClass={footerClass}
					isForwardInvite={isForwardInvite}
					isProposeTime={isProposeTime}
				/>
				{showSendInviteModal && (
					<ConfirmModalDialog
						title={<Text id="calendar.dialogs.sendInvite.title" />}
						cancelButton={false}
						onResult={this.handleInviteResponse}
						acceptText="buttons.send"
						rejectText="buttons.doNotSend"
						contentClass={s.discardModalContent}
					/>
				)}
				{(showDiscardChangeModal || isEventTabClosing) && (
					<ModalDialog
						title="calendar.dialogs.discardChanges.title"
						actionLabel="calendar.dialogs.discardChanges.buttonText"
						onAction={this.onDiscardChangeAction}
						onClose={this.onDiscardModalClose}
						contentClass={!isMultipleEventTabClosing && s.discardModalContent}
					>
						{isMultipleEventTabClosing &&
							(eventTitle ? (
								<MarkupText id="calendar.dialogs.discardChanges.forLabel" fields={{ eventTitle }} />
							) : (
								<MarkupText id="calendar.dialogs.discardChanges.forLabelWithoutTitle" />
							))}
					</ModalDialog>
				)}
			</div>
		);
	}
}
