import cx from 'classnames';
import { Text } from 'preact-i18n';
import Select from '../../select';
import style from './style.less';
import { FILTER_ACTION_TYPE } from '../../../constants/filter-rules';
import get from 'lodash-es/get';
import TextInput from '../../text-input';
import CloseButton from '../../close-button';
import { callWith } from '@zimbra/util/src/call-with';
import TagAction from './tag-action';

export default function AdvancedFilterActionInput({
	action,
	onChange,
	onFolderOrAddressChange,
	onDeleteAction,
	folders,
	filterMailFeature,
	tagList
}) {
	return (
		<div class={cx(style.subsectionBody, style.advancedActions, style.advancedMainBody)}>
			<div class={cx(style.half, style.inline, style.itemSelect, style.inputSelectField)}>
				<Select
					value={
						action.key === FILTER_ACTION_TYPE.FLAG &&
						action.flagName === FILTER_ACTION_TYPE.MARK_AS_READ
							? FILTER_ACTION_TYPE.MARK_AS_READ
							: action.key
					}
					class={cx(style.inputField, style.actionDropDown)}
					onChange={onChange(action)}
					fullWidth
				>
					<option value={FILTER_ACTION_TYPE.FILE_INTO}>
						<Text id="settings.filterRuleModal.actions.moveToFolder" />
					</option>
					<option value={FILTER_ACTION_TYPE.DISCARD}>
						<Text id="settings.filterRuleModal.actions.discard" />
					</option>
					{filterMailFeature && (
						<option value={FILTER_ACTION_TYPE.REDIRECT}>
							<Text id="settings.filterRuleModal.actions.redirect" />
						</option>
					)}
					<option value={FILTER_ACTION_TYPE.FLAG}>
						<Text id="settings.filterRuleModal.actions.flag" />
					</option>
					<option value={FILTER_ACTION_TYPE.KEEP_IN_INBOX}>
						<Text id="settings.filterRuleModal.actions.keep" />
					</option>
					<option value={FILTER_ACTION_TYPE.MARK_AS_READ}>
						<Text id="settings.filterRuleModal.actions.markAsRead" />
					</option>
					{tagList && (
						<option value={FILTER_ACTION_TYPE.TAG} disabled={!tagList.length}>
							<Text id="settings.filterRuleModal.actions.tagWith" />
						</option>
					)}
				</Select>
			</div>
			<div class={cx(style.itemSelect, style.advancedActionSubsectionBody, style.inputSelectField)}>
				{action.key === FILTER_ACTION_TYPE.FILE_INTO && (
					<MoveToFolderSelect
						folders={folders}
						value={action}
						action={action.key}
						onChange={onFolderOrAddressChange}
					/>
				)}

				{action.key === FILTER_ACTION_TYPE.REDIRECT && (
					<TextInput
						class={cx(
							style.textInputOfFilter,
							style.actionsInput,
							style.inputFieldForRedirectAddress,
							style.inputField
						)}
						type="email"
						onChange={onFolderOrAddressChange(action, 'address')}
						value={get(action, 'address')}
					/>
				)}
				{action.key === FILTER_ACTION_TYPE.TAG && (
					<TagAction
						tagList={tagList}
						onChange={onFolderOrAddressChange(action, 'tagName')}
						action={action.key}
						value={action}
					/>
				)}
			</div>
			<CloseButton
				class={style.advancedCloseButton}
				onClick={callWith(onDeleteAction, { item: action, isCondition: false })}
				inverse
			/>
		</div>
	);
}

function MoveToFolderSelect({ folders, value, onChange }) {
	return (
		<Select
			value={get(value, 'folderPath')}
			onChange={onChange(value, 'folderPath')}
			class={cx(style.inputField, style.actionDropDown)}
			fullWidth
		>
			{folders.map(fPath => (
				<option value={fPath} key={fPath}>
					{fPath.search(/\//g) === -1 ? <Text id={`folders.${fPath}`}>{fPath}</Text> : fPath}
				</option>
			))}
		</Select>
	);
}
