import ActionMenu, { DropDownWrapper } from '../../../action-menu';
import ActionMenuGroup from '../../../action-menu-group';
import ActionMenuItem from '../../../action-menu-item';

import { Text } from 'preact-i18n';
import style from './style';

export default function SharingOptions({ onToggleDetails }) {
	return (
		<ActionMenu
			icon="ellipsis-h"
			monotone
			arrow={false}
			anchor="end"
			actionButtonClass={style.button}
			popoverClass={style.popover}
		>
			<DropDownWrapper>
				<ActionMenuGroup>
					<ActionMenuItem disabled={!onToggleDetails} onClick={onToggleDetails}>
						<Text id="buttons.details" />
					</ActionMenuItem>
					{/* Todo:- Use when adding other sharing options	 */}
					{/* <ActionMenuItem disabled={!onEditItem} onClick={onEditItem}>
						<Text id="buttons.edit" />
					</ActionMenuItem>
					<ActionMenuItem disabled={!onDeleteItem} onClick={onDeleteItem}>
						<Text id="buttons.delete" />
					</ActionMenuItem> */}
				</ActionMenuGroup>
			</DropDownWrapper>
		</ActionMenu>
	);
}
