import { Component } from 'preact';
import NakedButton from '../naked-button';
import { Text } from 'preact-i18n';
import saveToModalDialog from './save-to-modal-dialog';

import cx from 'classnames';
import s from './style.less';
import { filterOutEMLattachment } from '../../lib/util';

export default class AttachmentGridHeader extends Component {
	onDownload = () => {
		const { onDownload, onDownloadAll, attachments } = this.props;
		attachments.length === 1 ? onDownload(attachments[0]) : onDownloadAll(attachments);
		this.setState({ showSaveAttachmentModal: false });
	};

	onCloseModal = () => {
		this.setState({ showSaveAttachmentModal: false });
	};

	onSave = () => {
		const useModal =
			this.context.zimlets.isSlotActive('slot::attachment-single-action') ||
			this.context.zimlets.isSlotActive('slot::attachment-multi-action');

		if (useModal) {
			this.setState({ showSaveAttachmentModal: true });
		} else {
			this.onDownload();
		}
	};

	render({ attachments, isUploading, removable, onRemoveAll }, { showSaveAttachmentModal }) {
		if (attachments.length > 0) {
			if (removable) {
				return (
					<div class={cx(s.hideBelowXs, s.buttonsContainer)}>
						<div>
							<Text
								id="compose.attachment.title"
								plural={attachments.length}
								fields={{ count: attachments.length }}
							/>
						</div>
						<div class={s.buttonDivider}>
							<NakedButton onClick={onRemoveAll} class={s.button} disabled={isUploading}>
								<Text id="compose.attachment.remove" plural={attachments.length} />
							</NakedButton>
						</div>
					</div>
				);
			}

			const hasDownloadServices =
				this.context.zimlets.isSlotActive('slot::attachment-single-action') ||
				this.context.zimlets.isSlotActive('slot::attachment-multi-action');

			return (
				<div class={cx(s.hideBelowXs, s.buttonsContainer)}>
					<div>
						<Text
							id="compose.attachment.title"
							plural={attachments.length}
							fields={{ count: attachments.length }}
						/>
					</div>
					{filterOutEMLattachment(attachments).length > 0 && (
						<div class={s.buttonDivider}>
							<NakedButton onClick={this.onSave} class={s.button} disabled={isUploading}>
								<Text
									id={
										hasDownloadServices ? 'compose.attachment.save' : 'compose.attachment.download'
									}
									plural={attachments.length}
								/>
							</NakedButton>
						</div>
					)}
					{hasDownloadServices &&
						showSaveAttachmentModal &&
						saveToModalDialog(attachments, this.onCloseModal, this.onDownload)}
				</div>
			);
		}
	}
}
