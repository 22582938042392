import { AutoCompleteGAL } from '../graphql/queries/calendar/auto-complete-gal.graphql';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import withAccountInfo from './account-info';

export default function withAutoCompleteGAL({
	queryAccessor = 'value',
	typeAccessor = 'type',
	skip,
	...config
} = {}) {
	return compose(
		withAccountInfo(({ data: { accountInfo: account } }) => ({
			zimbraFeatureGalEnabled: account.attrs.zimbraFeatureGalEnabled,
			zimbraFeatureGalAutoCompleteEnabled: account.attrs.zimbraFeatureGalAutoCompleteEnabled
		})),
		graphql(AutoCompleteGAL, {
			options: props => ({
				context: {
					debounceKey: 'autoCompleteGAL'
				},
				variables: {
					name: props[queryAccessor],
					type: props[typeAccessor],
					needExp: true
				}
			}),
			...config,
			skip: ({ zimbraFeatureGalEnabled, zimbraFeatureGalAutoCompleteEnabled, ...args }) => {
				if (zimbraFeatureGalEnabled && zimbraFeatureGalAutoCompleteEnabled) {
					return typeof skip === 'function' ? skip(args) : skip;
				}
				return true;
			}
		})
	);
}
