import { Icon } from '@zimbra/blocks';
import { Text, Localizer } from 'preact-i18n';
import cx from 'classnames';
import style from './style.less';

export default function CloseButton({ class: cls, inverse, ...props }) {
	return (
		<Localizer>
			<button
				aria-label={<Text id="buttons.close" />}
				{...props}
				class={cx(style.close, cls, inverse && style.inverse)}
			>
				<Icon name="close" />
			</button>
		</Localizer>
	);
}
