import { Fragment } from 'preact';
import cx from 'classnames';
import debounce from 'lodash-es/debounce';
import flatten from 'lodash-es/flatten';
import findIndex from 'lodash-es/findIndex';
import { KeyCodes } from '@zimbra/blocks';
import { flattenFolders, filteredFolders } from '../../utils/folders';
import { useState, useCallback, useMemo, useEffect } from 'preact/hooks';
import ActionMenuGroup from '../action-menu-group';
import ActionMenuFilter from '../action-menu-filter';
import Folder from '../folder';

import s from './style.less';

const FolderGroups = ({ groups, groupClass, ...rest }) =>
	groups.map(
		group =>
			group &&
			group.length > 0 && (
				<ActionMenuGroup class={cx(s.folderGroup, groupClass)}>
					{group.map(f => (
						<Folder {...rest} depth={1} folder={f} selected={rest.keyboardSelection === f.id} />
					))}
				</ActionMenuGroup>
			)
	);

function selectOffsetFolder(folderGroups, currentId, offset = 1) {
	const folders = flattenFolders(flatten(folderGroups)).filter(f => !f.disabled);
	if (!folders.length) {
		return null;
	}

	const index = findIndex(folders, f => f.id === currentId);
	if (index === -1 && offset >= 1) {
		return folders[0].id;
	}
	if (index === 0 && offset <= -1) {
		return currentId;
	}

	const nextFolder = folders[index + offset];
	return (nextFolder && nextFolder.id) || currentId;
}

export default function FolderSelect({ folders = [], onMove, onBack, folderGroupClass, ...rest }) {
	const [filterValue, setFilterValue] = useState('');
	const [keyboardSelection, setKeyboardSelection] = useState(null);

	const folderGroups = useMemo(
		() => (filterValue !== '' ? [filteredFolders(folders, filterValue)] : rest.folderGroups),
		[filterValue, folders, rest.folderGroups]
	);

	const handleFilterChange = debounce(e => {
		const value = e.target.value;
		const newFolderGroups = [filteredFolders(folders, value)];

		setFilterValue(value);
		setKeyboardSelection(value === '' ? null : selectOffsetFolder(newFolderGroups, null, 1));
	}, 225);

	const handleKeyboardNavigation = useCallback(
		(e, offset = 1) => {
			setKeyboardSelection(selectOffsetFolder(folderGroups, keyboardSelection, offset));
		},
		[folderGroups, keyboardSelection]
	);

	const clearStateValues = useCallback(() => {
		setFilterValue('');
		setKeyboardSelection(null);
	}, []);

	const moveItem = useCallback(
		folder => {
			clearStateValues();
			onMove(folder);
		},
		[clearStateValues, onMove]
	);

	const handleKeyboardEnter = useCallback(() => {
		if (!keyboardSelection) {
			return;
		}
		const destFolder = flattenFolders(folders).find(f => f.id === keyboardSelection);
		moveItem(destFolder);
	}, [folders, keyboardSelection, moveItem]);

	const handleFilterKeydown = useCallback(
		e => {
			switch (e.keyCode) {
				case KeyCodes.DOWN_ARROW:
					e.preventDefault();
					handleKeyboardNavigation(e, 1);
					break;
				case KeyCodes.UP_ARROW:
					e.preventDefault();
					handleKeyboardNavigation(e, -1);
					break;
				case KeyCodes.CARRIAGE_RETURN:
					handleKeyboardEnter(e);
					break;
				default:
					handleFilterChange(e);
					break;
			}
		},
		[handleKeyboardNavigation, handleFilterChange, handleKeyboardEnter]
	);

	const clearKeyboardSelection = useCallback(
		e => {
			// When programatically scrolling for keyboard selection, do not
			// clear the selection
			if (e.movementX === 0 && e.movementY === 0) {
				return;
			}

			if (keyboardSelection !== null) {
				setKeyboardSelection(null);
			}
		},
		[keyboardSelection]
	);

	const handleItemClick = useCallback(
		(e, folder) => {
			e.stopPropagation();
			moveItem(folder);
		},
		[moveItem]
	);

	useEffect(() => {
		return () => {
			handleFilterChange.cancel();
		};
	}, [handleFilterChange]);

	return (
		<Fragment>
			<ActionMenuFilter
				value={filterValue}
				onKeyDown={handleFilterKeydown}
				onBack={onBack}
				onClear={clearStateValues}
				placeholderTextId="mail.folders.FIND_PLACEHOLDER"
			/>

			<FolderGroups
				groups={folderGroups}
				keyboardSelection={keyboardSelection}
				onClick={handleItemClick}
				onMouseEnter={clearKeyboardSelection}
				groupClass={folderGroupClass}
			/>
		</Fragment>
	);
}
