import { Component } from 'preact';
import CloseOnClickOrKeydown from '../close-on-click-and-keydown';
import { createPortal } from 'preact/compat';
import cx from 'classnames';
import style from './style';
import { TAB } from '../../constants/key-codes';
import focusable from 'focusable';
import linkRef from 'linkref';

export default function Dialog({
	centered,
	onClickOutside,
	disableOutsideClick,
	disableEscape,
	...props
}) {
	return (
		<CloseOnClickOrKeydown
			onClickOutside={onClickOutside}
			disableEscape={disableEscape}
			disableOutsideClick={disableOutsideClick}
		>
			<div
				role="dialog"
				{...props}
				class={cx(style.dialog, centered && style.centered, props.class)}
			/>
		</CloseOnClickOrKeydown>
	);
}

export function FixedDialog(props) {
	return createPortal(
		<Dialog {...props} class={cx(style.fixed, props.class)} />,
		document.querySelector(props.into || 'body')
	);
}

// TODO: Capture/prevent scrolling underneath Modal in iOS - Blocked by https://bugs.webkit.org/show_bug.cgi?id=153852
export class ModalDialog extends Component {
	handleKeyDown = e => {
		if (e.keyCode === TAB) {
			const focusableChildren =
				this.refs.container && this.refs.container.querySelectorAll(focusable);
			if (!focusableChildren || !focusableChildren.length) {
				e.preventDefault();
				return;
			}

			if (e.shiftKey) {
				if (e.target === focusableChildren[0]) {
					e.preventDefault();
					focusableChildren[focusableChildren.length - 1].focus();
				}
			} else if (e.target === focusableChildren[focusableChildren.length - 1]) {
				e.preventDefault();
				focusableChildren[0].focus();
			}
		}
	};

	static defaultProps = {
		centered: true // Unlike other dialogs, the ModalDialog is centered by default
	};

	componentWillMount() {
		document.body.classList.add(this.props.bodyClass || style.noScroll);
		addEventListener('keydown', this.handleKeyDown);
	}

	componentDidMount() {
		this.initialActiveElement = document.activeElement;
		const child =
			this.refs.container &&
			this.refs.container.querySelectorAll(focusable)[this.props.autofocusChildIndex || 0];
		child && child.focus();
	}

	componentWillReceiveProps({ bodyClass }) {
		if (bodyClass !== this.props.bodyClass) {
			document.body.classList.remove(this.props.bodyClass);
			bodyClass && document.body.classList.add(bodyClass);
		}
	}

	componentWillUnmount() {
		document.body.classList.remove(this.props.bodyClass || style.noScroll);
		removeEventListener('keydown', this.handleKeyDown);
		this.initialActiveElement && this.initialActiveElement.focus();
	}

	render({
		overlayClass,
		into,
		disableOutsideClick,
		disableEscape = false,
		autofocusChildIndex,
		...props
	}) {
		return createPortal(
			<div ref={linkRef(this, 'container')} class={cx(style.overlay, overlayClass)}>
				{disableOutsideClick && disableEscape ? (
					<div
						role="dialog"
						{...props}
						class={cx(style.dialog, props.centered && style.centered, props.class)}
					/>
				) : (
					<Dialog
						{...props}
						disableOutsideClick={disableOutsideClick}
						disableEscape={disableEscape}
					/>
				)}
			</div>,
			document.querySelector(into || 'body')
		);
	}
}
