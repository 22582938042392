import { Fragment } from 'preact';
import { useState, useCallback, useEffect } from 'preact/hooks';
import { Text, withText } from 'preact-i18n';
import cx from 'classnames';
import { isValidEmail, getInputValue } from '../../../lib/util';
import { Spinner, Button, ChoiceInput, VerticalList } from '@zimbra/blocks';
//Why does Settings uses its own Select component instead of the one in blocks?
import Select from '../../select';
import ComboBox from '../../combo-box';
import AlignedForm from '../../aligned-form';
import AlignedLabel from '../../aligned-form/label';
import ErrorAlert from '../../error-alert';
import { errorMessage, faultCode } from '../../../utils/errors';
import FormGroup from '../../form-group';
import Label from '../../label';
import TextInput from '../../text-input';
import { USER_FOLDER_IDS } from './../../../constants';
import { INBOX } from './../../../constants/folders';
import { MAIL_VIEW } from './../../../constants/views';
import FolderListLight from './../../folder-list-light';
import { recursivelyFilterFolders, getPathOfSelectedFolders } from './../../../utils/folders';
import { useFoldersQuery } from './../../../hooks/folders';
import get from 'lodash-es/get';
import { useMediaQuery } from '../../../enhancers/use-media-query';
import { maxWidth, screenXsMax } from '../../../constants/breakpoints';

import style from '../style';
import ConfirmPersonaDelete from './confirm-delete-persona';

const excludeFolders = [USER_FOLDER_IDS.DRAFTS, USER_FOLDER_IDS.CHAT];

const getMessageViewFolders = data => {
	const folders = get(data, 'getFolder.folders.0.folders', []).filter(
		f => (!f.view || f.view === MAIL_VIEW) && !excludeFolders.includes(parseInt(f.id, 10))
	);

	const sharedFolders = recursivelyFilterFolders(
		get(data, 'getFolder.folders.0.linkedFolders') || [],
		MAIL_VIEW
	);
	return [...folders, ...sharedFolders];
};

// Contains fileds which will needs to be validated on form load
const initialFieldValues = {
	name: '',
	replyToEnabled: false,
	replyToAddress: '',
	whenSentToEnabled: false,
	whenSentToAddresses: [],
	whenInFoldersEnabled: false,
	whenInFolderIds: []
};

const formErrors = {};

function PersonaForm({
	fromAccounts,
	externalAccounts,
	delegatedAccounts,
	formData: formDataProp,
	isEditView,
	onSubmit,
	sendTypes: { sendAs, sendOBO },
	handleValidityOfTabs,
	activeId,
	identityExists,
	onChange
}) {
	const { matchesScreenXs } = useMediaQuery(maxWidth(screenXsMax), 'matchesScreenXs');

	// Manage error and loading states
	const [replyToAddressError, setReplyToAddressError] = useState(false);
	const [whenSentToAddressesError, setWhenSentToAddressesError] = useState(false);
	const [whenSentToFolderIdsError, setWhenSentToFolderIdsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [submissionError, setSubmissionError] = useState(null);
	const [submitValidationError, setSubmitValidationError] = useState(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	// Fetch Folder data
	const { data: folderList, loading: fetchingFolders } = useFoldersQuery({
		fetchPolicy: 'cache-only',
		variables: {
			view: null
		}
	});

	// Set Default Data
	const [formData, setFormData] = useState({
		emailAddress: '',
		fromDisplay: '',
		replyToDisplay: '',
		...initialFieldValues,
		...formDataProp
	});

	// `replyForwardEmail` - input field for `whenSentToAddresses`
	const [replyForwardEmail, setReplyForwardEmail] = useState('');
	const [selectedReplyForwardEmails, setSelectedReplyForwardEmails] = useState(
		[].concat(formData.whenSentToAddresses).filter(Boolean)
	);

	// for `whenInFolderIds`
	const [selectedFolderIds, setWhenInFolderIds] = useState(
		[].concat(formData.whenInFolderIds).filter(Boolean)
	);

	const formErrorValidate = useCallback(() => {
		let hasFormError = false;

		Object.keys(formErrors).forEach(fieldName => {
			if (formErrors[fieldName]) {
				hasFormError = true;
				return;
			}
		});

		setSubmitValidationError(hasFormError);

		onChange && handleValidityOfTabs(activeId, hasFormError);
	}, [activeId, handleValidityOfTabs, onChange]);

	useEffect(() => {
		// On Component Mount, Validate fields with existing values for Edit mode.
		onChange &&
			Object.keys(initialFieldValues).forEach(key => updateFormData(key, formData[key], true));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateFormData = useCallback(
		(key, value, validateOnly = false) => {
			let currFieldError;
			let errorKey = key;

			switch (key) {
				case 'name':
					currFieldError = !value.trim();
					break;
				case 'replyToEnabled':
					currFieldError =
						(value && !formData.replyToAddress) ||
						(formData.replyToAddress && !isValidEmail(formData.replyToAddress));

					setReplyToAddressError(currFieldError);
					errorKey = 'replyToAddress';
					break;
				case 'replyToAddress':
					currFieldError = (value && !isValidEmail(value)) || (formData.replyToEnabled && !value);

					setReplyToAddressError(currFieldError);
					errorKey = 'replyToAddress';
					break;
				case 'whenSentToEnabled':
					// whenSentToAddresses
					currFieldError = value && !selectedReplyForwardEmails.length;

					setWhenSentToAddressesError(currFieldError);
					errorKey = 'replyForwardEmail';
					break;
				case 'replyForwardEmail':
					// whenSentToAddresses
					currFieldError =
						selectedReplyForwardEmails.length > 0
							? !!value && !isValidEmail(value)
							: formData.whenSentToEnabled && selectedReplyForwardEmails.length === 0;

					setReplyForwardEmail(value);
					setWhenSentToAddressesError(currFieldError);
					formErrors.replyForwardEmail = currFieldError;

					// Disable Settings Modal Save button if any error
					formErrorValidate();
					// No changes need to update in case of temp input field `replyForwardEmail`
					return;
				case 'whenSentToAddresses':
					currFieldError = formData.whenSentToEnabled && !value.length;

					setWhenSentToAddressesError(currFieldError);
					errorKey = 'replyForwardEmail';
					break;
				case 'whenInFoldersEnabled':
					currFieldError = value && !selectedFolderIds.length;

					setWhenSentToFolderIdsError(currFieldError);
					errorKey = 'whenInFolderIds';
					break;
				case 'whenInFolderIds':
					currFieldError = formData.whenInFoldersEnabled && !value.length;

					setWhenSentToFolderIdsError(currFieldError);
					errorKey = 'whenInFolderIds';
					break;
			}

			// To avoid unnecessary keys to be stored
			if (currFieldError !== undefined) {
				formErrors[errorKey] = currFieldError;
			}

			// Disable Settings Modal Save button if any error
			formErrorValidate();

			// If validateOnly is true, skip data update.
			if (validateOnly) return;

			setFormData({
				...formData,
				[key]: value
			});

			// Trigger when persona form is in Edit mode.
			onChange && onChange(key, value);
		},
		[
			formErrorValidate,
			formData,
			onChange,
			selectedReplyForwardEmails.length,
			selectedFolderIds.length
		]
	);

	// In Edit Mode, Save all the changes to Main Account Settings Object
	const handleChange = useCallback(
		e => {
			const key = e.target.name;
			const value = getInputValue(key === 'emailAddress' ? e.nativeEvent : e);
			updateFormData(key, value);
		},
		[updateFormData]
	);

	const onSubmitPersona = useCallback(() => {
		const {
			name,
			emailAddress,
			fromDisplay,
			replyToEnabled,
			replyToAddress,
			replyToDisplay,
			whenInFoldersEnabled,
			whenSentToEnabled: whenSentToEnabledVal
		} = formData;

		let hasFormValidationError = false;

		if (replyToEnabled && (!replyToAddress || !isValidEmail(replyToAddress))) {
			setReplyToAddressError(true);
			hasFormValidationError = true;
			return;
		}

		if (
			whenSentToEnabledVal &&
			((replyForwardEmail && !isValidEmail(replyForwardEmail)) ||
				selectedReplyForwardEmails.length === 0)
		) {
			hasFormValidationError = true;
			setWhenSentToAddressesError(true);
		}

		if (whenInFoldersEnabled && !selectedFolderIds.length) {
			hasFormValidationError = true;
		}

		if (hasFormValidationError) {
			setSubmitValidationError(true);
			return;
		}

		const fromSplit = emailAddress && emailAddress.split(' ');

		setSubmitting(true);
		setSubmissionError(null);

		onSubmit({
			formData: {
				emailAddress: (fromSplit && fromSplit[fromSplit.length - 1]) || fromAccounts[0],
				emailAddressType: fromSplit && fromSplit.length > 1 ? sendOBO : sendAs,
				name,
				fromDisplay,
				replyToEnabled: !!replyToEnabled,
				replyToAddress,
				replyToDisplay,
				whenInFoldersEnabled,
				whenInFolderIds: selectedFolderIds,
				whenSentToEnabled: whenSentToEnabledVal,
				whenSentToAddresses: selectedReplyForwardEmails
			}
		})
			.then(() => {
				setSubmitting(false);
			})
			.catch(err => {
				const errCode = faultCode(err);
				setSubmitting(false);
				setSubmissionError(
					errCode === 'account.IDENTITY_EXISTS' ? identityExists : errorMessage(err)
				);
			});
	}, [
		formData,
		fromAccounts,
		identityExists,
		onSubmit,
		replyForwardEmail,
		selectedFolderIds,
		selectedReplyForwardEmails,
		sendAs,
		sendOBO
	]);

	const handleReplyForwardInputChange = useCallback(
		e => {
			setReplyForwardEmail(e.target.value.trim());
			formErrorValidate();
			// Set state only if it's prev value is true
			whenSentToAddressesError && setWhenSentToAddressesError(false);
		},
		[formErrorValidate, whenSentToAddressesError]
	);

	const handleAddUserClick = useCallback(() => {
		if (!replyForwardEmail) return;

		if (!isValidEmail(replyForwardEmail)) {
			// Set error for WhenSentToAddresses Email
			setWhenSentToAddressesError(true);
			formErrors.replyForwardEmail = true;
			formErrorValidate();
			return;
		} else if (whenSentToAddressesError) {
			setWhenSentToAddressesError(false);
		}

		if (!selectedReplyForwardEmails.includes(replyForwardEmail)) {
			const updatedList = selectedReplyForwardEmails.concat(replyForwardEmail);
			setSelectedReplyForwardEmails(updatedList);
			updateFormData('whenSentToAddresses', updatedList);
		}

		setReplyForwardEmail('');
	}, [
		formErrorValidate,
		replyForwardEmail,
		selectedReplyForwardEmails,
		updateFormData,
		whenSentToAddressesError
	]);

	const handleRemoveUserClick = useCallback(
		email => {
			const updatedList = selectedReplyForwardEmails.filter(emailAddr => emailAddr !== email);
			setSelectedReplyForwardEmails(updatedList);
			updateFormData('whenSentToAddresses', updatedList);
		},
		[selectedReplyForwardEmails, updateFormData]
	);

	const handleRemovePersona = useCallback(() => {
		setShowConfirmDelete(!showConfirmDelete);
	}, [showConfirmDelete]);

	const handleFolderSelectClick = useCallback(
		(_, selectedIds) => {
			setWhenInFolderIds(selectedIds);
			updateFormData('whenInFolderIds', selectedIds);
		},
		[updateFormData]
	);

	// All folder list
	const allFolders = getMessageViewFolders(folderList);
	// Convert folder ids to String
	const selectedFolderIdList = selectedFolderIds.map(String);
	// Find ids which needs to be expanded - If any nested folder id is selected,
	// It should expand all parent folders.
	const expandFolderIds = getPathOfSelectedFolders(allFolders, selectedFolderIdList);

	return submitting || fetchingFolders ? (
		<Spinner block />
	) : (
		<Fragment>
			<p>
				<Text id="settings.accounts.addPersona.personaDescription" />
			</p>
			<form action="javascript:" onSubmit={onSubmitPersona}>
				{submissionError && (
					<ErrorAlert>
						<Text id={submissionError}>{submissionError}</Text>
					</ErrorAlert>
				)}
				<div class={cx(style.subsectionBody, style.marginBottomLg, style.personaSubsection)}>
					<AlignedForm class={style.alignedForm}>
						<FormGroup class={style.formGroup} rows>
							<Label id="settings.accounts.addPersona.personaNameLabel" large />
							<TextInput
								onChange={handleChange}
								value={formData.name}
								name="name"
								autofocus
								required
								wide
								class={style.personaName}
							/>
						</FormGroup>
					</AlignedForm>

					<AlignedForm class={style.alignedForm}>
						<FormGroup
							rows={matchesScreenXs}
							compact={!matchesScreenXs}
							class={style.sectionHeading}
						>
							<Label id="settings.accounts.addPersona.fromSettingsLabel" large />
						</FormGroup>
						<FormGroup class={style.formGroup} rows={matchesScreenXs} compact={!matchesScreenXs}>
							<AlignedLabel
								align="left"
								textId="settings.accounts.addPersona.fromAddressLabel"
								width="185px"
								class={style.optionalLabel}
							/>
							<Select
								value={formData.emailAddress || fromAccounts[0]}
								onChange={handleChange}
								name="emailAddress"
								fullWidth
							>
								{[...fromAccounts, ...delegatedAccounts].map((account, index) => (
									<option key={`persona-from-address-${index}`} value={account}>
										{account}
									</option>
								))}
							</Select>
						</FormGroup>
						<FormGroup class={style.formGroup} rows={matchesScreenXs} compact={!matchesScreenXs}>
							<AlignedLabel
								align="left"
								textId="settings.accounts.addPersona.fromNameLabel"
								width="185px"
								class={style.optionalLabel}
							/>
							<div class={style.subsectionBody}>
								<TextInput
									name="fromDisplay"
									onChange={handleChange}
									value={formData.fromDisplay}
									class={style.optionalInput}
								/>
								<div class={style.helperText}>
									<Text id="settings.accounts.addPersona.fromNameHelper" />
								</div>
							</div>
						</FormGroup>
					</AlignedForm>

					<AlignedForm class={style.alignedForm}>
						<FormGroup
							rows={matchesScreenXs}
							compact={!matchesScreenXs}
							class={style.sectionHeading}
						>
							<Label id="settings.accounts.addPersona.replyToSettingsLabel" large />
						</FormGroup>
						<FormGroup class={style.formGroup} rows={matchesScreenXs} compact={!matchesScreenXs}>
							<div class={cx(style.subsection, style.expandedSubsection)}>
								<AlignedLabel
									align="left"
									textId="settings.accounts.addPersona.replyToLabel"
									width={matchesScreenXs ? '100%' : '185px'}
									class={cx(style.optionalLabel, style.multiline)}
								/>
								<div class={style.subsectionBody}>
									<ul>
										<li>
											<ChoiceInput
												name="replyToEnabled"
												onChange={handleChange}
												checked={formData.replyToEnabled}
											/>
											<Text id="settings.accounts.addPersona.replyToToggle" />
										</li>
									</ul>
								</div>
							</div>
						</FormGroup>
						<Fragment>
							<FormGroup class={style.formGroup} rows={matchesScreenXs} compact={!matchesScreenXs}>
								<AlignedLabel
									align="left"
									width="185px"
									class={style.optionalLabel}
									textId="settings.accounts.addPersona.replyToEmailLabel"
								/>
								<ComboBox
									textInputVal={formData.replyToAddress}
									textOnChange={handleChange}
									textPlaceholderId="settings.accounts.addPersona.replyToPlaceholder"
									type="email"
									name="replyToAddress"
									selectOnChange={handleChange}
									textClass={style.optionalInput}
								>
									{[...fromAccounts, ...externalAccounts].map((account, index) => (
										<option
											name="replyToAddress"
											key={`persona-reply-to-${account}${index}`}
											value={account}
											selected={account === formData.replyToAddress ? 'selected' : ''}
										>
											{account}
										</option>
									))}
								</ComboBox>
							</FormGroup>
							{replyToAddressError && (
								<FormGroup
									class={style.formGroup}
									rows={matchesScreenXs}
									compact={!matchesScreenXs}
								>
									<AlignedLabel align="left" width="185px" class={style.optionalLabel} />
									<ErrorAlert>
										<Text id="settings.accounts.errors.forwardInvalidEmail" />
									</ErrorAlert>
								</FormGroup>
							)}
							<FormGroup class={style.formGroup} rows={matchesScreenXs} compact={!matchesScreenXs}>
								<AlignedLabel
									align="left"
									width="185px"
									class={style.optionalLabel}
									textId="settings.accounts.addPersona.replyToNameLabel"
								/>
								<div class={style.subsectionBody}>
									<TextInput
										name="replyToDisplay"
										onChange={handleChange}
										value={formData.replyToDisplay}
										class={style.optionalInput}
									/>
									<div class={style.helperText}>
										<Text id="settings.accounts.addPersona.replyToNameHelper" />
									</div>
								</div>
							</FormGroup>
						</Fragment>
					</AlignedForm>

					<AlignedForm class={style.alignedForm}>
						<FormGroup
							rows={matchesScreenXs}
							compact={!matchesScreenXs}
							class={style.sectionHeading}
						>
							<Label id="settings.accounts.addPersona.useThisPersonaLabel" large />
						</FormGroup>
						<FormGroup class={style.formGroup}>
							<AlignedLabel
								align="left"
								width="185px"
								class={style.optionalLabel}
								textId="settings.accounts.addPersona.replyForwardSentToLabel"
							/>
							<div class={style.subsectionBody}>
								<div class={cx(style.optionalInput, style.marginBottomMd)}>
									<ChoiceInput
										name="whenSentToEnabled"
										onChange={handleChange}
										checked={formData.whenSentToEnabled}
									/>
									<Text id="settings.accounts.addPersona.replyToToggle" />
								</div>
								<div class={cx(style.comboWithAddBtn)}>
									<ComboBox
										textInputVal={replyForwardEmail}
										textOnChange={handleChange}
										textPlaceholderId="settings.accounts.addPersona.replyToPlaceholder"
										name="replyForwardEmail"
										type="email"
										selectOnChange={handleReplyForwardInputChange}
										textClass={style.optionalInput}
									>
										{[...fromAccounts, ...delegatedAccounts].map((account, index) => (
											<option
												key={`persona-reply-forward-address-${account}${index}`}
												value={account}
											>
												{account}
											</option>
										))}
									</ComboBox>
									<Button onClick={handleAddUserClick}>
										<Text id="buttons.add" />
									</Button>
								</div>
								{whenSentToAddressesError && (
									<ErrorAlert class={style.marginTopMd}>
										<Text
											id={`settings.accounts.errors.${
												submitValidationError ? 'enterOrSelectEmail' : 'forwardInvalidEmail'
											}`}
										/>
									</ErrorAlert>
								)}

								<VerticalList
									items={selectedReplyForwardEmails}
									onRemoveItem={handleRemoveUserClick}
									class={style.addressListDirectionBottom}
								/>
							</div>
						</FormGroup>

						<FormGroup class={style.formGroup}>
							<AlignedLabel
								align="left"
								width="185px"
								class={style.optionalLabel}
								textId="settings.accounts.addPersona.replyForwardFolderLabel"
							/>
							<div class={style.subsectionBody}>
								<div class={cx(style.optionalInput, style.marginBottomMd)}>
									<ChoiceInput
										name="whenInFoldersEnabled"
										onChange={handleChange}
										checked={formData.whenInFoldersEnabled}
									/>
									<Text id="settings.accounts.addPersona.replyToToggle" />
								</div>
								{whenSentToFolderIdsError && (
									<ErrorAlert class={style.marginTopMd}>
										<Text id="settings.accounts.errors.selectOneOrMoreFolders" />
									</ErrorAlert>
								)}
								<FolderListLight
									folders={allFolders}
									onFolderClick={handleFolderSelectClick}
									indexFolderName={INBOX}
									selectedIds={selectedFolderIdList}
									class={cx(style.marginTopMd, style.folderList)}
									expandedFolderIds={expandFolderIds}
									collapsibleFolderGroupToggle
									collapsibleCustomGroup
									isMultiSelect
								/>
							</div>
						</FormGroup>
					</AlignedForm>

					{isEditView ? (
						<div class={style.subsectionBody}>
							<Button
								styleType="primary"
								brand="danger"
								type="button"
								onClick={handleRemovePersona}
								alignLeft
							>
								<Text id="buttons.deletePersona" />
							</Button>
							<div class={style.confirmationSpan}>
								<Text id="settings.accounts.editPersona.deletePersonaHelper" />
							</div>
						</div>
					) : (
						<Button
							styleType="primary"
							brand="primary"
							type="submit"
							disabled={submitValidationError}
						>
							<Text id="buttons.addPersona" />
						</Button>
					)}

					{showConfirmDelete && (
						<ConfirmPersonaDelete
							id={formData.id}
							accountName={get(formData, 'name')}
							onCancel={handleRemovePersona}
						/>
					)}
				</div>
			</form>
		</Fragment>
	);
}

export default withText({
	identityExists: 'faults.account.IDENTITY_EXISTS'
})(PersonaForm);
