import { useCallback } from 'preact/hooks';
import { ChoiceInput } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash-es/get';
import { setAdvanceOptions } from '../../../store/search/actions';
import style from '../style';

export const Checkbox = ({ field }) => {
	const dispatch = useDispatch();
	const value = useSelector(state => get(state, `search.${field}`, false));

	const handleSubjectAndBody = useCallback(
		() => dispatch(setAdvanceOptions({ [field]: !value })),
		[dispatch, field, value]
	);

	return (
		<div className={style.searchInItem}>
			<ChoiceInput id={field} onChange={handleSubjectAndBody} checked={value} />
			<label for={field}>
				<Text id={`search.advanced.${field}`} />
			</label>
		</div>
	);
};
