import { createAction } from 'redux-actions';

export const setDate = createAction('calendar setDate');
export const toggleModal = createAction('calendar toggleModal');
export const setEvent = createAction('calendar setEvent');
export const addNewEvent = createAction('calendar addNewEvent');
export const removeEventData = createAction('calendar removeEventData');
export const removeSpecificEventTabData = createAction('calendar removeSpecificEventTabData');
export const removeAllEventTabsData = createAction('calendar removeAllEventTabsData');
export const setEventTabClosing = createAction('calendar setEventTabClosing');
export const setMultipleEventTabClosing = createAction('calendar setMultipleEventTabClosing');
