import { useBriefcaseSelectedItems } from './selected-item';
import { useClientConfig } from '../../../hooks/client-config';
import { route } from 'preact-router';
import { BRIEFCASE } from '../../../constants/folders';
import { updateSelection, clearSelection } from '../../../store/briefcase/actions';
import { getDataFromReduxStoreThunk } from '../../../utils/thunk';
import { useDispatch, batch } from 'react-redux';
import { useCallback } from 'preact/hooks';

export const useNavigateToNextItem = () => {
	const { itemToBeSelectAfterDelete: nextItem, currentFolder: selectedFolderName } =
		useBriefcaseSelectedItems();

	const dispatch = useDispatch();
	const {
		slugs: { search, briefcase }
	} = useClientConfig({ slugs: 'routes.slugs' });

	const navigateToItem = useCallback(() => {
		const folderAbsPath = selectedFolderName?.absFolderPath?.slice(1) || BRIEFCASE;
		const { id } = nextItem();
		if (id) {
			const isSearchView =
				dispatch(getDataFromReduxStoreThunk('url.routeProps'))?.isSearchView || false;

			if (isSearchView) {
				const searchLocation = dispatch(getDataFromReduxStoreThunk('url.location.search'));
				route(`/${search}/${briefcase}/${id}/${searchLocation}`);
			} else {
				route(`/${briefcase}/${encodeURIComponent(folderAbsPath)}/${id}`);
			}
			batch(() => {
				dispatch(clearSelection());
				dispatch(updateSelection([id]));
			});
		} else {
			dispatch(clearSelection());
		}
	}, [selectedFolderName?.absFolderPath, dispatch, nextItem, search, briefcase]);

	return navigateToItem;
};
