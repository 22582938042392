export const ALL_VIEW = 'all'; // psuedo-view for all folders
export const MAIL = 'mail';
export const MAIL_VIEW = 'message';
export const CONVERSATION_VIEW = 'conversation';
export const CONTACTS_VIEW = 'contact';
export const APPOINTMENT_VIEW = 'appointment';
export const TASK_VIEW = 'task';
export const CALENDAR_VIEW = 'calendar';
export const BRIEFCASE_VIEW = 'document';

// psuedo-view for contact groups
export const CONTACTS_GROUP_VIEW = 'contactGroup';
