export function getSelectedIds(state) {
	return state.mail.selectedIds;
}

export function getNavigatedId(state) {
	return state.mail.navigatedId;
}

export function getMailFolder(state, folderName) {
	return state.mail.conversations[folderName];
}
