import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'preact-i18n';
import { Button, ModalDialog } from '@zimbra/blocks';
import find from 'lodash-es/find';
import cx from 'classnames';
import {
	CONTACT_GROUP_PREFIX,
	HAB_GROUP_PREFIX,
	SHAREDFOLDER_GROUP_PREFIX
} from '../../constants/contacts';
import HabGroupListLight from './hab-group-list-light';
import { USER_FOLDER_IDS } from '../../constants';
import style from './style';
import { interleaveFoldersWithGroups } from '../../utils/contact-folders';
import SourceContactList from './source-contact-list';
import ChosenContactList from './chosen-contact-list';
import { useCallback } from 'preact/hooks';
import { getDataFromReduxStoreThunk } from '../../utils/thunk';

const contactsById = {};

export default function ContactPickerDialog({
	contacts = [],
	pending,
	folders = [],
	sharedFolders = [],
	contactGroups,
	into,
	habGroups,
	addressFieldType,
	folder,
	additionalContacts,
	zimbraFeatureGalEnabled,
	onClose,
	onSave,
	setFolder
}) {
	const dispatch = useDispatch();
	const getContact = useCallback(
		id => {
			const contactById = contactsById[id];

			if (contactById) {
				return contactById;
			}

			const { to, cc, bcc } = additionalContacts;
			return find([...contacts, ...to, ...cc, ...bcc], ({ id: contactId }) => id === contactId);
		},
		[additionalContacts, contacts]
	);

	const save = useCallback(() => {
		const chosen = dispatch(getDataFromReduxStoreThunk(state => state?.contactChooser?.chosen));
		const { to, cc, bcc } = chosen;

		onSave(
			addressFieldType
				? {
						to: to.map(getContact),
						cc: cc.map(getContact),
						bcc: bcc.map(getContact)
				  }
				: to.map(getContact)
		);
		onClose();
	}, [addressFieldType, getContact, onClose, onSave, dispatch]);

	const onFolderClick = useCallback(
		selectedFolder => {
			let folderId = selectedFolder.id;
			if (selectedFolder.__typename === 'Contact') {
				folderId = `${CONTACT_GROUP_PREFIX}${folderId}`;
			} else if (selectedFolder.sharedItemId) {
				folderId = `${SHAREDFOLDER_GROUP_PREFIX}${selectedFolder.name}`;
			} else if (selectedFolder.parentHabGroupId || selectedFolder.habGroups) {
				folderId = `${HAB_GROUP_PREFIX}${folderId}`;
			}
			setFolder(folderId || null);
		},
		[setFolder]
	);

	const interFolders = interleaveFoldersWithGroups(
		folders.filter(({ id }) => USER_FOLDER_IDS.TRASH !== Number(id)),
		contactGroups
	);

	return (
		<ModalDialog
			overlayClass={style.backdrop}
			class={cx(style.dialog, style.scrollable)}
			onClickOutside={onClose}
			into={into}
		>
			<div class={style.inner}>
				<header class={style.header}>
					<h2>
						<Text id="contacts.picker.title" />
					</h2>
				</header>
				<div class={style.contentContainer}>
					<div class={style.folderList}>
						<HabGroupListLight
							folders={interFolders}
							onFolderClick={onFolderClick}
							habGroups={habGroups}
							folder={folder}
							sharedFolders={sharedFolders}
						/>
					</div>
					<SourceContactList
						contacts={contacts}
						contactsById={contactsById}
						folder={folder}
						zimbraFeatureGalEnabled={zimbraFeatureGalEnabled}
						habGroups={habGroups}
					/>
					<ChosenContactList
						getContact={getContact}
						addressFieldType={addressFieldType}
						additionalContacts={additionalContacts}
					/>
				</div>
				<footer class={style.footer}>
					<Button styleType="primary" onClick={save} disabled={pending}>
						<Text id="buttons.done" />
					</Button>
					<Button onClick={onClose}>
						<Text id="buttons.cancel" />
					</Button>
					<SelectedCount />
				</footer>
			</div>
		</ModalDialog>
	);
}

const SelectedCount = () => {
	const selected = useSelector(state => state?.contactChooser?.selected);
	return (
		<span class={style.selectionState}>
			<Text
				id="contacts.picker.numOfSelectedContacts"
				plural={selected.length}
				fields={{ count: selected.length }}
			/>
		</span>
	);
};
