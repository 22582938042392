import cloneDeep from 'lodash-es/cloneDeep';
import {
	FORWARD,
	REPLY,
	REPLY_ALL,
	FORWARD_SUBJECT_PREFIX,
	REPLY_SUBJECT_PREFIX
} from '../constants/mail';
import { getAccountById } from '../utils/account';
import array from '@zimbra/util/src/array';
import { getEmailBody, htmlToText } from '../lib/html-email';
import incomingMessage from '../components/composer/vhtml-templates/incoming-message';
import newMessageDraft from './new-message-draft';
import get from 'lodash-es/get';

export function getRecipientsInToAndCcField(
	toAddress,
	ccAddress,
	fromAddress,
	emailAddresses,
	accountAddress,
	type,
	accountAlias = []
) {
	const isAlias = email => accountAlias.includes(email);
	// Extract Reply To Address
	const replyToAddress = emailAddresses.find(({ type: addressType }) => addressType === 'r');

	// Replying to a message sent by self, should adjust 'To' and 'cc' field appropriately.
	if (fromAddress?.[0]?.address === accountAddress) {
		// Check and Handle 'Reply to' scenario
		return {
			toAddressValue: replyToAddress
				? [replyToAddress]
				: (toAddress || []).filter(({ address }) => !isAlias(address)),
			ccAddressValue: (ccAddress || []).filter(({ address }) => !isAlias(address))
		};
	}
	// Handle 'Reply to' scenario
	let toAddressValue = replyToAddress ? [replyToAddress] : fromAddress;
	const ccAddressValue = (ccAddress || []).filter(
		({ address }) => address !== accountAddress && !isAlias(address)
	);

	if (type === REPLY) {
		return { toAddressValue, ccAddressValue };
	}
	toAddressValue = toAddressValue.concat(
		(toAddress || []).filter(({ address }) => address !== accountAddress && !isAlias(address))
	);
	return { toAddressValue, ccAddressValue };
}

export default function draftForMessage(
	type,
	message,
	accountAddr,
	intl,
	accountAlias,
	dataSourcesInfo,
	identitiesInfo,
	activeAccountId,
	account
) {
	const subjectPrefix = type === FORWARD ? FORWARD_SUBJECT_PREFIX : REPLY_SUBJECT_PREFIX;
	const { from, subject, ...restMsg } = message;
	const draftSubject = `${subjectPrefix}: ${(subject || '').replace(/^((fwd|re):\s*)*/gi, '')}`;

	const bodyWithReply = incomingMessage({
		from: from && from[0],
		subject,
		body: getEmailBody(cloneDeep(message), { allowImages: true }),
		template: get(intl.dictionary, 'calendar.invite'),
		...restMsg
	});

	const baseResponseMessage = {
		...newMessageDraft(),
		attachments: array(message.attachments),
		conversationId: message.conversationId,
		html: bodyWithReply,
		inReplyTo: message.messageId,
		inlineAttachments: array(message.inlineAttachments),
		origId: message.id.toString(),
		origFolderId: message.folderId.toString(),
		origTo: message.to,
		origCc: message.cc,
		subject: draftSubject,
		local: message.local || false,
		text: htmlToText(bodyWithReply)
	};

	const recipients = getRecipientsInToAndCcField(
		message.to,
		message.cc,
		message.from || [],
		message.emailAddresses,
		accountAddr,
		type,
		accountAlias
	);

	const fromAndReplyTo = getFromAndReplyTo(
		dataSourcesInfo,
		identitiesInfo,
		activeAccountId,
		account,
		message
	);

	switch (type) {
		case REPLY:
			return {
				...baseResponseMessage,
				replyType: 'r',
				to: recipients.toAddressValue,
				from: fromAndReplyTo.from,
				replyTo: fromAndReplyTo.replyTo,
				attachments: []
			};

		case REPLY_ALL:
			return {
				...baseResponseMessage,
				cc: recipients.ccAddressValue,
				replyType: 'r',
				to: recipients.toAddressValue,
				from: fromAndReplyTo.from,
				replyTo: fromAndReplyTo.replyTo,
				attachments: []
			};
		case FORWARD:
			return {
				...baseResponseMessage,
				replyType: 'w',
				from: fromAndReplyTo.from,
				replyTo: fromAndReplyTo.replyTo
			};
		default:
			throw new Error(`Unsupported response type '${type}'`);
	}
}

function getFromAndReplyTo(dataSourcesInfo, identitiesInfo, activeAccountId, account, message) {
	const fromAndReplyTo = { from: null, replyTo: null };
	// for backword compatibility
	if (!dataSourcesInfo || !identitiesInfo || !activeAccountId || !account) {
		return fromAndReplyTo;
	}

	let activeAccount = getAccountById(dataSourcesInfo, identitiesInfo, activeAccountId);
	// identity
	if (activeAccount && activeAccount.__typename === 'Identity' && activeAccount._attrs) {
		const {
			zimbraPrefFromAddress,
			zimbraPrefFromDisplay,
			zimbraPrefReplyToEnabled,
			zimbraPrefReplyToAddress,
			zimbraPrefReplyToDisplay
		} = activeAccount._attrs;
		// primary account
		if (activeAccountId === account.id) {
			// check persona
			const toCc = getToAndCcEmailAddresses(message.to, message.cc);
			const persona = identitiesInfo.identity.find(identity => {
				const {
					zimbraPrefWhenInFoldersEnabled,
					zimbraPrefWhenInFolderIds,
					zimbraPrefWhenSentToEnabled,
					zimbraPrefWhenSentToAddresses
				} = identity._attrs || {};
				return (
					(zimbraPrefWhenInFoldersEnabled &&
						(zimbraPrefWhenInFolderIds || []).indexOf(message.folderId) !== -1) ||
					(zimbraPrefWhenSentToEnabled &&
						(zimbraPrefWhenSentToAddresses || []).filter(item => toCc.includes(item)).length)
				);
			});
			activeAccount = persona || activeAccount;
		}
		fromAndReplyTo.from = [
			{
				address: zimbraPrefFromAddress,
				displayName: zimbraPrefFromDisplay,
				name: zimbraPrefFromDisplay || zimbraPrefFromAddress.split('@')[0],
				type: 'f'
			}
		];
		if (zimbraPrefReplyToEnabled && zimbraPrefReplyToAddress) {
			fromAndReplyTo.replyTo = [
				{
					address: zimbraPrefReplyToAddress,
					name: zimbraPrefReplyToDisplay || zimbraPrefReplyToAddress.split('@')[0]
				}
			];
		}
	} else if (activeAccount && activeAccount.__typename === 'DataSource') {
		// see graphql-decorators/account-info/normalized-identities.js#normalizeDataSource
		const emailAddress =
			activeAccount.emailAddress ||
			(activeAccount.username.indexOf('@') !== -1 && activeAccount.username) ||
			`${activeAccount.username}@${activeAccount.host}`;

		fromAndReplyTo.from = [
			{
				address: emailAddress,
				displayName: activeAccount.fromDisplay,
				name: activeAccount.fromDisplay || emailAddress.split('@')[0],
				type: 'f'
			}
		];
		if (activeAccount.useAddressForForwardReply && activeAccount.replyToAddress) {
			fromAndReplyTo.replyTo = [
				{
					address: activeAccount.replyToAddress,
					name: activeAccount.replyToDisplay || activeAccount.replyToAddress.split('@')[0]
				}
			];
		}
	} else {
		// fallback: use default account if active account info is not found for some reason
		activeAccount = getAccountById(dataSourcesInfo, identitiesInfo, account.id);
		fromAndReplyTo.from = [
			{
				address: activeAccount._attrs.zimbraPrefFromAddress,
				displayName: activeAccount._attrs.zimbraPrefFromDisplay,
				name:
					activeAccount._attrs.zimbraPrefFromDisplay ||
					activeAccount._attrs.zimbraPrefFromAddress.split('@')[0],
				type: 'f'
			}
		];
		if (
			activeAccount._attrs.zimbraPrefReplyToEnabled &&
			activeAccount._attrs.zimbraPrefReplyToAddress
		) {
			fromAndReplyTo.replyTo = [
				{
					address: activeAccount._attrs.zimbraPrefReplyToAddress,
					name:
						activeAccount._attrs.zimbraPrefReplyToDisplay ||
						activeAccount._attrs.zimbraPrefReplyToAddress.split('@')[0]
				}
			];
		}
	}
	return fromAndReplyTo;
}

function getToAndCcEmailAddresses(to, cc) {
	return (to || []).concat(cc || []).map(obj => obj.address);
}

export function findAccountForFrom(accounts, message) {
	const fromHeader = (message.from && message.from[0]) || { address: null, displayName: null };
	const replyToHeader = (message.replyTo && message.replyTo[0]) || {
		address: null,
		displayName: null
	};
	const fromAccount =
		fromHeader &&
		accounts.find(account => {
			// check From header
			const fromDisplay = account.fromDisplay || account.emailAddress.split('@')[0];
			const fromMatched =
				account.emailAddress === fromHeader.address && fromDisplay === fromHeader.displayName;
			// check Reply-To header
			let replyToMatched;
			if (account.replyToEnabled && account.replyToAddress) {
				const replyToDisplay = account.replyToDisplay || account.replyToAddress.split('@')[0];
				replyToMatched =
					account.replyToAddress === replyToHeader.address &&
					replyToDisplay === replyToHeader.displayName;
			} else {
				replyToMatched = !replyToHeader.address && !replyToHeader.displayName;
			}
			return fromMatched && replyToMatched;
		});
	return fromAccount;
}

export function getMatchedPersona(accounts, activeAccountId, message) {
	const activeAccount = accounts.find(account => account.id === activeAccountId);
	let persona;
	// check persona if it exists
	// persona is not used when an active account is a data source
	if (activeAccount.isPrimaryAccount && accounts.length > 1) {
		const originalToCc = getToAndCcEmailAddresses(message.origTo, message.origCc);
		persona = accounts.find(
			account =>
				(account.whenInFoldersEnabled &&
					(account.whenInFolderIds || []).indexOf(message.origFolderId) !== -1) ||
				(account.whenSentToEnabled &&
					(account.whenSentToAddresses || []).filter(item => originalToCc.includes(item)).length)
		);
	}
	return persona;
}
