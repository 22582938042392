let isOffline = !navigator.onLine;
const fireEvent = new Event('onlineStateChanged', { isOffline });

function watchNetworkStatus(cb) {
	function checker() {
		return cb(!navigator.onLine);
	}

	addEventListener('offline', checker);
	addEventListener('online', checker);

	return function () {
		removeEventListener('offline', checker);
		removeEventListener('online', checker);
	};
}

function ping(origin = '') {
	// Returns `true` if the app is OFFLINE
	return fetch(origin, {
		mode: 'no-cors',
		cache: 'no-store'
	})
		.catch(Object) // ignore errors
		.then(e => {
			// if e is an object (in case when the request completed, but response.ok is false, for e.g. 502 - Gateway timeout)
			if (e.ok === false && e.statusText) {
				e = e.statusText;
			}

			return (
				// chrome: 'Failed to fetch'
				/Failed to fetch/.test(e) ||
				// safari: 'The Internet connection appears to be offline'
				/The Internet connection appears to be offline/.test(e) ||
				// safari(>11.1): 'Type error'
				/Type error/.test(e) ||
				// for 502, 503, 504 errors
				/Bad Gateway/.test(e)
			);
		});
}

/**
 * Check actual network status by reaching out to some origin and checking
 * for "failed to fetch"
 * @returns {Promise<Boolean>}    returns TRUE if the network is disconnected. returns FALSE if the network connection is OK.
 */
function pollRealNetworkStatus(origin, skip, pollingDelay = 10000) {
	const timer = setInterval(() => {
		if (!(typeof skip === 'function' && skip())) {
			ping(origin).then(status => {
				if (isOffline !== status) {
					isOffline = status;
					window.dispatchEvent(fireEvent);
				}
			});
		}
	}, pollingDelay);

	return () => {
		clearInterval(timer);
	};
}

export function checkOfflineStatus() {
	return isOffline;
}

export function startWatchingOfflineStatus(zimbraOrigin) {
	return pollRealNetworkStatus(
		process.env.NODE_ENV !== 'production'
			? '//zimbra.org/index.html'
			: `${zimbraOrigin}/public/blank.html`
	);
}

export function watchOfflineStatus(cb) {
	const cancelWatcher = watchNetworkStatus(cb);

	function checker() {
		return cb(isOffline);
	}

	addEventListener('onlineStateChanged', checker);

	return function () {
		cancelWatcher();
		removeEventListener('onlineStateChanged', checker);
	};
}
