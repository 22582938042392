import { PLATFORM_TYPES } from '../constants/platform-types';

export const getPlatformType = () => {
	if (typeof process.env.ELECTRON_ENV !== 'undefined') {
		return PLATFORM_TYPES.ELECTRON;
	} else if (window.AndroidBridge || window?.device?.platform === 'Android') {
		return PLATFORM_TYPES.ANDROID;
	} else if (typeof webkit !== 'undefined' || window?.device?.platform === 'iOS') {
		return PLATFORM_TYPES.IOS;
	}
	return PLATFORM_TYPES.WEB;
};
