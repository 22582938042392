import { createContext } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { getBasePath } from '../../lib/util';
import { getServerPrefs } from '../../utils/desktop-prefs';

export const AppConfigContext = createContext();

export const AppConfigProvider = ({ children }) => {
	const [data, setData] = useState(null);

	useEffect(() => {
		if (typeof process.env.ELECTRON_ENV !== 'undefined') {
			import(`../../runtime-config.json`)
				.then(d =>
					setData({
						...d.default,
						...(typeof process.env.ELECTRON_ENV !== 'undefined' && getServerPrefs())
					})
				)
				.catch(e => console.error('Error loading app-config.json', e));
		} else {
			fetch(`${getBasePath()}/runtime-config.json`)
				.then(d => d.ok && d.json())
				.then(d => setData(d))
				.catch(e => console.error('Error loading app-config.json', e));
		}
	}, []);

	const { Provider } = AppConfigContext;
	return <Provider value={data}>{data && children}</Provider>;
};

export default AppConfigContext;
