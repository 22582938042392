export function setFocusToZimbra() {
	if (typeof process.env.ELECTRON_ENV !== 'undefined') {
		const { rendererIpc } = require('@zimbra/electron-app');

		return rendererIpc.setFocusToApp();
	}

	// Fake promise for web
	return new Promise(resolve => {
		window.focus();
		resolve();
	});
}
