import partition from 'lodash-es/partition';

const executeSyncEvents = (syncEvents, data) => syncEvents.forEach(event => event.listener(data));

const executeAsyncEvents = (asyncEvents, data) =>
	asyncEvents.reduce((promise, event) => {
		return promise.then(result => event.listener(data).then([].concat(result)));
	}, Promise.resolve([]));

/**
 * An event emitter that allows emit event from zimbra webclient and listen into zimlet.
 * It executes event listener one by one.
 * It supports asynchronous event listener,
 * which means if an event listener returns a promise then emitter will wait to execute next handler until promise gets resolved.
 */
export const zimletEventEmitter = {
	_events: {},
	on(name, listener, isAsync = false) {
		this._events[name] = this._events[name] || [];

		this._events[name].push({
			isAsync,
			listener
		});
	},

	off(name, listenerToRemove) {
		if (!this._events[name]) {
			throw new Error(`Can't remove a listener. Event "${name}" doesn't exits.`);
		}

		const filterListeners = event => event.listener !== listenerToRemove;

		this._events[name] = this._events[name].filter(filterListeners);

		if (!this._events[name].length) {
			delete this._events[name];
		}
	},

	emit(name, data, callback = () => {}) {
		const events = this._events[name];

		if (!events) {
			callback();
			return;
		}

		try {
			const [asyncEvents, syncEvents] = partition(events, e => e.isAsync);

			/* In the case of presence of async event listeners,
			 * first, execute async listeners one after another and then
			 * execute sync listeners and then execute callback at the end.
			 */
			executeAsyncEvents(asyncEvents, data)
				.then(() => executeSyncEvents(syncEvents, data))
				.then(callback);
		} catch (error) {
			console.error(`EventEmitter: Error executing ${name}`, error);
			callback();
		}
	}
};
