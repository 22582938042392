import pickBy from 'lodash-es/pickBy';

export function getInitiatedRequests(state) {
	return (
		Object.keys(
			pickBy(
				state.pollingRequests.requests,
				({ isInQueue, isRunning, isCompleted }) =>
					(!isInQueue && !isRunning && !isCompleted) || isInQueue || isRunning
			)
		) || []
	);
}

export function getQueuedRequests(state) {
	return (
		Object.keys(
			pickBy(
				state.pollingRequests.requests,
				({ isInQueue, isRunning, isCompleted }) => isInQueue && !isRunning && !isCompleted
			)
		) || []
	);
}

export function getRemovedRequests(state) {
	return pickBy(state.pollingRequests.requests, ({ isCompleted }) => isCompleted);
}
