import { IntlContext } from 'preact-i18n';
import { useContext } from 'preact/hooks';

export function withIntlConsumer(mapProps) {
	return BaseComponent => {
		return function Consumer(props) {
			const value = useContext(IntlContext);

			if (mapProps) {
				const mapped = mapProps(value);
				return <BaseComponent {...props} {...mapped} />;
			}

			return <BaseComponent {...props} {...value} />;
		};
	};
}
