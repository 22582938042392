import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import { getMoveFolderItems } from '../../utils/folders';
import { getArchiveZimletMailboxMetadata } from '../../graphql-decorators/mailbox-metadata';
import ActionMenu from '../action-menu';
import FolderSelect from '../folder-select';
import { withText } from 'preact-i18n';
import { useCallback } from 'preact/hooks';

const _ActionMenuMoveFolder = ({
	folders: allFolders,
	sharedFolders,
	localFolders,
	disabled,
	onMove,
	iconOnly,
	arrow,
	monotone,
	actionButtonClass,
	popoverClass,
	flags,
	disableLocalFolderForMove,
	title,
	moveText
}) => {
	const { loading: pstLoading, pstRootFolderId } = useSelector(state => state?.importPst);
	return (
		<ActionMenu
			icon="folder-move"
			iconSize="md"
			label={moveText}
			disabled={disabled}
			arrow={arrow}
			iconOnly={iconOnly}
			monotone={monotone}
			actionButtonClass={actionButtonClass}
			popoverClass={popoverClass}
			title={title}
		>
			<DropDown
				allFolders={allFolders}
				sharedFolders={sharedFolders}
				localFolders={localFolders}
				{...(pstLoading && { pstRootFolderId })}
				onMove={onMove}
				flags={flags}
				disableLocalFolderForMove={disableLocalFolderForMove}
			/>
		</ActionMenu>
	);
};

const ActionMenuMoveFolder = compose(
	getArchiveZimletMailboxMetadata(),
	withText({
		moveText: 'mail.contextMenu.MOVE'
	})
)(_ActionMenuMoveFolder);

function DropDown({
	allFolders,
	flags,
	sharedFolders,
	disableLocalFolderForMove,
	localFolders,
	closeDropdown,
	onMove,
	pstRootFolderId
}) {
	const handleMove = useCallback(
		folder => {
			onMove(folder);
			//closeDropdown is passed in by <ActionMenu/>
			closeDropdown();
		},
		[closeDropdown, onMove]
	);

	const [folders, folderGroups] = getMoveFolderItems(
		allFolders,
		flags,
		sharedFolders,
		!disableLocalFolderForMove && localFolders,
		pstRootFolderId
	);

	return <FolderSelect folders={folders} folderGroups={folderGroups} onMove={handleMove} />;
}

export default ActionMenuMoveFolder;
