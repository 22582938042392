import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';
import { DEFAULT_SORT } from '../../constants/search';
import update from 'immutability-helper';

const initialState = {
	sortBy: DEFAULT_SORT,
	selectedIds: [],
	selectedFolder: null,
	percentage: {},
	attachmentNumbers: 0,
	isUploading: false,
	timeStamp: null,
	renameItemId: null,
	expandedIds: [],
	deleteWarningDocs: []
};

export default handleActions(
	{
		[actionCreators.updateSelection]: (state, action) => {
			return {
				...state,
				selectedIds: action.payload
			};
		},
		[actionCreators.clearSelection]: state => {
			return {
				...state,
				selectedIds: []
			};
		},
		[actionCreators.setBriefcaselListSortBy]: (state, action) => {
			return { ...state, sortBy: action.payload };
		},
		[actionCreators.updateFolderSelection]: (state, action) => {
			return {
				...state,
				selectedFolder: action.payload
			};
		},
		[actionCreators.updateUploadPercentage]: (state, action) => {
			return {
				...state,
				percentage: action.payload
			};
		},
		[actionCreators.setUploadAttachmentNumber]: (state, action) => {
			return {
				...state,
				attachmentNumbers: action.payload
			};
		},
		[actionCreators.updateUploadStatus]: (state, action) => {
			return {
				...state,
				isUploading: action.payload
			};
		},
		[actionCreators.setTimeStampForUrl]: (state, action) => {
			return {
				...state,
				timeStamp: action.payload
			};
		},
		[actionCreators.updateRenameItemId]: (state, action) => {
			return {
				...state,
				renameItemId: action.payload
			};
		},
		[actionCreators.addExpandedId]: (state, action) =>
			update(state, { expandedIds: { $push: [action.payload] } }),

		[actionCreators.removeIdFromExpanded]: (state, action) => {
			const itemIndex = state.expandedIds.indexOf(action.payload);
			if (itemIndex !== -1) {
				return update(state, { expandedIds: { $splice: [[itemIndex, 1]] } });
			}
			return state;
		},
		[actionCreators.setDeleteWarning]: (state, action) =>
			update(state, { deleteWarningDocs: { $set: action.payload } }),

		[actionCreators.removeDeleteWarning]: (state, action) => {
			const itemIndex = state.deleteWarningDocs.findIndex(doc => doc.id === action.payload);
			if (itemIndex !== -1) {
				return update(state, { deleteWarningDocs: { $splice: [[itemIndex, 1]] } });
			}
			return state;
		},
		[actionCreators.removeAllDeleteWarning]: state =>
			update(state, { deleteWarningDocs: { $set: [] } })
	},
	initialState
);
