import { Component } from 'preact';
import { callWith, updateDesktopBadgeCount } from '../../lib/util';
import { Icon, ModalDialog } from '@zimbra/blocks';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import update from 'immutability-helper';
import { toggle as toggleSettings } from '../../store/settings/actions';
import { toggle as toggleAbout } from '../../store/about/actions';
import { hide as hideSidebar } from '../../store/sidebar/actions';
import { Text } from 'preact-i18n';
import ActionMenuItem from '../action-menu-item';
import ActionMenu, { DropDownWrapper } from '../action-menu';
import ActionMenuGroup from '../action-menu-group';
import ActionMenuSettings from '../action-menu-settings';
import withMediaQuery from '../../enhancers/with-media-query/index';
import { notify } from '../../store/notifications/actions';
import { minWidth, screenMd } from '../../constants/breakpoints';
import Login from '../login';
import clientConfiguration from '../../enhancers/client-config';
import withAccountInfo from '../../graphql-decorators/account-info';
import withLogout from '../../graphql-decorators/logout';
import PhotoUploadModal from '../photo-upload-modal';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import ModifyProfileImageMutation from '../../graphql/queries/accounts/modify-profile-image.graphql';
import Avatar from '../avatar';
import wire from 'wiretie';
import s from './style.less';

import getContext from '../../lib/get-context';
import { LanguageModal } from '../language-modal';
import { withModifyPrefs } from '../../graphql-decorators/preferences';
import appConfiguration from '../../enhancers/app-config';
import ResetPasswordModal from '../reset-password-modal';
import withTracking from '../../enhancers/tracking';
import withIdentities from '../../graphql-decorators/get-identities';
import get from 'lodash-es/get';
import cx from 'classnames';
import ZimletSlot from '../zimlet-slot';
import { zimletEventEmitter } from '../../lib/zimlet-manager/zimlet-event-emitter';
import { ZIMBRA_ZIMLET_EVENTS } from '../../constants/zimbra-zimlet-events';

const profileImageFragment = gql`
	fragment profileImageInfo on AccountInfo {
		profileImageId
	}
`;
@withTracking()
@clientConfiguration('userHelpPath')
@appConfiguration('zimbraOrigin')
@getContext(({ zimbraBatchClient }) => ({ zimbraBatchClient }))
@wire('zimbra', {}, zimbra => ({
	attach: zimbra.attachment.upload
}))
@compose(
	withMediaQuery(minWidth(screenMd), 'matchesScreenMd'),
	connect(
		state => ({
			isOffline: state.network.isOffline,
			isLoggedOut: state.network.isLoggedOut
		}),
		(dispatch, { matchesScreenMd }) => ({
			toggleSettings: () => {
				dispatch(toggleSettings());
				if (!matchesScreenMd) {
					dispatch(hideSidebar());
				}
			},
			toggleAbout: () => {
				dispatch(toggleAbout());
			},
			notify: options => dispatch(notify(options))
		})
	),
	withLogout(),
	withAccountInfo(
		({
			data: {
				accountInfo: {
					id,
					name,
					profileImageId,
					changePasswordURL,
					attrs: { displayName, zimbraFeatureChangePasswordEnabled, zimbraTwoFactorAuthEnabled }
				}
			}
		}) => ({
			userName: name,
			displayName,
			profileImageId,
			zimbraFeatureChangePasswordEnabled,
			zimbraTwoFactorAuthEnabled,
			accountId: id,
			changePasswordURL
		})
	),
	withProps(({ displayName, userName, zimbraBatchClient, profileImageId }) => ({
		name: displayName ? displayName.split(' ')[0] : userName.split('@')[0],
		imageURL: profileImageId ? zimbraBatchClient.getProfileImageUrl(profileImageId) : ''
	}))
)
@withModifyPrefs()
@graphql(ModifyProfileImageMutation, {
	props: ({ mutate, ownProps: { accountId } }) => ({
		modifyProfileImage: variables =>
			mutate({
				variables,
				update: (
					proxy,
					{
						data: {
							modifyProfileImage: { itemId }
						}
					}
				) => {
					let profileImageData;

					try {
						profileImageData = proxy.readFragment({
							id: `AccountInfo:${accountId}`,
							fragment: profileImageFragment
						});
					} catch (error) {
						console.error('error while reading cache', error);
					}

					if (profileImageData) {
						proxy.writeFragment({
							id: `AccountInfo:${accountId}`,
							fragment: profileImageFragment,
							data: update(profileImageData, {
								profileImageId: {
									$set: itemId
								}
							})
						});
					}
				}
			})
	})
})
@withIdentities(data => {
	const identities = get(data, 'data.getIdentities.identity') || [];
	const defaultFromDisplay = get(
		identities.find(({ name }) => name === 'DEFAULT'),
		'_attrs.zimbraPrefFromDisplay'
	);

	return { defaultFromDisplay };
})
export default class HeaderActions extends Component {
	handleLogout = () => {
		const { logout } = this.props;

		zimletEventEmitter.emit(ZIMBRA_ZIMLET_EVENTS.LOGOUT, null, () => {
			updateDesktopBadgeCount(); // setting default i.e 0 to not show any indication
			logout();
		});
	};

	toggleModal = ({ modalState, status }) => {
		const { matchesScreenMd, closeSidebar } = this.props;
		status && !matchesScreenMd && closeSidebar && closeSidebar();
		this.setState({ [modalState]: status });
	};

	saveImage = imageData => {
		const re = /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,(.*)/gi;
		const result = re.exec(imageData);
		const { modifyProfileImage } = this.props;

		return modifyProfileImage({ content: result[2], contentType: result[1] });
	};

	removeImage = () => this.props.modifyProfileImage({ content: null, contentType: null });

	onLogin = () => {
		const { refetchAccount, notify: displayNotification } = this.props;

		displayNotification({
			message: <Text id="loginScreen.resetPass.passwordChanged" />
		});

		refetchAccount();
	};

	onLanguageChange = val => {
		const { modifyPrefs, notify: displayNotification } = this.props;

		modifyPrefs({ zimbraPrefLocale: val })
			.then(() => this.setState({ showLanguageModal: false }))
			.catch(err => {
				console.error(err);

				displayNotification({
					message: <Text id="error.genericInvalidRequest" />,
					failure: true
				});
			});
	};

	navigateToExternalAuth = () => {
		const { changePasswordURL } = this.props;

		this.toggleModal({
			modalState: 'showChangePassword',
			status: false
		});

		window.open(changePasswordURL, '_blank');
	};

	render(
		{
			name,
			userName,
			zimbraFeatureChangePasswordEnabled,
			zimbraPrefLocale,
			profileImageId,
			zimbraOrigin,
			imageURL,
			zimbraTwoFactorAuthEnabled,
			userHelpPath,
			changePasswordURL,
			defaultFromDisplay,
			tracker,
			isLoggedOut,
			isOffline,
			...props
		},
		{ showChangePassword, showChangeProfileImage, showLanguageModal }
	) {
		const avatarImage = (
			<Avatar class={s.headerActionImage} email={userName} profileImageURL={imageURL} />
		);

		if (showChangeProfileImage) {
			tracker.setVirtualPageView({ path: '/change-profile-image' });
		}

		const accountDetailsTooltip = `${name}\n${
			defaultFromDisplay ? defaultFromDisplay + '\n' : ''
		}${userName}`;

		return (
			<div class={s.headerActions}>
				<ActionMenu
					actionButtonClass={s.headerActionButton}
					anchor="end"
					arrow={false}
					label={
						<span class={s.headerAction}>
							{props.matchesScreenMd ? (
								<span class={s.headerActionTitle}>
									<span class={s.headerActionName} title={accountDetailsTooltip}>
										{avatarImage}
										{name}
									</span>
									<Icon class={s.headerActionArrow} name="caret-down" size="md" />
								</span>
							) : (
								avatarImage
							)}
						</span>
					}
				>
					<DropDownWrapper>
						<ActionMenuGroup>
							{zimbraFeatureChangePasswordEnabled && (
								<ActionMenuItem
									disabled={isOffline}
									onClick={callWith(this.toggleModal, {
										modalState: 'showChangePassword',
										status: true
									})}
								>
									<Text id="header.CHANGE_PASSWORD" />
								</ActionMenuItem>
							)}
							{
								<ActionMenuItem
									disabled={isOffline}
									onClick={callWith(this.toggleModal, {
										modalState: 'showChangeProfileImage',
										status: true
									})}
								>
									<Text id="header.changeProfileImage" />
								</ActionMenuItem>
							}
							<ZimletSlot name="header-menu-item" />
							<ActionMenuItem disabled={isOffline} onClick={this.handleLogout}>
								<Text id="header.LOGOUT" />
							</ActionMenuItem>
						</ActionMenuGroup>
					</DropDownWrapper>
				</ActionMenu>
				<span class={s.headerAction}>
					<ActionMenuSettings
						onClickSettings={props.toggleSettings}
						onClickAbout={props.toggleAbout}
						onClickLanguage={callWith(this.toggleModal, {
							modalState: 'showLanguageModal',
							status: true
						})}
						actionButtonClass={s.settingsActionButton}
						iconClass={cx(s.settingsIcon, s.supportClass)}
						userHelpPath={userHelpPath}
					/>
				</span>
				{showChangePassword &&
					(changePasswordURL ? (
						<ResetPasswordModal
							onClose={callWith(this.toggleModal, {
								modalState: 'showChangePassword',
								status: false
							})}
							onContinue={callWith(this.navigateToExternalAuth, true)}
						/>
					) : (
						<ModalDialog
							onClickOutside={callWith(this.toggleModal, {
								modalState: 'showChangePassword',
								status: false
							})}
							autofocusChildIndex={1}
							class={s.changePassword}
						>
							<Login
								twoFactorEnabled={zimbraTwoFactorAuthEnabled}
								showChangePassword
								username={userName}
								onClose={callWith(this.toggleModal, {
									modalState: 'showChangePassword',
									status: false
								})}
								onLogin={this.onLogin}
							/>
						</ModalDialog>
					))}
				{showChangeProfileImage && (
					<PhotoUploadModal
						onClose={callWith(this.toggleModal, {
							modalState: 'showChangeProfileImage',
							status: false
						})}
						imageURL={imageURL}
						saveImage={this.saveImage}
						removeImage={this.removeImage}
					/>
				)}
				{showLanguageModal && (
					<LanguageModal
						zimbraPrefLocale={zimbraPrefLocale}
						onClose={callWith(this.toggleModal, { modalState: 'showLanguageModal', status: false })}
						onLanguageChange={this.onLanguageChange}
					/>
				)}
			</div>
		);
	}
}
