import { createAction } from 'redux-actions';

export const updateSelection = createAction('briefcase updateSelection');

export const clearSelection = createAction('briefcase clearSelection');

export const updateFolderSelection = createAction('briefcase updateFolderSelection');

export const setBriefcaselListSortBy = createAction('briefcase setBriefcaselListSortBy');

export const updateUploadPercentage = createAction('briefcase updateUploadPercentage');

export const setUploadAttachmentNumber = createAction('briefcase setUploadAttachmentNumber');

export const updateUploadStatus = createAction('briefcase updateUploadStatus');

export const setTimeStampForUrl = createAction('briefcase setTimeStampForUrl');

export const updateRenameItemId = createAction('briefcase updateRenameItemId');

export const addExpandedId = createAction('briefcase addExpandedId');

export const removeIdFromExpanded = createAction('briefcase removeIdFromExpanded');

export const setDeleteWarning = createAction('briefcase setDeleteWarning');

export const removeDeleteWarning = createAction('briefcase removeDeleteWarning');

export const removeAllDeleteWarning = createAction('briefcase removeAllDeleteWarning');
