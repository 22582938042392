import { Component } from 'preact';
import moment from 'moment';
import { Text, withText } from 'preact-i18n';
import each from 'lodash-es/each';
import get from 'lodash-es/get';
import flatten from 'lodash-es/flatten';
import memoize from 'lodash-es/memoize';
import { Button } from '@zimbra/blocks';
import ContactCard from '../contact-card';
import { smimeHandler } from '@zimbra/electron-app';
import cx from 'classnames';
import { connect } from 'react-redux';
import {
	getId,
	filterDuplicates,
	deepClone,
	isValidEmail,
	replaceAttributes,
	base64ToBlob,
	callWith
} from '../../../lib/util';
import { isSMIMEFeatureAvailable, isServerSMIMEFeatureAvailable } from '../../../utils/license';
import style from '../style';
import isEqual from 'lodash-es/isEqual';
import withAccountInfo from '../../../graphql-decorators/account-info';
import { withCreateContact, withModifyContact } from '../../../graphql-decorators/contact';
import { ContactEditSection } from './edit-section';
import { PublicCertificateEditSection } from './public-cert-section';
import { CONTACTS } from '../../../constants/folders';
import wire from 'wiretie';
import { PhotoUpload } from '../photo-upload';
import ActionMenu, { DropDownWrapper } from '../../action-menu';
import ActionMenuGroup from '../../action-menu-group';
import ActionMenuItem from '../../action-menu-item';
import ZimletSlot from '../../zimlet-slot';

import { getDisplayName } from '../../../utils/contacts';
import {
	EMAIL,
	IM,
	HOME,
	WORK,
	OTHER,
	NICKNAME,
	ADDRESS,
	WEBSITE,
	CUSTOM,
	ADD_MORE_FIELD_PLACEHOLDER,
	USER_CERTIFICATE
} from './fields';

import {
	getAddressFieldPrefixAndSuffix,
	processContactAttrs,
	sorter,
	I18nText,
	generateFieldInfo,
	removeAttrSuffix,
	segregateAttributesIntoGroups,
	mergeContactAttributes,
	hasMinimumRequiredFields
} from './helper';
import ModalDialog from '../../modal-dialog';

const INVALID_EMAIL_MESSAGE_KEY = 'invalidEmail';
const MISMATCH_EMAIL_MESSAGE_KEY = 'emailMismatchWithCert';
const MINIMUM_FIELDS_REQUIRED_MESSAGE_KEY = 'minimumFieldsRequired';
const OTHER_FIELD_ERROR = 'otherFieldError';

/* eslint-disable react/display-name */
const input = type => props => <input type={type} {...props} />;

export const FIELD_MAPS = {
	email: input('email'),
	mobilePhone: input('tel'),
	homePhone: input('tel'),
	workPhone: input('tel'),
	companyPhone: input('tel'),
	otherPhone: input('tel'),
	callbackPhone: input('tel'),
	assistantPhone: input('tel'),
	carPhone: input('tel'),
	pager: input('tel'),
	workFax: input('tel'),
	homeFax: input('tel'),
	otherFax: input('tel'),
	birthday: 'date',
	anniversary: 'date',
	homeURL: input('url'),
	workURL: input('url'),
	otherURL: input('url'),
	Street: props => <textarea {...props} />,
	notes: props => <textarea {...props} />
};
/* eslint-enable react/display-name */

function getContactDetailsField(fields, template) {
	const WORK_DETAILS_FIELDS = template?.contacts?.edit?.workDetailsFields;
	const PERSONAL_DETAILS_FIELDS = template?.contacts?.edit?.personalDetailsFields;
	return fields.filter(
		field =>
			field !== ADD_MORE_FIELD_PLACEHOLDER &&
			field !== USER_CERTIFICATE &&
			WORK_DETAILS_FIELDS.indexOf(removeAttrSuffix(field)) === -1 &&
			PERSONAL_DETAILS_FIELDS.indexOf(removeAttrSuffix(field)) === -1
	);
}

function getWorkDetailsField(fields, template) {
	const WORK_DETAILS_FIELDS = template?.contacts?.edit?.workDetailsFields;
	return fields.filter(field => WORK_DETAILS_FIELDS.indexOf(removeAttrSuffix(field)) > -1);
}

function getPersonalDetailsField(fields, template) {
	const PERSONAL_DETAILS_FIELDS = template?.contacts?.edit?.personalDetailsFields;
	return fields.filter(field => PERSONAL_DETAILS_FIELDS.indexOf(removeAttrSuffix(field)) > -1);
}

function updateAddMoreDropdownLabels([...addMoreDropdownFields], contactAttrs) {
	if (contactAttrs) {
		if (contactAttrs.im) {
			addMoreDropdownFields.splice(addMoreDropdownFields.indexOf(IM), 1);
		}
		if (contactAttrs.nickname) {
			addMoreDropdownFields.splice(addMoreDropdownFields.indexOf(NICKNAME), 1);
		}
		if (
			contactAttrs.homeStreet ||
			contactAttrs.homeCity ||
			contactAttrs.homeState ||
			contactAttrs.homePostalCode ||
			contactAttrs.homeCountry ||
			contactAttrs.workStreet ||
			contactAttrs.workCity ||
			contactAttrs.workState ||
			contactAttrs.workPostalCode ||
			contactAttrs.workCountry ||
			contactAttrs.otherStreet ||
			contactAttrs.otherCity ||
			contactAttrs.otherState ||
			contactAttrs.otherPostalCode ||
			contactAttrs.otherCountry
		) {
			addMoreDropdownFields.splice(addMoreDropdownFields.indexOf(ADDRESS), 1);
		}
		if (contactAttrs.website) {
			addMoreDropdownFields.splice(addMoreDropdownFields.indexOf(WEBSITE), 1);
		}
	}
	return addMoreDropdownFields;
}

function createFreshState({ contact, skipMissing, template }) {
	if (!contact) {
		contact = {};
	}

	contact = {
		...contact,
		attributes: {
			...(contact.attributes || contact._attrs || {})
		}
	};

	const ADD_MORE_FIELDS_DROPDOWN = template?.contacts?.edit?.addMoreFieldsDropdown;
	const addMoreDropdownFields = updateAddMoreDropdownLabels(
		ADD_MORE_FIELDS_DROPDOWN,
		contact.attributes
	);
	const updatedContact = processContactAttrs(contact);
	const attributesList = createAttributesList(updatedContact, skipMissing, template);
	return {
		contact: updatedContact,
		addMoreDropdownFields,
		attributesList,
		errors: null,
		offlineModal: false
	};
}

function createAttributesList(contact, skipMissing, template) {
	const attrs = contact.attributes;
	const NEW_CONTACT_FIELDS = template?.contacts?.edit?.newContactFields;
	const mergedAttrsList = mergeContactAttributes(
		[...NEW_CONTACT_FIELDS, USER_CERTIFICATE],
		Object.keys(attrs),
		template
	).filter(key => !skipMissing || attrs[key]);
	get(contact, 'attributes.other', '').length && mergedAttrsList.push(OTHER);
	return mergedAttrsList;
}

const getFolder = (folders, ident) => {
	for (let i = 0; i < folders.length; i++) {
		if (
			folders[i].absFolderPath.slice(1) === ident ||
			folders[i].name === ident ||
			folders[i].id === ident
		) {
			return folders[i];
		} else if (folders[i].folders) {
			const folder = getFolder(folders[i].folders, ident); // if current folder contains any subFolder(s)
			if (folder) return folder;
		}
	}
	return null;
};

@connect(state => ({
	isOffline: get(state, 'network.isOffline'),
	template: state.template
}))
@wire('zimbra', {}, zimbra => ({
	attach: zimbra.attachment.upload
}))
@withText({
	addMore: 'contacts.edit.addMore',
	otherAttributePlaceHolder: 'contacts.edit.otherAttributePlaceHolder'
})
@withCreateContact()
@withModifyContact()
@withAccountInfo(({ data: { accountInfo } }) => ({
	isSMimeEnabled: isSMIMEFeatureAvailable(accountInfo?.license),
	isServerSMimeEnabled: isServerSMIMEFeatureAvailable(accountInfo?.license)
}))
export default class ContactEditor extends Component {
	state = createFreshState(this.props);

	createContactFieldUpdater = memoize((field, isDate) => e => {
		const { onChange } = this.props;
		let value;
		//handle case where value is the return object, like DateInput
		if (isDate) {
			const m = moment(e);
			if (m.isValid()) value = m.format('YYYY-MM-DD');
		} else {
			//handle <input/> onInput events for different input types
			value =
				e.target.type === 'checkbox' || e.target.type === 'radio'
					? e.target.checked
					: e.target.value;
		}
		let { contact } = this.state;
		contact = {
			...contact,
			attributes: {
				...contact.attributes
			}
		};
		if (field.includes('attributes.other.key') || field.includes('attributes.other.value')) {
			const index = field.split('.').pop();
			contact.attributes.other[index][field.includes('key') ? 'key' : 'value'] = value;
		} else {
			const parts = field.split('.');
			let obj = contact;
			const partsLength = parts.length;
			for (let i = 0; i < partsLength - 1; i++) {
				obj = obj[parts[i]] || (obj[parts[i]] = {});
			}
			obj[parts[partsLength - 1]] = value;
		}

		this.setState({ contact });
		onChange && onChange({ contact });
	});

	// update the fields suffixes to make them sequential
	// e.g if fields are email, email4 and email5 then change them to email, email2 and email3
	normalizeContactAttributes(contact) {
		const attributesList = [...this.state.attributesList];
		const { template } = this.props;

		const groups = segregateAttributesIntoGroups(attributesList, template, true);

		let allFieldsRenameMap = {};

		/**
		 * aol is not supported anymore as it doesn't exist,
		 * but also may be on data from when it was supported and/or migrated
		 * remove it from the attribute, as it doesn't get renamed anymore
		 */
		if (contact.attributes.aol) delete contact.attributes.aol;

		Object.keys(groups).forEach(group => {
			const currentGroupRenameMap = this.createFieldRenameMap(group, groups[group]);
			if (group === IM) {
				Object.keys(currentGroupRenameMap).map(label => {
					const currentAttrValue = contact.attributes[label];
					delete contact.attributes[label];
					contact.attributes[currentGroupRenameMap[label]] =
						removeAttrSuffix(label) + '://' + currentAttrValue;
				});
			} else if (
				group.indexOf(HOME) > -1 ||
				group.indexOf(WORK) > -1 ||
				group.indexOf(OTHER) > -1
			) {
				// homeAddress or workAddress
				Object.keys(currentGroupRenameMap).map(label => {
					const newLabel = currentGroupRenameMap[label];
					const { prefix: originalFieldPrefix, suffix: originalFieldSuffix } =
						getAddressFieldPrefixAndSuffix(label);
					const { prefix: fieldPrefix } = getAddressFieldPrefixAndSuffix(newLabel);
					const suffix = this.createFieldSuffix(newLabel);
					const ADDRESS_FIELDS = template?.contacts?.edit?.addressFields;
					ADDRESS_FIELDS.map(field => {
						replaceAttributes(
							contact.attributes,
							originalFieldPrefix + field + originalFieldSuffix,
							fieldPrefix + field + suffix
						);
					});
					// Update attribute list
					const index = attributesList.indexOf(label);
					if (index) {
						attributesList[index] = newLabel;
					}
				});
			} else {
				allFieldsRenameMap = {
					...allFieldsRenameMap,
					...currentGroupRenameMap
				};
			}
		});

		Object.keys(allFieldsRenameMap).map(label => {
			replaceAttributes(contact.attributes, label, allFieldsRenameMap[label]);

			const index = attributesList.indexOf(label);
			if (index && allFieldsRenameMap[label]) {
				attributesList[index] = allFieldsRenameMap[label];
			}
		});

		this.setState({ attributesList, contact });

		return contact;
	}

	// create field rename map required to make fields sequential
	// e.g email, email3, email7 -> email, email2, email3
	createFieldRenameMap(attr, fields) {
		const renameAttributesMap = {};
		fields.sort(sorter).map((originalAttr, index) => {
			const indexedFieldKey = `${attr}${index === 0 ? '' : index + 1}`;

			if (originalAttr !== indexedFieldKey) {
				renameAttributesMap[originalAttr] = indexedFieldKey;
			}
		});
		return renameAttributesMap;
	}

	getFormValueChanges = (contact, updatedContact) => {
		const allKeys = filterDuplicates(
			Object.keys(contact.attributes).concat(Object.keys(updatedContact.attributes))
		);
		const changes = allKeys.reduce((result, key) => {
			if (!isEqual(updatedContact.attributes[key], contact.attributes[key])) {
				result[key] = updatedContact.attributes[key] || null;
			}
			return result;
		}, []);

		return changes;
	};

	readContactPublicCert = certStr =>
		smimeHandler &&
		certStr &&
		smimeHandler({
			operation: 'get-cert',
			certData: certStr
		});

	save = () => {
		const { isOffline } = this.props;
		if (isOffline) {
			this.setState({
				offlineModal: true
			});
			return;
		}
		let { isNew } = this.props;
		const {
			contact,
			createContact,
			modifyContact,
			customSave,
			onBeforeSave,
			onSave,
			hideDialogOnError,
			onValidationError,
			template
		} = this.props;
		const contactData = deepClone(this.state.contact);
		let otherAttrs = deepClone(contactData.attributes.other);
		if (!this.isContactValid(contactData)) {
			hideDialogOnError();
			onValidationError && onValidationError(true);
			return;
		}

		onValidationError && onValidationError(false);
		if (otherAttrs) {
			// Filter all other attributes where value is absent and append 'custom' as key where only value is provided.
			otherAttrs = otherAttrs
				.filter(f => f.value)
				.map(k => ({
					...k,
					key: k.key || CUSTOM
				}));
			const newCustomKeys = otherAttrs.map(other => other.key);
			const existingAttributes = get(contact, 'attributes.other');
			const oldCustomKeys = existingAttributes ? existingAttributes.map(other => other.key) : [];
			// Filter out all the unique custom key from all the existing and newly added custom values.
			const uniqueCustomKey = Array.from(new Set(newCustomKeys.concat(oldCustomKeys)));
			const finalCustomArray = uniqueCustomKey.map(uniqueKey => {
				const existingArray = existingAttributes
					? existingAttributes.filter(f => f.key === uniqueKey)
					: [];
				let newArray = otherAttrs.filter(f => f.key === uniqueKey);
				//Add blank values for the custom keys which are deleted.
				if (newArray.length < existingArray.length) {
					const differenceValue = existingArray.length - newArray.length;
					for (let i = 0; i < differenceValue; i++) {
						newArray.push({
							key: uniqueKey,
							value: ''
						});
					}
				}
				//Append numbers to custom key to make them unique if their are multiple keys with same name.
				if (newArray && newArray.length > 1) {
					newArray = newArray.map((n, i) => ({
						...n,
						key: n.key + (i + 1)
					}));
				}
				const previousOccurences = oldCustomKeys.filter(f => f === uniqueKey).length;
				const currentOccurences = newCustomKeys.filter(f => f === uniqueKey).length;
				if (previousOccurences !== currentOccurences && previousOccurences === 1) {
					newArray.push({
						key: uniqueKey,
						value: ''
					});
				}
				return newArray;
			});
			otherAttrs = flatten(finalCustomArray).map(({ key, value }) => ({
				key,
				value
			}));
		}
		each(contactData.attributes, (value, key) => {
			if (value && value.trim) contactData.attributes[key] = value.trim();
		});
		this.normalizeContactAttributes(contactData);

		isNew = isNew || !contactData.id;

		contactData.attributes.fullName = getDisplayName(
			get(contactData, 'attributes'),
			isNew,
			template
		);

		const finalContactValue = {
			...contactData,
			attributes: {
				...contactData.attributes,
				...(otherAttrs && otherAttrs.length && { other: otherAttrs })
			}
		};
		if (onBeforeSave) {
			onBeforeSave({ isNew, contact: finalContactValue });
		}

		if (customSave) {
			return customSave({ isNew, contact: finalContactValue });
		}

		if (isNew) {
			return createContact(finalContactValue).then(onSave, this.showError);
		}

		const changes = this.getFormValueChanges(contact, finalContactValue);

		modifyContact({
			id: contactData.id,
			attributes: changes
		}).then(onSave, this.showError);
	};

	isContactValid = contact => {
		let errors = {
			fields: [],
			messages: []
		};
		const attrs = contact.attributes;
		const { certEmail } = this.state;
		const { template } = this.props;

		if (!hasMinimumRequiredFields(attrs, template)) {
			errors.messages.push(MINIMUM_FIELDS_REQUIRED_MESSAGE_KEY);
		} else {
			Object.keys(attrs).map(attribute => {
				const fieldInfo = generateFieldInfo(attribute, template);
				if (fieldInfo.group === EMAIL && attrs[attribute]) {
					if (!isValidEmail(attrs[attribute])) {
						errors.fields.push(attribute);
						if (errors.messages.indexOf(INVALID_EMAIL_MESSAGE_KEY) === -1) {
							errors.messages.push(INVALID_EMAIL_MESSAGE_KEY);
						}
					} else if (
						certEmail &&
						get(contact, 'attributes.email') !== certEmail &&
						errors.messages.indexOf(MISMATCH_EMAIL_MESSAGE_KEY) === -1
					) {
						errors.messages.push(MISMATCH_EMAIL_MESSAGE_KEY);
						errors.fields.push(attribute);
					}
				}
				if (attribute === OTHER && attrs && attrs.other) {
					attrs.other = attrs.other.map(f => ({
						...f,
						errorExist: !!f.key.match(/\d+/g)
					}));
					attrs.other.find(f => f.errorExist) && errors.messages.push(OTHER_FIELD_ERROR);
					this.setState({ contact });
				}
			});
		}
		if (!errors.messages.length) {
			errors = null;
		}
		this.setState({ errors });
		return !errors;
	};

	showError = error => {
		this.setState({
			messages: [error]
		});
	};

	updateLabel = ({ originalLabel, newLabel, group }) => {
		const attributesList = [...this.state.attributesList];
		let { contact } = this.state;
		const { template } = this.props;
		contact = deepClone(contact);
		const newLabelWithSuffix = newLabel + this.createFieldSuffix(newLabel);

		if (group === ADDRESS) {
			const { prefix: originalFieldPrefix, suffix: originalFieldSuffix } =
				getAddressFieldPrefixAndSuffix(originalLabel);
			const { prefix: fieldPrefix } = getAddressFieldPrefixAndSuffix(newLabel);
			const suffix = this.createFieldSuffix(newLabel);
			const ADDRESS_FIELDS = template?.contacts?.edit?.addressFields;
			ADDRESS_FIELDS.map(field => {
				const originalFieldKey = originalFieldPrefix + field + originalFieldSuffix;
				replaceAttributes(contact.attributes, originalFieldKey, fieldPrefix + field + suffix);
			});
		} else {
			replaceAttributes(contact.attributes, originalLabel, newLabelWithSuffix);
		}

		attributesList.splice(attributesList.indexOf(originalLabel), 1, newLabelWithSuffix);
		this.setState({ attributesList, contact });
		if (this.props.onChange) {
			this.props.onChange({ contact });
		}
	};

	addFieldFromAddMoreDropdown = value => {
		const addMoreDropdownFields = [...this.state.addMoreDropdownFields];
		const { attributesList, contact } = this.state;
		const { template } = this.props;
		value = value === CUSTOM ? OTHER : value;
		const others = get(contact, 'attributes.other');
		const DROPDOWN_LABEL_FIELDS = template?.contacts?.edit?.dropdownLabelFields;
		this.addField({
			addAfterField:
				value !== OTHER
					? attributesList[attributesList.indexOf(ADD_MORE_FIELD_PLACEHOLDER) - 1]
					: `${value}.${others && others.length - 1}`,
			newFieldAttribute:
				(DROPDOWN_LABEL_FIELDS[value] && DROPDOWN_LABEL_FIELDS[value][0]) || // Dropdown fields: use first value from dropdown list
				value, // Non dropdown fields: use base type -> birthday etc
			group: value
		});

		value !== OTHER && addMoreDropdownFields.splice(addMoreDropdownFields.indexOf(value), 1);
		this.setState({ addMoreDropdownFields });
	};

	addField = ({ addAfterField, newFieldAttribute, group }) => {
		const attributesList = [...this.state.attributesList];
		let { contact } = this.state;
		const { template } = this.props;
		contact = deepClone(contact);

		if (group === OTHER) {
			const index = addAfterField.split('.').pop();
			const blankOtherField = {
				key: '',
				value: ''
			};
			let others = contact.attributes.other;
			if (others) {
				others.splice(Number(index) + 1, 0, blankOtherField);
			} else {
				others = [blankOtherField];
				this.setState({
					attributesList: [...attributesList, OTHER]
				});
			}
			this.setState({
				contact: {
					...contact,
					attributes: {
						...contact.attributes,
						other: others
					}
				}
			});
			return;
		}
		const newKeyWithSuffix = newFieldAttribute + this.createFieldSuffix(newFieldAttribute);

		if (group === ADDRESS) {
			//Handle address fields
			const suffix = this.createFieldSuffix(newFieldAttribute);
			const ADDRESS_FIELDS = template?.contacts?.edit?.addressFields;
			ADDRESS_FIELDS.map(field => (contact.attributes['home' + field + suffix] = ''));
		} else {
			contact.attributes[newKeyWithSuffix] = '';
		}

		attributesList.splice(attributesList.indexOf(addAfterField) + 1, 0, newKeyWithSuffix);
		this.setState({ attributesList, contact });
		if (this.props.onChange) {
			this.props.onChange({ contact });
		}
	};

	createFieldSuffix = field => {
		const { attributesList } = this.state;

		let index = 0;
		do {
			const fieldLabelWithSuffix = field + (index === 0 ? '' : index + 1);
			if (attributesList.indexOf(fieldLabelWithSuffix) === -1) {
				break;
			}
		} while (++index);

		return index ? index + 1 : '';
	};

	removeField = ({ attribute, group }) => {
		const attributesList = [...this.state.attributesList];
		let { contact } = this.state;
		const { template } = this.props;
		contact = deepClone(contact);
		if (group === OTHER) {
			const index = attribute.split('.').pop();
			contact.attributes.other.splice(index, 1);
			this.setState({ contact });
			return;
		}
		attributesList.splice(attributesList.indexOf(attribute), 1);
		if (group === ADDRESS) {
			const { prefix, suffix } = getAddressFieldPrefixAndSuffix(attribute);
			const ADDRESS_FIELDS = template?.contacts?.edit?.addressFields;
			ADDRESS_FIELDS.map(field => delete contact.attributes[prefix + field + suffix]);
		} else {
			delete contact.attributes[attribute];
		}
		this.setState({ attributesList, contact });
		if (this.props.onChange) {
			this.props.onChange({ contact });
		}
	};

	saveImage = imageData =>
		// Convert base64 data to blob
		base64ToBlob(imageData).then(blob => {
			const { attach } = this.props;

			attach(blob, {
				filename: 'default.' + imageData.slice(imageData.indexOf('/') + 1, imageData.indexOf(';'))
			}).then(aid => {
				this.setImageAttribute(aid);
			});
		});

	setImageAttribute = value => {
		const { onChange } = this.props;
		let { contact } = this.state;
		contact = deepClone(contact);
		contact.attributes.image = value;

		this.setState({ contact });

		onChange && onChange({ contact });
	};

	setContactState = contact => this.setState({ contact });

	showRemoveButtonForGroup = group => {
		const { attributesList } = this.state;
		const { template } = this.props;
		const DROPDOWN_LABEL_FIELDS = template?.contacts?.edit?.dropdownLabelFields;
		const groupLabels = DROPDOWN_LABEL_FIELDS[group] || [group]; //single label field like  birthday or anniversary

		const numOfFieldsOfType = attributesList.filter(
			fieldName => groupLabels.indexOf(removeAttrSuffix(fieldName)) > -1 //convert email2 to email
		).length;
		return numOfFieldsOfType > 1;
	};

	onAddCertificate = ({ userCertificate, certEmail }) => {
		const { contact } = this.state;
		contact.attributes = { ...contact.attributes, userCertificate };
		this.setState({ contact, certEmail });
	};

	onRemoveCertificate = () => {
		const { contact } = this.state;
		const { userCertificate, ...rest } = get(contact, 'attributes', {});
		contact.attributes = rest;
		this.setState({ contact });
	};

	onCloseOfflineModal = () => {
		this.setState({
			offlineModal: false
		});
	};

	componentDidMount() {
		const { contact, certEmail } = this.state;
		if (!certEmail) {
			const userCert = get(contact, 'attributes.userCertificate');
			const publicCertData = userCert && this.readContactPublicCert(userCert);
			publicCertData &&
				publicCertData.then(data =>
					this.setState({ certEmail: get(data, 'certificate.subject.email') })
				);
		}
	}

	componentWillReceiveProps({
		saveOnConfirmDialog,
		isOffline,
		triggerSaveContact,
		isNew,
		contact
	}) {
		// For offline prop change no need to perform any action
		if (isOffline !== this.props.isOffline) return;

		if (!isNew && this.props.contact !== contact) {
			this.setState({ contact });
		}

		if (
			(triggerSaveContact && triggerSaveContact !== this.props.triggerSaveContact) ||
			(saveOnConfirmDialog && saveOnConfirmDialog !== this.props.saveOnConfirmDialog)
		) {
			this.save();
		}
	}

	shouldComponentUpdate({ contact, isNew }, { contact: updatedContact }) {
		if (!isNew && this._dirty) {
			const changes = this.getFormValueChanges(contact, updatedContact);
			const { onFormChange } = this.props;

			onFormChange && onFormChange(Object.keys(changes).length !== 0);
		}
	}

	render(
		{
			folder,
			folders,
			showCard,
			showHeader,
			showFooter,
			showTitle,
			skipMissing,
			allowMove,
			readonly,
			onCancel,
			isNew,
			footerClass,
			disabled,
			isSMimeEnabled,
			isServerSMimeEnabled,
			addMore,
			otherAttributePlaceHolder,
			template,
			...props
		},
		{ contact, attributesList, errors, addMoreDropdownFields, offlineModal }
	) {
		const pfx = `contact-${getId(contact) || 'x'}-`;
		if (folders && !contact.folderId) {
			const parentFolder = getFolder(folders, folder) || getFolder(folders, CONTACTS);
			if (parentFolder) contact.folderId = parentFolder.id;
		}

		const detailFields = getContactDetailsField(attributesList, template);
		const workFields = getWorkDetailsField(attributesList, template);
		const personalFields = getPersonalDetailsField(attributesList, template);

		return (
			<div
				class={cx(
					style.contactEditor,
					showHeader !== false && style.hasHeader,
					showFooter !== false && style.hasFooter,
					props.class
				)}
			>
				{showHeader && (
					<div class={style.header}>
						<h2>
							<Text id={`contacts.edit.${isNew ? 'add' : 'edit'}Contact`} />
						</h2>
					</div>
				)}

				<div class={cx(style.inner, style.contactEditFormWrapper)}>
					{errors && errors.messages && (
						<div key="error" class={style.error}>
							{errors.messages.map(error => (
								<span>
									<I18nText attribute={error} dictionary="errors" />
								</span>
							))}
						</div>
					)}

					{showCard !== false && <ContactCard contact={contact} />}

					<div class={style.form} disabled={disabled}>
						<div class={style.avatar}>
							<PhotoUpload
								saveImage={this.saveImage}
								contact={contact}
								allowUpload
								removeImage={callWith(this.setImageAttribute, null)}
							/>
						</div>
						<ContactEditSection
							errorFields={errors && errors.fields}
							contact={contact}
							pfx={pfx}
							titleId={showTitle !== false && 'contacts.edit.details.contact'}
							fields={detailFields}
							readonly={readonly}
							onAddField={this.addField}
							showRemoveButtonForGroup={this.showRemoveButtonForGroup}
							onRemoveField={this.removeField}
							onFieldLabelChange={this.updateLabel}
							createContactFieldUpdater={this.createContactFieldUpdater}
							otherAttributePlaceHolder={otherAttributePlaceHolder}
							template={template}
						>
							{addMoreDropdownFields.length > 0 && (
								<div class={style.dropdownLabel}>
									<ActionMenu
										label={addMore}
										title={addMore}
										anchor="end"
										actionButtonClass={style.addFieldDropdown}
										placement="right"
									>
										<DropDownWrapper>
											<ActionMenuGroup>
												{addMoreDropdownFields.map(label => (
													<ActionMenuItem
														onClick={callWith(this.addFieldFromAddMoreDropdown, label)}
													>
														<I18nText attribute={label} />
													</ActionMenuItem>
												))}
											</ActionMenuGroup>
										</DropDownWrapper>
									</ActionMenu>
								</div>
							)}
						</ContactEditSection>

						{isSMimeEnabled && (
							<PublicCertificateEditSection
								title={<Text id="contacts.edit.details.secureCert" />}
								contactAttrs={contact.attributes}
								onAddCertificate={this.onAddCertificate}
								onRemoveCertificate={this.onRemoveCertificate}
							/>
						)}

						{isServerSMimeEnabled && (
							<ZimletSlot
								name="contact-add-server-smime-cert"
								setContactState={this.setContactState}
								contact={contact}
							/>
						)}

						<ContactEditSection
							errorFields={errors && errors.fields}
							contact={contact}
							pfx={pfx}
							titleId="contacts.edit.details.work"
							fields={workFields}
							readonly={readonly}
							onAddField={this.addField}
							onRemoveField={this.removeField}
							onFieldLabelChange={this.updateLabel}
							createContactFieldUpdater={this.createContactFieldUpdater}
							showRemoveButtonForGroup={this.showRemoveButtonForGroup}
							template={template}
						/>

						<ContactEditSection
							errorFields={errors && errors.fields}
							contact={contact}
							pfx={pfx}
							titleId="contacts.edit.details.personal"
							fields={personalFields}
							readonly={readonly}
							onAddField={this.addField}
							onRemoveField={this.removeField}
							showRemoveButtonForGroup={this.showRemoveButtonForGroup}
							onFieldLabelChange={this.updateLabel}
							createContactFieldUpdater={this.createContactFieldUpdater}
							template={template}
						/>
					</div>
					{offlineModal && (
						<ModalDialog
							title="contacts.offlineModal.title"
							onAction={this.onCloseOfflineModal}
							onClose={this.onCloseOfflineModal}
							cancelButton={false}
						>
							<Text id="contacts.offlineModal.body" />
						</ModalDialog>
					)}
				</div>

				{showFooter !== false && (
					<div class={cx(style.footer, footerClass)}>
						<Button styleType="primary" brand="primary" onClick={this.save} disabled={disabled}>
							<Text id="buttons.save" />
						</Button>
						<Button styleType="default" onClick={onCancel} disabled={disabled}>
							<Text id="buttons.cancel" />
						</Button>
					</div>
				)}
			</div>
		);
	}
}
