import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';

const initialState = {
	selected: [],
	chosen: { to: [], cc: [], bcc: [] }
};

export default handleActions(
	{
		[actionCreators.setSelected]: (state, action) => ({
			...state,
			selected: action.payload
		}),
		[actionCreators.setChosen]: (state, action) => ({
			...state,
			chosen: action.payload
		})
	},
	initialState
);
