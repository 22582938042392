import findIndex from 'lodash-es/findIndex';
import cloneDeep from 'lodash-es/cloneDeep';
import head from 'lodash-es/head';
import tail from 'lodash-es/tail';
import moment from 'moment';

const findInstIndex = (timestamp, instances) =>
	findIndex(instances, ({ start, end }, i) =>
		i === instances.length - 1
			? timestamp >= start && timestamp <= end
			: timestamp >= start && timestamp < end
	);

const BUSY_STATUSES = { unavailable: 4, busy: 3, tentative: 2, free: 1, nodata: 0 };

const getPriorityStatus = (statusA, statusB) => {
	if (BUSY_STATUSES[statusA] !== BUSY_STATUSES[statusB]) {
		return BUSY_STATUSES[statusA] > BUSY_STATUSES[statusB] ? statusA : statusB;
	}
	return statusA;
};

export const merge = statuses => {
	if (!statuses.length) {
		return statuses;
	}

	return tail(statuses).reduce(
		(result, instances) =>
			instances
				? instances.reduce((memo, inst) => {
						if (BUSY_STATUSES[inst.status] > 1) {
							const startIdx = findInstIndex(inst.start, memo);
							const endIdx = findInstIndex(inst.end, memo);
							if (startIdx !== -1 && endIdx !== -1 && startIdx <= endIdx) {
								const updated = cloneDeep(memo);
								if (startIdx === endIdx) {
									//if the inst[status] and memo[startIdx][status] do not match
									//then we need to remove the existing startIndex and replace it
									//with new slots with appropriate status as per the priority
									if (memo[startIdx].status !== inst.status) {
										updated.splice(
											startIdx,
											1,
											...[
												memo[startIdx].start !== inst.start
													? {
															...memo[startIdx],
															end: inst.start
													  }
													: {},
												{
													...inst,
													status: getPriorityStatus(inst.status, memo[startIdx].status)
												},
												memo[endIdx].end !== inst.end
													? {
															...memo[startIdx],
															start: inst.end
													  }
													: {}
											]
										);
										return updated;
									}
									return memo;
								}

								if (endIdx - startIdx > 1) {
									const inBetweenStatuses = updated.filter((el, i) => i > startIdx && i < endIdx);

									const updatedInBetweenStatus = inBetweenStatuses.map(el => {
										const memoInst = cloneDeep(el);
										memoInst.status = getPriorityStatus(el.status, inst.status);
										return memoInst;
									});

									updated.splice(
										startIdx + 1,
										updatedInBetweenStatus.length,
										...updatedInBetweenStatus
									);
								}
								let seperatedStatus;
								if (memo[startIdx].status !== inst.status) {
									seperatedStatus = [
										memo[startIdx].start !== inst.start
											? {
													...memo[startIdx],
													end: inst.start
											  }
											: {},
										{
											start: inst.start,
											end: memo[startIdx].end,
											status: getPriorityStatus(inst.status, memo[startIdx].status)
										}
									];
									updated.splice(startIdx, 1, ...seperatedStatus);
								}
								if (memo[endIdx].status !== inst.status) {
									updated.splice(
										seperatedStatus && seperatedStatus.length > 1
											? endIdx + seperatedStatus.length - 1
											: endIdx,
										1,
										...[
											memo[endIdx].start !== inst.end
												? {
														start: memo[endIdx].start,
														end: inst.end,
														status: getPriorityStatus(inst.status, memo[endIdx].status)
												  }
												: {},
											memo[endIdx].end !== inst.end
												? {
														...memo[endIdx],
														start: inst.end
												  }
												: {}
										]
									);
								}
								return updated;
							}
						}
						return memo;
				  }, result)
				: result,
		cloneDeep(head(statuses))
	);
};

export const hoursDecimal = timestamp => {
	const momentTime = moment(timestamp);
	return (
		momentTime.hours() +
		momentTime.minutes() / 60 +
		momentTime.seconds() / 3600 +
		momentTime.milliseconds() / (3600 * 1000)
	);
};
