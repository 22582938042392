import filter from 'lodash-es/filter';

/**
 * Given one folder and a list of contact groups, nest the contact groups into
 * the folder and all sub-folders
 */
export function nestContactGroupsInFolder(parentFolder, contactGroups) {
	const groupsInThisFolder = filter(contactGroups, { folderId: parentFolder.id });

	if (!groupsInThisFolder.length && !parentFolder.folders) {
		return parentFolder;
	}

	return {
		...parentFolder,
		folders: [
			...(parentFolder.folders
				? interleaveFoldersWithGroups(parentFolder.folders, contactGroups)
				: []),
			...groupsInThisFolder
		]
	};
}

/**
 * Given a list of folders and a list of contact groups, nest those contact groups
 * under the folders they belong to
 */
export function interleaveFoldersWithGroups(folders, contactGroups) {
	return folders.reduce(
		(acc, folder) => [...acc, nestContactGroupsInFolder(folder, contactGroups)],
		[]
	);
}

/**
 * Given a list of folders containing contact groups (as created by {@function interleaveFoldersWithGroups}),
 * remove the contact groups and leave only the folders.
 */
export function filterFoldersWithGroups(folders) {
	return folders.reduce(
		(acc, folder) =>
			isContactGroup(folder)
				? acc
				: [
						...acc,
						!folder.folders
							? folder
							: {
									...folder,
									folders: filterFoldersWithGroups(folder.folders)
							  }
				  ],
		[]
	);
}

function isContactGroup(folder) {
	return !folder.name && 'members' in folder;
}
