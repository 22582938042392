export const SEND_AS = 'sendAs';
export const SEND_AS_DIST_LIST = 'sendAsDistList';
export const SEND_ON_BEHALF = 'sendOnBehalfOf';
export const SEND_ON_BEHALF_DIST_LIST = 'sendOnBehalfOfDistList';
export const VIEW_FREE_BUSY = 'viewFreeBusy';

export const GRANTED = 'granted';
export const REVOKED = 'revoked';

export const FREE_BUSY_RIGHTS = {
	public: 'pub',
	all: 'all',
	domain: 'dom',
	user: 'usr',
	none: 'none'
};
