import { Component } from 'preact';
import { Router, Link } from 'preact-router';
import { Text } from 'preact-i18n';
import clientConfiguration from '../enhancers/client-config';
import ZimletSlot from '../components/zimlet-slot';
import split from '../lib/split-point';
import { CONVERSATION, MESSAGE } from '../constants/types';
import { getBasePath } from '../lib/util';

const ErrorPage = () => (
	<div>
		<h2>
			<Text id="routes.notFound" />
		</h2>
		<Link href="/">
			<Text id="routes.home" />
		</Link>
	</div>
);

const Routes = {
	Mail: split(() =>
		import(
			/* webpackMode: "lazy", webpackChunkName: "mail-screen"*/
			'../screens/mail'
		)
	),
	MailPrint: split(() =>
		import(/* webpackMode: "lazy", webpackChunkName: "mail-print" */ '../components/mail-print')
	),
	Contacts: split(() =>
		import(/* webpackMode: "lazy", webpackChunkName: "contacts-screen"*/ '../components/contacts')
	),
	ContactsPrint: split(() =>
		import(
			/* webpackMode: "lazy", webpackChunkName: "contacts-print"*/ '../components/contacts/print'
		)
	),
	Calendar: split(() =>
		import(
			/* webpackMode: "lazy", webpackChunkName: "calendar-screen"*/
			'../components/calendar'
		)
	),
	CalendarPrint: split(() =>
		import(
			/* webpackMode: "lazy", webpackChunkName: "calendar-print" */ '../components/calendar/print'
		)
	),
	Briefcase: split(() =>
		import(/* webpackMode: "lazy", webpackChunkName: "briefcase" */ '../components/briefcase')
	),
	Search: {
		Calendar: split(() =>
			import(
				/* webpackMode: "lazy", webpackChunkName: "search-calendar-screen"*/
				'../screens/search/calendar'
			)
		),
		Contacts: split(() =>
			import(
				/* webpackMode: "lazy", webpackChunkName: "search-contacts-screen"*/
				'../screens/search/contacts'
			)
		)
	},
	Graphiql: split(() =>
		import(
			/* webpackMode: "lazy", webpackChunkName: "graphiql" */
			'../screens/graphiql'
		)
	),
	Cloud: split(() =>
		import(
			/* webpackMode: "lazy", webpackChunkName: "default-home-page"*/
			'../components/default-home-page'
		)
	),
	Video: split(() =>
		import(
			/* webpackMode: "lazy", webpackChunkName: "default-home-page"*/
			'../components/default-home-page'
		)
	),
	Chat: split(() =>
		import(
			/* webpackMode: "lazy", webpackChunkName: "default-home-page"*/
			'../components/default-home-page'
		)
	),
	Integrations: split(() =>
		import(
			/* webpackMode: "lazy", webpackChunkName: "default-home-page"*/
			'../components/default-home-page'
		)
	),
	Error: ErrorPage
};

@clientConfiguration('routes.slugs')
export default class AppRouter extends Component {
	render({
		slugs,
		search,
		zimbraFeatureCalendarEnabled,
		zimbraFeatureTaggingEnabled,
		zimbraFeatureBriefcasesEnabled,
		...props
	}) {
		return (
			<ZimletSlot name="routes" slugs={slugs}>
				{slotContent => (
					<Router {...props} basePath={getBasePath()}>
						<Routes.Mail path={`/`} />
						<Routes.Mail path={`/${slugs.email}/${slugs.localFolder}/:folderName`} localFolder />
						<Routes.Mail
							path={`/${slugs.email}/${slugs.localFolder}/:folderName/${slugs.message}/:id`}
							type={MESSAGE}
							localFolder
						/>
						<Routes.Mail
							path={`/${slugs.email}/${slugs.message}/${slugs.localFolder}/:id`}
							replyLocalFolder
							type={MESSAGE}
							disableList
							disableMessageNavigation
						/>
						<Routes.Mail
							path={`/${slugs.email}/${slugs.conversation}/:id`}
							type={CONVERSATION}
							disableList
							disableMessageNavigation
						/>
						<Routes.Mail
							path={`/${slugs.email}/${slugs.message}/:id`}
							type={MESSAGE}
							disableList
							disableMessageNavigation
							hideMailToolbar
							htmlFlag //added a flag to open composer in html mode in case of reply/forward and message view
						/>
						<Routes.Mail path={`/${slugs.email}/:folderName?`} />
						{/* TODO : Remove below route once tab is implemented in all verticles */}
						<Routes.Mail path={`/${slugs.email}/new`} isNew />
						{/* ? contains optional parameter */}
						<Routes.Mail path={`/${slugs.email}/:folderName/:type/:id`} />
						<Routes.Mail path={`/${slugs.email}/:folderName/:type/:id/:partId/`} hideMailToolbar />
						<Routes.Mail path={`/${slugs.email}/:folderName/:type/new`} disableList />
						<Routes.MailPrint path={`/${slugs.email}/:type/:id/print`} hideHeader />
						{zimbraFeatureCalendarEnabled && [
							<Routes.Calendar path={`/${slugs.calendar}/:${slugs.tasks}?`} />,
							<Routes.Calendar path={`/${slugs.calendar}/day/:date`} />,
							<Routes.Calendar path={`/${slugs.calendar}/event/new`} createEvent />,
							<Routes.Calendar path={`/${slugs.calendar}/event/:actionType/:id`} />,
							// :actionType is used to differentiate between edit and forward event
							<Routes.Calendar path={`/${slugs.calendar}/event/:actionType/:id/:type`} />,
							// :type is used for single or all occurrence of recurring event
							<Routes.CalendarPrint path={`/${slugs.calendar}/print`} hideHeader />,
							<Routes.Search.Calendar path={`/${slugs.search}/${slugs.calendar}`} />
						]}
						{zimbraFeatureTaggingEnabled && [
							<Routes.Mail path={`/${slugs.email}/:folderName/${slugs.tags}`} isTag />,
							<Routes.Mail path={`/${slugs.email}/:folderName/${slugs.tags}/:type/:id`} isTag />,
							<Routes.MailPrint
								path={`/${slugs.email}/${slugs.tags}/:type/:id/print`}
								hideHeader
								isTag
							/>
						]}
						<Routes.Cloud path={`/${slugs.cloudapps}`} view={slugs.cloudapps} />
						{zimbraFeatureBriefcasesEnabled && [
							<Routes.Briefcase path={`/${slugs.search}/${slugs.briefcase}/:id?`} isSearchView />,
							<Routes.Briefcase path={`/${slugs.briefcase}/:folderName?/:id?`} />
						]}
						<Routes.Contacts path={`/${slugs.contacts}`} />
						<Routes.Contacts path={`/${slugs.contacts}/new`} contact="new" />
						<Routes.Contacts path={`/${slugs.contacts}/:folderName`} />
						<Routes.Contacts path={`/${slugs.contacts}/:folderName/:contact`} />
						<Routes.ContactsPrint path={`/${slugs.contacts}/print`} hideHeader />
						<Routes.Mail path={`/${slugs.search}/${slugs.email}/:type?/:id?`} isSearchView />
						<Routes.Search.Contacts path={`/${slugs.search}/${slugs.contacts}`} />
						<Routes.Graphiql path="/graphiql" hideHeader />
						<Routes.Video path={`/${slugs.videoapps}`} view={slugs.videoapps} />
						<Routes.Chat path={`/${slugs.chatapps}`} view={slugs.chatapps} />
						<Routes.Integrations path={`/${slugs.integrations}`} view={slugs.integrations} />
						{slotContent}
						<Routes.Error default />
					</Router>
				)}
			</ZimletSlot>
		);
	}
}
