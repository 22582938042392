import { Component, Fragment } from 'preact';
import { Spinner, Button } from '@zimbra/blocks';
import { Text, withText } from 'preact-i18n';
import { errorMessage, faultCode } from '../../../../utils/errors';
import AnimatedCheckmark from '../../../animated-checkmark';
import mailPort from '../../../../utils/mail-port';
import EditAccountView from './edit-account';
import AddAccountView from './add-account';
import { withTestExternalAccount } from '../../../../graphql-decorators/external-account';
import withMediaQuery from '../../../../enhancers/with-media-query';
import { maxWidth, screenXsMax } from '../../../../constants/breakpoints';
import clientConfiguration from '../../../../enhancers/client-config';
import pick from 'lodash-es/pick';
import style from '../../style';
import cx from 'classnames';

const ERRORS = {
	'LOGIN failed': 'settings.accounts.errors.loginFailed',
	'LOGIN Invalid credentials': 'settings.accounts.errors.invalidCredentials',
	'Connection refused': 'settings.accounts.errors.loginFailed',
	generic: 'settings.accounts.errors.generic'
};

function SuccessfulConnectionMessage({
	viewData: { selectedAccount, formData },
	onClick,
	isEditView
}) {
	const emailAddress = isEditView ? selectedAccount.emailAddress : formData.emailAddress;

	return (
		<div class={style.testSuccessMessageContainer}>
			<div class={cx(style.testingDataSourceSection, style.animatedCheckmark)}>
				<AnimatedCheckmark class={style.check} />
			</div>
			<div class={cx(style.testingDataSourceSection, style.successMessage)}>
				<Text id="settings.accounts.addAccount.successfulConnection" />
			</div>
			<Button onClick={onClick} styleType="primary" brand="primary">
				<Text id={`buttons.${isEditView ? 'update' : 'add'}`} /> <b>{emailAddress}</b>
			</Button>
		</div>
	);
}

function TestingConnectionMessage({ viewData: { selectedAccount, formData }, isEditView }) {
	const email = isEditView ? selectedAccount.emailAddress : formData.emailAddress;

	return (
		<Fragment>
			<div class={style.testingDataSourceSection}>
				<Spinner class={style.spinner} />
			</div>
			<div class={cx(style.testingDataSourceSection)}>
				<b>
					<Text
						id="settings.accounts.addAccount.connectingMessage"
						fields={{
							email
						}}
					/>
				</b>
			</div>
		</Fragment>
	);
}

@withText('faults.account.DATA_SOURCE_EXISTS')
@withMediaQuery(maxWidth(screenXsMax), 'matchesScreenXs')
@withTestExternalAccount()
@clientConfiguration('clientName')
class AddEditAccountView extends Component {
	state = {
		dataSourceError: null,
		dataSourceTestInProgress: false,
		dataSourceTestSuccess: false,
		submitting: false
	};

	handleAccountAction = () => {
		this.setState({
			dataSourceTestInProgress: false,
			dataSourceTestSuccess: false,
			submitting: true,
			dataSourceError: null
		});

		const {
			isEditView,
			onExternalAccountAction,
			viewData: { handleValidityOfTabs, formData, selectedAccount, activeId }
		} = this.props;

		onExternalAccountAction({
			isEditView,
			...(isEditView ? { data: selectedAccount } : { data: formData })
		})
			.then(() => {
				this.setState({
					submitting: false
				});

				handleValidityOfTabs && handleValidityOfTabs(activeId, false);
			})
			.catch(err => {
				const errCode = faultCode(err);

				this.setState({
					submitting: false,
					dataSourceError:
						errCode === 'account.DATA_SOURCE_EXISTS'
							? this.props.DATA_SOURCE_EXISTS
							: errorMessage(err)
				});
			});
	};

	handleTestDataSource = e => {
		e.preventDefault();

		const {
			viewData: { selectedAccount, formData },
			isEditView,
			testExternalAccount
		} = this.props;

		const currentData = isEditView ? selectedAccount : formData;

		const testingData = {
			...pick(currentData, [
				'accountType',
				'connectionType',
				'host',
				'leaveOnServer',
				'port',
				'username',
				'password'
			])
		};

		const { port: customPort, useCustomPort, useSSL, accountType } = currentData;
		const port = useCustomPort ? customPort : mailPort(accountType, useSSL);
		const connectionType = useSSL ? 'ssl' : 'cleartext';

		testingData.port = port;
		testingData.connectionType = connectionType;

		this.setState({
			dataSourceTestInProgress: true,
			dataSourceTestSuccess: false,
			dataSourceError: null
		});

		testExternalAccount(testingData)
			.then(
				({
					data: {
						testExternalAccount: { success, error }
					}
				}) => {
					this.setState({
						dataSourceTestInProgress: false,
						dataSourceTestSuccess: success && success,
						dataSourceError: (error && ERRORS[error || 'generic']) || ERRORS.generic
					});
				}
			)
			.catch(() => {
				this.setState({
					dataSourceTestInProgress: false,
					dataSourceTestSuccess: false,
					dataSourceError: ERRORS.generic
				});
			});
	};

	render(
		{ isEditView, matchesScreenXs, viewData },
		{ dataSourceTestInProgress, dataSourceTestSuccess, submitting, dataSourceError }
	) {
		return dataSourceTestInProgress || dataSourceTestSuccess || submitting ? (
			<div class={style.testingDataSourceContainer}>
				{dataSourceTestSuccess ? (
					<SuccessfulConnectionMessage
						viewData={viewData}
						isEditView={isEditView}
						onClick={this.handleAccountAction}
					/>
				) : dataSourceTestInProgress ? (
					<TestingConnectionMessage viewData={viewData} isEditView={isEditView} />
				) : (
					<div class={style.testingDataSourceSection}>
						<Spinner class={style.spinner} />
					</div>
				)}
			</div>
		) : isEditView ? (
			<EditAccountView
				{...viewData}
				testDataSource={this.handleTestDataSource}
				matchesScreenXs={matchesScreenXs}
				error={dataSourceError}
			/>
		) : (
			<AddAccountView
				{...viewData}
				testDataSource={this.handleTestDataSource}
				matchesScreenXs={matchesScreenXs}
				error={dataSourceError}
			/>
		);
	}
}

export default AddEditAccountView;
