import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'preact/hooks';
import moment from 'moment';
import get from 'lodash-es/get';

import s from '../style.less';
import { callWith } from '@zimbra/util/src/call-with';
import FormGroup from '../../../form-group';
import AlignedLabel from '../../../aligned-form/label';
import DateInput from '../../../date-input';
import SelectOption from '../../../select';
import CustomTimePicker from '../../../custom-time-picker';
import { getParsedAppointmentData } from '../../../../store/calendar/selectors';
import { setEvent } from '../../../../store/calendar/actions';

export const AppointmentEndTime = ({
	isForwardInvite,
	zimbraPrefUseTimeZoneListInCalendar,
	eventEndTimeZone,
	momentTz,
	isProposeTime,
	tabId
}) => {
	const dispatch = useDispatch();
	const event = useSelector(state => getParsedAppointmentData(state, 'event', tabId));
	const start = moment(event.start);
	const { allDay } = event;
	const invalidDateRange = start.diff(event.end) > 0;

	const handleEndChange = useCallback(
		(date, isValid = true) => {
			const stateToUpdate = {
				isFormDirty: true,
				isStartDateValid: isValid
			};

			if (isValid) {
				stateToUpdate.event = {
					...event,
					end: date
				};
			}

			dispatch(
				setEvent({
					tabId,
					eventData: stateToUpdate
				})
			);
		},
		[dispatch, event, tabId]
	);

	const onEventTimeZoneChange = useCallback(
		(zone, changeEvent) => {
			const TzValue = changeEvent && get(changeEvent, 'target.value');

			dispatch(
				setEvent({
					tabId,
					eventData: {
						eventEndTimeZone: TzValue,
						isFormDirty: true,
						...(zone === 'start' && {
							eventStartTimeZone: TzValue
						})
					}
				})
			);
		},
		[dispatch, tabId]
	);

	return (
		<FormGroup>
			<AlignedLabel class={s.alignedLabel} align="left" textId="calendar.editModal.fields.end" />
			<div class={s.datepickerWrapper}>
				<DateInput
					class={s.dateSelector}
					dateValue={event.end}
					onDateChange={handleEndChange}
					invalid={invalidDateRange}
					disabled={isForwardInvite}
				/>
				<CustomTimePicker
					value={allDay ? moment(event.end).endOf('day') : event.end}
					disabled={allDay}
					onDateChange={handleEndChange}
				/>
				{zimbraPrefUseTimeZoneListInCalendar && (
					<div class={s.timeZoneSelector}>
						<SelectOption
							value={eventEndTimeZone || (momentTz && momentTz.tz.guess())}
							disabled={allDay || isForwardInvite || isProposeTime}
							onChange={callWith(onEventTimeZoneChange, 'end', true)}
						>
							{momentTz &&
								momentTz.tz.names().map(TimeZone => <option value={TimeZone}>{TimeZone}</option>)}
						</SelectOption>
					</div>
				)}
			</div>
		</FormGroup>
	);
};
