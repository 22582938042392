import { Text } from 'preact-i18n';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { Button } from '@zimbra/blocks';
import update from 'immutability-helper';
import get from 'lodash-es/get';
import { callWith } from '../../../lib/util';
import DeleteExternalAccountMutation from '../../../graphql/queries/accounts/account-delete-external-mutation.graphql';
import { FolderActionMutation } from '../../../graphql/queries/folders/folders.graphql';
import GetFolder from '../../../graphql/queries/folders/get-folder.graphql';
import GetDataSources from '../../../graphql/queries/datasource/get-datasource.graphql';
import style from '../style';
import { SYSTEM_FOLDERS_IDS } from '../../../constants';
import ModalDialog from '../../modal-dialog';

const ConfirmDeleteAccountView = ({ id, accountName, deleteExternalAccount, onCancel }) => (
	<ModalDialog
		title={
			<Text
				id="settings.accounts.editAccount.confirmation"
				fields={{
					accountName
				}}
			/>
		}
		buttons={[
			<Button styleType="primary" brand="danger" onClick={callWith(deleteExternalAccount, { id })}>
				<Text id="buttons.removeMailbox" />
			</Button>
		]}
		onClose={onCancel}
	>
		<p class={style.confirmationSpan}>
			<Text id="settings.accounts.editAccount.additionalConfirmationInfo" />
		</p>
	</ModalDialog>
);

export default compose(
	graphql(FolderActionMutation, {
		props: ({ mutate }) => ({
			/**
			 * On delete POP3 external account, delete folder from `Folders` list as well
			 */
			folderAction: folderId => {
				mutate({
					variables: {
						action: {
							op: 'delete',
							id: folderId
						}
					},
					optimisticResponse: {
						__typename: 'Mutation',
						folderAction: true
					},
					update: cache => {
						/**
						 * Get existing folder list and optimistically update folder
						 * list after deleting the account folder
						 */
						let data;
						try {
							data = cache.readQuery({
								query: GetFolder,
								variables: {
									view: null
								}
							});
						} catch (error) {
							console.error({ error });
						}

						if (!data) return;

						const deletedFolderIndex = get(data, 'getFolder.folders.0.folders').findIndex(
							folder => folder.id === folderId
						);

						if (deletedFolderIndex === -1) return;

						cache.writeQuery({
							query: GetFolder,
							variables: {
								view: null
							},
							data: update(data, {
								getFolder: {
									folders: {
										0: {
											folders: {
												$splice: [[deletedFolderIndex, 1]]
											}
										}
									}
								}
							})
						});
					}
				});
			}
		})
	}),
	graphql(DeleteExternalAccountMutation, {
		props: ({ mutate, ownProps: { folderAction, accountType, folderId } }) => ({
			deleteExternalAccount: ({ id }) => {
				mutate({
					variables: {
						id
					},
					refetchQueries: [
						{
							query: GetDataSources
						}
					],
					optimisticResponse: {
						__typename: 'Mutation',
						deleteExternalAccount: true
					},
					update: proxy => {
						const data = proxy.readQuery({ query: GetDataSources });
						const { imap, pop3 } = get(data, 'getDataSources');
						(imap || []).find((acc, i) => acc.id === id && imap.splice(i, 1));
						(pop3 || []).find((acc, i) => acc.id === id && pop3.splice(i, 1));
						proxy.writeQuery({ query: GetDataSources, data });
					}
				}).then(() => {
					if (accountType === 'pop3' && !SYSTEM_FOLDERS_IDS.includes(folderId)) {
						folderAction(folderId);
					}
				});
			}
		})
	})
)(ConfirmDeleteAccountView);
