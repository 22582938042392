import { useState, useEffect } from 'preact/hooks';
import { useSelector, shallowEqual } from 'react-redux';
import FolderRefreshIcon from './folder-refresh-icon';
import { getInitiatedRequests } from './../../store/polling-requests/selectors';

export default function ImportRefreshIcon({
	accountId,
	shouldVisible = true,
	buttonClass,
	onClick
}) {
	const initiatedRequests = useSelector(getInitiatedRequests, shallowEqual);
	const [isRefreshing, setIsRefreshing] = useState();
	const [isVisible, setIsVisible] = useState(shouldVisible);

	useEffect(() => {
		const requestInitiated = initiatedRequests.includes(accountId);
		setIsRefreshing(requestInitiated);
		setIsVisible(shouldVisible || requestInitiated);
	}, [accountId, initiatedRequests, shouldVisible]);

	return (
		<FolderRefreshIcon
			buttonClass={buttonClass}
			isRefreshing={isRefreshing}
			shouldVisible={isVisible}
			{...(!isRefreshing && { onClick })}
		/>
	);
}
