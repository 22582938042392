import { Component } from 'preact';
import PropTypes from 'prop-types';
import cx from 'classnames';
import linkref from 'linkref';

import { Icon, KeyCodes } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import NakedButton from '../naked-button';

import s from './style.less';
import TextInput from '../text-input';

export default class FolderInput extends Component {
	handleKeyDown = e => {
		if (e.keyCode === KeyCodes.CARRIAGE_RETURN) {
			this.props.onSubmit(e.target.value);
		} else if (e.keyCode === KeyCodes.ESCAPE) {
			this.props.onClose && this.props.onClose();
		}
	};

	handleBlur = e => {
		const { closeOnBlur, onClose, onBlur } = this.props;

		if (closeOnBlur) {
			onClose();
		}

		if (onBlur) {
			onBlur(e);
		}
	};

	static propTypes = {
		onClose: PropTypes.func,
		onSubmit: PropTypes.func.isRequired,
		onInput: PropTypes.func.isRequired,
		value: PropTypes.string.isRequired,
		closeOnBlur: PropTypes.bool,
		selectAllOnFocus: PropTypes.bool
	};

	static defaultProps = {
		closeOnBlur: true,
		selectAllOnFocus: false,
		onSubmit: () => {},
		placeholderTextId: ''
	};

	render({ onClose, onInput, value, placeholderTextId, icon, ...rest }) {
		return (
			<div {...rest} class={cx(s.folderInputContainer, rest.class)}>
				{icon && <Icon name={icon} class={s.folderInputIcon} size="sm" />}
				<TextInput
					class={cx(s.folderInput, icon && s.folderInputWithIcon)}
					value={value}
					onInput={onInput}
					onKeyDown={this.handleKeyDown}
					onBlur={this.handleBlur}
					placeholder={<Text id={placeholderTextId} />}
					aria-label={<Text id={placeholderTextId} />}
					inputRef={linkref(this, 'input')}
					autofocus
				/>
				{onClose && (
					<NakedButton class={s.folderInputClose} onClick={onClose}>
						<Icon name="close" size="sm" />
					</NakedButton>
				)}
			</div>
		);
	}
}
