import { useContext } from 'preact/hooks';
import { AppConfigContext } from '../context/app-config';
import getValueFromMapByString from '@zimbra/util/src/get-value-from-map-by-string';

/**
 * A decorator that returns value from config correspond to passed input as a string or an object
 * @param {String | Object({key[String]: String})} args
 */
export function appConfiguration(args) {
	return function (Child) {
		return function Config(props) {
			const config = useContext(AppConfigContext);
			const derivedProps = getValueFromMapByString(config, args);

			return <Child {...props} {...derivedProps} />;
		};
	};
}

export default appConfiguration;
