import PropTypes from 'prop-types';
import { PureComponent } from 'preact/compat';

import ContextMenu from '../context-menu';
import { FolderGroupContextMenu } from '../context-menus';
import CollapsibleControl from '../collapsible-control';
import cx from 'classnames';
import s from './style.less';
import { getDataTransferJSON } from '../../../packages/@zimbra/util/src/data-transfer-manager';
import { USER_ROOT_FOLDER_ID } from '../../constants';
import get from 'lodash-es/get';

export default class FolderListGroup extends PureComponent {
	state = {
		dropTarget: false
	};

	/**
	 * Following code is for CustomFolder Group
	 * As the CustomFolder is not originally a folder had to add
	 * different dragover and drop function to achieve the functionality
	 * @param {Object} sourceFolder the folder that needs to be moved
	 * @param {Array} destFolder array of CustomFolderGroup
	 * @returns {Boolean} whether Folder is droppable or not
	 */
	handleFolderDropFlag = destFolder => {
		const { sourceFolder } = this.props;
		/**
		 * this condition is for CustomFolder group if the group has a folder
		 * with same name has source folder
		 */
		if (Array.isArray(destFolder) && destFolder.length > 0 && sourceFolder) {
			return !destFolder.some(folder => folder.name === sourceFolder.name);
		}
		//if CustomFolder group is Empty
		return sourceFolder && true;
	};

	handleDragLeave = () => {
		this.setState({ dropTarget: false });
	};

	// this handleDragOver is for CustomFolder Group  to drop on root level
	handleDragOver = e => {
		e.preventDefault();
		const { onDragOverExpandFolder, customFolders, sourceFolder } = this.props;
		onDragOverExpandFolder(e.target, USER_ROOT_FOLDER_ID);
		const dropResult = this.handleFolderDropFlag(customFolders);

		if (sourceFolder && dropResult) {
			this.setState({ dropTarget: true });
		}
	};

	// this handleDrop is for CustomFolder Group  to drop on root level
	// for folders inside CustomFolder Group  it is handled in FolderListItem
	handleDrop = e => {
		e.preventDefault();
		const data = getDataTransferJSON(e);
		const { onFolderDrop, folderDrop, onRouteOnFolderActive } = this.props;

		const sourceFolder = get(data, 'sourceFolder');
		/**
		 * This condition is required because if user tries to drop CustomFolder
		 * that is not-droppable we need to clear the data of the source Folder
		 * (one case scenario : if user tries to drop a sourcefolder on a not droppable folder
		 * and then at that moment starts dragging mails to drop on that folders even if the mails are droappable
		 * they wont we droppable as state is not cleared and this function i.e onFolderDropFlag
		 * will get executed giving wrong indication to the user)
		 */
		if (data && sourceFolder) {
			if (this.state.dropTarget) {
				folderDrop(sourceFolder);
				onRouteOnFolderActive(sourceFolder);
			}
			if (onFolderDrop !== null) onFolderDrop();

			this.handleDragLeave();
		}
	};

	static propTypes = {
		name: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
		onToggle: PropTypes.func.isRequired,
		collapsed: PropTypes.bool,
		children: PropTypes.node
	};

	static defaultProps = {
		name: 'Folders',
		collapsed: false
	};

	render(
		{
			children,
			name,
			onToggle,
			onCreateFolder,
			onFindFolder,
			collapsed,
			menu,
			className,
			sourceFolder,
			tagGroupClass,
			toggleClass
		},
		{ dropTarget }
	) {
		return (
			<ContextMenu
				menu={
					menu && (
						<FolderGroupContextMenu onCreateFolder={onCreateFolder} onFindFolder={onFindFolder} />
					)
				}
				class={className}
			>
				<div
					class={cx(toggleClass, s.groupToggle, dropTarget && s.dropFolderTarget)}
					onClick={onToggle}
					{...(sourceFolder && {
						onDragOver: this.handleDragOver,
						onDrop: this.handleDrop,
						onDragLeave: this.handleDragLeave
					})}
				>
					<CollapsibleControl collapsed={collapsed} class={s.folderCollapsibleControl} />
					{name}
				</div>
				{!collapsed && <div class={cx(s.groupChildren, tagGroupClass)}>{children}</div>}
			</ContextMenu>
		);
	}
}
