import Markup from 'preact-markup';
import { useContext, useState, useEffect } from 'preact/hooks';
import ClientConfig from '../../context/client-config';

export default function TagLine() {
	const config = useContext(ClientConfig);
	const [html, setHtml] = useState(null);

	useEffect(() => {
		if (config.data.loginTagLineMarkup) {
			fetch(config.getAssetURLByClient('login-tagline-markup.html'))
				.then(res => {
					if (!res.ok) {
						throw 'unable to load login-tagline-markup.html';
					}

					return res.text();
				})
				.then(res => {
					setHtml(res);
				})
				.catch(() => console.error('unable to load login-tagline-markup.html'));
		}
	}, [config]);

	return html && <Markup markup={html} type="html" />;
}
