import { usePreferences } from '../../../hooks/preferences';
import { getFoldersAndSharedFolders } from '../../../utils/folders';
import { useDispatch } from 'react-redux';
import { useDocumentActionMutation, useBriefcaseFoldersQuery } from '../../../hooks/briefcase';
import { getUrlAndDefaultSortThunk, getSelectedDocumentIdsThunk } from '../thunk';
import { getFolderSearchQueryParameters, routeOnDeleteOrMovingItem } from '../util';
import { types as apiClientTypes } from '@zimbra/api-client';
import { itemActionVariables } from '../../../hooks/action/utils';
import { TRASH } from '../../../constants/folders';

const { ActionOps } = apiClientTypes;

const useDocumentDelete = () => {
	const dispatch = useDispatch();
	const documentAction = useDocumentActionMutation();
	const { data: foldersData } = useBriefcaseFoldersQuery({ fetchPolicy: 'cache-only' });
	const { folders, sharedFolders } = getFoldersAndSharedFolders(foldersData);
	const { data } = usePreferences({ fetchPolicy: 'cache-only' });
	const zimbraPrefSortOrder = data?.getPreferences?.zimbraPrefSortOrder;

	const { url, defaultSort } = dispatch(getUrlAndDefaultSortThunk());
	const { folderName, sortValue, documentQuery } = getFolderSearchQueryParameters({
		url,
		defaultSort,
		folders,
		sharedFolders,
		zimbraPrefSortOrder
	});

	const deleteDocuments = documentIds =>
		documentAction({
			variables: itemActionVariables({
				id: documentIds,
				op: folderName.includes(TRASH) ? ActionOps.delete : ActionOps.trash
			}),
			documentQuery,
			documentIds,
			sortValue
		}).then(() => {
			if (dispatch(getSelectedDocumentIdsThunk()).length === 1) {
				routeOnDeleteOrMovingItem({ dispatch, url });
			}
		});

	return deleteDocuments;
};

export default useDocumentDelete;
