import { Component } from 'preact';
import { ESCAPE } from '../constants/key-codes';
import { isCurrentModal } from '../lib/modal-manager';

/**
 * Wraps child components in `ModalManager` which will plug modal-manager functionality onto the component it wraps.
 * @param {String} clickOutsideHandlerKey This is the name of the onClickOutsideHandler function being used. Or in other words the function that is called to close the modal.
 */

export default function withModalKeydownEnhancer(BaseComponent) {
	class ModalKeydownManager extends Component {
		handleKeyDown = e => {
			if (e.keyCode === ESCAPE) {
				const { onEscKeydown, modalId } = this.props;
				if (typeof onEscKeydown === 'function') {
					e.stopPropagation();
					isCurrentModal(modalId) && onEscKeydown(e);
				}
			}
		};

		events = onOrOff => {
			const fn = onOrOff === true ? addEventListener : removeEventListener;
			fn('keydown', this.handleKeyDown, true);
		};

		componentDidMount() {
			this.events(true);
		}

		componentWillUnmount() {
			this.events(false);
		}

		render(props) {
			return <BaseComponent {...props} />;
		}
	}
	return ModalKeydownManager;
}
