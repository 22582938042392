import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import update from 'immutability-helper';
import get from 'lodash-es/get';

import EnableTwoFactorAuthMutation from '../../graphql/mutations/two-factor-auth/enable-two-factor-auth.graphql';
import DisableTwoFactorAuthMutation from '../../graphql/mutations/two-factor-auth/remove-two-factor-auth.graphql';
import AccountInfoQuery from '../../graphql/queries/preferences/account-info.graphql';
import getContext from '../../lib/get-context';
//import { configure } from '../../config';
import csrfStorage from '../../utils/csrf';
import omitProps from '../../enhancers/omit-props';
//import jwtStorage from '../../utils/jwt';

const updateTwoFactorAuthEnabled = (data, value) =>
	update(data, {
		accountInfo: {
			attrs: {
				$merge: { zimbraTwoFactorAuthEnabled: value }
			}
		}
	});

export function withEnableTwoFactorAuth() {
	return compose(
		getContext(context => ({ context })),
		//configure('useJwt'),
		graphql(EnableTwoFactorAuthMutation, {
			props: ({ mutate, ownProps: { context /*, useJwt */ } }) => ({
				enableTwoFactorAuth: variables =>
					mutate({
						variables: {
							options: variables
						},
						update: cache => {
							if (variables.twoFactorCode) {
								try {
									const data = cache.readQuery({ query: AccountInfoQuery });

									if (get(data, 'accountInfo.attrs')) {
										cache.writeQuery({
											query: AccountInfoQuery,
											data: updateTwoFactorAuthEnabled(data, true)
										});
									}
								} catch (e) {
									console.warn('Error reading accountInfo query', e);
								}
							}
						}
					}).then(res => {
						// if (useJwt) {
						// 	const jwtToken = get(res, 'data.enableTwoFactorAuth.authToken.0._content');
						// 	jwtStorage.set(jwtToken, context);
						// }

						const csrfToken = get(res, 'data.enableTwoFactorAuth.csrfToken.0._content');
						// If JWT is enabled then we will not get csrf token, so ignore it in that case
						if (csrfToken) {
							csrfStorage.set(csrfToken, context);
						}

						return res;
					})
			})
		}),
		omitProps('context')
	);
}

export function withDisableTwoFactorAuth() {
	return graphql(DisableTwoFactorAuthMutation, {
		props: ({ mutate }) => ({
			disableTwoFactorAuth: () =>
				mutate({
					update: cache => {
						const data = cache.readQuery({ query: AccountInfoQuery });
						if (get(data, 'accountInfo.attrs')) {
							cache.writeQuery({
								query: AccountInfoQuery,
								data: updateTwoFactorAuthEnabled(data, false)
							});
						}
					}
				})
		})
	});
}
