import cx from 'classnames';
import Button from '../button';
import style from './style';

export const ProgressButton = ({
	disabled,
	onClick,
	remainingPercent,
	tooltip,
	inProgress,
	showProgress,
	text,
	className
}) => {
	return (
		<span {...(inProgress && { title: tooltip })}>
			<Button
				class={cx(style.btnProgress, inProgress && style.disabledButton, className)}
				disabled={disabled}
				onClick={onClick}
			>
				{showProgress && (
					<div
						class={cx(style.progress)}
						style={{
							width: remainingPercent
						}}
					/>
				)}
				<span>{text}</span>
			</Button>
		</span>
	);
};
