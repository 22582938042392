import { Fragment } from 'preact';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'preact/hooks';
import { Text } from 'preact-i18n';
import cx from 'classnames';
import get from 'lodash-es/get';

import s from '../style.less';
import { ChoiceInput } from '@zimbra/blocks';
import FormGroup from '../../../form-group';
import AlignedLabel from '../../../aligned-form/label';
import SelectOption from '../../../select';
import { REMIND_OPTIONS } from '../../../../constants/calendars';

import { getParsedAppointmentData } from '../../../../store/calendar/selectors';
import { getParsedAppointmentDataThunk } from '../../thunk';
import { setEvent } from '../../../../store/calendar/actions';

export const SetReminder = ({
	tabId,
	zimbraPrefCalendarReminderEmail,
	isForwardInvite,
	isProposeTime,
	disabledElement,
	isCopyInvite,
	appointmentData
}) => {
	const dispatch = useDispatch();
	const { remindValue, remindDesktop, isAlarmAsEmailEnabled } = useSelector(state => ({
		remindValue: getParsedAppointmentData(state, 'remindValue', tabId),
		remindDesktop: getParsedAppointmentData(state, 'remindDesktop', tabId),
		isAlarmAsEmailEnabled: getParsedAppointmentData(state, 'isAlarmAsEmailEnabled', tabId)
	}));

	const shouldEnableEmailAlarm = useCallback(() => {
		const event = dispatch(getParsedAppointmentDataThunk('event', tabId));
		return !!(event.new && !isCopyInvite
			? zimbraPrefCalendarReminderEmail
			: get(
					(get(appointmentData, 'message.invitations.0.components.0.alarms') || []).find(
						({ action }) => action === 'EMAIL'
					),
					'attendees.0.email'
			  ));
	}, [appointmentData, dispatch, isCopyInvite, tabId, zimbraPrefCalendarReminderEmail]);

	const handleRemindValueChange = useCallback(
		({ target: { value } }) => {
			dispatch(
				setEvent({
					tabId,
					eventData: {
						remindValue: value,
						isAlarmAsEmailEnabled: value !== 'never' && shouldEnableEmailAlarm(),
						isFormDirty: true
					}
				})
			);
		},
		[dispatch, shouldEnableEmailAlarm, tabId]
	);

	const handleRemindDesktopChange = useCallback(
		({ target: { checked } }) => {
			dispatch(
				setEvent({
					tabId,
					eventData: {
						remindDesktop: checked,
						isFormDirty: true
					}
				})
			);
		},
		[dispatch, tabId]
	);

	const handleRemindEmailChange = useCallback(
		({ target: { checked } }) => {
			dispatch(
				setEvent({
					tabId,
					eventData: {
						isAlarmAsEmailEnabled: checked,
						isFormDirty: true
					}
				})
			);
		},
		[dispatch, tabId]
	);

	return (
		<Fragment>
			<FormGroup compact={remindValue !== 'never' && zimbraPrefCalendarReminderEmail}>
				<AlignedLabel
					class={s.alignedLabel}
					align="left"
					textId="calendar.editModal.fields.remind"
				/>
				<SelectOption
					value={remindValue}
					onChange={handleRemindValueChange}
					disabled={isForwardInvite || isProposeTime}
					class={disabledElement}
				>
					{REMIND_OPTIONS.map(k => (
						<option value={k} key={k}>
							<Text id={`calendar.editModal.fields.remindOptions.${k}`} />
						</option>
					))}
				</SelectOption>
			</FormGroup>
			{remindValue !== 'never' && (
				<FormGroup class={s.fieldOffset} rows>
					<label class={cx(s.subOption, disabledElement)}>
						<ChoiceInput
							onChange={handleRemindDesktopChange}
							checked={remindDesktop}
							disabled={isForwardInvite || isProposeTime}
						/>
						<Text id="calendar.editModal.fields.mobileDesktop" />
					</label>
					{zimbraPrefCalendarReminderEmail && (
						<label class={cx(s.subOption, disabledElement)}>
							<ChoiceInput
								onChange={handleRemindEmailChange}
								checked={isAlarmAsEmailEnabled}
								disabled={isForwardInvite || isProposeTime}
							/>
							<Text
								id="calendar.editModal.fields.email"
								fields={{ email: zimbraPrefCalendarReminderEmail }}
							/>
						</label>
					)}
				</FormGroup>
			)}
		</Fragment>
	);
};
