import { useEffect, useCallback, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';

import NoopQuery from '../../../graphql/queries/noop.graphql';
import { MANUAL_REFRESH, AS_NEW_MAIL_ARRIVES } from '../../../constants/mail';

/**
 * A component which handles the initialization and updation of NoOp polling.
 *
 * @export
 * @param {Object} props props for the component
 * @param {Object} context app's context
 * @returns
 */
export function NoOpPollingManager({ mailPollingInterval, isOffline }, { client }) {
	const noOpInterval = useRef(null);
	const noOpAbortController = useRef(null);
	const noOpTimer = useRef(null);

	const clearTimers = useCallback(() => {
		noOpInterval.current && clearInterval(noOpInterval.current);
		noOpTimer.current && clearTimeout(noOpTimer.current);
		noOpAbortController.current && noOpAbortController.current.abort();
	}, []);

	/**
	 * Conditionally binds the NoOp query.
	 *
	 * for mailPollingInterval as
	 * 1. MANUAL_REFRESH
	 * 2. AS_NEW_MAIL_ARRIVES
	 * 3. Other value in milliseconds
	 *
	 */
	const initNoOp = useCallback(() => {
		const HALF_SEC = 500;
		const ONE_MIN = 60 * 1000;
		const FIVE_MIN = 5 * ONE_MIN;

		// clear all existing timers
		clearTimers();

		if (!isOffline) {
			if (mailPollingInterval && mailPollingInterval !== MANUAL_REFRESH) {
				// for as new mail arrives
				if (mailPollingInterval === AS_NEW_MAIL_ARRIVES) {
					// handle the vercel.app deployment environment differently as vercel.app terminates the api call if it takes more than 30 seconds
					// hence for that case, we will ping the api every one minute instead of doing long polling
					if (window.location.origin.includes('vercel.app')) {
						noOpInterval.current = setInterval(() => {
							client.query({
								query: NoopQuery,
								fetchPolicy: 'network-only'
							});
						}, ONE_MIN);
					} else {
						const invokeNoOp = () => {
							// Create abort controller
							noOpAbortController.current = new window.AbortController();

							// set a timer to cancel the request after 5 minutes
							if (noOpTimer.current) clearTimeout(noOpTimer.current);
							noOpTimer.current = setTimeout(() => {
								noOpAbortController.current && noOpAbortController.current.abort();
								setTimeout(invokeNoOp, HALF_SEC);
							}, FIVE_MIN);

							return (
								client
									.query({
										query: NoopQuery,
										variables: {
											wait: 1,
											limitToOneBlocked: 1
										},
										context: {
											retry: false,
											fetchOptions: {
												signal: noOpAbortController.current.signal
											}
										},
										fetchPolicy: 'network-only'
									})
									.then(({ data }) => {
										const waitDisallowed = get(data, 'noop.waitDisallowed');
										// if waitDisallowed is true, server is instructing the client to stop polling and get
										// to a periodic check instead
										setTimeout(invokeNoOp, waitDisallowed ? FIVE_MIN : HALF_SEC);
									})
									// check after 5 minutes if the request fails
									.catch(err => {
										// don't re-trigger if the catch was executed due to abort request
										if (!/The user aborted a request/.test(err.message)) {
											setTimeout(invokeNoOp, FIVE_MIN);
										}
									})
							);
						};

						setTimeout(invokeNoOp, 1000);
					}
				} else {
					// for any other value
					noOpInterval.current = setInterval(() => {
						client.query({
							query: NoopQuery,
							fetchPolicy: 'network-only'
						});
					}, mailPollingInterval);
				}
			}
		}
	}, [clearTimers, client, isOffline, mailPollingInterval]);

	const delayedNoOpInit = useCallback(
		(timeout = 5000) => setTimeout(initNoOp, timeout),
		[initNoOp]
	);

	useEffect(() => {
		delayedNoOpInit();
		return () => {
			clearTimers();
		};
	}, [clearTimers, delayedNoOpInit, mailPollingInterval]);

	useEffect(() => {
		if (isOffline) {
			clearTimers();
		}
	}, [clearTimers, isOffline]);

	return null;
}

NoOpPollingManager.propTypes = {
	isOffline: PropTypes.bool,
	mailPollingInterval: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
