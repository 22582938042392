import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import get from 'lodash-es/get';
import omit from 'lodash-es/omit';
import memoize from 'lodash-es/memoize';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { normalizeFoldersExpanded } from '../../utils/prefs';
import GetMailboxMetadataQuery from '../../graphql/queries/mailbox-metadata.graphql';
import SetMailboxMetadataMutation from '../../graphql/mutations/set-mailbox-metadata.graphql';
import {
	ARCHIVE_MAILBOX_METADATA_SECTION,
	DEFAULT_MAILBOX_METADATA_SECTION,
	MailListPaneMaxGrowthThreshold,
	MailListPaneMinShrinkThreshold
} from '../../constants/mailbox-metadata';

const memoizedGetMailboxMetadata = memoize(mailboxMetadata => {
	const attrs = get(mailboxMetadata, 'meta.0._attrs') || {};

	let { zimbraPrefReadingPaneSashHorizontal, zimbraPrefReadingPaneSashVertical } = attrs;

	if (zimbraPrefReadingPaneSashHorizontal) {
		zimbraPrefReadingPaneSashHorizontal = Math.min(
			Math.max(zimbraPrefReadingPaneSashHorizontal, MailListPaneMinShrinkThreshold),
			MailListPaneMaxGrowthThreshold
		);
	}

	if (zimbraPrefReadingPaneSashVertical) {
		zimbraPrefReadingPaneSashVertical = Math.min(
			Math.max(zimbraPrefReadingPaneSashVertical, MailListPaneMinShrinkThreshold),
			MailListPaneMaxGrowthThreshold
		);
	}

	return {
		mailboxMetadata: {
			...attrs,
			zimbraPrefReadingPaneSashHorizontal,
			zimbraPrefReadingPaneSashVertical
		},
		folderTreeOpen: get(attrs, 'zimbraPrefCustomFolderTreeOpen') || false,
		sharedFolderTreeOpen: get(attrs, 'zimbraPrefSharedFolderTreeOpen') || false,
		smartFolderTreeOpen: get(attrs, 'zimbraPrefSmartFolderTreeOpen') || false,
		foldersExpanded: normalizeFoldersExpanded(get(attrs, 'zimbraPrefFoldersExpanded')),
		groupByList: get(attrs, 'zimbraPrefGroupByList') || null,
		messageListDensity: get(attrs, 'zimbraPrefMessageListDensity') || null
	};
});

export function getMailboxMetadata(config = {}, mapProps) {
	const {
		options: { fetchPolicy = 'cache-first', variables = {}, ...restOptions } = {},
		...restConfig
	} = config;

	return graphql(GetMailboxMetadataQuery, {
		...restConfig,
		options: ({ isOffline }) => ({
			...restOptions,
			variables,
			fetchPolicy: isOffline ? 'cache-only' : fetchPolicy
		}),
		props: ({ data }) => {
			if (mapProps) {
				return mapProps(data);
			}

			return memoizedGetMailboxMetadata(data.getMailboxMetadata);
		}
	});
}

export function getArchiveZimletMailboxMetadata(config = {}) {
	const {
		options: { fetchPolicy = 'cache-first', variables, ...restOptions } = {},
		...restConfig
	} = config;

	return graphql(GetMailboxMetadataQuery, {
		...restConfig,
		options: ({ isOffline }) => ({
			...restOptions,
			variables: {
				...variables,
				section: ARCHIVE_MAILBOX_METADATA_SECTION
			},
			fetchPolicy: isOffline ? 'cache-only' : fetchPolicy
		}),
		props: ({ data: { getMailboxMetadata: mailboxMetadata } }) => {
			const attrs = get(mailboxMetadata, 'meta.0._attrs');
			return {
				archivedFolder: get(attrs, 'archivedFolder') || null
			};
		}
	});
}

const mailboxMetadataWrite = (proxy, section, nextAttrs) => {
	proxy.writeFragment({
		id: `MailboxMetadata:${section}`,
		fragment: gql`
		fragment attrs on MailboxMetadata {
			meta {
				section
				_attrs {
					${Object.keys(nextAttrs)}
				}
			}
		}
	`,
		data: {
			__typename: 'MailboxMetadata',
			meta: [
				{
					__typename: 'MailboxMetadataMeta',
					section,
					_attrs: {
						__typename: 'MailboxMetadataAttrs',
						...nextAttrs
					}
				}
			]
		}
	});
};

const memoizedSetMailboxMetadata = memoize(
	(mutate, client, section, propName, isOfflineDesktop) => attrs => {
		let prevMailboxMetadataAttrs;

		try {
			const prevMailboxMetadata = get(
				client.readQuery({
					query: GetMailboxMetadataQuery,
					variables: { ...(section && { section }) }
				}),
				'getMailboxMetadata.meta.0._attrs'
			);

			if (!prevMailboxMetadata) {
				throw Error(
					'Missing previous mailbox metadata might result in overriding its values unknowingly'
				);
			}

			prevMailboxMetadataAttrs = omit(prevMailboxMetadata, '__typename');
		} catch (e) {
			console.error(e);
		}

		// All attributes must be passed to the server when modifying
		const nextAttrs = {
			...prevMailboxMetadataAttrs,
			...attrs
		};

		if (isOfflineDesktop) {
			// Skip mutation in case user is offline on desktop app
			mailboxMetadataWrite(client, section, nextAttrs);
			return Promise.resolve();
		}
		return mutate({
			variables: {
				section,
				attrs: nextAttrs
			},
			optimisticResponse: {
				__typename: 'Mutation',
				[propName]: true
			},
			update: proxy => {
				// Write a fragment to the subsection of MailboxMetadata
				// with the changed attributes.
				mailboxMetadataWrite(proxy, section, nextAttrs);
			}
		});
	}
);

const mailboxMetadataMutationFactory = (propName, section) => () =>
	compose(
		connect(state => ({
			isOfflineDesktop: state.network.isOffline && typeof process.env.ELECTRON_ENV !== 'undefined'
		})),
		graphql(SetMailboxMetadataMutation, {
			props: ({ mutate, result: { client }, ownProps: { isOfflineDesktop } }) => ({
				[propName]: memoizedSetMailboxMetadata(mutate, client, section, propName, isOfflineDesktop)
			})
		})
	);

export const withSetMailboxMetaData = mailboxMetadataMutationFactory(
	'setMailboxMetadata',
	DEFAULT_MAILBOX_METADATA_SECTION
);
export const withSetArchiveMailboxMetaData = mailboxMetadataMutationFactory(
	'setArchiveZimletMailboxMetadata',
	ARCHIVE_MAILBOX_METADATA_SECTION
);
