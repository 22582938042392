import { clientConfig, DEFAULT_CLIENT } from './context/client-config';
import { useContext, useEffect } from 'preact/hooks';
import { getBasePath } from './lib/util';

const basePath = getBasePath();

const cache = {};

const setAttributes = (el, attrs = {}) => {
	for (const key in attrs) {
		if (attrs[key]) {
			el.setAttribute(key, attrs[key]);
		}
	}
};

const createMeta = (attrs, fragment) => {
	const meta = document.createElement('meta');
	setAttributes(meta, attrs);
	fragment.appendChild(meta);
};

const createLink = (attrs, fragment) => {
	const link = document.createElement('link');
	setAttributes(link, attrs);
	fragment.appendChild(link);
};

const processManifestFile = (manifest, config) => ({
	...manifest,
	icons: manifest.icons.map(({ src, ...rest }) => ({
		...rest,
		src: `${window.location.origin}${basePath && `/${basePath}`}/clients/${
			config.data.client
		}${src}`
	})),
	start_url: `${window.location.origin}/${basePath}`
});

const loadManifest = ({ manifest, config }) => {
	if (Object.keys(manifest).length) {
		const fragment = document.createDocumentFragment();

		// To add title for apple mobile
		createMeta({ name: 'apple-mobile-web-app-title', content: manifest.name }, fragment);

		// To add theme color
		createMeta({ name: 'theme-color', content: manifest['theme_color'] }, fragment);

		// To add browserconfig xml
		createMeta(
			{
				name: 'msapplication-config',
				content: config.getAssetURLByClient('browserconfig.xml')
			},
			fragment
		);

		manifest.icons.forEach(icon => {
			// To add icons for apple device
			icon.src.includes('/ios/') &&
				createLink(
					{
						href: icon.src,
						rel: 'apple-touch-icon',
						sizes: icon.sizes
					},
					fragment
				);

			// To add mask-icon
			!icon.src.includes('ios') &&
				createLink(
					{
						href: icon.src,
						rel: 'mask-icon',
						color: manifest['theme_color']
					},
					fragment
				);
		});

		const stringManifest = JSON.stringify(manifest);
		const blob = new Blob([stringManifest], { type: 'application/json' });
		const manifestURL = URL.createObjectURL(blob);

		// To add manifest.json
		createLink(
			{
				href: manifestURL,
				rel: 'manifest',
				crossorigin: 'use-credentials',
				type: 'application/manifest+json'
			},
			fragment
		);

		document.getElementsByTagName('head')[0].appendChild(fragment);
	}
};

const loadTheme = ({ config }) => {
	const fragment = document.createDocumentFragment();

	if (config.data.client !== DEFAULT_CLIENT) {
		// To load client color palette
		createLink(
			{
				href: config.getThemeResourceURL('palette.css'),
				type: 'text/css',
				rel: 'stylesheet'
			},
			fragment
		);

		// To load client specific theme
		createLink(
			{
				href: config.getThemeResourceURL('index.css'),
				type: 'text/css',
				rel: 'stylesheet'
			},
			fragment
		);
	}

	document.getElementsByTagName('head')[0].appendChild(fragment);
};

const addFavicon = ({ config }) => {
	const fragment = document.createDocumentFragment();
	createLink(
		{
			href: config.getAssetURLByClient('favicon.ico'),
			type: 'image/x-icon',
			rel: 'shortcut icon'
		},
		fragment
	);
	document.getElementsByTagName('head')[0].appendChild(fragment);
};

const removeResources = () => {
	const querySelectors = [
		'link[href*="favicon.ico"]',
		'meta[name*=apple-mobile-web-app-title]',
		'meta[name*=theme-color]',
		'meta[name*=msapplication-config]',
		'link[rel*=manifest]'
	];
	querySelectors.forEach(selector => {
		const element = document.querySelector(selector);
		element && element.remove();
	});

	const querySelectorAll = ['link[rel*=apple-touch-icon]', 'link[rel*=mask-icon]'];
	querySelectorAll.forEach(selector => {
		const elements = document.querySelectorAll(selector);
		if (elements.length) {
			elements.forEach(icon => icon.remove());
		}
	});
};

const updateDescription = config => {
	if (config.data.meta) {
		const { description } = config.data.meta;

		if (description) {
			const element = document.querySelector('meta[name*=description]');
			element && element.remove();

			const fragment = document.createDocumentFragment();

			createMeta(
				{
					name: 'description',
					content: description
				},
				fragment
			);

			document.getElementsByTagName('head')[0].appendChild(fragment);
		}
	}
};

const updateRobotsMeta = config => {
	if (config.data.meta) {
		const { robots } = config.data.meta;

		if (robots) {
			const element = document.querySelector('meta[name*=robots]');
			element && element.remove();

			const fragment = document.createDocumentFragment();

			createMeta(
				{
					name: 'robots',
					content: robots
				},
				fragment
			);
			document.getElementsByTagName('head')[0].appendChild(fragment);
		}
	}
};

export default function ConfigureClient() {
	const config = useContext(clientConfig);

	useEffect(() => {
		document.title = config.data.title;

		if (config.data.client !== DEFAULT_CLIENT) {
			updateDescription(config);

			updateRobotsMeta(config);

			removeResources();

			// To load theme specific to client
			loadTheme({ config });

			// To load client specific manifest file only for web
			if (typeof process.env.ELECTRON_ENV === 'undefined') {
				const manifestPath = config.getPWAResourceByClient('manifest.json');

				if (cache[manifestPath]) {
					loadManifest({ manifest: cache[manifestPath], config, manifestPath });
				} else {
					fetch(manifestPath)
						.then(d => d.ok && d.json())
						.then(manifest => {
							cache[manifestPath] = processManifestFile(manifest, config);
							loadManifest({
								manifest: cache[manifestPath],
								config,
								manifestPath
							});
						})
						.catch(err => {
							console.warn('Error processing PWA', err);
						});
				}
			}
		}

		addFavicon({ config });
	}, [config]);

	return null;
}
