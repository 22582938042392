export const NICKNAME = 'nickname';
export const HOME = 'home';
export const WORK = 'work';
export const OTHER = 'other';
export const EMAIL = 'email';
export const MOBILE = 'mobilePhone';
export const ADDRESS = 'address';
export const IM = 'imAddress';
export const ANNIVERSARY = 'anniversary';
export const BIRTHDAY = 'birthday';
export const COUNTRY = 'Country';
export const ADD_MORE_FIELD_PLACEHOLDER = '{add_more_field_placeholder}';
export const IMAGE = 'image';
export const USER_CERTIFICATE = 'userCertificate';
export const WEBSITE = 'website';
export const COMPANY = 'company';
export const CUSTOM = 'custom';
