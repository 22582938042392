import get from 'lodash-es/get';
import uniq from 'lodash-es/uniq';
import find from 'lodash-es/find';
import moment from 'moment';
import { groupMailBy } from '../../constants/user-prefs';
import {
	groupByList,
	multitasking,
	SMIMEDefaultSetting,
	SEND_READ_RECEIPT
} from '../../constants/mailbox-metadata';
import { getDayNumber } from '../../lib/util';
import ViewingEmailSettings from './viewing-email-settings';
import WritingEmailSettings from './writing-email-settings';
import AccountsSettings from './accounts-settings';
import SignaturesSettings from './signatures-settings';
import SharingSettings from './sharing-settings';
import OfflineModeSettings from './offline-mode-settings';
import CalendarAndRemindersSettings from './calendar-and-reminders-settings';
import { soapTimeToJson, jsonTimeToSoap } from '../../utils/prefs';
import FiltersSettings from './filters-settings';
import VacationResponseSettings from './vacation-response-settings';
import { CALENDAR_IDS, CALENDAR_TYPE } from '../../constants/calendars';
import SMimeAndEncryption from './smime-and-encryption';
import { isSMIMEFeatureAvailable } from '../../utils/license';
import AccountRecoverySettings from './account-recovery-settings';
import ZimletsSettings from './zimlets-settings';
import { SMIME_OPERATIONS } from '../../constants/smime';
import { WEBCLIENT_OFFLINE_BROWSER_KEY } from '../../constants/offline';
import { DEFAULT_INTERVAL } from '../../constants/mail';
import { convertToSeconds } from '../../utils/mail-polling';
import BlockedAndAllowed from './blocked-and-allowed-addresses-settings';
import GeneralSettings from './general-settings';
import {
	DEFAULT_FONT_COLOR,
	DEFAULT_FONT_FAMILY,
	DEFAULT_FONT_SIZE,
	DEFAULT_COMPOSE_DIRECTION
} from '../../constants/fonts';
import { HTML_MODE } from '../../constants/composer';
import OfflineDesktopSettings from './offline-desktop-settings';

/**
 * The types of fields that can be updated by settings
 * @typedef {string} {FieldType}
 * @enum {FieldType}
 */

export const FIELD_TYPES = {
	MAILBOX_METADATA: 'mailboxMetadata',
	USER_PREF: 'userPref',
	ACCOUNT_DATA: 'accountData',
	USER_ATTR: 'userAttribute',
	WHITE_BLACK_LIST: 'whiteBlacklist',
	FILTER_RULES: 'filterRules',
	MODIFY_PROPERTIES: 'modifyProperties',
	ZIMLET_PREFS: 'zimletPrefs',
	DESKTOP_PREF_LOCAL: 'desktopPrefLocal'
};

const DESKTOP_PREF_LOCAL_FIELDS = {
	DEFAULT_MAILTO_CLIENT: 'defaultMailtoClient',
	AUTO_ARCHIVE_FREQUENCY: 'autoArchiveFrequency'
};

export const DEFAULT_SIGNATURE_PREFIX = 'Default Signature';
export const FORWARD_REPLY_SIGNATURE_PREFIX = 'Forward Reply Signature';

export const ADDITIONAL_SIGNATURE_ACTIONS = {
	ADD: 'add',
	MODIFY_NAME: 'modifyname',
	MODIFY_CONTENT: 'modifycontent',
	DELETE: 'delete'
};

export const NEW_MAIL_PREF_VALUES = {
	NONE: 'none',
	INBOX: 'inbox',
	ALL_FOLDERS: 'allFolders'
};

export const EXTERNAL_ACCOUNT_TYPES = {
	IMAP: 'imap',
	POP: 'pop3'
};

/**
 * Methods for getting field values from props and saving field types back to the server.
 * @typedef {Object} {FieldTypeMethod}
 * @property {Function} selector - Calculate the current value of settings for a given key
 * @property {Function} updateAction - Save the updated settings back to the server.
 */
export const FIELD_TYPE_METHODS = {
	[FIELD_TYPES.MAILBOX_METADATA]: {
		selector: (key, ownProps) => get(ownProps, `mailboxMetadata.${key}`),
		updateAction: (newMailboxMetaData, { setMailboxMetadata }) =>
			setMailboxMetadata(newMailboxMetaData)
	},
	[FIELD_TYPES.USER_PREF]: {
		selector: (key, ownProps) => get(ownProps, `preferenceInfo.${key}`),
		updateAction: (prefs, { modifyPrefs }) => modifyPrefs(prefs)
	},
	[FIELD_TYPES.USER_ATTR]: {
		selector: (key, ownProps) => get(ownProps, `account.attrs.${key}`)
	},
	[FIELD_TYPES.ACCOUNT_DATA]: {
		selector: (key, ownProps) => get(ownProps, `account.${key}`)
	},
	[FIELD_TYPES.WHITE_BLACK_LIST]: {
		selector: (key, ownProps) => {
			const blackList = get(ownProps, `whiteBlackList.blackList.0.addr`);
			const whiteList = get(ownProps, `whiteBlackList.whiteList.0.addr`);

			if (!whiteList && !blackList) return { blockedAddresses: [], allowedAddresses: [] };

			return {
				blockedAddresses: (Array.isArray(blackList) && blackList.map(addr => addr._content)) || [],
				allowedAddresses: (Array.isArray(whiteList) && whiteList.map(addr => addr._content)) || []
			};
		},
		updateAction: (
			{ whiteBlackList: { blockedAddresses, allowedAddresses } },
			{ updateWhiteBlackList }
		) =>
			updateWhiteBlackList({
				blackList: { addresses: uniq(blockedAddresses) },
				whiteList: { addresses: uniq(allowedAddresses) }
			})
	},
	[FIELD_TYPES.FILTER_RULES]: {
		selector: (key, ownProps) => get(ownProps, 'filters.data'),
		updateAction: ({ filterRules }, { modifyFilterRules }) => modifyFilterRules(filterRules)
	},
	[FIELD_TYPES.ZIMLET_PREFS]: {
		selector: (key, ownProps) => {
			const zimletsData = get(ownProps, 'account.zimlets.zimlet');

			if (!zimletsData) return null;

			return zimletsData
				.filter(zimlet => zimlet.zimlet[0].zimbraXZimletCompatibleSemVer !== null)
				.map(zimlet => ({
					name: zimlet.zimlet[0].name,
					label: zimlet.zimlet[0].label,
					presence: zimlet.zimletContext[0].presence,
					description: zimlet.zimlet[0].description
				}));
		},
		updateAction: ({ zimletPrefs }, { modifyZimletPrefs }) =>
			modifyZimletPrefs(
				zimletPrefs
					.filter(zimlet => zimlet.presence !== 'mandatory')
					.map(zimlet => ({
						name: zimlet.name,
						presence: zimlet.presence
					}))
			)
	},
	[FIELD_TYPES.MODIFY_PROPERTIES]: {
		// NOTE: Does not consider the zimlet name that added the property.
		selector: (key, ownProps) => find(get(ownProps, `account.props.prop`), { name: key }),
		updateAction: (props, { modifyProps }) =>
			modifyProps(Object.keys(props).map(prop => props[prop]))
	},
	[FIELD_TYPES.DESKTOP_PREF_LOCAL]: {
		selector: (key, ownProps) => ownProps[key],
		updateAction: localPrefs => {
			if (typeof process.env.ELECTRON_ENV !== 'undefined') {
				const { rendererIpc } = require('@zimbra/electron-app');
				const prefKeys = Object.keys(localPrefs);

				for (let i = 0; i < prefKeys.length; i++) {
					const keyName = prefKeys[i];

					switch (keyName) {
						case DESKTOP_PREF_LOCAL_FIELDS.DEFAULT_MAILTO_CLIENT:
							return rendererIpc.setDefaultMailToClient();
						case DESKTOP_PREF_LOCAL_FIELDS.AUTO_ARCHIVE_FREQUENCY:
							return rendererIpc.updateAutoArchiveLocalPrefs(localPrefs[keyName]);
						default:
							break;
					}
				}
			}
		}
	}
};

/**
 * Create an entry used by settings menu to retrieve/save individual settings.
 * @typedef {Object} FieldConfig
 * @property {FieldType} type
 * @property {String} key
 * @property {*} defaultValue
 * @property {Function} toJS
 * @property {Function} fromJS
 */
const fieldConfig = (type, key, defaultValue, toJS, fromJS) => ({
	type,
	key,
	defaultValue,
	toJS,
	fromJS
});

const userPref = (zimbraUserPref, defaultValue, toJS, fromJS) =>
	fieldConfig(FIELD_TYPES.USER_PREF, zimbraUserPref, defaultValue, toJS, fromJS);
const mailboxMetadata = (zimbraMailboxMetadata, defaultValue, toJS, fromJS) =>
	fieldConfig(FIELD_TYPES.MAILBOX_METADATA, zimbraMailboxMetadata, defaultValue, toJS, fromJS);
const whiteBlackList = listType => fieldConfig(FIELD_TYPES.WHITE_BLACK_LIST, listType, []);
const filterRules = () => fieldConfig(FIELD_TYPES.FILTER_RULES, 'filterRules', []);
const getAccountData = (data, defaultValue) =>
	fieldConfig(FIELD_TYPES.ACCOUNT_DATA, data, defaultValue);
const userAttribute = (data, defaultValue) =>
	fieldConfig(FIELD_TYPES.USER_ATTR, data, defaultValue);

const zimletPrefs = () =>
	fieldConfig(FIELD_TYPES.ZIMLET_PREFS, 'zimletPrefs', [], zimletList =>
		zimletList.sort((a, b) =>
			(a.label || a.name).localeCompare(b.label || b.name, 'en', { sensitivity: 'base' })
		)
	);

const desktopPrefLocal = (userPrefValue, defaultValue) =>
	fieldConfig(FIELD_TYPES.DESKTOP_PREF_LOCAL, userPrefValue, defaultValue);

export const VIEWING_EMAIL_SETTINGS = {
	id: 'viewingEmail',
	component: ViewingEmailSettings,
	fields: {
		messageListsEnableConversations: userPref(
			groupMailBy.name,
			groupMailBy.default,
			val => val === groupMailBy.values.conversation,
			val => (val ? groupMailBy.values.conversation : groupMailBy.values.message)
		),
		messageListsShowSnippets: userPref('zimbraPrefShowFragments', true),
		messageListsGroupByList: mailboxMetadata(
			groupByList.name,
			groupByList.values.none,
			val => val === groupByList.values.date,
			val => (val ? groupByList.values.date : groupByList.values.none)
		),
		// New preference, not supported in old client.
		multitasking: mailboxMetadata('zimbraPrefMultitasking', multitasking.default),
		previewPane: userPref('zimbraPrefReadingPaneLocation', 'right'),
		// New preference, not supported in old client.
		messageListDensity: mailboxMetadata('zimbraPrefMessageListDensity', 'regular'),
		markAsRead: userPref('zimbraPrefMarkMsgRead', 0, Number, Number),
		mailPollingInterval: userPref(
			'zimbraPrefMailPollingInterval',
			DEFAULT_INTERVAL,
			pollInterval => convertToSeconds(pollInterval) // This is done because by default, server returns time as '5m' (5 minutes) or '1d' and so on..
		),
		sendReadReceipt: userPref('zimbraPrefMailSendReadReceipts', SEND_READ_RECEIPT.values.prompt),
		afterMoveMessage: userPref('zimbraPrefMailSelectAfterDelete', 'adaptive'),
		enableDesktopNotifications: userPref('zimbraPrefMailToasterEnabled', true),
		mailVersion: userPref('zimbraPrefClientType', 'advanced'),
		zimbraPrefMailToasterEnabled: userPref('zimbraPrefMailToasterEnabled', true),
		zimbraPrefShowAllNewMailNotifications: userPref('zimbraPrefShowAllNewMailNotifications', true),
		zimbraPrefMailTrustedSenderList: userPref('zimbraPrefMailTrustedSenderList', null),
		showImages: userPref(
			'zimbraPrefDisplayExternalImages',
			'never',
			val => val.toString(),
			val => val === 'true'
		),
		zimbraPrefMessageViewHtmlPreferred: userPref('zimbraPrefMessageViewHtmlPreferred', 'true'),
		zimbraPrefCalendarAlwaysShowMiniCal: userPref('zimbraPrefCalendarAlwaysShowMiniCal', 'true'),
		zimbraPrefDisplayTimeInMailList: userPref('zimbraPrefDisplayTimeInMailList', false)
	}
};
export const WRITING_EMAIL_SETTINGS = {
	id: 'writingEmail',
	component: WritingEmailSettings,
	fields: {
		whenSendingMessageAddToContacts: userPref('zimbraPrefAutoAddAddressEnabled', true),
		whenSendingMessageGenerateLinkPreviews: mailboxMetadata('zimbraPrefGenerateLinkPreviews', true),
		undoSendEnabled: mailboxMetadata('zimbraPrefUndoSendEnabled', false),
		delegatedSendSaveTarget: userPref('zimbraPrefDelegatedSendSaveTarget', 'owner'),
		requestReadReceipt: userPref('zimbraPrefMailRequestReadReceipts', false),
		saveToSent: userPref('zimbraPrefSaveToSent', true),
		// FIXME: determine preference key
		defaultSendingAccount: mailboxMetadata(''),
		// Default values below are same as default values in tinymce composer
		defaultRichTextSize: userPref('zimbraPrefHtmlEditorDefaultFontSize', DEFAULT_FONT_SIZE),
		defaultRichTextFont: userPref('zimbraPrefHtmlEditorDefaultFontFamily', DEFAULT_FONT_FAMILY),
		defaultRichTextColor: userPref('zimbraPrefHtmlEditorDefaultFontColor', DEFAULT_FONT_COLOR),
		defaultComposeDirection: userPref('zimbraPrefComposeDirection', DEFAULT_COMPOSE_DIRECTION),
		powerPastePrefEnabled: userPref('zimbraPrefPowerPasteEnabled', true),
		zimbraPrefComposeFormat: userPref('zimbraPrefComposeFormat', HTML_MODE)
	}
};
export const BLOCKED_AND_ALLOWED_ADDRESSES_SETTINGS = {
	id: 'allowedAndBlocked',
	component: BlockedAndAllowed,
	fields: {
		whiteBlackList: whiteBlackList('whiteBlackList')
	}
};

export const GENERAL_SETTINGS = {
	id: 'general',
	component: GeneralSettings,
	fields: {
		zimbraMailQuota: userAttribute('zimbraMailQuota'),
		usedQuota: getAccountData('used'),
		clientType: userPref('zimbraPrefClientType', 'modern'),
		timeFormat: mailboxMetadata('zimbraPrefTimeFormat'),
		dateFormat: mailboxMetadata('zimbraPrefDateFormat'),
		defaultMailToClient: desktopPrefLocal('defaultMailtoClient', false),
		autoArchiveFrequency: desktopPrefLocal('autoArchiveFrequency')
	}
};

export const OFFLINE_SETTINGS =
	typeof process.env.ELECTRON_ENV !== 'undefined'
		? {
				id: 'offlineDesktop',
				component: OfflineDesktopSettings
		  }
		: {
				id: 'offlineMode',
				component: OfflineModeSettings,
				fields: {
					offlineBrowserKey: userPref('zimbraPrefWebClientOfflineBrowserKey', '')
				},
				afterSave: ({ localOfflineBrowserKey }) => {
					if (localOfflineBrowserKey) {
						localStorage.setItem(WEBCLIENT_OFFLINE_BROWSER_KEY, localOfflineBrowserKey);
					} else {
						localStorage.removeItem(WEBCLIENT_OFFLINE_BROWSER_KEY);
					}
				}
		  };

export const ACCOUNTS_SETTINGS = {
	id: 'accounts',
	component: AccountsSettings,
	fields: {
		mailForwardingAddress: userPref('zimbraPrefMailForwardingAddress'),
		mailLocalDeliveryDisabled: userPref(
			'zimbraPrefMailLocalDeliveryDisabled',
			false,
			val => !val,
			val => !val
		)
	}
};

export const SIGNATURES_SETTINGS = {
	id: 'signatures',
	component: SignaturesSettings
};

export const SHARING_SETTINGS = {
	id: 'sharing',
	component: SharingSettings,
	hide: ({ accountData }) => !get(accountData, 'attrs.zimbraFeatureSharingEnabled')
};

export const VACATION_RESPONSE_SETTINGS = {
	id: 'vacationResponse',
	component: VacationResponseSettings,
	fields: {
		enableOutOfOfficeReply: userPref('zimbraPrefOutOfOfficeReplyEnabled', false),

		enableOutOfOfficeExternalReply: userPref('zimbraPrefOutOfOfficeExternalReplyEnabled', false),

		defaultFromDate: userPref('zimbraPrefOutOfOfficeFromDate', new Date()),

		defaultUntilDate: userPref('zimbraPrefOutOfOfficeUntilDate'),

		enableOutOfOfficeAlertOnLogin: userPref('zimbraPrefOutOfOfficeStatusAlertOnLogin', false),

		outOfOfficeReply: userPref('zimbraPrefOutOfOfficeReply', ''),
		outOfOfficeExternalReply: userPref('zimbraPrefOutOfOfficeExternalReply', ''),
		outOfOfficeSuppressExternalReply: userPref('zimbraPrefOutOfOfficeSuppressExternalReply', false)
	},
	hide: ({ accountData }) => !get(accountData, 'attrs.zimbraFeatureOutOfOfficeReplyEnabled')
};

export const CALENDAR_AND_REMINDERS_SETTINGS = {
	id: 'calendarAndReminders',
	component: CalendarAndRemindersSettings,
	fields: {
		startOfWeek: userPref(
			'zimbraPrefCalendarFirstDayOfWeek',
			moment.weekdays(0),
			val => moment.weekdays(+val),
			val => getDayNumber(val)
		),
		timeOfDay: userPref(
			'zimbraPrefCalendarWorkingHours',
			'',
			val => soapTimeToJson(val),
			jsonTime => jsonTimeToSoap(jsonTime)
		),
		timeZone: userPref(
			'zimbraPrefTimeZoneId',
			'America/New_York',
			val => val.toString(),
			val => val
		),
		autoAddAppointmentsToCalendar: userPref('zimbraPrefCalendarAutoAddInvites', true),
		enableAppleIcalDelegation: userPref('zimbraPrefAppleIcalDelegationEnabled', false),
		showDeclinedEvents: userPref('zimbraPrefCalendarShowDeclinedMeetings', false),
		showEventStartEndTimezone: userPref('zimbraPrefUseTimeZoneListInCalendar', false),
		defaultCalendar: userPref(
			'zimbraPrefDefaultCalendarId',
			CALENDAR_IDS[CALENDAR_TYPE.own].DEFAULT
		),
		calendarReminderEmail: userPref('zimbraPrefCalendarReminderEmail', ''),
		zimbraPrefCalendarToasterEnabled: userPref('zimbraPrefCalendarToasterEnabled', true),
		zimbraPrefCalendarShowPastDueReminders: userPref(
			'zimbraPrefCalendarShowPastDueReminders',
			true
		),
		zimbraPrefCalendarApptReminderWarningTime: userPref(
			'zimbraPrefCalendarApptReminderWarningTime',
			5
		)
	},
	hide: ({ accountData }) => !get(accountData, 'attrs.zimbraFeatureCalendarEnabled')
};

export const FILTERS_SETTINGS = {
	id: 'filters',
	component: FiltersSettings,
	fields: {
		filters: filterRules()
	},
	hide: ({ accountData }) => !get(accountData, 'attrs.zimbraFeatureFiltersEnabled')
};

export const SMIME_AND_ENCRYPTION = {
	id: 'smimeAndEncryption',
	component: SMimeAndEncryption,
	fields: {
		zimbraPrefSMIMEDefaultSetting: mailboxMetadata(
			SMIMEDefaultSetting,
			SMIME_OPERATIONS.rememberSettings
		)
	},
	hide: ({ accountData }) => !isSMIMEFeatureAvailable(get(accountData, 'license'))
};

export const ACCOUNT_RECOVERY_SETTINGS = {
	id: 'accountRecovery',
	component: AccountRecoverySettings,
	hide: ({ matchesScreenXsMax, accountData }) => {
		const passwordResetDisabled =
			get(accountData, 'attrs.zimbraFeatureResetPasswordStatus') === 'disabled';

		return !matchesScreenXsMax || passwordResetDisabled;
	}
};

export const ZIMLETS_SETTINGS = {
	id: 'zimlets',
	component: ZimletsSettings,
	fields: {
		zimletPrefs: zimletPrefs()
	},
	hide: ({ accountData }) => !get(accountData, 'attrs.zimbraFeatureManageZimlets')
};

export const SETTINGS_CONFIG = [
	GENERAL_SETTINGS,
	VIEWING_EMAIL_SETTINGS,
	WRITING_EMAIL_SETTINGS,
	ACCOUNTS_SETTINGS,
	ACCOUNT_RECOVERY_SETTINGS,
	SHARING_SETTINGS,
	SIGNATURES_SETTINGS,
	VACATION_RESPONSE_SETTINGS,
	FILTERS_SETTINGS,
	BLOCKED_AND_ALLOWED_ADDRESSES_SETTINGS,
	OFFLINE_SETTINGS,
	CALENDAR_AND_REMINDERS_SETTINGS,
	SMIME_AND_ENCRYPTION,
	ZIMLETS_SETTINGS
];
