import { PureComponent } from 'preact/compat';
import { IntlProvider } from 'preact-i18n';
import { withProps } from 'recompose';
import { DEFAULT_LOCALE } from '../constants/locale';
import { connect } from 'react-redux';

/**
 * Wraps child components in `IntlProvider` which will provide intl definition to all child components.
 * locale files path passed along with import statement as parameters as path should be relative to zimlet.
 * @param {object} zimletLocale with following values
 * @param {Function} importFn which returns `import($ZIMLET_LOCALE_PATH)` to load the locale file from zimlet.
 * @param {Boolean} showLoader whether to show loader or not
 */

export default function withZimletIntlWrapper(zimletLocale) {
	const { importFn } = zimletLocale || {};

	// Split locale string (languageCode-countryCode) by '-' and concate it after converting countryCode to uppercase.
	const normalizeToZimbraLocale = locale => {
		const localeParts = locale.split('-');
		return (
			(localeParts[1] && localeParts[0].concat('_', localeParts[1].toUpperCase())) || localeParts[0]
		);
	};

	return function (Child) {
		@connect(({ locale }) => ({ selectedLocale: locale }))
		@withProps(({ selectedLocale }) => {
			// In case of zimlets, get selected locale from redux and use it for zimlet locale
			// We don't have to do any normalization stuff here as redux will always have correct locale value
			// If we don't have any supported locale then use default locale
			// Else make sure locale value is as expected: i.e country code is in uppercase - 'en_US'
			const locale = selectedLocale ? normalizeToZimbraLocale(selectedLocale) : DEFAULT_LOCALE;

			return {
				defaultLocale: DEFAULT_LOCALE,
				locale
			};
		})
		class ZimletIntlWrapper extends PureComponent {
			state = {
				definition: null,
				defaultDefinition: null,
				momentLocale: null,
				momentLocaleConfig: null
			};

			importLocale = locale =>
				importFn(locale)
					.then(({ default: definition }) => definition)
					.catch(err => {
						console.error(`Error loading translations for ${locale} from zimlet.`, err);
						return null;
					});

			loadLocale = ({ locale, defaultLocale }, loadDefaultLocale) => {
				const allPromises = [];

				// Load en_US locale which will be used as fallback strings,
				// when particular locale doesn't have all strings
				allPromises.push(loadDefaultLocale && this.importLocale(defaultLocale));

				// If passed locale is same as default locale then no need to do anything extra
				if (locale !== defaultLocale) {
					// Load locale, containing all translated strings
					allPromises.push(this.importLocale(locale));
				}

				Promise.all(allPromises)
					.then(([defaultDefinition, definition = null]) => {
						// Put definition object in state which will be passed to child component
						this.setState({
							definition,
							...(loadDefaultLocale && { defaultDefinition })
						});
					})
					.catch(err => {
						console.error(`Error loading translations for ${locale}`, err);
					});
			};

			componentDidMount() {
				this.loadLocale(this.props, true);
			}

			componentWillReceiveProps(nextProps) {
				const nextLocale = nextProps.locale,
					locale = this.props.locale;

				// check if the active locale has changed, then fetch & apply the corresponding locale data.
				if (nextLocale !== locale) {
					this.loadLocale(nextProps);
				}
			}

			render(props, { definition, defaultDefinition }) {
				// defaultDefinition is only set in state after loading all locale files
				return (
					defaultDefinition && (
						<IntlProvider definition={definition}>
							<IntlProvider definition={defaultDefinition}>
								<Child {...props} />
							</IntlProvider>
						</IntlProvider>
					)
				);
			}
		}

		return ZimletIntlWrapper;
	};
}
