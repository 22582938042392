import get from 'lodash-es/get';
import { Text } from 'preact-i18n';

export function isAlreadyExistsError(e) {
	return e && /exists/.test(e.message);
}

export function errorMessage(error) {
	return get(error, 'graphQLErrors.0.originalError.message') || error.message || error;
}

export function faultCode(error) {
	return get(error, 'graphQLErrors.0.originalError.faults.0.Detail.Error.Code');
}

export function faultContent(error) {
	return get(error, 'graphQLErrors.0.originalError.faults.0.Detail.Error');
}

export const notifyError = (err, notifyAction) => {
	const errorCode = faultCode(err);

	if (errorCode) {
		notifyAction({
			message: <Text id={`faults.${errorCode}`} />,
			failure: true
		});
	}
	console.error('Error', err);
};
