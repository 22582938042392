import { handleActions } from 'redux-actions';
import { DEFAULT_SORT } from '../../constants/search';
import * as actionCreators from './actions';
import update from 'immutability-helper';

const initialState = {
	selectedEMLs: [],
	sortBy: DEFAULT_SORT,
	query: ''
};

export default handleActions(
	{
		[actionCreators.clearSelectedEML]: state => update(state, { selectedEMLs: { $set: [] } }),

		[actionCreators.setSelectedEML]: (state, action) =>
			update(state, { selectedEMLs: { $push: [action.payload.item] } }),

		[actionCreators.removeSelectedEML]: (state, action) => {
			const { id } = action.payload;
			const itemIndex = state.selectedEMLs.findIndex(i => i.id === id);
			if (itemIndex !== -1) {
				return update(state, { selectedEMLs: { $splice: [[itemIndex, 1]] } });
			}
			return state;
		},

		[actionCreators.setQuery]: (state, action) =>
			update(state, { query: { $set: action.payload } }),

		[actionCreators.removeQuery]: state => update(state, { query: { $set: '' } }),

		[actionCreators.setMsgListSortBy]: (state, action) =>
			update(state, { sortBy: { $set: action.payload } })
	},
	initialState
);
