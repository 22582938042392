import { Component, Fragment } from 'preact';
import { branch, renderComponent } from 'recompose';
import cloneDeep from 'lodash-es/cloneDeep';
import cx from 'classnames';
import queryString from 'query-string';
import { Text } from 'preact-i18n';
import clientConfiguration from '../../enhancers/client-config';
import appConfiguration from '../../enhancers/app-config';
import Routes from '../../routes';
import ZimletLoader from '../zimlet-loader';
import ZimletSlot from '../zimlet-slot';
import { clearOfflineData, isOfflineModeEnabled } from '../../utils/offline';
import {
	getEmail,
	getBasePath,
	updateDesktopBadgeCount,
	redirectToLoginURL,
	getValueFromLocalizeJSCode
} from '../../lib/util';
import { callWith } from '@zimbra/util/src/call-with';
import newMessageDraft from '../../utils/new-message-draft';
import { getNewMailUrl } from '../../utils/mail-item';
import ExternalHeader from '../external-header';
import Header from '../header';
import SettingsModal from '../settings-modal';
import KeyboardShortcutsModal from '../keyboard-shortcuts-modal';
import AttachmentViewer from '../attachment-viewer';
import NotificationModal from '../notification-modal';
import { connect } from 'react-redux';
import * as urlActionCreators from '../../store/url/actions';
import * as emailActionCreators from '../../store/email/actions';
import { notify } from '../../store/notifications/actions';
import {
	logoutStatus,
	offlineConnectionStatus,
	setLoginVisible
} from '../../store/network/actions';
import { setPreviewAttachment } from '../../store/attachment-preview/actions';
import { addZimletLocalization } from '../../store/zimlets/actions';
import { setAuthTokenExpired } from '../../store/active-account/actions';
import { getSelectedAttachmentPreview } from '../../store/attachment-preview/selectors';
import { getRouteProps } from '../../store/url/selectors';
import style from './style';
import withClientInfo from '../../graphql-decorators/with-client-info';
import withAccountInfo from '../../graphql-decorators/account-info';
import withPreference from '../../graphql-decorators/preferences/get-preferences';
import Reminders from '../reminders';
import FaviconWithBadge from '../favicon-with-badge';
import AboutModal from './../about-modal';
import OfflineDataLoader from '../offline-data-loader/index';
import withIntlWrapper from '../../enhancers/intl-wrapper';
import withTemplate from '../../enhancers/with-template';
import { AppShell, Loader } from '../app-shell-loader';
import { BottomSideAdSlots } from '../ad-slots';
import withMediaQuery from '../../enhancers/with-media-query';
import { maxWidth, screenSmMax } from '../../constants/breakpoints';
import { isValidServerConfChecksum } from '../../utils/desktop-prefs';
import Login from '../login';
import Notifications from '../notifications';
import ServerUrlDialog from '../login/server-url-dialog';
import { fetchUserAgentName } from '../../utils/user-agent';
import { rendererIpc } from '@zimbra/electron-app';
import get from 'lodash-es/get';
import LicenseDialog from './license-dialog';
import { isLicenseActive } from '../../utils/license';
import { getMailPollingIntervalLongPolling } from '../../utils/mail-polling';
import PstImport from '../pst-import';
import DesktopSync from '../desktop-sync';
import DiskSpaceWarning from '../desktop-sync/space-warning';
import PollingRequests from '../polling-requests';
import NewMailNotification from '../new-mail-notification';
import withDataSources from '../../graphql-decorators/get-datasource';
import withIdentities from '../../graphql-decorators/get-identities';
import { getMailboxMetadata } from '../../graphql-decorators/mailbox-metadata';
import ClientConfig, { ClientConfigProvider, DEFAULT_CLIENT } from '../../context/client-config';
import withTabManager from '../../enhancers/tab-manager';
import DefaultMailAppDialog from '../default-mail-app-dialog';
import { NoOpPollingManager } from './no-op-polling-manager';
import TabNavigation from '../app-navigation/tab-navigation';
import { updateLastVisitedTabThunk } from '../../utils/thunk';
import { initializeVertical } from '../../store/navigation/actions';
import withTracking from '../../enhancers/tracking';
import { AddShortcuts } from './app-add-shortcut';
import { faultCode } from '../../utils/errors';
import { AppBeforeUnload } from '../app-before-unload';
import { setDiskSpaceWarning } from '../../store/sync/actions';

const refetchQueries = ({ refetchAccount, refetchPreferences }) => {
	refetchAccount();
	refetchPreferences();
};

// CLIENT !== DEFAULT_CLIENT - this is to generate CLIENT specific build for mobile or desktop
// window.location.hostname - we have to create client as per hostname
// i.e. a.com, www.a.com, b.a.com, a.co.in, b.a.co.in, etc.
const derivedClient = CLIENT !== DEFAULT_CLIENT ? CLIENT : window.location.hostname;

const withClientConfig = () => {
	return function (Child) {
		return function ClientConfigWrapper(childProps) {
			const domain = get(childProps, 'domainName');
			const zimbraBrandingFolderName = get(childProps, 'zimbraBrandingFolderName');

			return (
				<ClientConfigProvider
					derivedClient={derivedClient}
					domain={zimbraBrandingFolderName || domain}
				>
					<Child {...childProps} />
				</ClientConfigProvider>
			);
		};
	};
};

const LoginContainer = ({
	refetchAccount,
	zimbraTwoFactorAuthEnabled,
	refetchPreferences,
	showResetPassword,
	zimbraFeatureResetPasswordStatus,
	username
}) => {
	return (
		<AppShell>
			<Login
				{...(typeof process.env.ELECTRON_ENV !== 'undefined' && {
					username
				})}
				zimbraFeatureResetPasswordStatus={zimbraFeatureResetPasswordStatus}
				twoFactorEnabled={zimbraTwoFactorAuthEnabled}
				onLogin={callWith(refetchQueries, { refetchAccount, refetchPreferences })}
				showResetPassword={showResetPassword}
			/>
			<Notifications />
		</AppShell>
	);
};

const ServerUrlContainer = () => (
	<AppShell>
		<ServerUrlDialog />
		<Notifications />
	</AppShell>
);

@withTracking()
// ZIMBRA_DOMAIN is been deprecated will be removed in future keeping it here for backward compatibility
@appConfiguration({ hostName: 'ZIMBRA_DOMAIN' })
@connect(
	state => ({
		attachment: getSelectedAttachmentPreview(state),
		showSettings: state.settings.visible,
		showAbout: state.about.visible,
		zimletModals: state.zimlets.modals,
		routeProps: getRouteProps(state),
		isOffline: state.network.isOffline,
		isLoginVisible: state.network.isLoginVisible,
		authTokenExpired: state.activeAccount.authTokenExpired
	}),
	{
		...urlActionCreators,
		...emailActionCreators,
		closeAttachmentPreview: setPreviewAttachment,
		notify,
		setAuthTokenExpired,
		logoutStatus,
		offlineConnectionStatus,
		updateLastVisitedTab: updateLastVisitedTabThunk,
		initializeVertical,
		setLoginVisible,
		addZimletLocalization,
		setSpaceWarning: setDiskSpaceWarning
	}
)
@withAccountInfo(
	({ data: { accountInfo, loading, refetch, error } }) => {
		const name = get(accountInfo, 'name');
		const domainName = name ? name.split('@')[1] : undefined;

		const returnProps = {
			accountLoading: loading,
			refetchAccount: refetch,
			...(error && {
				accountInfoError: error
			})
		};

		const returnOfflineProps = {
			...returnProps,
			name,
			domainName,
			account: accountInfo,
			version: get(accountInfo, 'version'),
			accountId: get(accountInfo, 'id'),
			accountDisplayName: get(accountInfo, 'attrs.displayName'),
			zimbraFeatureViewInHtmlEnabled: get(accountInfo, 'attrs.zimbraFeatureViewInHtmlEnabled'),
			zimbraFeatureDocumentEditingEnabled: accountInfo?.attrs?.zimbraFeatureDocumentEditingEnabled,
			zimbraTwoFactorAuthEnabled: get(accountInfo, 'attrs.zimbraTwoFactorAuthEnabled'),
			zimbraFeatureGalEnabled: get(accountInfo, 'attrs.zimbraFeatureGalEnabled'),
			zimbraFeatureCalendarEnabled: get(accountInfo, 'attrs.zimbraFeatureCalendarEnabled'),
			zimbraFeatureTaggingEnabled: get(accountInfo, 'attrs.zimbraFeatureTaggingEnabled'),
			zimbraFeatureSharingEnabled: get(accountInfo, 'attrs.zimbraFeatureSharingEnabled'),
			zimbraFeatureBriefcasesEnabled: get(accountInfo, 'attrs.zimbraFeatureBriefcasesEnabled'),
			zimbraBrandingFolderName: get(accountInfo, 'attrs.zimbraBrandingFolderName'),
			accountZimlets: get(accountInfo, 'zimlets.zimlet') || [],
			zimbraXLicense: isLicenseActive(get(accountInfo, 'license'))
		};

		if (error) {
			return typeof process.env.ELECTRON_ENV !== 'undefined' ? returnOfflineProps : returnProps;
		}

		return returnOfflineProps;
	},
	{
		options: {
			fetchPolicy: 'cache-and-network'
		}
	}
)
@withPreference(
	({ data: { getPreferences, refetch, loading } }) => ({
		refetchPreferences: refetch,
		zimbraPrefLocale: get(getPreferences, 'zimbraPrefLocale'),
		mailPollingInterval: getMailPollingIntervalLongPolling(
			get(getPreferences, 'zimbraPrefMailPollingInterval')
		),
		zimbraPrefCalendarToasterEnabled: get(getPreferences, 'zimbraPrefCalendarToasterEnabled'),
		zimbraPrefCalendarFirstDayOfWeek: get(getPreferences, 'zimbraPrefCalendarFirstDayOfWeek'),
		zimbraPrefCalendarShowPastDueReminders: get(
			getPreferences,
			'zimbraPrefCalendarShowPastDueReminders'
		),
		zimbraPrefMailToasterEnabled: get(getPreferences, 'zimbraPrefMailToasterEnabled'),
		offlineModeEnabled: isOfflineModeEnabled(
			get(getPreferences, 'zimbraPrefWebClientOfflineBrowserKey')
		),
		prefLoading: loading
	}),
	{
		options: {
			fetchPolicy: 'cache-and-network'
		}
	}
)
@getMailboxMetadata(
	{
		skip: ({ name }) => !name,
		options: {
			fetchPolicy: 'cache-and-network'
		}
	},
	({ getMailboxMetadata: mailboxMetadata }) => ({
		zimbraPrefTimeFormat: get(mailboxMetadata, 'meta.0._attrs.zimbraPrefTimeFormat'),
		zimbraPrefDateFormat: get(mailboxMetadata, 'meta.0._attrs.zimbraPrefDateFormat')
	})
)
@branch(
	({ prefLoading, zimbraPrefCalendarToasterEnabled }) =>
		prefLoading && zimbraPrefCalendarToasterEnabled === undefined,
	renderComponent(Loader)
)
@withIntlWrapper()
@withTemplate()
@branch(
	({ zimbraOrigin }) =>
		typeof process.env.ELECTRON_ENV !== 'undefined' &&
		(!zimbraOrigin ||
			zimbraOrigin.indexOf('/') === 0 ||
			!isValidServerConfChecksum({ zimbraOrigin })),
	renderComponent(withClientConfig()(ServerUrlContainer))
)
@withClientInfo(({ data }) => {
	const clientInfoLoading = get(data, 'loading');
	return {
		clientInfoLoading,
		...(!clientInfoLoading && {
			zimbraFeatureResetPasswordStatus: get(
				data,
				'clientInfo.attributes.zimbraFeatureResetPasswordStatus'
			),
			zimbraWebClientLoginURL: get(data, 'clientInfo.attributes.zimbraWebClientLoginURL')
		})
	};
})
@branch(
	({
		name,
		accountLoading,
		clientInfoLoading,
		prefLoading,
		zimbraPrefCalendarToasterEnabled,
		zimbraWebClientLoginURL
	}) =>
		(clientInfoLoading && zimbraWebClientLoginURL === undefined) ||
		(!name && accountLoading) ||
		(prefLoading && zimbraPrefCalendarToasterEnabled === undefined),
	renderComponent(Loader)
)
@branch(
	({ name, accountLoading, clientInfoLoading, isLoginVisible }) =>
		isLoginVisible || (!name && !accountLoading && !clientInfoLoading),
	branch(
		({ zimbraWebClientLoginURL }) => zimbraWebClientLoginURL,
		renderComponent(({ zimbraWebClientLoginURL }) => {
			redirectToLoginURL(zimbraWebClientLoginURL);
		}),
		renderComponent(
			withClientConfig()(
				({
					refetchAccount,
					accountInfoError,
					zimbraTwoFactorAuthEnabled,
					refetchPreferences,
					zimbraFeatureResetPasswordStatus,
					name: username
				}) => {
					const basePath = getBasePath();
					if (basePath && basePath.length > 0 && typeof window.cordova === 'undefined') {
						redirectToLoginURL();
					} else {
						const errorCode = faultCode(accountInfoError);
						return (
							<LoginContainer
								username={username}
								zimbraFeatureResetPasswordStatus={zimbraFeatureResetPasswordStatus}
								refetchAccount={refetchAccount}
								zimbraTwoFactorAuthEnabled={zimbraTwoFactorAuthEnabled}
								refetchPreferences={refetchPreferences}
								showResetPassword={errorCode === 'account.RESET_PASSWORD'}
							/>
						);
					}
				}
			)
		)
	)
)
@withClientConfig()
@clientConfiguration('nav, preauthRedirect.preauthCos, preauthRedirect.redirectURL, routes.slugs')
@branch(
	({ preauthCos, redirectURL, account, accountLoading, accountInfoError }) =>
		preauthCos &&
		redirectURL &&
		!accountLoading &&
		!accountInfoError &&
		get(account, 'cos.name') === preauthCos,
	renderComponent(({ redirectURL }) => {
		window.location.href = redirectURL;
	})
)
// Check if valid license is present if not render No valid license dialog
@branch(
	({ zimbraXLicense, accountLoading }) => !zimbraXLicense && !accountLoading,
	renderComponent(withClientConfig()(LicenseDialog))
)
@withIdentities(() => {}, {
	options: {
		fetchPolicy: 'cache-and-network'
	}
})
@withDataSources(() => {}, {
	options: {
		fetchPolicy: 'cache-and-network'
	}
})
@withMediaQuery(maxWidth(screenSmMax), 'matchesScreenMd')
@withTabManager()
export default class App extends Component {
	state = {
		showLoader: false // shows the loader while the migrations are running in the desktop app
	};
	routeChanged = e => {
		const { attachment, closeAttachmentPreview, setUrl, updateLastVisitedTab, tracker, slugs } =
			this.props;
		const { matches, path, url, ...routeProps } = e.current.props;

		if (attachment) {
			// Remove any preview attachments when changing routes.
			closeAttachmentPreview();
		}

		tracker.setPageView(e.url);

		setUrl({ url: e.url, routeProps, slugs });
		updateLastVisitedTab({
			url: e.url
		});
	};

	handleGlobalClick = e => {
		let { target } = e;
		do {
			if (
				String(target.nodeName).toUpperCase() === 'A' &&
				target.href &&
				target.href.match(/^mailto:/) &&
				!target.getAttribute('data-mce-href')
			) {
				let [, address, query] = target.href.match(/^mailto:\s*([^?]*)\s*(\?.*)?/i);
				const params = query ? queryString.parse(query) : {};
				address = decodeURIComponent(address) || params.to;

				params.to = [{ address, email: getEmail(address) }];
				this.openComposer(params);
				e.preventDefault();
				return false;
			}
		} while ((target = target.parentNode));
	};

	openComposer(params) {
		const message = Object.assign(newMessageDraft(), params);
		this.props.handleNewTab({
			url: getNewMailUrl(this.props.slugs.email, this.props.matchesScreenMd),
			tabItem: message,
			type: 'message',
			view: this.props.slugs.email
		});
	}

	handleGlobalKeyDown = e => {
		this.context.shortcutCommandHandler.handleKeyDown({ e });
	};

	handleGlobalKeyUp = e => {
		this.context.shortcutCommandHandler.handleKeyUp({ e });
	};

	/**
	 * Purges the persisted cache, resets the store if specified, clears otherwise.
	 * Reset would, clear the store and refetch all queries
	 * Clear would, only clear the store
	 *
	 * @param {Boolean} resetStore if specified, resets the store instead of clearing
	 * @returns {Promise}
	 * @memberof App
	 */
	resetCache = (resetStore = false) => {
		if (!this.props.offlineModeEnabled && this.context) {
			let promise;

			if (resetStore) {
				promise = this.context.client.resetStore();
			} else {
				promise = this.context.client.clearStore();
			}

			return promise;
		}

		return Promise.resolve();
	};

	localizeZimlets = (zimletsData, addLocalization, locale, zimbraOrigin) => {
		const zimletsName = zimletsData
			.filter(zimlet => zimlet.zimlet[0].zimbraXZimletCompatibleSemVer !== null)
			.map(zimlet => zimlet.zimlet[0].name);

		let urlZimlets = `/service/res/${zimletsName.join(',')}.js?locid=${locale}`;

		if (/^\/[^/]/.test(urlZimlets)) {
			urlZimlets = zimbraOrigin + urlZimlets;
		}

		fetch(urlZimlets)
			.then(r => {
				if (r.ok) {
					return r.text();
				}
			})
			.then(code => {
				const zimletsCode = code?.split('delete a;').filter(str => str.length > 0) || [];

				zimletsCode.forEach(zimletCode => {
					const zimletName = /a=window\['(.*)']/.exec(zimletCode)?.[1];
					if (zimletName) {
						addLocalization({
							name: zimletName,
							label: getValueFromLocalizeJSCode(zimletCode, 'label'),
							description: getValueFromLocalizeJSCode(zimletCode, 'description')
						});
					}
				});
			});
	};

	beforeUnloadHandler = () => this.resetCache();

	/**
	 * Initializes and shows/hides the top verticals based on whether there are any zimlets registered for those verticals.
	 */
	initializeVerticals = (props = this.props) => {
		const {
			zimbraFeatureCalendarEnabled,
			zimbraFeatureBriefcasesEnabled,
			slugs,
			initializeVertical: initVerticalData,
			updateLastVisitedTab
		} = props;

		const { zimlets } = this.context;

		const isVideoActive = zimlets.isSlotActive(`slot::${slugs.videoapps}-tab-item`);
		const isChatActive = zimlets.isSlotActive(`slot::${slugs.chatapps}-tab-item`);
		const isCloudStorageActive = zimlets.isSlotActive(`slot::${slugs.cloudapps}-tab-item`);
		const isIntegrationsActive =
			zimlets.isSlotActive('slot::menu') ||
			zimlets.isSlotActive(`slot::${slugs.integrations}-tab-item`);

		initVerticalData({
			zimbraFeatureCalendarEnabled,
			zimbraFeatureBriefcasesEnabled,
			slugs,
			isVideoActive,
			isChatActive,
			isCloudStorageActive,
			isIntegrationsActive
		});

		/**
		 * After initializing vertical data, set current url as last visited tab also
		 * This is required because route-changed event gets called first which will try to set lastVisitedTab
		 * but if we are loading a vertical which is coming from zimlet then at that time vertical data is not available
		 * it is made available only after zimlet initialization gets completed, at that time we need to set lastVisitedTab here
		 */
		updateLastVisitedTab({
			url: window.location.pathname
		});
	};

	initializeDesktopApp = () => {
		// Initial setup for desktop app. i.e. Create Account folder, initialize IPC and create DB connection
		const { name, isOffline, version } = this.props;
		rendererIpc.initializeDesktopAppConfig({ accountName: name, isOffline, version }).then(() => {
			this.setState({
				showLoader: false
			});
		});
	};

	componentWillMount() {
		this.initializeVerticals();

		if (typeof process.env.ELECTRON_ENV !== 'undefined') {
			// show loader while creating the connection is created as it runs the migration as well
			this.setState({
				showLoader: true
			});

			this.initializeDesktopApp();
		}
	}

	componentDidMount() {
		addEventListener('click', this.handleGlobalClick);
		addEventListener('keydown', this.handleGlobalKeyDown);
		addEventListener('keyup', this.handleGlobalKeyUp);

		const {
			account,
			version,
			notify: notifyAction,
			setAccount,
			setAuthTokenExpired: setAuthExpired,
			logoutStatus: setLogoutStatus,
			offlineConnectionStatus: setOfflineStatus,
			offlineModeEnabled,
			tracker,
			accountId,
			name,
			accountDisplayName,
			zimbraWebClientLoginURL,
			addZimletLocalization: addLocalization,
			locale,
			zimbraOrigin,
			setSpaceWarning
		} = this.props;
		account && setAccount(cloneDeep(account));
		this.context.zimbraBatchClient.setUserAgent({
			name: fetchUserAgentName(),
			version: version.split(' ')[0]
		});

		// start processing the notifications only when app component is mounted
		// reason being, there could be queries (mainly accountInfo) which are getting
		// updated in notifications, and if those queries are not yet present in the cache,
		// it would be a cache miss and sometimes the app displayed the login page
		// Added to fix PREAPPS-5455
		this.context.zimbraNotifications.startProcessing();

		// disable the persister if offline is not enabled
		// bind an even on window which would reset the cache every time when the app is refreshed
		if (!offlineModeEnabled) {
			addEventListener('beforeunload', this.beforeUnloadHandler);

			try {
				this.context.persistCache.remove();
				this.context.persistCache.purge();
			} catch (error) {
				console.warn('Could not remove Apollo cache. Are you in private browsing mode?', error);
			}
		}

		// Set user id in datalayer
		tracker.setAccountData({
			'user-id': accountId,
			username: accountDisplayName,
			email: name
		});

		this.context.zimbraErrorLink.registerHandler(({ errorCode, message, path = [] }) => {
			if (/Failed to fetch/.test(message)) {
				if (typeof process.env.ELECTRON_ENV === 'undefined') {
					notifyAction({
						message: <Text id="faults.network.offlineError" />,
						failure: true
					});
				}
				return;
			}

			// Whenever request fails with a network error, display a network error, such as 502 gateway timeout
			if (/Network request failed/.test(message) && path[0] !== 'noop') {
				notifyAction({
					message: <Text id="faults.network.networkError" />,
					failure: true
				});
				return;
			}

			switch (errorCode) {
				case 'service.AUTH_EXPIRED':
				case 'service.AUTH_REQUIRED':
					setAuthExpired(true);
					if (typeof process.env.ELECTRON_ENV !== 'undefined') {
						updateDesktopBadgeCount(0);
						setLogoutStatus(true);
						setOfflineStatus(true);
					} else {
						clearOfflineData(this.context);

						// Show error to user
						notifyAction({
							message: <Text id="faults.account.AUTH_EXPIRED" />,
							duration: 86400, // Increase duration of notification to make it persistant
							failure: true,
							action: {
								label: <Text id="buttons.signin" />,
								fn: () => {
									const basePath = getBasePath();
									// TODO: this needs to be fixed to be a generic one, currently its a patch to route to root
									// for zimbrax app when basePath value is set
									if (basePath && basePath.length > 0 && typeof window.cordova === 'undefined') {
										redirectToLoginURL(zimbraWebClientLoginURL);
									} else {
										window.location.reload();
									}
								}
							}
						});
					}
					break;
				case 'service.PERM_DENIED':
					notifyAction({
						message: <Text id="faults.account.PERM_DENIED" />,
						failure: true
					});
					break;
			}
		});
		this.context.zimlets.on('zimlets-initialized', this.initializeVerticals);

		this.localizeZimlets(account.zimlets.zimlet, addLocalization, locale, zimbraOrigin);
		if (typeof process.env.ELECTRON_ENV !== 'undefined') {
			const warningDiskSize = 200000000; //200MB
			rendererIpc
				.handleDiskSpaceWarning()
				.then(diskSpace => {
					if (diskSpace?.free < warningDiskSize) {
						setSpaceWarning(true);
					}
				})
				// eslint-disable-next-line no-console
				.catch(e => console.log('Error to calculate free disk space: ', e));
		}
	}

	componentWillReceiveProps(nextProps) {
		const {
			isOffline,
			offlineModeEnabled,
			zimbraFeatureCalendarEnabled,
			zimbraFeatureBriefcasesEnabled,
			matchesScreenMd
		} = nextProps;

		const {
			refetchAccount,
			isOffline: prevOffline,
			offlineModeEnabled: prevOfflineModeEnabled,
			matchesScreenMd: prevMatchesScreenMd,
			zimbraFeatureCalendarEnabled: prevZimbraFeatureCalendarEnabled,
			zimbraFeatureBriefcasesEnabled: prevZimbraFeatureBriefcasesEnabled
		} = this.props;

		const isDesktopApp = typeof process.env.ELECTRON_ENV !== 'undefined';

		// When user comes online after going offline
		if (prevOffline !== isOffline && isDesktopApp) {
			if (!isOffline) {
				// Refetch anything critical for the App, such as accountInfo
				refetchAccount({
					fetchPolicy: 'network-only'
				});
			}

			rendererIpc.updateNetworkStatus(isOffline);
		}

		// disable the persister if offline is not enabled
		if (!offlineModeEnabled && offlineModeEnabled !== prevOfflineModeEnabled) {
			try {
				this.context.persistCache.remove();
				this.context.persistCache.purge();
			} catch (error) {
				console.warn('Could not remove Apollo cache. Are you in private browsing mode?', error);
			}
		}

		if (
			matchesScreenMd !== prevMatchesScreenMd ||
			zimbraFeatureCalendarEnabled !== prevZimbraFeatureCalendarEnabled ||
			zimbraFeatureBriefcasesEnabled !== prevZimbraFeatureBriefcasesEnabled
		) {
			this.initializeVerticals(nextProps);
		}
	}

	componentWillUnmount() {
		removeEventListener('click', this.handleGlobalClick);
		removeEventListener('keydown', this.handleGlobalKeyDown);
		removeEventListener('keyup', this.handleGlobalKeyUp);
		removeEventListener('beforeunload', this.beforeUnloadHandler);
		this.context.zimbraErrorLink.unRegisterAllHandlers();
		this.context.zimlets.off('zimlets-initialized', this.initializeVerticals);
	}

	render(
		{
			nav,
			showSettings,
			showAbout,
			zimletModals,
			routeProps,
			matchesScreenMd,
			version,
			zimbraFeatureViewInHtmlEnabled,
			zimbraFeatureDocumentEditingEnabled,
			zimbraFeatureGalEnabled,
			accountZimlets,
			zimbraPrefLocale,
			refetchAccount,
			zimbraPrefCalendarToasterEnabled,
			zimbraPrefCalendarShowPastDueReminders,
			zimbraPrefMailToasterEnabled,
			zimbraFeatureCalendarEnabled,
			zimbraFeatureTaggingEnabled,
			zimbraFeatureSharingEnabled,
			zimbraFeatureBriefcasesEnabled,
			slugs,
			handleNewTab,
			isOffline,
			mailPollingInterval,
			account
		},
		{ showLoader }
	) {
		return showLoader ? (
			<Loader />
		) : routeProps.hideHeader ? (
			<div>
				<FaviconWithBadge />
				<Routes
					onChange={this.routeChanged}
					zimbraFeatureCalendarEnabled={zimbraFeatureCalendarEnabled}
					zimbraFeatureTaggingEnabled={zimbraFeatureTaggingEnabled}
					zimbraFeatureBriefcasesEnabled={zimbraFeatureBriefcasesEnabled}
				/>
			</div>
		) : (
			<AppShell>
				<AddShortcuts
					slugs={slugs}
					zimbraFeatureCalendarEnabled={zimbraFeatureCalendarEnabled}
					zimbraFeatureBriefcasesEnabled={zimbraFeatureBriefcasesEnabled}
					matchesScreenMd={matchesScreenMd}
					handleNewTab={handleNewTab}
				/>
				<NoOpPollingManager isOffline={isOffline} mailPollingInterval={mailPollingInterval} />
				<ZimletLoader accountZimlets={accountZimlets} />
				<ZimletSlot name="app" />
				<AppBeforeUnload accountName={account?.name} />

				{showSettings && (
					<Fragment>
						<div id="settings-modal-wrapper" />
						<SettingsModal into={'#settings-modal-wrapper'} />
					</Fragment>
				)}
				{showAbout && <AboutModal version={version} />}
				{Object.keys(zimletModals).map(id => zimletModals[id])}
				<KeyboardShortcutsModal />
				<AttachmentViewer
					zimbraFeatureViewInHtmlEnabled={zimbraFeatureViewInHtmlEnabled}
					zimbraFeatureDocumentEditingEnabled={zimbraFeatureDocumentEditingEnabled}
				/>
				<OfflineDataLoader />

				{nav && <ExternalHeader className={style.hideSmDown} />}
				<Header
					className={style.hideSmDown}
					zimbraFeatureGalEnabled={zimbraFeatureGalEnabled}
					zimbraPrefLocale={zimbraPrefLocale}
					refetchAccount={refetchAccount}
					zimbraFeatureTaggingEnabled={zimbraFeatureTaggingEnabled}
					zimbraFeatureSharingEnabled={zimbraFeatureSharingEnabled}
				/>
				<TabNavigation />
				{typeof process.env.ELECTRON_ENV !== 'undefined' && [
					<DiskSpaceWarning />,
					<PstImport />,
					<DesktopSync />
				]}
				<Notifications />
				<NotificationModal />
				<PollingRequests />
				{zimbraPrefMailToasterEnabled && (
					<ClientConfig.Consumer>
						{({ getAssetURLByClient }) => (
							<NewMailNotification getAssetURLByClient={getAssetURLByClient} slugs={slugs} />
						)}
					</ClientConfig.Consumer>
				)}
				{zimbraFeatureCalendarEnabled && (
					<ClientConfig.Consumer>
						{({ getAssetURLByClient }) => (
							<Fragment>
								<div id="reminder_wrapper" />
								<Reminders
									getAssetURLByClient={getAssetURLByClient}
									zimbraPrefCalendarToasterEnabled={zimbraPrefCalendarToasterEnabled}
									zimbraPrefCalendarShowPastDueReminders={zimbraPrefCalendarShowPastDueReminders}
									handleNewTab={handleNewTab}
									into={'#reminder_wrapper'}
								/>
							</Fragment>
						)}
					</ClientConfig.Consumer>
				)}

				{/* to fix portal issue placing side bar here. */}
				<div id="sidebar_portal" />

				<main class={cx(style.main, !nav && style.noExternalHeader)}>
					<FaviconWithBadge />
					<Routes
						onChange={this.routeChanged}
						zimbraFeatureCalendarEnabled={zimbraFeatureCalendarEnabled}
						zimbraFeatureTaggingEnabled={zimbraFeatureTaggingEnabled}
						zimbraFeatureBriefcasesEnabled={zimbraFeatureBriefcasesEnabled}
					/>
				</main>
				{matchesScreenMd && <BottomSideAdSlots />}
				{/* @TODO: Remove check for windows after fix is found for setting ZimbraX as default mailto app in windows */}
				{typeof process.env.ELECTRON_ENV !== 'undefined' && process.platform !== 'win32' && (
					<DefaultMailAppDialog />
				)}
			</AppShell>
		);
	}
}
