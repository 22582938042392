import withModalKeydownEnhancer from '../enhancers/modal-keydown-enhancer';
import withModalManager from '../enhancers/modal-enhancer';
import ClickOutsideDetector from './click-outside-detector';
import { useMemo } from 'preact/hooks';

/**
 * Based on props, identify wrapper component from following two.
 * ClickOutsideDetector - Click handler when clicked outside modal/component. Disable when `disableOutsideClick` is true.
 * ClickOutsideDetectorWithKeyDown - Attach Keydown listner when clicked `ESC` key wrapped around ClickOutsideDetector.
 */
export default function CloseOnClickOrKeydown({
	disableEscape = false,
	disableOutsideClick = false,
	onEscKeydown,
	onClickOutside,
	children
}) {
	const callbackMethod = onEscKeydown || onClickOutside;

	const skipHandlerAttaachment = useMemo(
		() => !callbackMethod && disableOutsideClick && disableEscape,
		[callbackMethod, disableEscape, disableOutsideClick]
	);

	const WrapperComponent = useMemo(() => {
		if (skipHandlerAttaachment) {
			return null;
		}

		// wrap `withModalKeydownEnhancer` only if ESC key is allow to close modal
		const ComponentWithKeyListner = disableEscape
			? ClickOutsideDetector
			: withModalKeydownEnhancer(ClickOutsideDetector);

		// wrap `withModalManager` only if outsideClick and ESC keydown is allowed.
		return withModalManager('onEscKeydown', disableOutsideClick)(ComponentWithKeyListner);
	}, [disableEscape, disableOutsideClick, skipHandlerAttaachment]);

	return skipHandlerAttaachment ? (
		children
	) : (
		<WrapperComponent
			onEscKeydown={callbackMethod}
			disableOutsideClick={disableOutsideClick}
			onClickOutside={onClickOutside}
			children={children}
		/>
	);
}
