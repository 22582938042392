import { Fragment } from 'preact';
import { useCallback } from 'preact/hooks';
import { Text } from 'preact-i18n';
import { useDispatch, useSelector } from 'react-redux';
import { ChoiceInput, Button } from '@zimbra/blocks';
import get from 'lodash-es/get';
import { setAdvanceOptions } from '../../../store/search/actions';
import style from '../style';
import { TEXT_CALENDAR, OTHER_ATTACHMENTS_TYPES, ATTACHMENT_TYPES } from '../constants';

const AttachmentItem = ({ option, attachmentTypes }) => {
	const dispatch = useDispatch();
	const checked = (attachmentTypes || []).indexOf(option) !== -1;

	const toggleAttachmentType = useCallback(
		({ target: { value } }) => {
			const arr = attachmentTypes;
			const index = arr.indexOf(value);

			if (index !== -1) {
				arr.splice(index, 1);
			} else {
				arr.push(value);
			}

			dispatch(setAdvanceOptions({ attachmentTypes: [...arr] }));
		},
		[attachmentTypes, dispatch]
	);

	return (
		<div class={style.attachmentType}>
			<label>
				<ChoiceInput onChange={toggleAttachmentType} value={option} checked={checked} />
				<Text id={`search.advanced.attachment.${option}`} />
			</label>
		</div>
	);
};

const AttachmentList = ({ list, attachmentTypes }) => (
	<div class={style.rowWrapper}>
		{list.map(type => (
			<AttachmentItem key={type} option={type} attachmentTypes={attachmentTypes} />
		))}
	</div>
);

export const Attachments = () => {
	const dispatch = useDispatch();
	const { attachmentTypes, showMoreTypes } = useSelector(state => get(state, 'search'));

	const toggleMoreTypes = useCallback(() => {
		const updatedTypes = showMoreTypes
			? attachmentTypes.filter(type => !OTHER_ATTACHMENTS_TYPES.includes(type))
			: attachmentTypes;

		dispatch(
			setAdvanceOptions({
				attachmentTypes: updatedTypes,
				showMoreTypes: !showMoreTypes
			})
		);
	}, [attachmentTypes, showMoreTypes, dispatch]);

	return (
		<div class={style.attachmentWrapper}>
			<AttachmentList list={ATTACHMENT_TYPES.slice(0, 4)} attachmentTypes={attachmentTypes} />
			<AttachmentList list={ATTACHMENT_TYPES.slice(4, 8)} attachmentTypes={attachmentTypes} />
			{showMoreTypes && (
				<Fragment>
					<AttachmentList
						list={OTHER_ATTACHMENTS_TYPES.slice(0, 4)}
						attachmentTypes={attachmentTypes}
					/>
					<AttachmentList list={[TEXT_CALENDAR]} attachmentTypes={attachmentTypes} />
				</Fragment>
			)}
			<Button styleType="text" size="small" onClick={toggleMoreTypes} class={style.showMoreButton}>
				<Text id={`search.advanced.attachment.${showMoreTypes ? 'showLess' : 'showMore'}`} />
			</Button>
		</div>
	);
};
