import { Text } from 'preact-i18n';
import { Icon, Button } from '@zimbra/blocks';
import s from './style.less';
import cx from 'classnames';

const SidebarPrimaryButton = ({ onClick, textId, hyperlinkStyle, href, disabled }) => (
	<Button
		class={cx(s.button, s.sidebarPrimaryButton, hyperlinkStyle && s.buttonLink)}
		styleType="primary"
		brand="primary"
		onClick={onClick}
		href={href}
		disabled={disabled}
	>
		{hyperlinkStyle && <Icon name="chevron-left" class={s.icon} />}
		<Text id={textId} />
	</Button>
);

export default SidebarPrimaryButton;
