import { ModalDialog } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import AttachmentViewerToolbar from './toolbar';
import AttachmentViewerControls from './controls';
import cx from 'classnames';
import saveAs from '../../lib/save-as';
import { isImage, isTextFile, hasAttachmentPreview } from '../../utils/attachments';
import { useDispatch, useSelector } from 'react-redux';
import {
	setPreviewAttachment,
	setSelectedAttachment,
	previewNextPage,
	previewPreviousPage
} from '../../store/attachment-preview/actions';
import { getSelectedAttachmentPreview } from '../../store/attachment-preview/selectors';
import style from './style';
import DownloadAttachment from '../../graphql/queries/download-attachment.graphql';
import { useQuery } from '@apollo/client';
import appConfiguration from '../../enhancers/app-config';
import { Viewer } from './viewer';
import { useCallback, useState, useEffect, useRef } from 'preact/hooks';
import { getDataFromReduxStoreThunk } from '../../utils/thunk';
import isEqual from 'lodash-es/isEqual';

export const useDownloadQuery = ({ skip, attachment }) => {
	const { data } = useQuery(DownloadAttachment, {
		skip,
		variables: { ...(attachment && { id: attachment.messageId, part: attachment.part }) }
	});
	return data || {};
};

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
}

function AttachmentViewer(
	{ zimbraFeatureViewInHtmlEnabled, zimbraFeatureDocumentEditingEnabled },
	{ zimbraBatchClient }
) {
	const attachment = useSelector(state => getSelectedAttachmentPreview(state)) || false;
	const isTextContent = isTextFile(attachment);
	const { downloadAttachment } = useDownloadQuery({
		skip: !isTextContent,
		attachment
	});
	const page = useSelector(state => state.attachmentPreview.selected + 1);
	const dispatch = useDispatch();
	const [pending, updatePending] = useState(false);
	const [fullScreen, updatefullScreen] = useState(false);
	const [zoom, updateZoom] = useState(1);
	const [currentPage, updateCurrentPage] = useState();
	const [numPages, udpateNumPages] = useState();
	const [error, updateError] = useState();
	const [src, updateSrc] = useState();

	const handleFullScreen = useCallback(() => {
		updatePending(false);
		updateCurrentPage(undefined);
		udpateNumPages(undefined);
		updatefullScreen(!fullScreen);
	}, [fullScreen]);

	const download = useCallback(() => {
		saveAs(attachment);
	}, [attachment]);

	const prevAttachment = usePrevious(attachment);
	const prevPage = usePrevious(page);

	const handleClose = useCallback(() => {
		dispatch(setPreviewAttachment());
	}, [dispatch]);

	const handleUnsupported = useCallback(() => {
		updatePending(false);
		updateCurrentPage(undefined);
		udpateNumPages(undefined);
		updateError(<Text id="mail.attachmentViewer.notSupported" />);
		updateSrc(src);
	}, [src]);

	const getAttachmentSrc = useCallback(
		attach => {
			if (!attach) {
				return;
			}
			const { base64, contentType, type, url } = attach;
			return base64
				? `data:${contentType || type};base64,${base64}`
				: dispatch(getDataFromReduxStoreThunk('state.url.routeProps.localFolder'))
				? url
				: zimbraBatchClient.getAttachmentUrl(attach);
		},
		[dispatch, zimbraBatchClient]
	);

	const handlePreviousAttachment = useCallback(() => {
		dispatch(previewNextPage());
	}, [dispatch]);

	const handleNextAttachment = useCallback(() => {
		dispatch(previewPreviousPage());
	}, [dispatch]);

	const attachmentToolbar = (
		<AttachmentViewerToolbar
			page={currentPage}
			maxPages={numPages}
			attachment={attachment}
			fullScreen={fullScreen}
			onDownload={download}
			onFullScreen={handleFullScreen}
			onClose={handleClose}
		/>
	);

	const viewer = attachment && (
		<Viewer
			attachment={attachment}
			src={src}
			pending={pending}
			error={error}
			zoom={zoom}
			isTextContent={isTextContent}
			textContent={downloadAttachment && downloadAttachment.content}
			updateCurrentPage={updateCurrentPage}
			updateError={updateError}
			updatePending={updatePending}
			udpateNumPages={udpateNumPages}
			getAttachmentSrc={getAttachmentSrc}
		/>
	);

	const attachmentControls = (
		<AttachmentViewerControls
			onPreviousAttachment={handlePreviousAttachment}
			onNextAttachment={handleNextAttachment}
			zoom={zoom}
			updateZoom={updateZoom}
		/>
	);

	useEffect(() => {
		if (attachment && !isEqual(attachment, prevAttachment)) {
			if (
				!hasAttachmentPreview(
					attachment,
					zimbraFeatureViewInHtmlEnabled,
					zimbraFeatureDocumentEditingEnabled
				)
			) {
				handleUnsupported();
			} else {
				dispatch(setSelectedAttachment(attachment));
				const attachmentSrc = getAttachmentSrc(attachment);
				attachmentSrc.startsWith('data:')
					? updateSrc(attachmentSrc)
					: updateSrc(
							`${attachmentSrc}${
								!isImage(attachment) && attachment.messageId ? '&view=html' : ''
							}&timeStamp=${new Date().getTime()}`
					  );
				updatePending(!isTextFile(attachment));
				updateError(undefined);
				updateCurrentPage(undefined);
				udpateNumPages(undefined);
			}
		}
		if (page !== prevPage && zoom !== 1) {
			updateZoom(1);
		}
	}, [
		attachment,
		getAttachmentSrc,
		handleUnsupported,
		zimbraFeatureViewInHtmlEnabled,
		zimbraFeatureDocumentEditingEnabled,
		zoom,
		prevAttachment,
		prevPage,
		page,
		dispatch
	]);

	return (
		<div class={cx(style.attachmentViewer, attachment && style.showing)}>
			{fullScreen ? (
				<ModalDialog class={style.modal} onClickOutside={handleFullScreen}>
					{attachmentToolbar}
					{viewer}
					{attachmentControls}
				</ModalDialog>
			) : (
				[attachmentToolbar, viewer, attachmentControls]
			)}
		</div>
	);
}

export default appConfiguration('zimbraOrigin')(AttachmentViewer);
