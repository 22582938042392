import { Component } from 'preact';
import { Text } from 'preact-i18n';
import { Icon, ChoiceInput } from '@zimbra/blocks';
import Filters from '../../filters';
import FilterRuleTests from './filter-rule-tests';
import FilterRuleAction from './filter-rule-action';
import SelectFolderModal from './select-folder-modal';
import FilterRuleRemoveModal from './filter-rule-remove-modal';
import findIndex from 'lodash-es/findIndex';
import cloneDeep from 'lodash-es/cloneDeep';
import cx from 'classnames';
import { callWith } from '../../../lib/util';
import NakedButton from '../../naked-button';
import AddMore from '../add-more';
import style from '../style';
import { isFilterSupported, isBasicFilter } from '../../../utils/filter';
import withMediaQuery from '../../../enhancers/with-media-query/index';
import { minWidth, screenSm } from '../../../constants/breakpoints';
import ActionMenuFilterOptions from './action-menu-filter-options';
import SearchQuery from '../../../graphql/queries/search/search.graphql';
import get from 'lodash-es/get';
import { graphql } from '@apollo/client/react/hoc';
import { applyFilterRules } from '../../../graphql/mutations/apply-filter-rules.graphql';
import isEqual from 'lodash-es/isEqual';
import { connect } from 'react-redux';
import { notify } from '../../../store/notifications/actions';

export const ItemTypes = {
	FILTER: 'filter'
};

@withMediaQuery(minWidth(screenSm), 'matchesScreenSm')
@connect(
	state => ({
		isOffline: get(state, 'network.isOffline')
	}),
	{ notify }
)
@graphql(applyFilterRules, {
	props: ({ mutate }) => ({
		applyFilterRules: (ids, filterRules) =>
			mutate({
				variables: {
					ids,
					filterRules
				}
			})
	})
})
export default class FiltersSettings extends Component {
	state = {
		expandedFilters: {},
		unsavedFilters: {},
		filterRuleModal: {
			open: false,
			value: null
		},
		filterRuleRemoveModal: {
			open: false
		},
		selectFolderModal: {
			open: false
		},
		filterRunning: false,
		filterProgress: 0,
		cancelFilterRun: false,
		isBasicFilterMode: true,
		modifiedMessageCount: 0
	};

	//Used to store drag target for filter drag-handle logic.
	target = null;

	//Stores query to fetch messages from folders.
	folderQuery = '';
	messageTotal = 0;
	processedMessageCount = 0;
	fetchMore = false;

	handleAddFilter = () => {
		this.setState({
			filterRuleModal: {
				open: true,
				value: null
			}
		});
	};

	handleEditFilter = index => {
		const selectedFilter = this.props.value.filters[index];
		this.setState({
			filterRuleModal: {
				open: true,
				value: selectedFilter
			},
			isBasicFilterMode: isBasicFilter(selectedFilter)
		});
	};

	handleRemoveFilter = index => {
		const selectedFilter = this.props.value.filters[index];
		this.setState({
			filterRuleRemoveModal: {
				open: true,
				value: selectedFilter
			}
		});
	};

	handleToggleDetails = index => {
		this.setState(state => ({
			expandedFilters: {
				...state.expandedFilters,
				[index]: !state.expandedFilters[index]
			}
		}));
	};

	showFilterDetails = index => {
		this.setState(state => ({
			expandedFilters: {
				...state.expandedFilters,
				[index]: true
			}
		}));
	};

	handleSelectRun = index => {
		this.setState({
			selectFolderModal: {
				open: true,
				index
			}
		});
	};

	handleApplyFilterToFolder = (ids, { id: cursorId, sortField }, offset) => {
		const { selectFolderModal, cancelFilterRun } = this.state;

		if (cancelFilterRun) {
			this.setState({
				filterRunning: false
			});
			return;
		}

		const { applyFilterRules: applyFilter, value } = this.props;
		const idList = ids.join(',');

		const selectedFilter = value.filters[selectFolderModal.index];
		const filterList = [{ name: selectedFilter.name }];

		applyFilter(idList, filterList)
			.then(data => {
				const modifiedMessages = get(data, 'data.applyFilterRules', []);
				this.processedMessageCount += ids.length;
				const filterProgress = parseInt((this.processedMessageCount / this.messageTotal) * 100, 10);

				this.setState(({ modifiedMessageCount }) => ({
					filterProgress,
					modifiedMessageCount: modifiedMessageCount + modifiedMessages.length
				}));

				if (this.fetchMore) {
					const cursor = { id: cursorId, sortVal: sortField };
					const newOffset = offset + ids.length;
					this.getMessagesFromFolder(cursor, newOffset);
				}
			})
			.catch(({ message }) => {
				if (message) {
					console.error(message);
				}
			});
	};

	getMessagesFromFolder = (cursor, offset = 0) => {
		if (this.state.cancelFilterRun) {
			this.setState({
				filterRunning: false
			});
			return;
		}

		/*
			Setting fetchPolicy to no-cache is a temporary fix. It is added since
			after the applyFilter mutation the notifications don't seem to be updating
			the cache correctly. This will need to be fixed in another ticket.
		*/
		const queryParams = {
			query: SearchQuery,
			fetchPolicy: 'no-cache',
			variables: {
				types: 'message',
				query: this.folderQuery,
				sortBy: 'dateDesc',
				limit: 100,
				recip: 0,
				needExp: true,
				offset,
				resultMode: 'IDS',
				...(cursor && { cursor })
			}
		};

		this.context.client
			.query(queryParams)
			.then(({ data }) => {
				const hit = get(data, 'search.hit', []);
				if (!hit.length) {
					return;
				}

				const ids = hit.map(({ id }) => id).sort((prev, next) => prev - next);
				const lastItem = hit[hit.length - 1];
				this.fetchMore = get(data, 'search.more');
				this.handleApplyFilterToFolder(ids, lastItem, offset);
			})
			.catch(({ message }) => {
				if (message) {
					console.error(message);
				}
			});
	};

	getQueryAndTotal = selectedFolders => {
		let folderQuery = '';
		let messageTotal = 0;

		selectedFolders.forEach(folder => {
			//Remove the preceding slash from the absFolderPath in order to use it in the search query.
			const folderPath = folder.absFolderPath.substring(1);
			folderQuery += folderQuery.length ? ` OR in:"${folderPath}"` : `in:"${folderPath}"`;
			messageTotal += folder.nonFolderItemCount;
		});

		return { folderQuery, messageTotal };
	};

	handleFilterRunAction = selectedFolders => {
		this.handleCloseFilterRun();
		this.showFilterDetails(this.state.selectFolderModal.index);

		const { folderQuery, messageTotal } = this.getQueryAndTotal(selectedFolders);
		this.folderQuery = folderQuery;
		this.messageTotal = messageTotal;
		this.processedMessageCount = 0;
		this.fetchMore = false;

		this.setState({
			cancelFilterRun: false,
			filterRunning: true,
			filterProgress: messageTotal === 0 ? 100 : 0,
			modifiedMessageCount: 0
		});

		messageTotal !== 0 && this.getMessagesFromFolder();
	};

	handleCloseFilterRun = () => {
		this.setState(({ selectFolderModal }) => ({
			selectFolderModal: {
				open: false,
				index: selectFolderModal.index
			}
		}));
	};

	handleCancelFilterRun = () => {
		this.setState({
			cancelFilterRun: true
		});
	};

	handleToggleActive = index => {
		const { filters } = this.props.value;
		const filter = filters[index];
		const nextFilter = {
			...filter,
			active: !filter.active
		};

		this.props.onFieldChange('filters')({
			target: {
				value: [...filters.slice(0, index), nextFilter, ...filters.slice(index + 1)]
			}
		});
	};

	handleFilterRuleModalAction = filterRule => {
		if (filterRule) {
			const filters = cloneDeep(this.props.value.filters);
			const selectedIdx = findIndex(
				this.props.value.filters,
				filter => filter === this.state.filterRuleModal.value
			);

			//If a new filter has been added or a filter data has been modified add to unsavedFilter llokup to prevent running a filter on an unsaved filter.
			if (selectedIdx === -1 || !isEqual(filters[selectedIdx], filterRule)) {
				const updateIndex = selectedIdx === -1 ? filters.length : selectedIdx;
				this.setState(state => ({
					unsavedFilters: {
						[updateIndex]: true,
						...state.unsavedFilters
					}
				}));
			}

			filters[selectedIdx === -1 ? filters.length : selectedIdx] = filterRule;
			this.props.onFieldChange('filters')({ target: { value: filters } });
		}
		this.setState({
			filterRuleModal: {
				open: false,
				value: null
			},
			isBasicFilterMode: true
		});
	};

	handleFilterRuleRemoveModalAction = result => {
		if (result && this.state.filterRuleRemoveModal.value) {
			const filters = cloneDeep(this.props.value.filters);
			const selectedIdx = findIndex(
				this.props.value.filters,
				filter => filter === this.state.filterRuleRemoveModal.value
			);
			filters.splice(selectedIdx, 1);
			this.props.onFieldChange('filters')({ target: { value: filters } });

			//Code to preserve expanded state after item is deleted.
			const modifiedExpandedFilters = cloneDeep(this.state.expandedFilters);

			let i = 0;
			for (i = selectedIdx; i < filters.length; i++) {
				modifiedExpandedFilters[i] = modifiedExpandedFilters[i + 1];
			}

			this.setState({
				expandedFilters: {
					...modifiedExpandedFilters
				}
			});
		}
		this.setState({
			filterRuleRemoveModal: {
				open: false,
				value: null
			}
		});
	};

	handleDragMouseDown = e => {
		this.target = e.currentTarget;
	};

	handleDragMouseUp = () => {
		this.target = null;
	};

	handleDragStart = (index, e) => {
		//Check to see if drag handle is a child of this draggable item.
		if (e.currentTarget.contains(this.target)) {
			e.dataTransfer.setData('text/plain', index);
			e.currentTarget.classList.add('draggedItem');

			//Collapse details & cancel filter run when user begins to drag.
			this.setState({
				expandedFilters: {},
				cancelFilterRun: true
			});
		} else {
			e.preventDefault();
		}
	};

	handleDragEnd = e => {
		e.currentTarget.classList.remove('draggedItem');
		this.target = null;
	};

	handleOnDragOver = e => {
		e.preventDefault();

		e.currentTarget.classList.add('dragOver');
	};

	handleOnDragLeave = e => {
		e.preventDefault();

		e.currentTarget.classList.remove('dragOver');
	};

	handleDrop = (dropIndex, e) => {
		e.preventDefault();
		e.currentTarget.classList.remove('dragOver');

		const draggedItemIndex = e.dataTransfer.getData('text');

		if (draggedItemIndex === dropIndex) {
			return;
		}

		const filters = cloneDeep(this.props.value.filters);
		const element = filters[draggedItemIndex];

		filters.splice(draggedItemIndex, 1);

		//If dragged item index greater than index of dropzone item.
		if (draggedItemIndex >= dropIndex) {
			filters.splice(dropIndex, 0, element);
		} else {
			filters.splice(dropIndex - 1, 0, element);
		}

		this.props.onFieldChange('filters')({ target: { value: filters } });
	};

	handleAdvancedFilterOpenAction = () => {
		this.setState({
			isBasicFilterMode: false
		});
	};

	handleSwitchToBasicFilterAction = () => {
		this.setState({
			isBasicFilterMode: true
		});
	};

	render(
		{ value, matchesScreenSm, accountData, dataSourcesInfo, identitiesInfo, folders, inboxFolder },
		{
			filterRuleModal,
			filterRuleRemoveModal,
			expandedFilters,
			isBasicFilterMode,
			selectFolderModal,
			filterProgress,
			filterRunning,
			unsavedFilters,
			modifiedMessageCount,
			cancelFilterRun
		}
	) {
		return (
			<div>
				<div class={cx(style.sectionTitle)}>
					<Text id="settings.filterRuleModal.mainTitle" />
				</div>
				{value.filters.length > 0 && (
					<div class={style.filtersList}>
						{value.filters.map((filter, index) => (
							<FilterListEntry
								key={`${index}-${filter.name}`}
								filter={filter}
								index={index}
								expanded={expandedFilters[index]}
								onEditFilter={this.handleEditFilter}
								onRemoveFilter={this.handleRemoveFilter}
								onToggleDetails={this.handleToggleDetails}
								onToggleActive={callWith(this.handleToggleActive, index)}
								onDragStart={callWith(this.handleDragStart, index, true)}
								onDragEnd={this.handleDragEnd}
								onDragOver={this.handleOnDragOver}
								onDragLeave={this.handleOnDragLeave}
								onDrop={callWith(this.handleDrop, index, true)}
								onDragMouseUp={this.handleDragMouseUp}
								onDragMouseDown={this.handleDragMouseDown}
								onSelectRun={this.handleSelectRun}
								onCancelFilterRun={this.handleCancelFilterRun}
								filterProgress={filterProgress}
								selectFolderModal={selectFolderModal}
								modifiedMessageCount={modifiedMessageCount}
								isFilterRunning={filterRunning}
								unsavedFilter={unsavedFilters[index]}
								cancelFilterRun={cancelFilterRun}
							/>
						))}

						<div
							class={style.dummyDropZone}
							onDragOver={this.handleOnDragOver}
							onDragLeave={this.handleOnDragLeave}
							onDrop={callWith(this.handleDrop, value.filters.length, true)}
						/>
					</div>
				)}

				<AddMore label="settings.filterRuleModal.addFilter" onClick={this.handleAddFilter} />

				{filterRuleModal.open && (
					<Filters
						value={filterRuleModal.value}
						isBasicFilterMode={isBasicFilterMode}
						onSave={this.handleFilterRuleModalAction}
						onClose={callWith(this.handleFilterRuleModalAction, null)}
						accountData={accountData}
					/>
				)}
				{filterRuleRemoveModal.open && (
					<FilterRuleRemoveModal
						onResult={this.handleFilterRuleRemoveModalAction}
						value={filterRuleRemoveModal.value}
					/>
				)}
				{selectFolderModal.open && (
					<SelectFolderModal
						value={filterRuleModal.value}
						matchesScreenSm={matchesScreenSm}
						onSave={this.handleFilterRunAction}
						onClose={this.handleCloseFilterRun}
						account={accountData}
						dataSources={dataSourcesInfo}
						identities={identitiesInfo}
						folders={folders}
						inboxFolder={inboxFolder}
					/>
				)}
			</div>
		);
	}
}

function FilterListEntry({
	filter,
	index,
	expanded,
	onEditFilter,
	onRemoveFilter,
	onToggleDetails,
	onToggleActive,
	onDragStart,
	onDragEnd,
	onDragOver,
	onDragLeave,
	onDrop,
	onDragMouseUp,
	onDragMouseDown,
	onCancelFilterRun,
	onSelectRun,
	filterProgress,
	selectFolderModal,
	modifiedMessageCount,
	isFilterRunning,
	unsavedFilter,
	cancelFilterRun
}) {
	const handleToggleDetails = callWith(onToggleDetails, index);
	const disableWhenRunning = isFilterRunning && filterProgress !== 100;
	const filterSupported = isFilterSupported(filter);

	return (
		<div
			class={style.filtersListEntry}
			draggable
			onDragStart={onDragStart}
			onDragEnd={onDragEnd}
			onDragOver={onDragOver}
			onDragLeave={onDragLeave}
			onDrop={onDrop}
		>
			<div class={style.controls}>
				<ChoiceInput checked={filter.active} onInput={onToggleActive} />
				<button class={style.filterName} onClick={handleToggleDetails}>
					{filter.name}
				</button>

				<ActionMenuFilterOptions
					buttonClass={style.actionButton}
					onToggleDetails={filterSupported && callWith(handleToggleDetails, index)}
					onSelectRun={
						filter.active && !disableWhenRunning && !unsavedFilter && callWith(onSelectRun, index)
					}
					onEditFilter={filterSupported && !disableWhenRunning && callWith(onEditFilter, index)}
					onDeleteFilter={!disableWhenRunning && callWith(onRemoveFilter, index)}
				/>

				<span onMouseUp={onDragMouseUp} onMouseDown={onDragMouseDown} class={style.dragHandle}>
					<Icon name="drag" />
				</span>
			</div>

			{expanded && (
				<div class={style.filterDetails}>
					{selectFolderModal.index === index && isFilterRunning && !cancelFilterRun && (
						<div class={style.filterProgressInfo}>
							<span class={style.progressBar}>
								<span class={style.progressIndicator} style={{ width: `${filterProgress}%` }} />
							</span>

							<span class={style.progressValue}>
								{filterProgress === 100 ? (
									<span>
										<Icon name="check-circle" size="sm" class={style.runSuccessIndicator} />
										<Text
											id="settings.filterRuleModal.filterRunTotal"
											fields={{ modifiedMessageCount }}
										/>
									</span>
								) : (
									<span>
										<Text
											id="settings.filterRuleModal.progressIndicator"
											fields={{ progress: filterProgress }}
										/>
										<NakedButton class={style.cancelRun} onClick={onCancelFilterRun}>
											<Icon name="close-circle" size="sm" />
										</NakedButton>
									</span>
								)}
							</span>
						</div>
					)}

					<p class={style.ifThenText}>
						{' '}
						{filter.conditions[0].allOrAny === 'allof' ? (
							<Text id="settings.filterRuleModal.ifAllConditionsMet" />
						) : (
							<Text id="settings.filterRuleModal.ifAnyConditionsMet" />
						)}{' '}
					</p>
					<FilterRuleTests test={filter.conditions[0]} />
					<FilterRuleAction action={filter.actions[0]} />
				</div>
			)}
		</div>
	);
}
