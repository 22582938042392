import { Component } from 'preact';
import Button from '../button';
import Spinner from '../spinner';
import cx from 'classnames';
import style from './style.less';

export default class AnimatedButton extends Component {
	state = {
		shake: this.props.shake,
		loading: this.props.loading,
		disabled: this.props.disabled
	};

	setStateFromProps = props => {
		const { loading, shake, disabled } = props;

		this.setState({
			loading,
			shake,
			disabled
		});
	};

	componentWillReceiveProps(nextProps) {
		const { shake } = this.state;
		const { afterShake } = this.props;

		if (nextProps.shake === true && shake !== nextProps.shake) {
			this.timer = setTimeout(() => {
				this.setState({ shake: false });
				afterShake();
			}, 250);
		}

		this.setStateFromProps(nextProps);
	}

	componentWillUnmount() {
		this.timer && clearTimeout(this.timer);
	}

	render({ children, title, toolTip, ...rest }, { loading, shake, disabled }) {
		title = title || (children && children.length && children);

		return (
			<Button
				{...rest}
				class={cx(style.btnAnimated, shake && style.shakeHorizontal, rest.class)}
				disabled={disabled}
				title={toolTip}
			>
				{title}
				{loading && (
					<span class={style.spinner}>
						<Spinner btnSpinner />
					</span>
				)}
			</Button>
		);
	}
}
