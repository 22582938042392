import { PureComponent } from 'preact/compat';
import FolderListLight from '../folder-list-light';
import FolderListItem from '../folder-list-light/item';
import { getTrimmedFolderId } from '../../utils/contacts';

export default class HabGroupListLight extends PureComponent {
	state = {
		habGroupsExpanded: []
	};

	setHabGroupsExpanded = folderId => {
		this.setState(({ habGroupsExpanded: prevFoldersExpanded }) => ({
			habGroupsExpanded:
				(prevFoldersExpanded?.folderId && prevFoldersExpanded.filter(id => id !== folderId)) ||
				prevFoldersExpanded.concat(folderId)
		}));
	};

	renderHabGroups = () => {
		const { habGroups = [], folder: selectedFolderId, onFolderClick } = this.props;
		const { habGroupsExpanded } = this.state;
		return habGroups.map(folder => (
			<FolderListItem
				folder={folder}
				foldersExpanded={habGroupsExpanded}
				selectedFolderIds={[getTrimmedFolderId(selectedFolderId)]}
				grouped
				onToggleExpanded={this.setHabGroupsExpanded}
				onFolderClick={onFolderClick}
			/>
		));
	};

	render({ folder, folders, onFolderClick, sharedFolders }) {
		return (
			<FolderListLight
				folders={folders}
				onFolderClick={onFolderClick}
				extraChildren={this.renderHabGroups()}
				folderNameProp="displayName"
				selectedIds={[getTrimmedFolderId(folder)]}
				sharedFolders={sharedFolders}
			/>
		);
	}
}
