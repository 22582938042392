import { useMutation } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import update from 'immutability-helper';

import modifyPrefs from '../../graphql/mutations/modify-prefs.graphql';
import GetPreferences from '../../graphql/queries/preferences/preferences.graphql';
import memoize from 'lodash-es/memoize';

/**
 * Decorator that passes down a prop called "modifyPrefs" that calls the modifyPrefs mutation and optimistically updates
 * preferences in the store with the new preferences
 */
const memoizedModifyPrefs = memoize(mutate => prefs => mutate(getMutationObject(prefs)));
export function withModifyPrefs() {
	return graphql(modifyPrefs, {
		props: ({ mutate }) => ({
			modifyPrefs: memoizedModifyPrefs(mutate)
		})
	});
}

export function useModifyPrefs() {
	const [modifyPrefsMutation] = useMutation(modifyPrefs, {});

	return function (prefs) {
		return modifyPrefsMutation(getMutationObject(prefs));
	};
}

function getMutationObject(prefs) {
	return {
		variables: {
			prefs
		},
		optimisticResponse: {
			__typename: 'Mutation',
			modifyPrefs: true
		},
		update: proxy => {
			const data = proxy.readQuery({ query: GetPreferences });
			proxy.writeQuery({
				query: GetPreferences,
				data: update(data, {
					getPreferences: {
						$merge: prefs
					}
				})
			});
		}
	};
}
