import { Text } from 'preact-i18n';
import { useDispatch } from 'react-redux';
import { useCallback } from 'preact/hooks';
import NakedButton from '../naked-button';
import style from './style';
import { offlineInfoVisibility } from '../../store/network/actions';
import { OfflineBadge } from '../offline-label';

export const OfflineInfo = () => {
	const dispatch = useDispatch();

	const handleClick = useCallback(() => {
		dispatch(offlineInfoVisibility(false));
	}, [dispatch]);

	return (
		<div class={style.offlineInfo}>
			<Text id="offlineInfo.message" />{' '}
			<NakedButton linkColor onClick={handleClick}>
				<Text id="offlineInfo.gotIt" />
			</NakedButton>
			<OfflineBadge />
		</div>
	);
};
