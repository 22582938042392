import { Component } from 'preact';
import InlineModalDialog from '../../../inline-modal-dialog';
import { Text } from 'preact-i18n';
import { Button, Icon, ChoiceInput } from '@zimbra/blocks';
import ModalDrawer from '../../../modal-drawer';
import ModalDrawerToolbar from '../../../modal-drawer-toolbar';
import withMediaQuery from '../../../../enhancers/with-media-query';
import { minWidth, screenMd } from '../../../../constants/breakpoints';
import style from './style.less';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import cx from 'classnames';

import { findFolder, specialFolders, customFolders, INBOX } from '../../../../utils/folders';
import { callWith } from '@zimbra/util/src/call-with';

const INBOX_REGEX = new RegExp('^$' + INBOX + '$', 'i');
const CUSTOM_FOLDER_PARENT_NAME = 'folders';

@withMediaQuery(minWidth(screenMd), 'matchesScreenMd')
export default class FilterModal extends Component {
	state = {
		error: null,
		accountFolderMap: [],
		selectedFolders: []
	};

	handleRun = () => {
		const { onSave, index } = this.props;
		onSave(this.state.selectedFolders, index);
	};

	handleClose = () => {
		this.setState({ error: null }, this.props.onClose);
	};

	handleCloseDrawer = () => {
		this.setState({ isDrawerMounted: false }, this.props.onClose);
	};

	assembleAccountList = () => {
		const {
			account: { id },
			inboxFolder: { unread, id: inboxFolderId },
			folders,
			identities,
			dataSources
		} = this.props;

		const primaryIdentity = find(identities.identity, i => i.id === id);
		const accountList = [
			{
				id,
				uuid: id,
				isPrimary: true,
				name: primaryIdentity._attrs.zimbraPrefFromAddress,
				folderId: null,
				navigateTo: '/email/Inbox',
				unread,
				disableSelect: true
			}
		];

		dataSources.imap &&
			accountList.push(
				...dataSources.imap
					.filter(({ l: folderID }) => folderID !== inboxFolderId)
					.map(i => {
						const sourceFolders = get(findFolder(folders, i.l), 'folders') || [];
						const primaryFolder =
							find(sourceFolders, ({ name }) => INBOX_REGEX.test(name)) || sourceFolders[0];

						return {
							id: i.id,
							uuid: i.id,
							name: i.emailAddress,
							folderId: i.l,
							failingSince: i.failingSince,
							lastError: get(i, 'lastError._content'),
							unread: get(primaryFolder, 'unread'),
							disableSelect: true
						};
					})
			);

		return accountList;
	};

	foldersForAccount = (accounts, { id, isPrimary, folderId }) => {
		const { folders } = this.props;
		// Exclude IMAP sub-folders for the primary account
		const excludedDataSourceFolderIds = isPrimary
			? accounts.filter(a => a.id !== id).map(a => a.folderId)
			: [];

		// Return the filtered folder list for the primary account, or the root
		// folder for the IMAP account
		return isPrimary
			? folders.filter(f => !includes(excludedDataSourceFolderIds, f.id.toString()))
			: get(findFolder(folders, folderId), 'folders') || [];
	};

	modifySelectedArray(item) {
		const { selectedFolders } = this.state;

		this.setState({
			selectedFolders: item.selected
				? selectedFolders.filter(({ uuid }) => item.uuid !== uuid)
				: [...selectedFolders, item]
		});
	}

	//Find Folder to toggle its selected/expanded state
	findDeepWithToggle(item, selection, propertyToToggle) {
		if (item.uuid === selection.uuid) {
			//If toggling the selected state modify the selectedFolders array.
			if (propertyToToggle === 'selected') {
				this.modifySelectedArray(item);
			}

			//Toggle item's selected state
			return {
				...item,
				[propertyToToggle]: !item[propertyToToggle]
			};
		} else if (get(selection, 'folders.length')) {
			return {
				...selection,
				folders: selection.folders.map(folderItem =>
					this.findDeepWithToggle(item, folderItem, propertyToToggle)
				)
			};
		}

		return selection;
	}

	handleToggleFolderState = ({ item, property }) => {
		this.setState({
			accountFolderMap: this.state.accountFolderMap.map(accountItem =>
				this.findDeepWithToggle(item, accountItem, property)
			)
		});
	};

	componentWillMount() {
		const accounts = this.assembleAccountList();

		const accountFolderMap = accounts.map(currentAccount => {
			const foldersList = this.foldersForAccount(accounts, currentAccount);
			const customFolderList = customFolders(foldersList);
			const account = {
				...currentAccount,
				folders: specialFolders(foldersList),
				expanded: false,
				selected: false
			};

			if (customFolderList.length) {
				account.folders.push({
					name: CUSTOM_FOLDER_PARENT_NAME,
					uuid: `${CUSTOM_FOLDER_PARENT_NAME}:${currentAccount.id}`,
					folders: customFolderList,
					disableSelect: true
				});
			}

			return account;
		});

		this.setState({
			accountFolderMap
		});
	}

	renderFolderList = items => <ul>{items.map(item => this.renderFolderListItem(item))}</ul>;

	renderFolderListItem(item) {
		const hasFolders = get(item, 'folders.length');

		if (hasFolders) {
			return (
				<li key={item.uuid}>
					<label
						class={cx(
							style.folderRow,
							item.name === CUSTOM_FOLDER_PARENT_NAME && style.padLeft,
							item.name !== CUSTOM_FOLDER_PARENT_NAME && item.disableSelect && style.accountRow
						)}
					>
						{!item.disableSelect && (
							<ChoiceInput
								onChange={callWith(this.handleToggleFolderState, {
									item,
									property: 'selected'
								})}
								type="checkbox"
								checked={item.selected}
							/>
						)}
						<span class={style.itemName}>
							{
								<Text
									id={
										item.name === CUSTOM_FOLDER_PARENT_NAME
											? `folderlist.${item.name}`
											: `folders.${item.name}`
									}
								>
									{item.name}
								</Text>
							}
						</span>
					</label>
					<Icon
						name={`angle-${item.expanded ? 'down' : 'right'}`}
						class={style.folderExpandCollapse}
						onClick={callWith(this.handleToggleFolderState, {
							item,
							property: 'expanded'
						})}
					/>
					{item.expanded && this.renderFolderList(item.folders)}
				</li>
			);
		}

		return (
			<li>
				<label class={style.folderRow}>
					<ChoiceInput
						onChange={callWith(this.handleToggleFolderState, {
							item,
							property: 'selected'
						})}
						type="checkbox"
						checked={item.selected}
					/>
					<span class={style.itemName}>
						{
							<Text
								id={
									item.name === CUSTOM_FOLDER_PARENT_NAME
										? `folderlist.${item.name}`
										: `folders.${item.name}`
								}
							>
								{item.name}
							</Text>
						}
					</span>
				</label>
			</li>
		);
	}

	render({ matchesScreenMd }, { error, isDrawerMounted, accountFolderMap, selectedFolders }) {
		const [ComponentClass, componentClassProps] = matchesScreenMd
			? [InlineModalDialog, { autofocusChildIndex: 1 }]
			: [
					ModalDrawer,
					{
						mounted: isDrawerMounted,
						toolbar: (
							<ModalDrawerToolbar
								buttons={[
									<Button
										styleType="primary"
										brand="primary"
										onClick={this.handleRun}
										disabled={!selectedFolders.length}
									>
										<Text id="settings.selectFolderModal.runLabel" />
									</Button>
								]}
								onClose={this.handleCloseDrawer}
							/>
						),
						onClickOutside: this.handleClose
					}
			  ];
		return (
			<ComponentClass
				{...componentClassProps}
				actionLabel="settings.selectFolderModal.runLabel"
				title={matchesScreenMd && 'settings.selectFolderModal.title'}
				onAction={this.handleRun}
				onClose={this.handleClose}
				error={error}
				disablePrimary={!selectedFolders.length}
				showCloseBtn={false}
			>
				<div class={style.selectFolderModal}>{this.renderFolderList(accountFolderMap)}</div>
			</ComponentClass>
		);
	}
}
