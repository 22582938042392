import { graphql } from '@apollo/client/react/hoc';
import DiscoverRightsQuery from '../../graphql/queries/preferences/discover-rights.graphql';

const defaultRights = [
	{
		_content: 'sendAs'
	},
	{
		_content: 'sendOnBehalfOf'
	},
	{
		_content: 'sendAsDistList'
	},
	{
		_content: 'sendOnBehalfOfDistList'
	}
];

export default function withDiscoverRights(mapProps = () => ({}), rights = defaultRights) {
	return graphql(DiscoverRightsQuery, {
		options: () => ({
			variables: { right: rights }
		}),
		props: result => ({
			delegatedRights: result.data.discoverRights,
			...mapProps(result)
		})
	});
}
