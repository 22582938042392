import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';

const initialState = {
	checkedTags: []
};

export default handleActions(
	{
		[actionCreators.unCheckTag]: (state, action) => ({
			...state,
			checkedTags: state.checkedTags.filter(tag => tag !== action.payload)
		}),
		[actionCreators.checkTag]: (state, action) => ({
			...state,
			checkedTags: [...state.checkedTags, action.payload]
		})
	},
	initialState
);
