import { createElement, Component, Fragment } from 'preact';
import { Localizer, Text } from 'preact-i18n';
import get from 'lodash-es/get';
import each from 'lodash-es/each';
import { getCurrentUrl, route } from 'preact-router';
import { withProps, compose, branch } from 'recompose';
import cx from 'classnames';
import queryString from 'query-string';
import linkstate from 'linkstate';
import { USER_FOLDER_IDS } from '../../constants';
import {
	isActiveFolder,
	isActiveOrChildFolder,
	routeToRenamedFolder,
	trimMailFolderViewFromUri,
	LOCAL_FOLDER_ABSFOLDERPATH_PREFIX
} from '../../utils/routing';
import { canMoveMessagesIntoFolders, isRenameAllowed, hasFlag } from '../../utils/folders';
import { callWith, isValidEmail } from '../../lib/util';
import clientConfiguration from '../../enhancers/client-config';
import withDialog from '../../enhancers/with-dialog';
import { CONTACT_GROUP_PREFIX } from '../../constants/contacts';
import { CONTACTS_VIEW, CONTACTS_GROUP_VIEW, MAIL_VIEW } from '../../constants/views';
import { Icon, ChoiceInput, Popover, Spinner } from '@zimbra/blocks';
import { setDataTransferJSON, getDataTransferJSON } from '@zimbra/util/src/data-transfer-manager';
import MenuItem from '../menu-item';
import CollapsibleControl from '../collapsible-control';
import FolderInput from '../folder-input';
import NakedButton from '../naked-button';
import ImportRefreshIcon from './import-refresh-icon';
import FolderRefreshIcon from './folder-refresh-icon';
import ContextMenu from '../context-menu';
import EmptyFolderDialog from '../empty-folder-dialog';
import DeleteList from '../contacts/edit-lists/delete-list';
import DeleteFolderDialog from '../delete-folder-dialog';
import ShareDialog from '../share-dialog';
import {
	InboxContextMenu,
	SpecialFolderContextMenu,
	SpamContextMenu,
	TrashContextMenu,
	ContactListContextMenu,
	ContactFolderShareOnlyContextMenu,
	ContactsFolderContextMenu,
	ContactsTrashContextMenu,
	TagListContextMenu,
	MailFolderContextMenu,
	BriefcaseFolderContextMenu,
	BriefcasesFolderContextMenu,
	BriefcaseTrashFolderMenu
} from '../context-menus';
import { DEFAULT_NOTIFICATION_DURATION } from '../../constants/notifications';
import {
	MovedSmartFolderMessage,
	DeletedSmartFolder
} from '../../components/notifications/messages';
import { FolderEmptiedMessage } from '../notifications/messages';

import style from './style';
import { getQueryOptions } from '../../utils/search';
import NewFolder from './new-folder';
import { TRASH, DRAFTS, OUTBOX } from '../../constants/folders';
import { DB_PATH_SEPERATOR, FS_FOLDERS, LOCAL_FOLDER_IDS } from '@zimbra/electron-app';
import {
	getFolderNameValidationStatus,
	INVALID_FOLDER_NAME_ERRORS,
	FOLDER_NAME_CHAR_LIMIT,
	isSystemFolder,
	checkIfFolderDroppable,
	getTranslatedFolderName
} from './util';
import { faultCode } from '../../utils/errors';
import ImportModal from './../import-modal';
import COLORS from '../../constants/colors';
import CreateTagModal from '../tags/create-tags-modal';
import { TAG_ACTIONS } from '../../constants/tags';
import ModalDialog from '../modal-dialog';
import FolderInfoTooltip from './tooltip';

import moment from 'moment';
import { startAttachmentDownloadProcess } from '../../lib/save-as';
import { types as apiClientTypes } from '@zimbra/api-client';

const { ActionOps } = apiClientTypes;
import Dumpster from '../dumpster';
import {
	getVariablesFromDataId,
	getSearchInFolderDataIds
} from '../../graphql/utils/graphql-optimistic';

const DROP_ANIMATION_MAX = 500;
const CUSTOM_CONTEXT_MENUS = {
	'message.inbox': { contextMenu: InboxContextMenu, showEllipsis: true },
	'message.drafts': { contextMenu: SpecialFolderContextMenu, showEllipsis: true },
	'message.sent': { contextMenu: SpecialFolderContextMenu, showEllipsis: true },
	'message.archive': { contextMenu: SpecialFolderContextMenu, showEllipsis: true },
	'message.junk': { contextMenu: SpamContextMenu, showEllipsis: true },
	'message.trash': { contextMenu: TrashContextMenu, showEllipsis: true },
	'message.outbox': { contextMenu: SpecialFolderContextMenu, showEllipsis: true },
	'contact.contacts': { contextMenu: ContactsFolderContextMenu, showEllipsis: true },
	'contact.trash': { contextMenu: ContactsTrashContextMenu, showEllipsis: true },
	'contact.emailed contacts': {
		contextMenu: ContactFolderShareOnlyContextMenu,
		showEllipsis: true
	},
	'contact.global address list': { contextMenu: null, showEllipsis: false },
	'document.briefcase': { contextMenu: BriefcasesFolderContextMenu, showEllipsis: true },
	'document.trash': { contextMenu: BriefcaseTrashFolderMenu, showEllipsis: true },
	'document.files shared with me': { contextMenu: null, showEllipsis: false },
	contactgroup: { contextMenu: ContactListContextMenu, showEllipsis: true },
	message: { contextMenu: MailFolderContextMenu, showEllipsis: true },
	tag: { contextMenu: TagListContextMenu, showEllipsis: false },
	document: { contextMenu: BriefcaseFolderContextMenu, showEllipsis: true }
};
const HOVER_DURATION = 1000;

function getAllSubfolders({ folder, folders, linkedFolders }) {
	let subFolders = folders || folder;
	linkedFolders && (subFolders = (subFolders || []).concat(linkedFolders));
	return subFolders && subFolders.length > 0 ? subFolders : null;
}

@withProps(props => ({
	isTag: !(props?.folder?.absFolderPath || props?.folder?.folderId) // contact lists have folderId as distinct property instead of absFolderPath.
}))
@clientConfiguration({ slugs: 'routes.slugs', localStorePath: 'localStorePath' })
class BaseFolderListItem extends Component {
	state = {
		dropTarget: false,
		dropped: false,
		isRenaming: false,
		isRenamingTag: false,
		isCreatingSubFolder: false,
		showDeleteConfirm: false,
		isPopoverActive: false
	};

	getFolderName = () => {
		const { folder, folderNameProp = 'name' } = this.props;
		return typeof folderNameProp === 'function' ? folderNameProp(folder) : folder[folderNameProp];
	};

	customContextMenu = () => {
		const { customContextMenus = {}, view, isTag } = this.props;
		const contextMenuKey = `${view}.${this.getFolderName()}`.toLowerCase();
		const contextMenuListKey = view.toLowerCase();
		const contextMenuObject =
			(isTag && CUSTOM_CONTEXT_MENUS.tag) ||
			customContextMenus[contextMenuKey] ||
			CUSTOM_CONTEXT_MENUS[contextMenuKey] ||
			CUSTOM_CONTEXT_MENUS[contextMenuListKey];
		if (contextMenuObject) {
			return contextMenuObject.contextMenu;
		}
	};

	validateFolderName = (name, isLocalFolder) => {
		const { isValid, notifyMessageID } = getFolderNameValidationStatus(name, isLocalFolder);

		if (!isValid) {
			const message =
				notifyMessageID !== INVALID_FOLDER_NAME_ERRORS.LENGTH_EXCEED_WARNING ? (
					<Text id={`notifications.${notifyMessageID}`} />
				) : (
					<Text
						id={`notifications.${notifyMessageID}`}
						fields={{ count: FOLDER_NAME_CHAR_LIMIT }}
					/>
				);

			this.props.notify({
				failure: true,
				message
			});
		}
		return isValid;
	};

	showMenuEllipsis = () => {
		const { customContextMenus = {}, view, defaultShowEllipsis } = this.props;
		const contextMenuKey = `${view}.${this.getFolderName()}`.toLowerCase();
		const contextMenuListKey = view.toLowerCase();
		const contextMenuObject =
			customContextMenus[contextMenuKey] ||
			CUSTOM_CONTEXT_MENUS[contextMenuKey] ||
			CUSTOM_CONTEXT_MENUS[contextMenuListKey];
		if (contextMenuObject) return contextMenuObject.showEllipsis;
		return defaultShowEllipsis;
	};

	getSearchURL = folderQuery => {
		const { urlSlug, slugs } = this.props;
		const fQuery = getQueryOptions(folderQuery);
		const qObject = {};
		each(fQuery, (value, key) => {
			if (value && key !== 'query') {
				if (key === 'dateTypeValue') {
					if (value !== 'anytime') {
						qObject[key] = value;
					}
				} else if (key === 'activeFolder') {
					qObject.folder = value;
				} else {
					qObject[key] = value;
				}
			}
		});

		const types = urlSlug === 'calendar' ? 'appointment,task' : 'conversation';
		const searchParam =
			isValidEmail(fQuery.query) && !Object.keys(qObject).length
				? { e: fQuery.query, types }
				: { q: fQuery.query, types, ...qObject };

		return `/${slugs.search}/${urlSlug}?${queryString.stringify(
			searchParam
		)}&foldername=${encodeURIComponent(this.getFolderName())}`;
	};

	getUrl = () => {
		const { urlSuffixProp, urlSlug, urlPrefix, folder, slugs, isTag } = this.props;

		// Remove `/` from start and end of absFolderPath
		const updatedAbsFolderPath =
			folder.absFolderPath && folder.absFolderPath.replace(/(^\/|\/$)/, '');

		const folderName = this.getFolderName();
		const urlSuffix =
			(urlSuffixProp && folder[urlSuffixProp]) ||
			(folder.local
				? updatedAbsFolderPath &&
				  `${LOCAL_FOLDER_ABSFOLDERPATH_PREFIX.substr(1)}${encodeURIComponent(
						updatedAbsFolderPath
				  )}`
				: encodeURIComponent(updatedAbsFolderPath || folderName || folder.id));

		return `/${urlSlug}/${urlPrefix ? encodeURIComponent(urlPrefix) : ''}${urlSuffix}${
			isTag ? '/' + slugs.tags : ''
		}`;
	};

	isFolderActive = url => {
		const { urlSlug, indexFolder = {}, folder, slugs, isTag } = this.props;
		const folderUrl = this.getUrl();

		const urlRegex =
			!folder.local &&
			folder.id === indexFolder.id &&
			new RegExp(`/${urlSlug === slugs.email ? `(?:${urlSlug})?` : urlSlug || ''}/?$`).test(
				window.location.href
			)
				? /(?:)/
				: new RegExp(
						`${folderUrl.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')}(${
							!isTag ? '?!/tags' : '' // if it is not a tag then it should not be followed by /tags
						})($|/${urlSlug === slugs.contacts ? 'new' : ''})` // Add "new" to handle new Contact dialog, If it is not present then on selecting subFolder, App append activeClass to both parent and sub folder.
				  );
		return urlRegex.test(url);
	};

	handleDrop = e => {
		e.preventDefault();

		const data = getDataTransferJSON(e);

		const { dropTargetType, onDrop, folder, folderDrop, onFolderDrop, onRouteOnFolderActive } =
			this.props;
		const sourceFolder = get(data, 'sourceFolder');
		/**
		 * This condition is required because if user drops on a folder
		 * that is not-droppable we need to clear the data of the source Folder
		 * (one case scenario : if user tries to drop a sourcefolder on a not droppable folder
		 * and then at that moment starts dragging mails to drop on that folders even if the mails are droappable
		 * they wont we droppable as state is not cleared and this function i.e onFolderDropFlag
		 * will get executed giving wrong indication to the user)
		 */
		if (data && sourceFolder) {
			if (this.state.dropTarget) {
				folderDrop(sourceFolder, folder);
				this.dropTimer = setTimeout(this.handleDragLeave, DROP_ANIMATION_MAX);
				onRouteOnFolderActive(sourceFolder, folder);
			}
			if (onFolderDrop !== null) onFolderDrop();
			return false;
		}

		e.targetFolder = e.targetList = e.destination = folder;
		e.targetType = e.destinationType = dropTargetType || 'folder';
		if (onDrop !== null) onDrop(e, folder);
		this.setState({ dropped: true });
		this.dropTimer = setTimeout(this.handleDragLeave, DROP_ANIMATION_MAX);
		return false;
	};

	handleDragStart = e => {
		const { view, folder, onFolderDragStart } = this.props;
		setDataTransferJSON(e, {
			data: {
				type: view,
				selected: [folder.id],
				sourceFolder: folder
			},
			itemCount: 1
		});
		e.sourceFolder = folder;
		onFolderDragStart && onFolderDragStart(e.sourceFolder);
	};

	handleDragOver = e => {
		e.preventDefault();

		const { onDrop, dropEffect, onDragOverExpandFolder, folder, folderToDrop } = this.props;

		onDragOverExpandFolder(e.target, folder.id);
		const dropResult = folderToDrop && this.handleFolderDropFlag(folderToDrop, folder);

		e.dataTransfer.dropEffect = dropEffect;

		if ((folderToDrop && dropResult) || (!folderToDrop && onDrop != null)) {
			this.setState({ dropTarget: true, dropped: false });
		}
	};

	handleDragLeave = () => {
		if (this.props.onDrop != null) {
			this.setState({ dropTarget: false, dropped: false });
		}
	};

	/**
	 * Following code is for folder on which user wants to drop
	 * @param {Object} sourceFolder the folder that needs to be moved
	 * @param {Object} destFolder the destinationFolder where user wants to drop
	 * @returns {Boolean} whether Folder is droppable or not
	 */
	handleFolderDropFlag = (sourceFolder, destFolder) => {
		return checkIfFolderDroppable({ sourceFolder, destFolder });
	};

	handleMarkRead = () => {
		const { action, tagAction, folder, isTag, isOffline, afterAction, refresh } = this.props;

		if (isTag) {
			return tagAction(
				{
					...folder,
					unread: 0,
					folderId: folder.id
				},
				TAG_ACTIONS.MARK_AS_READ
			);
		}

		const { id, ownerZimbraId, sharedItemId, local } = folder;

		return action({
			variables: {
				type: 'FolderAction',
				op: 'read',
				isLocal: local,
				id: ownerZimbraId && sharedItemId ? `${ownerZimbraId}:${sharedItemId}` : id // For Shared Folder, we've to use `ownerZimbraId, sharedItemId`
			},
			context: {
				local: local || (typeof process.env.ELECTRON_ENV !== 'undefined' && isOffline)
			}
		}).then(result => {
			//ToDo remove this once handling of no op  done in local storage
			if (local || (typeof process.env.ELECTRON_ENV !== 'undefined' && isOffline)) {
				refresh && refresh();
				afterAction && afterAction(local);
			}

			return result;
		});
	};

	handleExport = ({ absFolderPath, name }) => {
		const { emailAddress } = this.props;
		const downloadDateTime = moment().format('YYYY-MM-DD-HHmmss');
		const value = `/home/${emailAddress}${absFolderPath}?fmt=tgz&recursive=1&filename=${name}-${downloadDateTime}&emptyname=No+Data+to+Export&charset=UTF-8`;
		const uri = this.context.zimbraBatchClient.resolve(value);
		startAttachmentDownloadProcess(uri);
	};

	handleDelete = () => {
		if (this.props.isTag) {
			this.setState({
				showDeleteConfirm: true
			});
		} else {
			const { view, confirmDelete, confirmDeleteList } = this.props;
			view === 'contactGroup' ? confirmDeleteList() : confirmDelete();
		}
	};

	handleRename = () => {
		const { isTag } = this.props;
		// Call appropriate rename mutation based on type of underlying folder.
		this.setState({
			isRenaming: true,
			...(isTag && {
				isRenamingTag: true
			}),
			...(!isTag && {
				renamingInputValue: this.getFolderName()
			})
		});
	};

	handleCloseRename = () => {
		this.setState({ isRenaming: false, renamingInputValue: undefined });
	};

	handleRenameSubmit = name => {
		const {
			folder: { folderId, id, local, userId, owner },
			isSearchFolder,
			action,
			notify: notifyAction,
			view,
			modifyContactList,
			isOffline
		} = this.props;

		if (!this.validateFolderName(name, local)) {
			return;
		}

		if (view === 'contactGroup') {
			modifyContactList({
				groupName: name,
				folderId,
				groupId: id
			}).then(() => {
				this.performRenameSubmitAfterAction(name);
			});
		} else {
			const newName = name.replace(/^[ ]+|[ ]+$/g, '');
			const derivedFolderId = owner && view === CONTACTS_VIEW ? userId : id;

			action({
				variables: {
					name: newName,
					type: 'FolderAction',
					op: 'rename',
					id: derivedFolderId,
					isLocal: local
				},
				context: {
					local: local || (typeof process.env.ELECTRON_ENV !== 'undefined' && isOffline)
				}
			})
				.then(() => {
					this.performRenameSubmitAfterAction(name);
				})
				.catch(err => {
					const errCode = faultCode(err);

					if (errCode && errCode === 'mail.ALREADY_EXISTS') {
						notifyAction({
							failure: true,
							message: (
								<Text
									id={`faults.${errCode}${isSearchFolder ? '_search' : '_folder'}`}
									fields={{ name: newName }}
								/>
							)
						});
					}
				});
		}

		this.setState({ isRenaming: false });
	};

	handleTagModalClose = () =>
		this.setState({
			isRenaming: false
		});

	handleCloseDeleteConfirmModal = () =>
		this.setState({
			showDeleteConfirm: false
		});

	handleDeleteTagAction = () => {
		const { tagAction, folder, urlSlug, view } = this.props;
		tagAction(folder, TAG_ACTIONS.DELETE).then(() => {
			// If active folder is being deleted, redirect to root path of current vertical, else no routing action required
			// Tags doesn't have `absFolderPath` prop. Create one for deleted folder for `isActiveFolder` check
			folder.absFolderPath = `/${folder.name}`;
			if (isActiveFolder(folder, getCurrentUrl(), urlSlug)) {
				route(`/${view === MAIL_VIEW ? '' : urlSlug}`);
			}
		});
		this.handleCloseDeleteConfirmModal();
	};

	performRenameSubmitAfterAction = listName => {
		const { folder, afterAction, urlSlug } = this.props;

		afterAction && afterAction(folder.local);

		const url = getCurrentUrl();

		if (isActiveOrChildFolder(folder, url, urlSlug)) {
			routeToRenamedFolder(folder, url, listName);
		}
	};

	handleEditSearch = () => {
		const { matchesMediaQuery, folder } = this.props;
		!matchesMediaQuery && this.props.hide();
		this.props.setActiveSearch(folder);
		this.props.setShowAdvanced({ show: true });
	};

	handleCreateSubFolder = () => {
		this.setState({ isCreatingSubFolder: true });
	};

	handleCreateSubFolderClose = () => {
		this.setState({ isCreatingSubFolder: false });
	};

	handleCreateSubFolderSubmit = name => {
		const {
			view,
			folder: { id: parentFolderId, local },
			createFolder,
			onToggleExpanded
		} = this.props;

		if (!this.validateFolderName(name, local)) {
			return;
		}

		createFolder({
			name,
			view,
			isLocalFolder: local,
			parentFolderId
		});

		this.setState({ isCreatingSubFolder: false });
		onToggleExpanded(parentFolderId, true);
	};

	handleMoveFolder = (folderToMove = {}) => {
		const { id: destFolderId } = folderToMove;
		const { folder, onRouteOnFolderActive, isOffline } = this.props;
		// TODO: code needs to be refactored when we implement folder move from online folders to local folders
		if (destFolderId === 'isLocalFolder') {
			const {
				onCreateLocalFolder,
				folder: { name },
				handleMoveToLocal
			} = this.props;
			if (!name.match(/^[A-Za-z0-9_\s]+$/)) {
				this.props.notify({
					message: <Text id="notifications.moveFolderSpecialCharWarning" />
				});
			} else {
				onCreateLocalFolder(name).then(() => handleMoveToLocal(folder));
			}
			return;
		}
		this.props
			.action({
				variables: {
					type: 'FolderAction',
					op: 'move',
					id: folder.id,
					isLocal: folder.local,
					...((destFolderId || !folder.local) && {
						// To move to top level, destFolderId will not be present and
						// in case it's local Folder, set local root id from local-client
						folderId: destFolderId || USER_FOLDER_IDS.ROOT
					})
				},
				context: {
					local: folder.local || (typeof process.env.ELECTRON_ENV !== 'undefined' && isOffline)
				}
			})
			.then(() => {
				this.props.afterAction(folder.local);
				onRouteOnFolderActive(folder, folderToMove);
			});
		this.props.onToggleExpanded(destFolderId, true);
	};

	handleDblClick = () => {
		const { isSearchFolder, isRenameAllowed: renameAllowed, isGalFolder } = this.props;
		if (
			isSearchFolder ||
			((renameAllowed || isRenameAllowed)(this.getFolderName()) && !isGalFolder)
		) {
			this.handleRename();
		}
	};

	handleFolderDeleteClick = () => {
		const { view } = this.props;
		if (view === 'contactGroup') {
			this.props.confirmDeleteList();
		} else {
			this.props.confirmDelete();
		}
	};

	ensureFolderObject = folder =>
		typeof folder === 'string' ? { id: folder, name: folder } : folder;

	handleClick = e => {
		const {
			folder,
			onItemClick,
			matchesMediaQuery,
			hide: hideSidebar,
			clearSelected: clearSelectedFolder
		} = this.props;

		const folderObj = this.ensureFolderObject(folder);
		clearSelectedFolder();
		onItemClick && onItemClick(e, { isActive: true, folderObj });
		!matchesMediaQuery && hideSidebar();
	};

	handleExpandClick = ({ id, expanded }, e) => {
		e.stopPropagation();
		e.preventDefault();
		this.props.onToggleExpanded(id, !expanded);
	};

	// having issue with draggable=false with anchor tag in firefox
	// causing the onDragStart to be called even if the draggable=false
	preventDefault = e => e.preventDefault();

	disablePopover = () => {
		if (this.timer) {
			clearTimeout(this.timer);
			delete this.timer;
		}
		this.setState({ isPopoverActive: false });
	};

	enablePopover = () => {
		this.setState(prevState => {
			if (!prevState.isPopoverActive) {
				return { isPopoverActive: true };
			}
		});
	};

	showPopoverWithDelay = () => {
		if (this.timer) {
			clearTimeout(this.timer);
			delete this.timer;
		}
		if (!this.state.isPopoverActive) {
			this.timer = setTimeout(this.enablePopover, HOVER_DURATION);
		}
	};

	static defaultProps = {
		onItemClick: () => {},
		onToggleExpanded: () => {},
		foldersExpanded: {},
		depth: 1,
		menu: null,
		disableCollapse: false,
		isSearchFolder: false
	};

	renderItem = ({ openContextMenu, menu }) => {
		const contextMenu = menu?.props?.menu;
		const {
			foldersExpanded,
			urlSlug,
			badgeProp,
			depth,
			onDrop,
			grouped,
			disableCollapse,
			matchesMediaQuery,
			isSearchFolder,
			hideFolder,
			isOffline,
			onIconClick,
			downloadInProgress,
			isTag,
			icon,
			handleCheck,
			isCheckEnabled,
			inlineCheckBoxStyle,
			folder,
			folderDetails,
			isTagChecked,
			contextMenu: checkEnableContextMenu,
			folderListItemClass,
			showPstLoading,
			folderNameProp = 'name',
			view
		} = this.props;
		const contextMenuOptions = isCheckEnabled
			? checkEnableContextMenu
			: contextMenu && this.showMenuEllipsis();

		const inlineStyle = isTag && {
			backgroundColor: COLORS[folder.color || 0]
		};

		const expanded = folder.id ? foldersExpanded[folder.id.toString()] === true : false;

		const subFolders = getAllSubfolders(folder);
		const isDownloadingInProgress =
			downloadInProgress && downloadInProgress.indexOf(folder.id) >= 0;

		const isActive = isActiveFolder(folder, getCurrentUrl(), urlSlug, false);
		const folderName = this.getFolderName();

		const badge =
			typeof badgeProp === 'function'
				? badgeProp(folder)
				: badgeProp !== false &&
				  folder[
						badgeProp ||
							(folderName === DRAFTS || folderName === OUTBOX ? 'nonFolderItemCount' : 'unread')
				  ];

		const unreadDescendent = get(folder, 'unreadDescendent');

		const isDropTarget =
			onDrop && canMoveMessagesIntoFolders([{ name: folderName, id: folder.id }]).length > 0;

		// Refresh icons are eligible for system inbox folder or it's moving emails
		const showRefreshIcon = onIconClick || isDownloadingInProgress;

		const isDraggable =
			folder.__typename === 'Folder' &&
			!isSearchFolder &&
			!folder.permissions &&
			!isSystemFolder(folder);

		const menuItem = (
			<div
				class={cx(
					style.item,
					folderListItemClass,
					grouped && style.grouped,
					isTag && style.tagGroup,
					badge && style.hasBadge,
					!disableCollapse && subFolders && style.collapsible,
					this.state.dropTarget && style.dropTarget,
					this.state.dropped && style.dropped,
					hideFolder && style.hide,
					showPstLoading && style.disabled
				)}
				data-folder-depth={depth}
				onClick={this.enablePopover}
				onMouseLeave={this.disablePopover}
				onMouseEnter={this.showPopoverWithDelay}
			>
				<Localizer>
					<MenuItem
						draggable={isDraggable}
						{...(!isCheckEnabled && {
							href: isSearchFolder ? this.getSearchURL(folder.query) : this.getUrl(),
							urlSlug,
							activeClass: cx(style.active, style.activeClass),

							isItemActive: this.isFolderActive
						})}
						customClass={cx(
							style.itemLink,
							isCheckEnabled && style.checkedItemLink,
							isTag && style.tagLink,
							folder.broken && style.brokenSymLink
						)}
						innerClass={style.itemInner}
						title={getTranslatedFolderName(folder, folderNameProp)}
						hideDefaultTooltip={!isTag}
						onDragStart={isDraggable ? this.handleDragStart : this.preventDefault}
						onClick={isCheckEnabled ? callWith(handleCheck, folder, true) : this.handleClick}
						{...(!folder.broken &&
							!isCheckEnabled && {
								onDblClick: callWith(this.handleDblClick, menu)
							})}
						{...(isDropTarget &&
							folder.droppable && {
								onDragOver: this.handleDragOver,
								onDragEnter: this.handleDragOver,
								onDragLeave: this.handleDragLeave,
								onDrop: this.handleDrop
							})}
					>
						{!disableCollapse && subFolders && (
							<CollapsibleControl
								collapsed={!expanded}
								onClick={callWith(
									this.handleExpandClick,
									{
										id: folder.id,
										expanded
									},
									true
								)}
								class={cx(
									style.folderCollapsibleControl,
									style[`folderCollapsibleControl--depth${depth}`],
									grouped && style.grouped
								)}
							/>
						)}

						{isCheckEnabled && (
							<ChoiceInput
								checked={
									(isTag && isTagChecked) ||
									(folderDetails ? folderDetails.includes(folder.id) : hasFlag(folder, 'checked'))
								}
								containerClass={cx(!isTag && 'coloredCheckbox')}
								{...(!isTag && {
									inlineStyle: { backgroundColor: inlineCheckBoxStyle(folder) }
								})}
							/>
						)}
						<div
							class={cx(
								style.itemTitle,
								unreadDescendent && style.hasBadge,
								isTag && style.tagName
							)}
							style={inlineStyle}
						>
							{icon}
							{getTranslatedFolderName(folder, folderNameProp)}
						</div>
						{showPstLoading && <Spinner size="md" class={style.pstLoader} />}
						{!isOffline &&
							(folder.externalAccount ? (
								<ImportRefreshIcon
									accountId={get(folder, 'externalAccount.id')}
									{...(isActive &&
										onIconClick && {
											onClick: callWith(
												onIconClick,
												{
													externalAccount: folder.externalAccount
												},
												true
											)
										})}
								/>
							) : (
								<FolderRefreshIcon
									shouldVisible={showRefreshIcon}
									isRefreshing={isDownloadingInProgress}
									{...(onIconClick && {
										onClick: callWith(onIconClick, {}, true)
									})}
								/>
							))}
						{!!badge && <div className={style.badge}>{badge}</div>}
						{!matchesMediaQuery && contextMenuOptions && (
							<Icon
								name="ellipsis-h"
								size="sm"
								onClick={openContextMenu}
								class={style.contextMenuIcon}
							/>
						)}
					</MenuItem>
				</Localizer>

				{matchesMediaQuery && isSearchFolder && (
					<NakedButton
						class={style.folderItemAction}
						onClick={callWith(this.handleFolderDeleteClick, folder)}
					>
						<Icon name="close" size="sm" />
					</NakedButton>
				)}
			</div>
		);

		return (
			<Fragment>
				{!isTag && !hideFolder && folder.__typename !== 'Contact' ? (
					<Popover
						placement="bottom-end"
						anchor="center"
						active={this.state.isPopoverActive}
						classes={{
							containerClass: style.eventTooltipTarget,
							toggleClass: style.eventTooltipTarget,
							popoverClass: style.eventTooltip
						}}
						target={menuItem}
					>
						<FolderInfoTooltip view={view} folder={folder} />
					</Popover>
				) : (
					menuItem
				)}

				{this.state.isCreatingSubFolder && (
					<NewFolder
						class={cx(style.folderInput, grouped && style.grouped)}
						onClose={this.handleCreateSubFolderClose}
						onSubmit={this.handleCreateSubFolderSubmit}
					/>
				)}
			</Fragment>
		);
	};

	render(
		{
			zimbraDumpsterEnabled,
			zimbraFeatureSharingEnabled,
			depth,
			folder,
			folders,
			foldersExpanded,
			confirmEmpty,
			menu,
			grouped,
			localFolders,
			refetchFolders,
			isSearchFolder,
			showFolderShareDialog,
			tags,
			matchesMediaQuery,
			isOffline,
			isTag,
			importFolder,
			view,
			recoverDeletedMail,
			contextMenu,
			isCheckEnabled,
			subFolderClass,
			showPstLoading
		},
		{ isRenaming, renamingInputValue, showDeleteConfirm }
	) {
		folder = this.ensureFolderObject(folder);

		const expanded = folder.id ? foldersExpanded[folder.id.toString()] === true : false;

		let item;

		if (typeof folder.view === 'function') {
			item = createElement(folder.view, { folder, depth });
		} else {
			const {
				handleMoveFolder,
				handleMarkRead,
				handleRename,
				handleDelete,
				handleCreateSubFolder,
				handleEditSearch,
				handleTagModalClose,
				handleExport
			} = this;
			const { Menu = menu } = {
				Menu:
					isCheckEnabled && contextMenu && !isTag ? contextMenu(folder) : this.customContextMenu()
			};

			// For folder list, we replace folder item with rename widget. But for tags edit, we open a separate modal.
			const folderRename =
				isRenaming &&
				(isTag ? (
					<CreateTagModal onClose={handleTagModalClose} tags={tags} tag={folder} />
				) : (
					<FolderInput
						class={cx(grouped && style.grouped)}
						value={renamingInputValue}
						onInput={linkstate(this, 'renamingInputValue')}
						onClose={this.handleCloseRename}
						onSubmit={this.handleRenameSubmit}
					/>
				));
			// Hide folder item when folder rename is visible
			const folderItem = (isTag || !isRenaming) && (
				<ContextMenu
					menu={
						isCheckEnabled && Menu && !isTag ? (
							Menu
						) : (
							<ClosableMenu
								view={view}
								depth={depth}
								menu={Menu}
								folder={folder}
								folders={folders}
								onMarkFolderRead={handleMarkRead}
								onExport={handleExport}
								onImportFolder={importFolder}
								refetchFolders={refetchFolders}
								onEmptyFolder={confirmEmpty}
								{...(zimbraDumpsterEnabled && { onRecoverMail: recoverDeletedMail })}
								onRenameFolder={handleRename}
								onMoveFolder={handleMoveFolder}
								onDeleteFolder={handleDelete}
								onCreateSubFolder={handleCreateSubFolder}
								{...(matchesMediaQuery && { onEditSearch: handleEditSearch })}
								{...(zimbraFeatureSharingEnabled && { onShare: showFolderShareDialog })}
								isSearchFolder={isSearchFolder}
								localFolders={localFolders}
								isOffline={isOffline}
								disableMenu={showPstLoading}
							/>
						)
					}
					render={this.renderItem}
				/>
			);

			const subFolders = getAllSubfolders(folder);
			item = [folderRename, folderItem];

			if (subFolders) {
				item = (
					<div class={cx(style.folderWithSubFolders, subFolderClass)}>
						{item}
						{expanded &&
							subFolders.map(subFolder => (
								<FolderListItem {...this.props} depth={(depth || 1) + 1} folder={subFolder} />
							))}
					</div>
				);
			}
		}

		const folderName = this.getFolderName();
		return (
			<Fragment>
				{item}
				{showDeleteConfirm && (
					<ModalDialog
						title={<Text id="tags.dialogs.deleteTag.title" fields={{ name: folderName }} />}
						actionLabel="buttons.continue"
						onAction={this.handleDeleteTagAction}
						onClose={this.handleCloseDeleteConfirmModal}
					>
						<p>
							<Text id="tags.dialogs.deleteTag.text" fields={{ name: folderName }} />
						</p>
					</ModalDialog>
				)}
			</Fragment>
		);
	}
}

//decorate Menu wiwht
class ClosableMenu extends Component {
	handleMoveFolder = folder => {
		this.props.onMoveFolder && this.props.onMoveFolder(folder);
		this.props.onClose && this.props.onClose();
	};

	render({
		menu: Menu,
		folder,
		folders,
		onMarkFolderRead,
		onExport,
		onImportFolder,
		onEmptyFolder,
		onRenameFolder,
		onDeleteFolder,
		onCreateSubFolder,
		onEditSearch,
		isSearchFolder,
		localFolders,
		onDeleteTag,
		isOffline,
		onShare,
		depth,
		onRecoverMail,
		disableMenu
	}) {
		return (
			Menu &&
			!disableMenu && (
				<Menu
					folder={folder}
					depth={depth}
					folders={folders}
					onMarkFolderRead={onMarkFolderRead}
					onImportFolder={onImportFolder}
					onEmptyFolder={onEmptyFolder}
					onExport={onExport}
					onRenameFolder={onRenameFolder}
					onMoveFolder={this.handleMoveFolder}
					onDeleteFolder={onDeleteFolder}
					onCreateSubFolder={onCreateSubFolder}
					onEditSearch={onEditSearch}
					isSearchFolder={isSearchFolder}
					localFolders={localFolders}
					onDeleteTag={onDeleteTag}
					isOffline={isOffline}
					onShare={onShare}
					onRecoverMail={onRecoverMail}
				/>
			)
		);
	}
}

class ConfirmDeleteDialog extends Component {
	// If this is in the trash or is a search folder,
	// prompt the user to permanently delete it
	shouldPermanentlyDelete = () => {
		const { local, parentFolderId, absFolderPath } = this.props.folder;

		return (
			(!local &&
				(parentFolderId === USER_FOLDER_IDS.TRASH.toString() ||
					absFolderPath.startsWith(`/${TRASH}/`))) ||
			(local &&
				(parentFolderId === LOCAL_FOLDER_IDS.TRASH ||
					absFolderPath.startsWith(`${FS_FOLDERS.mailTrash}${DB_PATH_SEPERATOR}`)))
		);
	};

	isFolderSearchFolder = () => this.props.folder.query;

	performFolderAction = ({
		type = 'FolderAction',
		id = this.props.folder.id,
		op,
		folderId,
		folderPath,
		local = this.props.folder.local
	}) => {
		const { action, isOffline } = this.props;

		return action({
			variables: {
				type,
				id,
				op,
				folderId,
				isLocal: local
			},
			context: {
				local: local || (typeof process.env.ELECTRON_ENV !== 'undefined' && isOffline)
			},
			update: cache => {
				// removing caches of current folder and it's subfolder
				// based on folderPath so that it won't remove cache of other parent folder if it contains same sub folder name
				if ((op === ActionOps.trash || op === ActionOps.delete) && folderPath) {
					if (folderPath.indexOf('/') === 0 && folderPath.length > 1) {
						folderPath = folderPath.slice(1);
						const queries = getSearchInFolderDataIds(cache, folderPath);
						queries.forEach(query => {
							cache.evict({
								id: 'ROOT_QUERY',
								fieldName: 'search',
								args: getVariablesFromDataId(query)
							});
						});
						queries.length && cache.gc();
					}
				}
			}
		});
	};

	handleConfirm = () => {
		const {
			closeDialog,
			folder,
			folder: { name: folderName, absFolderPath: folderPath, local, userId, broken },
			afterAction,
			notify: notifyAction,
			isSearchFolder,
			urlSlug,
			view
		} = this.props;

		const permanently = this.shouldPermanentlyDelete() || broken;

		//when using move opertion for moving to trash-folder creates error in subfolders of sharedFolders
		//move should be used in case for local folders
		this.performFolderAction({
			op: permanently ? ActionOps.delete : ActionOps.trash,
			folderId: local
				? LOCAL_FOLDER_IDS.TRASH
				: permanently
				? undefined
				: USER_FOLDER_IDS.TRASH.toString(),
			local,
			folderPath,
			...(userId && view === CONTACTS_VIEW && { id: userId })
		})
			.then(() => {
				afterAction(local);
				// perform post processing if the deleted folder was search folder
				if (isSearchFolder && !permanently) {
					this.folderActionTimer = setTimeout(() => {
						this.performFolderAction({
							op: ActionOps.delete
						});
					}, DEFAULT_NOTIFICATION_DURATION * 1000);

					this.props.notify({
						message: <DeletedSmartFolder name={folderName} />,
						action: {
							label: <Text id="buttons.undo" />,
							fn: () => {
								this.folderActionTimer && clearTimeout(this.folderActionTimer);
								this.performFolderAction({
									op: ActionOps.move,
									folderId: USER_FOLDER_IDS.ROOT
								}).then(() => {
									this.props.notify({
										message: <MovedSmartFolderMessage name={folderName} />
									});
									afterAction();
								});
							}
						}
					});
				}
			})
			.catch(err => {
				const errCode = faultCode(err);

				if (errCode && errCode === 'mail.ALREADY_EXISTS') {
					notifyAction({
						failure: true,
						message: <Text id={`faults.${errCode}_folder_in_trash`} fields={{ name: folderName }} />
					});
				}
			});

		if (isActiveFolder(folder, getCurrentUrl(), urlSlug)) {
			route(view === MAIL_VIEW ? '/' : `/${urlSlug}`, true);
		}

		closeDialog && closeDialog();
	};

	componentWillUnmount() {
		this.dropTimer && clearTimeout(this.dropTimer);
		this.folderActionTimer && clearTimeout(this.folderActionTimer);
	}
	render(props) {
		return (
			<DeleteFolderDialog
				{...props}
				onConfirm={this.handleConfirm}
				permanent={this.shouldPermanentlyDelete()}
			/>
		);
	}
}

class ConfirmEmptyDialog extends Component {
	handleConfirm = () => {
		const {
			action,
			closeDialog,
			folder,
			refresh,
			urlSlug,
			clearSelected: clearSelectedIds,
			notify: notifyAction,
			isOffline,
			folderNameProp = 'name'
		} = this.props;
		const { id, ownerZimbraId, sharedItemId, name, local } = folder;
		action({
			variables: {
				type: 'FolderAction',
				id: ownerZimbraId && sharedItemId ? `${ownerZimbraId}:${sharedItemId}` : id, // For Shared Folder, we've to use `ownerZimbraId, sharedItemId`,
				op: 'empty',
				isLocal: local,
				recursive: name === TRASH // Recursive mode (empty all items including subfolders) enabled only for TRASH folder
			},
			context: {
				local: local || (typeof process.env.ELECTRON_ENV !== 'undefined' && isOffline)
			}
		}).then(() => {
			if (isActiveFolder(folder, getCurrentUrl(), urlSlug) && refresh) {
				clearSelectedIds();
				route(trimMailFolderViewFromUri(location.pathname));
				refresh();
			} else if (
				refresh &&
				((local && folder.id !== LOCAL_FOLDER_IDS.TRASH.toString()) ||
					(typeof process.env.ELECTRON_ENV !== 'undefined' && isOffline))
			) {
				refresh();
			}
			notifyAction({
				message: <FolderEmptiedMessage folder={folder} folderNameProp={folderNameProp} />
			});
			this.props.afterAction(local);
		});
		closeDialog && closeDialog();
	};

	render(props) {
		return <EmptyFolderDialog {...props} onConfirm={this.handleConfirm} />;
	}
}

// Compose HOCs with `compose` so that `FolderListItem` has the correct
// prop context for nested folders.
const FolderListItem = compose(
	branch(
		({ folder }) => folder.__typename === 'Contact',
		withProps(() => ({
			view: CONTACTS_GROUP_VIEW,
			urlSuffixProp: 'id',
			folderNameProp: 'fileAsStr',
			urlPrefix: CONTACT_GROUP_PREFIX,
			icon: <Icon name="list-ul" size="xs" class={style.contactGroupIcon} />
		}))
	),
	withDialog('confirmDelete', ConfirmDeleteDialog),
	withDialog('confirmDeleteList', DeleteList),
	withDialog('importFolder', ImportModal),
	withDialog('confirmEmpty', ConfirmEmptyDialog),
	withDialog('recoverDeletedMail', Dumpster),
	withDialog('showFolderShareDialog', ShareDialog)
)(BaseFolderListItem);

export default FolderListItem;
