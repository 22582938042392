// eslint-disable-next-line no-unused-vars
import { Component } from 'preact';
import { isCurrentModal } from '../lib/modal-manager';

/**
 * Wraps a component and provides a manner to respond to clicks outside that component.
 */
export default class ClickOutsideDetector extends Component {
	isMouseClickInBaseRect = ({ clientX, clientY }) => {
		const { left, top, width, height } = this.base.getBoundingClientRect();
		if (typeof clientX === 'undefined' || typeof clientY === 'undefined') {
			return true;
		}
		return clientX >= left && clientX <= left + width && clientY >= top && clientY <= top + height;
	};

	handleClick = e => {
		const { target } = e;
		const { onClickOutside, disableOutsideClick, modalId } = this.props;
		if (
			!disableOutsideClick &&
			typeof this.base !== 'undefined' &&
			!this.base.contains(target) &&
			typeof onClickOutside === 'function' &&
			!this.isMouseClickInBaseRect(e)
		) {
			modalId ? isCurrentModal(modalId) && onClickOutside(e) : onClickOutside(e);
		}
	};

	handleBlur = e => {
		const { onClickOutside, disableOutsideClick, modalId } = this.props;
		if (
			!disableOutsideClick &&
			document &&
			document.activeElement &&
			document.activeElement.nodeName === 'IFRAME' &&
			!this.base.contains(document.activeElement) &&
			typeof onClickOutside === 'function'
		) {
			modalId ? isCurrentModal(modalId) && onClickOutside(e) : onClickOutside(e);
		}
	};

	events = onOrOff => {
		const fn = onOrOff === true ? addEventListener : removeEventListener;
		fn('click', this.handleClick, true);
		fn('touchstart', this.handleClick, true);
		fn('blur', this.handleBlur, true);
		fn('contextmenu', this.handleClick, true);
	};

	componentDidMount() {
		this.events(true);
	}

	componentWillUnmount() {
		this.events(false);
	}

	render({ children }) {
		return children;
	}
}
