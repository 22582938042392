import { gql } from '@apollo/client';
import GetFolder from '../queries/folders/get-folder.graphql';
import GetTag from '../queries/tags/tags.graphql';
import SearchQuery from '../queries/search/search.graphql';
import MessageQuery from '../queries/message.graphql';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import castArray from 'lodash-es/castArray';
import { DEFAULT_LIMIT } from '../../constants/search';
import { isUnread } from '../../utils/mail-item';
import update from 'immutability-helper';

/**
 * Given a query and variables, transform the result of reading that query and
 * write it back with the same query and variables
 */
export function mapQuery(client, options, mapCachedToData) {
	try {
		client.writeQuery({
			...options,
			data: mapCachedToData(client.readQuery(options))
		});
		return true;
	} catch (e) {
		return false;
	}
}

function getDataFromCache(data) {
	if (!data.parent) {
		return data.data;
	}
	return { ...data.data, ...getDataFromCache(data.parent) };
}

/**
 * Given a partial dataId and a filter function, find list of full dataIds
 * @param {ApolloCacheProxy} client     The Apollo cache
 * @param {String} [partialDataId]      A part of the dataId, with or without variables
 * @param {Function} [predicate]        A function to find the correct full dataId
 * @returns {String}                    A list of full dataIds including variables if predicate succeeds else return empty array.
 */
export function findDataIds(client, partialDataId = 'ROOT_QUERY', predicate = () => true) {
	const data = client && getDataFromCache(get(client, 'cache.data', get(client, 'data')));

	if (!data) {
		return;
	}

	const dataIdTokens = partialDataId.split('.');
	const numTokens = dataIdTokens.length;
	const normalizedPath = dataIdTokens.slice(0, numTokens - 1).join('.');
	const property = dataIdTokens[numTokens - 1];
	const normalizedData = numTokens > 1 ? get(data, normalizedPath) : data;

	return Object.keys(normalizedData).filter(
		dataId =>
			(!partialDataId || dataId.indexOf(property) !== -1) &&
			predicate(`${normalizedPath ? `${normalizedPath}.` : ''}${dataId}`, normalizedData[dataId])
	);
}

/**
 * Given a partial dataId and a filter function, find a full dataId
 * @param {ApolloCacheProxy} client     The Apollo cache
 * @param {String} [partialDataId]      A part of the dataId, with or without variables
 * @param {Function} [predicate]        A function to find the correct full dataId
 * @returns {String}                    A full dataId including variables
 */
export function findDataId(client, partialDataId = 'ROOT_QUERY', predicate = () => true) {
	return findDataIds(client, partialDataId, predicate)[0];
}

function getCachedData(client, partialDataId = 'ROOT_QUERY', predicate = () => true) {
	const data = client && get(client, 'cache.data.data', get(client, 'data.data'));
	if (!data) {
		return;
	}

	const cacheId = Object.keys(data).filter(
		dataId => (!partialDataId || dataId.indexOf(partialDataId) !== -1) && predicate(dataId)
	)[0];
	return data[cacheId];
}

export function getRootQuery(client) {
	return client && get(client, 'cache.data.data.ROOT_QUERY', get(client, 'data.data.ROOT_QUERY'));
}

/**
 * Given a dataId, parse the variables from it
 * @param {String} dataId    A dataId as returned by {@function findDataId}
 * @returns {Object}         An object containing parsed variables from the dataId
 * <example>
 *   getVariablesFromDataId('ROOT_QUERY.search({ limit: 500, query: "in:Inbox" })') === { limit: 500, query: "in:Inbox" }
 */
export function getVariablesFromDataId(dataId) {
	try {
		return JSON.parse(dataId.replace(/^[^(]+\((.*)\)$/, '$1'));
	} catch (e) {}
}

export function getSearchInFolderDataId(client, folderName) {
	return findDataId(client, 'ROOT_QUERY.search', dataId => dataId.indexOf(folderName) !== -1);
}

export function getSearchInFolderDataIds(client, folderName) {
	return findDataIds(client, 'ROOT_QUERY.search', dataId => {
		const queryJson = getVariablesFromDataId(dataId);
		return queryJson && queryJson.query && queryJson.query.indexOf(folderName) !== -1;
	});
}

function searchInContactPredicate(dataId, folderName, predicatePhrase) {
	if (folderName) {
		const folderSubString = dataId.substring(dataId.indexOf('\\"') + 2, dataId.lastIndexOf('\\"'));
		return folderSubString.startsWith(folderName) && dataId.indexOf(predicatePhrase) !== -1;
	}
	// This cache id contains data for "All Contacts"
	return dataId.indexOf(`"${predicatePhrase}"`) !== -1;
}

function getSearchContactDataId(client, folderName, predicatePhrase) {
	return findDataId(client, 'ROOT_QUERY.search', dataId =>
		searchInContactPredicate(dataId, folderName, predicatePhrase)
	);
}

export function getSearchInFolderVariables(client, folderName) {
	return getVariablesFromDataId(getSearchInFolderDataId(client, folderName));
}

export function getAllFolderVariablesInSearch(client, folderName) {
	const dataIds = findDataIds(
		client,
		'ROOT_QUERY.search',
		dataId => dataId.indexOf(folderName) !== -1
	);
	const variables = [];
	dataIds.forEach(dataId => {
		variables.push(getVariablesFromDataId(dataId));
	});
	return variables;
}

export function getSearchContactVariables(client, folderName, predicatePhrase = 'NOT #type:group') {
	return getVariablesFromDataId(getSearchContactDataId(client, folderName, predicatePhrase));
}

export function getFolderData(
	client,
	partialDataId,
	folderName,
	predicatePhrase = 'NOT #type:group'
) {
	const cachedData = getCachedData(client, partialDataId, dataId =>
		searchInContactPredicate(dataId, folderName, predicatePhrase)
	);
	return cachedData;
}

/**
 * Resolve a folderId or id to a folderName
 * @param {ApolloClient}
 * @param {Object|String|Function}    if object, look up folder id in cache. if string, return string. if function, re-resolve result of invocation.
 */
export function resolveFolderName(client, folder, isTag, local, localContext) {
	if (folder && typeof folder !== 'string') {
		const id = folder.folderId || folder.id;
		if (id) {
			const lookUpFunction = isTag ? findTagInCache : findFolderInCache;
			return get(
				lookUpFunction(client, { id: (id || '').toString() }, local, localContext),
				'name'
			);
		}

		if (typeof folder === 'function') {
			return resolveFolderName(client, folder(), isTag, local, localContext);
		}
	}

	return folder;
}

export function optimisticAddFolderItemCount(
	client,
	folder,
	{ nonFolderItemCount, unread, localContext, local = false },
	isTag
) {
	const folderName = resolveFolderName(client, folder, isTag, local, localContext);

	mapQuery(
		client,
		{
			query: GetFolder,
			variables: { view: null, local },
			context: {
				...localContext
			}
		},
		data =>
			update(data, {
				getFolder: {
					folders: {
						0: {
							folders: {
								$apply: folders =>
									folders &&
									folders.map(cachedFolder => {
										if (cachedFolder.name === folderName) {
											const nonFolderCount =
												parseInt(cachedFolder.nonFolderItemCount, 10) +
												parseInt(nonFolderItemCount, 10);
											return {
												...cachedFolder,
												...(nonFolderItemCount && {
													nonFolderItemCount: nonFolderCount > 0 ? nonFolderCount : 0
												}),
												unread: parseInt(cachedFolder.unread, 10) + parseInt(unread, 10)
											};
										}

										return cachedFolder;
									})
							}
						}
					}
				}
			})
	);
}

export function optimisticAddToFolder(client, folder, messages, isConv) {
	const folderName = resolveFolderName(client, folder);
	messages = castArray(messages);

	// 1. Increment the item count in the given folder
	optimisticAddFolderItemCount(client, folderName, {
		nonFolderItemCount: messages.length,
		unread: messages.filter(isUnread).length
	});

	const filterIds = messages.map(
		({ id, draftId, conversationId }) => (isConv && conversationId) || id || draftId
	);
	const key = isConv ? 'conversations' : 'messages';

	// If the folder does not exist or has no items in it, return only messages
	// If the folder does exist and has data, merge the items together
	optimisticSetSearchFolder(client, folderName, isConv, ({ data, folderExists }) => ({
		[key]:
			!folderExists || !data?.search?.[key]
				? messages
				: messages.concat((data?.search?.[key] || []).filter(({ id }) => !~filterIds.indexOf(id)))
	}));
}

export function optimisticRemoveFromFolder(client, folder, messages, isConv, isTag) {
	const folderName = resolveFolderName(client, folder, isTag);
	messages = castArray(messages);

	// 1. Decrement the item count in the given folder
	optimisticAddFolderItemCount(client, folderName, {
		nonFolderItemCount: messages.length * -1,
		unread: messages.filter(isUnread).length * -1
	});

	const filterIds = messages.map(
		({ id, draftId, conversationId }) => (isConv && conversationId) || id || draftId
	);
	const key = isConv ? 'conversations' : 'messages';

	// Remove messages from the search results for folderName
	optimisticSetSearchFolder(
		client,
		folderName,
		isConv,
		({ data }) => ({
			[key]: (data?.search?.[key] || []).filter(({ id }) => !~filterIds.indexOf(id))
		}),
		isTag
	);
}

export function optimisticSetSearchFolder(client, folder, isConv, mapDataToSearch, isTag) {
	// 1. Get the cache variables for `search({ in: "Inbox", ... })`
	const inboxVariables = getSearchInFolderVariables(client, folder);

	if (!inboxVariables) {
		// TODO: What if user hasnt ever loaded inbox?
		return;
	}

	const folderName = resolveFolderName(client, folder);
	const folderVariables = {
		...inboxVariables,
		types: isConv ? 'conversation' : 'message',
		query: `${isTag ? `tag` : `in`}:"${folderName}"`,
		limit: DEFAULT_LIMIT,
		recip: 2,
		sortBy: 'dateDesc',
		fullConversation: true
	};

	// 2. Find or create search results for Search query
	let searchData,
		folderExists = false;
	try {
		// Try to read the given folderName
		searchData = client.readQuery({
			query: SearchQuery,
			variables: folderVariables
		});
		folderExists = true;
	} catch (e) {
		// If it does not exist, use the Inbox data instead
		searchData = client.readQuery({
			query: SearchQuery,
			variables: inboxVariables
		});
	}

	const search =
		typeof mapDataToSearch !== 'function'
			? mapDataToSearch
			: mapDataToSearch({
					variables: folderVariables,
					data: searchData,
					folderExists
			  });

	// 3. Prepend the given messages to the search results
	if (searchData && search) {
		client.writeQuery({
			query: SearchQuery,
			variables: folderVariables,
			data: {
				search: {
					...searchData.search,
					...search
				}
			}
		});
	}
}

export function optimisticUpdateContactsTag(client, tagName, itemId) {
	const contactVariables = getSearchContactVariables(client, tagName, '"types":"contact"');
	let searchData;
	try {
		// Try to read the given tagName
		searchData = client.readQuery({
			query: SearchQuery,
			variables: contactVariables
		});
	} catch (e) {}

	if (!searchData) return;

	// Return if searchData doesn't have any contacts
	const contactsList = get(searchData, 'search.contacts') || [];
	if (!contactsList.length) return;

	// Remove item from contact list of given tagName
	const updatedList = contactsList.filter(({ id }) => id !== itemId);

	// Not required to write to cache if it has same number of items
	if (contactsList.length !== updatedList.length) {
		client.writeQuery({
			query: SearchQuery,
			variables: contactVariables,
			data: {
				search: {
					...searchData.search,
					contacts: [...updatedList]
				}
			}
		});
	}
}

export function optimisticWriteMessage(client, messages, html, changedToSignAndEnc = false) {
	[].concat(messages).forEach(message => {
		client.writeQuery({
			query: MessageQuery,
			variables: {
				// NOTE: These must be kept in sync with the query used by the MailScreen (src/screens/mail/)
				id: message.id,
				max: 250000,
				html
			},
			data: {
				message
			}
		});

		// Issue: Apollo cache is updating first level of mimeparts only when switching from
		// `Do not sign or encrypt` to `Sign and Encrypt`. Which leads to data security concerns as
		// cache contains staled data (html and plain content type which shouldn't
		// present for encrypted mails) of non-encrypted data.
		// So, Removing staled keys manually only when smimeOpration changes from non-encrypt to encrypt.
		if (changedToSignAndEnc && message.mimeParts) {
			const staledKey = new RegExp(`^MessageInfo:${message.id}.mimeParts.0.mimeParts.`);
			Object.keys(client.data.data).forEach(key => key.match(staledKey) && client.data.delete(key));
		}
	});
}

export function findFolderInCache(client, filter, local = false, localContext = { local: false }) {
	return find(
		client.readQuery({
			query: GetFolder,
			variables: {
				view: null,
				local
			},
			context: {
				...localContext
			}
		}).getFolder.folders[0].folders,
		filter
	);
}

export function findTagInCache(client, filter) {
	return find(
		client.readQuery({
			query: GetTag
		}).getTag,
		filter
	);
}

export function optimisticSetInviteResponse(client, inviteId, calendarItemId, participationStatus) {
	// There could be multiple places (reminders, calendar queries etc.) where a given appt could be present.
	const appointmentDataIds = findDataIds(
		client,
		`CalendarItemHitInfo:${calendarItemId}`,
		(dataId, data) => data.inviteId === inviteId
	);

	if (appointmentDataIds.length) {
		const appointmentFieldsFragment = gql`
			fragment appointmentFields on CalendarItemHitInfo {
				participationStatus
			}
		`;

		appointmentDataIds.forEach(apptDataId => {
			const data = client.readFragment({
				id: apptDataId,
				fragment: appointmentFieldsFragment,
				__typename: 'CalendarItemHitInfo'
			});

			client.writeFragment({
				id: apptDataId,
				fragment: appointmentFieldsFragment,
				data: {
					...data,
					participationStatus
				},
				__typename: 'CalendarItemHitInfo'
			});
		});
	}
}
