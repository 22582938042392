import { Component } from 'preact';
import { Text, MarkupText } from 'preact-i18n';
import cx from 'classnames';
import { Button, Icon, KeyCodes } from '@zimbra/blocks';
import update from 'immutability-helper';
import TextInput from '../../text-input';
import { getInputValue, callWith } from '../../../lib/util';
import style from '../style';
import InlineModalDialog from '../../inline-modal-dialog';
import linkstate from 'linkstate';
import get from 'lodash-es/get';
import { graphql } from '@apollo/client/react/hoc';
import AddRecoveryAddress from '../../../graphql/queries/accounts/account-add-recovery-address-mutation.graphql';
import ValidateRecoveryAddress from '../../../graphql/queries/accounts/account-validate-recovery-address-mutation.graphql';
import ResendRecoveryCode from '../../../graphql/queries/accounts/account-resend-recovery-code-mutation.graphql';
import ResetRecoveryAddress from '../../../graphql/queries/accounts/account-reset-recovery-address-mutation.graphql';
import GetPreferencesQuery from '../../../graphql/queries/preferences/preferences.graphql';
import { connect } from 'react-redux';
import { showNotificationModal as openNotificationModal } from '../../../store/notification-modal/actions';
import withPreference from '../../../graphql-decorators/preferences/get-preferences';

@connect(null, { showNotificationModal: openNotificationModal })
@graphql(AddRecoveryAddress, {
	props: ({ mutate }) => ({
		addRecoveryAddress: ({ address }) =>
			mutate({
				variables: {
					recoveryAccount: address
				},
				refetchQueries: [
					{
						query: GetPreferencesQuery
					}
				]
			})
	})
})
@graphql(ResendRecoveryCode, {
	name: 'resendRecoveryCode'
})
@graphql(ResetRecoveryAddress, {
	props: ({ mutate }) => ({
		resetRecoveryAddress: () =>
			mutate({
				optimisticResponse: {
					__typename: 'Mutation',
					setRecoveryAccount: true
				},
				update: proxy => {
					proxy.writeQuery({
						query: GetPreferencesQuery,
						data: update(proxy.readQuery({ query: GetPreferencesQuery }), {
							getPreferences: {
								zimbraPrefPasswordRecoveryAddress: { $set: null },
								zimbraPrefPasswordRecoveryAddressStatus: { $set: null }
							}
						})
					});
				}
			})
	})
})
@withPreference(({ data: { getPreferences } }) => ({
	accountRecoveryStatus: get(getPreferences, 'zimbraPrefPasswordRecoveryAddressStatus'),
	recoveryAddress: get(getPreferences, 'zimbraPrefPasswordRecoveryAddress')
}))
@graphql(ValidateRecoveryAddress, {
	props: ({ ownProps: { recoveryAddress }, mutate }) => ({
		validateRecoveryAddress: ({ verificationCode }) =>
			mutate({
				variables: {
					recoveryAccount: recoveryAddress,
					recoveryAccountVerificationCode: verificationCode
				},
				refetchQueries: [
					{
						query: GetPreferencesQuery
					}
				]
			})
	})
})
export default class AccountRecoverySettings extends Component {
	state = {
		addingRecovery: false,
		email: null,
		code: ''
	};

	handleModifyRecoveryClick = () => {
		this.setState({ addingRecovery: true });
	};

	handleKeyDown = e => {
		e.keyCode === KeyCodes.CARRIAGE_RETURN && this.handleVerificationSubmit();
	};

	onEmailChange = e => {
		this.setState({ email: getInputValue(e) });
	};

	onCancelHandler = () => {
		this.setState({ addingRecovery: false, showError: false });
	};

	closeModal = () => {
		this.setState({ showVerificationModal: false, code: '' });
	};

	handleAddressSubmit = () => {
		const email = this.state.email;

		email && email !== this.props.recoveryAddress
			? this.props
					.addRecoveryAddress({ address: email })
					.then(() => {
						this.setState({
							addingRecovery: false,
							showVerificationModal: true,
							showError: false,
							maxResends: false
						});
					})
					.catch(() => {
						this.setState({ showError: <Text id="settings.accountRecovery.addressError" /> });
					})
			: this.props.recoveryAddress &&
			  this.setState({ showError: <Text id="settings.accountRecovery.sameAddress" /> });
	};

	handleVerificationSubmit = () => {
		this.props
			.validateRecoveryAddress({ verificationCode: this.state.code })
			.then(() => {
				this.setState({
					showVerificationModal: false,
					code: '',
					recoveryCodeError: false,
					resentCode: false
				});
			})
			.catch(() => {
				this.setState({ recoveryCodeError: true, resentCode: false });
			});
	};

	handleResendVerification = (resentMessage = true) => {
		this.props
			.resendRecoveryCode()
			.then(() => {
				this.setState({
					code: '',
					resentCode: resentMessage,
					recoveryCodeError: false,
					showVerificationModal: true
				});
			})
			.catch(() => {
				this.setState({
					maxResends: <Text id="settings.accountRecovery.maxResendsReached" />,
					recoveryCodeError: false,
					resentCode: false
				});
			});
	};

	handleResetAddress = () => {
		const { resetRecoveryAddress, showNotificationModal } = this.props;
		const email = this.state.email;
		this.setState({ showError: null, email: null });
		resetRecoveryAddress().catch(e => {
			console.error(e);
			showNotificationModal({
				message: e
			});
			this.setState({ email });
		});
	};

	render(
		{ accountRecoveryStatus, recoveryAddress },
		{
			addingRecovery,
			email,
			showVerificationModal,
			code,
			recoveryCodeError,
			showError,
			resentCode,
			maxResends
		}
	) {
		const verified = accountRecoveryStatus === 'verified';

		return (
			<div class={style.accountRecovery}>
				<div class={style.sectionTitle}>
					<Text id="settings.accountRecovery.title" />
				</div>
				{showError && <div class={style.error}>{showError}</div>}
				{maxResends && <div class={style.error}>{maxResends}</div>}
				<p>
					<Text id="settings.accountRecovery.addressToUseText" />
				</p>
				{showVerificationModal && (
					<InlineModalDialog
						title="settings.accountRecovery.verifyRecoveryEmail"
						titleClass={style.inlineModalTitle}
						actionLabel="buttons.continue"
						secondaryActionLabel="settings.accountRecovery.buttons.resend"
						onAction={this.handleVerificationSubmit}
						onSecondaryAction={this.handleResendVerification}
						onClose={this.closeModal}
						showCloseBtn={false}
						closeOnClickOutside
						innerClassName={style.inlineModal}
						wrapperClassName={style.inlineModalWrapper}
						toolbarClassName={style.inlineModalToolbar}
						disablePrimary={!code}
					>
						<div>
							{recoveryCodeError && (
								<div class={cx(style.inlineError, style.danger)}>
									<Text id="settings.accountRecovery.codeError" />
								</div>
							)}
							{resentCode && (
								<div class={cx(style.inlineError, style.success)}>
									<Text id="settings.accountRecovery.resent" />
								</div>
							)}
							{maxResends && <div class={cx(style.inlineError, style.danger)}>{maxResends}</div>}
							<div class={style.verificationMessage}>
								<MarkupText
									id="settings.accountRecovery.verificationText"
									fields={{ recoveryAddress }}
								/>
							</div>
							<TextInput
								name="verificationCode"
								value={code}
								placeholderId="settings.accountRecovery.enterCode"
								onInput={linkstate(this, 'code')}
								required
								wide
								onKeyDown={this.handleKeyDown}
								class={cx(style.codeInput, recoveryCodeError && style.inputError)}
							/>
						</div>
					</InlineModalDialog>
				)}
				{recoveryAddress && !addingRecovery && (
					<div>
						<div class={cx(style.subsection, style.emailAndStatus)}>
							<div class={cx(style.recoveryEmail, !verified && style.pending)}>
								{recoveryAddress}
							</div>
							<div class={cx(style.status)}>
								{verified ? (
									<div class={style.verified}>
										<Icon class={style.statusIcon} name="check-circle" />
										<Text id="settings.accountRecovery.verified" />
									</div>
								) : (
									<div class={style.pending}>
										<Icon class={style.statusIcon} name="warning" />
										<Text id="settings.accountRecovery.pending" />
									</div>
								)}
							</div>
						</div>
						<div class={style.actionSection}>
							<div class={style.modifyButtons}>
								<Button
									class={style.noLeftMargin}
									onClick={this.handleModifyRecoveryClick}
									styleType="primary"
									brand="primary"
								>
									<Text id="buttons.change" />
								</Button>
								{verified ? (
									<Button onClick={this.handleResetAddress} styleType="text">
										<Text id="settings.accountRecovery.buttons.reset" />
									</Button>
								) : (
									<Button onClick={callWith(this.handleResendVerification, false)} styleType="text">
										<Text id="settings.accountRecovery.buttons.resend" />
									</Button>
								)}
							</div>
						</div>
					</div>
				)}
				{(!recoveryAddress || addingRecovery) && (
					<form action="javascript:" onSubmit={this.handleAddressSubmit}>
						<div>
							<TextInput
								name="email"
								type="email"
								onChange={this.onEmailChange}
								value={email || recoveryAddress}
								placeholderId="settings.emailPlaceholder"
								required
								wide
								class={style.emailInput}
							/>
						</div>
						<div>
							<Button class={style.noLeftMargin} type="submit" styleType="primary" brand="primary">
								<Text id="buttons.continue" />
							</Button>
							{recoveryAddress && (
								<Button onClick={this.onCancelHandler}>
									<Text id="buttons.cancel" />
								</Button>
							)}
						</div>
					</form>
				)}
			</div>
		);
	}
}
