import { Component, Fragment } from 'preact';
import { Button, KeyCodes, VerticalList } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import linkState from 'linkstate';
import TextInput from '../../text-input';
import { isValidEmail } from '../../../lib/util';
import isValidDomain from 'is-valid-domain';
import ErrorAlert from '../../error-alert';
import style from '../style';

export default class DomainAddressList extends Component {
	state = {
		inputAddress: '',
		errorTextId: null
	};

	handleKeyDown = e => {
		e.keyCode === KeyCodes.CARRIAGE_RETURN && this.handleAdd();
	};

	handleAdd = () => {
		const {
			onUpdateList,
			list,
			emailOnly,
			preventSelfEmail,
			ownerEmails,
			selfEmailPreventErrorId,
			errorId
		} = this.props;
		const { inputAddress } = this.state;

		if (list.indexOf(inputAddress) !== -1) {
			return;
		}

		if (preventSelfEmail && ownerEmails.includes(inputAddress)) {
			this.setState({ errorTextId: selfEmailPreventErrorId });
			return;
		}

		if (isValidEmail(inputAddress) || (!emailOnly && isValidDomain(inputAddress))) {
			const updatedList = list.concat(inputAddress);
			onUpdateList(updatedList);
			this.setState({ inputAddress: '', errorTextId: null });
		} else {
			this.setState({ errorTextId: errorId });
		}
	};

	handleRemove = address => {
		const { onUpdateList, list } = this.props;
		const updatedList = list.filter(e => e !== address);
		onUpdateList(updatedList);
	};

	render({ list, placeholderId }, { inputAddress, errorTextId }) {
		return (
			<Fragment>
				{errorTextId && (
					<ErrorAlert class={style.domainAddressListError}>
						<Text id={errorTextId} />
					</ErrorAlert>
				)}

				<div class={style.inputGroup}>
					<TextInput
						placeholderId={placeholderId}
						class={style.textInput}
						value={inputAddress}
						onChange={linkState(this, 'inputAddress')}
						wide
						onKeyDown={this.handleKeyDown}
					/>
					<Button
						class={style.subsectionBodyButton}
						onClick={this.handleAdd}
						disabled={!inputAddress.trim().length}
					>
						<Text id="buttons.add" />
					</Button>
				</div>

				<VerticalList items={list} onRemoveItem={this.handleRemove} />
			</Fragment>
		);
	}
}
