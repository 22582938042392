import { graphql } from '@apollo/client/react/hoc';
import TagsQuery from '../../graphql/queries/tags/tags.graphql';

export default function withTags(config = {}, mapProps) {
	const { options: { fetchPolicy = 'cache-and-network', ...restOptions } = {}, ...restConfig } =
		config;

	return graphql(TagsQuery, {
		...restConfig,
		options: ({ isOffline }) => ({
			...restOptions,
			fetchPolicy: isOffline ? 'cache-only' : fetchPolicy
		}),
		props: result => mapProps(result)
	});
}
