import { Component } from 'preact';
import { Text, Localizer } from 'preact-i18n';
import { writeText } from 'clipboard-polyfill/text';
import { connect } from 'react-redux';
import { notify } from '../../store/notifications/actions';
import ActionMenu, { DropDownWrapper } from '../action-menu';
import ActionMenuItem from '../action-menu-item';
import ActionMenuGroup from '../action-menu-group';
import s from './style.less';

@connect(null, { notify })
export default class ContactCardClipboardMenu extends Component {
	state = {
		showEditModal: false
	};

	copy(text) {
		writeText(text).then(() => {
			this.props.notify({
				message: <Text id="contacts.hoverCard.COPIED_TOAST" />
			});
		});
	}

	handleCopyAddress = () => {
		this.copy(this.props.email);
	};

	handleCopyDetails = () => {
		const { name, email, jobDescription, phone } = this.props;

		this.copy([name, jobDescription, email, phone].filter(Boolean).join('\n'));
	};

	toggleActionMenu = active => {
		const { toggleActionMenu } = this.props;
		typeof toggleActionMenu === 'function' && toggleActionMenu(active);
	};

	render({ title }) {
		return (
			<div>
				<Localizer>
					<ActionMenu
						class={s.actionMenu}
						actionButtonClass={s.menuButton}
						popoverClass={s.menu}
						corners="all"
						icon="copy-to-clipboard"
						iconSize="sm"
						arrow={false}
						onToggle={this.toggleActionMenu}
						monotone
						iconOnly
						into={false}
						anchor="end"
						placement="top"
						title={title}
						hoverDuration={50}
					>
						<DropDownWrapper>
							<ActionMenuGroup>
								<ActionMenuItem onClick={this.handleCopyAddress}>
									<Text id="contacts.hoverCard.COPY_ADDRESS" />
								</ActionMenuItem>
								<ActionMenuItem onClick={this.handleCopyDetails}>
									<Text id="contacts.hoverCard.COPY_DETAILS" />
								</ActionMenuItem>
							</ActionMenuGroup>
						</DropDownWrapper>
					</ActionMenu>
				</Localizer>
			</div>
		);
	}
}
