import { JUNK } from '../../constants/folders';

// Search query variables for dumpster search request
export const dumpsterSearchVariable = (sortBy, searchQuery = '') => ({
	inDumpster: true,
	query: `-in:/${JUNK} ${searchQuery}`,
	sortBy,
	types: 'message',
	limit: 100,
	recip: 0,
	offset: 0,
	needExp: true
});
