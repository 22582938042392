import { useSaveDocumentMutation } from '../../../hooks/briefcase';
import { useCallback } from 'preact/hooks';

const useMakeCurrentVersion = selectedItems => {
	const [saveDocument] = useSaveDocumentMutation();
	const isEarliearVersionSelected =
		selectedItems.length === 1 &&
		selectedItems[0].isLatest === false &&
		selectedItems[0].current !== true;

	const handlerMakeCurrentVersion = useCallback(() => {
		let { id, version, latestVersion } = selectedItems[0];
		id = id.split('_')[0];
		saveDocument({
			variables: {
				document: {
					id,
					version: latestVersion,
					document: {
						id,
						version
					}
				}
			}
		});
	}, [saveDocument, selectedItems]);

	return {
		isEarliearVersionSelected,
		handlerMakeCurrentVersion
	};
};

export default useMakeCurrentVersion;
