import { Component } from 'preact';
import { Icon } from '@zimbra/blocks';
import memoize from 'lodash-es/memoize';
import md5 from 'tiny-js-md5';
import { getAttachedImageUrl, getName } from '../../utils/contacts';
import clientConfiguration from '../../enhancers/client-config';
import getContext from '../../lib/get-context';
import { getEmail } from '../../lib/util';
import get from 'lodash-es/get';
import cx from 'classnames';
import style from './style';
import { withPropsOnChange } from 'recompose';
import appConfiguration from '../../enhancers/app-config';

const getAvatar = memoize(
	email => `https://www.gravatar.com/avatar/${md5(email.toLowerCase())}?s=200&d=blank`
);
@clientConfiguration('routes.slugs, enableGravatarAccess')
@appConfiguration('zimbraOrigin')
@getContext(({ zimbraBatchClient }) => ({ zimbraBatchClient }))
@withPropsOnChange(
	['profileImageURL', 'showInitials', 'email', 'contact'],
	({
		contact = {},
		profileImageURL, // Used for photoUploadModal
		thumbnailBase64, // Directly pass base64 of profile pic
		email,
		zimbraOrigin,
		zimbraBatchClient
	}) => {
		const attrs = get(contact, 'attributes') || get(contact, '_attrs') || {};
		const name = (attrs && getName(attrs)) || '';

		return {
			name,
			initials: (name.match(/\b[A-Z]/g) || []).join(''),
			emailAddress: getEmail(email || attrs.email),
			thumbnailBase64: get(attrs, 'thumbnailPhoto') || thumbnailBase64,
			imageURL:
				profileImageURL ||
				(get(contact, 'attributes')
					? getAttachedImageUrl(contact, zimbraOrigin, zimbraBatchClient)
					: null)
		};
	}
)
export default class Avatar extends Component {
	render({
		showInitials = false,
		initials,
		contact,
		mode,
		thumbnailBase64,
		emailAddress,
		imageURL,
		class: cls,
		enableGravatarAccess
	}) {
		const { zimbraCalResType, isGroup } = contact || {};
		return (
			<div class={cx(style.avatar, mode === 'contain' && style.contain, cls)}>
				{showInitials && initials ? (
					<span class={style.initials}>{initials}</span>
				) : (
					<Icon
						name={zimbraCalResType === 'Location' ? 'location' : isGroup ? 'users' : 'user'}
						class={style.default}
					/>
				)}
				{imageURL ? (
					<div class={style.inner} style={{ backgroundImage: `url(${imageURL})` }} />
				) : thumbnailBase64 ? (
					<div
						class={style.inner}
						style={{ backgroundImage: `url(${`data:;base64,` + thumbnailBase64})` }}
					/>
				) : (
					<div
						class={style.inner}
						style={
							emailAddress &&
							enableGravatarAccess && { backgroundImage: `url(${getAvatar(emailAddress)})` }
						}
					/>
				)}
			</div>
		);
	}
}
