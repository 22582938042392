import { useContext } from 'preact/hooks';
import { TrackingContext } from '../context/tracking';

export function withTracking() {
	return function (Child) {
		return function Config(props) {
			const config = useContext(TrackingContext);

			return <Child {...props} {...config} />;
		};
	};
}

export default withTracking;
