import find from 'lodash-es/find';
import { setDefaultReminderValue, getReminderInterval } from './calendar';

function getInterval(preferredTime) {
	const remindValue = setDefaultReminderValue(preferredTime);
	if (remindValue === 'never') {
		return;
	}
	return getReminderInterval(remindValue);
}

export function newAlarm(
	{ action = 'DISPLAY', attendees = null, interval, zimbraPrefCalendarApptReminderWarningTime } = {
		action: 'DISPLAY',
		attendees: null,
		interval,
		zimbraPrefCalendarApptReminderWarningTime
	}
) {
	let alarm;
	const defaultInterval = getInterval(zimbraPrefCalendarApptReminderWarningTime);
	if (defaultInterval) {
		alarm = {
			action,
			trigger: {
				relative: {
					...(interval || defaultInterval),
					relatedTo: 'START',
					negative: true
				}
			}
		};
	}
	if (attendees) {
		alarm.attendees = attendees;
	}

	return alarm;
}

export function cloneAlarm(alarm, { action }) {
	return {
		...alarm,
		action
	};
}

export function hasEmailAlarm(alarms) {
	return !!find(alarms, a => a.action === 'EMAIL');
}

export function hasDisplayAlarm(alarms) {
	return !!find(alarms, a => a.action === 'DISPLAY');
}

/**
 * @param {Object} Calendar event object
 * @param {Array<ID>} editableCalendarsIDs - IDs' of own calenders and other calendar (including shared ones) having write permission.
 *
 * @returns {Boolean} whether or not the given Calendar event is writable or not.
 */
export function isEventWritable({ permissions, folderId, class: eventType }, editableCalendarsIDs) {
	const writePermission = permissions && permissions.includes('w');

	return (
		editableCalendarsIDs.includes(folderId) &&
		(!permissions ||
			(eventType === 'PRI' && permissions.includes('p') && writePermission) ||
			(eventType === 'PUB' && writePermission))
	);
}

/**
 * @param {Object} Calendar event object
 * @param {Array<ID>} editableCalendarsIDs - IDs' of own calenders and other calendar (including shared ones) having write permission.
 *
 * @returns {Boolean} whether or not the given Calendar event is deletable or not.
 */
export function isEventDeletable(
	{ permissions, folderId, class: eventType },
	editableCalendarsIDs
) {
	const deletePermission = permissions && permissions.includes('d');

	return (
		editableCalendarsIDs.includes(folderId) &&
		(!permissions ||
			(eventType === 'PRI' && permissions.includes('p') && deletePermission) ||
			(eventType === 'PUB' && deletePermission))
	);
}
