import { Fragment } from 'preact';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useCallback, useState } from 'preact/hooks';
import { Localizer, Text } from 'preact-i18n';

import s from '../style.less';
import { callWith } from '@zimbra/util/src/call-with';
import FormGroup from '../../../form-group';
import AlignedLabel from '../../../aligned-form/label';
import AddressField from '../../../address-field';
import ZimletSlot from '../../../zimlet-slot';
import LocationPicker from '../../../location-picker-modal';
import { addressFromContact } from '../../../../utils/contacts';

import { getParsedAppointmentData } from '../../../../store/calendar/selectors';
import { useAccountInfo } from '../../../../hooks/account-info';

export const LocationChooser = (
	{
		tabId,
		showToAddressField,
		disabledElement,
		isForwardInvite,
		isProposeTime,
		handleLocationChange,
		validateLocationToken,
		renderVideoZimletSlot
	},
	context
) => {
	const { data } = useAccountInfo();
	const zimbraFeatureGalEnabled = data?.accountInfo?.attrs?.zimbraFeatureGalEnabled;

	const { event, notes, locations, isCustomRecurringEvent, repeatValue } = useSelector(state => ({
		event: getParsedAppointmentData(state, 'event', tabId),
		notes: getParsedAppointmentData(state, 'notes', tabId),
		locations: getParsedAppointmentData(state, 'locations', tabId),
		isCustomRecurringEvent: getParsedAppointmentData(state, 'isCustomRecurringEvent', tabId),
		repeatValue: getParsedAppointmentData(state, 'repeatValue', tabId)
	}));

	const [showLocationPicker, setShowLocationPicker] = useState(false);

	const handleLocationPickerChange = useCallback(
		newLocation => {
			const [floatingLocation] = locations.filter(l => typeof l === 'string');
			handleLocationChange({
				value: newLocation.map(addressFromContact).concat(floatingLocation || [])
			});
			setShowLocationPicker(false);
		},
		[handleLocationChange, locations]
	);

	const onToggleLocationPicker = useCallback(value => {
		setShowLocationPicker(value);
	}, []);

	return (
		<Fragment>
			{renderVideoZimletSlot &&
				context.zimlets.isSlotActive('slot::calendar-appointment-edit-video-call') && (
					<FormGroup>
						<AlignedLabel
							class={s.alignedLabel}
							align="left"
							textId="calendar.editModal.fields.videoCall"
						/>
						<ZimletSlot
							name="calendar-appointment-edit-video-call"
							notes={notes}
							locations={locations}
							event={event}
							isCustomRecurringEvent={isCustomRecurringEvent}
							repeatValue={repeatValue}
							handleLocationChange={handleLocationChange}
							tabId={tabId}
						/>
					</FormGroup>
				)}
			{showLocationPicker && zimbraFeatureGalEnabled && (
				<LocationPicker
					start={event.start}
					end={event.end}
					locations={locations.map(({ address }) => address)}
					onSave={handleLocationPickerChange}
					onClose={callWith(onToggleLocationPicker, false)}
				/>
			)}
			{!showToAddressField && (
				<FormGroup>
					<AlignedLabel class={s.alignedLabel} align="left">
						<Localizer>
							{zimbraFeatureGalEnabled ? (
								<a
									href="javascript:"
									aria-label={<Text id="calendar.editModal.fields.location" />}
									onClick={callWith(onToggleLocationPicker, true)}
								>
									<Text id="calendar.editModal.fields.location" />
								</a>
							) : (
								<Text id="calendar.editModal.fields.location" />
							)}
						</Localizer>
					</AlignedLabel>
					<AddressField
						class={cx(s.addressField, disabledElement)}
						value={locations}
						onChange={handleLocationChange}
						validateToken={validateLocationToken}
						type="resource"
						isLocation
						formSize
						disabled={isForwardInvite || isProposeTime}
					/>
					<ZimletSlot
						name="calendar-appointment-edit-location"
						locations={locations}
						event={event}
						handleLocationChange={handleLocationChange}
						tabId={tabId}
						notes={notes}
					/>
				</FormGroup>
			)}
		</Fragment>
	);
};
