import { PureComponent } from 'preact/compat';
import { IntlProvider } from 'preact-i18n';
import { withProps } from 'recompose';
import Loader from '../components/app-shell-loader';
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '../constants/locale';
import moment from 'moment';
import { connect } from 'react-redux';
import { setLocale } from '../store/locale/actions';
import { defaultLocaleFormat, getDefaultDateFormat } from '../utils/locale';

/**
 * Map language fallbacks for locales that don't appear in SUPPORTED_LOCALES.
 * e.g. if browser asks for 'fr', use 'fr-fr'.
 */
/* Indonesian locale is received 'in' from server but needs to be changed to 'id' which is the general format.*/
const localeMap = {
	en: 'en_US',
	fr: 'fr_FR',
	in: 'id',
	no: 'nn_NO'
};

/**
 * Wraps child components in `IntlProvider` which will provide intl definition to all child components.
 */

export default function withIntlWrapper() {
	const isSupportedLocale = locale => {
		// Check for supported locales, ie en_US
		if (SUPPORTED_LOCALES.find(el => el === normalizeToZimbraLocale(locale))) {
			return true;
		}

		// Some browsers gives fr locale when french is selected instead of fr_FR
		// So check mapping first and change locale code based on that
		if (localeMap[locale]) {
			return true;
		}
	};

	// Locale name has differences between moment and zimbra server, so normalize it
	const normalizeToMomentLocale = locale => {
		switch (locale) {
			case 'pt_BR':
			case 'zh_CN':
			case 'zh_HK':
			case 'zh_TW':
				return locale.toLowerCase().replace('_', '-');
			case 'en_US':
			case 'fr_FR':
			case 'nn_NO':
				return locale.split('_')[0];
			default:
				return locale;
		}
	};

	// Split locale string (languageCode-countryCode) by '-' and concat it after converting countryCode to uppercase.
	const normalizeToZimbraLocale = locale => {
		const localeParts = locale.split('-');
		return (
			(localeParts[1] && localeParts[0].concat('_', localeParts[1].toUpperCase())) || localeParts[0]
		);
	};

	return function (Child) {
		@connect(({ locale }) => ({ selectedLocale: locale }), {
			setLocale
		})
		@withProps(({ zimbraPrefLocale }) => {
			let locale = (
				(zimbraPrefLocale && [].concat(zimbraPrefLocale)) ||
				navigator.languages ||
				[].concat(navigator.language)
			).find(isSupportedLocale);

			if (localeMap[locale]) {
				locale = localeMap[locale];
			}

			// If we don't have any supported locale then use default locale
			// Else make sure locale value is as expected: i.e country code is in uppercase - 'en_US'
			locale = locale ? normalizeToZimbraLocale(locale) : DEFAULT_LOCALE;

			return {
				defaultLocale: DEFAULT_LOCALE,
				locale
			};
		})
		class IntlWrapper extends PureComponent {
			state = {
				definition: null,
				defaultDefinition: null,
				momentLocale: null,
				momentLocaleConfig: null
			};

			// Import custom date/time format for moment which is defined by us
			importMomentLocaleConfig = locale =>
				import(
					/* webpackMode: "lazy", webpackChunkName: "zimbra-locales/moment-locale-config-[request]" */
					`../intl/locale/${locale}.js`
				).then(({ default: momentLocaleConfig }) => momentLocaleConfig);

			// Import translation files used for all strings in the app
			importLocale = locale =>
				import(
					/* webpackMode: "lazy", webpackChunkName: "zimbra-locales/locale-[request]" */
					`../intl/${locale}.json`
				).then(({ default: definition }) => definition);

			// Import moment's translation files for language specific date/time formats
			importMomentLocale = locale => {
				// In case of en_US, we don't need to load moment locale as it is already part of default package
				if (locale === this.props.defaultLocale) {
					return Promise.resolve(normalizeToMomentLocale(locale));
				}

				const importLocale = lc =>
					import(
						/* webpackMode: "lazy", webpackChunkName: "zimbra-locales/moment-locale-[request]" */
						`moment/dist/locale/${lc}`
					);

				const normalizedLocale = normalizeToMomentLocale(locale);

				return importLocale(normalizedLocale)
					.then(() => normalizedLocale)
					.catch(error => {
						console.warn(`Error loading moment locale for ${normalizedLocale}`, error);

						const langCode = locale.split('_')[0];
						return importLocale(langCode).then(() => langCode);
					});
			};

			loadLocale = ({ locale, defaultLocale }, loadDefaultLocale) => {
				const allPromises = [];
				const { setLocale: storeLocale } = this.props;

				// Remove current state values, so we can show a loader while we load locale files
				!loadDefaultLocale &&
					this.setState({
						definition: null,
						momentLocale: null,
						momentLocaleConfig: null
					});

				// Load en_US locale which will be used as fallback strings,
				// when particular locale doesn't have all strings
				allPromises.push(loadDefaultLocale && this.importLocale(defaultLocale));
				allPromises.push(this.importMomentLocaleConfig(locale));
				// If passed locale is same as default locale then no need to do anything extra
				if (locale !== defaultLocale) {
					// Load locale, containing all translated strings
					allPromises.push(this.importLocale(locale));

					// Load moment locale
					allPromises.push(this.importMomentLocale(locale));
				}

				Promise.all(allPromises)
					.then(
						([
							defaultDefinition,
							momentLocaleConfig,
							definition = null,
							momentLocale = normalizeToMomentLocale(locale)
						]) => {
							// Store locale in redux store.
							storeLocale(locale);

							// Put definition object in state which will be passed to child component
							this.setState({
								definition,
								...(loadDefaultLocale && { defaultDefinition }),
								momentLocale,
								momentLocaleConfig
							});
						}
					)
					.catch(err => {
						console.error(`Error loading translations for ${locale}`, err);
					});
			};

			componentDidMount() {
				this.loadLocale(this.props, true);
			}

			componentWillReceiveProps(nextProps) {
				const { locale: nextLocale } = nextProps,
					{ locale } = this.props;

				// check if the active locale has changed, then fetch & apply the corresponding locale data.
				if (nextLocale !== locale) {
					this.loadLocale(nextProps);
				}
			}

			render(
				{ zimbraPrefCalendarFirstDayOfWeek, zimbraPrefTimeFormat, zimbraPrefDateFormat, ...props },
				{ definition, defaultDefinition, momentLocale, momentLocaleConfig }
			) {
				// Show loader till all locale data is not loaded
				if (!momentLocale) {
					return <Loader />;
				}

				moment.locale(momentLocale);
				if (momentLocaleConfig) {
					moment.defineLocale(
						`${momentLocale}_custom`,
						momentLocaleConfig(
							momentLocale,
							zimbraPrefCalendarFirstDayOfWeek,
							zimbraPrefTimeFormat || defaultLocaleFormat(),
							zimbraPrefDateFormat || getDefaultDateFormat(momentLocale),
							deepAssign(
								definition?.settings?.general?.dateFormats,
								defaultDefinition?.settings?.general?.dateFormats
							)
						)
					);
				}

				return (
					<IntlProvider definition={definition}>
						<IntlProvider definition={defaultDefinition}>
							<Child {...props} />
						</IntlProvider>
					</IntlProvider>
				);
			}
		}

		return IntlWrapper;
	};
}

/** A simpler Object.assign
 *  based on https://github.com/synacor/preact-i18n
 *  @private
 */
function assign(obj, props) {
	for (const i in props) {
		if (Object.prototype.hasOwnProperty.call(props, i)) {
			obj[i] = props[i];
		}
	}
	return obj;
}

/** Recursively copy keys from `source` to `target`, skipping truthy values already in `target`.
 *  based on https://github.com/synacor/preact-i18n
 *  It is simplified because it is used onlyt for dateFormats.
 *	@private
 */
function deepAssign(target = {}, source) {
	const out = assign({}, target);
	for (const i in source) {
		if (Object.prototype.hasOwnProperty.call(source, i)) {
			out[i] = target[i] || source[i];
		}
	}
	return out;
}
