export const SORT_BY = {
	dateDesc: 'dateDesc',
	dateAsc: 'dateAsc',
	attachDesc: 'attachDesc', // attachments first
	attachAsc: 'attachAsc', // attachments last
	flagDesc: 'flagDesc', // is flagged / starred
	flagAsc: 'flagAsc',
	nameAsc: 'nameAsc', // from address / sender
	nameDesc: 'nameDesc',
	rcptAsc: 'rcptAsc', // to address / recipient
	rcptDesc: 'rcptDesc',
	subjAsc: 'subjAsc', // subject
	subjDesc: 'subjDesc',
	readDesc: 'readDesc',
	readAsc: 'readAsc',
	sizeAsc: 'sizeAsc',
	sizeDesc: 'sizeDesc'
};
export const DEFAULT_SORT = SORT_BY.dateDesc;
export const DEFAULT_LIMIT = 100;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const SEARCH_TYPE = {
	contacts: 'contacts',
	mail: 'mail'
};
