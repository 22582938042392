import { graphql } from '@apollo/client/react/hoc';
import CreateContactMutation from '../../graphql/queries/contacts/create-contact.graphql';
import ModifyContactMutation from '../../graphql/queries/contacts/modify-contact.graphql';
import AutoCompleteQuery from '../../graphql/queries/contacts/auto-complete.graphql';
import GetContact from '../../graphql/queries/contacts/get-contact.graphql';
import {
	getAutoSuggestionQueryVariables,
	getAllGetContactQueryVariables
} from '../../graphql/utils/graphql-variables';

const contactMutationFactory = (mutationName, propName) => () =>
	graphql(mutationName, {
		props: ({ mutate, result: { client } }) => ({
			[propName]: contact =>
				mutate({
					variables: {
						contact
					},
					refetchQueries: () => [
						...getAutoSuggestionQueryVariables(client.cache).map(cacheVars => ({
							query: AutoCompleteQuery,
							variables: cacheVars
						})),
						...getAllGetContactQueryVariables(client.cache).map(cacheVars => ({
							query: GetContact,
							variables: cacheVars
						}))
					]
				})
		})
	});

export const withCreateContact = contactMutationFactory(CreateContactMutation, 'createContact');
export const withModifyContact = contactMutationFactory(ModifyContactMutation, 'modifyContact');
