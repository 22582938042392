export const screenXs = 480;
export const screenSm = 769;
export const screenMd = 1025;
export const screenLg = 1300;

export const screenXsMax = screenSm - 1;
export const screenSmMax = screenMd - 1;
export const screenMdMax = screenLg - 1;

export const minWidth = px => `(min-width: ${px}px)`;
export const maxWidth = px => `(max-width: ${px}px)`;
