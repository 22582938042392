import moment from 'moment';

export default function inviteDate(start, end) {
	const startDate = moment(start),
		endDate = moment(end),
		sameDay = startDate.isSame(endDate, 'day'),
		separator = sameDay ? ' to ' : ' - ';

	return `${startDate.format('LLLL')}${separator}${endDate.format(sameDay ? 'LT' : 'LLLL')}`;
}
