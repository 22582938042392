import { Component } from 'preact';
import cx from 'classnames';
import style from './style';

// eslint-disable-next-line react/prefer-stateless-function
export default class SmartSpinner extends Component {
	render({ block, ...props }) {
		const Child = block ? BlockSpinner : Spinner;

		return <Child {...props} />;
	}
}

function Spinner({ size = 'sm', ...props }) {
	return <span {...props} class={cx(style.spinner, props.class, style[size])} />;
}

function BlockSpinner(props) {
	return (
		<div class={cx(style.blockSpinner, props.class)}>
			<div>
				<Spinner {...props} class={style.innerSpinner} />
			</div>
		</div>
	);
}
