import cx from 'classnames';
import { useSelector } from 'react-redux';

import MenuItem from '../menu-item';

import s from './style.less';
function stopPropagation(e) {
	e.stopPropagation();
}

const ActionMenuItem = ({
	class: cls,
	className,
	disabled,
	href,
	iconClass,
	onClick,
	nonOfflineDesktopFeature = false,
	narrow,
	...rest
}) => {
	const isOffline = useSelector(state => state?.network?.isOffline);
	const isOfflineDesktopApp = isOffline && typeof process.env.ELECTRON_ENV !== 'undefined';

	const isDisabled = disabled || (nonOfflineDesktopFeature && isOfflineDesktopApp);

	return (
		<MenuItem
			{...rest}
			class={cx(s.item, className, cls, narrow && s.narrow, isDisabled && s.disabled)}
			iconClass={cx(s.icon, iconClass)}
			onClick={isDisabled ? stopPropagation : onClick}
			disabled={isDisabled}
			{...(href && !isDisabled && { href })}
		/>
	);
};

export default ActionMenuItem;
