import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';
import update from 'immutability-helper';

const initialState = {
	verticals: {},
	showAdvanced: false,
	searchQuery: ''
};

const generateVertical = (
	prevVerticals,
	{
		zimbraFeatureCalendarEnabled,
		zimbraFeatureBriefcasesEnabled,
		slugs,
		isVideoActive,
		isChatActive,
		isCloudStorageActive,
		isIntegrationsActive
	}
) => {
	return {
		[slugs.email]: {
			name: slugs.email,
			url: '/',
			icon: 'envelope',
			title: 'Email',
			tabs: prevVerticals?.[slugs.email]?.tabs || [],
			lastVisitedTab: null
		},
		...(zimbraFeatureCalendarEnabled && {
			[slugs.calendar]: {
				name: slugs.calendar,
				url: `/${slugs.calendar}`,
				icon: 'calendar-o',
				title: 'Calendar',
				tabs: prevVerticals?.[slugs.calendar]?.tabs || [],
				lastVisitedTab: null
			}
		}),
		...(zimbraFeatureBriefcasesEnabled && {
			[slugs.briefcase]: {
				name: slugs.briefcase,
				url: `/${slugs.briefcase}`,
				icon: 'briefcase',
				title: 'Briefcase',
				tabs: prevVerticals?.[slugs.briefcase]?.tabs || [],
				lastVisitedTab: null
			}
		}),
		...(isVideoActive && {
			[slugs.videoapps]: {
				name: slugs.videoapps,
				url: `/${slugs.videoapps}`,
				icon: 'videocam',
				title: 'Video',
				tabs: prevVerticals?.[slugs.videoapps]?.tabs || [],
				lastVisitedTab: null
			}
		}),
		...(isChatActive && {
			[slugs.chatapps]: {
				name: slugs.chatapps,
				url: `/${slugs.chatapps}`,
				icon: 'chat',
				title: 'Chat',
				tabs: prevVerticals?.[slugs.chatapps]?.tabs || [],
				lastVisitedTab: null
			}
		}),
		...(isCloudStorageActive && {
			[slugs.cloudapps]: {
				name: slugs.cloudapps,
				url: `/${slugs.cloudapps}`,
				icon: 'cloud',
				title: 'Cloud',
				tabs: prevVerticals?.[slugs.cloudapps]?.tabs || [],
				lastVisitedTab: null
			}
		}),
		[slugs.contacts]: {
			name: slugs.contacts,
			url: `/${slugs.contacts}`,
			icon: 'address-book',
			title: 'Contacts',
			tabs: prevVerticals?.[slugs.contacts]?.tabs || [],
			lastVisitedTab: null
		},
		...(isIntegrationsActive && {
			[slugs.integrations]: {
				name: slugs.integrations,
				url: `/${slugs.integrations}`,
				icon: 'integration',
				title: 'Integrations',
				tabs: prevVerticals?.[slugs.integrations]?.tabs || [],
				lastVisitedTab: null
			}
		})
	};
};

export default handleActions(
	{
		[actionCreators.initializeVertical]: (state, { payload }) => {
			return update(state, { verticals: { $set: generateVertical(state.verticals, payload) } });
		},
		[actionCreators.addTab]: (state, { payload }) => {
			const view = payload.view;
			const vertical = state?.verticals?.[view];
			const index = vertical?.tabs?.findIndex(
				tab => tab.id === payload.id && tab.tabId === payload.tabId
			);

			let op;
			if (index !== -1) {
				op = { $splice: [[index, 1, payload]] };
			} else {
				op = { $push: [payload] };
			}

			return update(state, { verticals: { [view]: { tabs: op } } });
		},
		[actionCreators.removeTab]: (state, { payload }) => {
			const view = payload.view;
			const vertical = state?.verticals?.[view];
			const index = vertical?.tabs?.findIndex(
				tab =>
					(payload.tabId ? tab.tabId === payload.tabId : tab.id === payload.id) &&
					tab.type === payload.type
			);

			return update(state, {
				verticals: {
					[view]: {
						tabs: { $splice: [[index, 1]] },
						...(vertical?.tabs?.[index]?.url === vertical?.lastVisitedTab && {
							lastVisitedTab: { $set: null }
						})
					}
				}
			});
		},
		[actionCreators.removeTabs]: (state, { payload: { view, tabIds = [], resetLastTab } }) => {
			if (tabIds.length === 0) return state;
			const returnObj = state?.verticals?.[view]?.tabs?.filter(
				tab => tabIds.indexOf(tab.id) === -1
			);
			return update(state, {
				verticals: {
					[view]: {
						tabs: {
							$set: returnObj
						},
						...(resetLastTab && { lastVisitedTab: { $set: null } })
					}
				}
			});
		},
		[actionCreators.removeAllTabs]: (state, { payload: { view } = {} } = {}) =>
			update(state, {
				verticals: {
					[view]: {
						tabs: { $apply: tabs => tabs.filter(({ permanent }) => permanent) },
						// TODO: Need to check how this behaves in cloudapps vertical as we still need to preserve for briefcase
						lastVisitedTab: { $set: null }
					}
				}
			}),
		[actionCreators.updateTab]: (state, { payload }) => {
			const { view } = payload;
			const vertical = state?.verticals?.[view];
			const tabs = vertical?.tabs || [];

			const index = tabs.findIndex(
				tab => tab.tabId === payload.tabId || (tab.id && payload.id && tab.id === payload.id)
			);

			if (index > -1) {
				const { id, urlToUpdate: url, title } = payload;
				const updateData = {
					...(id && { id }),
					...(url && { url }),
					...(title && { title })
				};

				return update(state, {
					verticals: { [view]: { tabs: { [index]: { $merge: updateData } } } }
				});
			}

			return state;
		},
		[actionCreators.setShowAdvanced]: (state, { payload }) => ({
			...state,
			showAdvanced: payload.show,
			searchQuery: payload.searchQuery
		}),
		[actionCreators.updateLastVisitedTab]: (state, { payload: { view, url } }) => {
			if (!state.verticals?.[view]) {
				return state;
			}

			return update(state, { verticals: { [view]: { lastVisitedTab: { $set: url } } } });
		}
	},
	initialState
);
