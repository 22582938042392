import { Fragment } from 'preact';
import AlignedForm from '../../../../aligned-form';
import { useState, useCallback } from 'preact/hooks';
import FormGroup from '../../../../form-group';
import TextInput from '../../../../text-input';
import CollapsibleControl from '../../../../collapsible-control';
import { Localizer, Text } from 'preact-i18n';
import mailPort from '../../../../../utils/mail-port';
import { Button, ChoiceInput } from '@zimbra/blocks';
import style from '../../../style';
import PasswordInput from '../../../../password-input';
import { EXTERNAL_ACCOUNT_TYPES } from '../../../constants';
import { callWith } from '../../../../../lib/util';
import cx from 'classnames';
import AccountChoiceInput from './account-choice-input';
import AccountAlignedLabel from './account-aligned-label';

function SettingsAlignedLabel({ textId, width }) {
	return <AccountAlignedLabel noCSS textId={textId} width={width || '195px'} />;
}

function InlineRadioButton({ value, textId, accountType, isEditing, onChange }) {
	return (
		<label class={style.inlineRadioButton}>
			<AccountChoiceInput
				type="radio"
				name="accountType"
				value={value}
				onChange={callWith(onChange, value)}
				checked={accountType === value}
				disabled={isEditing}
				textId={`settings.accounts.addAccount.${textId}`}
				noWrapper
			/>
		</label>
	);
}

export default function ExternalServerSettings({
	formData,
	onFormDataChange,
	matchesScreenXs,
	isEditing,
	editingServerSettings = false,
	zimbraFeatureImapDataSourceEnabled,
	zimbraFeaturePop3DataSourceEnabled
}) {
	const [additionalSettingsCollapsed, setAdditionalSettingsCollapsed] = useState(true);

	const {
		accountType,
		useSSL,
		leaveOnServer,
		useCustomFolder,
		username,
		host,
		password,
		useCustomPort,
		port
	} = formData;

	const handleToggle = useCallback(() => {
		setAdditionalSettingsCollapsed(collapse => !collapse);
	}, []);

	const handleToggleUseSSL = useCallback(() => {
		const value = !useSSL;
		onFormDataChange('useSSL')(value, () => onFormDataChange('port')(mailPort(accountType, value)));
	}, [accountType, onFormDataChange, useSSL]);

	const handleChangeAccountType = useCallback(
		type => {
			onFormDataChange('accountType')(type, () => onFormDataChange('port')(mailPort(type, useSSL)));
		},
		[onFormDataChange, useSSL]
	);

	const handleToggleDeleteOnServer = useCallback(() => {
		onFormDataChange('leaveOnServer')(!leaveOnServer);
	}, [leaveOnServer, onFormDataChange]);

	const handleCustomFolderToggle = useCallback(() => {
		onFormDataChange('useCustomFolder')(!useCustomFolder);
	}, [onFormDataChange, useCustomFolder]);

	const handleCustomPortToggle = useCallback(() => {
		const value = !useCustomPort;

		onFormDataChange('useCustomPort')(
			value,
			() => !value && onFormDataChange('port')(mailPort(accountType, useSSL))
		);
	}, [accountType, onFormDataChange, useCustomPort, useSSL]);

	const labelWidth = matchesScreenXs ? '100%' : '195px';

	return (
		<Fragment>
			<AlignedForm>
				<FormGroup>
					<div class={style.subsection}>
						<SettingsAlignedLabel textId="settings.accounts.addAccount.typeSubsection" />
						<div class={style.subsectionBody}>
							{zimbraFeatureImapDataSourceEnabled && (
								<InlineRadioButton
									accountType={accountType}
									value={EXTERNAL_ACCOUNT_TYPES.IMAP}
									textId={EXTERNAL_ACCOUNT_TYPES.IMAP}
									onChange={handleChangeAccountType}
									isEditing={isEditing}
								/>
							)}
							{zimbraFeaturePop3DataSourceEnabled && (
								<InlineRadioButton
									accountType={accountType}
									value={EXTERNAL_ACCOUNT_TYPES.POP}
									textId="pop"
									onChange={handleChangeAccountType}
									isEditing={isEditing}
								/>
							)}
						</div>
					</div>
				</FormGroup>
				<FormGroup
					class={cx(style.subsection, style.formGroup)}
					rows={matchesScreenXs}
					compact={!matchesScreenXs}
				>
					<SettingsAlignedLabel textId="settings.accounts.addAccount.username" />
					<div class={cx(style.subsectionBody, style.w100)}>
						<TextInput
							onChange={callWith(onFormDataChange, 'username')()}
							value={username}
							placeholderId="settings.accounts.addAccount.namePlaceholder"
							required
							class={cx(style.textInput, style.infoInput)}
						/>
					</div>
				</FormGroup>
				<FormGroup
					class={cx(style.subsection, style.formGroup)}
					rows={matchesScreenXs}
					compact={!matchesScreenXs}
				>
					<SettingsAlignedLabel textId="settings.accounts.addAccount.host" />
					<div class={cx(style.subsectionBody, style.w100)}>
						<input
							placeholder={
								accountType === 'imap' ? 'imap.mail.example.com' : 'pop.mail.example.com'
							}
							onChange={callWith(onFormDataChange, 'host')()}
							value={host}
							class={cx(style.textInput, style.infoInput)}
							type="text"
							required
						/>
					</div>
				</FormGroup>
				{isEditing && (
					<FormGroup
						class={cx(style.subsection, style.formGroup)}
						rows={matchesScreenXs}
						compact={!matchesScreenXs}
					>
						<SettingsAlignedLabel textId="settings.accounts.addAccount.password" />
						<div class={cx(style.subsectionBody, style.w100)}>
							<Localizer>
								<PasswordInput
									class={style.externalPassword}
									onInput={callWith(onFormDataChange, 'password')()}
									value={password}
									placeholder={<Text id="settings.accounts.addAccount.passwordPlaceholder" />}
									required
									wide
								/>
							</Localizer>
						</div>
					</FormGroup>
				)}
				<FormGroup onClick={handleToggle} class={style.advancedToggle}>
					<CollapsibleControl collapsed={additionalSettingsCollapsed} />
					<Text id="settings.accounts.addAccount.advancedSettings" />
				</FormGroup>
			</AlignedForm>
			{!additionalSettingsCollapsed && (
				<AlignedForm>
					<FormGroup>
						{matchesScreenXs ? (
							<Fragment>
								<AccountChoiceInput
									onChange={handleCustomPortToggle}
									checked={useCustomPort}
									textId="settings.accounts.addAccount.changePort"
									noWrapper
								/>
								<div class={style.rightHint}>
									<Text
										id="settings.accounts.addAccount.portHint"
										fields={{
											portNum: mailPort(accountType, useSSL)
										}}
									/>
								</div>
							</Fragment>
						) : (
							<SettingsAlignedLabel textId="settings.accounts.addAccount.port" />
						)}
						<div class={style.inputWithRightHint}>
							{!matchesScreenXs && (
								<Fragment>
									<ChoiceInput onChange={handleCustomPortToggle} checked={useCustomPort} />
									<div class={style.changePortLabel}>
										<Text id="settings.accounts.addAccount.changePort" />
									</div>
								</Fragment>
							)}
							<input
								value={useCustomPort ? port : mailPort(accountType, useSSL)}
								onChange={callWith(onFormDataChange, 'port')()}
								class={cx(style.textInput, style.portInput)}
								type="number"
								min="1"
								max="65534"
								disabled={!useCustomPort}
							/>
							{!matchesScreenXs && (
								<div class={style.rightHint}>
									<Text
										id="settings.accounts.addAccount.portHint"
										fields={{
											portNum: mailPort(accountType, useSSL)
										}}
									/>
								</div>
							)}
						</div>
					</FormGroup>
					<FormGroup>
						<div class={cx(style.subsection, style.w100)}>
							{matchesScreenXs ? (
								<AccountChoiceInput
									onChange={handleToggleUseSSL}
									checked={useSSL}
									textId="settings.accounts.addAccount.useSSL"
								/>
							) : (
								<Fragment>
									<SettingsAlignedLabel
										width={labelWidth}
										textId="settings.accounts.addAccount.useSSL"
									/>
									<AccountChoiceInput
										onChange={handleToggleUseSSL}
										checked={useSSL}
										textId="settings.accounts.editAccount.enable"
									/>
								</Fragment>
							)}
						</div>
					</FormGroup>
					{accountType === 'pop3' && (
						<div class={style.w100}>
							<FormGroup>
								<div class={style.subsection}>
									<SettingsAlignedLabel
										width={labelWidth}
										textId="settings.accounts.addAccount.downloadDestination"
									/>
									<div class={style.subsectionBody}>
										<div class={style.toggleFolder}>
											<AccountChoiceInput
												type="radio"
												name="folder"
												onChange={handleCustomFolderToggle}
												checked={useCustomFolder}
												textId="settings.accounts.addAccount.downloadFolderLabel"
												noWrapper
											/>
											<div class={style.downloadFolderName}>
												{username || (
													<span class={style.downloadFolderPlaceholder}>
														<Text id="settings.accounts.addAccount.downloadFolderPlaceholder" />
													</span>
												)}
											</div>
										</div>
										<AccountChoiceInput
											type="radio"
											name="folder"
											onChange={handleCustomFolderToggle}
											checked={!useCustomFolder}
											textId="settings.accounts.addAccount.inbox"
											noWrapper
										/>
									</div>
								</div>
							</FormGroup>
							<FormGroup>
								<div class={cx(style.subsection, style.w100)}>
									{matchesScreenXs ? (
										<AccountChoiceInput
											onChange={handleToggleDeleteOnServer}
											checked={!leaveOnServer}
											textId="settings.accounts.addAccount.popDeleteFromServer"
										/>
									) : (
										<Fragment>
											<SettingsAlignedLabel
												width={labelWidth}
												textId="settings.accounts.addAccount.popDeleteFromServer"
											/>
											<AccountChoiceInput
												onChange={handleToggleDeleteOnServer}
												checked={!leaveOnServer}
												textId="settings.accounts.editAccount.enable"
											/>
										</Fragment>
									)}
								</div>
							</FormGroup>
						</div>
					)}
				</AlignedForm>
			)}
			<Button
				class={style.plainButton}
				type="submit"
				styleType="primary"
				brand="primary"
				disabled={isEditing && !editingServerSettings}
			>
				<Text id="buttons.testConnection" />
			</Button>
		</Fragment>
	);
}
