let exportVar;

if (typeof process.env.ELECTRON_ENV !== 'undefined') {
	const { ipcRenderer } = require('electron-better-ipc');
	const { ipcNames } = require('./constants');
	const mitt = require('mitt').default;
	const paths = require('path');
	const fsPromises = require('fs').promises;
	const { LOG_FOLDER } = require('../local-store/constants');

	/**
	 * Class for housekeeping of renderer process' IPCs. Its responsibilities include:
	 * 1. Handlers for all calls that renderer process would be answering to main, are added and bound.
	 * 2. Calls to the main process would be made through this file only through a promise based api.
	 *
	 * @class RendererIpc
	 */
	class RendererIpc {
		constructor() {
			/**
			 * An emitter which dispatches events to the listeners.
			 * Motivation behind this emitter is, notifications from the main process would always
			 * be listened by this file only. However, sometimes, the components need to be made aware
			 * about these notification, upon such situation, components can listen to the changes emitted
			 * by the emitter with the values received from the main process, and react accordingly.
			 *
			 * @memberof RendererIpc
			 */

			this.emitter = mitt();
		}

		/**
		 * Invoked when the electron app's renderer process initiates, registers all the handlers to the renderer process IPC
		 * which would be invoked by the main process to communicate with the renderer process. The handlers bound would be executed
		 * whenever main process calls the specified IPC call.
		 *
		 * @memberof RendererIpc
		 */
		bindListeners() {
			ipcRenderer.answerMain(ipcNames.setPstProgressStatus, status => {
				this.emitter.emit(ipcNames.setPstProgressStatus, status);
			});

			ipcRenderer.answerMain(ipcNames.setSyncStatus, status => {
				this.emitter.emit(ipcNames.setSyncStatus, status);
			});

			ipcRenderer.answerMain(ipcNames.getClientInfoResponse, response => {
				this.emitter.emit(ipcNames.getClientInfoResponse, response);
			});

			ipcRenderer.answerMain(ipcNames.refreshFolders, () => {
				this.emitter.emit(ipcNames.refreshFolders);
			});
		}

		unbindListeners() {
			this.removeAllIpcListeners(ipcNames.setPstProgressStatus);
			this.removeAllIpcListeners(ipcNames.setSyncStatus);
			this.removeAllIpcListeners(ipcNames.getClientInfoResponse);
			this.removeAllIpcListeners(ipcNames.refreshFolders);
		}

		/**
		 * Registers the event with the emitter.
		 *
		 * @param {String} eventName name of the event to be registered
		 * @param {Function} fn function that gets executed when the specified event is emitted
		 * @memberof RendererIpc
		 */
		listenToIpcEvent(eventName, fn) {
			this.emitter.on(eventName, fn);
		}

		/**
		 * Unbinds the event from the emitter.
		 *
		 * @param {String} eventName name of the event to be unbound
		 * @param {Function} fn a reference of the function that needs to be removed from the listeners
		 * @memberof RendererIpc
		 */
		removeIpcListener(eventName, fn) {
			this.emitter.off(eventName, fn);
		}

		/**
		 * Unbinds all listeners for the specified event from the emitter
		 *
		 * @param {String} eventName the event name whose all listeners should be removed
		 * @memberof RendererIpc
		 */
		removeAllIpcListeners(eventName) {
			this.emitter.off(eventName);
		}

		/**
		 * Wrapper method for ipcRenderer's `callMain`method
		 *
		 * @param {String} channel to call on main ipc
		 * @param {Any} params passed for given channel
		 *
		 * @memberof RendererIpc
		 */
		callMain(channel, options) {
			return ipcRenderer.callMain(channel, options);
		}

		startPSTExtraction(filePath) {
			return ipcRenderer.callMain(ipcNames.startPSTExtractor, filePath);
		}

		abortPSTExtraction() {
			return ipcRenderer.callMain(ipcNames.abortPSTExtractor);
		}

		async getImportCountFromLog(folderName) {
			const logFolderPath = await ipcRenderer.callMain(ipcNames.getLogFolderPath);
			const filePath = paths.join(logFolderPath, LOG_FOLDER, folderName, 'extraction-result.log');
			return fsPromises.readFile(filePath, 'utf-8');
		}

		requestClientInfo(requestParams) {
			return ipcRenderer.callMain(ipcNames.requestClientInfo, requestParams);
		}

		setUnreadMailCount(count) {
			require('electron').ipcRenderer.sendSync('update-badge', count);
			return ipcRenderer.callMain(ipcNames.setUnreadMailCount, count);
		}

		openExternalBrowser(URL) {
			return ipcRenderer.callMain(ipcNames.openExternalBrowser, URL);
		}

		getDefaultMailToClient() {
			return ipcRenderer.callMain(ipcNames.getDefaultMailToClient);
		}

		createFolder(options) {
			return ipcRenderer.callMain(ipcNames.createFolder, options);
		}

		setDefaultMailToClient() {
			return ipcRenderer.callMain(ipcNames.setDefaultMailToClient);
		}

		setFocusToApp() {
			return ipcRenderer.callMain(ipcNames.setFocusToApp);
		}

		updateNetworkStatus(options) {
			return ipcRenderer.callMain(ipcNames.updateNetworkStatus, options);
		}

		terminateSyncProcess(options) {
			return ipcRenderer.callMain(ipcNames.terminateSyncProcess, options);
		}

		terminateArchiveProcess(options) {
			return ipcRenderer.callMain(ipcNames.terminateArchiveProcess, options);
		}

		initializeDesktopAppConfig(options) {
			return ipcRenderer.callMain(ipcNames.initialize, options);
		}

		handleDiskSpaceWarning() {
			return ipcRenderer.callMain(ipcNames.handleDiskSpaceWarning);
		}

		resetDesktopApp(options) {
			return ipcRenderer.callMain(ipcNames.reset, options);
		}

		updateSyncProcess(options) {
			return ipcRenderer.callMain(ipcNames.updateSyncProcess, options);
		}

		resumeSyncProcess() {
			return ipcRenderer.callMain(ipcNames.resumeSyncProcess);
		}

		updateAutoArchiveLocalPrefs(options) {
			return ipcRenderer.callMain(ipcNames.updateAutoArchiveLocalPrefs, options);
		}

		getLocalPrefs(options) {
			return ipcRenderer.callMain(ipcNames.getPreferences, options);
		}
	}

	exportVar = new RendererIpc();
}

module.exports = exportVar;
