import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import uniqBy from 'lodash-es/uniqBy';
import get from 'lodash-es/get';
import GetRightsQuery from '../../graphql/queries/rights/get-rights-query.graphql';
import GrantRightsMutation from '../../graphql/queries/rights/grant-rights-mutation.graphql';
import RevokeRightsMutation from '../../graphql/queries/rights/revoke-rights-mutation.graphql';
import withSendRightsEmailNotifications from './email-notifications';
import { SEND_AS, SEND_ON_BEHALF, VIEW_FREE_BUSY } from '../../constants/rights';

export const getRightsInput = {
	input: {
		access: [{ right: SEND_AS }, { right: SEND_ON_BEHALF }, { right: VIEW_FREE_BUSY }]
	}
};

export function withGetRights() {
	return graphql(GetRightsQuery, {
		name: 'getRightsQuery',
		options: {
			variables: getRightsInput
		}
	});
}

export function withGrantRights() {
	return compose(
		withSendRightsEmailNotifications(),
		graphql(GrantRightsMutation, {
			props: ({ mutate, ownProps: { sendGrantRightsEmailNotification } }) => ({
				onGrantRights: (address, rights) => {
					const access = rights.map(({ __typename, ...rest }) => ({
						address,
						...rest
					}));

					let cacheReference;
					return mutate({
						variables: {
							input: {
								access
							}
						},
						refetchQueries: [
							{
								query: GetRightsQuery,
								variables: getRightsInput
							}
						],
						optimisticResponse: {
							__typename: 'Mutation',
							grantRights: {
								__typename: 'RightsResponse',
								access: access.map(obj => ({
									// adding null values for requested fields in the mutation as they must be present with apollo 3 upgrade
									right: null,
									address: null,
									granteeType: null,
									deny: null,
									...obj,
									__typename: 'AccountACEInfo'
								}))
							}
						},
						update: (cache, { data: optimisticResponse }) => {
							cacheReference = cache;
							const { grantRights } = optimisticResponse;

							// Update results to `getRights`
							const data = cache.readQuery({
								query: GetRightsQuery,
								variables: getRightsInput
							});

							cache.writeQuery({
								query: GetRightsQuery,
								variables: getRightsInput,
								data: {
									...data,
									getRights: {
										...data.getRights,
										access: !data.getRights.access
											? grantRights.access
											: uniqBy(
													[...data.getRights.access, ...grantRights.access],
													accessRights =>
														accessRights && `${accessRights.address}-${accessRights.right}`
											  )
									}
								}
							});
						}
					})
						.then(res => {
							const grantee = get(res, 'data.grantRights.access.0.address');
							const rightType = get(rights, '0.right');

							if (grantee && rightType !== VIEW_FREE_BUSY) {
								sendGrantRightsEmailNotification({
									grantee,
									rights: rights.map(({ right }) => right)
								});
							}

							return res;
						})
						.catch(err => {
							if (cacheReference && cacheReference.writeQuery) {
								const data = cacheReference.readQuery({
									query: GetRightsQuery,
									variables: getRightsInput
								});

								cacheReference.writeQuery({
									query: GetRightsQuery,
									variables: getRightsInput,
									data: {
										...data,
										getRights: {
											__typename: 'RightsResponse',
											access: !data.getRights.access
												? null
												: data.getRights.access.filter(right => right && right.address !== address)
										}
									}
								});
							}
							return Promise.reject(err);
						});
				}
			})
		})
	);
}

export function withRevokeRights() {
	return compose(
		withSendRightsEmailNotifications(),
		graphql(RevokeRightsMutation, {
			props: ({ mutate, ownProps: { sendRevokeRightsEmailNotification } }) => ({
				onRevokeRights: (address, rights) => {
					const access = rights.map(({ __typename, ...rest }) => ({
						address,
						...rest
					}));

					return mutate({
						variables: {
							input: {
								access
							}
						},
						refetchQueries: [
							{
								query: GetRightsQuery,
								variables: getRightsInput
							}
						],
						optimisticResponse: {
							__typename: 'Mutation',
							revokeRights: {
								__typename: 'RightsResponse',
								access: access.map(obj => ({
									// adding null values for requested fields in the mutation as they must be present with apollo 3 upgrade
									right: null,
									address: null,
									granteeType: null,
									deny: null,
									...obj,
									__typename: 'AccountACEInfo'
								}))
							}
						},
						update: (cache, { data: { revokeRights } }) => {
							// Update results to `getRights`
							const data = cache.readQuery({
								query: GetRightsQuery,
								variables: getRightsInput
							});

							cache.writeQuery({
								query: GetRightsQuery,
								variables: getRightsInput,
								data: {
									...data,
									getRights: {
										__typename: 'RightsResponse',
										access: !data.getRights.access
											? null
											: data.getRights.access.filter(
													accessRights =>
														(accessRights && accessRights.address !== address) ||
														(accessRights &&
															!revokeRights.access.find(
																({ right: revokedRight }) => accessRights.right === revokedRight
															))
											  )
									}
								}
							});
						}
					}).then(res => {
						const grantee = get(res, 'data.revokeRights.access.0.address');
						const rightType = get(rights, '0.right');

						if (grantee && rightType !== VIEW_FREE_BUSY) {
							sendRevokeRightsEmailNotification({
								grantee,
								rights: rights.map(({ right }) => right)
							});
						}

						return res;
					});
				}
			})
		})
	);
}

export function withRevokeAllRights() {
	return compose(
		withSendRightsEmailNotifications(),
		graphql(RevokeRightsMutation, {
			props: ({ mutate, ownProps: { sendRevokeRightsEmailNotification } }) => ({
				onRevokeAllRights: address => {
					const access = [SEND_AS, SEND_ON_BEHALF].map(right => ({
						granteeType: 'usr',
						right,
						address
					}));

					return mutate({
						variables: {
							input: {
								access
							}
						},
						optimisticResponse: {
							__typename: 'Mutation',
							revokeRights: {
								__typename: 'RightsResponse',
								access: access.map(obj => ({ ...obj, __typename: 'AccountACEInfo' }))
							}
						},
						update: cache => {
							const data = cache.readQuery({
								query: GetRightsQuery,
								variables: getRightsInput
							});

							cache.writeQuery({
								query: GetRightsQuery,
								variables: getRightsInput,
								data: {
									getRights: {
										__typename: 'RightsResponse',
										access: !data.getRights.access
											? null
											: data.getRights.access.filter(
													accessRights => accessRights && accessRights.address !== address
											  )
									}
								}
							});
						}
					}).then(res => {
						const grantee = get(res, 'data.revokeRights.access.0.address');
						if (grantee) {
							sendRevokeRightsEmailNotification({
								grantee,
								rights: [SEND_AS, SEND_ON_BEHALF]
							});
						}

						return res;
					});
				}
			})
		})
	);
}
