const maxAddresses = 3;
export default function ListItemEmailAddresses({ emailAddresses }) {
	const { length } = emailAddresses;

	return (
		<span title={emailAddresses.join('\n')}>
			{length > maxAddresses
				? [`...${emailAddresses.slice(Math.max(emailAddresses.length - 3, 1)).join(', ')}`]
				: emailAddresses.join(', ')}
		</span>
	);
}
