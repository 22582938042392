import { Component } from 'preact';
import { Text, withText } from 'preact-i18n';
import { withProps, branch, renderNothing } from 'recompose';
import { fetchRecurrenceText } from '../../utils/calendar';

@withText({
	order1: 'calendar.dialogs.customRecurrence.repeatSection.weekOrder.first',
	order2: 'calendar.dialogs.customRecurrence.repeatSection.weekOrder.second',
	order3: 'calendar.dialogs.customRecurrence.repeatSection.weekOrder.third',
	order4: 'calendar.dialogs.customRecurrence.repeatSection.weekOrder.fourth',
	order5: 'calendar.dialogs.customRecurrence.repeatSection.weekOrder.last'
})
@branch(({ recurrence }) => !recurrence, renderNothing)
@withProps(props => fetchRecurrenceText(props))
export default class Recurrence extends Component {
	render({ customRecurrenceString, customRecurrenceObject }) {
		return (
			<Text
				id={`calendar.dialogs.customRecurrence.recurrence.${customRecurrenceString}`}
				fields={customRecurrenceObject}
			/>
		);
	}
}
