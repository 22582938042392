import { useDispatch } from 'react-redux';
import {
	useBriefcaseFoldersQuery,
	useBriefcaseFolderActionMutation,
	useVersionActionMutation
} from '../../../hooks/briefcase';
import { useCallback } from 'preact/hooks';
import { clearSelection, setDeleteWarning } from '../../../store/briefcase/actions';
import useDocumentDelete from '../hooks/delete-document';
import { TRASH } from '../../../constants/folders';
import { types as apiClientTypes } from '@zimbra/api-client';
import { getSelectedDocumentIdsThunk } from '../thunk';
import {
	getFolderSearchQueryParameters,
	isButtonEnabled,
	getUpdatedTargetFolders,
	routeOnDeleteOrMovingItem,
	removeVersionFromFileName
} from '../util';
import { folderActionMutationVariables } from '../../../hooks/folders/utils';
import { getFolder, getFoldersAndSharedFolders } from '../../../utils/folders';
import get from 'lodash-es/get';
import { getDataFromReduxStoreThunk } from '../../../utils/thunk';

const { ActionOps } = apiClientTypes;

export const DeleteWrapper = ({ children, documentList, selectNextItem }) => {
	const dispatch = useDispatch();
	const { data: foldersData } = useBriefcaseFoldersQuery({ fetchPolicy: 'cache-only' });
	const { folders, sharedFolders } = getFoldersAndSharedFolders(foldersData);
	const versionAction = useVersionActionMutation();
	const folderAction = useBriefcaseFolderActionMutation();
	const deleteDocuments = useDocumentDelete();

	const handleDocumentDelete = useCallback(
		({ deletedSelected, preventNextItemNavigate }) => {
			deleteDocuments(deletedSelected).then(() => {
				if (!preventNextItemNavigate) {
					selectNextItem && selectNextItem();
				}
			});
		},
		[deleteDocuments, selectNextItem]
	);

	const handleVersionDelete = useCallback(
		({ id, version, includeOlderRevisions = false }) => {
			versionAction({
				variables: {
					id,
					ver: version,
					includeOlderRevisions: +includeOlderRevisions
				}
			}).then(() => {
				selectNextItem && selectNextItem();
			});
		},
		[selectNextItem, versionAction]
	);

	const handleFolderDelete = useCallback(
		({ deletedSelected, item }) => {
			const url = dispatch(getDataFromReduxStoreThunk('url'));
			const { folderName, currentFolder } = getFolderSearchQueryParameters({
				url,
				folders,
				sharedFolders
			});

			const op = folderName.includes(TRASH) ? ActionOps.delete : ActionOps.trash;
			const destFolder =
				op === ActionOps.trash &&
				getFolder(folders, f => get(f, 'name') && get(f, 'name') === TRASH);

			let updateFolders = item && [item];
			if (!updateFolders) {
				const selectedIds = dispatch(getSelectedDocumentIdsThunk());
				updateFolders = currentFolder.folders.filter(({ id }) => selectedIds.includes(id));
			}

			folderAction({
				variables: folderActionMutationVariables({
					id: deletedSelected,
					op
				}),
				folderIds: deletedSelected,
				currentFolder,
				...(op === ActionOps.trash && {
					targetFolders: getUpdatedTargetFolders({
						folders: updateFolders,
						destFolder,
						currentFolderPath: currentFolder.absFolderPath
					}),
					destFolder
				})
			}).then(() => {
				if (dispatch(getSelectedDocumentIdsThunk()).length === 1) {
					routeOnDeleteOrMovingItem({ dispatch, url });
				}
				dispatch(clearSelection());
			});
		},
		[dispatch, folderAction, folders, sharedFolders]
	);

	const handleDelete = useCallback(
		({ item }) => {
			// Priority item to handle below case
			// Case: when one or more items are selected and user click inline download icon of other file which is not selected.
			let selectedIds = item ? [item.id] : dispatch(getSelectedDocumentIdsThunk());

			const currentVersionWarning = [];

			// Filterout current version item if root file is not selected
			selectedIds = selectedIds.filter(id => {
				if (id.includes('_')) {
					const versionDocument = Array.isArray(documentList)
						? documentList.find(({ id: documentId }) => documentId === id)
						: documentList;

					if (versionDocument.current) {
						const docId = id.split('_')[0];
						if (selectedIds.indexOf(docId) === -1) {
							currentVersionWarning.push({
								id: docId,
								name: removeVersionFromFileName(versionDocument.name)
							});
							return false;
						}
					}
				}
				return true;
			});

			// Show warning dialog when user try to delete current version and root file is not selected.
			if (currentVersionWarning.length) {
				dispatch(setDeleteWarning(currentVersionWarning));
			}

			// Prepare ids which are either folderId, Root file id or current file id
			const selectedRootDocOrFolderIds = selectedIds
				.map(id => {
					if (id.includes('_')) {
						const versionDocument = Array.isArray(documentList)
							? documentList.find(({ id: documentId }) => documentId === id)
							: documentList;
						if (versionDocument.current) {
							const docId = id.split('_')[0];
							if (selectedIds.indexOf(docId) === -1) {
								return docId;
							}
						}
						return null;
					}
					return id;
				})
				.filter(id => id !== null);

			// Filter out versionIds only if it's current or parent file is not selected.
			const versionIds = selectedIds.filter(id => {
				if (id.includes('_')) {
					const docId = id.split('_')[0];
					if (
						selectedRootDocOrFolderIds.indexOf(docId) === -1 &&
						!currentVersionWarning.some(doc => doc.id === docId)
					) {
						// if root/current file is not selected
						return true;
					}
				}
				return false;
			});

			if (versionIds.length) {
				versionIds.forEach(versionId => {
					const [versionDocId, versionNumber] = versionId.split('_');

					handleVersionDelete({
						id: versionDocId,
						version: parseInt(versionNumber, 10)
					});
				});
			}

			if (selectedRootDocOrFolderIds.length) {
				const rootDocIds = selectedRootDocOrFolderIds.join(',');
				if ((item && !item?.absFolderPath) || documentList?.length) {
					handleDocumentDelete({
						deletedSelected: rootDocIds,
						preventNextItemNavigate: currentVersionWarning.length > 0
					});
				} else {
					handleFolderDelete({ deletedSelected: rootDocIds, ...(item && { item }), folders });
				}
			}
		},
		[dispatch, documentList, folders, handleDocumentDelete, handleFolderDelete, handleVersionDelete]
	);

	const handleInlineDelete = useCallback(
		item => event => {
			event.stopPropagation();
			handleDelete({ item });
		},
		[handleDelete]
	);

	const enableDelete = isButtonEnabled({
		documentList,
		sharedFolders,
		folders
	});

	return children({
		handleDelete,
		handleInlineDelete,
		handleFolderDelete,
		enableDelete
	});
};
