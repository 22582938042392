import { Text } from 'preact-i18n';
import AccountsList from './accounts-list';
import style from '../style';

const ActiveAccountsView = ({
	accounts,
	switchActiveScreen,
	maxPersonasReached,
	personasEnabled,
	onExternalAccountAction,
	editAccountProps,
	fromAccounts
}) => (
	<div class={style.w100}>
		<div class={style.sectionTitle}>
			<Text id="settings.accounts.mailAddressesTitle" />
		</div>
		<AccountsList
			switchActiveScreen={switchActiveScreen}
			accounts={accounts}
			maxPersonasReached={maxPersonasReached}
			personasEnabled={personasEnabled}
			onExternalAccountAction={onExternalAccountAction}
			editAccountProps={editAccountProps}
			fromAccounts={fromAccounts}
		/>
	</div>
);

export default ActiveAccountsView;
