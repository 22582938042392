import get from 'lodash-es/get';

/**
 * A function that returns value from a Object.
 * If Object is { a: 1, b: 2 } then Input example: 'a,b' or { c: 'a' } and Output: { a: 1, b: 2 } or { c: 1 }
 * @param {Object} map
 * @param {Object|String} args
 */
export default function (map, args) {
	const derivedProps = {};
	const list = typeof args === 'string' ? args.split(/\s*,\s*/) : args;
	const isArray = Array.isArray(list);

	// eslint-disable-next-line guard-for-in
	for (let key in list) {
		const path = list[key];
		if (isArray) key = path.split('.').pop();
		derivedProps[key] = get(map, path);
	}

	return derivedProps;
}
