export const REPLY = 'REPLY';
export const REPLY_ALL = 'REPLY_ALL';
export const FORWARD = 'FORWARD';
export const SHOW_ORIGINAL = 'SHOW_ORIGINAL';
export const DEFAULT_MESSAGES_PER_PAGE = 50;
export const FORWARD_SUBJECT_PREFIX = 'Fwd';
export const REPLY_SUBJECT_PREFIX = 'Re';

// Polling interval constants for refetching mail items.
export const MANUAL_REFRESH = '31536000';
export const AS_NEW_MAIL_ARRIVES = '500';
export const MIN_INTERVAL_VALUE = '60'; // 60 seconds
export const DEFAULT_INTERVAL = '300'; // 5 minutes (in seconds)

export const FOLDER_SELECT = 'FOLDER_SELECT';
export const TAG_CHOOSER = 'TAG_CHOOSER';
export const BLOCK_MAIL = 'BLOCK_MAIL';
export const OPEN_FILTER = 'OPEN_FILTER';

export const OLK_SRC_BODY_SECTION_ID = 'OLK_SRC_BODY_SECTION';
export const COMPOSER_QUOTE_TOGGLE = '__zm_composer_toggle__';
export const QUOTE_TOGGLE = '__zm_toggle__';
export const DISPLAY_OLK_SRC_BODY = 'displayOLKSrcBody';
