import style from './style';

export default function ToggleButtonText({ iconPosition, icon, value, tooltipTitle }) {
	return (
		<div>
			{iconPosition === 'left' && icon}
			<p class={style.toggle} title={tooltipTitle}>
				{value}
			</p>
			{iconPosition === 'right' && icon}
		</div>
	);
}
