import { Text } from 'preact-i18n';
import { useCallback } from 'preact/hooks';

import { Button } from '@zimbra/blocks';

import LoginBackground from './background';
import { useClientConfig } from '../../hooks/client-config';

import style from './style';
import ClientLogo from '../client-logo';
import CopyrightTextComponent from '../copyright-modal';
import TagLine from './tagline';

export const Error = () => {
	const { loginLogoLink, loginLogoTitle } = useClientConfig({
		loginLogoLink: 'loginLogoLink',
		loginLogoTitle: 'loginLogoTitle'
	});

	const handleSignInRedirect = useCallback(() => (location.href = location.origin), []);

	const handleResetPassRedirect = useCallback(
		() => (location.href = `${location.origin}?forgotPassword=true`),
		[]
	);

	return (
		<LoginBackground class={style.container}>
			<div class={style.login} zmLoginPanel>
				<ClientLogo href={loginLogoLink} title={loginLogoTitle} target="_blank" />
				<p>
					<Text id="loginScreen.errors.invalidLink" />
				</p>
				<div class={style.buttons}>
					<Button onClick={handleSignInRedirect} styleType="text">
						<Text id="loginScreen.header.title" />
					</Button>
					<Button onClick={handleResetPassRedirect} styleType="text">
						<Text id="loginScreen.forgotPass.forgotPassword" />
					</Button>
				</div>
			</div>
			<footer class={style.footer}>
				<TagLine />
				<CopyrightTextComponent isLogin />
			</footer>
		</LoginBackground>
	);
};
