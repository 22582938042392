import { Text } from 'preact-i18n';
import ActionMenu, { DropDownWrapper } from '../../../action-menu';
import ActionMenuGroup from '../../../action-menu-group';
import ActionMenuItem from '../../../action-menu-item';

export default function ActionMenuFilterOptions({
	buttonClass,
	onToggleDetails,
	onEditFilter,
	onDeleteFilter,
	onSelectRun
}) {
	return (
		<ActionMenu
			icon="ellipsis-h"
			monotone
			arrow={false}
			anchor="end"
			actionButtonClass={buttonClass}
		>
			<DropDownWrapper>
				<ActionMenuGroup>
					<ActionMenuItem disabled={!onToggleDetails} onClick={onToggleDetails}>
						<Text id="settings.filters.details" />
					</ActionMenuItem>
					<ActionMenuItem disabled={!onSelectRun} onClick={onSelectRun}>
						<Text id="settings.filters.run" />
					</ActionMenuItem>
					<ActionMenuItem disabled={!onEditFilter} onClick={onEditFilter}>
						<Text id="buttons.edit" />
					</ActionMenuItem>
					<ActionMenuItem disabled={!onDeleteFilter} onClick={onDeleteFilter}>
						<Text id="buttons.delete" />
					</ActionMenuItem>
				</ActionMenuGroup>
			</DropDownWrapper>
		</ActionMenu>
	);
}
