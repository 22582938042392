import ClientConfig from '../../context/client-config';
import { useContext } from 'preact/hooks';
import get from 'lodash-es/get';

const CopyrightTextComponent = ({ isLogin }) => {
	const config = useContext(ClientConfig);
	const configCopyrightName = get(config.data, 'copyrightClientName');
	const copyrightClientName = configCopyrightName ? `ZIMBRA and ${configCopyrightName}` : 'Zimbra';

	const copyrightDoNotTranslate = isLogin
		? `© 2022 Synacor, Inc. All rights reserved. "${copyrightClientName}" is a registered trademark of Synacor, Inc.`
		: `Copyright© 2005 - 2022 Synacor, Inc. All rights reserved. This product is protected by U.S. and international copyright and intellectual property laws. "${copyrightClientName}" is a registered trademark of Synacor, Inc. in the United States and other jurisdictions. All other marks and names mentioned herein may be trademarks of their respective companies.`;

	return isLogin ? (
		<small>{copyrightDoNotTranslate}</small>
	) : (
		<span>{copyrightDoNotTranslate}</span>
	);
};

export default CopyrightTextComponent;
