import { Fragment } from 'preact';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useState } from 'preact/hooks';
import { Localizer, Text } from 'preact-i18n';
import cx from 'classnames';
import get from 'lodash-es/get';
import isString from 'lodash-es/isString';

import { callWith } from '@zimbra/util/src/call-with';
import s from '../style.less';
import { deepClone } from '../../../../lib/util';
import FormGroup from '../../../form-group';
import AlignedLabel from '../../../aligned-form/label';
import AddressField from '../../../address-field';
import {
	ATTENDEE_ROLE,
	PARTICIPATION_STATUS,
	CALENDAR_USER_TYPE
} from '../../../../constants/calendars';
import ContactPicker from '../../../contact-picker';
import { addressFromContact } from '../../../../utils/contacts';
import { AttendeesAvailabilityIndicator } from './availability-indicator';
import { ToAddress } from './to-address';
import { LocationChooser } from './location-chooser';

import { getParsedAppointmentData } from '../../../../store/calendar/selectors';
import { setEvent } from '../../../../store/calendar/actions';

export const AttendeesChooser = ({
	tabId,
	disabledElement,
	isForwardInvite,
	isProposeTime,
	appointmentData,
	isLocation,
	matchesScreenXs,
	matchesScreenMd,
	identitiesInfo,
	hasValidAttendee,
	showToAddressField,
	renderVideoZimletSlot
}) => {
	const [showContactPicker, setShowContactPicker] = useState(false);
	const dispatch = useDispatch();

	const onToggleContactPicker = useCallback(value => {
		setShowContactPicker(value);
	}, []);

	const { locations, attendees, forwardAttendees, availabilityVisible, isDraft } = useSelector(
		state => ({
			locations: getParsedAppointmentData(state, 'locations', tabId),
			attendees: getParsedAppointmentData(state, 'attendees', tabId),
			forwardAttendees: getParsedAppointmentData(state, 'forwardAttendees', tabId),
			availabilityVisible: getParsedAppointmentData(state, 'availabilityVisible', tabId),
			isDraft: getParsedAppointmentData(state, 'isDraft', tabId)
		})
	);
	const nonResourceAttendees = attendees.filter(
		attendee => attendee.calendarUserType !== CALENDAR_USER_TYPE.resource
	);

	const nonResourceForwardAttendees = forwardAttendees.filter(
		attendee => attendee.calendarUserType !== CALENDAR_USER_TYPE.resource
	);

	const organizer = get(appointmentData, 'message.invitations.0.components.0.organizer');
	let allAttendees = nonResourceAttendees.concat(nonResourceForwardAttendees);
	if (isProposeTime) {
		allAttendees = allAttendees.concat(organizer);
	}

	const filterDuplicateAddresses = useCallback(
		arr => {
			let hasDupes = false;
			const found = [],
				out = [];

			for (let i = 0; i < arr.length; i++) {
				const addr = arr[i];
				const stringToCheck = isLocation && isString(addr) ? addr : addr.address;

				if (found.indexOf(stringToCheck) === -1) {
					found.push(stringToCheck);
					out.push(addr);
				} else {
					hasDupes = true;
				}
			}

			return hasDupes ? out : arr;
		},
		[isLocation]
	);

	const attendeesChange = useCallback(
		event =>
			event.value.map(participant => {
				if (isString(participant)) {
					return participant;
				}

				// Find address in state if already stored

				const attendee = attendees.find(({ address }) => participant.address === address);

				// Delete not required properties
				const { shortName, originalEmail, ...restParticipant } = participant;

				return {
					// Default values
					role: ATTENDEE_ROLE.required,
					participationStatus: PARTICIPATION_STATUS.needsAction,
					rsvp: true,

					// Values stored in state
					...(attendee && {
						role: attendee.role,
						participationStatus: attendee.participationStatus,
						rsvp: attendee.rsvp
					}),

					// Values from address field
					...restParticipant
				};
			}),
		[attendees]
	);

	const handleForwardAttendeesChange = useCallback(
		event => {
			const filteredForwardAttendees = filterDuplicateAddresses(attendeesChange(event));

			const update = { forwardAttendees: filteredForwardAttendees };

			dispatch(
				setEvent({
					tabId,
					eventData: update
				})
			);
		},
		[attendeesChange, dispatch, filterDuplicateAddresses, tabId]
	);

	const handleAttendeesChange = useCallback(
		event => {
			const changedAttendees = attendeesChange(event);
			// If no valid attendee is present in draft mode, then hide inevent notification
			const oldAttendees = attendees;
			const hasValidAttendeeInCurrentlist = hasValidAttendee(changedAttendees);
			const hadValidAttendeeInOldlist = hasValidAttendee(oldAttendees);
			const showInEventNotification = isDraft && hasValidAttendeeInCurrentlist;
			const update = {
				attendees: changedAttendees,
				isFormDirty: true,
				...(!showInEventNotification && { showInEventNotification }),
				// allInviteesRemoved is true when user removes the last valid attendee.
				...(hadValidAttendeeInOldlist &&
					!hasValidAttendeeInCurrentlist && { allInviteesRemoved: true })
			};

			if (availabilityVisible) {
				const schedulerCandidates = changedAttendees.concat(
					locations.filter(
						loc => loc.calendarUserType === CALENDAR_USER_TYPE.resource || loc.originalEmail
					)
				);
				!schedulerCandidates.some(a => !isString(a)) && (update.availabilityVisible = false);
			}
			dispatch(
				setEvent({
					tabId,
					eventData: update
				})
			);
		},
		[
			attendees,
			attendeesChange,
			availabilityVisible,
			dispatch,
			hasValidAttendee,
			isDraft,
			locations,
			tabId
		]
	);

	const handleAttendeesPickerChange = useCallback(
		attendeesChangeValue => {
			isForwardInvite
				? handleForwardAttendeesChange({
						value: attendeesChangeValue.map(addressFromContact)
				  })
				: handleAttendeesChange({
						value: attendeesChangeValue.map(addressFromContact)
				  });
		},
		[handleAttendeesChange, handleForwardAttendeesChange, isForwardInvite]
	);

	const handleLocationChange = useCallback(
		({ value }) => {
			const mappedLocations = value.map(locationResource => {
				if (isString(locationResource)) {
					return locationResource;
				}

				const location = locations.find(({ address }) => locationResource.address === address);
				if (
					locationResource.zimbraCalResType ||
					locationResource.originalEmail ||
					locationResource.calendarUserType === CALENDAR_USER_TYPE.resource
				) {
					const { shortName, originalEmail, ...restLocation } = locationResource;

					return {
						// Default values
						role: ATTENDEE_ROLE.required,
						participationStatus: PARTICIPATION_STATUS.needsAction,
						rsvp: true,
						calendarUserType: CALENDAR_USER_TYPE.resource,

						// Values stored in state
						...(location && {
							role: location.role,
							participationStatus: location.participationStatus,
							rsvp: location.rsvp
						}),

						// Values from address field
						...restLocation
					};
				}

				return location || locationResource;
			});

			// Separate location resources and user text input and order them such that all user text inputs come at the end.
			const resources = [];
			let userInputs = '';
			mappedLocations.forEach(loc => {
				if (!isString(loc) && (loc.zimbraCalResType || loc.calendarUserType)) {
					resources.push(deepClone(loc));
				} else {
					userInputs = loc;
				}
			});

			const update = { locations: resources.concat(userInputs), isFormDirty: true };

			if (availabilityVisible) {
				const schedulerCandidates = attendees.concat(
					resources.filter(
						loc => loc.calendarUserType === CALENDAR_USER_TYPE.resource || loc.originalEmail
					)
				);
				!schedulerCandidates.some(a => !isString(a)) && (update.availabilityVisible = false);
			}

			dispatch(
				setEvent({
					tabId,
					eventData: update
				})
			);
		},
		[attendees, availabilityVisible, dispatch, locations, tabId]
	);

	const validateLocationToken = useCallback((address, token) => address || token, []);

	return (
		<Fragment>
			{showContactPicker && !isProposeTime && (
				<Fragment>
					<div id="contact_picker_attendees" />

					<ContactPicker
						// @FIXME temporary fix, contactpicker component should filter out selected contacts from full contact list, which is not happening correctly
						contacts={filterDuplicateAddresses(
							isForwardInvite ? nonResourceForwardAttendees : nonResourceAttendees
						)}
						onSave={handleAttendeesPickerChange}
						onClose={callWith(onToggleContactPicker, false)}
						into="#contact_picker_attendees"
					/>
				</Fragment>
			)}
			{showToAddressField && (isForwardInvite || isProposeTime) && (
				<ToAddress
					onOpenContactPicker={callWith(onToggleContactPicker, true)}
					handleForwardAttendeesChange={handleForwardAttendeesChange}
					isProposeTime={isProposeTime}
					organizer={[organizer]}
					nonResourceForwardAttendees={nonResourceForwardAttendees}
				/>
			)}
			<LocationChooser
				renderVideoZimletSlot={renderVideoZimletSlot}
				tabId={tabId}
				attendees={attendees}
				showToAddressField={showToAddressField}
				disabledElement={disabledElement}
				isForwardInvite={isForwardInvite}
				isProposeTime={isProposeTime}
				validateLocationToken={validateLocationToken}
				handleLocationChange={handleLocationChange}
			/>
			{!showToAddressField && (
				<Fragment>
					<FormGroup class={s.inviteesGroup}>
						<AlignedLabel class={cx(s.alignedLabel, disabledElement)} align="left">
							<Localizer>
								<a
									href="javascript:"
									aria-label={<Text id="contacts.picker.title" />}
									{
										/* temporarily disabled contact picker for mobile screen. implement PREAPPS-5928 for mobile screen and remove this temporary code. */
										...(!isForwardInvite &&
											matchesScreenMd &&
											!isProposeTime && { onClick: callWith(onToggleContactPicker, true) })
									}
								>
									<Text id="calendar.editModal.fields.invitees" />
								</a>
							</Localizer>
						</AlignedLabel>
						<AddressField
							class={cx(s.addressField, disabledElement)}
							value={nonResourceAttendees}
							onChange={handleAttendeesChange}
							formSize
							disabled={isForwardInvite || isProposeTime}
						/>
					</FormGroup>
					<AttendeesAvailabilityIndicator
						tabId={tabId}
						isForwardInvite={isForwardInvite}
						locations={locations}
						handleForwardAttendeesChange={handleForwardAttendeesChange}
						handleAttendeesChange={handleAttendeesChange}
						handleLocationChange={handleLocationChange}
						matchesScreenXs={matchesScreenXs}
						attendees={attendees}
						allAttendees={allAttendees}
						forwardAttendees={forwardAttendees}
						identitiesInfo={identitiesInfo}
						isProposeTime={isProposeTime}
					/>
				</Fragment>
			)}
		</Fragment>
	);
};
