import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import render from 'preact-render-to-string';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash-es/get';
import {
	prepareCalendarList,
	fetchRecurrenceText,
	zimbraFormat,
	getActiveCalendars
} from '../../utils/calendar';
import AppointmentDeleteMutation from '../../graphql/queries/calendar/appointment-delete.graphql';
import AppointmentCreateMutation from '../../graphql/queries/calendar/appointment-create.graphql';
import AppointmentCreateExceptionMutation from '../../graphql/queries/calendar/appointment-create-exception.graphql';
import AppointmentModifyMutation from '../../graphql/queries/calendar/appointment-modify.graphql';
import AppointmentMoveMutation from '../../graphql/queries/calendar/appointment-move.graphql';
import MessageQuery from '../../graphql/queries/message.graphql';
import CalendarsQuery from '../../graphql/queries/calendar/calendars.graphql';
import AppointmentsQuery from '../../graphql/queries/calendar/appointments.graphql';
import CalendarCreateMutation from '../../graphql/queries/calendar/calendar-create.graphql';
import withAccountInfo from '../account-info';
import {
	htmlInviteEmail,
	htmlEventEmail
} from '../../components/calendar/emails/html-invite-email';
import plainTextInviteEmail from '../../components/calendar/emails/plain-text-invite-email';
import { Text, IntlProvider } from 'preact-i18n';
import { USER_FOLDER_IDS } from '../../constants';
import { isValidEmail, getEmail, serializeAddress, deepClone } from '../../lib/util';
import isString from 'lodash-es/isString';
import { CALENDAR_USER_TYPE, TIMES } from '../../constants/calendars';
import { htmlToText } from '../../lib/html-email';
import { VIEW_DAY, VIEW_WEEK, VIEW_MONTH, VIEW_AGENDA } from '../../components/calendar/constants';
import getEmailHeaders from '../../vhtml-components/email-headers';
import momentTz from 'moment-timezone';
import { withIntlConsumer } from '../../enhancers/with-intl-consumer';

function inviteEmailMailParts({ message, ...options }) {
	let headerString;

	if (message) {
		const { subject, organizer, attendees, template } = options;
		const { subject: msgSubject, from: msgFrom, to: msgTo, ...restMsg } = message;

		headerString = getEmailHeaders({
			subject: msgSubject || subject,
			from: msgFrom || organizer,
			to: msgTo || attendees,
			template,
			...restMsg
		});
	}

	return [
		{
			contentType: 'text/plain',
			content: plainTextInviteEmail({
				headerString,
				...options
			})
		},
		{
			contentType: 'text/html',
			content: htmlInviteEmail({ headerString, ...options })
		}
	];
}

export function appointmentBody(options) {
	const { attendees, body } = options;

	// Meeting requests
	if (attendees.length) {
		return inviteEmailMailParts(options);
	}

	return [
		{
			contentType: 'text/plain',
			content: (body && htmlToText(body)) || ''
		},
		{
			contentType: 'text/html',
			content: (body && htmlEventEmail(options)) || ''
		}
	];
}

export function createLocationPayload(locations) {
	const payload = [];

	locations.forEach(location => {
		if (isString(location)) payload.push(location);
		else if (!(location.zimbraCalResType || location.calendarUserType)) {
			payload.push(location.address);
		} else {
			payload.push(serializeAddress(location.address, location.name));
		}
	});

	return payload.join('; ').trim();
}

function addResourcesToAttendees(attendees, locations) {
	// In order to find delta of resources in attendees, re-populate all resources.
	let attendeesClone = deepClone(
		attendees.filter(att => !(att.calendarUserType === CALENDAR_USER_TYPE.resource))
	);

	attendeesClone = attendeesClone.concat(
		locations.filter(loc => {
			if (
				loc.zimbraCalResType === 'Location' ||
				loc.calendarUserType === CALENDAR_USER_TYPE.resource
			) {
				delete loc.zimbraCalResType;
				delete loc.__typename;
				return true;
			}
			return false;
		})
	);

	return attendeesClone;
}

function createAppointmentMutationVariables({
	inviteId,
	folderId,
	ownerEmail,
	modifiedSequence,
	revision,
	name,
	locations,
	start,
	end,
	exceptId,
	alarms,
	recurrence,
	freeBusy,
	allDay,
	isPrivate,
	shouldSaveAsLocalCopy,
	notes,
	attachments = [],
	newAttachments = [],
	attendees = [],
	componentNum,
	organizer = {},
	status,
	folderIdShared,
	accountName,
	primaryAddress,
	displayName,
	intl,
	isDraft,
	eventStartTimeZone,
	eventEndTimeZone,
	significent,
	isQuickAddEvent
}) {
	let emailAddresses = [];
	const organizerAddress = get(organizer, 'address');
	const organizerName = get(organizer, 'name');
	const organizerSentby = get(organizer, 'sentBy');
	organizer = {
		address: organizerAddress || ownerEmail || primaryAddress,
		name: organizerName || displayName,
		sentBy:
			organizerSentby || organizerAddress || ownerEmail !== primaryAddress ? primaryAddress : null
	};

	!shouldSaveAsLocalCopy &&
		emailAddresses.push({
			address: ownerEmail || primaryAddress,
			type: 'f'
		});

	const locationString = createLocationPayload(locations);
	const startTimezone = eventStartTimeZone || momentTz.tz.guess();
	const endTimezone = eventEndTimeZone || momentTz.tz.guess();
	attendees = addResourcesToAttendees(attendees, locations);

	const attendeesVal = attendees.filter(
		token => !isString(token) && isValidEmail(getEmail(token.address)) // Incomplete address
	); // Invalid email

	const isQuickEventWithLocation = isQuickAddEvent && !isDraft;

	if ((!isDraft && significent) || isQuickEventWithLocation) {
		//When the owner email and primary address are different the user is sending an invite on behalf of someone else.
		const sentBy = ownerEmail === primaryAddress ? ownerEmail : primaryAddress;

		if (!shouldSaveAsLocalCopy) {
			emailAddresses.push({
				address: sentBy,
				type: 's'
			});

			emailAddresses = emailAddresses.concat(
				attendeesVal.map(attendee => ({
					address: attendee.address,
					type: 't'
				}))
			);
		}
	}

	const startVal = {
		date: zimbraFormat(start, allDay),
		...(!allDay && { timezone: startTimezone })
	};
	const endVal =
		allDay && zimbraFormat(end, allDay) === startVal
			? null
			: { date: zimbraFormat(end, allDay), ...(!allDay && { timezone: endTimezone }) };
	const classType = isPrivate ? 'PRI' : 'PUB';

	//fields only set when modifying an appointment
	const modifyFields = !inviteId
		? {}
		: {
				id: inviteId,
				modifiedSequence,
				revision
		  };

	const intlHelper = params =>
		render(
			<IntlProvider definition={intl.dictionary}>
				<Text {...params} />
			</IntlProvider>
		);

	const weekOrder = {
		order1: intlHelper({ id: 'calendar.dialogs.customRecurrence.repeatSection.weekOrder.first' }),
		order2: intlHelper({ id: 'calendar.dialogs.customRecurrence.repeatSection.weekOrder.second' }),
		order3: intlHelper({ id: 'calendar.dialogs.customRecurrence.repeatSection.weekOrder.third' }),
		order4: intlHelper({ id: 'calendar.dialogs.customRecurrence.repeatSection.weekOrder.fourth' }),
		order5: intlHelper({ id: 'calendar.dialogs.customRecurrence.repeatSection.weekOrder.last' })
	};

	const customRecurrence = fetchRecurrenceText({
		recurrence,
		startTime: start,
		newEvent: true,
		...weekOrder
	});

	const descriptionText = customRecurrence
		? intlHelper({
				id: `calendar.dialogs.customRecurrence.recurrence.${customRecurrence.customRecurrenceString}`,
				fields: customRecurrence.customRecurrenceObject
		  })
		: '';

	const description = {
		mimeParts: {
			contentType: 'multipart/alternative',
			mimeParts: appointmentBody({
				organizer,
				start,
				end,
				location: locationString,
				attendees: attendeesVal,
				repeats: descriptionText,
				subject: name,
				body: notes,
				template: get(intl.dictionary, 'calendar.invite')
			})
		}
	};

	return {
		appointment: {
			...modifyFields,
			componentNum,
			message: {
				folderId: folderIdShared || folderId || String(USER_FOLDER_IDS.CALENDAR),
				subject: name,
				invitations: {
					components: [
						{
							name,
							location: locationString,
							alarms,
							...(!exceptId && { recurrence }),
							freeBusy,
							allDay,
							class: classType,
							organizer,
							start: startVal,
							end: endVal,
							status,
							// Pass exceptId only when creating an exception
							...(exceptId &&
								(get(exceptId, 'date') ? { exceptId } : { exceptId: { date: exceptId } })),
							attendees: attendeesVal,
							draft: isDraft
						}
					]
				},
				emailAddresses,
				...description,
				...((attachments.length || newAttachments.length) && {
					attachments: {
						...(newAttachments.length && {
							attachmentId: newAttachments.join(',')
						}),
						...(attachments.length && {
							existingAttachments: attachments.map(att => ({
								messageId: att.messageId,
								part: att.part
							}))
						})
					}
				})
			}
		},
		accountName
	};
}

export function withAppointmentData() {
	return graphql(MessageQuery, {
		name: 'appointmentData',
		skip: props => !get(props, 'event.inviteId'),
		// For delegated events, send entire invite id. For non-delegated invite id, strip message id.
		options: ({ event: { inviteId, utcRecurrenceId } }) => ({
			variables: {
				id: inviteId,
				ridZ: utcRecurrenceId,
				max: 250000
			}
		})
	});
}

export function withDeleteAppointment() {
	return graphql(AppointmentDeleteMutation, {
		props: ({ mutate }) => ({
			deleteAppointment: ({ inviteId, componentNum = 0, date, start, message }) =>
				mutate({
					variables: {
						appointment: {
							inviteId,
							componentNum,
							...(date && { instanceDate: date }),
							...(start && start),
							...(message && message)
						}
					}
				})
		})
	});
}

export function withCalendars(mapProps, config = {}) {
	const { options: { fetchPolicy = 'cache-first' } = {}, ...restConfig } = config;

	return compose(
		withAccountInfo(({ data: { accountInfo } }) => ({
			zimbraFeatureSharingEnabled: get(accountInfo, 'attrs.zimbraFeatureSharingEnabled')
		})),
		graphql(CalendarsQuery, {
			...restConfig,
			options: ({ isOffline }) => ({
				fetchPolicy: isOffline ? 'cache-only' : fetchPolicy
			}),
			props: result => {
				const {
					data: { getFolder, loading },
					ownProps: { zimbraFeatureSharingEnabled }
				} = result;

				const combinedCalenders = [
					...(get(getFolder, 'folders.0.folders') || []),
					...((zimbraFeatureSharingEnabled && get(getFolder, 'folders.0.linkedFolders')) || [])
				];

				if (mapProps) {
					return mapProps(result, combinedCalenders);
				}

				return {
					...prepareCalendarList(
						deepClone(get(getFolder, 'folders.0.folders')),
						zimbraFeatureSharingEnabled && get(getFolder, 'folders.0.linkedFolders')
					),
					loading
				};
			}
		})
	);
}

export function withAppointments(mapProps, config = {}) {
	const { skip, options } = config;

	return compose(
		connect(
			state => ({
				checkedTags: get(state, 'tags.checkedTags')
			}),
			null
		),
		graphql(AppointmentsQuery, {
			skip: typeof skip === 'function' && skip,
			options:
				typeof options === 'function'
					? options
					: ({ date, view, calendars, isOffline, tags, checkedTags }) => {
							const { fetchPolicy, variables } = options;

							let start = moment(date),
								end;

							switch (view) {
								case VIEW_DAY:
									start = start.startOf('day');
									break;
								case VIEW_WEEK:
									start = start.startOf('week');
									break;
								case VIEW_MONTH:
									start = start.startOf('month');
									break;
								case VIEW_AGENDA:
									start = start.startOf('month');
									end = moment(start).endOf('month');
									break;
							}
							const calendarIdsQuery = getActiveCalendars(calendars)
								.map(({ id }) => `inid:"${id}"`)
								.join(' OR ');

							const tagList = tags
								.filter(({ id }) => checkedTags.includes(id))
								.map(({ name }) => `tag:"${name}"`)
								.join(' OR ');

							return {
								variables: {
									...variables,
									calExpandInstStart: start.valueOf() - TIMES[view],
									calExpandInstEnd: (end && end.valueOf()) || start.valueOf() + TIMES[view] * 3,
									query: `${tagList ? `(${tagList}) AND` : ''} (${calendarIdsQuery})`
								},
								fetchPolicy: isOffline ? 'cache-only' : fetchPolicy
							};
					  },
			props: result => {
				if (mapProps) {
					return mapProps(result);
				}

				const {
					data: { getAppointments, refetch: refetchAppointments, loading: loadingAppointments }
				} = result;

				return {
					appointments: get(getAppointments, 'appointments') || [],
					refetchAppointments,
					loadingAppointments
				};
			}
		})
	);
}

export function withCreateCalendar() {
	return graphql(CalendarCreateMutation, {
		props: ({ mutate }) => ({
			createCalendar: (name, color, url) =>
				mutate({
					variables: {
						name,
						color: Number(color),
						url
					}
				})
		})
	});
}

export function withMoveAppointment() {
	return graphql(AppointmentMoveMutation, {
		props: ({ mutate }) => ({
			moveAppointment: ({ calendarItemId, destFolderId }) =>
				mutate({
					variables: { id: calendarItemId, folderId: destFolderId }
				})
		})
	});
}

const appointmentMutationFactory = (mutationName, propName, updateMessageQuery) => () =>
	compose(
		withIntlConsumer(),
		graphql(mutationName, {
			props: ({ ownProps: { primaryAddress, intl }, mutate }) => ({
				[propName]: (appointmentInput, folderIdShared, accountName) => {
					const mutationVariables = createAppointmentMutationVariables({
						...appointmentInput,
						folderIdShared,
						accountName,
						primaryAddress,
						intl
					});

					const mutateOptions = {
						variables: mutationVariables,

						// Update MessageQuery data if required
						...(updateMessageQuery && {
							refetchQueries: [
								{
									query: MessageQuery,
									variables: {
										id: appointmentInput.inviteId,
										ridZ: appointmentInput.utcRecurrenceId,
										max: 250000
									}
								}
							]
						})
					};

					return mutate(mutateOptions);
				}
			})
		})
	);

export const withCreateAppointment = appointmentMutationFactory(
	AppointmentCreateMutation,
	'createAppointment'
);

export const withCreateAppointmentException = appointmentMutationFactory(
	AppointmentCreateExceptionMutation,
	'createAppointmentException'
);

export const withModifyAppointment = appointmentMutationFactory(
	AppointmentModifyMutation,
	'modifyAppointment',
	true
);
