import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';

const initialState = {
	isOffline: false,
	isLoggedOut: false,
	showOfflineInfo: false,
	pendingSyncCount: 0,
	// Set to true when the App is syncing offline changes back to the server
	isSyncInProgress: false,
	isLoginVisible: false
};

export default handleActions(
	{
		[actionCreators.offlineConnectionStatus]: (state, action) => ({
			...state,
			isOffline: action.payload
		}),
		[actionCreators.logoutStatus]: (state, action) => ({
			...state,
			isLoggedOut: action.payload
		}),
		[actionCreators.offlineInfoVisibility]: (state, action) => ({
			...state,
			showOfflineInfo: action.payload
		}),
		[actionCreators.setLoginVisible]: (state, action) => ({
			...state,
			isLoginVisible: action.payload
		}),
		[actionCreators.setSyncInProgress]: (state, action) => {
			const count = action.payload
				? state.pendingSyncCount + 1
				: state.pendingSyncCount > 0
				? state.pendingSyncCount - 1
				: 0;
			return {
				...state,
				pendingSyncCount: count,
				isSyncInProgress: count > 0
			};
		}
	},
	initialState
);
