import { useCallback } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import style from './style';
import { ChoiceInput } from '@zimbra/blocks';
import cx from 'classnames';
import ListHeaderActionMenu from '../../list-header-action-menu';
import { clearSelection } from '../../../store/dumpster/actions';

const SORT_MENU_ORDERED_LIST = ['date', 'size'];

export function MsgListHeader({ actions, isIndeterminate, allSelected, sortBy, onSort }) {
	const dispatch = useDispatch();
	const handleIndeterminate = useCallback(() => dispatch(clearSelection()), [dispatch]);

	return (
		<div class={style.msgListHeader}>
			<div class={cx(style.row, style.sort)}>
				<div class={style.leftContainer}>
					<ChoiceInput
						indeterminate={isIndeterminate}
						checked={allSelected}
						onChange={isIndeterminate ? handleIndeterminate : actions.toggleSelectAll}
					/>
				</div>
				<ListHeaderActionMenu
					sortValue={sortBy}
					onSort={onSort}
					sortMenuOrderList={SORT_MENU_ORDERED_LIST}
				/>
			</div>
		</div>
	);
}
