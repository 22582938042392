export default function postMessagesToWebkit(methodName, data = {}) {
	if (typeof webkit !== 'undefined') {
		try {
			webkit.messageHandlers.postMessagesHandler.postMessage({
				methodName,
				...data
			});
		} catch (err) {
			console.error(err);
		}
	}
}
