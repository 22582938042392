import { useState, useEffect } from 'preact/hooks';

/**
 * A custom hook used to detect whether the window size matches a specified media query.
 * Useful to conditionally add logic to the UI for different screen sizes.
 *
 * @param {String} mq    	A string specifying the media query to match against
 * @param {String} propName The name of the variable you would like to use
 * 							to indicate whether the media query is a match or not.
 */
export function useMediaQuery(mq, propName = 'matchesMediaQuery') {
	const [matchesMediaQuery, setMatchesMq] = useState(window.matchMedia(mq).matches);

	useEffect(() => {
		const mediaQuery = window.matchMedia(mq);
		const handleMediaQueryChange = e => {
			setMatchesMq(e.matches);
		};

		if ('addEventListener' in mediaQuery) {
			mediaQuery.addEventListener('change', handleMediaQueryChange);
		} else if ('addListener' in mediaQuery) {
			mediaQuery.addListener(handleMediaQueryChange);
		}

		return () => {
			if (mediaQuery) {
				if ('removeEventListener' in mediaQuery) {
					mediaQuery.removeEventListener('change', handleMediaQueryChange);
				} else if ('removeListener' in mediaQuery) {
					mediaQuery.removeListener(handleMediaQueryChange);
				}
			}
		};
	}, [mq]);

	return { [propName]: matchesMediaQuery };
}
