export function getFormattedString(attrs, template) {
	if (!template || !template.fields) {
		return '';
	}
	const separator = template.separator || ' ';
	const fieldsToJoin = [];
	for (const field of template.fields) {
		if (field instanceof Object) {
			fieldsToJoin.push(getFormattedString(attrs, field));
		} else {
			fieldsToJoin.push(attrs[field]);
		}
	}
	return fieldsToJoin.filter(Boolean).join(separator);
}
