import { USER_FOLDER_IDS } from '../constants/index';
import flatten from 'lodash-es/flatten';

export const CANNOT_MOVE_INTO_LOCALFOLDER = [
	USER_FOLDER_IDS.TRASH.toString(),
	USER_FOLDER_IDS.JUNK.toString(),
	USER_FOLDER_IDS.DRAFTS.toString()
];

export function getFilteredItemsForLocalFolder(mailItems, type) {
	const msgIdsToDownload = [];

	if (type === 'conversation') {
		mailItems.forEach(conv => {
			const moveableMsgs = conv.messagesMetaData.filter(
				({ folderId }) => !~CANNOT_MOVE_INTO_LOCALFOLDER.indexOf(folderId)
			);

			if (moveableMsgs.length) {
				const moveableMsgIds = moveableMsgs.map(({ id }) => id);
				msgIdsToDownload.push(moveableMsgIds);
			}
		});
	} else {
		const moveableMsgIds = mailItems.map(({ id }) => id);
		msgIdsToDownload.push(moveableMsgIds);
	}

	return {
		...(msgIdsToDownload.length && {
			msgIdsToDownload: flatten(msgIdsToDownload)
		})
	};
}

export function desktopRefetchFolder({ refetchFolders, refetchLocalFolders, local, isOffline }) {
	//ToDo remove once no op handling done for local storage
	local && refetchLocalFolders && refetchLocalFolders();

	typeof process.env.ELECTRON_ENV !== 'undefined' &&
		isOffline &&
		refetchFolders &&
		refetchFolders();
}
