import pick from 'lodash-es/pick';
import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';
import { getBasePath } from '../../lib/util';
import queryString from 'query-string';

// NOTE: The state is actually a URI not a URL.

const basePath = getBasePath();

const initialLocation = pickLocation(window.location);

function pickLocation(obj) {
	return pick(obj, [
		'hash',
		'host',
		'hostname',
		'href',
		'origin',
		'pathname',
		'port',
		'protocol',
		'search'
	]);
}

const anchor = document.createElement('a');
function urlToLocation(url) {
	// Remove basepath when storing urls as router will be adding it back when used
	if (url.includes(basePath)) {
		url = url.replace(basePath, '');
	}

	anchor.href = url;
	return pickLocation(anchor);
}

const initialState = {
	prevLocation: undefined,
	location: initialLocation,
	view: null,
	routeProps: {}
};

/**
 * Given a uri of either /{view}/restOfPath or /search/{view}/restOfPath, returns {view}
 *
 * @param {String} uri     A URI (no origin)
 * @returns {String}       The view portion of the path
 * @example
 *   getView('/someroute/foo') === 'someroute'
 *   getView('/search/someroute/foo') === 'someroute'
 */
function getView(uri = '', defaultSlugs) {
	const matched = uri.match(/^\/(?:search\/)?([^/]+)/),
		view = matched && matched[1];

	// To activate integrations tab when url doesn't start with any of vertical slug.
	return !view ? defaultSlugs.email : defaultSlugs[view] || defaultSlugs.integrations;
}

function getTabIdFromUrl(searchString) {
	return queryString.parse(searchString).tabid;
}

export default handleActions(
	{
		[actionCreators.setUrl]: (state, action) => {
			const { url, routeProps, slugs = {} } = action.payload;
			const location = urlToLocation(url);
			const previousLocationTabId = getTabIdFromUrl(state.location.search);
			const newLocationTabId = getTabIdFromUrl(location.search);
			const tempPrevLocation =
				state.location.href !== location.href &&
				(!previousLocationTabId || !newLocationTabId || previousLocationTabId !== newLocationTabId) // Don't update prevLocation while url updated after first autosave and if tabId is not changed
					? state.location
					: state.prevLocation;

			return {
				...state,
				prevLocation: tempPrevLocation,
				location,
				view: getView(location.pathname, slugs),
				routeProps
			};
		}
	},
	initialState
);
