import { Component } from 'preact';
import { Text } from 'preact-i18n';
import AddressField from '../../../address-field';
import ModalDialog from '../../../modal-dialog';
import { SEND_AS, SEND_ON_BEHALF, FREE_BUSY_RIGHTS } from '../../../../constants/rights';
import { withGrantRights, withRevokeRights } from '../../../../graphql-decorators/rights';
import get from 'lodash-es/get';
import linkState from 'linkstate';
import style from './style';

function getRightsInput(right) {
	return {
		granteeType: FREE_BUSY_RIGHTS.user,
		right
	};
}

@withGrantRights()
@withRevokeRights()
export default class AddEditDelegatesDialog extends Component {
	state = {
		sendAsRight: this.props.sendAsRight,
		sendOnBehalfOfRight: this.props.sendOnBehalfOfRight,
		value: this.props.value || []
	};

	handleSaveDelegate = () => {
		if (this.state.error) {
			this.setState({ error: undefined });
		}

		this.setState({ pending: true });

		const {
			sendAsRight: prevSendAsRight,
			sendOnBehalfOfRight: prevSendOnBehalfOfRight,
			onGrantRights,
			onRevokeRights,
			onClose
		} = this.props;

		const {
			value,
			sendAsRight: nextSendAsRight,
			sendOnBehalfOfRight: nextSendOnBehalfOfRight
		} = this.state;

		// If the TokenInput has a contact, it will be an Object. Otherwise use the string value.
		const address = value && (typeof value[0] === 'object' ? value[0].address : value[0]);

		const newGrantRights = [
			!prevSendAsRight && nextSendAsRight && getRightsInput(SEND_AS),
			!prevSendOnBehalfOfRight && nextSendOnBehalfOfRight && getRightsInput(SEND_ON_BEHALF)
		].filter(Boolean);

		const newRevokeRights = [
			prevSendAsRight && !nextSendAsRight && getRightsInput(SEND_AS),
			prevSendOnBehalfOfRight && !nextSendOnBehalfOfRight && getRightsInput(SEND_ON_BEHALF)
		].filter(Boolean);

		const promises = [];

		if (onGrantRights && newGrantRights.length) {
			promises.push(
				onGrantRights(address, newGrantRights).catch(e => {
					const errorCode = get(e, 'graphQLErrors.0.originalError.faults.0.Detail.Error.Code');
					const message = /NO_SUCH_ACCOUNT/.test(errorCode) ? (
						<Text id="settings.writingEmail.delegates.errors.noSuchAccount" />
					) : (
						e.message
					);

					this.setState({
						error: message,
						pending: false
					});
					return Promise.reject();
				})
			);
		}

		if (onRevokeRights && newRevokeRights.length) {
			onRevokeRights(address, newRevokeRights);
		}

		Promise.all(promises)
			.then(() => {
				this.setState({ pending: false });
				onClose();
			})
			.catch(() => {
				this.setState({ pending: false });
			});
	};

	render({ onClose, disableInput }, { value, sendAsRight, sendOnBehalfOfRight, error, pending }) {
		const disabled = !(sendAsRight || sendOnBehalfOfRight) || !value[0];
		return (
			<ModalDialog
				pending={pending}
				error={error}
				onClose={onClose}
				onAction={this.handleSaveDelegate}
				disablePrimary={disabled}
				title="settings.writingEmail.delegates.delegatePermissions"
			>
				<p>
					<Text id="settings.writingEmail.delegates.usersHaveDelegated" />
				</p>
				<AddressField
					isGalOnly
					maxTokens={1}
					tokenInputClass={style.tokenInput}
					value={value}
					onChange={linkState(this, 'value', 'value')}
					disabled={disableInput}
				/>

				<table class={style.checkboxes} role="presentation">
					<tbody>
						<tr>
							<td>
								<input
									id="sendAs"
									type="checkbox"
									checked={!!sendAsRight}
									onInput={linkState(this, 'sendAsRight')}
									onChange={linkState(this, 'sendAsRight')}
								/>
								<label for="sendAs">
									<Text id="settings.writingEmail.delegates.delegateSendAs" />
								</label>
							</td>
						</tr>

						<tr>
							<td>
								<input
									id="sendOnBehalfOf"
									type="checkbox"
									checked={!!sendOnBehalfOfRight}
									onInput={linkState(this, 'sendOnBehalfOfRight')}
									onChange={linkState(this, 'sendOnBehalfOfRight')}
								/>
								<label for="sendOnBehalfOf">
									<Text id="settings.writingEmail.delegates.delegateSendOnBehalfOf" />
								</label>
							</td>
						</tr>
					</tbody>
				</table>
			</ModalDialog>
		);
	}
}
