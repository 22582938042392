import { TIME_FORMATS } from './../constants/locale';
import moment from 'moment';

export const defaultLocaleFormat = () => {
	const defaultFormat = moment.localeData().longDateFormat('LT');

	if (defaultFormat.includes('HH')) {
		return TIME_FORMATS.FORMAT_0_24;
	} else if (defaultFormat.includes('H')) {
		return TIME_FORMATS.FORMAT_24;
	} else if (defaultFormat.includes('hh')) {
		return TIME_FORMATS.FORMAT_0_12;
	}
	return TIME_FORMATS.FORMAT_12;
};

//current date won’t always show leading zeros, that’s why we need a single digit example.
export const DATE_EXAMPLE = '09/01/2021';

export const TIME_FORMAT_OPTIONS = [
	{ value: TIME_FORMATS.FORMAT_12, label: 'settings.general.timeFormatOptions.AMPM' },
	{ value: TIME_FORMATS.FORMAT_0_12, label: 'settings.general.timeFormatOptions.AMPMWithZero' },
	{ value: TIME_FORMATS.FORMAT_24, label: 'settings.general.timeFormatOptions.NOAMPM' },
	{ value: TIME_FORMATS.FORMAT_0_24, label: 'settings.general.timeFormatOptions.NOAMPMWithZero' }
];

// Getting date format options to show in settings as an example of given date i.e 01/09/2021
export const getDateFormatOptions = intl => {
	const date = moment(DATE_EXAMPLE, 'DD/MM/YYYY');
	const dateFormats = intl?.dictionary?.settings?.general?.dateFormats || {};
	return [
		{
			value: dateFormats['DD/MM/YYYY'],
			label: date.format(dateFormats['DD/MM/YYYY'])
		},
		{
			value: dateFormats['MM/DD/YYYY'],
			label: date.format(dateFormats['MM/DD/YYYY'])
		},
		{
			value: dateFormats['YYYY-MM-DD'],
			label: date.format(dateFormats['YYYY-MM-DD'])
		},
		{
			value: dateFormats['DD-MMM-YY'],
			label: date.format(dateFormats['DD-MMM-YY'])
		}
	];
};

export const defaultDateFormat = () => moment.localeData().longDateFormat('[defaultL]');

export const getDefaultDateFormat = momentLocale => {
	//here we are setting momentLocale locally to get default value of L from moment defined locale
	return moment().locale(momentLocale).localeData().longDateFormat('L');
};

//Checking that getDateFormatOptions() having default date format(L) option of moment locale.
export const haveLocaleDefaultDateFormat = intl => {
	const defaultFormat = defaultDateFormat();
	return getDateFormatOptions(intl).some(option => option.value === defaultFormat);
};

export const selectedDateFormat = (zimbraPrefDateFormat, defaultL, dateFormats) => {
	const selectedFormatKey = Object.keys(dateFormats).find(
		key => dateFormats[key] === zimbraPrefDateFormat
	);

	return { L: dateFormats[selectedFormatKey] || defaultL };
};

export const getDateFormat = (dateFormat, intl) => {
	const DATE_FORMAT_OPTIONS = getDateFormatOptions(intl);
	const defaultLocaleDateFormat = defaultDateFormat();
	// if options not having default date format then here we are adding default format (L) for each locale
	const DATE_OPTIONS = !haveLocaleDefaultDateFormat(intl)
		? DATE_FORMAT_OPTIONS.concat({
				value: defaultLocaleDateFormat,
				label: moment(DATE_EXAMPLE, 'DD/MM/YYYY').format(defaultLocaleDateFormat)
		  })
		: DATE_FORMAT_OPTIONS;
	const defaultDateFormatValue =
		dateFormat && DATE_FORMAT_OPTIONS.some(({ value: optionValue }) => optionValue === dateFormat)
			? dateFormat
			: defaultLocaleDateFormat;

	return { defaultDateFormatValue, DATE_OPTIONS };
};

export const isLocaleJapan = locale => locale === 'ja';
