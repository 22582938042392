import cx from 'classnames';
import { Icon } from '@zimbra/blocks';
import COLORS from '../../../constants/colors';
import style from './style';

export default function TagPill({
	tag,
	onTagRemove,
	onHandleRouteToTag,
	className,
	isRemovable = true
}) {
	return (
		<span
			class={cx(style.pill, className)}
			style={{ backgroundColor: COLORS[tag.color || 0] }}
			title={tag.name}
		>
			<span class={style.name} onClick={onHandleRouteToTag}>
				{tag.name}
			</span>
			{isRemovable && <Icon class={style.icon} name="close" size="sm" onClick={onTagRemove} />}
		</span>
	);
}
