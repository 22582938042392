import { Fragment } from 'preact';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'preact/hooks';
import { Text } from 'preact-i18n';
import partition from 'lodash-es/partition';
import chooseFiles from 'choose-files';
import style from '../style.less';
import TinyMceComposer from '../../../tinymce-composer';
import FormGroup from '../../../form-group';
import AlignedLabel from '../../../aligned-form/label';
import { EDITOR_TYPE_NOTES } from '../../../../constants/tinymce';
import AttachmentGrid from '../../../attachment-grid';
import { hasAttachmentPreview, encodeAttachment } from '../../../../utils/attachments';
import { handleFileSizeExceed } from '../../../../lib/util';
import { getParsedAppointmentData } from '../../../../store/calendar/selectors';
import { setEvent } from '../../../../store/calendar/actions';
import { notify, clear } from '../../../../store/notifications/actions';
import { ZmEditorToolbar } from '../../../zmeditor-toolbar';
import NakedButton from '../../../naked-button';
import { Icon } from '@zimbra/blocks';

export const EventNotes = ({
	tabId,
	isForwardInvite,
	isProposeTime,
	isOffline,
	hideAttachmentPreview,
	zimbraFeatureViewInHtmlEnabled,
	zimbraFeatureDocumentEditingEnabled,
	zimbraMailAttachmentMaxSize,
	zimbraMtaMaxMessageSize
}) => {
	const dispatch = useDispatch();

	const { notes, attachments } = useSelector(state => ({
		notes: getParsedAppointmentData(state, 'notes', tabId),
		attachments: getParsedAppointmentData(state, 'attachments', tabId)
	}));

	const notesId = `zimbra-notes-${tabId}`;

	const handleNotesChange = useCallback(
		(e, body) => {
			dispatch(
				setEvent({
					tabId,
					eventData: {
						notes: body,
						isFormDirty: true
					}
				})
			);
		},
		[dispatch, tabId]
	);

	const addAttachments = useCallback(
		choosedAttachments => {
			if (isOffline) return;
			const maxAttachmentSizeLimit =
				zimbraMailAttachmentMaxSize !== 0 && zimbraMailAttachmentMaxSize < zimbraMtaMaxMessageSize
					? zimbraMailAttachmentMaxSize
					: zimbraMtaMaxMessageSize;
			const sizeFilteredAttachments = handleFileSizeExceed(
				choosedAttachments,
				maxAttachmentSizeLimit
			);
			const BYTES_TO_MB = 1024 * 1024;

			if (choosedAttachments.length > sizeFilteredAttachments.length) {
				dispatch(
					notify({
						message: (
							<Text
								id="dialogs.attachmentWarning.attachmentCount"
								plural={choosedAttachments.length}
								fields={{
									sizeLimit: Math.round(maxAttachmentSizeLimit / BYTES_TO_MB)
								}}
							/>
						),
						failure: true,
						action: {
							label: <Text id="buttons.ok" />,
							fn: () => {
								dispatch(clear());
							}
						}
					})
				);
				if (sizeFilteredAttachments.length === 0)
					//if the length of the updated files list is zero then only we will return
					return;
			}

			let [previewAttachments, otherAttachments] = partition(sizeFilteredAttachments, attachment =>
				hasAttachmentPreview(
					attachment,
					zimbraFeatureViewInHtmlEnabled,
					zimbraFeatureDocumentEditingEnabled
				)
			);
			previewAttachments = previewAttachments.map(encodeAttachment);
			Promise.all(previewAttachments).then(res => {
				dispatch(
					setEvent({
						tabId,
						eventData: {
							isChoosingAttachments: false,
							isErrored: false,
							attachments: [...attachments, ...res, ...otherAttachments],
							isFormDirty: true
						}
					})
				);
			});
		},
		[
			attachments,
			dispatch,
			isOffline,
			tabId,
			zimbraFeatureViewInHtmlEnabled,
			zimbraMailAttachmentMaxSize,
			zimbraMtaMaxMessageSize,
			zimbraFeatureDocumentEditingEnabled
		]
	);

	const chooseAttachments = useCallback(() => {
		dispatch(
			setEvent({
				tabId,
				eventData: {
					isChoosingAttachments: true
				}
			})
		);
		chooseFiles(addAttachments);
	}, [addAttachments, dispatch, tabId]);

	const removeAttachment = useCallback(
		({ attachment, all = false }) => {
			dispatch(
				setEvent({
					tabId,
					eventData: {
						isErrored: false,
						attachments:
							all && !attachment
								? []
								: attachments.filter(a =>
										attachment.part ? a.part !== attachment.part : a !== attachment
								  ),
						isFormDirty: true
					}
				})
			);
		},
		[attachments, dispatch, tabId]
	);

	return (
		<Fragment>
			<FormGroup>
				<AlignedLabel
					class={style.alignedLabel}
					align="left"
					textId="calendar.editModal.fields.notes"
				/>
				<div class={style.notesContainer}>
					<TinyMceComposer
						value={notes}
						composerId={notesId}
						onEditorChange={handleNotesChange}
						editorType={EDITOR_TYPE_NOTES}
					/>
					<ZmEditorToolbar
						leftItems={
							<NakedButton
								title={<Text id="calendar.editModal.buttons.addAttachment" />}
								onClick={chooseAttachments}
								disabled={isOffline || isForwardInvite || isProposeTime}
								customToolbarAction
							>
								<Icon name="paperclip" />
							</NakedButton>
						}
						toolbarWrapperId={`${notesId}-Toolbar`}
						class={style.notesToolbar}
					/>
				</div>
			</FormGroup>
			{attachments.length > 0 && (
				<FormGroup>
					<div class={style.attachmentContainer}>
						<AttachmentGrid
							attachments={attachments}
							hideAttachmentPreview={hideAttachmentPreview}
							removable={!isProposeTime}
							onRemove={!isProposeTime && removeAttachment}
							onChooseAttachments={chooseAttachments}
						/>
					</div>
				</FormGroup>
			)}
		</Fragment>
	);
};
