import {
	MINUTES_TO_SECONDS,
	HOURS_TO_SECONDS,
	DAYS_TO_SECONDS,
	MILLISECONDS_MULTIPLIER
} from '../constants/mail-polling';

import {
	MANUAL_REFRESH,
	MIN_INTERVAL_VALUE,
	AS_NEW_MAIL_ARRIVES,
	DEFAULT_INTERVAL
} from '../constants/mail';

export function convertToMilliseconds(time) {
	return convertToSeconds(time) * MILLISECONDS_MULTIPLIER;
}

/**
 * Handles the different possibilities of the time's value in string and returns the time accordingly.
 *
 * @param {String} time time string
 */
export function convertToSeconds(time) {
	// For each type of time format (that server returns), convert to seconds appropriately.
	if (time.indexOf('d') > -1) {
		return parseInt(time, 10) * DAYS_TO_SECONDS;
	} else if (time.indexOf('h') > -1) {
		return parseInt(time, 10) * HOURS_TO_SECONDS;
	} else if (time.indexOf('ms') > -1) {
		return parseInt(time, 10) / MILLISECONDS_MULTIPLIER;
	} else if (time.indexOf('m') > -1) {
		return parseInt(time, 10) * MINUTES_TO_SECONDS;
	}

	return parseInt(time, 10);
}

/**
 * Converts the server polling interval's value to the millisecond equivalent. Parses the string
 * sent by the server. This method will be removed when we remove the polling from search request
 *
 * @param {String|Number} zimbraPrefMailPollingInterval preference value of the interval set on server
 * @returns {Number}
 */
export function getMailPollingInterval(zimbraPrefMailPollingInterval) {
	let mailPollingInterval = 0;

	if (zimbraPrefMailPollingInterval && zimbraPrefMailPollingInterval !== MANUAL_REFRESH) {
		mailPollingInterval =
			zimbraPrefMailPollingInterval === AS_NEW_MAIL_ARRIVES
				? MIN_INTERVAL_VALUE
				: zimbraPrefMailPollingInterval;

		mailPollingInterval = Math.max(
			MIN_INTERVAL_VALUE * MILLISECONDS_MULTIPLIER,
			convertToMilliseconds(mailPollingInterval) || DEFAULT_INTERVAL * MILLISECONDS_MULTIPLIER
		);
	}

	return mailPollingInterval;
}

/**
 * Converts the server polling interval's value to the millisecond equivalent. Parses the string
 * sent by the server.
 *
 * @param {String|Number} zimbraPrefMailPollingInterval preference value of the interval set on server
 * @returns {Number}
 */
export function getMailPollingIntervalLongPolling(zimbraPrefMailPollingInterval) {
	let mailPollingInterval = MANUAL_REFRESH;

	if (zimbraPrefMailPollingInterval && zimbraPrefMailPollingInterval !== MANUAL_REFRESH) {
		if (zimbraPrefMailPollingInterval === AS_NEW_MAIL_ARRIVES) {
			mailPollingInterval = AS_NEW_MAIL_ARRIVES;
		} else {
			mailPollingInterval = convertToMilliseconds(zimbraPrefMailPollingInterval);
		}
	}

	return mailPollingInterval;
}
