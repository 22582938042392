import { Fragment } from 'preact';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useState } from 'preact/hooks';
import { Text } from 'preact-i18n';
import cx from 'classnames';

import s from '../style.less';
import { callWith } from '@zimbra/util/src/call-with';
import { ChoiceInput } from '@zimbra/blocks';
import ActionMenu, { DropDownWrapper } from '../../../action-menu';
import ActionMenuGroup from '../../../action-menu-group';
import ActionMenuItem from '../../../action-menu-item';
import FormGroup from '../../../form-group';
import AlignedLabel from '../../../aligned-form/label';
import Recurrence from '../../../recurrence';
import CustomRecurrenceModal from '../custom-recurrence-modal';

import { getParsedAppointmentData } from '../../../../store/calendar/selectors';
import { setEvent } from '../../../../store/calendar/actions';
import { reCalculateStartEndDate } from '../../../../utils/invitation';

const REPEAT_OPTIONS = ['NONE', 'DAI', 'WEE', 'MON', 'YEA'];

export const RecurrenceSelect = ({
	eventInstance,
	createEvent,
	disabledElement,
	editInstance,
	isForwardInvite,
	isProposeTime,
	isPrivateCheck,
	recurrenceFromState,
	tabId
}) => {
	const [showCustomRecurrenceModal, setShowCustomRecurrenceModal] = useState(false);

	const onToggleCustomRecurrenceModal = useCallback(value => {
		setShowCustomRecurrenceModal(value);
	}, []);

	const dispatch = useDispatch();
	const {
		event,
		isCustomRecurringEvent,
		customSettingChanged,
		repeatValue,
		endsOnDate,
		endsAfterRecur,
		customByMonthRule,
		customByDayRule,
		customByMonthDayRule,
		customBySetPosRule,
		customIntervalRule,
		isPrivate,
		selectedCalendar
	} = useSelector(state => ({
		event: getParsedAppointmentData(state, 'event', tabId),
		isCustomRecurringEvent: getParsedAppointmentData(state, 'isCustomRecurringEvent', tabId),
		customSettingChanged: getParsedAppointmentData(state, 'customSettingChanged', tabId),
		repeatValue: getParsedAppointmentData(state, 'repeatValue', tabId),
		endsOnDate: getParsedAppointmentData(state, 'endsOnDate', tabId),
		endsAfterRecur: getParsedAppointmentData(state, 'endsAfterRecur', tabId),
		customByMonthRule: getParsedAppointmentData(state, 'customByMonthRule', tabId),
		customByDayRule: getParsedAppointmentData(state, 'customByDayRule', tabId),
		customByMonthDayRule: getParsedAppointmentData(state, 'customByMonthDayRule', tabId),
		customBySetPosRule: getParsedAppointmentData(state, 'customBySetPosRule', tabId),
		customIntervalRule: getParsedAppointmentData(state, 'customIntervalRule', tabId),
		isPrivate: getParsedAppointmentData(state, 'isPrivate', tabId),
		selectedCalendar: getParsedAppointmentData(state, 'selectedCalendar', tabId)
	}));

	const isPrivateAllowed = selectedCalendar && isPrivateCheck(selectedCalendar);

	const updateRepeatVal = useCallback(
		value => {
			dispatch(
				setEvent({
					tabId,
					eventData: {
						repeatValue: value,
						isCustomRecurringEvent: false,
						customByMonthDayRule: null,
						customByDayRule: null,
						customByMonthRule: null,
						customBySetPosRule: null,
						customIntervalRule: null,
						endsOnDate: null,
						endsAfterRecur: null,
						isFormDirty: true
					}
				})
			);
		},
		[dispatch, tabId]
	);

	const handleIsPrivateChange = useCallback(
		({ target: { checked } }) => {
			dispatch(
				setEvent({
					tabId,
					eventData: {
						isPrivate: checked,
						isFormDirty: true
					}
				})
			);
		},
		[dispatch, tabId]
	);

	const handleCustomRecurrenceChange = useCallback(
		({
			endsOnDate: endsOnDateParam,
			endsAfterRecur: endsAfterRecurParam,
			customByDayRule: customByDayRuleParam,
			customByMonthRule: customByMonthRuleParam,
			customByMonthDayRule: customByMonthDayRuleParam,
			customBySetPosRule: customBySetPosRuleParam,
			selectedOption,
			intervalCount
		}) => {
			dispatch(
				setEvent({
					tabId,
					eventData: {
						endsOnDate: endsOnDateParam,
						endsAfterRecur: endsAfterRecurParam,
						repeatValue: selectedOption,
						customByDayRule: customByDayRuleParam,
						customByMonthRule: customByMonthRuleParam,
						customByMonthDayRule: customByMonthDayRuleParam,
						customBySetPosRule: customBySetPosRuleParam,
						customIntervalRule: intervalCount,
						isCustomRecurringEvent: true,
						customSettingChanged: true,
						event: {
							...event,
							recurrence: recurrenceFromState({
								customByDayRule: customByDayRuleParam,
								customByMonthDayRule: customByMonthDayRuleParam,
								customByMonthRule: customByMonthRuleParam,
								customBySetPosRule: customBySetPosRuleParam,
								customIntervalRule: intervalCount,
								endsOnDate: endsOnDateParam,
								endsAfterRecur: endsAfterRecurParam,
								repeatValue: selectedOption,
								event
							}),
							// Find nearest start and end date when user has chosen custom week day in the recurrence
							...reCalculateStartEndDate(event.start, event.end, customByDayRuleParam?.wkday)
						},
						isFormDirty: true
					}
				})
			);
		},
		[dispatch, event, recurrenceFromState, tabId]
	);

	return (
		<Fragment>
			{showCustomRecurrenceModal && (
				<CustomRecurrenceModal
					event={event}
					eventEndsOnDate={endsOnDate}
					eventEndsAfterRecur={endsAfterRecur}
					customEventByMonthRule={customByMonthRule}
					customEventByDayRule={customByDayRule}
					customEventByMonthDayRule={customByMonthDayRule}
					customEventBySetPosRule={customBySetPosRule}
					customRepeatValue={repeatValue}
					customEventIntervalRule={customIntervalRule}
					onSave={handleCustomRecurrenceChange}
					onClose={callWith(onToggleCustomRecurrenceModal, false)}
				/>
			)}
			<FormGroup>
				<AlignedLabel
					class={s.alignedLabel}
					align="left"
					textId="calendar.editModal.fields.repeat"
				/>
				<ActionMenu
					label={
						showCustomRecurrenceModal || (isCustomRecurringEvent && !eventInstance) ? (
							<Recurrence
								recurrence={event.recurrence}
								start={event.start}
								newEvent={createEvent || customSettingChanged}
							/>
						) : (
							<Text id={`calendar.editModal.fields.repeatOptions.${repeatValue}`} />
						)
					}
					actionButtonClass={cx(s.repeatInstanceBtn, disabledElement)}
					disabled={editInstance || isForwardInvite || isProposeTime}
				>
					<DropDownWrapper>
						<ActionMenuGroup>
							{REPEAT_OPTIONS.map(val => (
								<ActionMenuItem onClick={callWith(updateRepeatVal, val)}>
									<Text id={`calendar.editModal.fields.repeatOptions.${val}`} />
								</ActionMenuItem>
							))}
							{isCustomRecurringEvent && (
								<ActionMenuItem class={s.customRecurrText}>
									<Recurrence
										recurrence={event.recurrence}
										start={event.start}
										newEvent={createEvent || customSettingChanged}
									/>
								</ActionMenuItem>
							)}
						</ActionMenuGroup>
						<ActionMenuGroup>
							<ActionMenuItem onClick={callWith(onToggleCustomRecurrenceModal, true)}>
								<Text id="buttons.custom" />
							</ActionMenuItem>
						</ActionMenuGroup>
					</DropDownWrapper>
				</ActionMenu>
				<AlignedLabel class={s.privateWrapper} align="left">
					<label class={disabledElement}>
						<ChoiceInput
							disabled={!isPrivateAllowed || isForwardInvite || isProposeTime}
							checked={isPrivateAllowed && isPrivate}
							onChange={handleIsPrivateChange}
						/>
						<Text id="calendar.editModal.fields.private" />
					</label>
				</AlignedLabel>
			</FormGroup>
		</Fragment>
	);
};
