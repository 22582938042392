import { Link } from 'preact-router';
import Icon from '../icon';
import cx from 'classnames';
import style from './style';

export default function Button({
	styleType,
	size = 'regular',
	brand = '',
	iconPosition = 'left',
	icon,
	iconName,
	children,
	alignLeft,
	className,
	class: cls,
	iconOnly = false,
	useAnchor = false,
	...props
}) {
	icon =
		iconName || typeof icon === 'string' ? (
			<Icon name={iconName || icon} class={cx(style.icon, style[iconPosition])} />
		) : (
			icon
		);
	let ComponentClass = 'button';
	if (props.href) {
		ComponentClass = useAnchor ? 'a' : Link;
		props.role = 'button';
	} else if (!props.type) props.type = 'button';

	return (
		<ComponentClass
			{...props}
			class={cx(
				style.button,
				styleType && style[styleType],
				style[size],
				brand && style[`brand-${brand}`],
				alignLeft && style.alignLeft,
				iconOnly && style.iconOnly,
				className,
				cls
			)}
		>
			{iconPosition === 'left' && icon}
			{children}
			{iconPosition === 'right' && icon}
		</ComponentClass>
	);
}
