import style from './style';
import FolderListLight from '../folder-list-light';
import ResponsiveModal from '../responsive-modal';
import ModalDrawerToolbar from '../modal-drawer-toolbar';
import { Text } from 'preact-i18n';
import { useDumpsterFoldersQuery } from '../../hooks/dumpster';

export function FolderDialog({
	onClose,
	handleRecoverItems,
	handleSelectedFolder,
	selectedFolder
}) {
	const { folders } = useDumpsterFoldersQuery();

	return (
		<ResponsiveModal
			drawerProps={{
				class: style.folderDrawer,
				containerClass: style.containerClass,
				headerClass: style.headerClass,
				toolbar: (
					<ModalDrawerToolbar
						actionLabel="buttons.save"
						cancelLabel="buttons.back"
						onAction={handleRecoverItems}
						onClose={onClose}
						disablePrimary={!selectedFolder}
					/>
				)
			}}
			dialogProps={{
				disableOutsideClick: true,
				class: style.folderModal,
				contentClass: style.folderDialogContent
			}}
			scrollable
			title={<Text id="dialogs.dumpsterDialog.folderListTitle" />}
			actionLabel="buttons.save"
			cancelLabel="buttons.back"
			onAction={handleRecoverItems}
			onClose={onClose}
			disablePrimary={!selectedFolder}
		>
			{folders && folders.length > 0 && (
				<FolderListLight
					folders={folders}
					onFolderClick={handleSelectedFolder}
					collapsibleCustomGroup
				/>
			)}
		</ResponsiveModal>
	);
}
