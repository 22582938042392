export const CSRF_TOKEN_STORAGE_KEY = 'csrfToken';

const publishUpdatedCsrfToken = (csrfToken, context) => {
	if (context) {
		// Set CSRF in both new and legacy client
		context.zimbraBatchClient.setCsrfToken(csrfToken);
		context.zimbra.setCsrfToken(csrfToken);
		context.zimlets.setCsrfToken(csrfToken);
	}
};

/**
 * A singleton interface for getting/setting the csrfToken.
 */
export default {
	get: () => localStorage.getItem(CSRF_TOKEN_STORAGE_KEY),

	set: (csrfToken, context) => {
		publishUpdatedCsrfToken(csrfToken, context);

		localStorage.setItem(CSRF_TOKEN_STORAGE_KEY, csrfToken);
	},

	clear: context => {
		publishUpdatedCsrfToken(null, context);

		localStorage.removeItem(CSRF_TOKEN_STORAGE_KEY);
	}
};
