import { MarkupText, Text } from 'preact-i18n';
import get from 'lodash-es/get';
import flatMap from 'lodash-es/flatMap';
import style from '../style';
import cx from 'classnames';

import { FILTER_ACTION_TYPE } from '../../../constants/filter-rules';
export default function FilterRuleAction({ action }) {
	function renderActionText(key, index) {
		switch (key) {
			case FILTER_ACTION_TYPE.FILE_INTO:
				return (
					<MarkupText
						id="settings.filterRuleModal.deliverTo"
						fields={{ folderPath: action[FILTER_ACTION_TYPE.FILE_INTO][index].folderPath }}
					/>
				);
			case FILTER_ACTION_TYPE.REDIRECT:
				return (
					<MarkupText
						id="settings.filterRuleModal.redirectTo"
						fields={{ redirectAddress: get(action, `redirect.${index}.address`) }}
					/>
				);
			case FILTER_ACTION_TYPE.FLAG:
				if (get(action, `flag.${index}.flagName`) === 'read') {
					return <Text id="settings.filterRuleModal.readFlag" />;
				}
				return <Text id="settings.filterRuleModal.starFlag" />;
			case FILTER_ACTION_TYPE.DISCARD:
				return <Text id="settings.filterRuleModal.actions.discard" />;
			case FILTER_ACTION_TYPE.KEEP_IN_INBOX:
				return <Text id="settings.filterRuleModal.actions.keep" />;
			case FILTER_ACTION_TYPE.TAG:
				return (
					<MarkupText
						id="settings.filterRuleModal.tagAction"
						fields={{ tagName: action[FILTER_ACTION_TYPE.TAG][index].tagName }}
					/>
				);
		}
	}

	return (
		<div class={style.filterProcessLabel}>
			<p class={style.ifThenText}>
				<Text id="settings.filterRuleModal.thenLabel" />
			</p>
			<ul class={style.filterList}>
				{flatMap(Object.keys(action), filterActionKey =>
					filterActionKey !== FILTER_ACTION_TYPE.STOP
						? action[filterActionKey].map((currentElement, i) => (
								<li key={`${i}-${filterActionKey}`}>{renderActionText(filterActionKey, i)}</li>
						  ))
						: null
				)}
			</ul>
			<p class={cx(style.ifThenText, style.processAdditional)}>
				{action.stop ? (
					<Text id="settings.filterRuleModal.stopProcessing" />
				) : (
					<Text id="settings.filterRuleModal.allowProcessing" />
				)}
			</p>
		</div>
	);
}
