import { Localizer, Text } from 'preact-i18n';

import s from '../style.less';
import FormGroup from '../../../form-group';
import AlignedLabel from '../../../aligned-form/label';
import AddressField from '../../../address-field';

export const ToAddress = ({
	onOpenContactPicker,
	handleForwardAttendeesChange,
	isProposeTime,
	organizer,
	nonResourceForwardAttendees
}) => (
	<FormGroup>
		<AlignedLabel class={s.alignedLabel} align="left">
			<Localizer>
				<a
					href="javascript:"
					aria-label={<Text id="contacts.picker.title" />}
					onClick={onOpenContactPicker}
				>
					<Text id="calendar.editModal.fields.to" />
				</a>
			</Localizer>
		</AlignedLabel>
		<AddressField
			class={s.addressField}
			value={isProposeTime ? organizer : nonResourceForwardAttendees}
			onChange={handleForwardAttendeesChange}
			formSize
			disabled={isProposeTime}
		/>
	</FormGroup>
);
