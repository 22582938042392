import { Component } from 'preact';
import { Text } from 'preact-i18n';
import groupBy from 'lodash-es/groupBy';
import get from 'lodash-es/get';
import style from './style';
import OpenAddEditDelegatesButton from './open-add-edit-delegates-button';
import DelegatesListItem from './delegates-list-item';
import { withGetRights } from '../../../../graphql-decorators/rights';
import { VIEW_FREE_BUSY } from './../../../../constants/rights';

@withGetRights()
export default class DelegatesList extends Component {
	render({ getRightsQuery }) {
		const { loading, error, getRights } = getRightsQuery;
		const delegatesRights = (get(getRights, 'access') || []).filter(
			rights => rights.right !== VIEW_FREE_BUSY
		);

		return (
			<div>
				{loading ? (
					'Loading...'
				) : error ? (
					`Error: ${error}`
				) : !delegatesRights || !delegatesRights.length ? null : ( // Empty case, render nothing
					<ul>
						{Object.entries(groupBy(delegatesRights, 'address')).map(([address, rights]) => (
							<DelegatesListItem address={address} rights={rights} />
						))}
					</ul>
				)}
				<OpenAddEditDelegatesButton
					class={style.addDelegates}
					label="settings.writingEmail.delegates.addDelegates"
				/>

				<Text id="settings.writingEmail.delegates.addDelegatesNotes" />
			</div>
		);
	}
}
