import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';
import update from 'immutability-helper';

const initialState = {
	selectedFolderName: null
};

export default handleActions(
	{
		[actionCreators.setSelectedFolder]: (state, action) =>
			update(state, { selectedFolderName: { $set: action.payload } })
	},
	initialState
);
