import { useDocumentActionMutation, useBriefcaseFoldersQuery } from '../../../hooks/briefcase';
import { useCallback } from 'preact/hooks';
import { itemActionVariables } from '../../../hooks/action/utils';
import { useDispatch } from 'react-redux';
import { types as apiClientTypes } from '@zimbra/api-client';
import { getSelectedDocumentIdsThunk, getUrlAndDefaultSortThunk } from '../thunk';
import { getFolderSearchQueryParameters, isButtonEnabled } from '../util';
import { getFoldersAndSharedFolders } from '../../../utils/folders';
import get from 'lodash-es/get';
import { gql } from '@apollo/client';

const { ActionOps } = apiClientTypes;

export const StarWrapper = ({ children, documentList }, { client }) => {
	const dispatch = useDispatch();
	const documentAction = useDocumentActionMutation();
	const { data: foldersData } = useBriefcaseFoldersQuery({ fetchPolicy: 'cache-only' });
	const { folders, sharedFolders } = getFoldersAndSharedFolders(foldersData);

	const handleStar = useCallback(
		({ flag, id }) => {
			const starSelected = id || dispatch(getSelectedDocumentIdsThunk()).join(',');
			const { url, defaultSort } = dispatch(getUrlAndDefaultSortThunk());
			client
				.query({
					query: gql`
						query {
							getPreferences {
								zimbraPrefSortOrder
							}
						}
					`
				})
				.then(({ data }) => {
					const zimbraPrefSortOrder = get(data, 'getPreferences.zimbraPrefSortOrder');
					const { sortValue, documentQuery } = getFolderSearchQueryParameters({
						url,
						defaultSort,
						folders,
						sharedFolders,
						zimbraPrefSortOrder
					});

					const op = flag ? ActionOps.flag : ActionOps.unflag;

					documentAction({
						variables: itemActionVariables({
							id: starSelected,
							op
						}),
						documentQuery,
						documentIds: starSelected,
						sortValue,
						op
					});
				});
		},
		[client, dispatch, documentAction, folders, sharedFolders]
	);

	const handleInlineStar = useCallback(
		item => (event, value) => {
			event.stopPropagation();
			handleStar({ flag: value, id: item.id });
		},
		[handleStar]
	);

	const enableStar = isButtonEnabled({
		documentList,
		sharedFolders,
		folders
	});

	return children({
		handleStar,
		handleInlineStar,
		enableStar
	});
};
