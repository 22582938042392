export const MIN_LENGTH = 'minLength';
export const MAX_LENGTH = 'maxLength';
export const MIN_AGE = 'minAge';
export const MAX_AGE = 'maxAge';
export const COMMON_PASSWORD = 'commonPassword';
export const ENFORCE_HISTORY = 'enforceHistory';
export const MODIFIED_TIME = 'modifiedTime';
export const MIN_UPPERCASE = 'minUpperCaseChars';
export const MIN_LOWERCASE = 'minLowerCaseChars';
export const MIN_PUNCTUATION = 'minPunctuationChars';
export const MIN_NUMERIC = 'minNumericChars';
export const MIN_ALPHA = 'minAlphaChars';
export const ALLOWED_ = 'allowedChars';
export const MIN_DIGITSORPUNCS = 'minDigitsOrPuncs';
export const ALLOWED_PUNCTUATION = 'allowedPunctuationChars';
export const PASSWORD_ALLOWED_USERNAME = 'passwordAllowUsername';
export const INVALID_PASSWORD_MSG_PATTERN = /invalid password: (zimbraPassword\S+)/;
