import { Text } from 'preact-i18n';
import { Icon } from '@zimbra/blocks';

import style from '../style';
import AddEditAccountView from './add-edit-account';
import AddEditPersonaView from './add-edit-persona';
import { useCallback, useEffect, useRef } from 'preact/hooks';

export default function MailAccount({
	account,
	onExternalAccountAction,
	editAccountProps: { savedAccounts, ...viewData },
	expendDetails,
	isExpended,
	fromAccounts
}) {
	const { name, emailAddress, failingSince, isPrimaryAccount, accountType } = account;

	const accountRef = useRef(null);

	useEffect(() => {
		isExpended &&
			setTimeout(() => {
				const expendedAccount = accountRef && accountRef.current;
				expendedAccount &&
					expendedAccount.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
			}, 50);
	}, [isExpended]);

	const toggleDetails = useCallback(() => {
		expendDetails(account.id);
	}, [expendDetails, account.id]);

	return (
		<li class={style.mailAccountItem} ref={accountRef}>
			<div onClick={toggleDetails} class={style.mailAccountBox}>
				<div class={style.leftLogo}>
					<Icon
						class={style.leftLogoIcon}
						name={isPrimaryAccount || accountType ? 'envelope' : 'user-circle-o'}
					/>
				</div>
				<div class={style.mailAccountContent}>
					<b class={style.mailAccountName}>{name}</b>{' '}
					{isPrimaryAccount ? fromAccounts[0] : emailAddress}{' '}
					{isPrimaryAccount ? (
						<span class={style.mailAccountNote}>
							(<Text id="settings.accounts.types.primary" />)
						</span>
					) : (
						''
					)}
				</div>
				{failingSince && (
					<div class={style.mailAccountFailing}>
						<Icon name="warning" size="xs" class={style.warningIcon} />
					</div>
				)}
				<Icon class={style.rightArrow} name={`angle-${isExpended ? 'up' : 'down'}`} size="xs" />
			</div>
			{isExpended && (
				<div class={style.w100}>
					{isPrimaryAccount || accountType ? (
						<AddEditAccountView
							isEditView
							viewData={{
								...viewData,
								selectedAccount: account,
								selectedAccountId: account.id,
								savedSelectedAccount: savedAccounts.find(({ id }) => account.id === id)
							}}
							onExternalAccountAction={onExternalAccountAction}
						/>
					) : (
						<AddEditPersonaView
							{...viewData}
							selectedAccount={account}
							selectedAccountId={account.id}
							isEditView
						/>
					)}
				</div>
			)}
		</li>
	);
}
