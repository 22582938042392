import { saveHash, verifyConfChecksum } from './crypto';

let setServerPrefsStore, getServerPrefs, isValidServerConfChecksum;

if (typeof process.env.ELECTRON_ENV !== 'undefined') {
	const FileSystem = require('fs');
	const { constants } = require('@zimbra/electron-app');

	const getDataFilePath = prefFileName => {
		if (prefFileName) {
			const { remote } = require('electron'),
				path = require('path');

			return path.join(remote.app.getPath('userData'), prefFileName);
		}
	};

	const getServerPrefPath = () => getDataFilePath(constants.serverPrefFileName);

	const getPrefsFromStore = prefFilePath => {
		const prefData =
			FileSystem.existsSync(prefFilePath) && FileSystem.readFileSync(prefFilePath, 'utf8');
		return prefData ? JSON.parse(prefData) : {};
	};

	const createOrModifyPrefStore = (prefFilePath, data) => {
		FileSystem.writeFileSync(prefFilePath, data);
	};

	setServerPrefsStore = data => {
		try {
			const serializedData = JSON.stringify(data);

			createOrModifyPrefStore(getServerPrefPath(), serializedData);

			// Everytime server prefs updated, we need to generate the hash
			saveHash(serializedData, getDataFilePath(constants.serverPrefHashFileName));
		} catch (e) {
			console.error('[Electron] Error creating/updating server preferences', e);
		}
	};

	getServerPrefs = () => {
		try {
			return getPrefsFromStore(getServerPrefPath());
		} catch (e) {
			console.error('[Electron] Error reading server preferences', e);
		}
	};

	isValidServerConfChecksum = data =>
		verifyConfChecksum(getDataFilePath(constants.serverPrefHashFileName), JSON.stringify(data));
}

const formSecureURL = url => {
	const regex = new RegExp('[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$');
	if (url.trim().length > 0 && regex.test(url)) {
		return `https://${url}`;
	}
	return false;
};

const getLocation = href => {
	const l = document.createElement('a');
	l.href = href;
	return l;
};

export {
	setServerPrefsStore,
	getServerPrefs,
	isValidServerConfChecksum,
	formSecureURL,
	getLocation
};
