import { Text } from 'preact-i18n';
import ActionMenu, { DropDownWrapper } from '../action-menu';
import ActionMenuGroup from '../action-menu-group';
import ActionMenuItem from '../action-menu-item';
import { REMINDER_SNOOZE_OPTIONS } from '../../constants/reminder';
import { callWith } from '@zimbra/util/src/call-with';
import { Button } from '@zimbra/blocks';

export const SnoozeTimeButton = ({
	attachmentsTitle,
	actionButtonClass,
	toggleClass,
	actionMenuClass,
	arrow,
	monotone,
	iconOnly,
	isOffline,
	id,
	start,
	handleSnooze,
	snoozeClass,
	snoozeButtonClass,
	buttonLabel
}) => {
	return (
		<div className={snoozeClass}>
			<Button
				styleType="primary"
				brand="primary"
				class={snoozeButtonClass}
				onClick={callWith(handleSnooze, { id, start })}
			>
				<Text id={buttonLabel} />
			</Button>
			<ActionMenu
				actionButtonClass={actionButtonClass}
				toggleClass={toggleClass}
				icon="clock"
				iconOnly={iconOnly}
				monotone={monotone}
				arrow={arrow}
				class={actionMenuClass}
				disabled={isOffline}
				title={attachmentsTitle}
				iconSize="sm"
			>
				<DropDownWrapper>
					<ActionMenuGroup>
						{REMINDER_SNOOZE_OPTIONS.map(snoozeTime => (
							<ActionMenuItem onClick={callWith(handleSnooze, { id, start, snoozeTime })}>
								<Text id={`notifications.reminderSnoozeOptions.${snoozeTime}`} />
							</ActionMenuItem>
						))}
					</ActionMenuGroup>
				</DropDownWrapper>
			</ActionMenu>
		</div>
	);
};
