export const ANY = 'any';
const IMAGE = 'image';
const PDF = 'pdf';
const DOC = 'doc';
const SLIDES = 'slides';
const SHEET = 'sheet';
const MOVIE = 'movie';
const COMPRESSED = 'compressed';

const APPLICATION = 'application';
const HTML = 'html';
const MAIL_MESSAGE = 'mailMessage';
const AUDIO = 'audio';
export const TEXT_CALENDAR = 'textCalendar';

export const ATTACHMENT_TYPES = [ANY, IMAGE, PDF, DOC, SLIDES, SHEET, MOVIE, COMPRESSED];
export const OTHER_ATTACHMENTS_TYPES = [APPLICATION, HTML, MAIL_MESSAGE, AUDIO, TEXT_CALENDAR];

export const VIEW_TO_SEARCH = {
	conversation: 'email',
	message: 'email'
};

export const UNREAD = 'unread';
export const READ = 'read';
export const UNFLAGGED = 'unflagged';
export const FLAGGED = 'flagged';
export const RECEIVED = 'received';
export const SENT = 'sent';
export const UNREPLIED = 'unreplied';
export const REPLIED = 'replied';
export const UNFORWARDED = 'unforwarded';
export const FORWARDED = 'forwarded';
export const REMOTE = 'remote';
export const ANYWHERE = 'anywhere';
export const LOCAL = 'local';
export const TOFROMME = 'tofromme';
export const TOCCME = 'toccme';
export const TOFROMCCME = 'tofromccme';
export const TOME = 'tome';
export const FROMCCME = 'fromccme';
export const FROMME = 'fromme';
export const CCME = 'ccme';
export const DRAFT = 'draft';
export const INVITE = 'invite';
export const SOLO = 'solo';

export const MORE_FILTER_OPTIONS = [
	READ,
	UNFLAGGED,
	DRAFT,
	SENT,
	RECEIVED,
	REPLIED,
	UNREPLIED,
	FORWARDED,
	UNFORWARDED,
	INVITE,
	SOLO,
	TOME,
	FROMME,
	CCME,
	TOFROMME,
	TOCCME,
	FROMCCME,
	TOFROMCCME,
	LOCAL,
	REMOTE,
	ANYWHERE
];

export const FILTER_EXCLUSION_TERM = {
	[UNREAD]: [READ],
	[READ]: [UNREAD],
	[UNFLAGGED]: [FLAGGED],
	[FLAGGED]: [UNFLAGGED],
	[RECEIVED]: [SENT],
	[SENT]: [RECEIVED],
	[UNREPLIED]: [REPLIED],
	[REPLIED]: [UNREPLIED],
	[UNFORWARDED]: [FORWARDED],
	[FORWARDED]: [UNFORWARDED],
	[REMOTE]: [ANYWHERE, LOCAL],
	[ANYWHERE]: [REMOTE, LOCAL],
	[LOCAL]: [REMOTE, ANYWHERE],
	[TOFROMME]: [TOCCME, TOFROMCCME, TOME, FROMCCME, FROMME],
	[TOCCME]: [TOFROMME, TOFROMCCME, TOME, FROMCCME, CCME],
	[TOFROMCCME]: [TOFROMME, TOCCME, TOME, FROMCCME, FROMME, CCME],
	[TOME]: [TOFROMME, TOCCME, TOFROMCCME],
	[FROMCCME]: [TOFROMME, TOFROMCCME, FROMME, TOCCME, CCME],
	[FROMME]: [TOFROMME, FROMCCME, TOFROMCCME],
	[CCME]: [TOCCME, FROMCCME, TOFROMCCME],
	[DRAFT]: [],
	[INVITE]: [],
	[SOLO]: []
};

export const ANYTIME = 'anytime';
export const LAST7 = 'last7';
export const LAST30 = 'last30';
export const SENT_BEFORE = 'sentBefore';
export const SENT_AFTER = 'sentAfter';
export const SENT_ON = 'sentOn';
export const CUSTOM_DATE = 'customDate';

export const DATE_OPTIONS = [ANYTIME, LAST7, LAST30, SENT_BEFORE, SENT_AFTER, SENT_ON, CUSTOM_DATE];
