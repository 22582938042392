import { Component } from 'preact';
import PropTypes from 'prop-types';
import { withText } from 'preact-i18n';

import { canMoveMessagesIntoFolders, isChildFolder } from '../../utils/folders';

import FolderSelect from '../folder-select';

import s from './style.less';
import { withProps } from 'recompose';
import { OUTBOX, DRAFTS, JUNK } from '../../constants/folders';
function isFolderDisabled(folder, activeFolder) {
	return (
		folder.absFolderPath.includes(`/${DRAFTS}`) ||
		folder.absFolderPath.includes(`/${JUNK}`) ||
		folder.absFolderPath.includes(`/${OUTBOX}`) ||
		folder.id === activeFolder.id ||
		folder.id === activeFolder.parentFolderId ||
		isChildFolder(activeFolder, folder.id)
	);
}

function withDisabledState(folders, activeFolder) {
	return folders.map(f => ({
		...f,
		disabled: isFolderDisabled(f, activeFolder),
		folder: withDisabledState(f.folder || [], activeFolder)
	}));
}

function disableFolders(folders = []) {
	return folders.map(f => {
		const subFolders = f.folders;
		return {
			...f,
			disabled: true,
			...(subFolders && subFolders.length > 0 && { folders: disableFolders(subFolders) })
		};
	});
}
@withText({ localFolderName: 'folderlist.local_folder' })
@withProps(({ folders: allFolders, localFolders, activeFolder, localFolderName }) => {
	const canMoveMessages = canMoveMessagesIntoFolders(
		activeFolder.local ? localFolders : allFolders
	);
	const folders = withDisabledState(canMoveMessages, activeFolder);
	if (typeof process.env.ELECTRON_ENV !== 'undefined' && !activeFolder.local) {
		const localFolderExists = localFolders && localFolders.length > 0;
		localFolderExists &&
			folders.push({
				absFolderPath: '/' + localFolderName,
				name: localFolderName,
				disabled: true,
				isLocalFolder: true,
				folders: disableFolders(localFolders) || null
			});
	}
	return {
		folders
	};
})
export default class ContextMenuMoveFolder extends Component {
	handleClick(e) {
		e.stopPropagation();
	}

	static propTypes = {
		folders: PropTypes.array,
		onMove: PropTypes.func,
		onCancelMove: PropTypes.func
	};

	render({ folders, onMove, onCancelMove }) {
		const folderGroups = [folders];

		return (
			<div onClick={this.handleClick}>
				<FolderSelect
					folders={folders}
					folderGroups={folderGroups}
					folderGroupClass={s.folderGroup}
					maxGroupHeight={200}
					onMove={onMove}
					onBack={onCancelMove}
				/>
			</div>
		);
	}
}
