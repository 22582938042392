import { useState, useCallback } from 'preact/hooks';
import { Text } from 'preact-i18n';
import { Spinner, Button } from '@zimbra/blocks';
import { errorMessage } from './../../../utils/errors';
import { useDeletePersona } from './../../../hooks/persona';
import { notify } from './../../../store/notifications/actions';
import { useDispatch } from 'react-redux';
import ModalDialog from '../../modal-dialog';

export default function ConfirmPersonaDelete({ id, accountName, onCancel }) {
	const dispatch = useDispatch();
	const [submitting, setSubmitting] = useState(false);
	const removePersona = useDeletePersona();

	const removePersonaData = useCallback(() => {
		setSubmitting(true);
		removePersona(id)
			.then(() => {
				setSubmitting(false);
				onCancel();
			})
			.catch(error => {
				dispatch(
					notify({
						failure: true,
						message: errorMessage(error)
					})
				);
				setSubmitting(false);
				onCancel();
			});
	}, [dispatch, id, removePersona, onCancel]);

	return submitting ? (
		<Spinner block />
	) : (
		<ModalDialog
			title={<Text id="settings.accounts.editPersona.confirmation" fields={{ accountName }} />}
			buttons={[
				<Button styleType="primary" brand="danger" onClick={removePersonaData}>
					<Text id="buttons.deletePersona" />
				</Button>
			]}
			onClose={onCancel}
		>
			<p>
				<Text id="settings.accounts.editPersona.additionalConfirmationInfo" />
			</p>
		</ModalDialog>
	);
}
