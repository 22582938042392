import { Component } from 'preact';

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { error: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components and setState for rerender and log errors
		this.setState({
			error
		});
		console.error('ErrorBoundary', error, errorInfo);
	}

	render(props) {
		return props.children;
	}
}
