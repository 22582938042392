import { route } from 'preact-router';
import { getNewMailUrl } from '../../utils/mail-item';
import newMessageDraft from '../../utils/new-message-draft';
import { useCommandHandlers } from '../../keyboard-shortcuts/command-handlers';
import searchInputStyle from '../search-input/style';
/**
@TODO: Remove this and directly include useCommandHandlers hook inside the component
	when the component is changed from class to functional component
*/
export const AddShortcuts = (
	{
		slugs,
		zimbraFeatureCalendarEnabled,
		zimbraFeatureBriefcasesEnabled,
		handleNewTab,
		matchesScreenMd
	},
	context
) => {
	const handlers = [
		{ context: 'all', command: 'GO_TO_MAIL', handler: () => route('/') },
		{ context: 'all', command: 'GO_TO_MAIL', handler: () => route('/') },
		zimbraFeatureCalendarEnabled && {
			context: 'all',
			command: 'GO_TO_CALENDAR',
			handler: () => route(`/${slugs.calendar}`)
		},
		zimbraFeatureBriefcasesEnabled && {
			context: 'all',
			command: 'GO_TO_BRIEFCASE',
			handler: () => route(`/${slugs.briefcase}`)
		},
		{
			context: 'all',
			command: 'GO_TO_CONTACTS',
			handler: () => route(`/${slugs.contacts}`)
		},
		{
			context: 'all',
			command: 'COMPOSE_MESSAGE',
			handler: () =>
				handleNewTab({
					url: getNewMailUrl(slugs.email, matchesScreenMd),
					tabItem: newMessageDraft(),
					type: 'message',
					view: slugs.email
				})
		},
		{
			context: 'all',
			command: 'NEW_CONTACT',
			handler: () => route(`/${slugs.contacts}/new`)
		},
		{
			context: 'all',
			command: 'FOCUS_SEARCH_BOX',
			handler: ({ e }) => {
				e && e.preventDefault();
				document.getElementsByClassName(searchInputStyle.input)[0].focus(); //a bit hacky.  Less hacky would be to store ref in redux store
			}
		}
	].filter(Boolean);

	useCommandHandlers(handlers, context);
};
