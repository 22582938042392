import { Component, Fragment } from 'preact';
import { useCallback } from 'preact/hooks';
import PropTypes from 'prop-types';
import cx from 'classnames';
import flatten from 'lodash-es/flatten';
import findIndex from 'lodash-es/findIndex';
import { Text } from 'preact-i18n';
import { connect } from 'react-redux';
import { KeyCodes } from '@zimbra/blocks';
import { flattenFolders, filteredFolders } from '../../../utils/folders';
import { getView } from '../../../store/url/selectors';

import ActionMenuGroup from '../../action-menu-group';
import ActionMenuItem from '../../action-menu-item';
import Folder from '../../folder';
import s from '../style.less';

const SearchFolderGroups = ({
	onAllMail,
	folderGroups,
	specialFolders,
	customFolders,
	sharedFolders,
	groupClass,
	selectedFolderId,
	...rest
}) => {
	const renderFolder = useCallback(
		f => (
			<Folder
				{...rest}
				key={f.id}
				depth={1}
				folder={f}
				selectedFolderId={selectedFolderId}
				selected={selectedFolderId === f.id}
			/>
		),
		[rest, selectedFolderId]
	);

	return (
		<ActionMenuGroup>
			<div class={cx(s.searchFolderGroup, groupClass)}>
				<ActionMenuItem onClick={onAllMail}>
					<div className={s.allMail}>
						<Text id="search.scope.all_mail" />
					</div>
				</ActionMenuItem>

				{specialFolders.map(f => renderFolder(f))}

				{customFolders.length > 0 && (
					<Fragment>
						<p class={cx(s.dropdownHeading)}>
							<Text id="folderlist.folders" />
						</p>
						{customFolders.map(f => (
							<div className={s.folderGroupHeading}>{renderFolder(f)}</div>
						))}
					</Fragment>
				)}

				{sharedFolders.length > 0 && (
					<Fragment>
						<p class={cx(s.dropdownHeading)}>
							<Text id="folderlist.sharedFolders" />
						</p>
						{sharedFolders.map(f => (
							<div className={s.folderGroupHeading}>{renderFolder(f)}</div>
						))}
					</Fragment>
				)}
			</div>
		</ActionMenuGroup>
	);
};

function selectOffsetFolder(folderGroups, currentId, offset = 1) {
	const folders = flattenFolders(flatten(folderGroups)).filter(f => !f.disabled);
	if (!folders.length) {
		return null;
	}

	const index = findIndex(folders, f => f.id === currentId);
	if (index === -1 && offset >= 1) {
		return folders[0].id;
	}
	if (index === 0 && offset <= -1) {
		return currentId;
	}

	const nextFolder = folders[index + offset];
	return (nextFolder && nextFolder.id) || currentId;
}

@connect(state => ({
	currentView: getView(state)
}))
export class FolderSearch extends Component {
	state = {
		filterValue: '',
		keyboardSelection: null // [<groupIndex>, <itemIndex>]
	};

	handleFilterChange = (e, folders) => {
		const value = e.target.value;
		const newFolderGroups = [filteredFolders(folders, value)];
		const newSelection = value === '' ? null : selectOffsetFolder(newFolderGroups, null, 1);

		this.setState({
			filterValue: value,
			keyboardSelection: newSelection
		});
	};

	allFolderSearch = () => {
		this.props.onSearch(null);
	};

	handleFilterKeydown = e => {
		if (e.keyCode === KeyCodes.DOWN_ARROW || e.keyCode === KeyCodes.UP_ARROW) {
			e.preventDefault();

			if (e.keyCode === KeyCodes.DOWN_ARROW) {
				this.handleKeyboardNavigation(e, this.folderGroups, 1);
			} else if (e.keyCode === KeyCodes.UP_ARROW) {
				this.handleKeyboardNavigation(e, this.folderGroups, -1);
			}
		}
	};

	handleFilterKeyup = e => {
		if (e.keyCode === 13) {
			this.handleKeyboardEnter(e);
		} else if (e.keyCode !== KeyCodes.DOWN_ARROW && e.keyCode !== KeyCodes.UP_ARROW) {
			this.handleFilterChange(e, this.props.folders);
		}
	};

	handleKeyboardNavigation = (e, folderGroups, offset = 1) => {
		const { keyboardSelection } = this.state;

		this.setState({
			keyboardSelection: selectOffsetFolder(folderGroups, keyboardSelection, offset)
		});
	};

	clearFilter = () => {
		if (this.state.filterValue !== '') {
			this.setState({ filterValue: '' });
		}
	};

	clearKeyboardSelection = e => {
		// When programatically scrolling for keyboard selection, do not
		// clear the selection
		if (e.movementX === 0 && e.movementY === 0) {
			return;
		}

		if (this.state.keyboardSelection !== null) {
			this.setState({ keyboardSelection: null });
		}
	};

	handleKeyboardEnter = () => {
		if (!this.state.keyboardSelection) {
			return;
		}
		this.searchItem(this.state.keyboardSelection);
	};

	handleItemClick = (e, folder) => {
		e.stopPropagation();
		this.searchItem(folder);
	};

	handleClearFilter = () => {
		this.clearState();
	};

	searchItem = value => {
		this.clearState();
		this.props.onSearch(value);
	};

	clearState = () => {
		this.setState({
			filterValue: '',
			keyboardSelection: null
		});
	};

	static propTypes = {
		folders: PropTypes.array,
		onSearch: PropTypes.func.isRequired
	};

	render(
		{ folders, onSearch, folderGroupClass, onBack, currentView, selectedFolderId, ...rest },
		{ filterValue, keyboardSelection }
	) {
		const folderGroups =
			filterValue !== '' ? [filteredFolders(folders, filterValue)] : rest.folderGroups;
		const [specialFolders, customFolders, sharedFolders] = rest.folderGroups;

		return (
			<SearchFolderGroups
				selectedFolderId={selectedFolderId}
				folderGroups={folderGroups}
				specialFolders={specialFolders}
				customFolders={customFolders}
				sharedFolders={sharedFolders}
				keyboardSelection={keyboardSelection}
				onClick={this.handleItemClick}
				onMouseEnter={this.clearKeyboardSelection}
				groupClass={folderGroupClass}
				onAllMail={this.allFolderSearch}
			/>
		);
	}
}
