import { graphql } from '@apollo/client/react/hoc';
import saveSMimeCert from '../../graphql/queries/smime/save-smime-cert.graphql';
import getSMimeCertInfoQuery from '../../graphql/queries/smime/smime-cert-info.graphql';
import update from 'immutability-helper';

export default function withSaveSmimeCertificate() {
	return graphql(saveSMimeCert, {
		props: ({ mutate }) => ({
			saveSmimeCertificate: cert =>
				mutate({
					variables: {
						upload: { id: cert.aid },
						password: cert.password
					},
					update: (cache, { data }) => {
						try {
							const newSmimeCertificate = data?.saveSMimeCert?.certificates[0];
							const smimeData = cache.readQuery({ query: getSMimeCertInfoQuery });

							cache.writeQuery({
								query: getSMimeCertInfoQuery,
								data: update(smimeData, {
									getSMimeCertInfo: {
										certificates: {
											$set: [newSmimeCertificate]
										}
									}
								})
							});
						} catch (error) {
							console.error({ error });
						}
					}
				})
		})
	});
}
