import { Fragment } from 'preact';
import { Button } from '@zimbra/blocks';
import { PureComponent } from 'preact/compat';
import cx from 'classnames';
import { parseAddress, isValidEmail, getEmail } from '../../lib/util';
import { addressFromContact, displayAddress } from '../../utils/contacts';
import Suggestions from './suggestions';
import TokenInput from '../token-input';
import ContactPicker from '../contact-picker';
import get from 'lodash-es/get';
import style from './style';
import isString from 'lodash-es/isString';
import { setDataTransferJSON, getDataTransferJSON } from '@zimbra/util/src/data-transfer-manager';
import ContactInputToken from './contact-input-token';
import update from 'immutability-helper';

function createAddress(address) {
	if (typeof address === 'object') {
		return address.email && addressFromContact(address);
	}

	return parseAddress(address);
}

function defaultValidateTokenFn(address, token) {
	return isValidEmail(getEmail(token.address));
}

function checkExisting(recipients, c) {
	return (
		recipients.find(v => {
			const attrs = c.attributes || c._attrs || c;
			return attrs.email === v.address && attrs.fullName === v.name;
		}) || addressFromContact(c)
	);
}

function updateExistingFieldValue(recipient, contactDetails, updatedValue) {
	const updatedValueName = updatedValue.split('@')[0];
	if (recipient.address === contactDetails.address) {
		const newContactDetails = update(contactDetails, {
			address: { $set: updatedValue },
			displayName: { $set: updatedValueName },
			name: { $set: updatedValueName }
		});
		return newContactDetails;
	}
	return recipient;
}
export default class AddressField extends PureComponent {
	openPicker = () => {
		this.setState({ openPicker: true });
	};

	closePicker = () => {
		this.setState({ openPicker: false });
	};

	setContacts = contacts => {
		const {
			recipients: { to: existingTo, cc: existingCc, bcc: existingBcc }
		} = this.props;
		const { to, cc, bcc } = contacts;

		this.props.onChange({
			value: {
				to: to.map(c => checkExisting(existingTo, c)),
				cc: cc.map(c => checkExisting(existingCc, c)),
				bcc: bcc.map(c => checkExisting(existingBcc, c))
			}
		});
	};

	filterDuplicateAddresses = arr => {
		let hasDupes = false;
		const found = [],
			out = [];
		const { isLocation } = this.props;

		for (let i = 0; i < arr.length; i++) {
			const addr = arr[i];
			const stringToCheck = isLocation && isString(addr) ? addr : get(addr, 'address');

			if (found.indexOf(stringToCheck) === -1) {
				found.push(stringToCheck);
				out.push(addr);
			} else {
				hasDupes = true;
			}
		}

		return hasDupes ? out : arr;
	};

	handleDragStart = (selected, draggingToken, isSelected, e) => {
		const { value, addressFieldType } = this.props;
		const draggingPills =
			selected && selected.length > 0 && isSelected
				? value.filter(({ address }) => selected.indexOf(address) !== -1) // extracts full token object from the "value" by using addresses in selected array.
				: [draggingToken];

		setDataTransferJSON(e, {
			data: {
				selected: draggingPills,
				addressFieldType
			},
			itemCount: draggingPills.length
		});
	};

	handleDropPills = e => {
		const { selected, addressFieldType: source } = getDataTransferJSON(e);
		const { removeAndAddPills, addressFieldType: destination } = this.props;

		removeAndAddPills(selected, source, destination);
	};

	updateExistingValue = (address, contactDetails) => {
		const {
			recipients: { to: existingTo, cc: existingCc, bcc: existingBcc }
		} = this.props;
		if (contactDetails.address !== address) {
			this.props.onChange({
				value: {
					to: existingTo.map(recipient =>
						updateExistingFieldValue(recipient, contactDetails, address)
					),
					cc: existingCc.map(recipient =>
						updateExistingFieldValue(recipient, contactDetails, address)
					),
					bcc: existingBcc.map(recipient =>
						updateExistingFieldValue(recipient, contactDetails, address)
					)
				},
				valueUpdated: true
			});
		}
	};

	render(
		{
			label,
			value,
			formSize,
			wasPreviouslySelected,
			previouslySelectedLabel,
			class: c,
			tokenInputClass,
			tokenInputStyle,
			validateToken,
			showCertBadge = false,
			draggable,
			handleDragEnter,
			disabled,
			addressFieldType,
			recipients,
			matchesScreenMd,
			onCertDataChange,
			...props
		},
		{ openPicker }
	) {
		let divId = addressFieldType;
		if (!divId && label) {
			// Space can break dom structure
			divId = label.replace(/ /g, '_');
		}

		return (
			<div class={cx(style.addressField, c)} onDrop={this.handleDropPills}>
				{divId && (
					<Fragment>
						<div id={`contact_picker_${divId}`} />
						<Button
							styleType="text"
							class={style.label}
							// temporarily disabled contact picker for mobile screen. implement PREAPPS-5928 for mobile screen and remove this temporary code.
							{...(matchesScreenMd && { onClick: this.openPicker })}
						>
							{label}
						</Button>
					</Fragment>
				)}

				<TokenInput
					{...props}
					value={this.filterDuplicateAddresses(value)}
					class={cx(style.input, formSize && style.formSize, tokenInputClass)}
					inputClassName={style.inputField}
					createValue={createAddress}
					renderValue={displayAddress}
					renderToken={renderToken}
					draggable={draggable}
					handleDragStart={this.handleDragStart}
					onDragEnter={handleDragEnter}
					tokenInputStyle={tokenInputStyle}
					renderAutoSuggest={Suggestions}
					wasPreviouslySelected={wasPreviouslySelected}
					validateToken={
						typeof validateToken === 'function' ? validateToken : defaultValidateTokenFn
					}
					previouslySelectedLabel={previouslySelectedLabel}
					onDataChange={onCertDataChange}
					showCertBadge={showCertBadge}
					disabled={disabled}
					addressFieldType={addressFieldType}
					updateExistingContact={this.updateExistingValue}
				/>

				{openPicker && divId && (
					<ContactPicker
						contacts={this.filterDuplicateAddresses(value)}
						onSave={this.setContacts}
						onClose={this.closePicker}
						onUpdate={this.updateContact}
						into={`#contact_picker_${divId}`}
						addressFieldType={addressFieldType}
						recipients={recipients}
					/>
				)}
			</div>
		);
	}
}

const renderToken = ({ token, ...props }) => <ContactInputToken contact={token} {...props} />;
