import { Text } from 'preact-i18n';
import { Button } from '@zimbra/blocks';
import cx from 'classnames';
import s from './style';

export default function AddMore({ label, onClick, class: cls, disabled, ...props }) {
	return (
		<Button
			styleType="floating"
			brand="primary"
			onClick={onClick}
			disabled={disabled}
			class={cx(s.addMore, cls)}
			{...props}
		>
			<Text id={label} />
		</Button>
	);
}
