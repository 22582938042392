/*
	The modal stack is used to store the order in which modals appear on the UI.
	Each modal will have an ID which is pushed onto the stack.
	This is used primarily to allow only the most recent modal opened to close when the user hits the ESC key.
*/
const modalStack = [];

/**
 * This is used to generate a new modal id and push that onto the the 'modalStack'.
 * It then returns the newly created modalId. 'modalId' will be index of pushed item + 1.
 */
export function pushToModalStack() {
	return modalStack.push(modalStack.length + 1);
}

/**
 * This function pops the last item on the 'modalStack'.
 */
export function popFromModalStack() {
	modalStack.pop();
}

/**
 * This function is used to determine if current modal id matches the most recent modal opened in 'modalStack'.
 * @param {Integer} modalId The id of the modal you want to verify is the current modal
 */
export function isCurrentModal(modalId) {
	return modalId === modalStack.length;
}
