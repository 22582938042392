export const USER_ROOT_FOLDER_ID = '1';
export const USER_FOLDER_IDS = {
	ROOT: 1,
	INBOX: 2,
	TRASH: 3,
	JUNK: 4,
	SENT: 5,
	DRAFTS: 6,
	CONTACTS: 7,
	CALENDAR: 10,
	EMAILED_CONTACTS: 13,
	CHAT: 14,
	TASKS: 15,
	BRIEFCASE: 16,
	FILES_SHARED_WITH_ME: 20
};
export const INTERNAL_GAL_PATH = '_InternalGAL';
export const MAX_FOLDER_DEPTH = 10;

export const [ASCENDING, DESCENDING] = ['Asc', 'Desc'];
export const ARROW_DIRECTION_NAMES = { Asc: 'arrow-up-thin', Desc: 'arrow-down-thin' };

export const SYSTEM_FOLDERS_IDS = ['1', '2', '3', '4', '5', '6', '7', '10', '13', '14', '15', '16'];

export const LOCAL_SYSTEM_FOLDERS = ['zd-localfolders'];
