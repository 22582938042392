import { Component } from 'preact';
import { graphql } from '@apollo/client/react/hoc';
import { Text } from 'preact-i18n';
import linkstate from 'linkstate';
import { withPropsOnChange } from 'recompose';
import isPlainObject from 'lodash-es/isPlainObject';
import get from 'lodash-es/get';
import differenceWith from 'lodash-es/differenceWith';
import differenceBy from 'lodash-es/differenceBy';
import isEqual from 'lodash-es/isEqual';
import { connect } from 'react-redux';

import { getEmail, isValidEmail } from '../../lib/util';
import { getEmailGrants, removeEmailGrant, addEmailGrants, updateGrant } from '../../utils/acl';

import ModalDialog from '../modal-dialog';
import ShareMain from './main';
import ShareAccessSettings from './access-settings';
import { FolderActionMutation } from '../../graphql/queries/folders/folders.graphql';
import SendShareNotificationMutation from '../../graphql/queries/shares/send-notification.graphql';

import { notify as notifyActionCreator } from '../../store/notifications/actions';
import { ZIMBRA_GRANT_IDS } from '../../constants/calendars';
import withAccountInfo from '../../graphql-decorators/account-info';
import { faultCode } from '../../utils/errors';
import { getTranslatedFolderName } from '../folder-list/util';

function getPublicUrl({ absFolderPath }, restURL) {
	return restURL ? `${restURL}${encodeURIComponent(absFolderPath)}.ics` : '';
}

function isItemAlreadySharedWithUser(acl, email) {
	acl = acl || [];
	return acl.find(elem => elem.address === email);
}

@connect(null, {
	notify: notifyActionCreator
})
@withAccountInfo(({ data: { accountInfo: account } }) => ({
	ownerEmail: account.name,
	publicURL: account.publicURL,
	restURL: account.rest,
	isPublicSharingEnabled: get(account, 'attrs.zimbraPublicSharingEnabled'),
	isExternalSharingEnabled: get(account, 'attrs.zimbraExternalSharingEnabled')
}))
@withPropsOnChange(['calendar, folder'], ({ calendar, folder }) => ({
	/**
	 * Setting `shareableItem` is so because `Share dialog` component wraps Calender/Email/Contact folder items
	 * and these components use different `prop names` for `folder`.
	 */
	shareableItem: calendar || folder || null
}))
@graphql(FolderActionMutation, {
	props: ({ mutate }) => ({
		folderAction: ({ grant: { __typename, ...restGrantData } = {}, ...restActionParams }) =>
			mutate({
				variables: {
					action: {
						...(Object.keys(restGrantData).length && { grant: restGrantData }),
						...restActionParams
					}
				}
			})
	})
})
@graphql(SendShareNotificationMutation, {
	props: ({ mutate }) => ({
		sendShareNotification: shareNotification => mutate({ variables: { shareNotification } })
	})
})
export default class ShareDialog extends Component {
	state = {
		acl: null,
		showAccessSettings: false,
		emailsToInvite: [],
		invitePermissions: 'r',
		enableLinks: false
		// error: null
	};

	aclWithNewInvites = () => {
		const { acl, emailsToInvite, invitePermissions } = this.state;
		const emails = emailsToInvite.filter(c => isValidEmail(c.address)).map(c => c.address);
		return addEmailGrants(acl, emails, invitePermissions, this.props.publicURL);
	};

	handleACLChange = nextACL => {
		this.setState({
			acl: nextACL
		});
	};

	handleRemoveEmailGrant = grant => {
		this.setState({
			acl: removeEmailGrant(this.state.acl, grant)
		});
	};

	handleUpdateGrant = nextGrant => {
		this.setState({
			acl: updateGrant(this.state.acl, nextGrant)
		});
	};

	handleEmailsToInviteChange = e => {
		this.handleErrorOnEmailsToInvite(e.value);
		this.setState({ emailsToInvite: e.value });
	};

	handleErrorOnEmailsToInvite = emailsToInvite => {
		const errors = new Set();
		const aclGrant = get(this.state, 'acl.grant');
		const { ownerEmail, screen } = this.props;

		if (emailsToInvite && emailsToInvite.length > 0) {
			let emailToken;
			for (emailToken of emailsToInvite) {
				// emailsToInvite contain a string as the last entry of the array until the pill has been created
				if (isPlainObject(emailToken)) {
					const email = getEmail(emailToken.address);

					if (!isValidEmail(email)) {
						errors.add('shareItemModal.notifications.errors.invalidEmail');
					} else if (isItemAlreadySharedWithUser(aclGrant, email)) {
						errors.add(`shareItemModal.notifications.errors.${screen}.itemAlreadyShared`);
					} else if (ownerEmail === email) {
						errors.add(`shareItemModal.notifications.errors.${screen}.cannotShareWithSelf`);
					}
				}
			}
		}

		this.setState({
			error:
				errors && errors.size > 0 ? (
					<div>
						{Array.from(errors).map(error => (
							<div>
								<Text id={error} />
							</div>
						))}
					</div>
				) : null
		});
	};

	handleToggleAccessSettings = () => {
		this.setState({
			showAccessSettings: !this.state.showAccessSettings,
			emailsToInvite: [],
			error: null
		});
	};

	handleUpdateACL = ({ acl, id }, { grant: updatedGrant }) => {
		const { folderAction, sendShareNotification, refetchFolders } = this.props;
		const prevGrants = get(acl, 'grant') || [];
		const addGrants = differenceWith(updatedGrant, prevGrants, isEqual);
		const removeGrants = differenceBy(
			differenceWith(prevGrants, updatedGrant, isEqual),
			addGrants,
			'zimbraId'
		);

		return Promise.all([
			...addGrants.map(grant =>
				folderAction({
					op: 'grant',
					id,
					grant
				}).then(res =>
					grant.address
						? sendShareNotification({
								// if `grant.address` is in `prevGrants`, it must be an `edit`.
								...(prevGrants.find(prevGrant => prevGrant.address === grant.address)
									? { action: 'edit' }
									: {}),
								item: { id },
								address: { address: grant.address }
						  })
						: res
				)
			),
			...removeGrants.map(grant => {
				folderAction({
					op: '!grant',
					id,
					zimbraId: grant.zimbraId || ZIMBRA_GRANT_IDS[grant.granteeType]
				});

				if (grant.address) {
					sendShareNotification({
						action: 'revoke',
						item: { id },
						address: { address: grant.address }
					});
				}
			})
		]).then(refetchFolders);
	};

	handleSave = () => {
		const acl = this.aclWithNewInvites();
		const { shareableItem, notify, screen, onClose } = this.props;

		this.setState({ pendingSave: true });

		this.handleUpdateACL(shareableItem, acl)
			.then(() => {
				const { emailsToInvite } = this.state;

				if (emailsToInvite.length) {
					const emailsToInviteLength = emailsToInvite.filter(c => isValidEmail(c.address)).length;
					notify({
						message: (
							<Text
								id="shareItemModal.notifications.itemShared"
								plural={emailsToInviteLength}
								fields={{ count: emailsToInviteLength }}
							/>
						)
					});
				}

				this.setState({
					pendingSave: false,
					error: null,
					emailsToInvite: []
				});

				onClose(); // close the modal dialog on successful save
			})
			.catch(e => {
				const errorCode = faultCode(e);
				if (errorCode === 'service.PERM_DENIED') {
					/cannot grant access to the owner of the item/.test(e.message)
						? notify({
								failure: true,
								message: (
									<Text id={`shareItemModal.notifications.errors.${screen}.cannotShareWithSelf`} />
								)
						  })
						: this.setState({
								error: (
									<div>
										<Text id="shareItemModal.notifications.errors.externalSharingError" />
									</div>
								)
						  });
				} else if (errorCode === 'mail.CANNOT_GRANT') {
					this.setState({
						error: (
							<div>
								<Text id={`shareItemModal.notifications.errors.cannotGrant`} />
							</div>
						)
					});
				} else {
					notify({
						failure: true,
						message: <Text id="error.genericInvalidRequest" />
					});
				}

				this.setState({ pendingSave: false });
			});
	};

	update({ shareableItem: { acl } = {} }) {
		if (!this.state.acl) {
			this.setState({
				acl: acl
					? {
							...acl,
							grant: acl.grant || []
					  }
					: {
							grant: []
					  }
			});
		}
	}

	componentWillMount() {
		this.update(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.update(nextProps);
	}

	render(
		{
			shareableItem,
			onClose,
			screen,
			restURL,
			ownerEmail,
			isPublicSharingEnabled,
			isExternalSharingEnabled
		},
		{ acl, showAccessSettings, pendingSave, emailsToInvite, invitePermissions, error }
	) {
		const emailGrants = getEmailGrants(acl);
		const filterEmailGrants = emailGrants.filter(({ address }) => address !== ownerEmail);
		const aclEmails = filterEmailGrants.map(g => getEmail(g.address || g.zimbraId));
		const enableEmail = filterEmailGrants && filterEmailGrants.length > 0;

		return (
			<ModalDialog
				title={
					showAccessSettings ? (
						<Text id="shareItemModal.settingsTitle" />
					) : (
						<Text
							id={`shareItemModal.${screen}.title`}
							fields={{ name: getTranslatedFolderName(shareableItem) }}
						/>
					)
				}
				onAction={this.handleSave}
				onClose={onClose}
				actionLabel="buttons.save"
				cancelLabel="buttons.close"
				pending={pendingSave}
				error={error}
				disablePrimary={!!error}
			>
				{showAccessSettings ? (
					<ShareAccessSettings
						emailGrants={filterEmailGrants}
						onRemoveEmailGrant={this.handleRemoveEmailGrant}
						onUpdateGrant={this.handleUpdateGrant}
						screen={screen}
					/>
				) : (
					<ShareMain
						acl={acl}
						aclEmails={aclEmails}
						emailGrants={filterEmailGrants}
						emailsToInvite={emailsToInvite}
						invitePermissions={invitePermissions}
						enableEmail={enableEmail}
						onACLChange={this.handleACLChange}
						onEmailsToInviteChange={this.handleEmailsToInviteChange}
						onInvitePermissionsChange={linkstate(this, 'invitePermissions')}
						onManageAccess={this.handleToggleAccessSettings}
						publicURL={getPublicUrl(shareableItem, restURL)}
						screen={screen}
						ownerEmail={ownerEmail}
						isPublicSharingEnabled={isPublicSharingEnabled}
						isExternalSharingEnabled={isExternalSharingEnabled}
					/>
				)}
			</ModalDialog>
		);
	}
}
