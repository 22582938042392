import { ChoiceInput } from '@zimbra/blocks';
import { Text, Localizer } from 'preact-i18n';
import TextInput from '../../text-input';
import cx from 'classnames';
import style from '../style';
import SelectOption from '../../select';
import moment from 'moment';
import { setDefaultReminderValue } from '../../../utils/calendar';
import { REMIND_OPTIONS } from '../../../constants/calendars';

export const StartOfWeek = ({ startOfWeek, onFieldChange }) => (
	<div class={style.subsection}>
		<div class={cx(style.subsectionTitle, style.forSelect)}>
			<Text id="settings.calendarAndReminders.startOfWeekSubsection" />
		</div>
		<div class={style.subsectionBody}>
			<div class={style.half}>
				<SelectOption value={startOfWeek} onChange={onFieldChange('startOfWeek')} fullWidth>
					{moment.weekdays().map(day => (
						<option value={day}>{day}</option>
					))}
				</SelectOption>
			</div>
		</div>
	</div>
);

export const AutoAddAppointments = ({ autoAddAppointmentsToCalendar, onFieldChange }) => (
	<div class={cx(style.subsection, style.notYetImplemented)}>
		<div class={style.subsectionTitle}>
			<Text id="settings.calendarAndReminders.eventsListsSubsection" />
		</div>
		<div class={style.subsectionBody}>
			<ul class={style.list}>
				<li>
					<label>
						<ChoiceInput
							onChange={onFieldChange('autoAddAppointmentsToCalendar')}
							checked={autoAddAppointmentsToCalendar}
						/>
						<Text id="settings.calendarAndReminders.autoAddAppointmentsToCalendar" />
					</label>
				</li>
			</ul>
		</div>
	</div>
);

export const ShowEventTimeZone = ({
	showEventStartEndTimezone,
	updateEventStartEndTimezoneShow
}) => (
	<div class={style.subsection}>
		<div class={style.subsectionTitle}>
			<Text id="settings.calendarAndReminders.showEventTimeZoneTitle" />
		</div>
		<div class={style.subsectionBody}>
			<ul class={style.list}>
				<li>
					<label>
						<ChoiceInput
							onChange={updateEventStartEndTimezoneShow}
							checked={showEventStartEndTimezone}
						/>
						<span>
							<Text id="settings.calendarAndReminders.showEventTimeZone" />
						</span>
					</label>
				</li>
			</ul>
		</div>
	</div>
);

export const EnableDelegation = ({
	enableAppleIcalDelegation,
	updateAppleIcalDelegationEnabled
}) => (
	<div class={style.subsection}>
		<div class={cx(style.subsectionTitle)}>
			<Text id="settings.calendarAndReminders.sharingTitle" />
		</div>
		<div class={style.subsectionBody}>
			<ul class={style.list}>
				<li>
					<label>
						<ChoiceInput
							onChange={updateAppleIcalDelegationEnabled}
							checked={enableAppleIcalDelegation}
						/>
						<Text id="settings.calendarAndReminders.enableAppleIcalDelegation" />
					</label>
				</li>
			</ul>
		</div>
	</div>
);

export const EnableDeclinedEvents = ({ showDeclinedEvents, toggleDeclinedEvents }) => (
	<div class={style.subsection}>
		<div class={cx(style.subsectionTitle)}>
			<Text id="settings.calendarAndReminders.declinedEvents" />
		</div>
		<div class={style.subsectionBody}>
			<label>
				<ChoiceInput onChange={toggleDeclinedEvents} checked={showDeclinedEvents} />
				<Text id="settings.calendarAndReminders.enableDeclinedEvents" />
			</label>
		</div>
	</div>
);

export const ReminderEmail = ({
	calendarReminderEmail,
	isInvalidReminderEmail,
	handleReminderEmailInput
}) => (
	<div class={style.subsection}>
		<div class={cx(style.subsectionTitle, style.verticallyCenteredLabel)}>
			<Text id="settings.calendarAndReminders.sendRemiderTo" />
		</div>
		<div class={style.subsectionBody}>
			<Localizer>
				<TextInput
					value={calendarReminderEmail}
					placeholder={<Text id="settings.emailPlaceholder" />}
					class={style.emailAlarmAddrInput}
					invalid={isInvalidReminderEmail}
					onInput={handleReminderEmailInput}
				/>
			</Localizer>
		</div>
	</div>
);

export const ShowReminder = ({ zimbraPrefCalendarToasterEnabled, onFieldChange }) => (
	<div class={style.subsection}>
		<div class={cx(style.subsectionTitle, style.verticallyCenteredLabel)}>
			<Text id="settings.calendarAndReminders.showReminderPopup" />
		</div>
		<div class={style.subsectionBody}>
			<label>
				<ChoiceInput
					onChange={onFieldChange('zimbraPrefCalendarToasterEnabled')}
					checked={zimbraPrefCalendarToasterEnabled}
				/>
				<Text id="settings.calendarAndReminders.showReminderPopupLabel" />
			</label>
		</div>
	</div>
);

export const ShowDefaultRemindOptions = ({
	zimbraPrefCalendarApptReminderWarningTime,
	handleReminderTimeChange
}) => {
	const defaultReminderValue = setDefaultReminderValue(zimbraPrefCalendarApptReminderWarningTime);
	return (
		<div class={style.subsection}>
			<div class={cx(style.subsectionTitle, style.verticallyCenteredLabel)}>
				<Text id="settings.calendarAndReminders.defaultReminderTimeLabel" />
			</div>
			<div class={style.subsectionBody}>
				<SelectOption value={defaultReminderValue} onChange={handleReminderTimeChange}>
					{REMIND_OPTIONS.map(k => (
						<option value={k} key={k}>
							<Text id={`calendar.editModal.fields.remindOptions.${k}`} />
						</option>
					))}
				</SelectOption>
			</div>
		</div>
	);
};

export const ShowReminderForPastDue = ({
	zimbraPrefCalendarShowPastDueReminders,
	onFieldChange
}) => (
	<div class={style.subsection}>
		<div class={cx(style.subsectionTitle, style.verticallyCenteredLabel)}>
			<Text id="settings.calendarAndReminders.showReminderForPastDue" />
		</div>
		<div class={style.subsectionBody}>
			<label>
				<ChoiceInput
					onChange={onFieldChange('zimbraPrefCalendarShowPastDueReminders')}
					checked={zimbraPrefCalendarShowPastDueReminders}
				/>
				<Text id="settings.calendarAndReminders.showReminderForPastDueLabel" />
			</label>
		</div>
	</div>
);
