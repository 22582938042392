import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';
import moment from 'moment';
import get from 'lodash-es/get';
import set from 'lodash-es/set';

const NEW_EVENT = {
	event: {},
	remindDesktop: true,
	repeatValue: 'NONE',
	showAsValue: 'B',
	isPrivate: false,
	isChoosingAttachments: false,
	notes: '',
	attendees: [],
	forwardAttendees: [],
	attachments: [],
	aidArr: [],
	isErrored: false,
	locations: [],
	showSendInviteModal: false,
	isDraft: false,
	neverSent: false,
	isOrganizer: false,
	isFormDirty: false,
	showDiscardChangeModal: false,
	showInEventNotification: false,
	pausedBeforeUnload: false,
	allInviteesRemoved: false,
	customSettingChanged: false,
	isAlarmAsEmailEnabled: true,
	isStartDateValid: true,
	isEndDateValid: true,
	isNewEvent: true
};

const initialState = {
	date: moment().startOf('day').toDate(),
	activeModal: {
		modalType: null,
		modalProps: null
	},
	eventsData: {},
	isEventTabClosing: false,
	isMultipleEventTabClosing: false
};

export default handleActions(
	{
		[actionCreators.setDate]: (state, action) => ({
			...state,
			date: moment(action.payload).startOf('day').toDate()
		}),
		[actionCreators.toggleModal]: (state, action) => ({
			...state,
			activeModal: action.payload
		}),
		[actionCreators.setEvent]: (state, { payload }) => {
			const { eventsData } = state;
			const eventObject = get(state, `eventsData.${payload.tabId}`);

			const updateEventObject = {
				...eventObject,
				...payload.eventData
			};

			set(eventsData, `${payload.tabId}`, updateEventObject);

			return {
				...state,
				eventsData
			};
		},
		[actionCreators.addNewEvent]: (state, { payload: { tabId, subType } }) => {
			const { eventsData } = state;
			eventsData[tabId] = {
				...NEW_EVENT,
				...(subType === 'quickAdd' && { isQuickAddEvent: true })
			};
			return {
				...state,
				eventsData
			};
		},
		[actionCreators.removeEventData]: (state, { payload }) => {
			const { eventsData } = state;
			delete eventsData[payload.tabId];

			return {
				...state,
				eventsData
			};
		},
		[actionCreators.removeSpecificEventTabData]: (state, { payload }) => {
			const { eventsData } = state;
			payload.forEach(tabId => delete eventsData[tabId]);
			return {
				...state,
				eventsData
			};
		},
		[actionCreators.removeAllEventTabsData]: state => ({
			...state,
			eventsData: {}
		}),
		[actionCreators.setEventTabClosing]: (state, { payload }) => ({
			...state,
			isEventTabClosing: payload
		}),
		[actionCreators.setMultipleEventTabClosing]: (state, { payload }) => ({
			...state,
			isMultipleEventTabClosing: payload
		})
	},
	initialState
);
