import { graphql } from '@apollo/client/react/hoc';

import ClientInfoQuery from '../graphql/queries/get-client-config.graphql';

export default function withClientInfo(mapProps, config = {}) {
	return graphql(ClientInfoQuery, {
		options: ({ hostName }) => ({
			variables: hostName
				? { by: 'name', domain: hostName }
				: { by: 'virtualHostname', domain: window.location.hostname }
		}),
		props: result => mapProps(result),
		...config
	});
}
