import { useCallback } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import get from 'lodash-es/get';
import AddressField from '../../address-field';
import { setAdvanceOptions } from '../../../store/search/actions';
import style from '../style';

export const AddressWrapper = ({ field }) => {
	const dispatch = useDispatch();
	const fieldName = useSelector(state => get(state, `search.${field}`, []));

	const handleValue = useCallback(
		({ value }) => dispatch(setAdvanceOptions({ [field]: value })),
		[field, dispatch]
	);

	return (
		<AddressField
			class={cx(style.contactSuggestInput, style.addressFieldInput)}
			tokenInputClass={style.tokenInput}
			tokenInputStyle={style.tokenInputStyle}
			value={fieldName}
			onChange={handleValue}
		/>
	);
};
