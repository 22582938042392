import postMessagesToWebkit from '../utils/webkit-ios';

export default function saveAs({ url, base64, filename, contentType }) {
	if (typeof url === 'string') {
		// Hack for https://bugzilla.mozilla.org/show_bug.cgi?id=1237226
		//   If the URL responds with `Content Disposition: inline;` Firefox
		//   will not download the resource.
		if (/\/service\/home\/(?!.*\?.*disp=a)/i.test(url)) {
			url += `${url.indexOf('?') === -1 ? '?' : '&'}disp=a`;
		}
	} else if (typeof process.env.ELECTRON_ENV !== 'undefined' && base64) {
		// In case we receive `base64` data, we create in-memory file and then, upon confirmation, save it to the hard disk.
		url = `data:${contentType};base64,${base64}`;
	} else {
		// Blob support for other scenarios.
		url = URL.createObjectURL(url);
	}

	startAttachmentDownloadProcess(url, filename);
	postMessagesToWebkit('attachmentDownloadProcess', { url, filename });
}

export function startAttachmentDownloadProcess(resourceURL, resourceName) {
	if (window.cordova) {
		const event = new CustomEvent('cordovaDownload', {
			detail: { url: resourceURL, name: resourceName }
		});
		document.dispatchEvent(event);
	} else {
		const link = document.createElement('a');
		if ('download' in link) {
			if (resourceName) {
				fetch(resourceURL)
					.then(res => res.blob())
					.then(blob => {
						const url = window.URL.createObjectURL(blob);
						link.href = url;
						link.setAttribute('download', resourceName || '');
						link.dispatchEvent(new MouseEvent('click', { view: window }));
					});
			} else {
				link.href = resourceURL;
				link.download = '';

				link.dispatchEvent(new MouseEvent('click', { view: window }));
			}
		} else {
			// fallback, open resource in new tab.
			window.open(resourceURL, '_blank', '');
		}
	}
}
