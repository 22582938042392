import { useDispatch } from 'react-redux';
import { getSelectedDocumentIdsThunk, getUrlAndDefaultSortThunk } from '../thunk';
import { useCallback } from 'preact/hooks';
import { updateRenameItemId } from '../../../store/briefcase/actions';
import { useBriefcaseFoldersQuery, useRenameItemActionMutation } from '../../../hooks/briefcase';
import { itemActionVariables } from '../../../hooks/action/utils';
import { types as apiClientTypes } from '@zimbra/api-client';
import get from 'lodash-es/get';
import { getFolderSearchQueryParameters, getBriefcaseFolders, isButtonEnabled } from '../util';
import { getFoldersAndSharedFolders } from '../../../utils/folders';
import { gql } from '@apollo/client';
import { faultCode } from '../../../utils/errors';
import { notify } from '../../../store/notifications/actions';
import { Text } from 'preact-i18n';
const { ActionOps } = apiClientTypes;

/**
 * This hook will handle rename related actions has it is used
 * in list of each document item
 */
export const useRenameActions = ({ client }) => {
	const dispatch = useDispatch();

	const documentAction = useRenameItemActionMutation();

	const handleRenameItemId = useCallback(() => {
		const selectedItem = dispatch(getSelectedDocumentIdsThunk());
		dispatch(updateRenameItemId(selectedItem[0]));
	}, [dispatch]);

	const unsetRenameItemId = useCallback(() => {
		dispatch(updateRenameItemId(null));
	}, [dispatch]);

	const handleRename = useCallback(
		({ newName, id, document }) => {
			getBriefcaseFolders(client).then(({ data: foldersData }) => {
				const { folders, sharedFolders } = getFoldersAndSharedFolders(foldersData);
				const { url, defaultSort } = dispatch(getUrlAndDefaultSortThunk());
				client
					.query({
						query: gql`
							query {
								getPreferences {
									zimbraPrefSortOrder
								}
							}
						`
					})
					.then(({ data }) => {
						const zimbraPrefSortOrder = get(data, 'getPreferences.zimbraPrefSortOrder');
						const { sortValue, documentQuery } = getFolderSearchQueryParameters({
							url,
							defaultSort,
							folders,
							sharedFolders,
							zimbraPrefSortOrder
						});

						documentAction({
							variables: itemActionVariables({
								id,
								op: ActionOps.rename,
								name: newName
							}),
							...(sortValue.includes('subj') && { documentQuery, sortValue }),
							document,
							newName
						}).catch(error => {
							if (faultCode(error) === 'mail.ALREADY_EXISTS') {
								dispatch(
									notify({
										message: (
											<Text
												id="briefcase.operation.rename.error"
												fields={{
													name: newName
												}}
											/>
										),
										failure: true
									})
								);
							}
						});
					});
			});
		},
		[client, dispatch, documentAction]
	);

	return {
		handleRename,
		unsetRenameItemId,
		handleRenameItemId
	};
};

/**
 * This hook will handle rename related actions and enable property based on folder permission
 */

export const useRenameHandler = ({ client, documentList }) => {
	const { handleRename, unsetRenameItemId, handleRenameItemId } = useRenameActions({ client });

	const { data: foldersData } = useBriefcaseFoldersQuery({ fetchPolicy: 'cache-only' });
	const { folders, sharedFolders } = getFoldersAndSharedFolders(foldersData);

	const disableRename = !isButtonEnabled({
		documentList,
		sharedFolders,
		folders
	});

	return {
		handleRename,
		unsetRenameItemId,
		handleRenameItemId,
		disableRename
	};
};
