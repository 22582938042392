import split from '../../lib/split-point';
import { Spinner } from '@zimbra/blocks';
import style from './loading.less';

export default split(
	() =>
		import(
			/* webpackMode: "lazy", webpackChunkName: "photo-upload-modal" */ './photo-upload-modal'
		),
	'photo-upload-modal',
	<div class={style.loading}>
		<Spinner block class={style.spinner} />
	</div>
);
