import { Component } from 'preact';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { getFolder, getFolderNameFunction } from '../../utils/folders';
import CreateFolderMutation from '../../graphql/queries/folders/create-folder.graphql';
import GetFolder from '../../graphql/queries/folders/get-folder.graphql';

export default function withCreateFolderMutation() {
	// TODO: Refetch `getFolder` query?
	return graphql(CreateFolderMutation, {
		props: ({ mutate }) => ({ createFolder: mutate })
	});
}

export function guarenteeFolderExists({ folderName, options = {} }) {
	return compose(
		withCreateFolderMutation(),
		Child =>
			class WithOutboxWrapper extends Component {
				componentWillMount() {
					let folder;

					try {
						// Safely read the cache to check if the folder exists already
						folder = getFolder(
							this.context.client.readQuery({
								query: GetFolder,
								variables: {
									view: null
								}
							}),
							getFolderNameFunction(folderName)
						);
					} catch (e) {}

					if (!folder) {
						this.props
							.createFolder({
								variables: {
									name: folderName,
									fetchIfExists: true,
									parentFolderId: 1,
									view: 'message',
									...options
								},
								// When the Outbox folder did not exist, the mutation to create the folder and the refetch of folders to get the new list of
								// folders were part of the same batch query, hence the refetch did not contain the Outbox folder. Hence, added this refetch query
								// to refetch folder data once the outbox folder has been created (almost in the case of new user)
								refetchQueries: [
									{
										query: GetFolder
									}
								]
							})
							.then(({ data: { createFolder } }) => {
								if (typeof process.env.ELECTRON_ENV !== 'undefined' && createFolder) {
									const { rendererIpc } = require('@zimbra/electron-app');

									rendererIpc.createFolder(createFolder);
								}
							})
							// eslint-disable-next-line no-console
							.catch(ex => console.log('Exception to create folder', ex));
					}
				}

				render(props) {
					return <Child {...props} />;
				}
			}
	);
}
