import { useCallback } from 'preact/hooks';
import { route } from 'preact-router';
import { Button } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import get from 'lodash-es/get';
import { notify } from '../../../store/notifications/actions';
import { UpdatedSmartFolderQuery } from '../../notifications/messages';
import modifySearchFolderMutation from '../../../graphql/queries/folders/modify-search-mutation.graphql';
import { getSearchQueryString } from '../../../utils/search';
import style from '../style';
import { getQueryObject } from '../utils';
import { getAdvanceSearchData } from '../thunk';
import { useClientConfig } from '../../../hooks/client-config';

export const Actions = ({ queryOptions, onHideAdvanced, searchValue }) => {
	const dispatch = useDispatch();
	const [modifySearchFolder] = useMutation(modifySearchFolderMutation);
	const { slugs } = useClientConfig('routes.slugs');

	const handleSubmit = useCallback(
		e => {
			e.preventDefault();

			const { search, view, types, email } = dispatch(getAdvanceSearchData());
			const q = searchValue || email;
			const qObject = getQueryObject({ q, types, ...search });

			onHideAdvanced();

			if (queryOptions) {
				const updatedQuery = getSearchQueryString({ ...qObject, query: qObject.q });

				modifySearchFolder({
					variables: {
						search: {
							id: get(search, 'activeFolder.id'),
							query: updatedQuery,
							types
						}
					}
				}).then(() =>
					dispatch(
						notify({
							message: <UpdatedSmartFolderQuery />
						})
					)
				);
			} else {
				const qString = queryString.stringify(qObject);

				route(`/${slugs.search}/${view}?${qString}`);
			}
		},
		[dispatch, searchValue, onHideAdvanced, queryOptions, modifySearchFolder, slugs.search]
	);

	return (
		<div class={style.formActions}>
			<Button styleType="primary" type="submit" onClick={handleSubmit} class={style.submitButton}>
				<Text id={queryOptions ? 'buttons.updateSearch' : 'buttons.search'} />
			</Button>

			<Button
				styleType="text"
				href="https://wiki.zimbra.com/wiki/Zimbra_Web_Client_Search_Tips"
				target="_blank"
				useAnchor
				class={style.searchTips}
			>
				<Text id="search.advanced.searchTips" />
			</Button>
		</div>
	);
};
