import { useCallback } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { notify } from '../../store/notifications/actions';
import { route } from 'preact-router';
import { Text } from 'preact-i18n';
import { DEFAULT_UNDO_DURATION } from '../../constants/undo-timeout';
import { urlForMessage, notifyMailError } from '../../utils/drafts';
import { useMutation } from '@apollo/client';
import SendMessageMutation from '../../graphql/queries/send-message-mutation.graphql';
import {
	convertMessageToZimbra,
	createOptimisticMessageInfo
} from '../../graphql-decorators/send-message';
import MessageQuery from '../../graphql/queries/message.graphql';
import {
	optimisticAddToFolder,
	optimisticRemoveFromFolder,
	optimisticWriteMessage,
	findFolderInCache
} from '../../graphql/utils/graphql-optimistic';
import { OUTBOX, DRAFTS } from '../../constants/folders';
import { types as apiClientTypes } from '@zimbra/api-client';
const { MessageFlags } = apiClientTypes;
import { removeFlag } from '../../lib/util';
import { generateOfflineId } from '../../utils/offline';

export const useMsgSendHandler = ({ sendMessage, sendMessageWithDelay, clearAutoSend }) => {
	const dispatch = useDispatch();

	const handleSend = useCallback(
		({
			isReadReceiptRequested,
			message,
			mailboxMetadata,
			onSend,
			zimbraMtaMaxMessageSize,
			isOffline,
			accountName,
			genericMailError,
			updateCache = true,
			urlSlugs,
			folderName
		}) => {
			if (message.autoSendTime || (mailboxMetadata && mailboxMetadata.zimbraPrefUndoSendEnabled)) {
				return sendMessageWithDelay({
					message,
					delay: message.autoSendTime || Date.now() + DEFAULT_UNDO_DURATION * 1000,
					requestReadReceipt: isReadReceiptRequested
				}).then(
					({
						data: {
							saveDraft: {
								message: [draftSaved]
							}
						}
					}) => {
						onSend && onSend();
						if (
							!message.autoSendTime &&
							mailboxMetadata &&
							mailboxMetadata.zimbraPrefUndoSendEnabled
						) {
							dispatch(
								notify({
									message: <Text id="mail.notifications.sent" />,
									action: {
										label: <Text id="buttons.undo" />,
										fn: () => {
											route(urlForMessage(draftSaved.id, urlSlugs, folderName));

											clearAutoSend(draftSaved).then(() => {
												dispatch(
													notify({
														message: <Text id="mail.notifications.sendUndone" />
													})
												);
											});
										}
									}
								})
							);
						}
					}
				);
			}
			return sendMessage({
				message,
				requestReadReceipt: isReadReceiptRequested,
				accountName,
				updateCache
			})
				.then(() => {
					dispatch(
						notify({
							message: <Text id="mail.notifications.sent" />
						})
					);
					onSend && onSend();
				})
				.catch(err => {
					console.error(err);
					if (!isOffline) {
						dispatch(notifyMailError(err, notify, zimbraMtaMaxMessageSize, genericMailError));
						throw err;
					}
				});
		},
		[clearAutoSend, dispatch, sendMessage, sendMessageWithDelay]
	);

	return { handleSend };
};

export const useSendMutation = () => {
	return useMutation(SendMessageMutation);
};

export const useSendMessageMutation = ({
	zimbraPrefMessageViewHtmlPreferred = true,
	isOffline = false
}) => {
	const [sendMessage] = useSendMutation();

	return useCallback(
		({
			message,
			requestReadReceipt,
			accountName,
			updateCache = true,
			sign = false,
			encrypt = false
		}) => {
			const isOfflineDesktopApp = typeof process.env.ELECTRON_ENV !== 'undefined' && isOffline;

			if (!message.draftId) {
				message.draftId = generateOfflineId();
			} else if (!message.id && isOfflineDesktopApp) {
				message.id = message.draftId;
			}

			return sendMessage({
				context: {
					offlineQueueName: `sendMessage:${message.draftId}`,
					// Cancel any outstanding `saveDraft` requests
					cancelQueues: `saveDraft:${message.draftId}`,
					local: isOfflineDesktopApp
				},
				variables: {
					message: convertMessageToZimbra(message, { requestReadReceipt, isOfflineDesktopApp }),
					accountName,
					...(sign && { sign: true }),
					...(encrypt && { encrypt: true })
				},
				optimisticResponse: {
					sendMessage: {
						__typename: 'SendMessageOptResponse',
						message: {
							__typename: 'MsgWithGroupInfo',
							id: message.draftId
						}
					}
				},
				...(updateCache && {
					update: (cache, { data }) => {
						if (isOfflineDesktopApp) return;
						if (data.sendMessage.__typename !== 'SendMessageOptResponse') {
							try {
								// Check for existing message in cache
								const cachedMessage = cache.readQuery({
									query: MessageQuery,
									variables: {
										id: message.draftId,
										html: zimbraPrefMessageViewHtmlPreferred,
										max: 250000
									}
								}).message;

								optimisticRemoveFromFolder(cache, OUTBOX, cachedMessage);
							} catch (e) {}

							return;
						}

						// When offline mode is enabled messages are optimistically placed in the outbox
						const outbox = findFolderInCache(cache, folder => folder.name === OUTBOX);

						if (!outbox) {
							// If the outbox does not exist, offline mode is disabled so do nothing.
							return;
						}

						const messageInfo = createOptimisticMessageInfo({
							...message,
							flags: removeFlag(message.flags, MessageFlags.draft) + MessageFlags.unread,
							id: data.sendMessage.message.id,
							folderId: outbox.id,
							date: new Date(),
							autoSendTime: data?.sendMessage?.message?.autoSendTime
						});

						let cachedMessage = {};
						try {
							// Check for existing message in cache
							cachedMessage = cache.readQuery({
								query: MessageQuery,
								variables: {
									id: messageInfo.draftId,
									html: zimbraPrefMessageViewHtmlPreferred,
									max: 250000
								}
							}).message;
						} catch (e) {}

						// 1. if there message is not already in outbox, add it.
						if (cachedMessage.folderId !== outbox.id) {
							optimisticAddToFolder(cache, OUTBOX, messageInfo);
						}

						// 2. always write message to MessageQuery
						optimisticWriteMessage(cache, messageInfo, zimbraPrefMessageViewHtmlPreferred);

						// 3. Remove message from drafts folder search results
						if (
							cachedMessage.flags &&
							~cachedMessage.flags.indexOf(MessageFlags.draft) &&
							cachedMessage.folderId !== outbox.id
						) {
							optimisticRemoveFromFolder(
								cache,
								cachedMessage.folderId ? { id: cachedMessage.folderId } : DRAFTS,
								messageInfo
							);
						}
					}
				})
			});
		},
		[zimbraPrefMessageViewHtmlPreferred, isOffline, sendMessage]
	);
};
