export const FILTER_TEST_TYPE = {
	BODY: 'body',
	ADDRESS: 'address',
	HEADER: 'header',
	HEADER_EXIST: 'headerExists'
};

export const FILTER_CONDITION_TYPE = {
	TOCC: 'to,cc',
	FROM: 'from',
	ALLORANY: 'allOrAny',
	SUBJECT: 'subject'
};

export const FILTER_ACTION_TYPE = {
	FILE_INTO: 'fileInto',
	DISCARD: 'discard',
	REDIRECT: 'redirect',
	STOP: 'stop',
	FLAG: 'flag',
	MARK_AS_READ: 'read',
	KEEP_IN_INBOX: 'keep',
	TAG: 'tag'
};

export const RULE_PATH_PREFIX = ['conditions', '0'];

const RULE_PREDICATE_OPTIONS = {
	MATCHES_EXACTLY: {
		update: { stringComparison: 'is', negative: false },
		value: 'MATCHES_EXACTLY'
	},
	DOES_NOT_MATCH_EXACTLY: {
		update: { stringComparison: 'is', negative: true },
		value: 'DOES_NOT_MATCH_EXACTLY'
	},
	DOES_NOT_CONTAIN: {
		value: 'DOES_NOT_CONTAIN',
		update: { stringComparison: 'contains', negative: true }
	},
	CONTAINS: {
		value: 'CONTAINS',
		update: { stringComparison: 'contains', negative: false }
	},
	HEADER_CONTAINS: {
		value: 'CONTAINS',
		update: { stringComparison: 'contains', negative: false }
	},
	MATCHES_WILDCARD: {
		value: 'MATCHES_WILDCARD',
		update: { stringComparison: 'matches', negative: false }
	},
	DOES_NOT_MATCH_WILDCARD: {
		value: 'DOES_NOT_MATCH_WILDCARD',
		update: { stringComparison: 'matches', negative: true }
	},
	HEADER_EXIST: {
		value: 'HEADER_EXIST',
		update: { key: 'headerExists', negative: false }
	},
	HEADER_DOES_NOT_EXIST: {
		value: 'HEADER_DOES_NOT_EXIST',
		update: { key: 'headerExists', negative: true }
	},
	BODY_CONTAINS: {
		value: 'BODY_CONTAINS',
		update: { negative: false }
	},
	BODY_DOES_NOT_CONTAIN: {
		value: 'BODY_DOES_NOT_CONTAIN',
		update: { negative: true }
	}
};
export function getRulePredicateOptions({
	containsLabel,
	containsNotLabel,
	matchesWildCard,
	matchesWildCardNot,
	matchesExactlyLabel,
	matchesExactlyNotLabel,
	headerDoesNotexistLabel,
	headerExistLabel
}) {
	RULE_PREDICATE_OPTIONS.MATCHES_EXACTLY.label = matchesExactlyLabel;
	RULE_PREDICATE_OPTIONS.DOES_NOT_MATCH_EXACTLY.label = matchesExactlyNotLabel;
	RULE_PREDICATE_OPTIONS.DOES_NOT_CONTAIN.label = containsNotLabel;
	RULE_PREDICATE_OPTIONS.CONTAINS.label = containsLabel;
	RULE_PREDICATE_OPTIONS.HEADER_CONTAINS.label = containsLabel;
	RULE_PREDICATE_OPTIONS.MATCHES_WILDCARD.label = matchesWildCard;
	RULE_PREDICATE_OPTIONS.DOES_NOT_MATCH_WILDCARD.label = matchesWildCardNot;
	RULE_PREDICATE_OPTIONS.BODY_CONTAINS.label = containsLabel;
	RULE_PREDICATE_OPTIONS.BODY_DOES_NOT_CONTAIN.label = containsNotLabel;
	RULE_PREDICATE_OPTIONS.HEADER_EXIST.label = headerExistLabel;
	RULE_PREDICATE_OPTIONS.HEADER_DOES_NOT_EXIST.label = headerDoesNotexistLabel;
	return RULE_PREDICATE_OPTIONS;
}
export const NEW_FILTER_RULE = {
	name: '',
	active: true,
	actions: [
		{
			[FILTER_ACTION_TYPE.FILE_INTO]: [
				{
					index: 0,
					folderPath: 'Inbox'
				}
			],
			[FILTER_ACTION_TYPE.STOP]: [{ index: 1 }]
		}
	],
	conditions: [
		{
			allOrAny: 'allof',
			[FILTER_TEST_TYPE.ADDRESS]: [
				{
					...RULE_PREDICATE_OPTIONS.CONTAINS.update,
					index: 0,
					header: 'from',
					part: 'all',
					value: ''
				},
				{
					...RULE_PREDICATE_OPTIONS.CONTAINS.update,
					index: 1,
					header: 'to,cc',
					part: 'all',
					value: ''
				}
			],
			[FILTER_TEST_TYPE.HEADER]: [
				{
					...RULE_PREDICATE_OPTIONS.HEADER_CONTAINS.update,
					index: 2,
					header: 'subject',
					value: ''
				}
			],
			[FILTER_TEST_TYPE.BODY]: [
				{
					...RULE_PREDICATE_OPTIONS.BODY_CONTAINS.update,
					value: '',
					index: 3
				}
			],
			[FILTER_TEST_TYPE.HEADER_EXIST]: [
				{
					header: '',
					index: 4
				}
			]
		}
	]
};
