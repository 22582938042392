import { CONVERSATION, MESSAGE } from './types';

export const groupMailBy = {
	name: 'zimbraPrefGroupMailBy',
	values: {
		conversation: CONVERSATION,
		message: MESSAGE
	},
	default: CONVERSATION
};

export const PREF_TAG_TREE_OPEN = 'zimbraPrefTagTreeOpen';
