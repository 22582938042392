import { ASCENDING, DESCENDING } from '../constants';

/**
 * Extracts and returns sortBy and sortOrder from provided sortValue.
 *
 * @returns {Array} returns array of length 2
 */
export const splitSortValue = sortValue => {
	if (!sortValue) return;

	if (sortValue.indexOf(DESCENDING, sortValue.length - 4) !== -1) {
		// Check 'Desc'(length 4) is present at the end of sortValue string.
		return [sortValue.split(DESCENDING)[0], DESCENDING];
	} else if (sortValue.indexOf(ASCENDING, sortValue.length - 3) !== -1) {
		// Check 'Asc'(length 3) is present at the end of sortValue string.
		return [sortValue.split(ASCENDING)[0], ASCENDING];
	}

	return;
};

/**
 * Joins and returns sortValue from provided values of sortBy and sortOrder.
 *
 * @returns {String} returns sortValue
 */
export const joinSortValue = (sortBy, sortOrder) => {
	if (!sortBy || !sortOrder) return;
	return `${sortBy}${sortOrder}`;
};
