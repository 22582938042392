import { Text } from 'preact-i18n';
import style from './style';
import { ROUTE_EVENT, ROUTE_NEW } from '../calendar/constants';
import ActionButton from '../action-button';
import { useCallback } from 'preact/hooks';

export function AddEvent({ handleNewTab, calendarUrlSlug, closeEventTabThunkAction, isOffline }) {
	const addEvent = useCallback(() => {
		handleNewTab({
			url: `/${calendarUrlSlug}/${ROUTE_EVENT}/${ROUTE_NEW}`,
			type: 'event',
			onClose: closeEventTabThunkAction
		});
	}, [calendarUrlSlug, closeEventTabThunkAction, handleNewTab]);

	return (
		<ActionButton
			class={style.addEvent}
			monotone
			icon="plus"
			iconSize="sm"
			onClick={addEvent}
			textClass={style.title}
			disabled={isOffline && typeof process.env.ELECTRON_ENV !== 'undefined'}
		>
			<Text id="miniCal.addEvent" />
		</ActionButton>
	);
}
