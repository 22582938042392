import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';
import get from 'lodash-es/get';
import unset from 'lodash-es/omit';

const initialValues = {
	pollingInterval: 2000,
	addPollingDelay: 0,
	isInQueue: false,
	isRunning: false,
	isCompleted: false,
	processId: null
};

const getUpdatedStateObject = (state, requestId, overrideObject = {}, isNewRequest = false) => {
	if (isNewRequest) {
		if (!requestId || state.requests[requestId]) return state;
	} else if (!state.requests[requestId]) return state;

	return {
		...state,
		requests: {
			...state.requests,
			[requestId]: {
				...state.requests[requestId],
				...overrideObject
			}
		}
	};
};

const initialState = { requests: {} };

export default handleActions(
	{
		[actionCreators.addPollingRequest]: (state, action) =>
			getUpdatedStateObject(
				state,
				get(action, 'payload.id'),
				{
					...initialValues,
					...action.payload
				},
				true
			),
		[actionCreators.initPollingRequest]: (state, action) =>
			getUpdatedStateObject(state, action.payload, {
				isInQueue: true,
				isRunning: false
			}),
		[actionCreators.startPollingRequest]: (state, action) => {
			const { id: requestId, processId } = action.payload;

			const requestData = state.requests[requestId];
			if (!requestData) return state;

			const { pollingInterval, addPollingDelay } = requestData;
			const newPollingInterval = pollingInterval + addPollingDelay;

			return getUpdatedStateObject(state, requestId, {
				processId,
				pollingInterval:
					addPollingDelay > 0 && newPollingInterval < 15000 ? newPollingInterval : pollingInterval,
				isInQueue: false,
				isRunning: true
			});
		},
		[actionCreators.moveRequestToQueue]: (state, action) => {
			return getUpdatedStateObject(state, action.payload, {
				isInQueue: true,
				isRunning: false
			});
		},
		[actionCreators.stopPollingRequest]: (state, action) =>
			getUpdatedStateObject(state, action.payload, {
				isInQueue: false,
				isRunning: false,
				isCompleted: true
			}),
		[actionCreators.markCompleted]: (state, action) => ({
			...state,
			requests: unset(state.requests, action.payload)
		})
	},
	initialState
);
