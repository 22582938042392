import { USER_FOLDER_IDS } from '../../constants';
import { types as apiClientTypes } from '@zimbra/api-client';
import { getFolder, getFolderNameFunction } from '../../utils/folders';
import get from 'lodash-es/get';
import { DEFAULT_SORT } from '../../constants/search';
import { BRIEFCASE } from '../../constants/folders';
import { updateFoldersPathRecursively } from '../folder-list/util';
import { getSelectedDocumentIdsThunk } from './thunk';
import { route } from 'preact-router';
import { briefcaseFolderVariable, briefcaseSearchVariable } from '../../hooks/briefcase/utils';
import GetFolder from '../../graphql/queries/folders/get-folder.graphql';
import SearchQuery from '../../graphql/queries/search/search.graphql';
const { ActionOps } = apiClientTypes;

/**
 * Rights -
 * r - read, w - write, i - insert, d - delete, a - administer,
 * x - workflow action, p - view private, f - view freebusy, c - create subfolder
 */
export const hasDeletePermOnFolder = permissions => !permissions || permissions.includes('d');

export const hasInsertPermOnFolder = permissions => !permissions || permissions.includes('i');

export const getFolderIdDocumentIdsObject = items => {
	if (!items) {
		return null;
	} else if (Array.isArray(items)) {
		return items.reduce((acc, val) => {
			acc[val.folderId] = (acc[val.folderId] || []).concat(val.id);
			return acc;
		}, {});
	}
	return {
		[items.folderId]: [items.id]
	};
};

export const getFolderMapping = (selectedDoc, folderIds, folders, sharedFolders) => {
	const folderObject = folderIds.reduce((acc, val) => {
		let folder;
		if (val && val.indexOf(':') !== -1) {
			const [ownerZimbraId, sharedItemId] = val.split(':');

			//searching only in sharedFolders because in Documents data
			// the folderId for sharedFolders is of type ownerZimbraId:sharedItemId
			folder = getFolder(
				sharedFolders,
				f =>
					(f.sharedItemId &&
						f.ownerZimbraId &&
						f.sharedItemId === sharedItemId &&
						f.ownerZimbraId === ownerZimbraId) ||
					f.id === val ///for subfolders of sharedFolders we have folderId  of type ownerZimbraId:sharedItemId
			);

			//changing the value of the ownerZimbraId:sharedItemId with the folder.id that is found
			//because in folders data we get folderId to be different for shared Folders
			if (folder.id.indexOf(':') === -1) {
				//for subfolders of sharedFolders we have folderId of type ownerZimbraId:sharedItemId
				selectedDoc[folder.id] = selectedDoc[val];
				delete selectedDoc[val];
			}
		} else {
			//searching only in useFolders has folderId would be same in Documents data
			folder = getFolder(folders, f => f.id && f.id === val);
		}
		if (folder) {
			acc[folder.id] = folder;
		}
		return acc;
	}, {});

	return { updatedSelectedDoc: selectedDoc, folderObject };
};

export const getOperationOnMoveAction = ({
	documentList,
	folderList,
	destFolderId,
	folders,
	sharedFolders
}) => {
	const selectedDoc = getFolderIdDocumentIdsObject(documentList);

	const { updatedSelectedDoc, folderObject } = getFolderMapping(
		selectedDoc,
		Object.keys(selectedDoc),
		folders,
		sharedFolders
	);
	const folderIds = Object.keys(folderObject);
	const selectedOperationsOnItems = folderIds.reduce((acc, val) => {
		if (!hasDeletePermOnFolder(folderObject[val].permissions)) {
			acc[ActionOps.copy] = (acc[ActionOps.copy] || []).concat(
				...updatedSelectedDoc[folderObject[val].id]
			);
		} else if (Number(destFolderId) !== USER_FOLDER_IDS.TRASH) {
			acc[ActionOps.move] = (acc[ActionOps.move] || []).concat(
				...updatedSelectedDoc[folderObject[val].id]
			);
		} else {
			acc[ActionOps.trash] = (acc[ActionOps.trash] || []).concat(
				...updatedSelectedDoc[folderObject[val].id]
			);
		}
		return acc;
	}, {});

	folderList.forEach(item => {
		if (!hasDeletePermOnFolder(item.permissions)) {
			selectedOperationsOnItems[ActionOps.copy] = (
				selectedOperationsOnItems[ActionOps.trash] || []
			).concat(item.id);
		} else if (Number(destFolderId) !== USER_FOLDER_IDS.TRASH) {
			selectedOperationsOnItems[ActionOps.move] = (
				selectedOperationsOnItems[ActionOps.move] || []
			).concat(item.id);
		} else {
			selectedOperationsOnItems[ActionOps.trash] = (
				selectedOperationsOnItems[ActionOps.trash] || []
			).concat(item.id);
		}
	});

	return { selectedOperationsOnItems };
};

//common for star and delete to check if the button is enabled for individual items
export const isButtonEnabled = ({ documentList, folders, sharedFolders }) => {
	if (!folders || (folders && folders.length === 0)) {
		return false;
	}
	if (!documentList) {
		return false;
	}
	const selectedDoc = getFolderIdDocumentIdsObject(documentList);

	const { folderObject } = getFolderMapping(
		selectedDoc,
		Object.keys(selectedDoc),
		folders,
		sharedFolders
	);
	const folderIds = Object.keys(folderObject);

	if (folderIds.length === 1) {
		return hasDeletePermOnFolder(folderObject[folderIds[0]].permissions);
	}

	return folderIds.every(val => hasDeletePermOnFolder(folderObject[val].permissions));
};

export const getFolderSearchQueryParameters = ({
	url,
	defaultSort,
	sharedFolders,
	folders,
	zimbraPrefSortOrder
}) => {
	const folderName = get(url, 'routeProps.folderName') || BRIEFCASE;
	const isSearchView = get(url, 'routeProps.isSearchView', false);
	const documentQuery = (isSearchView && get(url, 'routeProps.q')) || `in:"${folderName}"`;
	const currentFolder = getFolder(
		[...folders, ...sharedFolders],
		getFolderNameFunction(folderName)
	);

	let sortValue = DEFAULT_SORT;
	if (zimbraPrefSortOrder) {
		sortValue =
			(isSearchView && defaultSort) ||
			(currentFolder && getSortOrderForFolder(zimbraPrefSortOrder, currentFolder)) ||
			DEFAULT_SORT;
	}

	return { url, folderName, documentQuery, sortValue, currentFolder, isSearchView };
};

export const getSortOrderForFolder = (zimbraPrefSortOrder, { id }) => {
	const result = zimbraPrefSortOrder && zimbraPrefSortOrder.match(new RegExp(`(${id}:\\w+)`));
	return (
		(result && result[0].substring(result[0].lastIndexOf(':') + 1, result[0].length)) ||
		DEFAULT_SORT
	);
};

export const getDocumentListData = ({ documents, currentFolder }) => {
	const folderData = (currentFolder && currentFolder.folders) || [];
	return [...folderData, ...documents];
};

export const getUpdatedTargetFolders = ({ folders, destFolder, currentFolderPath }) =>
	folders.reduce((acc, val, index) => {
		const { folders: f, absFolderPath } = val;

		acc[index] = {
			...val,
			parentFolderId: destFolder.id,
			absFolderPath: absFolderPath.replace(currentFolderPath, destFolder.absFolderPath),
			...(f && {
				folders: f.map(folder =>
					updateFoldersPathRecursively({
						folder,
						updatedFolderPath: destFolder.absFolderPath,
						currentFolderPath
					})
				)
			})
		};
		return acc;
	}, []);

export const routeOnDeleteOrMovingItem = ({ dispatch, url }) => {
	const selectedIds = dispatch(getSelectedDocumentIdsThunk());
	const pathname = get(url, 'location.pathname');
	const uri = `${pathname.substr(0, pathname.length - (selectedIds[0].length + 1))}${get(
		url,
		'location.search'
	)}`;
	route(uri);
};

export const getBriefcaseFolders = client =>
	client.query({
		query: GetFolder,
		variables: briefcaseFolderVariable(),
		fetchPolicy: 'cache-only'
	});

export const getBriefcaseDocuments = ({ client, query, sortValue }) =>
	client.query({
		query: SearchQuery,
		variables: briefcaseSearchVariable({ query, sortValue }),
		fetchPolicy: 'cache-only'
	});

/**
 *
 * @param {String} name
 * @param {String} version
 * @returns {String} e.g. <name> (<version>).txt OR <name> (<version>). like "Test.txt" would be "Test (1).txt".
 */
const appendVersionInFileName = (name, version) => {
	if (name.includes('.')) {
		const index = name.lastIndexOf('.');
		return `${name.slice(0, index)} (${version})${name.slice(index)}`;
	}
	return `${name} (${version})`;
};

export const removeVersionFromFileName = name => {
	const regex = /(^.*)(\s\(\d*\))/;
	const matchFound = name.match(regex);
	if (matchFound) {
		return name.replace(matchFound[2], '');
	}
	return name;
};

/**
 * This method used to retrive document items with earlier versions of document which is expanded by user
 * @param {Array} items documents array
 * @param {Array} expandedIds version id array which is expanded
 * @returns {Array} updated documents array along with document version items which is expanded
 */
export const getDocumentsWithVersions = (items, expandedIds) => {
	return items.reduce((acc, currentDoc) => {
		const retriveVersions = expandedIds.some(id => currentDoc.id === id);

		if (retriveVersions) {
			acc.push({ ...currentDoc, isExpanded: true, isLatest: true });
			if (currentDoc.docs?.length) {
				const versionsArray = currentDoc.docs.map(({ version }) => version);
				const maxVersion = Math.max(...versionsArray);
				const docVersions = currentDoc.docs.map(doc => {
					const updatedDoc = {
						...doc,
						id: `${doc.id}_${doc.version}`,
						name: appendVersionInFileName(doc.name, doc.version),
						isLatest: false,
						isExpanded: false,
						latestVersion: currentDoc.version
					};
					if (doc.version === maxVersion) {
						return { ...updatedDoc, current: true };
					}
					return updatedDoc;
				});
				acc.push(...docVersions);
			}
		} else {
			acc.push({ ...currentDoc, isExpanded: false, isLatest: true });
		}

		return acc;
	}, []);
};
