import AttachmentItem from './item';
import AttachmentGridHeader from './header';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setPreviewAttachment } from '../../store/attachment-preview/actions';
import saveAs from '../../lib/save-as';
import saveAsZip from '../../lib/save-as-zip';
import s from './style.less';
import { filterOutEMLattachment } from '../../lib/util';

function AttachmentGrid({
	attachments,
	removable,
	onPreview,
	onDownloadAll,
	onDownload,
	onRemove,
	cancelUploading,
	onRemoveAll,
	hideAttachmentHeader,
	isLocalFilePath,
	hideCloseIcon,
	hideAttachmentPreview = false,
	zimbraFeatureBriefcasesEnabled,
	showAttachToBriefcase,
	uploadingFiles = [],
	localFolder,
	messageId
}) {
	const isUploading = !!uploadingFiles.length;
	return (
		<div>
			{!hideAttachmentHeader && (
				<AttachmentGridHeader
					attachments={attachments.concat(uploadingFiles)}
					removable={removable}
					onDownload={onDownload}
					onDownloadAll={onDownloadAll}
					onRemoveAll={onRemoveAll}
					isUploading={isUploading}
				/>
			)}
			<div class={s.attachments}>
				{uploadingFiles &&
					uploadingFiles.map(attachment => (
						<AttachmentItem
							removable={removable}
							onRemove={cancelUploading}
							attachment={attachment}
							hideAttachmentPreview={hideAttachmentPreview}
							isUploading={isUploading}
						/>
					))}
				{attachments &&
					attachments.map(attachment => (
						<AttachmentItem
							showAttachToBriefcase={showAttachToBriefcase}
							zimbraFeatureBriefcasesEnabled={zimbraFeatureBriefcasesEnabled}
							removable={removable}
							onDownload={onDownload}
							onRemove={onRemove}
							onPreview={onPreview}
							attachment={attachment}
							hideAttachmentPreview={hideAttachmentPreview}
							isLocalFilePath={isLocalFilePath}
							hideCloseIcon={hideCloseIcon}
							messageId={messageId}
							localFolder={localFolder}
						/>
					))}
			</div>
		</div>
	);
}

export default compose(
	connect(null, (dispatch, { attachments, onRemove }) => ({
		onPreview: attachment =>
			dispatch(setPreviewAttachment(attachment, filterOutEMLattachment(attachments))),
		onDownload: attachment => saveAs(attachment),
		onDownloadAll: () => saveAsZip(filterOutEMLattachment(attachments)),
		onRemoveAll: () => onRemove({ all: true })
	}))
)(AttachmentGrid);
