import style from './style.less';
import { Button } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import AdvancedFilterConditionInput from './advanced-condition-input';

export default function AdvancedConditionContainer({
	allConditions,
	rulePredicateOptions,
	filterConditionConfig,
	matchesScreenMd,
	onRuleMatchCaseChange,
	onConditionLabelChange,
	onRulePredicateChange,
	onRuleValueChange,
	onDeleteCondition,
	onAddNewCondition,
	onHeaderNameChanged,
	headerInputPlaceholder
}) {
	return (
		<div class={style.conditionListWrapper}>
			{allConditions &&
				allConditions.map(
					eachCondition =>
						eachCondition && (
							<AdvancedFilterConditionInput
								condition={eachCondition}
								filterConditionConfig={filterConditionConfig}
								rulePredicateOptions={rulePredicateOptions}
								matchesScreenMd={matchesScreenMd}
								onRuleMatchCaseChange={onRuleMatchCaseChange}
								onConditionLabelChange={onConditionLabelChange}
								onRulePredicateChange={onRulePredicateChange}
								onRuleValueChange={onRuleValueChange}
								onDeleteCondition={onDeleteCondition}
								onHeaderNameChanged={onHeaderNameChanged}
								headerInputPlaceholder={headerInputPlaceholder}
							/>
						)
				)}

			<Button class={style.advancedFilterSetting} styleType="text" onClick={onAddNewCondition}>
				<Text id="settings.filterRuleModal.addMoreCondition" />
			</Button>
		</div>
	);
}
