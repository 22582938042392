import style from './style';

const Label = ({ children, href, onClick }) => {
	let Component = 'span';

	if (href) {
		Component = 'a';
	} else if (onClick) {
		Component = 'button';
	}

	return (
		<Component class={style.label} href={href} onClick={onClick}>
			{children}
		</Component>
	);
};

export default Label;
