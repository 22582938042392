import { useDispatch } from 'react-redux';
import { useCallback } from 'preact/hooks';
import { getDataFromReduxStoreThunk } from '../../../utils/thunk';
import { getFolderMapping, getBriefcaseFolders, removeVersionFromFileName } from '../util';
import { getFoldersAndSharedFolders } from '../../../utils/folders';
import saveAs from '../../../lib/save-as';
import { USER_FOLDER_IDS } from '../../../constants';

const generateDownloadURL = ({
	userEmail,
	selectedDocList,
	zimbraBatchClient,
	folders,
	sharedFolders
}) => {
	const selectedDoc = selectedDocList.reduce((acc, val) => {
		acc[val.folderId] = (acc[val.folderId] || []).concat(`${val.id}.${val.version}`);
		return acc;
	}, {});

	const { updatedSelectedDoc, folderObject } = getFolderMapping(
		selectedDoc,
		Object.keys(selectedDoc),
		folders,
		sharedFolders
	);
	const folderIds = Object.keys(folderObject);
	const length = selectedDocList.length;
	const downloadUrls = [];
	let fileName = null;
	let contentType = null;

	folderIds.forEach(val => {
		let uri = `/home/${userEmail}/${folderObject[val].absFolderPath.replace('/', '')}`;

		if (length === 1) {
			const singleSelected = selectedDocList[0];
			fileName = singleSelected.name;
			const fileId =
				val === USER_FOLDER_IDS.FILES_SHARED_WITH_ME.toString() ? `id=${singleSelected.id}&` : '';
			const version =
				val === USER_FOLDER_IDS.FILES_SHARED_WITH_ME.toString()
					? ''
					: `&ver=${singleSelected.version}`;
			contentType = singleSelected.contentType;
			uri = `${uri}/${encodeURIComponent(
				removeVersionFromFileName(singleSelected.name)
			)}?${fileId}disp=a${version}`;
		} else if (length > 1) {
			const multipleSelected = updatedSelectedDoc[val];
			const list = multipleSelected.join(',');

			uri = `${uri}?fmt=zip&list=${list}`;
		}
		downloadUrls.push(zimbraBatchClient.resolve(uri));
	});
	return { downloadUrls, ...(length === 1 && { fileName, contentType }) };
};

export const useDownloadHandler = ({ zimbraBatchClient, client, selectedList }) => {
	const dispatch = useDispatch();

	const handleDownload = useCallback(
		({ item }) => {
			getBriefcaseFolders(client).then(({ data: foldersData }) => {
				const { folders, sharedFolders } = getFoldersAndSharedFolders(foldersData);
				const updatedSelectedList = (selectedList || [item]).map(selectedItem => ({
					...selectedItem,
					id: selectedItem.id.split('_')[0]
				}));

				const userEmail = dispatch(getDataFromReduxStoreThunk('email.account.name'));
				const { downloadUrls, fileName, contentType } = generateDownloadURL({
					userEmail,
					selectedDocList: item ? [item] : updatedSelectedList,
					zimbraBatchClient,
					folders,
					sharedFolders
				});
				downloadUrls.forEach(downloadUrl =>
					saveAs({ url: downloadUrl, filename: fileName, contentType })
				);
			});
		},
		[client, dispatch, selectedList, zimbraBatchClient]
	);

	return {
		handleDownload
	};
};
