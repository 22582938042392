export const SUPPORTED_LOCALES = [
	'ar', // Arabic
	'bg', // Bulgarian
	'ca', // Catalan
	'cs', // Czech
	'da', // Danish
	'de', // German
	'en_US', // English
	'eu', // Basque,
	'es', // Spanish
	'fr_FR', // French
	'fr_CA', // Canadian French
	'hi', // Hindi
	'hr', // Croatian
	'hu', // Hungarian
	'in', // Indonesian - Bahasa Indonesia
	'it', // Italian
	'ja', // Japanese
	'ko', // Korean
	'lo', // Lao
	'ms', // Malay
	'nl', // Dutch, Netherlands
	'no', // Norwegian
	'pl', // Polish
	'pt', // Portuguese
	'pt_BR', // Brazilian Portugeze
	'ro', // Romanian
	'ru', // Russian
	'sl', // Slovenian
	'sv', // Swedish
	'sk', // Slovak
	'ta', // Tamil
	'th', // Thai
	'tr', // Turkish
	'uk', // Ukrainian
	'vi', // Vietnamese
	'zh_CN', // Chinese
	'zh_HK', // Traditional Chinese (Hongkong)
	'zh_TW' // Traditional Chinese (Taiwan)
];

export const TIME_FORMATS = {
	FORMAT_12: '12',
	FORMAT_0_12: '0_12',
	FORMAT_24: '24',
	FORMAT_0_24: '0_24'
};

export const DATE_FIELD_NAME = 'dateFormat';
export const TIME_FIELD_NAME = 'timeFormat';

export const DEFAULT_LOCALE = 'en_US';
