import { applyMiddleware, createStore, compose } from 'redux';
import { zimletReduxStoreEnhancer } from './enhancers';
import thunk from 'redux-thunk';
import { createPromise } from 'redux-promise-middleware';
import createReducer from './reducers';
import { persistReducer } from 'redux-persist';
import { persistConfig } from './persist-config';

let composeEnhancers = compose;

if (process.env.NODE_ENV !== 'production') {
	if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
	}
}

export default function configureStore(initialState = {}, zimbra, additionalReducers) {
	const persistedReducer = persistReducer(persistConfig, createReducer(additionalReducers));

	const { store, persistor } = zimletReduxStoreEnhancer(createStore)(
		persistedReducer,
		initialState,
		composeEnhancers(applyMiddleware(createPromise(), thunk.withExtraArgument(zimbra)))
	);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./reducers', () => {
			const nextRootReducer = require('./reducers').default();
			store.replaceReducer(persistReducer(persistConfig, createReducer(nextRootReducer)));

			try {
				persistor.persist();
			} catch (error) {
				console.warn(
					'Could not store persistent Redux cache. Are you in private browsing mode?',
					error
				);
			}
		});
	}

	return { store, persistor };
}
