import { Component } from 'preact';
import { Text } from 'preact-i18n';
import cx from 'classnames';
import linkState from 'linkstate';
import { AnimatedButton, Icon } from '@zimbra/blocks';
import { rendererIpc, constants } from '@zimbra/electron-app';
import TextInput from '../text-input';
import ClientLogo from '../client-logo';
import style from './style';
import { setServerPrefsStore, formSecureURL, getLocation } from '../../utils/desktop-prefs';

const { ipcNames } = constants;
const PLACEHOLDER_URL = 'server.domain.com';
export default class ServerUrlDialog extends Component {
	state = {
		inputValue: '',
		url: ''
	};

	handleClearInput = () => this.setState({ inputValue: '' });

	handleError = (errorMessage = false) => {
		this.setState({
			errorMessage,
			loading: false,
			url: ''
		});
	};

	updateURL = () => {
		const { url } = this.state;
		url && setServerPrefsStore && setServerPrefsStore({ zimbraOrigin: url });

		rendererIpc.unbindListeners();

		//Note: don't know what is best way to reload config so choose to reload page
		window.location.reload();
	};

	handleResponse = response => {
		if (
			response?.Body?.ClientInfoResponse ||
			response?.Body?.Fault?.Detail?.Error?.Code === 'service.UNKNOWN_DOCUMENT'
		) {
			this.handleError();
			this.updateURL();
		} else if (
			String(response?.message).includes('ERR_CERT_INVALID') ||
			String(response?.message).includes('SSLHandshakeException')
		) {
			this.handleError('serverConfigDialog.error.invalidCertificate');
		} else if (response?.code === 'ERR_INTERNET_DISCONNECTED') {
			this.handleError('app.connectionError');
		} else {
			this.handleError('serverConfigDialog.error.invalidServer');
		}
	};

	validateUrl = url => {
		const hostName = getLocation(url).hostname;
		const requestParams = {
			url: `${url}/service/soap`,
			options: {
				method: 'POST',
				body: JSON.stringify({
					Body: {
						ClientInfoRequest: {
							_jsns: 'urn:zimbraAccount',
							domain: [{ by: 'virtualHostname', _content: hostName }]
						}
					},
					Header: { context: { _jsns: 'urn:zimbra', userAgent: { name: 'Zimbra Desktop' } } }
				}),
				headers: { 'Content-Type': 'application/json' }
			}
		};
		rendererIpc.requestClientInfo(requestParams);
	};

	submit = () => {
		const { inputValue } = this.state;
		const url = formSecureURL(inputValue.toLocaleLowerCase());
		this.setState({
			errorMessage: false,
			loading: true,
			url
		});
		url ? this.validateUrl(url) : this.handleError('serverConfigDialog.error.invalidURL');
	};

	componentDidMount() {
		rendererIpc.listenToIpcEvent(ipcNames.getClientInfoResponse, this.handleResponse);
	}

	componentWillUnmount() {
		rendererIpc.removeIpcListener(ipcNames.getClientInfoResponse, this.handleResponse);
	}

	render({}, { inputValue, errorMessage, loading }) {
		const serverUrl = `server-url`;

		return (
			<div class={style.container}>
				<div class={style.login}>
					<ClientLogo class={style.logo} />
					<h1>
						<Text id="serverConfigDialog.title" />
					</h1>
					<form onSubmit={this.submit} novalidate action="javascript:" method="POST">
						<div class={style.form}>
							{errorMessage && (
								<div class={cx(style.error, style.showing)}>
									<Text id={errorMessage} />
								</div>
							)}
							<label htmlFor={serverUrl} id={`${serverUrl}-label`}>
								<Text id="serverConfigDialog.serverUrl" />
							</label>
							<div class={style.inputContainer}>
								<TextInput
									aria-labelledby={`${serverUrl}-label`}
									autofocus
									autocorrect="off"
									autocapitalize="off"
									spellcheck="false"
									class={style.urlInput}
									type="email"
									id={serverUrl}
									value={inputValue}
									placeholder={PLACEHOLDER_URL}
									onInput={linkState(this, 'inputValue')}
								/>
								<Icon
									role="button"
									name="close"
									class={style.clear}
									onClick={this.handleClearInput}
								/>
							</div>
						</div>
						<div class={style.buttons}>
							<AnimatedButton
								styleType="primary"
								brand="primary"
								type="submit"
								loading={loading}
								disabled={loading}
								title={<Text id="serverConfigDialog.continue" />}
							/>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
