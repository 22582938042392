import { Component } from 'preact';
import cx from 'classnames';
import cloneDeep from 'lodash-es/cloneDeep';
import style from './style';
import TokenInputSuggestion from './token-input-suggestion';
import linkState from 'linkstate';
import { callWith } from '../../lib/util';
import Selection from 'react-ds';
import intersectionWith from 'lodash-es/intersectionWith';

const memoize =
	(fn, mem = {}) =>
	key =>
		key in mem ? mem[key] : (mem[key] = fn(key));

export default class TokenInput extends Component {
	state = {
		tokens: [],
		selected: [],
		activateSelected: false,
		isMouseDown: false,
		tokenContainerRef: null,
		tokensRef: []
	};

	onAdd = ({ tokens }) => {
		const { tokens: tokenList } = this.state;
		const { onChange, addressFieldType } = this.props;
		let value = tokenList.concat(tokens);

		addressFieldType && (value = { [addressFieldType]: value });
		onChange && onChange({ value, valueUpdated: true });
	};

	onStateChange = ({ selected, activateSelected }) => {
		this.setState({ selected, activateSelected, isMouseDown: false });
	};

	onRemove = selectedTokens => {
		const { onChange, addressFieldType } = this.props;
		const tokens = this.state.tokens.slice();
		const filteredTokens = tokens.filter(t => !selectedTokens.includes(t.address));
		let value = filteredTokens;

		addressFieldType && (value = { [addressFieldType]: value });
		this.setState({ tokens: filteredTokens }, () => {
			onChange && onChange({ value, valueUpdated: true });
		});
	};

	handleTokenClick = (token, e) => {
		const { ctrlKey, metaKey } = e;
		const { address } = token;
		let selectedTokens = cloneDeep(this.state.selected);
		if (ctrlKey || metaKey) {
			const clickedTokenIndex = selectedTokens.indexOf(address);
			clickedTokenIndex !== -1
				? selectedTokens.splice(clickedTokenIndex, 1) // deselect token from selected.
				: selectedTokens.push(address); // add new token to selected.
		} else {
			selectedTokens = [address];
		}
		this.onStateChange({ selected: selectedTokens, activateSelected: true });
	};

	handleDragStart = ({ draggingToken }, e) => {
		const { selected } = this.state; // need to fetch latest value
		const isSelected = selected && selected.includes(draggingToken.address);
		!isSelected && this.handleTokenClick(draggingToken, e);
		this.props.handleDragStart(selected, draggingToken, isSelected, e);
	};

	handleDragEnd = () => {
		this.setState({
			selected: [],
			activateSelected: false,
			isMouseDown: false
		});
	};

	suggestionRef = ref => (this.suggestion = ref);

	refocus = () => this.suggestion && this.suggestion.refocus();

	getValue = value => (this.props.renderValue ? this.props.renderValue(value) : value.value);

	selectIndex = memoize(index => selected => {
		this.setState({
			selected: selected !== false && index,
			activateSelected: false
		});
		return false;
	});

	activateIndex = memoize(index => activated => {
		if (activated === false) this.setState({ activateSelected: false });
		else this.setState({ selected: index, activateSelected: true });
	});

	setMouseDownState = () => {
		!this.state.isMouseDown && this.setState({ isMouseDown: true });
	};

	getRenderTokenProps = token => {
		const value = this.getValue(token);
		const address = token.address;
		const { selected, activateSelected } = this.state;
		const {
			onDataChange,
			showCertBadge,
			validateToken,
			draggable,
			onDragEnter,
			onChange,
			onCtrlEnterKeyDown,
			updateExistingContact,
			addressFieldType
		} = this.props;

		const isSelected = selected && selected.includes(address);
		//If array then check if address is in selected array.
		return {
			key: address,
			token,
			value,
			activated: isSelected && activateSelected === true,
			activate: this.activateIndex(address),
			isSelected,
			selected,
			invalid: validateToken ? !validateToken(value, token) : false,
			onKeyDown: this.handleKey,
			onDataChange,
			showCertBadge,
			draggable,
			onChange,
			updateExistingContact,
			onCtrlEnterKeyDown,
			onClick: callWith(this.handleTokenClick, token, true),
			onDragStart: callWith(this.handleDragStart, { draggingToken: token }, true),
			onDragEnter: callWith(onDragEnter, address),
			onDragEnd: this.handleDragEnd,
			onMouseDown: this.setMouseDownState,
			addTokensRef: this.addTokensRef,
			addressFieldType
		};
	};

	update = props => {
		let tokens = props.value;
		if (typeof tokens === 'string') {
			tokens = tokens
				.split(/\s*,\s*/)
				.map(this.props.createValue || (value => ({ value })))
				.filter(Boolean);
		}
		this.setState({ tokens });
	};

	addTokensRef = ref => {
		// Method gets called on unmount phase as well,
		// so we don't want to do setState at that time
		if (!this.base) {
			return;
		}

		const { tokensRef } = this.state;

		!tokensRef.includes(ref) && tokensRef.push(ref);
		this.setState({
			tokensRef
		});
	};

	addTokenContainerRef = ref => {
		// Method gets called on unmount phase as well,
		// so we don't want to do setState at that time
		if (!this.base) {
			return;
		}

		this.setState({
			tokenContainerRef: ref
		});
	};

	handleSelection = selectedItems => {
		const { tokens } = this.state;

		const selectedTokens = intersectionWith(
			tokens,
			selectedItems,
			({ name, displayName }, { innerText }) => innerText === (name || displayName)
		).map(({ address }) => address);
		this.setState({
			selected: selectedTokens
		});
	};

	componentWillMount() {
		this.update(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.value !== this.props.value) {
			this.update(nextProps);
		}
		// Reset selected array after dropped pills into address field
		if (nextProps.value.length !== this.props.value.length) {
			this.setState({ selected: [], activateSelected: false });
		}
	}

	render(
		{
			renderAutoSuggest,
			renderToken,
			createValue,
			class: c,
			children,
			placeholder,
			wasPreviouslySelected,
			previouslySelectedLabel,
			tokenInputStyle,
			inputClassName,
			isLocation,
			isGalOnly,
			type,
			onDragEnter,
			onCtrlEnterKeyDown,
			onFocus,
			disabled
		},
		{ tokens, selected, activateSelected, isMouseDown, tokenContainerRef, tokensRef }
	) {
		renderToken = renderToken || (typeof children === 'function' && children) || renderDefaultToken;
		return (
			<div
				class={cx(style.tokenInput, c)}
				onClick={this.refocus}
				onMouseDown={this.refocus}
				ref={this.addTokenContainerRef}
			>
				<div class={style.tokens}>
					{Boolean(tokens.length && tokenContainerRef && tokensRef.length && !isMouseDown) && (
						<Selection
							target={tokenContainerRef}
							elements={tokensRef}
							onHighlightChange={this.handleSelection}
							returnRefs
						/>
					)}
					{tokens
						.filter(t => typeof t !== 'string')
						.map(this.getRenderTokenProps)
						.map(renderToken)}
					<TokenInputSuggestion
						ref={this.suggestionRef}
						renderAutoSuggest={renderAutoSuggest}
						createValue={createValue}
						placeholder={placeholder}
						wasPreviouslySelected={wasPreviouslySelected}
						previouslySelectedLabel={previouslySelectedLabel}
						tokenInputStyle={tokenInputStyle}
						inputClassName={inputClassName}
						isLocation={isLocation}
						isGalOnly={isGalOnly}
						type={type}
						selected={selected}
						tokens={tokens}
						activateSelected={activateSelected}
						onAdd={this.onAdd}
						onActivate={linkState(this, 'activateSelected')}
						onSelect={linkState(this, 'selected')}
						onRemove={this.onRemove}
						onStateChange={this.onStateChange}
						isMouseDown={isMouseDown}
						onDragEnter={callWith(onDragEnter)}
						onCtrlEnterKeyDown={onCtrlEnterKeyDown}
						disabled={disabled}
						onFocus={onFocus}
					/>
				</div>
			</div>
		);
	}
}

const renderDefaultToken = ({ value, selected, select, key }) => (
	<button style={selected ? 'background:rgba(0,0,0,0.2);' : ''} onClick={select} key={key}>
		{value}
	</button>
);
