import get from 'lodash-es/get';
import { useFoldersQuery } from '../folders';
import { useSearchQuery } from '../search';
import { MAIL_VIEW } from '../../constants/views';
import { USER_FOLDER_IDS } from '../../constants';
import { useItemActionMutation } from '../action/index';
import { dumpsterSearchVariable } from './utils';
import SearchQuery from '../../graphql/queries/search/search.graphql';

export const useDumpsterFoldersQuery = () => {
	const { data, refetch, loading } = useFoldersQuery({
		variables: {
			view: MAIL_VIEW
		},
		options: {
			fetchPolicy: 'cache-only'
		}
	});

	return {
		folders: get(data, 'getFolder.folders.0.folders', []).filter(
			f => (!f.view || f.view === MAIL_VIEW) && parseInt(f.id, 10) !== USER_FOLDER_IDS.CHAT // We don't want to show "Chats" folder
		),
		refetchFolders: refetch,
		loading
	};
};

export const useDumpsterSearchQuery = (sortBy, searchQuery = '') => {
	const { search, refetch, more, loading } = useSearchQuery({
		variables: dumpsterSearchVariable(sortBy, searchQuery)
	});

	return {
		items: get(search, 'messages') || [],
		refetchItems: refetch,
		loading,
		more
	};
};

export const useDumpsterActionMutation = () => {
	const [dumpsterItemAction] = useItemActionMutation();

	return ({ variables, sortBy, searchQuery = '', ids }) => {
		return dumpsterItemAction({
			variables,
			update: cache => {
				const data = cache.readQuery({
					query: SearchQuery,
					variables: dumpsterSearchVariable(sortBy, searchQuery)
				});

				const items = get(data, 'search.messages');
				let updatedItems;
				if (items && items.length) {
					updatedItems = items.filter(({ id: itemId }) => ids.indexOf(itemId) === -1 && itemId);
				}

				cache.writeQuery({
					query: SearchQuery,
					variables: dumpsterSearchVariable(sortBy, searchQuery),
					data: { search: { ...data.search, messages: updatedItems } }
				});
			}
		});
	};
};
