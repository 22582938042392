import { Component, Fragment } from 'preact';
import cx from 'classnames';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { getTranslatedFolderName } from '../folder-list/util';
import ActionMenuItem from '../action-menu-item';
import s from './style.less';

export default class Folder extends Component {
	handleClick = e => {
		const { onClick, folder } = this.props;
		return onClick(e, folder);
	};

	componentDidUpdate(prevProps) {
		if (this.props.selected && !prevProps.selected) {
			scrollIntoViewIfNeeded(this.base);
		}
	}

	render(props) {
		return (
			<Fragment>
				<ActionMenuItem
					{...props}
					aria-selected={props.selected}
					class={cx(
						props.keyboardSelection && s.disableHover,
						props.selected && s.selected,
						props.class
					)}
					style={{ paddingLeft: `${props.depth * 14 + 18}px` }}
					onClick={this.handleClick}
					disabled={props.folder.disabled}
				>
					{getTranslatedFolderName(props.folder)}
				</ActionMenuItem>
				{props.folder.folders &&
					props.folder.folders.map(f => (
						<Folder
							{...props}
							folder={f}
							depth={props.depth + 1}
							selected={props.keyboardSelection === f.id || props.selectedFolderId === f.id}
						/>
					))}
			</Fragment>
		);
	}
}
