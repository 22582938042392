import { BRIEFCASE, TRASH } from '../../constants/folders';
import SearchQuery from '../../graphql/queries/search/search.graphql';
import { BRIEFCASE_VIEW } from '../../constants/views';
import foldersData from '../../graphql/fragments/folder-data.graphql';

export const saveDocumentRefetchQueries = (folderName, sortValue) => {
	return (
		folderName &&
		folderName !== TRASH && [
			{
				query: SearchQuery,
				variables: briefcaseSearchVariable({ query: `in:"${folderName || BRIEFCASE}"`, sortValue })
			}
		]
	);
};

export const saveDocumentVariables = ({
	id,
	documentId,
	folderId,
	version,
	messageId,
	attachmentPart,
	filename
}) => {
	return {
		document: {
			...(documentId && { id: documentId }),
			...(folderId && { folderId }),
			...(filename && { name: filename }),
			...(id && {
				upload: {
					id
				}
			}),
			...(attachmentPart &&
				messageId && {
					messageData: {
						messageId,
						attachmentPart
					}
				}),
			...(version && { version })
		}
	};
};

export const briefcaseSearchVariable = ({ query, sortValue }) => ({
	offset: 0,
	query,
	types: 'document',
	limit: 100,
	needExp: true,
	sortBy: sortValue
});

export const briefcaseFolderVariable = () => ({
	view: BRIEFCASE_VIEW
});

export const getDocument = (client, folderName, filename) => {
	return client.query({
		query: SearchQuery,
		variables: {
			offset: 0,
			query: `in:"${folderName}" filename:"${filename}"`,
			types: 'document',
			limit: 1,
			needExp: true
		},
		// Hear the fetchPolicy is network only because every time
		// when we upload a document ,we dont update the cache we refetch the
		// list  ,so in this case every time the user adds
		// a document to list with same-name we want the updated version for the document
		fetchPolicy: 'network-only'
	});
};

export const readAndWriteFolderData = ({ cache, folder, targetFolders, folderIds }) => {
	const updatedFolder = cache.readFragment({
		id: `Folder:${folder.id}`,
		fragment: foldersData,
		fragmentName: 'foldersData'
	});
	let { folders } = updatedFolder;
	folders = targetFolders
		? folders
			? folders.concat(targetFolders)
			: targetFolders
		: folderIds && folders.filter(f => folderIds.indexOf(f.id) === -1);
	cache.writeFragment({
		id: `Folder:${folder.id}`,
		fragment: foldersData,
		fragmentName: 'foldersData',
		data: {
			...updatedFolder,
			folders: (folders.length > 0 && folders.sort((a, b) => (a.name > b.name ? 1 : -1))) || null
		}
	});
};
