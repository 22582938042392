module.exports = {
	/**
	 * Holds the names of the handlers for IPC communication, these references should be used
	 * for both registration and invocation to avoid any typos
	 */
	ipcNames: {
		initialize: 'initialize',
		setUnreadMailCount: 'setUnreadMailCount',
		getUserFolderPath: 'getUserFolderPath',
		getLogFolderPath: 'getLogFolderPath',
		openExternalBrowser: 'openExternalBrowser',
		setFocusToApp: 'setFocusToApp',
		requestClientInfo: 'requestClientInfo',
		getClientInfoResponse: 'getClientInfoResponse',
		setPstProgressStatus: 'setPstProgressStatus',
		startPSTExtractor: 'startPSTExtractor',
		abortPSTExtractor: 'abortPSTExtractor',
		getImportCountFromLog: 'getImportCountFromLog',
		getDefaultMailToClient: 'getDefaultMailToClient',
		setDefaultMailToClient: 'setDefaultMailToClient',
		updateNetworkStatus: 'updateNetworkStatus',
		setSyncStatus: 'setSyncStatus',
		handleDiskSpaceWarning: 'handleDiskSpaceWarning',
		updateSyncProcess: 'updateSyncProcess',
		resumeSyncProcess: 'resumeSyncProcess',
		terminateSyncProcess: 'terminateSyncProcess',
		setAutoArchiveStatus: 'setAutoArchiveStatus',
		updateAutoArchiveLocalPrefs: 'updateAutoArchiveLocalPrefs',
		terminateArchiveProcess: 'terminateArchiveProcess',
		downloadMessage: 'downloadMessage',
		getFolder: 'getFolder',
		getMessage: 'getMessage',
		getMessagesMetadata: 'getMessagesMetadata',
		getAccountInfo: 'getAccountInfo',
		getMailboxMetadata: 'getMailboxMetadata',
		getPreferences: 'getPreferences',
		refreshFolders: 'refreshFolders',
		search: 'search',
		action: 'action',
		addMessage: 'addMessage',
		attach: 'attach',
		createFolder: 'createFolder',
		saveDraft: 'saveDraft',
		sendMessage: 'sendMessage',
		reset: 'reset'
	}
};
