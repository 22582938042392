import { Button, Icon } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import ZimletSlot from '../zimlet-slot';
import ModalDialog from '../modal-dialog';
import s from './style.less';

export default function saveToModalDialog(attachments, onCloseModal, onDownload) {
	const hasSingleAttachment = attachments && attachments.length === 1;

	return (
		<ModalDialog
			title="dialogs.attachmentSave.title"
			onClose={onCloseModal}
			buttons={false}
			cancelButton={false}
			innerClass={s.modalInner}
		>
			<div class={s.buttonContainer}>
				<Button
					class={s.button}
					icon={<Icon name="download" style={s.icon} />}
					iconPosition="left"
					onClick={onDownload}
				>
					<Text id="buttons.download" />
				</Button>
			</div>

			{hasSingleAttachment ? (
				<ZimletSlot
					name="attachment-single-action"
					attachment={attachments[0]}
					buttonClass={s.button}
					iconClass={s.icon}
					divClass={s.buttonContainer}
					closeModal={onCloseModal}
				/>
			) : (
				<ZimletSlot
					name="attachment-multi-action"
					attachments={attachments}
					buttonClass={s.button}
					iconClass={s.icon}
					divClass={s.buttonContainer}
					closeModal={onCloseModal}
				/>
			)}
		</ModalDialog>
	);
}
