import { Text } from 'preact-i18n';
import cx from 'classnames';
import style from '../style';
import { I18nText, generateFieldInfo } from './helper';
import { FIELD_MAPS } from '.';
import DropdownLabel from './dropdown-label';
import AddressGroup from './address-group';
import AddRemoveButtons from './add-remove-buttons';
import DateInput from '../../date-input';

export function ContactEditSection({
	errorFields,
	children,
	contact,
	titleId,
	fields,
	pfx,
	readonly,
	createContactFieldUpdater,
	onAddField,
	onFieldLabelChange,
	onRemoveField,
	showRemoveButtonForGroup,
	otherAttributePlaceHolder,
	template
}) {
	return (
		<fieldset>
			{titleId && (
				<legend>
					<Text id={titleId} />
				</legend>
			)}
			{fields.map(attr => {
				const field = generateFieldInfo(attr, template);
				const FieldTagName = FIELD_MAPS[field.nonSuffixedAttribute] || 'input';
				return attr === 'other' ? (
					contact.attributes.other.map(({ key, value, errorExist }, index) => (
						<div class={style.contactFieldItem}>
							<div class={style.inputWrap}>
								<input
									id={`otherKey${index}`}
									class={cx(style.otherField, errorFields && errorExist && style.errorField)}
									value={key}
									onInput={createContactFieldUpdater(`attributes.${attr}.key.${index}`)}
									autocomplete={'off'}
									placeholder={otherAttributePlaceHolder}
								/>
							</div>
							<div class={style.inputWrap}>
								<input
									id={`otherValue${index}`}
									value={value}
									onInput={createContactFieldUpdater(`attributes.${attr}.value.${index}`)}
									autocomplete={'off'}
								/>
							</div>
							<AddRemoveButtons
								showRemoveButton
								attribute={`attributes.${attr}.value.${index}`}
								group="other"
								onAddField={onAddField}
								onRemoveField={onRemoveField}
							/>
						</div>
					))
				) : (
					<div class={style.contactFieldItem}>
						{field.hasDropdownLabels && !readonly ? (
							<div class={style.dropdownLabel}>
								<DropdownLabel {...field} onFieldLabelChange={onFieldLabelChange} />
							</div>
						) : (
							<label for={pfx + attr}>
								<I18nText attribute={attr} />
							</label>
						)}
						<div class={cx(style.inputWrap, field.isAddressField ? style.inputDropdown : '')}>
							{field.isAddressField ? (
								<AddressGroup
									readonly={readonly}
									pfx={pfx}
									attribute={attr}
									contact={contact}
									createContactFieldUpdater={createContactFieldUpdater}
								/>
							) : readonly ? (
								<span class={style.readonlyValue}>{contact.attributes[attr]}</span>
							) : FieldTagName === 'date' ? (
								<DateInput
									id={pfx + attr}
									name={attr}
									class={cx(
										style.dateInput,
										errorFields && errorFields.includes(attr) ? style.errorField : ''
									)}
									dateValue={contact.attributes[attr]}
									onDateChange={createContactFieldUpdater(`attributes.${attr}`, true)}
								/>
							) : (
								<FieldTagName
									id={`${pfx}${attr}`}
									name={attr}
									class={cx(errorFields && errorFields.includes(attr) && style.errorField)}
									value={contact.attributes[attr] || ''}
									onInput={createContactFieldUpdater(`attributes.${attr}`)}
									autocomplete={'off'}
								/>
							)}
						</div>
						{!readonly && field.showAddRemoveButtons && (
							<AddRemoveButtons
								showRemoveButton={showRemoveButtonForGroup(
									field.group || field.nonSuffixedAttribute
								)}
								attribute={attr}
								defaultAttributeForNewField={
									field.dropdownLabels ? field.dropdownLabels[0] : field.nonSuffixedAttribute
								}
								group={field.group}
								onAddField={onAddField}
								onRemoveField={onRemoveField}
							/>
						)}
					</div>
				);
			})}
			{children}
		</fieldset>
	);
}
