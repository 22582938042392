import getEmailHeaders from '../../../vhtml-components/email-headers';
import { OLK_SRC_BODY_SECTION_ID } from '../../../constants/mail';

export default function incomingMessage({ body, ...details }) {
	return `
		<div id="${OLK_SRC_BODY_SECTION_ID}">
			<blockquote style="margin:0 0 0 .8em; border-left:1px #ccc solid; padding-left:1em">
				${getEmailHeaders(details)}
				${body}
			</blockquote>
		</div>
	`
		.trim()
		.replace(/(^|\n)\t+/g, '');
}
