import { PureComponent } from 'preact/compat';
import { Text } from 'preact-i18n';
import style from '../../style';
import cx from 'classnames';
import { withProps } from 'recompose';
import { Button, Icon } from '@zimbra/blocks';
import TextInput from '../../../text-input';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import assign from 'lodash-es/assign';
import SelectOption from '../../../select';
import ErrorAlert from '../../../error-alert';
import Suggestions from './../../../address-field/suggestions';
import { callWith, isValidEmail, getEmail, getEmailDomain } from '../../../../lib/util';
import { faultCode } from '../../../../utils/errors';
import { withRevokeRights, withGrantRights } from '../../../../graphql-decorators/rights';
import { VIEW_FREE_BUSY, FREE_BUSY_RIGHTS } from '../../../../constants/rights';

// Remove non-freeBusy and already selected rights
function excludeRightFromList(toBeExcluded, list) {
	return list.filter(
		access =>
			access &&
			!find(toBeExcluded, {
				right: VIEW_FREE_BUSY,
				granteeType: access.granteeType,
				...(access.address && { address: access.address }),
				// `deny` may be `null` so set default value as false
				...(access.granteeType === FREE_BUSY_RIGHTS.all && { deny: access.deny || false })
			})
	);
}

function getRights(permission, accessList) {
	if (permission === FREE_BUSY_RIGHTS.user) {
		// If user removes all users from list, set option to `No one`
		if (!accessList.length) {
			return [
				{
					right: VIEW_FREE_BUSY,
					granteeType: FREE_BUSY_RIGHTS.all,
					deny: true
				}
			];
		}
		return accessList;
	}

	// granteeType is same for 'None' and 'All'. Difference is value of 'Deny'
	// So, here we are setting values for 'deny' accordingly.
	return [
		{
			right: VIEW_FREE_BUSY,
			granteeType: permission === FREE_BUSY_RIGHTS.none ? FREE_BUSY_RIGHTS.all : permission,
			deny: permission === FREE_BUSY_RIGHTS.none
		}
	];
}

/**
 * get updated rights list with appropriate values of keys.
 * For domain rights, get domain name from accountName,
 * For multi user rights, keep same address.
 * */
function getGrantRightsQueryInput(rights, accountName) {
	return rights.map(({ address, originalEmail, ...restRight }) => {
		const rightSpecificAddress =
			restRight.granteeType === FREE_BUSY_RIGHTS.domain ? getEmailDomain(accountName) : address;

		return {
			...(rightSpecificAddress && { address: rightSpecificAddress }),
			...restRight
		};
	});
}

@withGrantRights()
@withRevokeRights()
@withProps(({ grantedRights }) => {
	// Fetch Free Busy view specific permissions from granted rights
	const grantedFreeBusyRights =
		grantedRights && grantedRights.filter(access => access && access.right === VIEW_FREE_BUSY);

	if (grantedFreeBusyRights) {
		// Set selected free busy option once value is available
		// If deny is true, set selected option as `none`
		const selectedPermission = get(grantedFreeBusyRights, '0.deny')
			? FREE_BUSY_RIGHTS.none
			: get(grantedFreeBusyRights, '0.granteeType');

		return {
			selectedPermission,
			viewPermittedUserList:
				selectedPermission === FREE_BUSY_RIGHTS.user
					? grantedFreeBusyRights.filter(access => access.granteeType === FREE_BUSY_RIGHTS.user)
					: []
		};
	}

	return {
		viewPermittedUserList: []
	};
})
export default class FreeBusyViewPermission extends PureComponent {
	state = {
		viewPermittedUserList: this.props.viewPermittedUserList,
		freeBusyEmailInput: '',
		disableSelection: false,
		invalidEmail: false,
		notAnInternalUser: false,
		shouldRenderSuggestions: false,
		selectedPermission: this.props.selectedPermission
	};

	isAlreadySelectedEmail = ({ email }) =>
		!!this.state.viewPermittedUserList.find(({ address }) => address === getEmail(email));

	/**
	 * After selecting suggestion, placing email address to input box instead of
	 * adding it to list. It should not display further suggestions.
	 * To prevent that, set `shouldRenderSuggestions` to `true`,
	 * if user changes selected value in inputbox and it's not false.
	 * Also, If required, reset error flags on input change.
	 */
	handleEmailInputChange = e => {
		this.setState(({ shouldRenderSuggestions, invalidEmail, notAnInternalUser }) => ({
			freeBusyEmailInput: e.target.value,
			...(!shouldRenderSuggestions && { shouldRenderSuggestions: true }),
			...(invalidEmail && { invalidEmail: false }),
			...(notAnInternalUser && { notAnInternalUser: false })
		}));
	};

	/**
	 * Get email address from `name <email@address>` and
	 * prevent suggestion rendering until input changes
	 */
	onSuggestionSelection = ({ email }) =>
		this.setState({
			freeBusyEmailInput: getEmail(email),
			originalEmail: email,
			shouldRenderSuggestions: false
		});

	onCloseSuggestions = () => this.setState({ shouldRenderSuggestions: false });

	/**
	 * Trigger mutations
	 */
	grantRevokeRights = (accountName, newGrantRights) => {
		const { onGrantRights, onRevokeRights } = this.props;
		const stateToUpdate = {};

		return onGrantRights(accountName, newGrantRights)
			.then(data => {
				// Get granted data and add it to state if it's successful
				const grantedRights = get(data, 'data.grantRights.access.0');
				if (grantedRights && grantedRights.granteeType === FREE_BUSY_RIGHTS.user) {
					assign(stateToUpdate, {
						viewPermittedUserList: [...this.state.viewPermittedUserList, grantedRights]
					});
				} else if (this.state.viewPermittedUserList.length === 1) {
					assign(stateToUpdate, {
						viewPermittedUserList: []
					});
				}

				if (!this.props.grantedRights) {
					assign(stateToUpdate, {
						disableSelection: false
					});
					this.setState(stateToUpdate);
					return Promise.resolve();
				}

				const revokeRights = excludeRightFromList(newGrantRights, this.props.grantedRights);

				// Call `onRevokeRights` only if viewFreeBusy rights exist
				if (revokeRights.length) {
					return onRevokeRights(accountName, revokeRights).then(() => {
						assign(stateToUpdate, {
							disableSelection: false
						});
						// Reset User list if user switch from `usr` to other permission OR
						// Has removed any email address from list
						if (this.props.viewPermittedUserList.length) {
							assign(stateToUpdate, {
								viewPermittedUserList:
									this.props.selectedPermission !== FREE_BUSY_RIGHTS.user ? [] : newGrantRights
							});
						}
						this.setState(stateToUpdate);
					});
				}

				if (this.props.viewPermittedUserList.length) {
					assign(stateToUpdate, {
						viewPermittedUserList:
							this.props.selectedPermission !== FREE_BUSY_RIGHTS.user ? [] : newGrantRights
					});
				}

				assign(stateToUpdate, {
					disableSelection: false
				});
				this.setState(stateToUpdate);
			})
			.catch(error => {
				// Show error in context if got error of `NO_SUCH_ACCOUNT`
				this.setState({
					notAnInternalUser: /NO_SUCH_ACCOUNT/.test(faultCode(error)),
					disableSelection: false
				});
				console.error(error);
				return Promise.reject();
			});
	};

	updateGrantRevokeRights = (permission, newPermissionList = [], removeAction = false) => {
		if (permission !== this.state.selectedPermission) {
			this.setState({ selectedPermission: permission });
		}

		// Do nothing when user selects option `This internal users only:` from other option
		if (permission === FREE_BUSY_RIGHTS.user && !newPermissionList.length && !removeAction) {
			return;
		}

		// Set new permissions and reset states for error and input
		this.setState({
			freeBusyEmailInput: '',
			invalidEmail: false,
			notAnInternalUser: false,
			disableSelection: true
		});

		const accountName = this.props.zimbraPrefFromAddress;
		// Create appropriate rights based on selected option
		const rights = getRights(permission, newPermissionList);

		// Add required fields to Rights for grantQuery and Trigger mutation
		this.grantRevokeRights(accountName, getGrantRightsQueryInput(rights, accountName));
	};

	// Called from `onChange` event
	onFreeBusyPermissionChange = ({ target: { value } }) => this.updateGrantRevokeRights(value);

	handleAddUserClick = () => {
		const { originalEmail, freeBusyEmailInput, viewPermittedUserList, invalidEmail } = this.state;

		if (isValidEmail(freeBusyEmailInput)) {
			const freeBusyPermission = {
				right: VIEW_FREE_BUSY,
				granteeType: FREE_BUSY_RIGHTS.user,
				address: freeBusyEmailInput,
				deny: false,
				originalEmail: originalEmail || freeBusyEmailInput
			};

			// Pass grant type and new list of user permissions
			this.updateGrantRevokeRights(FREE_BUSY_RIGHTS.user, [
				...viewPermittedUserList,
				freeBusyPermission
			]);
		} else {
			!invalidEmail && this.setState({ invalidEmail: true });
		}
	};

	handleRemoveUserClick = ({ originalEmail, disabled }) => {
		if (disabled) return;
		const { viewPermittedUserList, notAnInternalUser } = this.state;
		const newPermissionList = viewPermittedUserList.filter(
			({ address }) => address.trim() !== getEmail(originalEmail)
		);
		// Reset `Not an internal user` error on remove action
		notAnInternalUser &&
			this.setState({
				notAnInternalUser: false
			});

		this.updateGrantRevokeRights(FREE_BUSY_RIGHTS.user, newPermissionList, true);
	};

	getSelectedViewFreeBusyPermission = selectedPermissions => {
		return get(selectedPermissions, '0.deny')
			? FREE_BUSY_RIGHTS.none
			: get(selectedPermissions, '0.granteeType');
	};

	componentWillReceiveProps = ({ selectedPermission, viewPermittedUserList }) => {
		const {
			selectedPermission: prevSelectedPermission,
			viewPermittedUserList: prevViewPermittedUserList
		} = this.props;

		if (selectedPermission !== prevSelectedPermission) {
			/**
			 *  Skip updating `Selected Option` in case User has selected 'These internal users only' and
			 *  removes all users (API returns `null` as access so to avoid selecting default option `Everyone`,
			 *  we are checking and updating `selectedPermission` accordingly)
			 */
			if (
				prevViewPermittedUserList.length > 0 &&
				(!selectedPermission || selectedPermission === FREE_BUSY_RIGHTS.public)
			) {
				this.setState({
					selectedPermission: FREE_BUSY_RIGHTS.user,
					viewPermittedUserList
				});
			} else {
				this.setState({
					selectedPermission,
					viewPermittedUserList
				});
			}
		}
	};

	render(
		{},
		{
			selectedPermission,
			freeBusyEmailInput,
			viewPermittedUserList,
			disableSelection,
			invalidEmail,
			notAnInternalUser,
			shouldRenderSuggestions
		}
	) {
		// Enable Email input for 'These internal users only:' option only.
		// Disable while saving data through APIs.
		const disableUsersInput = disableSelection || selectedPermission !== FREE_BUSY_RIGHTS.user;
		const isEmailInputboxEmpty = !freeBusyEmailInput.trim().length;

		return (
			<div class={cx(style.subsection, style.freeBusyPermission)}>
				<div class={cx(style.subsectionTitle, style.forSelect)}>
					<Text id="settings.calendarAndReminders.freeBusyPermissionLabel" />
				</div>
				<div class={style.subsectionBody}>
					<SelectOption
						value={selectedPermission}
						onChange={this.onFreeBusyPermissionChange}
						class={cx(style.selectOptions, disableSelection && style.disabledElement)}
						fullWidth
						disabled={disableSelection}
					>
						{Object.keys(FREE_BUSY_RIGHTS).map(key => (
							<option value={FREE_BUSY_RIGHTS[key]} key={FREE_BUSY_RIGHTS[key]}>
								<Text
									id={`settings.calendarAndReminders.freeBusyPermissionOptions.${FREE_BUSY_RIGHTS[key]}`}
								/>
							</option>
						))}
					</SelectOption>
					<div class={cx(style.emailListContainer, disableUsersInput && style.disabledText)}>
						{viewPermittedUserList.length > 0 &&
							viewPermittedUserList.map(
								({ address }) =>
									address && (
										<div class={cx(style.mailForwardAddress, style.emailList)}>
											<div class={style.emailAddress}>{address.trim()}</div>
											<Icon
												name="close"
												size="md"
												class={style.closeForwardEmailAddress}
												onClick={callWith(this.handleRemoveUserClick, {
													originalEmail: address.trim(),
													disabled: disableUsersInput
												})}
											/>
										</div>
									)
							)}
						{(invalidEmail || notAnInternalUser) && (
							<ErrorAlert>
								{invalidEmail && <Text id="settings.accounts.errors.forwardInvalidEmail" />}
								{notAnInternalUser && (
									<Text id="settings.calendarAndReminders.errors.notAnInternalUser" />
								)}
							</ErrorAlert>
						)}
					</div>
					<div class={cx(style.inputGroup, style.compact)}>
						{shouldRenderSuggestions && (
							<div class={style.suggestionContainer}>
								<Suggestions
									value={freeBusyEmailInput}
									tokens={viewPermittedUserList}
									wasPreviouslySelected={this.isAlreadySelectedEmail}
									onSelect={this.onSuggestionSelection}
									onRemove={this.handleRemoveUserClick}
									onClose={this.onCloseSuggestions}
									dropUp
								/>
							</div>
						)}
						<TextInput
							placeholder={
								<Text id="settings.calendarAndReminders.freeBusyPermissionEmailPlaceholder" />
							}
							class={style.infoInput}
							value={freeBusyEmailInput}
							onChange={this.handleEmailInputChange}
							disabled={disableUsersInput}
						/>

						<Button
							onClick={this.handleAddUserClick}
							disabled={disableUsersInput || isEmailInputboxEmpty}
						>
							<Text id="buttons.add" />
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
