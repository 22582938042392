import SaveDocument from '../../graphql/mutations/save-document.graphql';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import { useFoldersQuery, useFolderActionMutation } from '../folders';
import { useMutation, useQuery } from '@apollo/client';
import { useSearchQuery } from '../search';
import { useItemActionMutation } from '../action/index';
import { briefcaseSearchVariable, briefcaseFolderVariable, readAndWriteFolderData } from './utils';
import SearchQuery from '../../graphql/queries/search/search.graphql';
import DownloadDocument from '../../graphql/queries/download-document.graphql';
import ListDocumentRevisions from '../../graphql/queries/list-document-revisions.graphql';
import { types as apiClientTypes } from '@zimbra/api-client';
import update from 'immutability-helper';
import { BRIEFCASE_VIEW } from '../../constants/views';
import { DocumentAction } from '../../graphql/queries/document/document-action.graphql';
import cloneDeep from 'lodash-es/cloneDeep';
import { PurgeVersion } from '../../graphql/mutations/purge-version.graphql';

const { ActionOps, MessageFlags } = apiClientTypes;

export const useBriefcaseFoldersQuery = options => {
	const { data, refetch, loading } = useFoldersQuery({
		variables: briefcaseFolderVariable(),
		...options
	});

	// TODO: this is added because of the issue https://github.com/apollographql/react-apollo/issues/2202
	// check if the folder data is of anything else other than 'document', clear out folders in that case
	if (
		get(data, 'getFolder.folders.0.folders.0', {}).view &&
		get(data, 'getFolder.folders.0.folders.0', {}).view !== BRIEFCASE_VIEW
	) {
		set(data, 'getFolder.folders.0.folders', null);
		set(data, 'getFolder.folders.0.linkedFolders', null);
	}

	return { data, refetch, loading };
};

export const useDownloadDocumentQuery = ({ skip, id, url }) => {
	const { data } = useQuery(DownloadDocument, {
		skip,
		variables: { id, url }
	});
	return data || {};
};

export const useBriefcaseSearchQuery = ({ documentQuery: query, sortValue, fetchPolicy }) => {
	const { search, refetch, more, loading } = useSearchQuery({
		variables: briefcaseSearchVariable({ query, sortValue }),
		...(fetchPolicy && { fetchPolicy })
	});

	return {
		documents: get(search, 'documents') || [],
		refetchDocuments: refetch,
		more,
		loading
	};
};

export const useDocumentRevisions = ({ id, version = -1, count = 50, skip, fetchPolicy }) => {
	const { data, loading } = useQuery(ListDocumentRevisions, {
		variables: { id, version, count },
		skip,
		...(fetchPolicy && { fetchPolicy })
	});

	return {
		id,
		documents: data?.listDocumentRevisions,
		loading
	};
};

export const useSaveDocumentMutation = () => {
	return useMutation(SaveDocument);
};

export const useDocumentActionMutation = () => {
	const [documentAction] = useItemActionMutation();

	return function ({
		variables,
		documentQuery: query,
		documentIds,
		folderIds,
		sortValue,
		op: operation,
		currentFolder,
		destFolder,
		targetFolders
	}) {
		return documentAction({
			variables,
			...(query &&
				(documentIds || folderIds) && {
					update: cache => {
						if (documentIds) {
							const data = cache.readQuery({
								query: SearchQuery,
								variables: briefcaseSearchVariable({ query, sortValue })
							});

							const documents = get(data, 'search.documents');
							let updatedDocuments;

							if (documents && documents.length) {
								if (operation === ActionOps.flag || operation === ActionOps.unflag) {
									updatedDocuments = documents.map(document =>
										documentIds.indexOf(document.id) !== -1
											? operation === ActionOps.flag
												? {
														...document,
														flags:
															(document.flags.indexOf('f') === -1 &&
																document.flags.concat(MessageFlags.flagged)) ||
															document.flags
												  }
												: { ...document, flags: document.flags.replace(MessageFlags.flagged, '') }
											: document
									);
								} else {
									updatedDocuments = documents.filter(
										({ id: documentId }) => documentIds.indexOf(documentId) === -1 && documentId
									);
								}
							}
							cache.writeQuery({
								query: SearchQuery,
								variables: briefcaseSearchVariable({ query, sortValue }),
								data: { search: { ...data.search, documents: updatedDocuments } }
							});
						}
						if (folderIds) {
							readAndWriteFolderData({ cache, folder: currentFolder, folderIds });
							readAndWriteFolderData({ cache, folder: destFolder, targetFolders });
						}
					}
				})
		});
	};
};

export const useVersionActionMutation = () => {
	const [versionAction] = useMutation(PurgeVersion);

	return function ({ variables }) {
		return versionAction({
			variables,
			update: cache => {
				const versionId = variables.ver;
				const versionPayload = { id: variables.id, version: -1, count: 50 };

				try {
					const data = cache.readQuery({
						query: ListDocumentRevisions,
						variables: versionPayload
					});

					const listDocumentRevisions = data?.listDocumentRevisions;

					const updatedVersionDocs = listDocumentRevisions.docs.filter(
						({ version }) => version !== versionId
					);

					cache.writeQuery({
						query: ListDocumentRevisions,
						variables: versionPayload,
						data: {
							listDocumentRevisions: { ...data.listDocumentRevisions, docs: updatedVersionDocs }
						}
					});
				} catch (e) {
					console.warn('Error reading/writing document revisions query', e);
				}
			}
		});
	};
};

export const useBriefcaseFolderActionMutation = () => {
	const [folderAction] = useFolderActionMutation();
	return function ({ variables, folderIds, currentFolder, destFolder, targetFolders }) {
		return folderAction({
			variables,
			...(folderIds && {
				update: cache => {
					if (folderIds) {
						readAndWriteFolderData({ cache, folder: currentFolder, folderIds });
						destFolder && readAndWriteFolderData({ cache, folder: destFolder, targetFolders });
					}
				}
			})
		});
	};
};

export const useDocumentAction = () => {
	return useMutation(DocumentAction);
};

export const useRenameItemActionMutation = () => {
	const [documentAction] = useItemActionMutation();

	return function ({ variables, document, newName, documentQuery: query, sortValue }) {
		return documentAction({
			variables,
			update: cache => {
				cache.modify({
					id: cache.identify(document),
					fields: {
						name() {
							return newName;
						}
					}
				});

				if (sortValue && query) {
					const data = cache.readQuery({
						query: SearchQuery,
						variables: briefcaseSearchVariable({ query, sortValue })
					});

					cache.writeQuery({
						query: SearchQuery,
						variables: briefcaseSearchVariable({ query, sortValue }),
						data: update(data, {
							search: {
								documents: {
									$apply: docs => {
										const documents = cloneDeep(docs);
										return sortValue.includes('Asc')
											? documents.sort((a, b) =>
													a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
											  )
											: documents.sort((a, b) =>
													a.name.toUpperCase() < b.name.toUpperCase() ? 1 : -1
											  );
									}
								}
							}
						})
					});
				}
			}
		});
	};
};
