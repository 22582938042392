import { Text } from 'preact-i18n';
import get from 'lodash-es/get';
import { useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderBar, CloseOnClickOrKeydown } from '@zimbra/blocks';
import { useFoldersQuery } from '../../hooks/folders';
import { USER_FOLDER_IDS } from '../../constants';
import { MAIL_VIEW } from '../../constants/views';
import { getSearchFolder, getAdvancedSearchOptions } from '../../store/url/selectors';
import { getQueryOptions } from '../../utils/search';
import { flattenFolders, recursivelyFilterFolders } from '../../utils/folders';
import { setAdvanceOptions, resetAdvanceOptions } from '../../store/search/actions';
import AlignedForm from '../aligned-form';
import AlignedLabel from '../aligned-form/label';
import FormGroup from '../form-group';
import { AddressWrapper } from './fields/address';
import { FilterBy } from './fields/filter';
import { DateFields } from './fields/date';
import { SearchIn } from './fields/search-in';
import { Attachments } from './fields/attachments';
import { Actions } from './fields/actions';
import { getDataforAdvaceSearch } from './utils';
import style from './style';

const FormGroupComponent = ({ textId, children }) => {
	return (
		<FormGroup compact class={style.formGroup}>
			<AlignedLabel align="left" width="100px" whiteSpace="break-spaces">
				<Text id={textId} />
			</AlignedLabel>
			{children}
		</FormGroup>
	);
};

const SearchAdvanced = ({
	onHideAdvanced,
	searchValue,
	zimbraFeatureTaggingEnabled,
	zimbraFeatureSharingEnabled,
	isReset
}) => {
	const dispatch = useDispatch();

	const { data } = useFoldersQuery({
		variables: {
			view: null
		},
		fetchPolicy: 'cache-first'
	});

	const folders = get(data, 'getFolder.folders.0.folders', []).filter(
		f => (!f.view || f.view === MAIL_VIEW) && parseInt(f.id, 10) !== USER_FOLDER_IDS.CHAT // We don't want to show "Chats" folder
	);

	const sharedFolders = zimbraFeatureSharingEnabled
		? recursivelyFilterFolders(get(data, 'getFolder.folders.0.linkedFolders') || [], MAIL_VIEW)
		: [];

	const { urlOpts, searchIn, queryOptions } = useSelector(state => {
		const activeFolder = get(state, 'search.activeFolder');

		return {
			urlOpts: getAdvancedSearchOptions(state),
			searchIn: getSearchFolder(state),
			queryOptions: activeFolder && getQueryOptions(get(activeFolder, 'query'))
		};
	});

	useEffect(() => {
		if (!isReset) {
			const allFolders = flattenFolders([...folders, ...sharedFolders]);
			const obj = queryOptions || urlOpts;
			const advanceProps = getDataforAdvaceSearch(obj, { searchIn, allFolders });

			dispatch(setAdvanceOptions(advanceProps));
		}
		return () => {
			dispatch(resetAdvanceOptions());
		};
	}, [dispatch, data]); // eslint-disable-line

	return (
		<CloseOnClickOrKeydown onClickOutside={onHideAdvanced}>
			<div className={style.container}>
				{!data ? (
					<LoaderBar />
				) : (
					<AlignedForm>
						<FormGroupComponent textId="search.advanced.searchIn">
							<SearchIn folders={folders} sharedFolders={sharedFolders} />
						</FormGroupComponent>

						<FormGroupComponent textId="search.advanced.from">
							<AddressWrapper field="fromValue" />
						</FormGroupComponent>

						<FormGroupComponent textId="search.advanced.to">
							<AddressWrapper field="toValue" />
						</FormGroupComponent>

						<FormGroupComponent textId="search.advanced.attachment.title">
							<Attachments />
						</FormGroupComponent>

						<FormGroupComponent textId="search.advanced.filterBy">
							<FilterBy zimbraFeatureTaggingEnabled={zimbraFeatureTaggingEnabled} />
						</FormGroupComponent>

						<FormGroupComponent textId="search.advanced.date">
							<DateFields />
						</FormGroupComponent>

						<Actions
							searchValue={searchValue}
							onHideAdvanced={onHideAdvanced}
							queryOptions={queryOptions}
						/>
					</AlignedForm>
				)}
			</div>
		</CloseOnClickOrKeydown>
	);
};

export default SearchAdvanced;
