export const getPageAttributes = path => {
	const pathSubString = path.slice(1);

	const firstIndex = pathSubString.indexOf('/');
	const lastIndex = pathSubString.lastIndexOf('/');

	const segment = (lastIndex > 0 && pathSubString.slice(0, lastIndex)) || pathSubString;

	let page;

	if (firstIndex > 0 && lastIndex >= firstIndex) {
		page = pathSubString.slice(lastIndex + 1);
	}

	return {
		path,
		segment,
		...(page && { page })
	};
};
