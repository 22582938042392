import { Text } from 'preact-i18n';
import ToolbarPortalTarget from './toolbar-portal-target';
import ZimletSlot from '../zimlet-slot';
import AppNavigationTabs from './tabs';
import style from './style';
import { minWidth, screenMd } from '../../constants/breakpoints';
import { useSelector, useDispatch } from 'react-redux';
import { toggleModal } from '../../store/calendar/actions';
import { Icon } from '@zimbra/blocks';
import { CalendarMainContextMenu } from '../context-menus';
import OfflineLabel from '../offline-label';
import { LoggedOutInfo } from '../logged-out';
import s from './style.less';
import ContextMenu from '../context-menu';
import { useClientConfig } from '../../hooks/client-config';
import { useCallback } from 'preact/hooks';
import { useMediaQuery } from '../../enhancers/use-media-query';
import escapeRegExp from 'lodash-es/escapeRegExp';
import { Link } from 'preact-router/match';
import { Fragment } from 'preact';

const isActiveItem = ({ item, url }) => {
	if (item === '/calendar') {
		const match = new RegExp('^' + escapeRegExp(item));
		return match.test(url.location.pathname);
	}
	return false;
};

const renderItem = ({ openContextMenu }) => (
	<Icon name="ellipsis-h" size="sm" onClick={openContextMenu} class={s.contextMenuIcon} />
);

const TabContextMenu = ({ url }) => {
	const { slugs } = useClientConfig('routes.slugs');
	const dispatch = useDispatch();
	const { view } = url;

	const handleOpenModal = useCallback(
		modalType => {
			dispatch(
				toggleModal({
					modalType
				})
			);
		},
		[dispatch]
	);

	return (
		isActiveItem({ item: `/${slugs[view]}`, url }) && (
			<ContextMenu
				class={s.contextMenu}
				{...(view === 'calendar' && {
					menu: <CalendarMainContextMenu openModal={handleOpenModal} />
				})}
				render={renderItem}
			/>
		)
	);
};

const MenuItemView = ({ renderContextMenu, vertical = {}, url }) => (
	<div class={style.defaultItem}>
		<Link class={style.placeholderText} href={vertical.url} role="title">
			<Text id={`appNavigation.${vertical.name}`} />
		</Link>
		{renderContextMenu && <TabContextMenu url={url} />}
	</div>
);

const TabMenuItem = ({ vertical = {} }) => {
	const tabs = vertical.tabs || [];

	return tabs
		.filter(({ permanent }) => permanent)
		.map(({ titleId, url, title }) => (
			<Link class={style.tabLink} href={url} title={name}>
				{titleId ? <Text id={titleId} /> : title}
			</Link>
		));
};

export default function TabNavigation({ sidebar }) {
	const url = useSelector(state => state?.url);
	const verticals = useSelector(state => state?.navigation?.verticals);
	const { slugs } = useClientConfig('routes.slugs');
	const { desktopView } = useMediaQuery(minWidth(screenMd), 'desktopView');

	return (
		<div class={style.appMenu}>
			{desktopView && (
				<div class={style.nav}>
					<MenuItemView url={url} vertical={verticals[url.view]} />
					{url.view === slugs.integrations && <ZimletSlot props class={style.slot} name="menu" />}
					<ZimletSlot props class={style.slot} name={`${url.view}-tab-item`} />
					{Object.keys(verticals).length > 0 && <AppNavigationTabs />}
					<OfflineLabel isDesktopView={desktopView} />
					{typeof process.env.ELECTRON_ENV !== 'undefined' && <LoggedOutInfo />}
				</div>
			)}
			{!desktopView && sidebar && (
				<Fragment>
					<MenuItemView url={url} vertical={verticals[url.view]} renderContextMenu />
					{Object.keys(verticals).length > 0 && <TabMenuItem vertical={verticals[url.view]} />}
				</Fragment>
			)}
			<ToolbarPortalTarget />
		</div>
	);
}
