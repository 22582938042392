import TagsQuery from '../../graphql/queries/tags/tags.graphql';
import { graphql } from '@apollo/client/react/hoc';
import TagCreateMutation from '../../graphql/queries/tags/create-tag.graphql';

export default function withTagCreate() {
	return graphql(TagCreateMutation, {
		props: ({ mutate }) => ({
			createTag: tag =>
				mutate({
					variables: {
						tag
					},
					optimisticResponse: {
						__typename: 'Mutation',
						createTag: {
							id: 'tempId',
							name: tag.name,
							color: tag.color,
							unread: 0,
							__typename: 'Tag'
						}
					},
					update: (cache, { data: { createTag: newTag } }) => {
						const data = cache.readQuery({ query: TagsQuery });
						let { getTag } = data;
						const existingTagIndex = getTag.findIndex(
							({ name: tagName }) => tagName === newTag.name
						);
						const existingTag = getTag[existingTagIndex];

						if (!existingTag) {
							getTag = [...getTag, newTag];
						} else {
							getTag = [
								...getTag.slice(0, existingTagIndex),
								{
									...existingTag,
									id: newTag.id
								},
								...getTag.slice(existingTagIndex)
							];
						}

						cache.writeQuery({
							query: TagsQuery,
							data: {
								...data,
								getTag
							}
						});
					}
				})
		})
	});
}
