import { PureComponent } from 'preact/compat';
import { CloseOnClickOrKeydown } from '@zimbra/blocks';
import find from 'lodash-es/find';
import withAutoComplete from '../../graphql-decorators/autocomplete';
import withAutoCompleteGAL from '../../graphql-decorators/autocomplete-gal';
import ContactSuggestion from '../contact-suggestion';
import isEmpty from 'lodash-es/isEmpty';
import { branch, renderNothing, withProps, compose } from 'recompose';
import { withApollo } from '@apollo/client/react/hoc';
import withLunrIndex from '../../enhancers/with-lunr-index';
import contactFields from '../../graphql/fragments/contact.graphql';
import { zimbraQueryToLunrQuery } from '../../utils/search';
import { connect } from 'react-redux';
import { CONTACTS_VIEW } from '../../constants/views';
import { getEmail } from '../../lib/util';
import cx from 'classnames';

import style from './style';

const byRank = (a, b) => b.ranking - a.ranking;
@connect(state => ({
	isOffline: state.network.isOffline
}))
@branch(
	({ isOffline }) => isOffline,
	compose(
		withLunrIndex(CONTACTS_VIEW),
		withApollo,
		withProps(({ client, lunrIndex, sort, ...props }) => ({
			contactSuggestions:
				props.value !== ''
					? lunrIndex.search(zimbraQueryToLunrQuery(`*${props.value}*`)).map(
							({ ref }) =>
								client.readFragment({
									id: `Contact:${ref}`,
									fragment: contactFields,
									fragmentName: 'contactFields'
								}).attributes
					  )
					: []
		}))
	),
	compose(
		withAutoComplete(),
		withAutoCompleteGAL({
			skip: ({ isLocation, isGalOnly }) => !(isLocation || isGalOnly),
			props: ({ data, ownProps }) => ({
				galContacts:
					data.loading || !data.autoCompleteGAL || !data.autoCompleteGAL.contacts
						? []
						: data.autoCompleteGAL.contacts
								.map(val => val.attributes)
								.filter(
									attributes => !ownProps.isLocation || attributes.zimbraCalResType === 'Location'
								)
			})
		})
	)
)
@branch(
	({ galContacts, contactSuggestions }) => isEmpty(galContacts) && isEmpty(contactSuggestions),
	renderNothing
)
export default class Suggestions extends PureComponent {
	counter = 0;

	selectAddress = contact => {
		const { galContacts, contactSuggestions, onSelectionChange } = this.props;
		onSelectionChange &&
			onSelectionChange({
				index: (galContacts || contactSuggestions).indexOf(contact),
				value: contact
			});
	};

	chooseAddress = contact => {
		if (!this.props.wasPreviouslySelected || !this.props.wasPreviouslySelected(contact)) {
			this.props.onSelect(contact);
		}
	};

	getSelectedIndex() {
		const { selectedIndex, contactSuggestions, galContacts } = this.props;
		return (
			selectedIndex &&
			Math.max(0, Math.min((galContacts || contactSuggestions).length - 1, selectedIndex))
		);
	}

	static defaultProps = {
		filter() {}
	};

	componentWillMount() {
		const { onSelectionChange } = this.props;
		onSelectionChange && onSelectionChange(0);
	}

	componentWillReceiveProps({
		contactSuggestions,
		selectedIndex,
		commitSelectedIndex,
		onSelectionChange,
		galContacts
	}) {
		const list = galContacts || contactSuggestions;
		const count = list ? list.length : 0;
		if (selectedIndex && selectedIndex >= count) {
			onSelectionChange(count - 1);
		}
		if (count && commitSelectedIndex != null) {
			this.chooseAddress(list[commitSelectedIndex]);
		}
	}

	componentWillUnmount() {
		const { onSelectionChange } = this.props;
		onSelectionChange && onSelectionChange(null);
	}

	renderContact = (contact, index) => {
		// Compare only email address instead of "Contact Name <email@address>"
		const token = find(
			this.props.tokens,
			t => getEmail(t.originalEmail) === getEmail(contact.email)
		);

		return (
			<ContactSuggestion
				input={this.props.value}
				token={token}
				active={this.getSelectedIndex() === index}
				selected={this.props.tokens && Boolean(token)}
				previouslySelected={
					this.props.wasPreviouslySelected && this.props.wasPreviouslySelected(contact)
				}
				previouslySelectedLabel={
					typeof this.props.previouslySelectedLabel === 'function'
						? this.props.previouslySelectedLabel(contact)
						: this.props.previouslySelectedLabel
				}
				contact={contact}
				onSelect={this.selectAddress}
				onClick={this.chooseAddress}
				onRemove={this.props.onRemove}
				isLocation={this.props.isLocation}
			/>
		);
	};

	render({ contactSuggestions, galContacts = [], isLocation, isGalOnly, dropUp, onClose }) {
		const contacts =
			isLocation || isGalOnly ? galContacts : contactSuggestions.slice().sort(byRank);

		return (
			contacts.length !== 0 && (
				<CloseOnClickOrKeydown onClickOutside={onClose}>
					<div class={cx(style.suggestions, dropUp && style.up)}>
						{contacts.map(this.renderContact)}
					</div>
				</CloseOnClickOrKeydown>
			)
		);
	}
}
