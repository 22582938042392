import orderBy from 'lodash-es/orderBy';
import intersection from 'lodash-es/intersection';

const EMPTY_ARRAY = [];

export function extractTagsForItem(tags = [], item) {
	if (!item) return null;
	if (!tags.length) return EMPTY_ARRAY;

	const { tags: itemTags, tagNames: itemTagNames, id } = item;
	// Shared calendar event contains ":". In such case, tagNames are present instead of tags.
	const tagsArray = ((id?.includes(':') && itemTagNames) || itemTags || '')
		.split(',')
		.filter(Boolean);

	if (tagsArray.length) {
		const tagValueToFind = id.includes(':') ? 'name' : 'id';

		const matchedTags = tags
			.filter(({ [tagValueToFind]: tagValue }) => tagsArray.indexOf(tagValue) !== -1)
			.slice();
		return (matchedTags && orderBy(matchedTags, [({ name }) => name.toLowerCase()])) || EMPTY_ARRAY;
	}
	return EMPTY_ARRAY;
}

// Get common tags from the items selected. These will be marked checked in tag-chooser modal
export function getTagsFromSelection(selectedItems = [], allItems) {
	// Check if `selectedItems` is an Array of IDs ["1", "2", ...]
	// or Objects [{id: 1, ...}, {id: 2, ...}, ...].
	// If it's already an Array of ids, skip fetching id
	if (selectedItems.length && typeof selectedItems[0] === 'object') {
		selectedItems = selectedItems.map(({ id }) => id);
	}

	return allItems
		? intersection(
				...allItems
					.filter(({ id }) => selectedItems.indexOf(id) !== -1)
					.map(({ tags }) => tags && tags.split(','))
		  )
		: [];
}
