import { createElement } from 'preact';
import { Localizer } from 'preact-i18n';
import cx from 'classnames';
import { callWith } from '../../lib/util';
import { getTranslatedFolderName } from '../folder-list/util';
import { ChoiceInput } from '@zimbra/blocks';
import MenuItem from '../menu-item';
import CollapsibleControl from '../collapsible-control';
import style from '../folder-list/style';
import s from './style';

import { PureComponent } from 'preact/compat';

function getAllSubfolders({ folder, folders, linkedFolders, habGroups }) {
	return (folders || folder || habGroups || []).concat(linkedFolders || []);
}

//TODO: change it to functional component
export default class FolderListItem extends PureComponent {
	ensureFolderObject = folder =>
		typeof folder === 'string' ? { id: folder, name: folder } : folder;

	handleExpandClick = ({ id, expanded }, e) => {
		e.stopPropagation();
		e.preventDefault();
		this.props.onToggleExpanded(id, !expanded);
	};

	handleClick = ({ folder }, e) => {
		e.stopPropagation();
		const { onFolderClick, isRefreshing } = this.props;
		onFolderClick && onFolderClick(folder, !isRefreshing);
	};

	static defaultProps = {
		onItemClick: () => {},
		onToggleExpanded: () => {},
		foldersExpanded: {},
		depth: 1,
		disableCollapse: false
	};

	renderItem = () => {
		const {
			depth,
			grouped,
			disableCollapse,
			folder,
			selectedFolderIds,
			indexFolder,
			foldersExpanded,
			isFolderDisabled,
			isMultiSelect
		} = this.props;

		const expanded = folder.id ? foldersExpanded.includes(folder.id) === true : false;

		const subFolders = getAllSubfolders(folder);

		const hasFolderClickPermission = !isFolderDisabled || isFolderDisabled(folder);
		// For `Single Select` only. To sets active background color for the selected item.
		const isActive =
			!isMultiSelect &&
			(selectedFolderIds.length
				? selectedFolderIds.includes(folder.id)
				: indexFolder && indexFolder.id === folder.id);

		return (
			<div
				class={cx(
					style.item,
					grouped && style.grouped,
					!disableCollapse && subFolders && style.collapsible,
					style.itemTitle
				)}
				data-folder-depth={depth}
			>
				<Localizer>
					<MenuItem
						customClass={cx(style.itemLink, folder.broken && style.brokenSymLink)}
						innerClass={style.itemInner}
						title={getTranslatedFolderName(folder)}
						{...(hasFolderClickPermission && {
							onClick: callWith(this.handleClick, { folder }, true)
						})}
						{...(isActive && {
							activeClass: style.active
						})}
					>
						{!disableCollapse && subFolders.length > 0 && (
							<CollapsibleControl
								collapsed={!expanded}
								onClick={callWith(
									this.handleExpandClick,
									{
										id: folder.id,
										expanded
									},
									true
								)}
								class={cx(
									style.folderCollapsibleControl,
									style[`folderCollapsibleControl--depth${depth}`],
									grouped && style.grouped
								)}
							/>
						)}
						{isMultiSelect && (
							<ChoiceInput
								type="checkbox"
								containerClass={style.checkboxContainer}
								checked={selectedFolderIds.includes(folder.id)}
							/>
						)}
						<div class={cx(style.itemTitle, !hasFolderClickPermission && s.disabledFolder)}>
							{getTranslatedFolderName(folder)}
						</div>
					</MenuItem>
				</Localizer>
			</div>
		);
	};

	render({ depth, folder, foldersExpanded }) {
		folder = this.ensureFolderObject(folder);

		const expanded = folder.id ? foldersExpanded.includes(folder.id) === true : false;

		let item;

		if (typeof folder.view === 'function') {
			item = createElement(folder.view, { folder, depth });
		} else {
			const folderItem = createElement(this.renderItem);
			const subFolders = getAllSubfolders(folder);
			item = [folderItem];

			if (subFolders) {
				item = (
					<div class={style.folderWithSubFolders}>
						{item}
						{expanded &&
							subFolders.map(subFolder => (
								<FolderListItem {...this.props} depth={(depth || 1) + 1} folder={subFolder} />
							))}
					</div>
				);
			}
		}

		return item;
	}
}
