import { combineReducers } from 'redux';

import activeAccount from './active-account/reducer';
import attachmentPreview from './attachment-preview/reducer';
import attachMail from './attach-mail/reducer';
import attachDialog from './attach-dialog/reducer';
import calendar from './calendar/reducer';
import network from './network/reducer';
import trashFolder from './trash-folder/reducer';
import junkFolder from './junk-folder/reducer';
import contacts from './contacts/reducer';
import contactChooser from './contact-chooser/reducer';
import dragdrop from './dragdrop/reducer';
import email from './email/reducer';
import mail from './mail/reducer';
import briefcase from './briefcase/reducer';
import mediaMenu from './media-menu/reducer';
import navigation from './navigation/reducer';
import notifications from './notifications/reducer';
import notificationModal from './notification-modal/reducer';
import pollingRequests from './polling-requests/reducer';
import settings from './settings/reducer';
import about from './about/reducer';
import sidebar from './sidebar/reducer';
import tags from './tags/reducer';
import url from './url/reducer';
import search from './search/reducer';
import zimlets from './zimlets/reducer';
import locale from './locale/reducer';
import importModal from './account-settings/reducer';
import importPst from './pst-import/reducer';
import dumpster from './dumpster/reducer';
import miniCal from './mini-calendar/reducer';
import sync from './sync/reducer';
import template from './template/reducer';

export default function createReducer(additionalReducers = {}) {
	return combineReducers({
		...additionalReducers,
		activeAccount,
		attachmentPreview,
		attachMail,
		attachDialog,
		calendar,
		contacts,
		contactChooser,
		dragdrop,
		email,
		briefcase,
		importModal,
		importPst,
		locale,
		mail,
		mediaMenu,
		navigation,
		notifications,
		notificationModal,
		pollingRequests,
		settings,
		about,
		sidebar,
		tags,
		url,
		network,
		trashFolder,
		junkFolder,
		search,
		dumpster,
		zimlets,
		miniCal,
		sync,
		template
	});
}
