let ID = 0;
export const DIALOGS_RESTORE = ++ID;
export const DIALOGS_EXPORT = ++ID;
export const DIALOGS_IMPORT = ++ID;
export const DIALOGS_ASSIGN_TO_LISTS = ++ID;
export const DIALOGS_DELETE_PERMANENT = ++ID;
export const DIALOGS_DELETE_LIST = ++ID;
export const DIALOGS_SAVE_CHANGES = ++ID;
export const DIALOGS_SELECT_FOLDER = ++ID;

export const CONTACT_TYPE = { inline: 'I', gal: 'G', localOrShared: 'C' };
export const MODIFY_CONTACT_LIST_OPERATION = { add: '+', remove: '-' };
