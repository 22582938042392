import get from 'lodash-es/get';
import {
	addTab,
	updateTab,
	removeAllTabs,
	removeTab,
	updateLastVisitedTab,
	removeTabs
} from '../store/navigation/actions';
import { isOfflineId } from '../utils/offline';

export const getDataFromReduxStoreThunk = path => (_, getState) => {
	if (typeof path === 'function') {
		return path(getState());
	}
	return get(getState(), path, {});
};

export const addTabThunk = ({ view, ...tab }) => {
	return (dispatch, getState) => {
		view = view || get(getState(), 'url.view');
		dispatch(addTab({ ...tab, view }));
	};
};

export const updateTabThunk = ({ view, ...tab }) => {
	return (dispatch, getState) => {
		view = view || get(getState(), 'url.view');
		dispatch(updateTab({ ...tab, view }));
	};
};

export const removeTabThunk = ({ view, ...tab }) => {
	return (dispatch, getState) => {
		view = view || get(getState(), 'url.view');
		dispatch(removeTab({ ...tab, view }));
	};
};

export const removeOfflineTabsThunk = () => {
	return (dispatch, getState) => {
		const state = getState();
		const view = state?.url?.view;
		const vertical = state?.navigation?.verticals?.[view];

		const tabIds = vertical.tabs?.reduce((acc, tab) => {
			if (isOfflineId(tab.id)) {
				acc.push(tab.id);
			}
			return acc;
		}, []);
		const resetLastVisitedTab = isOfflineId(vertical.lastVisitedTab);

		dispatch(removeTabs({ view, tabIds, resetLastVisitedTab }));
	};
};

export const removeEMLTabsThunk = ({ id }) => {
	return (dispatch, getState) => {
		const state = getState();
		const view = state?.url?.view;
		const vertical = state?.navigation?.verticals?.[view];

		const reg = new RegExp(`/message/${id}/[0-9]\\?full`);
		const tabs = vertical.tabs?.filter(({ url }) => url.match(reg));
		const tabIds = vertical.tabs?.reduce((acc, tab) => {
			if (tab.url.match(reg)) {
				acc.push(tab.id);
			}
			return acc;
		}, []);

		const lastVisitedTabIndex = tabs.findIndex(
			tab => vertical.lastVisitedTab.indexOf(tab.id) !== -1
		);
		const resetLastVisitedTab = lastVisitedTabIndex !== -1;

		dispatch(removeTabs({ view, tabIds, resetLastVisitedTab }));
	};
};

export const removeAllTabsThunk = () => {
	return (dispatch, getState) => {
		const view = get(getState(), 'url.view');
		dispatch(removeAllTabs({ view }));
	};
};

export const updateLastVisitedTabThunk = ({ url, view }) => {
	return (dispatch, getState) => {
		view = view || get(getState(), 'url.view');
		dispatch(updateLastVisitedTab({ view, url }));
	};
};
