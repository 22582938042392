import { withCalendars, withAppointments } from '../../graphql-decorators/calendar';

import { useEffect, useCallback, useState, useRef } from 'preact/hooks';
import { compose } from 'recompose';
import { Spinner } from '@zimbra/blocks';
import { Text } from 'preact-i18n';
import get from 'lodash-es/get';
import moment from 'moment';
import { handleAppointmentsNoOp, getActiveCalendars } from '../../utils/calendar';
import { getSortedAppointments, getTimeRange } from './mini-calendar-util';
import { MiniCalendarSections } from './mini-calendar-sections';
import { MiniCalendarEvents } from './mini-calendar-events';
import style from './style.less';
import { updateHeight } from '../../store/mini-calendar/actions';
import PreviewResizeControl from '../preview-resize-control';
import clientConfiguration from '../../enhancers/client-config';
import { connect, useSelector, useDispatch } from 'react-redux';
import withTabManager from '../../enhancers/tab-manager';
import { closeEventTabThunk } from '../calendar/thunk';
import { AddEvent } from './add-event';
import { Header } from './header';

const MINI_CAL_DRAG_MIN_VALUE = 48;

const MiniCalendar = (
	{
		calendarUrlSlug,
		closeEventTabThunkAction,
		handleNewTab,
		appointments = [],
		loadingAppointments,
		refetchAppointments,
		calendars,
		selectedDate,
		isOffline
	},
	{ zimbraBatchClient }
) => {
	const ref = useRef(null);
	const height = useSelector(state => get(state, 'miniCal.height'));
	const dispatch = useDispatch();

	const [resizedHeight, setHeightResize] = useState(height);

	const sortedAppointments = getSortedAppointments(appointments, selectedDate);

	const handleResizeStart = useCallback(() => {
		if (resizedHeight !== height) {
			setHeightResize(height);
		}
	}, [resizedHeight, height]);

	const handleResize = useCallback(
		offset => {
			const updatedHeight = height - offset;

			if (
				updatedHeight >= MINI_CAL_DRAG_MIN_VALUE &&
				updatedHeight <= get(ref, 'current.offsetParent.clientHeight')
			) {
				setHeightResize(updatedHeight);
			}
		},
		[height]
	);

	const handleResizeEnd = useCallback(
		() => dispatch(updateHeight(resizedHeight)),
		[dispatch, resizedHeight]
	);

	const renderListItem = useCallback(
		event => (
			<MiniCalendarEvents
				key={event.id}
				event={event}
				calendarUrlSlug={calendarUrlSlug}
				closeEventTabThunkAction={closeEventTabThunkAction}
				handleNewTab={handleNewTab}
			/>
		),
		[calendarUrlSlug, closeEventTabThunkAction, handleNewTab]
	);

	const handleNotifications = useCallback(
		notifications => {
			handleAppointmentsNoOp(notifications, refetchAppointments, calendars);
		},
		[calendars, refetchAppointments]
	);

	useEffect(() => {
		if (zimbraBatchClient.notifier) {
			zimbraBatchClient.notifier.addNotifyHandler(handleNotifications);

			return () => {
				zimbraBatchClient.notifier.removeNotifyHandler(handleNotifications);
			};
		}
	}, [handleNotifications, zimbraBatchClient.notifier]);

	return [
		<PreviewResizeControl
			onDragStart={handleResizeStart}
			onDrag={handleResize}
			onDragEnd={handleResizeEnd}
			visibleClass={style.resizeControl}
			hiddenClass={style.resizeControl}
		/>,
		<div ref={ref} class={style.miniCalView} style={{ height: `${resizedHeight}px` }}>
			<div class={style.miniCal}>
				<Header />

				{!loadingAppointments ? (
					sortedAppointments.length === 0 ? (
						<span class={style.noAppointments}>
							<Text class={style.noAppointments} id="miniCal.noEvents" />
						</span>
					) : (
						<ul>
							{sortedAppointments.map(appointment => {
								const sectionLabel = getTimeRange(appointment[0], selectedDate);
								return (
									<MiniCalendarSections
										key={appointment[0].id}
										sectionLabel={sectionLabel}
										events={appointment}
										renderItem={renderListItem}
									/>
								);
							})}
						</ul>
					)
				) : (
					<Spinner class={style.spinner} />
				)}

				<AddEvent
					calendarUrlSlug={calendarUrlSlug}
					closeEventTabThunkAction={closeEventTabThunkAction}
					handleNewTab={handleNewTab}
					isOffline={isOffline}
				/>
			</div>
		</div>
	];
};

export const MiniCalendarView = compose(
	clientConfiguration({
		calendarUrlSlug: 'routes.slugs.calendar'
	}),
	withTabManager(),
	withCalendars(({ data: { loading: loadingCalendars } }, calendars) => ({
		calendars: getActiveCalendars(calendars) || [],
		loadingCalendars
	})),
	connect(
		({ miniCal, network }) => ({
			selectedDate: miniCal.selectedDate || moment(),
			isOffline: network.isOffline
		}),
		{
			closeEventTabThunkAction: closeEventTabThunk
		}
	),
	withAppointments(
		({ data: { getAppointments, loading: loadingAppointments, refetch: refetchAppointments } }) => {
			return {
				appointments: get(getAppointments, 'appointments'),
				loadingAppointments,
				refetchAppointments
			};
		},
		{
			skip: ({ loadingCalendars, calendars }) =>
				loadingCalendars || (calendars && calendars.length === 0),
			options: ({ calendars, selectedDate, isOffline }) => ({
				variables: {
					calExpandInstStart: moment(selectedDate).startOf('day').valueOf(),
					calExpandInstEnd: moment(selectedDate).endOf('day').valueOf(),
					query: calendars.map(({ id }) => `inid:"${id}"`).join(' OR ')
				},
				fetchPolicy: isOffline ? 'cache-only' : 'cache-and-network'
			})
		}
	)
)(MiniCalendar);
