import { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { route } from 'preact-router';
import PropTypes from 'prop-types';
import GetFolder from '../../graphql/queries/folders/get-folder.graphql';
import withPreference from '../../graphql-decorators/preferences/get-preferences';
import { USER_FOLDER_IDS } from '../../constants';
import renderToString from 'preact-render-to-string';
import { Text, IntlProvider } from 'preact-i18n';
import get from 'lodash-es/get';
import groupBy from 'lodash-es/groupBy';
import trimStart from 'lodash-es/trimStart';
import { flattenFolders } from '../../utils/folders';
import withTabManager from '../../enhancers/tab-manager';
import { normalize, entities } from '@zimbra/api-client';
import { setFocusToZimbra } from '../../utils/notifications';
import { withIntlConsumer } from '../../enhancers/with-intl-consumer';

const CLOSE_TIME = 10000; // milliseconds
const { MessageInfo } = entities;
const normalizeMessage = normalize(MessageInfo);

@withIntlConsumer()
@graphql(GetFolder, {
	options: () => ({
		fetchPolicy: 'cache-only',
		variables: {
			view: null
		}
	}),
	props: ({ data }) => ({
		allFolders: [
			...(get(data, 'getFolder.folders.0.folders') || []),
			...(get(data, 'getFolder.folders.0.linkedFolders') || [])
		]
	})
})
@withTabManager()
@withPreference(({ data: { getPreferences } }) => ({
	zimbraPrefShowAllNewMailNotifications: get(
		getPreferences,
		'zimbraPrefShowAllNewMailNotifications'
	),
	viewType: get(getPreferences, 'zimbraPrefGroupMailBy')
}))
export default class NewMailNotification extends Component {
	createRouteUrl = mails => {
		const { slugs, viewType, allFolders } = this.props;
		const folderId = get(mails, '0.folderId');
		const { absFolderPath: folderPath } = this.getFolderName(allFolders, folderId);
		const id = viewType === 'message' ? get(mails, '0.id') : get(mails, '0.convId');

		return `/${slugs.email}${folderPath}/${viewType}/${id}?full=true`;
	};

	handleNotificationClick = (noticeObj, mails) => {
		const { handleNewTab, viewType } = this.props;

		mails.length > 1
			? route('/')
			: handleNewTab({
					url: this.createRouteUrl(mails),
					type: viewType,
					title: get(mails, '0.subject')
			  });

		setFocusToZimbra().then(() => {
			noticeObj.close();
		});
	};

	getFolderName = (folders, folderId) => flattenFolders(folders).find(({ id }) => id === folderId);

	filterInboxMails = mails =>
		mails.filter(({ folderId }) => folderId === USER_FOLDER_IDS.INBOX.toString());

	processNewMail = mails => {
		const { zimbraPrefShowAllNewMailNotifications } = this.props;

		const filteredMails = !zimbraPrefShowAllNewMailNotifications
			? this.filterInboxMails(mails)
			: mails;
		filteredMails.length !== 0 && this.generateNotification(filteredMails);
	};

	getFromEmailAddress = emailAddresses => {
		const grouped = groupBy(emailAddresses, 'type');
		return get(grouped, 'f.0.displayName') || get(grouped, 'f.0.address');
	};

	generateNotification = mails => {
		if (window.Notification && Notification.permission === 'granted') {
			const { getAssetURLByClient } = this.props;
			let body, title;

			if (mails.length > 1) {
				body = this.getTranslatedString({
					id: 'newMailNotification.newMailMessage',
					field: { name: 'count', value: mails.length }
				});

				title = this.getTranslatedString({
					id: 'newMailNotification.newMailTitle',
					field: {},
					plural: mails.length
				});
			} else {
				const subject = get(mails, '0.subject');
				const excerpt = trimStart(get(mails, '0.excerpt'));
				const fromEmailAddress = this.getFromEmailAddress(get(mails, '0.emailAddresses'));

				body = subject && excerpt ? `${subject} - ${excerpt}` : subject || excerpt;

				title = this.getTranslatedString({
					id: 'newMailNotification.newMailTitle',
					field: { name: 'displayName', value: fromEmailAddress },
					plural: mails.length
				});
			}

			const options = {
				icon: getAssetURLByClient('icon.png'),
				tag: 'NEW_MAIL',
				requireInteraction: false,
				body
			};

			const notify = new Notification(title, options);

			notify.addEventListener('click', () => {
				this.handleNotificationClick(notify, mails);
			});

			// Clearing the previous timer and setting a fresh one if new mail arrives before CLOSE_TIME has elapsed.
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				notify.close();
			}, CLOSE_TIME);
		}
	};

	getTranslatedString = ({ id, field: { name, value }, plural }) =>
		renderToString(
			<IntlProvider definition={this.props.intl.dictionary}>
				<Text id={id} plural={plural} fields={{ [name]: value }} />
			</IntlProvider>
		);

	/*
	 * Binds the notifications event and listens for updates in the new message.
	 * Process the new mail to show notification if there is any update from notifications.
	 *
	 * @param {Object} notifications the notifications received from the api
	 * @memberof New Mail
	 */
	handleNewMailNotifications = notifications => {
		const createdMessages = get(notifications, 'created.m') || [];
		const newMessages = [];
		createdMessages.forEach(i => {
			const item = normalizeMessage(i);
			const flags = item.flag || item.flags;
			flags &&
				flags.indexOf('u') > -1 &&
				newMessages.push({
					id: item.id,
					convId: item.conversationId || null,
					subject: item.subject || null,
					flags: item.flags || item.flag || null,
					folderId: item.folderId || null,
					emailAddresses: item.emailAddresses || null,
					excerpt: item.excerpt || ''
				});
		});

		newMessages.length > 0 && this.processNewMail(newMessages);
	};

	unbindNewMailNotification = () => {
		// Removed the notifications handler attached
		const notifier = get(this.context, 'zimbraBatchClient.notifier');
		notifier && notifier.removeNotifyHandler(this.handleNewMailNotifications);
	};

	bindNewMailNotification = () => {
		// bind to notifications on message created
		const notifier = get(this.context, 'zimbraBatchClient.notifier');
		notifier && notifier.addNotifyHandler(this.handleNewMailNotifications);
	};

	static propTypes = {
		data: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				convId: PropTypes.string,
				subject: PropTypes.string,
				flags: PropTypes.string,
				folderId: PropTypes.string,
				emailAddresses: PropTypes.arrayOf(
					PropTypes.shape({
						type: PropTypes.string,
						address: PropTypes.string,
						displayName: PropTypes.displayName,
						name: PropTypes.string
					})
				),
				excerpt: PropTypes.string
			})
		),
		zimbraPrefShowAllNewMailNotifications: PropTypes.bool,
		getAssetURLByClient: PropTypes.func,
		viewType: PropTypes.string
	};

	componentDidMount() {
		this.bindNewMailNotification();
	}

	componentWillUnmount() {
		this.unbindNewMailNotification();
		clearTimeout(this.timer);
	}

	render() {
		return null;
	}
}
