import csrfStorage from '../utils/csrf';

export function importFile(fileData, format, username, folderPath, types, zimbraBatchClient) {
	const csrftoken = csrfStorage.get();
	const type = types ? `&types=${types}` : '';
	const reqUrl = `/home/${username}/${folderPath}?fmt=${format}${type}&charset=UTF-8`;
	const resUrl = zimbraBatchClient.resolve(reqUrl);

	return fetch(resUrl, {
		method: 'POST',
		body: fileData,
		headers: {
			'Content-Type': 'application/octet-stream',
			...(csrftoken && {
				'X-Zimbra-Csrf-Token': csrftoken
			})
		},
		credentials: 'include'
	});
}
