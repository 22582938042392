import flatMapDeep from 'lodash-es/flatMapDeep';
import find from 'lodash-es/find';
import compact from 'lodash-es/compact';
import last from 'lodash-es/last';
import findIndex from 'lodash-es/findIndex';
import pick from 'lodash-es/pick';
import escapeStringRegexp from 'escape-string-regexp';
import memoize from 'lodash-es/memoize';
import { getAllowedFoldersForMove, getAllowedLocalFoldersForMove } from './mail-list';
import { setMoveableFolderFlag } from '../hooks/folders/utils';

import { USER_ROOT_FOLDER_ID, USER_FOLDER_IDS } from '../constants';
import {
	INBOX as DEFAULT_INBOX,
	DRAFTS,
	SENT,
	ARCHIVE,
	JUNK,
	TRASH,
	OUTBOX,
	BRIEFCASE,
	CONTACTS,
	FILES_SHARED_WITH_ME
} from '../constants/folders';

// special folders is used for both position and order
const SPECIAL_FOLDERS_UNFORMATTED = [
	DEFAULT_INBOX,
	DRAFTS,
	SENT,
	ARCHIVE,
	JUNK,
	BRIEFCASE,
	CONTACTS,
	TRASH,
	OUTBOX,
	FILES_SHARED_WITH_ME
];
const SPECIAL_FOLDERS = SPECIAL_FOLDERS_UNFORMATTED.map(folder => folder.toLowerCase());
export const INBOX = SPECIAL_FOLDERS[0];

const IGNORE_FOLDERS = ['USER_ROOT'];
const IGNORE_FOLDERS_ID = [USER_FOLDER_IDS.FILES_SHARED_WITH_ME.toString()];
const CANNOT_MOVE_INTO_FOLDERS = ['outbox', 'global address list'];
const CANNOT_MOVE_OUT_OF_FOLDERS = ['outbox'];

// Flags to specify actions allowed to be taken on mail items in a folder
export const MAIL_ITEM_ACTION_REPLY_FORWARD = 1;
export const MAIL_ITEM_ACTION_DELETE = 2;
export const MAIL_ITEM_ACTION_ARCHIVE = 4;
export const MAIL_ITEM_ACTION_MOVE = 8;
export const MAIL_ITEM_ACTION_SPAM = 16;
export const MAIL_ITEM_ACTION_EDIT = 32;
export const MAIL_ITEM_ACTION_MORE = 64;
export const MAIL_ITEM_ACTION_DEFAULT =
	MAIL_ITEM_ACTION_REPLY_FORWARD |
	MAIL_ITEM_ACTION_DELETE |
	MAIL_ITEM_ACTION_ARCHIVE |
	MAIL_ITEM_ACTION_MOVE |
	MAIL_ITEM_ACTION_SPAM |
	MAIL_ITEM_ACTION_MORE;

// Given a folderName, set the available actions for mail items in that folder.
const MAIL_ITEM_ACTIONS_RESTRICTION_FOLDERS = {
	drafts: MAIL_ITEM_ACTION_DELETE,
	sent: MAIL_ITEM_ACTION_DEFAULT & ~MAIL_ITEM_ACTION_SPAM, // all actions but not SPAM
	outbox: MAIL_ITEM_ACTION_DELETE | MAIL_ITEM_ACTION_EDIT
};

function hasBit(bit, mask) {
	return (mask & bit) !== 0;
}

export function canReplyForward(folderName) {
	return hasBit(MAIL_ITEM_ACTION_REPLY_FORWARD, getAllowedMailItemActions(folderName));
}

export function canDelete(folderName) {
	return hasBit(MAIL_ITEM_ACTION_DELETE, getAllowedMailItemActions(folderName));
}

export function canArchive(folderName) {
	return hasBit(MAIL_ITEM_ACTION_ARCHIVE, getAllowedMailItemActions(folderName));
}

export function canMove(folderName) {
	return hasBit(MAIL_ITEM_ACTION_MOVE, getAllowedMailItemActions(folderName));
}

export function canSpam(folderName) {
	return hasBit(MAIL_ITEM_ACTION_SPAM, getAllowedMailItemActions(folderName));
}

export function canEdit(folderName) {
	return hasBit(MAIL_ITEM_ACTION_EDIT, getAllowedMailItemActions(folderName));
}

export function canMore(folderName) {
	return hasBit(MAIL_ITEM_ACTION_MORE, getAllowedMailItemActions(folderName));
}

export function canMoveMailInto(folderName, folderPermissions) {
	return (
		folderName &&
		!CANNOT_MOVE_INTO_FOLDERS.includes(String(folderName).toLowerCase()) &&
		(!folderPermissions || folderPermissions.includes('i'))
	);
}

export function canMoveMailOutOf(folderName, folderPermissions) {
	return (
		folderName &&
		!CANNOT_MOVE_OUT_OF_FOLDERS.includes(String(folderName).toLowerCase()) &&
		folderPermissions !== 'r'
	);
}

export function canShareFolder({ id, local, permissions }) {
	const derivedId = id.split(':')[1] || id;
	return (
		!local &&
		![
			USER_FOLDER_IDS.ROOT,
			USER_FOLDER_IDS.TRASH,
			USER_FOLDER_IDS.JUNK,
			USER_FOLDER_IDS.TASKS,
			USER_FOLDER_IDS.CHAT
		].includes(parseInt(derivedId, 10)) &&
		(!permissions || permissions.includes('a')) // Folder not shared yet OR user is given ADMIN rights for that folder.
	);
}

export function hasWritePermOnFolder({ id, local, permissions }) {
	return (
		local ||
		(![
			USER_FOLDER_IDS.ROOT,
			USER_FOLDER_IDS.TRASH,
			USER_FOLDER_IDS.JUNK,
			USER_FOLDER_IDS.EMAILED_CONTACTS,
			USER_FOLDER_IDS.TASKS,
			USER_FOLDER_IDS.CHAT
		].includes(parseInt(id, 10)) &&
			(!permissions || permissions.includes('c'))) // Folder not shared yet OR user is given (READ + INSERT)/(CREATE-SUBFOLDER) rights for that folder.
	);
}

export function canMarkReadOnFolder({ id, permissions }) {
	return (
		![
			USER_FOLDER_IDS.ROOT,
			USER_FOLDER_IDS.EMAILED_CONTACTS,
			USER_FOLDER_IDS.TASKS,
			USER_FOLDER_IDS.CHAT
		].includes(parseInt(id, 10)) &&
		(!permissions || permissions.includes('c')) // Folder not shared yet OR user is given (READ + INSERT)/(CREATE-SUBFOLDER) rights for that folder.
	);
}
/**
 * Return a bit mask representing allowed operations for a given folder
 */
export function getAllowedMailItemActions(folderName) {
	return (
		(folderName && MAIL_ITEM_ACTIONS_RESTRICTION_FOLDERS[String(folderName).toLowerCase()]) ||
		MAIL_ITEM_ACTION_DEFAULT
	);
}

function baseFilter(folders) {
	return folders.filter(f => f.name && !IGNORE_FOLDERS.includes(f.name.toString().toLowerCase()));
}

export function isBriefcaseSharedFileFolder(folderId) {
	return folderId === USER_FOLDER_IDS.FILES_SHARED_WITH_ME.toString();
}

export function specialFolders(folders, specialFolderList = SPECIAL_FOLDERS) {
	return !(folders && folders.length)
		? []
		: specialFolderList.reduce((result, specialName) => {
				for (let i = folders.length; i--; ) {
					const folder = folders[i];
					if (!isBriefcaseSharedFileFolder(folder.id)) {
						if (
							typeof specialName === 'function'
								? specialName(folder)
								: folder.name && folder.name.toString().toLowerCase() === specialName
						) {
							result.push(folder);
							break;
						}
					}
				}
				return result;
		  }, []);
}

export function customFolders(folders, specialFolderList = SPECIAL_FOLDERS) {
	if (!folders) return [];
	return baseFilter(folders).filter(
		folder =>
			!specialFolderList.some(specialName =>
				typeof specialName === 'function'
					? specialName(folder)
					: folder.name && folder.name.toString().toLowerCase() === specialName
			)
	);
}

export function specialBriefcaseFileSharedFolder(folders) {
	if (!folders) return;
	return folders.find(folder => isBriefcaseSharedFileFolder(folder.id));
}
/**
 * Given a list of folders, return a list of folders that are allowed to have
 * messages moved into them
 */
export function canMoveMessagesIntoFolders(folders) {
	if (!folders) return [];
	return baseFilter(folders).filter(
		({ name, permissions, broken, id }) =>
			!broken && canMoveMailInto(name, permissions) && !IGNORE_FOLDERS_ID.includes(id)
	);
}

export function isChildFolder(rootFolder, folderId) {
	const queue = rootFolder.folder ? [...rootFolder.folder] : [];

	while (queue.length > 0) {
		const child = queue.shift();
		if (child.id === folderId) {
			return true;
		} else if (child.folder && child.folder.length > 0) {
			queue.push(...child.folder);
		}
	}

	return false;
}

/**
 * Find a folder recursively in a tree of Zimbra folders.
 */
export function findFolder(rootFolder, msgFolderId) {
	const queue = rootFolder.folders ? [...rootFolder.folders] : [...rootFolder];

	while (queue.length > 0) {
		const child = queue.shift();
		const { id, ownerZimbraId, sharedItemId } = child;

		if (msgFolderId === (ownerZimbraId && sharedItemId ? `${ownerZimbraId}:${sharedItemId}` : id)) {
			return child;
		} else if (child.folders && child.folders.length > 0) {
			queue.push(...child.folders);
		} else if (child.linkedFolders && child.linkedFolders.length > 0) {
			queue.push(...child.linkedFolders);
		}
	}

	return false;
}

export function isTopLevelFolder(folder) {
	return folder.parentFolderId && folder.parentFolderId.toString() === USER_ROOT_FOLDER_ID;
}

export function renamedFolderAbsPath(prevAbsPath, newName) {
	const parentFolders = prevAbsPath.split('/');
	parentFolders.shift(); // Leading slash
	parentFolders.pop(); // Current folder name
	return [...parentFolders, newName].join('/');
}

export function flattenFolders(folders) {
	return flatMapDeep(folders, f => [f, ...(f.folders ? flattenFolders(f.folders) : [])]);
}

export function flattenHabGroups(habGroups) {
	return flatMapDeep(habGroups, f => [f, ...(f.habGroups ? flattenHabGroups(f.habGroups) : [])]);
}

export function filteredFolders(folders, query) {
	if (!folders || query === '') {
		return [];
	}

	const regex = new RegExp(escapeStringRegexp(query), 'ig');

	return flattenFolders(folders).filter(f => f.name.toString().match(regex));
}

const FLAGS = {
	checked: '#',
	unchecked: ''
};

export function hasFlag(folder, flag) {
	const flags = folder.flags || folder.flag;
	return flags ? flags.indexOf(FLAGS[flag] || flag) > -1 : false;
}

/**
 * Mutate the flags on the folder to add the specified flag
 */
export function updateFlag(folder, flag) {
	if (FLAGS[flag]) {
		folder.flags = (folder.flags || '') + FLAGS[flag];
	} else {
		folder.flags = folder.flags && folder.flags.replace(FLAGS.checked, '');
	}
}

export function inFolder(currentFolder, name) {
	return currentFolder && currentFolder.name.toLowerCase() === name;
}

export function getFolderNameFunction(folderName) {
	return f => f.absFolderPath && f.absFolderPath.replace('/', '') === folderName;
}

export function getFolder(folders, fn) {
	return find(flattenFolders(folders), fn);
}

export function getHabGroup(habGroups, fn) {
	return find(flattenHabGroups(habGroups), fn);
}

export function getConversationFolder(folders, conversation) {
	if (!folders || !conversation.messages || conversation.messages.length === 0) {
		return null;
	}

	const messageFolders = compact(conversation.messages.map(m => findFolder(folders, m.folderId)));

	return last(canMoveMessagesIntoFolders(messageFolders)) || last(messageFolders);
}

export function isRenameAllowed(folderName) {
	return folderName && !~SPECIAL_FOLDERS.indexOf(folderName.toLowerCase());
}

export function sharedFolderDropKey(folders) {
	return folders.map(f => ({
		...f,
		droppable: f.permissions && f.permissions.includes('c'),
		folders: f.folders ? sharedFolderDropKey(f.folders) : null
	}));
}

/**
 *
 * @param {Array<Object>} foldersList The folders array which needs to be filtered recursively for the specified type
 * @param {String} viewType type of the view to filter the folders with
 * @returns {Array<Object>} filtered array of folders based on the viewType provided
 */
export const recursivelyFilterFolders = memoize((foldersList, viewType) => {
	if (!foldersList) return [];

	// filter the top level folders
	const filteredItems = foldersList.filter(f => !f.view || f.view === viewType);

	for (let idx = 0, len = filteredItems.length; idx < len; idx++) {
		const folder = filteredItems[idx];
		const { folders, linkedFolders } = folder;

		// For sub folders
		if (folders && folders.length) {
			folder.folders = recursivelyFilterFolders(folders, viewType);
		}

		// For linked/shared folders
		if (linkedFolders && linkedFolders.length) {
			folder.linkedFolders = recursivelyFilterFolders(linkedFolders, viewType);
		}
	}

	return filteredItems;
});

/**
 *
 * Find list of ids which needs to be expanded based on selectedIds.
 * i.e.
 * - Parent
 * 		+ Child1
 * 		- Child2
 * 			+ GrandChild1
 * 			+ GrandChild2
 *	if `selectedIds` contains id of `GrandChild1`, then `Parent > Child2 > GrandChild2` all 3 folders should be expanded.
 *	This method will return [Parent, Child2, GrandChild2].
 *
 * @param {Array<Object>} folder - Folders array which needs to be iterated
 * @param {Array<String>} selectedFolderIds - Selected Folder Ids
 * @param {*} tempFolderPath - Temp variable to store heirarchy of folder ids until next `selected` folder found
 * @param {*} allSelectedFolderPath - Final folder ids which needs to be expanded irrespective of heirarchy
 */
export function getPathOfSelectedFolders(folders, selectedFolderIds) {
	if (!folders) return;

	const paths = [];
	let parentPath = [];

	return (function deepCheck(folder) {
		if (!folder) return;

		folder.forEach(element => {
			if (selectedFolderIds.includes(element.id)) {
				paths.push(...parentPath);
				parentPath = [];
			}
			parentPath.push(element.id);

			if (element.folders) {
				deepCheck(element.folders);
			}
			parentPath.pop();
		});

		return paths;
	})(folders);
}

// POP account info if folder matches with folder ids
export function setExternalAccountInfo(folders, externalAccountInfo) {
	if (!(externalAccountInfo || []).length) return folders;

	externalAccountInfo.forEach(popAccount => {
		const linkedPOPFolderIndex = findIndex(folders, folder => folder.id === popAccount.l);
		if (linkedPOPFolderIndex >= 0) {
			folders[linkedPOPFolderIndex] = {
				...folders[linkedPOPFolderIndex],
				externalAccount: {
					...pick(popAccount, ['l', 'id']),
					accountType: 'pop3',
					accountName: popAccount.name
				}
			};
		}
	});

	return folders;
}

/**
 *
 * @param {Array} folders The user folders array
 * @param {Array} flags flags Array of all selected mails used for filtering
 * @param {Array} sharedFolders The shared folders array
 * @param {Array} localFolders The local folders array
 * @returns {Array} array of folders and folderGroups based on the provided params
 */
export function getMoveFolderItems(
	allFolders,
	flags,
	sharedFolders,
	localFolders,
	folderInLoading
) {
	let folders = canMoveMessagesIntoFolders(allFolders);
	let _specialFolders = specialFolders(folders);

	folders = folders.concat(canMoveMessagesIntoFolders(sharedFolders));

	// In case of Contacts, skip filtering droppable folders for move.
	if (flags) {
		folders = getAllowedFoldersForMove(flags, canMoveMessagesIntoFolders(folders)).filter(
			f => f.droppable
		);
		_specialFolders = specialFolders(folders);
	}

	const folderGroups = [customFolders(folders), _specialFolders];

	if (Array.isArray(localFolders) && localFolders.length > 0) {
		const localAllowedFolders = getAllowedLocalFoldersForMove(
			flags,
			canMoveMessagesIntoFolders(localFolders),
			folderInLoading
		).filter(f => f.droppable);

		folderGroups.push(localAllowedFolders);
		folders = folders.concat(localAllowedFolders);
	}

	return [folders, folderGroups];
}

export const getFoldersAndSharedFolders = data => ({
	folders: (data?.getFolder?.folders?.[0]?.folders || []).map(setMoveableFolderFlag),
	sharedFolders: (data?.getFolder?.folders?.[0]?.linkedFolders || []).map(setMoveableFolderFlag)
});
