import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import get from 'lodash-es/get';
import memoize from 'lodash-es/memoize';
import update from 'immutability-helper';

import { denormalizeDataSource } from './../../graphql-decorators/account-info/normalized-identities';
import TestExternalAccountMutation from '../../graphql/queries/accounts/account-test-external-mutation.graphql';
import AddExternalAccountMutation from '../../graphql/queries/accounts/account-add-external-mutation.graphql';
import ModifyExternalAccountMutation from '../../graphql/queries/accounts/account-modify-external-mutation.graphql';
import ImportExternalAccountMutation from '../../graphql/queries/accounts/account-import-external-mutation.graphql';
import GetImportStatus from '../../graphql/queries/accounts/account-external-account-status.graphql';
import GetDataSources from '../../graphql/queries/datasource/get-datasource.graphql';
import GetFolder from '../../graphql/queries/folders/get-folder.graphql';

export function withTestExternalAccount() {
	return graphql(TestExternalAccountMutation, {
		props: ({ mutate }) => ({
			testExternalAccount: externalAccount =>
				mutate({
					variables: {
						externalAccount
					}
				})
		})
	});
}

export function withAddExternalAccount() {
	return graphql(AddExternalAccountMutation, {
		props: ({ mutate }) => ({
			addExternalAccount: externalAccount =>
				mutate({
					variables: { externalAccount },
					refetchQueries: [
						{ query: GetDataSources },
						{ query: GetFolder, variables: { view: null } }
					]
				})
		})
	});
}

export function withModifyExternalAccount() {
	return compose(
		graphql(ModifyExternalAccountMutation, {
			props: ({ mutate }) => ({
				modifyExternalAccount: dataSource =>
					mutate({
						variables: {
							id: dataSource.id,
							type: dataSource.accountType,
							attrs: denormalizeDataSource(dataSource)
						},
						refetchQueries: [{ query: GetDataSources }],
						optimisticResponse: {
							__typename: 'Mutation',
							modifyExternalAccount: true
						},
						update: proxy => {
							const data = proxy.readQuery({ query: GetDataSources });
							const dataSources = get(data, `getDataSources.${dataSource.accountType}`);
							const index = dataSources.findIndex(dataObj => dataObj.id === dataSource.id);

							proxy.writeQuery({
								query: GetDataSources,
								data: update(data, {
									getDataSources: {
										[dataSource.accountType]: {
											[index]: {
												$set: dataSource
											}
										}
									}
								})
							});
						}
					})
			})
		})
	);
}

const memoizedImportExternalAccount = memoize(
	mutate => externalAccount =>
		mutate({
			variables: { externalAccount }
		})
);

export function withImportExternalAccountData() {
	return graphql(ImportExternalAccountMutation, {
		props: ({ mutate }) => ({
			importExternalAccount: memoizedImportExternalAccount(mutate)
		})
	});
}

export function getExternalAccountImportStatus(client) {
	return client.query({
		query: GetImportStatus,
		fetchPolicy: 'network-only'
	});
}
