import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'preact/hooks';
import moment from 'moment';
import cx from 'classnames';
import { Text } from 'preact-i18n';
import get from 'lodash-es/get';

import s from '../style.less';
import { callWith } from '@zimbra/util/src/call-with';
import { ChoiceInput } from '@zimbra/blocks';
import FormGroup from '../../../form-group';
import AlignedLabel from '../../../aligned-form/label';
import DateInput from '../../../date-input';
import SelectOption from '../../../select';
import CustomTimePicker from '../../../custom-time-picker';
import { getParsedAppointmentData } from '../../../../store/calendar/selectors';
import { setEvent } from '../../../../store/calendar/actions';
import { WEEK_DAYS } from '../../constants';
import { reCalculateStartEndDate } from '../../../../utils/invitation';
import update from 'immutability-helper';

export const AppointmentStartTime = ({
	isForwardInvite,
	zimbraPrefUseTimeZoneListInCalendar,
	eventStartTimeZone,
	momentTz,
	isProposeTime,
	tabId
}) => {
	const dispatch = useDispatch();
	const event = useSelector(state => getParsedAppointmentData(state, 'event', tabId));
	const eventData = useSelector(state => state?.calendar?.eventsData?.[tabId]);
	const { isCustomRecurringEvent, customByDayRule } = useSelector(state => ({
		isCustomRecurringEvent: getParsedAppointmentData(state, 'isCustomRecurringEvent', tabId),
		customByDayRule: getParsedAppointmentData(state, 'customByDayRule', tabId)
	}));

	const start = moment(event.start);
	const { allDay } = event;
	const disabledElement = (isForwardInvite || isProposeTime) && s.disabledElement;

	const handleStartChange = useCallback(
		(date, isValid = true) => {
			const stateToUpdate = {
				isFormDirty: true,
				isStartDateValid: isValid
			};

			if (isValid && isCustomRecurringEvent && customByDayRule) {
				const startDay = moment(date).isoWeekday();
				const byDayIndex = customByDayRule?.wkday.map(({ day }) => WEEK_DAYS.indexOf(day));

				// Don't recalculateStartEndDate if user selected date satisfy custom week selection
				if (byDayIndex.length && !byDayIndex.includes(startDay)) {
					const { start: newStart, end: newEnd } = reCalculateStartEndDate(
						event.start,
						event.end,
						customByDayRule?.wkday,
						date
					);
					dispatch(
						setEvent({
							tabId,
							eventData: update(eventData, {
								event: {
									start: { $set: newStart },
									end: { $set: newEnd }
								}
							})
						})
					);
					return;
				}
			}

			if (isValid) {
				const diff = moment(event.start).diff(date);
				stateToUpdate.event = {
					...event,
					start: date,
					end: moment(event.end).subtract(diff).toDate().setSeconds(0)
				};
			}

			dispatch(
				setEvent({
					tabId,
					eventData: stateToUpdate
				})
			);
		},
		[customByDayRule, dispatch, event, eventData, isCustomRecurringEvent, tabId]
	);

	const onEventTimeZoneChange = useCallback(
		(zone, changeEvent) => {
			const TzValue = changeEvent && get(changeEvent, 'target.value');

			dispatch(
				setEvent({
					tabId,
					eventData: {
						eventEndTimeZone: TzValue,
						isFormDirty: true,
						...(zone === 'start' && {
							eventStartTimeZone: TzValue
						})
					}
				})
			);
		},
		[dispatch, tabId]
	);

	const handleAllDayChange = useCallback(
		({ target: { checked } }) => {
			dispatch(
				setEvent({
					tabId,
					eventData: {
						event: {
							...event,
							allDay: checked
						},
						isFormDirty: true
					}
				})
			);
		},
		[dispatch, event, tabId]
	);

	return (
		<FormGroup>
			<AlignedLabel class={s.alignedLabel} align="left" textId="calendar.editModal.fields.start" />
			<div class={s.datepickerWrapper}>
				<DateInput
					class={s.dateSelector}
					dateValue={event.start}
					onDateChange={handleStartChange}
					disabled={isForwardInvite}
				/>
				<CustomTimePicker
					value={allDay ? start.startOf('day') : event.start}
					disabled={allDay}
					onDateChange={handleStartChange}
				/>
				{zimbraPrefUseTimeZoneListInCalendar && (
					<div class={s.timeZoneSelector}>
						<SelectOption
							value={eventStartTimeZone || (momentTz && momentTz.tz.guess())}
							disabled={allDay || isForwardInvite || isProposeTime}
							onChange={callWith(onEventTimeZoneChange, 'start', true)}
						>
							{momentTz &&
								momentTz.tz.names().map(TimeZone => <option value={TimeZone}>{TimeZone}</option>)}
						</SelectOption>
					</div>
				)}
				<label class={cx(s.allDay, disabledElement)}>
					<ChoiceInput
						checked={allDay}
						onChange={handleAllDayChange}
						disabled={isForwardInvite || isProposeTime}
					/>
					<Text id="calendar.editModal.fields.allDay" />
				</label>
			</div>
		</FormGroup>
	);
};
