import { Fragment } from 'preact';
import { PureComponent } from 'preact/compat';
import { Icon } from '@zimbra/blocks';
import cx from 'classnames';
import { displayAddress } from '../../utils/contacts';
import { ContactHoverCardWrapper } from '../contact-card-wrapper';
import style from './style';
import { withPropsOnChange, branch, compose } from 'recompose';
import { getSMimePublicCerts } from '../../graphql-decorators/smime/public-certs';
import { smimeHandler } from '@zimbra/electron-app';
import ZimletSlot from '../zimlet-slot';

@branch(
	() => typeof process.env.ELECTRON_ENV !== 'undefined',
	compose(
		withPropsOnChange(
			['contact'],
			({ contact: { isCertificateExpired, publicCertObject, address } }) => ({
				invalidCert:
					isCertificateExpired ||
					(publicCertObject && address !== publicCertObject?.subject?.email),
				publicCertObject,
				verifiedStatusText:
					publicCertObject &&
					((isCertificateExpired && 'expiredText') ||
						(address !== publicCertObject?.subject?.email && 'emailMismatchText') ||
						'verifiedText')
			})
		),
		getSMimePublicCerts()
	)
)
export default class ContactInputToken extends PureComponent {
	state = {
		isDragOverOnPill: false,
		editing: false,
		contactName:
			this.props.contact.name || this.props.contact.shortName || displayAddress(this.props.contact)
	};

	prevent = e => {
		e.preventDefault();
		e.stopPropagation();
		return false;
	};

	handleDragOver = e => {
		e.preventDefault();
		if (!this.timer) {
			// Create the timer to avoid unwanted re-rendering of the token and increase performance
			this.timer = setTimeout(
				() =>
					!this.props.isSelected &&
					!this.state.isDragOverOnPill &&
					this.setState({ isDragOverOnPill: true }),
				10
			);
		}
	};

	handleDragLeave = e => {
		e.preventDefault();
		if (this.timer) {
			// Remove timer after mouse leaves the drop area.
			clearTimeout(this.timer);
			delete this.timer;
			this.state.isDragOverOnPill && this.setState({ isDragOverOnPill: false });
		}
	};

	handleDoubleClick = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState(
			{
				editing: true
			},
			() => e.target.focus()
		);
	};

	handleBlur = e => {
		const contactValue = e.target.innerHTML;
		const { contact, updateExistingContact } = this.props;
		this.setState({ editing: false, contactName: displayAddress({ email: contactValue }) });
		const contactNameValue = contact.name || displayAddress(contact);
		if (contactValue !== contactNameValue) {
			updateExistingContact(contactValue, contact);
		}
	};

	parseSmimeCert = ({ publicCert, onDataChange, addressFieldType, contact }) => {
		if (smimeHandler) {
			if (publicCert) {
				smimeHandler({
					operation: 'get-cert',
					certData: publicCert
				})
					.then(({ certificate, isExpired }) => {
						onDataChange &&
							onDataChange(
								{
									...contact,
									publicCert,
									publicCertObject: certificate,
									isCertificateExpired: isExpired
								},
								addressFieldType
							);
					})
					.catch(err => console.error(err));
			} else {
				onDataChange &&
					onDataChange(
						{
							...contact,
							publicCert: null,
							publicCertObject: null,
							isCertificateExpired: false
						},
						addressFieldType
					);
			}
		}
	};

	componentDidMount() {
		if (typeof process.env.ELECTRON_ENV !== 'undefined') {
			this.parseSmimeCert(this.props);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (
			typeof process.env.ELECTRON_ENV !== 'undefined' &&
			this.props.publicCert !== nextProps.publicCert
		) {
			this.parseSmimeCert(nextProps);
		}
	}

	render(
		{
			contact,
			onKeyDown,
			isSelected,
			activated,
			invalid,
			invalidCert,
			publicCertObject,
			verifiedStatusText,
			onDragStart,
			draggable,
			onDragEnter,
			onDragEnd,
			onMouseDown,
			addTokensRef
		},
		{ hover, details, isDragOverOnPill, editing }
	) {
		const name = contact.name || contact.shortName || displayAddress(contact),
			active = hover || details || activated;

		const recipient = (
			<Fragment>
				<span
					class={cx(
						style.token,
						isSelected && style.selected,
						active && style.active,
						invalid && style.invalid,
						isDragOverOnPill && style.insertionPoint
					)}
					onKeyDown={onKeyDown}
					onMouseDown={onMouseDown}
					draggable={draggable}
					onDragStart={onDragStart}
					onDragEnter={onDragEnter}
					onDragEnd={onDragEnd}
					onDragOver={this.handleDragOver}
					onDragLeave={this.handleDragLeave}
					onDrop={this.handleDragLeave}
				>
					<ZimletSlot name="address-pill" email={contact.address} />

					<div class={style.tokenLabel} ref={addTokensRef}>
						{publicCertObject && (
							<Icon
								size="sm"
								class={cx(style.smimePubCertShieldIcon, invalidCert && style.expiredCert)}
								name="verified"
							/>
						)}
						<button
							contentEditable={editing}
							onDoubleClick={this.handleDoubleClick}
							onContextMenu={this.prevent}
							onBlur={this.handleBlur}
						>
							{editing ? contact.address : name}
						</button>
					</div>
				</span>
				<span class={style.tokenMidSpace} />
			</Fragment>
		);

		return !invalid ? (
			<ContactHoverCardWrapper
				address={contact.address || contact.email}
				contact={contact}
				name={name}
				visible={hover}
				onDismiss={this.closeDetails}
				target={recipient}
				invalidCert={invalidCert}
				verifiedStatusText={verifiedStatusText}
			/>
		) : (
			recipient
		);
	}
}
