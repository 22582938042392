import isEmpty from 'lodash-es/isEmpty';
import { SORT_BY } from '../constants/search';

/**
 * Given two mail items, sort them based on the given sort value
 * Useful for callback to Array.prototype.sort
 * @param {String} sort    A sort by value ('dateDesc', 'dateAsc')
 * @param {Object} a       The left mail item to sort
 * @param {Object} b       The right mail item to sort
 * @example
 *   messages.sort((a, b) => doMailSort('dateDesc', a, b))
 *   messages.sort(doMailSort.bind(null, 'dateDesc'))
 */
export function doMailSort(sort, a, b) {
	switch (sort) {
		case SORT_BY.dateAsc:
			return a.date - b.date;
		case SORT_BY.dateDesc:
		default:
			return b.date - a.date;
	}
}

/**
 * Convert a Zimbra search query to a lunr.js search query
 */
export function zimbraQueryToLunrQuery(query) {
	// TODO: This needs to be made much more robust to make offline search decent.
	return query && query.replace('has:attachment', 'attachment:true');
}

export function getSearchQueryString(props) {
	const {
		query: q,
		email,
		folderForSearch,
		subject,
		contains,
		filename,
		before,
		after,
		date,
		from,
		to,
		folder,
		under,
		size,
		tag,
		status,
		cc,
		hasAttachment,
		hasRefine,
		hasImage,
		hasPdf,
		hasDoc,
		hasSlides,
		hasSheet,
		hasMovie,
		hasCompressed,
		hasApplication,
		hasHtml,
		hasMailMessage,
		hasAudio,
		hasTextCalendar,
		sharedFolders,
		isOffline
	} = props;
	let query = [q, email].filter(Boolean).join(' ');
	let orValue = ' ';
	const qFolder = folder || folderForSearch;
	query = qFolder && !isEmpty(qFolder) ? query + ` in:"${qFolder}"` : query;

	if (
		q !== '' &&
		!qFolder &&
		isEmpty(qFolder) &&
		sharedFolders &&
		!isEmpty(sharedFolders) &&
		!isOffline
	) {
		const qSharedFolders =
			sharedFolders
				.map(sharedFolder => {
					return sharedFolder.id.includes(':')
						? `inid:${sharedFolder.userId}`
						: `inid:${sharedFolder.id}`;
				})
				.join(' OR ') + ' OR is:local';
		query += ` (${qSharedFolders})`;
	}

	if (subject) {
		const decoded = decodeURI(subject);
		query += ` subject:"${decoded}"`;
		orValue = ' OR ';
	}

	if (contains) {
		const decoded = decodeURI(contains);
		query += `${orValue}content:"${decoded}"`;
	}

	if (!isEmpty(after)) {
		const decodedAfter = decodeURI(after);
		query += ` after:"${decodedAfter}"`;
	}

	if (!isEmpty(before)) {
		const decodedBefore = decodeURI(before);
		query += ` before:"${decodedBefore}"`;
	}

	if (!isEmpty(date)) {
		const decodedDate = decodeURI(date);
		query += ` date:"${decodedDate}"`;
	}

	if (hasAttachment === 'true') {
		query += ' has:attachment';
		orValue = ' OR ';
	}

	if (hasImage === 'true') {
		query += `${orValue}attachment:image/*`;
		orValue = ' OR ';
	}

	if (hasPdf === 'true') {
		query += `${orValue}attachment:application/pdf`;
		orValue = ' OR ';
	}

	if (hasDoc === 'true') {
		query += `${orValue}(type:doc OR type:dot OR type:wbk OR type:docx OR type:docm OR type:dotx OR type:dotm OR type:docb OR type:odt OR type:ott OR type:odm OR attachment:text OR attachment:word)`;
		orValue = ' OR ';
	}

	if (hasSlides === 'true') {
		query += `${orValue}(type:odp OR type:otp OR type:ppt OR type:pot OR type:pps OR type:pptx OR type:pptm OR type:potx OR type:potm OR type:ppam OR type:ppsx OR type:sldx OR type:sldm)`;
		orValue = ' OR ';
	}

	if (hasSheet === 'true') {
		query += `${orValue}(type:ods OR type:ots OR type:xls OR type:xlt OR type:xlm OR type:xlsx OR type:xlsm OR type:xltx OR type:xltm OR type:xlsb OR type:csv)`;
		orValue = ' OR ';
	}

	if (hasMovie === 'true') {
		query += `${orValue}attachment:video`;
		orValue = ' OR ';
	}

	if (hasCompressed === 'true') {
		query += `${orValue}(attachment:application/x-zip-compressed OR attachment:application/zip OR type:tgz OR type:rar OR type:tar)`;
		orValue = ' OR ';
	}

	if (hasApplication === 'true') {
		query += `${orValue}attachment:application/*`;
		orValue = ' OR ';
	}

	if (hasHtml === 'true') {
		query += `${orValue}attachment:text/html`;
		orValue = ' OR ';
	}

	if (hasMailMessage === 'true') {
		query += `${orValue}attachment:message/rfc822`;
		orValue = ' OR ';
	}

	if (hasAudio === 'true') {
		query += `${orValue}attachment:audio`;
		orValue = ' OR ';
	}

	if (hasTextCalendar === 'true') {
		query += `${orValue}attachment:text/calendar`;
		orValue = ' OR ';
	}

	if (filename) {
		const decoded = decodeURI(filename);
		if (hasAttachment === 'true') {
			query += ' OR';
		}

		query += ` filename:"${decoded}"`;
	}

	if (under) {
		const decoded = decodeURI(under);
		query += ` under:"${decoded}"`;
	}

	if (size) {
		const decoded = decodeURI(size);
		query += ` size:"${decoded}"`;
	}

	if (from) {
		const fromQuery = decodeURI(from)
			.split(',')
			.map(address => `from:${address}`)
			.join(' OR ');
		query = `${query} ${fromQuery}`;
	}

	if (to) {
		const toQuery = decodeURI(to)
			.split(',')
			.map(address => `to:${address}`)
			.join(' OR ');
		query = `${query} ${toQuery}`;
	}

	if (cc) {
		const ccQuery = decodeURI(cc)
			.split(',')
			.map(address => `cc:${address}`)
			.join(' OR ');
		query = `${query} ${ccQuery}`;
	}

	if (tag) {
		const tagQuery = decodeURI(tag)
			.split(',')
			.map(t => `tag:${t.indexOf(' ') >= 0 ? `"${t}"` : t}`)
			.join(' ');
		query = `${query} ${tagQuery}`;
	}

	if (status) {
		const statusQuery = decodeURI(status)
			.split(',')
			.map(f => `is:${f}`)
			.join(' ');
		query = `${query} ${statusQuery}`;
	}

	if (q !== '' && hasRefine) {
		query += ' OR has:refine';
	}

	return query.trim();
}

function getOptions(searchQuery, option, addressOption = false) {
	const tempOption = searchQuery.match(new RegExp(`${option}(("(.*?)")|(\\S*))`, 'g'));
	if (addressOption) {
		return tempOption.length && tempOption.map(f => f.replace(option, '').replace(/"/g, ''));
	}

	return (
		tempOption.length &&
		tempOption.map(f => f.replace(new RegExp(option, 'g'), '').replace(/"/g, '')).toString()
	);
}

export function getQueryOptions(searchQuery) {
	const newOpts = {
		subject: '',
		contains: '',
		before: '',
		after: '',
		date: '',
		folder: '',
		dateTypeValue: 'anytime',
		from: '',
		to: '',
		cc: ''
	};

	if (searchQuery.match(' has:refine')) {
		newOpts.hasRefine = true;

		let from, to, cc;
		const query = searchQuery.replace(/^(.*?)( OR | \w+?:).*/g, '$1');

		if (searchQuery.match(/.*(in:")(.*?)".*/)) {
			newOpts.activeFolder = searchQuery.replace(/.*(in:")(.*?)".*/g, '$2');
		}

		if (searchQuery.match(' subject:')) {
			newOpts.subject = getOptions(searchQuery, 'subject:');
		}

		if (searchQuery.match(' content:')) {
			newOpts.contains = getOptions(searchQuery, 'content:');
		}

		if (searchQuery.match(' after:')) {
			newOpts.after = getOptions(searchQuery, 'after:');
			newOpts.dateTypeValue = 'sentAfter';
		}

		if (searchQuery.match(' before:')) {
			newOpts.before = getOptions(searchQuery, 'before:');
			newOpts.dateTypeValue = 'sentBefore';
		}

		if (searchQuery.match(' date:')) {
			newOpts.date = getOptions(searchQuery, 'date:');
			newOpts.dateTypeValue = 'sentOn';
		}

		if (searchQuery.match('has:attachment')) {
			newOpts.hasAttachment = 'true';
		}

		if (searchQuery.match('attachment:image')) {
			newOpts.hasImage = 'true';
		}

		if (searchQuery.match('attachment:application/pdf')) {
			newOpts.hasPdf = 'true';
		}

		if (
			searchQuery.match(
				'type:doc OR type:dot OR type:wbk OR type:docx OR type:docm OR type:dotx OR type:dotm OR type:docb OR type:odt OR type:ott OR type:odm OR attachment:text'
			)
		) {
			newOpts.hasDoc = 'true';
		}

		if (
			searchQuery.match(
				'type:odp OR type:otp OR type:ppt OR type:pot OR type:pps OR type:pptx OR type:pptm OR type:potx OR type:potm OR type:ppam OR type:ppsx OR type:sldx OR type:sldm'
			)
		) {
			newOpts.hasSlides = 'true';
		}

		if (
			searchQuery.match(
				'type:ods OR type:ots OR type:xls OR type:xlt OR type:xlm OR type:xlsx OR type:xlsm OR type:xltx OR type:xltm OR type:xlsb OR type:csv'
			)
		) {
			newOpts.hasSheet = 'true';
		}

		if (searchQuery.match('attachment:video')) {
			newOpts.hasMovie = 'true';
		}

		if (
			searchQuery.match(
				'attachment:application/x-zip-compressed OR attachment:application/zip OR type:tgz OR type:rar OR type:tar'
			)
		) {
			newOpts.hasCompressed = 'true';
		}

		if (searchQuery.match('attachment:application/*')) {
			newOpts.hasApplication = 'true';
		}

		if (searchQuery.match('attachment:text/html')) {
			newOpts.hasHtml = 'true';
		}

		if (searchQuery.match('attachment:message/rfc822')) {
			newOpts.hasMailMessage = 'true';
		}

		if (searchQuery.match('attachment:audio')) {
			newOpts.hasAudio = 'true';
		}

		if (searchQuery.match('attachment:text/calendar')) {
			newOpts.hasTextCalendar = 'true';
		}

		if (searchQuery.match(' filename:')) {
			newOpts.hasAttachment = 'true';
			newOpts.filename = getOptions(searchQuery, 'filename:');
		}

		if (searchQuery.match(' in:')) {
			newOpts.folder = getOptions(searchQuery, 'in:');
		}

		if (searchQuery.match(' under:')) {
			newOpts.under = getOptions(searchQuery, 'under:');
		}

		if (searchQuery.match(' size:')) {
			newOpts.size = getOptions(searchQuery, 'size:');
		}

		if (searchQuery.match(' tag:')) {
			newOpts.tag = getOptions(searchQuery, 'tag:');
		}

		if (searchQuery.match(' is:')) {
			newOpts.status = getOptions(searchQuery, 'is:');
		}

		if (searchQuery.match(' from:')) {
			from = getOptions(searchQuery, 'from:', true);
		}

		if (searchQuery.match(' cc:')) {
			cc = getOptions(searchQuery, 'cc:', true);
		}

		if (searchQuery.match(' to:')) {
			to = getOptions(searchQuery, 'to:', true);
		}

		if (newOpts.before && newOpts.after) {
			const before = new Date(newOpts.before);
			const after = new Date(newOpts.after);
			const timeDiff = Math.abs(after.getTime() - before.getTime());
			const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

			switch (diffDays) {
				case 7:
					newOpts.dateTypeValue = 'last7';
					break;
				case 30:
					newOpts.dateTypeValue = 'last30';
					break;
				default:
					newOpts.dateTypeValue = 'customDate';
			}
		}

		newOpts.query = query.trim();
		newOpts.from = from && from.length ? from.join() : '';
		newOpts.to = to && to.length ? to.join() : '';
		newOpts.cc = cc && cc.length ? cc.join() : '';
	} else {
		newOpts.query = searchQuery.trim();
	}

	return newOpts;
}

export function getQuery(searchQuery) {
	if (searchQuery.match(' has:refine')) {
		return searchQuery.replace(/^(.*?)( OR | \w+?:).*/g, '$1');
	}
	return searchQuery.trim();
}
