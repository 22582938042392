import { Component } from 'preact';
import { callWith } from '../../../lib/util';
import ActionMenu, { DropDownWrapper } from '../../action-menu';
import ActionMenuGroup from '../../action-menu-group';
import ActionMenuItem from '../../action-menu-item';
import { withText } from 'preact-i18n';
import { removeAttrSuffix } from './helper';
import style from '../style';

@withText(props =>
	props.dropdownLabels.reduce(
		(result, label) => ({
			...result,
			[label]: `contacts.edit.${`dropdown.${props.group || 'fields'}`}.${removeAttrSuffix(label)}`
		}),
		{}
	)
)
export default class DropdownLabel extends Component {
	changeLabel = value => {
		const { attribute, group } = this.props;
		this.props.onFieldLabelChange({
			newLabel: value,
			originalLabel: attribute,
			group
		});
	};

	render({ dropdownLabels, nonSuffixedAttribute }) {
		return (
			<ActionMenu
				label={this.props[nonSuffixedAttribute]}
				title={this.props[nonSuffixedAttribute]}
				anchor="end"
				actionButtonClass={style.addFieldDropdown}
				placement="right"
			>
				<DropDownWrapper>
					<ActionMenuGroup>
						{dropdownLabels.map(label => (
							<ActionMenuItem
								icon={nonSuffixedAttribute === label && 'check'}
								onClick={callWith(this.changeLabel, label)}
							>
								{this.props[label]}
							</ActionMenuItem>
						))}
					</ActionMenuGroup>
				</DropDownWrapper>
			</ActionMenu>
		);
	}
}
