import { handleActions } from 'redux-actions';
import * as actionCreators from './actions';

const initialState = {
	visible: false,
	activeId: null,
	keyboardShortcutsVisible: false,
	settingsTrackerData: new Map()
};

export default handleActions(
	{
		[actionCreators.show]: state => ({
			...state,
			visible: true
		}),
		[actionCreators.hide]: state => ({
			...state,
			visible: false
		}),
		[actionCreators.toggle]: state => ({
			...state,
			visible: !state.visible
		}),
		[actionCreators.setActiveId]: (state, action) => ({
			...state,
			activeId: action.payload
		}),
		[actionCreators.showKeyboardShortcuts]: state => ({
			...state,
			keyboardShortcutsVisible: true
		}),
		[actionCreators.hideKeyboardShortcuts]: state => ({
			...state,
			keyboardShortcutsVisible: false
		}),
		[actionCreators.toggleKeyboardShortcuts]: state => ({
			...state,
			keyboardShortcutsVisible: !state.keyboardShortcutsVisible
		}),
		[actionCreators.resetSettingsTrackerData]: state => ({
			...state,
			settingsTrackerData: new Map()
		}),
		[actionCreators.setAccountChangesTrackerData]: (state, action) => {
			if (!state.settingsTrackerData.has('save-account-changes')) {
				state.settingsTrackerData.set('save-account-changes', {});
			}
			const { key, value } = action.payload;
			state.settingsTrackerData.get('save-account-changes')[key] = value;
			return {
				...state,
				settingsTrackerData: state.settingsTrackerData
			};
		}
	},
	initialState
);
