import { Text } from 'preact-i18n';
import moment from 'moment';
import cx from 'classnames';
import { callWith } from '../../../../lib/util';
import { Icon } from '@zimbra/blocks';
import NakedButton from '../../../naked-button';

import style from './style';

export default function AppSpecificPasswordsList({ appPasswords, removeApp }) {
	return (
		<div class={style.appPasswordsList}>
			<div class={cx(style.row, style.header)}>
				<div class={style.col}>
					<Text id="settings.accounts.editAccount.twoFactorAuth.appSpecificPasswords.list.appNameCol" />
				</div>
				<div class={style.col}>
					<Text id="settings.accounts.editAccount.twoFactorAuth.appSpecificPasswords.list.appLastUsedCol" />
				</div>
			</div>
			<div class={style.body}>
				{appPasswords.map(({ appName, lastUsed }) => (
					<div key={`appPwd-${appName}`} class={style.row}>
						<div class={style.col}>{appName}</div>
						<div class={style.col}>
							<span>{lastUsed ? moment(lastUsed).format('MMMM D, Y') : '-'}</span>
							<NakedButton class={style.removeAppBtn} onClick={callWith(removeApp, appName)}>
								<Icon name="close" />
							</NakedButton>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
