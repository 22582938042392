import { createContext } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { getBasePath } from '../../lib/util';
import isEmpty from 'lodash-es/isEmpty';
import ConfigureClient from '../../configure-client';
import merge from 'lodash-es/merge';

const cache = {};

// Constant for default client
export const DEFAULT_CLIENT = 'default';

/**
 * A function that returns URL of passed svg name specific to client
 * @param {string} client
 */
function getResourceURLByClient(client, version) {
	return function (name) {
		return `${getBasePath()}/clients/${client}/${name}?v=${version}`; // This is to bust cache
	};
}

/**
 * A function that returns URL of passed assets specific to client
 * @param {string} client
 */
function getAssetURLByClient(client, version) {
	return function (name) {
		return `${getBasePath()}/clients/${client}/assets/${name}?v=${version}`;
	};
}

/**
 * A function that returns URL of passed svg name specific to client
 * @param {string} client
 */
function getIconURLByClient(client, version) {
	return function (name) {
		return `${getBasePath()}/clients/${client}/assets/icons/${name}.svg?v=${version}`;
	};
}

/**
 * A function that returns URL of passed resource name specific to client
 * @param {string} client
 */
function getPWAResourceByClient(client, version) {
	return function (name) {
		return `${getBasePath()}/clients/${client}/pwa/${name}?v=${version}`; // This is to bust cache
	};
}

/**
 * A function that returns URL of passed resource name specific to theme
 * Note: Currently, theme is inside client folder but later we can move theme from client and will tread it independently
 * @param {string} client
 */
function getThemeResourceURL(theme, version) {
	return function (name) {
		// TODO: return path will be replaced with following url once theme is implemented
		// `${getBasePath()}/themes/${theme}/${name}?v=${version}`;
		return `${getBasePath()}/clients/${theme}/${name}?v=${version}`; // This is to bust cache
	};
}

/**
 * A function to load resources specific to client
 */
function loadClientResources(derivedClient, domain) {
	let defaultClientPromise, derivedClientPromise, domainClientPromise;

	if (typeof process.env.ELECTRON_ENV !== 'undefined') {
		defaultClientPromise = import(`../../../clients/default/config.json`).then(d => d.default);

		derivedClientPromise = import(`../../../clients/${derivedClient}/config.json`)
			.then(d => d.default)
			.catch(() => {});
	} else {
		defaultClientPromise =
			cache[DEFAULT_CLIENT] ||
			fetch(`${getBasePath()}/clients/default/config.json`).then(d => d.ok && d.json());

		derivedClientPromise =
			cache[derivedClient] ||
			fetch(`${getBasePath()}/clients/${derivedClient}/config.json?t=${Date.now()}`)
				.then(d => (d.ok ? d.json() : {}))
				.catch(() => {});

		domainClientPromise = domain
			? cache[domain] ||
			  fetch(`${getBasePath()}/clients/${domain}/config.json?t=${Date.now()}`)
					.then(d => (d.ok ? d.json() : {}))
					.catch(() => {})
			: Promise.resolve();
	}

	return Promise.all([defaultClientPromise, derivedClientPromise, domainClientPromise]).then(
		config => {
			cache[DEFAULT_CLIENT] = config[0];
			cache[derivedClient] = config[1];
			cache[domain] = config[2];

			// Merge config of default and specific client
			let client;
			let theme;

			if (!isEmpty(config[2])) {
				client = theme = domain;
			} else if (!isEmpty(config[1])) {
				client = theme = derivedClient;
			} else {
				client = theme = DEFAULT_CLIENT;
			}

			const data = merge({}, config[0], config[1], config[2], { client, theme });

			const { version } = data;

			const clientConfig = {
				data,
				getDefaultAssetURL: getAssetURLByClient(DEFAULT_CLIENT, version),
				getDefaultIconURL: getIconURLByClient(DEFAULT_CLIENT, version),
				getAssetURLByClient: getAssetURLByClient(client, version),
				getIconURLByClient: getIconURLByClient(client, version),
				getResourceURLByClient: getResourceURLByClient(client, version),
				getPWAResourceByClient: getPWAResourceByClient(client, version),
				getThemeResourceURL: getThemeResourceURL(theme, version)
			};

			return clientConfig;
		}
	);
}

export const clientConfig = createContext();

export const ClientConfigProvider = ({ derivedClient, domain, children }) => {
	const [data, setData] = useState(null);

	useEffect(() => {
		loadClientResources(derivedClient, domain)
			.then(d => setData(d))
			.catch(e => {
				console.error('Error while loading application configuration!', e);
			});
	}, [derivedClient, domain]);

	const { Provider } = clientConfig;
	return <Provider value={data}>{data && [<ConfigureClient />, children]}</Provider>;
};

export default clientConfig;
