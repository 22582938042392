import { PureComponent } from 'preact/compat';
import { DEFAULT_LOCALE } from '../constants/locale';
import { setTemplate } from '../store/template/actions';
import { connect } from 'react-redux';

/**
 * Get template for localization and store it to context.
 */
export default function withTemplate() {
	return function (Child) {
		@connect(null, { setTemplate })
		class TemplateLoader extends PureComponent {
			// Import custom template for localization
			importTemplate = locale =>
				import(
					/* webpackMode: "lazy", webpackChunkName: "zimbra-locales/locale-template-[request]" */
					`../intl/template/${locale}.json`
				)
					.then(({ default: template }) => template)
					.catch(err => {
						// No error is thrown when custom template is not loaded
						if (locale === DEFAULT_LOCALE) {
							throw err;
						}
					});

			loadLocale = ({ locale, defaultLocale }) => {
				const allPromises = [];

				// Load en_US locale which will be used as fallback strings,
				// when particular locale doesn't have all strings.
				// Default locale must always be loaded unlike IntlWrapper.
				allPromises.push(this.importTemplate(defaultLocale));
				// If passed locale is same as default locale then no need to do anything extra
				if (locale !== defaultLocale) {
					allPromises.push(this.importTemplate(locale));
				}

				Promise.all(allPromises)
					.then(([defaultTemplate, customTemplate = {}]) => {
						this.props.setTemplate(deepAssign(customTemplate, defaultTemplate));
					})
					.catch(err => {
						console.error(`Error loading default template for ${locale}`, err);
					});
			};

			componentDidMount() {
				this.loadLocale(this.props);
			}

			componentWillReceiveProps(nextProps) {
				const { locale: nextLocale } = nextProps,
					{ locale } = this.props;

				// check if the active locale has changed, then fetch & apply the corresponding locale data.
				if (nextLocale !== locale) {
					this.loadLocale(nextProps);
				}
			}

			render(props) {
				return <Child {...props} />;
			}
		}

		return TemplateLoader;
	};
}

/** A simpler Object.assign
 *  Reference: https://github.com/synacor/preact-i18n
 *  @private
 */
function assign(obj, props) {
	for (const i in props) {
		if (Object.prototype.hasOwnProperty.call(props, i)) {
			obj[i] = props[i];
		}
	}
	return obj;
}

/** Recursively copy keys from `source` to `target`, skipping truthy values already in `target`.
 *  Based on https://github.com/synacor/preact-i18n
 *	@private
 */
function deepAssign(target, source) {
	const out = assign({}, target);
	for (const i in source) {
		if (Object.prototype.hasOwnProperty.call(source, i)) {
			if (target[i] && source[i] && Array.isArray(target[i]) && Array.isArray(source[i])) {
				continue;
			} else if (
				target[i] &&
				source[i] &&
				typeof target[i] === 'object' &&
				typeof source[i] === 'object'
			) {
				out[i] = deepAssign(target[i], source[i]);
			} else {
				out[i] = target[i] || source[i];
			}
		}
	}
	return out;
}
