import isEqual from 'lodash-es/isEqual';

/**
 * Compares the properties of the two objects shallowly.
 *
 * @export
 * @param {Object} a first object to be compared
 * @param {Object} b second object to be compared
 * @returns {Boolean}
 */
export function shallowEqual(a, b) {
	let i;
	for (i in a) if (a[i] !== b[i]) return false;
	for (i in b) if (!(i in a)) return false;
	return true;
}

export function findDiff(a, b) {
	const diff = {};
	let i;
	for (i in a) {
		if (a[i] !== b[i]) {
			diff[`${i}1`] = a[i];
			diff[`${i}2`] = b[i];
		}
	}
	for (i in b) {
		if (!(i in a)) {
			diff[`${i}1`] = a[i];
			diff[`${i}2`] = b[i];
		}
	}
	return diff;
}
/**
 * Finds the differences between the specified objects.
 *
 * @export
 * @param {Object} obj1 first object
 * @param {Object} obj2 second object
 * @returns {Array<String>} names of the properties that are different
 */
export function getObjectDiff(obj1, obj2) {
	const diff = Object.keys(obj1).reduce((result, key) => {
		if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
			result.push(key);
		} else if (isEqual(obj1[key], obj2[key])) {
			const resultKeyIndex = result.indexOf(key);
			result.splice(resultKeyIndex, 1);
		}
		return result;
	}, Object.keys(obj2));

	return diff;
}
