import { generateOfflineId } from './offline';

export default function newMessageDraft() {
	return {
		attachments: [],
		bcc: [],
		cc: [],
		flags: 'd',
		subject: '',
		to: [],
		from: [],
		sender: [],
		draftId: generateOfflineId()
	};
}
