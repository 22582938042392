import { graphql } from '@apollo/client/react/hoc';
import get from 'lodash-es/get';
import GetContact from '../../graphql/queries/contacts/get-contact.graphql';
import { pruneEmpty } from '../../utils/filter';
import { CONTACT_TYPE } from '../../../src/components/contacts/constants';

export default function withGetContacts(_config = {}) {
	return graphql(GetContact, {
		..._config,
		props: ({
			data: { getContact, loading, error },
			ownProps: { contactGroups = [], selectedContacts } = {}
		}) => {
			if (selectedContacts) {
				return {
					contacts: getContact && getContact.map(contact => pruneEmpty(contact)),
					loading,
					error
				};
			}
			const contactGroup = get(getContact, '0') || {};
			const contactGroupMembers = (get(contactGroup, 'members') || []).reduce(
				(acc, { contacts, type, value }) => {
					const contact =
						type === CONTACT_TYPE.inline
							? {
									attributes: { email: value },
									id: value,
									folderId: '',
									type
							  }
							: contacts && pruneEmpty(contacts[0]);

					if (contact) {
						// GetContact API doesn't return `memberOf` property for Contacts so, we derive it from contactGroups.
						const memberOf =
							type === CONTACT_TYPE.inline
								? contactGroup.id
								: contactGroups
										.filter(
											({ members }) =>
												members &&
												!!members.some(
													({ type: memberType, value: memberValue }) =>
														memberType === CONTACT_TYPE.localOrShared && memberValue === contact.id
												)
										)
										.map(({ id }) => id)
										.join(',');

						acc.push({
							...contact,
							memberOf
						});
					}

					return acc;
				},
				[]
			);

			return {
				contacts: contactGroupMembers,
				contactGroup: {
					...contactGroup,
					name: contactGroup.fileAsStr
				},
				loading
			};
		}
	});
}
