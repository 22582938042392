import { Component } from 'preact';
import { Text } from 'preact-i18n';
import get from 'lodash-es/get';

import { AnimatedButton } from '@zimbra/blocks';

import style from '../style';
import { startAttachmentDownloadProcess } from '../../../lib/save-as';
import moment from 'moment';
import withMediaQuery from '../../../enhancers/with-media-query';
import { minWidth, screenMd } from '../../../constants/breakpoints';
import { branch, renderNothing } from 'recompose';
import withTracking from '../../../enhancers/tracking';

@withTracking()
@withMediaQuery(minWidth(screenMd), 'matchesScreenMd')
@branch(({ matchesScreenMd }) => !matchesScreenMd, renderNothing)
class Export extends Component {
	handleExport = () => {
		this.setState({
			exportInProgress: true
		});

		setTimeout(() => {
			this.setState({
				exportInProgress: false
			});
		}, 10000);

		this.props.tracker.setUserAction({ action: 'settings-account-export' });

		const emailAddress = get(this.props, 'emailAddress');
		const downloadDateTime = moment().format('YYYY-MM-DD-HHmmss');
		const value = `/home/${emailAddress}/?fmt=tgz&filename=All-${downloadDateTime}&emptyname=No+Data+to+Export&charset=UTF-8`;

		const uri = this.context.zimbraBatchClient.resolve(value);

		startAttachmentDownloadProcess(uri);
	};

	render({}, { exportInProgress }) {
		const { isCalendarEnabled } = this.props;
		return (
			<div class={style.exportSection}>
				<div class={style.sectionTitle}>
					<Text id="settings.accounts.editAccount.export.title" />
				</div>
				<div class={style.description}>
					<p>
						<Text
							id={`settings.accounts.editAccount.export.description.${
								isCalendarEnabled ? 'default' : 'calDisabled'
							}`}
						/>
					</p>
					<p>
						<AnimatedButton
							loading={exportInProgress}
							disabled={exportInProgress}
							shake={false}
							styleType="primary"
							brand="primary"
							type="submit"
							title={<Text id="settings.accounts.editAccount.export.title" />}
							onClick={this.handleExport}
							class={style.exportButton}
						/>
					</p>
				</div>
			</div>
		);
	}
}

export default Export;
