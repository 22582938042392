import { Component } from 'preact';
import { createPortal } from 'preact/compat';

import s from './style.less';
import cx from 'classnames';

export default class Toolbar extends Component {
	state = { mounted: false };

	componentDidMount() {
		// We encounter a duplicate mounting issue if we try to immediately render,
		// likely due portaling into a sibling component and mounting order.
		setTimeout(() => {
			this.setState({ mounted: true });
		}, 0);
	}

	render(props) {
		if (this.state.mounted) {
			return createPortal(
				<ToolbarContainer {...props} />,
				document.getElementById('app-navigation-toolbar')
			);
		}
	}
}

export function ToolbarContainer({ children, ...props }) {
	return (
		<div {...props} class={cx(s.toolbarContainer, props.class)}>
			<div class={s.toolbar}>{children}</div>
		</div>
	);
}
