import { Text } from 'preact-i18n';
import flatMap from 'lodash-es/flatMap';
import { FILTER_TEST_TYPE, FILTER_CONDITION_TYPE } from '../../../constants/filter-rules';

import style from '../style';

function formatTestPredicate(stringComparison, negative) {
	return <Text id={`settings.filterRules.${stringComparison}${negative ? 'Not' : ''}`} />;
}

function formatTestHeader(header) {
	if (header === 'to,cc') return <Text id="settings.filterRules.tocc" />;
	return header.charAt(0).toUpperCase() + header.slice(1);
}

/**
 * Creates human-readable displays of filter rule test conditions.
 *
 */
export default function FilterRuleTests({ test }) {
	return (
		<ul class={style.filterList}>
			{flatMap(Object.keys(test), (filterTestKey, index) => {
				if (filterTestKey === FILTER_CONDITION_TYPE.ALLORANY) return;

				if (FILTER_TEST_TYPE.BODY === filterTestKey) {
					return test[filterTestKey].map(({ value, negative, caseSensitive }) => {
						const pred = formatTestPredicate('contains', negative);
						const part = <Text id="settings.filterRules.body" />;
						const matchCase = caseSensitive && <Text id="settings.filterRules.matchCase" />;
						return (
							<li key={`${index}-${filterTestKey}`}>
								{part} {pred} "<b>{value}</b>" {matchCase}
							</li>
						);
					});
				}

				if (
					FILTER_TEST_TYPE.ADDRESS === filterTestKey ||
					FILTER_TEST_TYPE.HEADER === filterTestKey
				) {
					return test[filterTestKey].map(
						({ header, value, stringComparison, negative, caseSensitive }) => {
							const pred = formatTestPredicate(stringComparison, negative);
							const part = formatTestHeader(header);
							const matchCase = caseSensitive && <Text id="settings.filterRules.matchCase" />;
							if (header === 'subject' || filterTestKey === FILTER_TEST_TYPE.ADDRESS) {
								return (
									<li>
										{part} {pred} "<b>{value}</b>" {matchCase}
									</li>
								);
							}
							const headerLabel = <Text id="settings.filterRules.headerLabel" />;
							return (
								<li key={`${index}-${filterTestKey}`}>
									{headerLabel} "<b>{header}</b>" {pred} "<b>{value}</b>" {matchCase}
								</li>
							);
						}
					);
				}

				if (FILTER_TEST_TYPE.HEADER_EXIST === filterTestKey) {
					return test[filterTestKey].map(({ header, negative }) => {
						const headerLabel = <Text id="settings.filterRules.headerLabel" />;
						const checkExist = (
							<Text id={`settings.filterRules.${negative ? 'doesNotExist' : 'exists'}`} />
						);
						return (
							<li key={`${index}-${filterTestKey}`}>
								{headerLabel} "<b>{header}</b>" {checkExist}
							</li>
						);
					});
				}
				return (
					<li class={style.unsupportedFilterRule} key={`${index}-${filterTestKey}`}>
						<Text id="settings.filterRuleModal.unsupportedCondition" />
					</li>
				);
			})}
		</ul>
	);
}
