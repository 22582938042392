let exportVar = {};

if (typeof process.env.ELECTRON_ENV !== 'undefined') {
	const {
		rendererIpc,
		constants: { ipcNames }
	} = require('@zimbra/electron-app');

	const handleError = response => {
		if (response && response.error === true) {
			const error = new Error(response.message);

			error.stack = response.stack;
			error.faults = response.faults;

			return error;
		}

		return response;
	};

	const localStoreClient = {
		downloadMessage: options => rendererIpc.callMain(ipcNames.downloadMessage, options),
		getFolder: options => rendererIpc.callMain(ipcNames.getFolder, options),
		getMessage: options => rendererIpc.callMain(ipcNames.getMessage, options),
		getMessagesMetadata: options => rendererIpc.callMain(ipcNames.getMessagesMetadata, options),
		getAccountInfo: options => rendererIpc.callMain(ipcNames.getAccountInfo, options),
		getMailboxMetadata: options => rendererIpc.callMain(ipcNames.getMailboxMetadata, options),
		getPreferences: options => rendererIpc.callMain(ipcNames.getPreferences, options),
		search: options => rendererIpc.callMain(ipcNames.search, options),
		action: (type, options) =>
			rendererIpc.callMain(ipcNames.action, { type, options }).then(handleError),
		addMessage: options => rendererIpc.callMain(ipcNames.addMessage, options),
		saveDraft: (message, accountName) =>
			rendererIpc.callMain(ipcNames.saveDraft, { message, accountName }),
		sendMessage: (message, accountName) =>
			rendererIpc.callMain(ipcNames.sendMessage, { message, accountName }),
		createFolder: options => rendererIpc.callMain(ipcNames.createFolder, options).then(handleError),
		attach: async ({ files, message }) =>
			Promise.all(
				files.map(async file => ({
					fileProps: {
						...file,
						size: file.size,
						contentType: file.contentType || file.type,
						filename: file.filename || file.name
					},
					buffer: await file.arrayBuffer()
				}))
			).then(fileList =>
				rendererIpc
					.callMain(ipcNames.attach, {
						files: fileList,
						message
					})
					.then(handleError)
			)
	};

	exportVar = { localStoreClient };
}

module.exports = exportVar;
