import { graphql } from '@apollo/client/react/hoc';
import GetIdentities from '../../graphql/queries/identities/get-identities.graphql';

export default function withIdentities(mapProps, config = {}) {
	const { options: { fetchPolicy = 'cache-first', ...restOptions } = {}, ...restConfig } = config;

	return graphql(GetIdentities, {
		...restConfig,
		options: ({ isOffline }) => ({
			...restOptions,
			fetchPolicy: isOffline ? 'cache-only' : fetchPolicy
		}),
		props: result => mapProps(result)
	});
}
