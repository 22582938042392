import moment from 'moment';
import fromPairs from 'lodash-es/fromPairs';
import mapValues from 'lodash-es/mapValues';
import map from 'lodash-es/map';
import isEmpty from 'lodash-es/isEmpty';
import { switchTimeFormat } from '../lib/util';
import memoize from 'lodash-es/memoize';

// Converts a string in the format of `257:false,266:true,1115:true`
// into an object.
export const normalizeFoldersExpanded = memoize((str = '') => {
	if (!str) {
		return {};
	}
	return mapValues(
		fromPairs(
			str
				.split(',')
				.map(pair => {
					const matches = pair.match(/^(.+):(true|false)$/);
					return matches ? [matches[1], matches[2]] : null;
				})
				.filter(Boolean)
		),
		v => v === 'true'
	);
});

export function serializeFoldersExpanded(obj = {}) {
	return map(obj, (v, k) => `${k}:${v}`).join(',');
}

/** Converts a string in the format
 * `1:N:0300:1100,2:Y:0300:1100,3:Y:0300:1100,4:Y:0300:1100,5:Y:0300:1100,6:Y:0300:1100,7:N:0300:1100`
 * into a JSON readable object.
 * @param {String} str
 * @returns {Object}
 */
export function soapTimeToJson(str) {
	const tokens = str.split(',').map(token => token.split(':'));

	if (tokens.length) {
		return tokens.reduce(
			(result, [dayIndex, isWorking, start, end]) => ({
				...result,
				[dayIndex]: {
					enabled: isWorking === 'Y',
					start: moment(start, 'HHmm'),
					end: moment(end, 'HHmm')
				}
			}),
			{}
		);
	}

	console.error(
		'Error while converting SOAP time to JSON, zimbraPrefCalendarWorkingHours is improperly formatted:',
		str
	);
	return {};
}

/**
 * Returns a string containing parsed hours of the day extracted from the JSON-formatted {@param jsonTime}
 * @param {{ ID: { enabled: String, start: String, end: String } }} jsonTime
 * @returns {String}
 */
export function jsonTimeToSoap(jsonTime) {
	try {
		let soapTime = '';

		Object.keys(jsonTime).forEach((dayNumber, index) => {
			soapTime = soapTime.concat(
				`${dayNumber}:${jsonTime[dayNumber].enabled ? 'Y' : 'N'}:${switchTimeFormat(
					jsonTime[dayNumber].start
				)}:${switchTimeFormat(jsonTime[dayNumber].end)}${
					index !== Object.keys(jsonTime).length - 1 ? ',' : ''
				}`
			);
		});
		return soapTime;
	} catch (error) {
		console.error('Error while converting JSON time to SOAP: ', error.message);
	}
}
// API returns empty lists as an array containing an empty object
export function normalizeList(arr = []) {
	return arr.filter(obj => !isEmpty(obj));
}

/** Converts a string '1999:sizeDesc,2:priorityAsc,2003:readDesc,2277:readDesc,3:dateAsc,4:nameDesc,5:readDesc'
 * into an object.
 * @param {String} str
 * @returns {Object}
 */
export function normalizeSortOrder(str = '') {
	if (!str) {
		return {};
	}
	return fromPairs(
		str.split(',').map(pair => {
			const splitStr = pair.split(':');
			const last = splitStr.pop();
			const first = splitStr.join(':');
			return [first, last];
		})
	);
}
