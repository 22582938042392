export const REMINDER_SNOOZE_OPTIONS = [
	'1m',
	'5m',
	'10m',
	'15m',
	'30m',
	'45m',
	'60m',
	'2h',
	'4h',
	'8h',
	'1d',
	'2d',
	'3d',
	'4d',
	'1w'
];
