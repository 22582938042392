import { Fragment } from 'preact';
import cx from 'classnames';
import { Match } from 'preact-router/match';
import { Link } from 'preact-router';
import { Icon } from '@zimbra/blocks';
import style from './style';
import { getBasePath } from '../../lib/util';

export default function MenuItem({
	customClass,
	activeClass,
	iconClass,
	innerClass,
	icon,
	iconPosition = 'left',
	isExternal = false,
	iconText,
	responsive,
	children,
	sidebarEnable,
	href,
	isItemActive,
	...props
}) {
	const childElement = (
		<Fragment>
			{icon && (
				<span class={cx(iconClass || style.icon, props.disabled && style.iconDisabled)}>
					{typeof icon === 'string' ? <Icon name={icon} /> : icon}
					{iconText && <span class={style.iconText}>{iconText}</span>}
				</span>
			)}
			<span
				class={cx(style.inner, innerClass)}
				title={props.hideDefaultTooltip === true ? '' : props.title}
			>
				{children}
			</span>
		</Fragment>
	);
	const classList = cx(
		!customClass && style.navItem,
		icon && (iconPosition === 'right' ? style.iconRight : style.iconLeft),
		customClass !== true && customClass,
		props.class,
		responsive && style.responsive,
		props.disabled && style.disabled,
		sidebarEnable && style.sidebarEnable
	);
	return href ? (
		isExternal ? (
			<a target="_blank" rel="noopener noreferrer" href={href} class={classList}>
				{childElement}
			</a>
		) : (
			<Match path={href} basePath={getBasePath()}>
				{({ matches, url }) => (
					<Link
						{...props}
						href={href}
						class={cx(
							(matches || (isItemActive && isItemActive(url))) && [style.active, activeClass],
							classList
						)}
					>
						{childElement}
					</Link>
				)}
			</Match>
		)
	) : (
		<div {...props} class={cx(classList, activeClass)}>
			{childElement}
		</div>
	);
}
