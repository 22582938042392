import { Component } from 'preact';
import { ApolloClient, ApolloProvider, ApolloLink, split } from '@apollo/client';
import DebounceLink from 'apollo-link-debounce';
import { PersistGate } from 'redux-persist/integration/react';
import RetryAllLink from '../../apollo/retry-all-link';
import GraphQLServerLink from '../../apollo/graphql-server-link';
import getApplicationStorage, { getApplicationStorageMaxSize } from '../../constants/storage';
import {
	startWatchingOfflineStatus,
	checkOfflineStatus,
	watchOfflineStatus
} from '@zimbra/is-offline';
import jwtStorage from '../../utils/jwt';
import { Provider as ReduxProvider } from 'react-redux';
import csrfStorage from '../../utils/csrf';
import Provider from 'preact-context-provider';
import appConfiguration from '../../enhancers/app-config';
import createStore from '../../store';
import zimbraClient from '../../lib/zimbra-client';
import zimletManager from '../../lib/zimlet-manager';
import createGifClient from '../../lib/gif-client';
import {
	offlineConnectionStatus,
	setSyncInProgress,
	logoutStatus
} from '../../store/network/actions';
import KeyboardShortcutHandler from '../../keyboard-shortcuts/keyboard-shortcut-handler';
import KeyToCommandBindings from '../../keyboard-shortcuts/key-to-command-bindings';
import {
	createZimbraSchema,
	LocalBatchLink,
	ZimbraInMemoryCache,
	ZimbraErrorLink,
	OfflineQueueLink
} from '@zimbra/api-client';

import { ZimbraNotifications } from '../../notifications';

import { CachePersistor } from 'apollo3-cache-persist';
import { fetchUserAgentName } from '../../utils/user-agent';
import App from './app';
import clientResolvers from '../../apollo/client-resolvers';
import { ServiceWorkerUpdate } from '../service-worker-update';
import { localStoreClient } from '../../electron/local-client-stub';
import { registerIpcListeners, unregisterIpcListeners } from '@zimbra/electron-app';

const DEBOUNCE_LINK_TIMEOUT = 135;

const userAgent = {
	name: fetchUserAgentName(),
	version: '' // Current Server version of the backend.
};

@appConfiguration('zimbraOrigin,zimbraGraphQLEndPoint,useJwt,useCsrf,giphyKey')
export default class Provide extends Component {
	syncOfflineMutationQueue = () => {
		this.offlineQueueLink.getSize().then(size => {
			if (size > 2) {
				this.store.dispatch(setSyncInProgress(true));

				return this.offlineQueueLink.sync({ apolloClient: this.apolloClient }).then(() => {
					this.store.dispatch(setSyncInProgress(false));
				});
			}
		});
	};

	constructor(props, context) {
		super(props, context);

		const { zimbraOrigin, zimbraGraphQLEndPoint, useJwt, useCsrf } = this.props;
		const jwtToken = useJwt && jwtStorage.get();
		const csrfToken = useCsrf && csrfStorage.get();

		// this is the api client that gets used by the zm-x-web to directly invoke soap
		this.zimbra = zimbraClient({
			url: zimbraOrigin,
			jwtToken,
			csrfToken
		});

		const { store, persistor } = createStore({}, this.zimbra);
		this.store = store;
		this.persistor = persistor;

		this.keyBindings = new KeyToCommandBindings();
		this.shortcutCommandHandler = new KeyboardShortcutHandler({
			store: context.store,
			keyBindings: this.keyBindings
		});

		// Content providers (images, gifs, videos, news).
		this.content = {
			gifs: createGifClient(this.props)
		};

		/** Begin Apollo Setup */

		// TODO: Cache eviction: timestamp records on read, sort by oldest, remove oldest until storage is within quota.
		// TODO: Tag items as "offlineCritical" if they should never be evicted
		getApplicationStorage().then(storage => {
			this.cache = new ZimbraInMemoryCache();

			// returns the graphqlSchema and the batchClient of zm-api-js client
			const { client: batchClient, schema } = createZimbraSchema({
				cache: this.cache,
				zimbraOrigin,
				jwtToken,
				csrfToken,
				userAgent,
				localStoreClient
			});

			// the batchClient of zm-api-js is helpful to invoke apis from the api-js batch client
			this.batchClient = batchClient;

			// initialize the notifications handler
			this.zimbraNotifications = new ZimbraNotifications({
				cache: this.cache,
				getApolloClient: () => this.apolloClient,
				batchClient: this.batchClient,
				store: this.store,
				processNotifications: false
			});

			// Defering creation of zimlets until the batchClient is ready
			this.zimlets = zimletManager({
				zimbra: this.zimbra,
				store: this.store,
				getApolloClient: () => this.apolloClient,
				config: {
					zimbraOrigin,
					zimbraGraphQLEndPoint,
					useJwt,
					useCsrf
				},
				jwtToken,
				csrfToken,
				keyBindings: this.keyBindings,
				shortcutCommandHandler: this.shortcutCommandHandler,
				zimbraBatchClient: batchClient
			});

			// schema also gets added to context provider, to be used by graphiql screen
			this.schema = schema;

			// this helps to batch the requests, and is responsible for invoking all the
			// graphql requests on the specified schema and return the results
			this.batchLink = new LocalBatchLink({
				schema
			});

			// helps to register error handlers and executes them on graphql errors
			this.zimbraErrorLink = new ZimbraErrorLink();
			this.zimbraErrorLink.registerHandler(console.error.bind(console));

			// a link responsible for managing the offline operations, requires a storage to store
			// the link operations and hydrate them again
			// all operations go through this link, if the network is not offline,
			// the link just passes the operation to the next link
			this.offlineQueueLink = new OfflineQueueLink({ storage });

			// A link that retries the requests based on the config
			// checks the errors to decide whether the request has failed
			this.retryLink = new RetryAllLink({
				attempts: {
					max: 3
				}
			});

			this.apolloLink = ApolloLink.from([
				this.zimbraErrorLink,
				new DebounceLink(DEBOUNCE_LINK_TIMEOUT),
				this.retryLink,
				this.offlineQueueLink,
				// TODO: When we need to support batching for backend graphql apis, we would have to define
				// one more link here, which would, based on the context variable, either use http-link or
				// http-batch-link (needs to be created when needed)
				split(
					operation => operation.getContext().remote === true,
					new GraphQLServerLink({ zimbraOrigin, zimbraGraphQLEndPoint, csrfToken }),
					this.batchLink
				)
			]);

			this.persistCache = new CachePersistor({
				cache: this.cache,
				storage,
				maxSize: getApplicationStorageMaxSize()
			});

			(window.location.search.indexOf('nocache=true') === -1
				? this.persistCache.restore()
				: Promise.resolve()
			)
				.catch(e => {
					console.warn(
						'Could not restore persistent Apollo cache. Are you in private browsing mode?',
						e
					);
				})
				.then(() => {
					const isOffline = checkOfflineStatus();
					this.apolloClient = new ApolloClient({
						cache: this.cache,
						link: this.apolloLink,
						resolvers: clientResolvers,
						assumeImmutableResults: true,
						defaultOptions: {
							watchQuery: {
								fetchPolicy: isOffline ? 'cache-only' : 'cache-and-network',
								nextFetchPolicy: lastFetchPolicy => {
									if (
										lastFetchPolicy === 'cache-and-network' ||
										lastFetchPolicy === 'network-only'
									) {
										return 'cache-first';
									}

									return lastFetchPolicy;
								}
							}
						}
					});

					// If the App is online, sync any local mutations. Otherwise set the app to
					// Offline mode
					if (!isOffline) {
						this.syncOfflineMutationQueue();
					} else {
						// Keep offlie queue closed in case app opened in offline mode
						this.offlineQueueLink.close();
						this.offlineSyncPending = true;
						this.store.dispatch(logoutStatus(false));
						this.store.dispatch(offlineConnectionStatus(isOffline));
					}

					// Poll real network status, side effect for `watchOfflineStatus`
					this.stopWatchingOfflineStatus = startWatchingOfflineStatus(zimbraOrigin);

					// Watch offline status changes.
					this.clearWatchOfflineStatus = watchOfflineStatus(offline => {
						this.store.dispatch(offlineConnectionStatus(offline));
						offline && this.store.dispatch(logoutStatus(false));
						this.apolloClient.defaultOptions.watchQuery = {
							fetchPolicy: isOffline ? 'cache-only' : 'cache-and-network',
							nextFetchPolicy: lastFetchPolicy => {
								if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'network-only') {
									return 'cache-first';
								}

								return lastFetchPolicy;
							}
						};
						if (offline) {
							this.offlineQueueLink.close();
						} else {
							if (this.offlineSyncPending) {
								this.offlineSyncPending = false;
								this.syncOfflineMutationQueue();
							}
							this.offlineQueueLink.open({ apolloClient: this.apolloClient });
						}
					});

					this.setState({}); // queue a rerender (hacky)

					if (process.env.NODE_ENV === 'development') {
						window.apolloClient = this.apolloClient;
						window.gql = require('@apollo/client').gql;
						window.batchClient = this.batchClient;
						window.offlineLink = this.offlineQueueLink;
						window.zimbra = this.zimbra;
						window.store = this.store;
						window.storage = storage;
						window.zimlets = this.zimlets;
						window.content = this.content;
					}
				});
		});
	}

	componentWillMount() {
		registerIpcListeners();
	}

	componentWillUnmount() {
		unregisterIpcListeners();
		this.clearWatchOfflineStatus();
		this.stopWatchingOfflineStatus();
		this.zimlets.destroy();
		this.zimbraNotifications.destroy();
		delete this.zimlets;
		delete this.zimbraNotifications;
	}

	render({ zimbraOrigin, zimbraGraphQLEndPoint, useJwt, useCsrf, ...rest }) {
		const config = { zimbraOrigin, zimbraGraphQLEndPoint, useJwt, useCsrf };
		return (
			this.apolloClient && (
				<Provider
					config={config}
					zimbra={this.zimbra}
					zimlets={this.zimlets}
					gifs={this.content.gifs}
					links={this.content.links}
					keyBindings={this.keyBindings}
					shortcutCommandHandler={this.shortcutCommandHandler}
					schema={this.schema}
					zimbraBatchClient={this.batchClient}
					zimbraNotifications={this.zimbraNotifications}
					zimbraBatchLink={this.batchLink}
					zimbraErrorLink={this.zimbraErrorLink}
					offlineQueueLink={this.offlineQueueLink}
					persistCache={this.persistCache}
					reduxPersistor={this.persistor}
					client={this.apolloClient}
				>
					<ReduxProvider store={this.store}>
						<PersistGate persistor={this.persistor}>
							<ApolloProvider client={this.apolloClient}>
								{typeof process.env.ELECTRON_ENV === 'undefined' && <ServiceWorkerUpdate />}
								<App {...rest} {...config} />
							</ApolloProvider>
						</PersistGate>
					</ReduxProvider>
				</Provider>
			)
		);
	}
}
