import { Component } from 'preact';
import { Text } from 'preact-i18n';
import { graphql } from '@apollo/client/react/hoc';
import get from 'lodash-es/get';
import { callWith } from '../../../lib/util';
import { Button } from '@zimbra/blocks';
import TwoFactorAuthSetup from '../../two-factor-auth-setup';
import { withStateHandlers, withProps, branch, renderNothing } from 'recompose';
import GetOneTimeCodes from '../../../graphql/queries/two-factor-auth/one-time-codes.graphql';
import GetTrustedDevicesQuery from '../../../graphql/queries/two-factor-auth/trusted-devices.graphql';
import {
	RevokeTrustedDevice,
	RevokeOtherTrustedDevices
} from '../../../graphql/mutations/two-factor-auth/revoke-trusted-devices.graphql';
import ConfirmModalDialog from '../../confirm-modal-dialog';
import OneTimeCodes from './one-time-codes';
import AppSpecificPasswords from './app-specific-passwords';
import { withDisableTwoFactorAuth } from '../../../graphql-decorators/two-factor-auth';
import NakedButton from '../../naked-button';
import { isLicenseActive } from '../../../utils/license';

import style from '../style';

const MODAL_HIDE = 0;
const MODAL_SHOW_DISABLE_2FA = 1;
const MODAL_SHOW_FORGET_CURR_TRUSTED_DEVICE = 2;
const MODAL_SHOW_FORGET_OTHER_TRUSTED_DEVICES = 3;

@withStateHandlers(
	{
		showTwoFactorAuthSetup: false,
		showOneTimeCodes: false,
		confirmModalDialogState: MODAL_HIDE
	},
	{
		setTwoFactorAuthSetup: () => value => ({ showTwoFactorAuthSetup: value }),
		toggleShowOneTimeCodes: () => value => ({ showOneTimeCodes: value }),
		setConfirmModalDialogState: () => value => ({ confirmModalDialogState: value })
	}
)
@withProps(({ accountData, selectedAccount }) => ({
	zimbraTwoFactorAuthEnabled: get(accountData, 'attrs.zimbraTwoFactorAuthEnabled'),
	zimbraFeatureTwoFactorAuthAvailable:
		get(selectedAccount, 'isPrimaryAccount') &&
		isLicenseActive(get(accountData, 'license')) &&
		get(accountData, 'attrs.zimbraFeatureTwoFactorAuthAvailable'),
	zimbraFeatureTwoFactorAuthRequired: get(accountData, 'attrs.zimbraFeatureTwoFactorAuthRequired'),
	zimbraFeatureTrustedDevicesEnabled: get(accountData, 'attrs.zimbraFeatureTrustedDevicesEnabled'),
	zimbraFeatureAppSpecificPasswordsEnabled: get(
		accountData,
		'attrs.zimbraFeatureAppSpecificPasswordsEnabled'
	)
}))
@branch(
	({ zimbraFeatureTwoFactorAuthAvailable }) => !zimbraFeatureTwoFactorAuthAvailable,
	renderNothing
)
@graphql(GetOneTimeCodes, {
	skip: ({ zimbraTwoFactorAuthEnabled }) => !zimbraTwoFactorAuthEnabled,
	options: ({ emailAddress }) => ({
		variables: {
			username: emailAddress
		}
	}),
	props: ({ data: { loading, getScratchCodes } }) => {
		if (loading) {
			return;
		}

		const scratchCode = get(getScratchCodes, 'scratchCodes.scratchCode');
		return {
			oneTimeCodes: scratchCode.map(obj => get(obj, '_content')).filter(Boolean)
		};
	}
})
@graphql(GetTrustedDevicesQuery, {
	skip: ({ zimbraTwoFactorAuthEnabled, zimbraFeatureTrustedDevicesEnabled }) =>
		!zimbraTwoFactorAuthEnabled || !zimbraFeatureTrustedDevicesEnabled,
	props: ({ data: { getTrustedDevices: { nOtherDevices, thisDeviceTrusted } = {} } = {} }) => ({
		nOtherDevices: typeof nOtherDevices === 'undefined' ? 0 : nOtherDevices,
		thisDeviceTrusted: typeof thisDeviceTrusted === 'undefined' ? false : thisDeviceTrusted
	})
})
@graphql(RevokeTrustedDevice, {
	props: ({ mutate }) => ({
		revokeTrustedDevice: () =>
			mutate({
				refetchQueries: [
					{
						query: GetTrustedDevicesQuery
					}
				]
			})
	})
})
@graphql(RevokeOtherTrustedDevices, {
	props: ({ mutate }) => ({
		revokeOtherTrustedDevices: () =>
			mutate({
				refetchQueries: [
					{
						query: GetTrustedDevicesQuery
					}
				]
			})
	})
})
@withDisableTwoFactorAuth()
export default class TwoFactorAuthSettings extends Component {
	handleUserResponse = userResp => {
		const {
			disableTwoFactorAuth,
			confirmModalDialogState,
			setConfirmModalDialogState,
			revokeTrustedDevice,
			revokeOtherTrustedDevices
		} = this.props;

		setConfirmModalDialogState(MODAL_HIDE);

		if (userResp) {
			if (confirmModalDialogState === MODAL_SHOW_DISABLE_2FA) {
				disableTwoFactorAuth();
			} else if (confirmModalDialogState === MODAL_SHOW_FORGET_CURR_TRUSTED_DEVICE) {
				revokeTrustedDevice();
			} else if (confirmModalDialogState === MODAL_SHOW_FORGET_OTHER_TRUSTED_DEVICES) {
				revokeOtherTrustedDevices();
			}
		}
	};

	render({
		zimbraTwoFactorAuthEnabled,
		setTwoFactorAuthSetup,
		toggleShowOneTimeCodes,
		showTwoFactorAuthSetup,
		showOneTimeCodes,
		emailAddress,
		oneTimeCodes,
		setOneTimeCodes,
		setConfirmModalDialogState,
		confirmModalDialogState,
		zimbraFeatureTwoFactorAuthRequired,
		zimbraFeatureTrustedDevicesEnabled,
		thisDeviceTrusted,
		nOtherDevices,
		zimbraFeatureAppSpecificPasswordsEnabled
	}) {
		return (
			<div class={style.twoFactorAuthSetup}>
				<div class={style.sectionTitle}>
					<Text id="settings.accounts.editAccount.twoFactorAuth.title" />
				</div>

				{zimbraTwoFactorAuthEnabled ? (
					<div>
						<p class={style.twoFactorAuthDescription}>
							<Text id="settings.accounts.editAccount.twoFactorAuth.twoFactorAuthEnabled" />
						</p>
						{!zimbraFeatureTwoFactorAuthRequired && (
							<Button
								styleType="primary"
								brand="danger"
								onClick={callWith(setConfirmModalDialogState, MODAL_SHOW_DISABLE_2FA)}
								class={style.disableTwoFactorAuthBtn}
							>
								<Text id="buttons.disableTwoFactorAuth" />
							</Button>
						)}

						<div class={style.wrapTwoFactorAuthInfo}>
							{oneTimeCodes && (
								<div class={style.subsection}>
									<div class={style.subsectionTitle}>
										<Text id="settings.accounts.editAccount.twoFactorAuth.oneTimeCodes.label" />
									</div>
									<div class={style.subsectionBody}>
										<NakedButton
											class={style.nakedButton}
											onClick={callWith(toggleShowOneTimeCodes, true)}
										>
											<Text
												id="settings.accounts.editAccount.twoFactorAuth.oneTimeCodes.unusedCodes"
												fields={{ count: oneTimeCodes.length }}
											/>
										</NakedButton>
									</div>
								</div>
							)}
							{zimbraFeatureTrustedDevicesEnabled && (
								<div class={style.subsection}>
									<div class={style.subsectionTitle}>
										<Text id="settings.accounts.editAccount.twoFactorAuth.trustedDevices.title" />
									</div>
									<div class={style.subsectionBody}>
										<div>
											<Text
												id="settings.accounts.editAccount.twoFactorAuth.trustedDevices.info"
												fields={{ total: nOtherDevices + (thisDeviceTrusted ? 1 : 0) }}
											/>
										</div>
										<div class={style.trustedDevicesActionContainer}>
											{thisDeviceTrusted && (
												<NakedButton
													class={style.nakedButton}
													onClick={callWith(
														setConfirmModalDialogState,
														MODAL_SHOW_FORGET_CURR_TRUSTED_DEVICE
													)}
												>
													<Text id="settings.accounts.editAccount.twoFactorAuth.trustedDevices.forgetDevice" />
												</NakedButton>
											)}
											{thisDeviceTrusted && nOtherDevices > 0 && (
												<Text id="settings.accountRecovery.pipe" />
											)}
											{nOtherDevices > 0 && (
												<NakedButton
													class={style.nakedButton}
													onClick={callWith(
														setConfirmModalDialogState,
														MODAL_SHOW_FORGET_OTHER_TRUSTED_DEVICES
													)}
												>
													<Text id="settings.accounts.editAccount.twoFactorAuth.trustedDevices.forgetOtherDevices" />
												</NakedButton>
											)}
										</div>
									</div>
								</div>
							)}
							{zimbraFeatureAppSpecificPasswordsEnabled && <AppSpecificPasswords />}
						</div>
					</div>
				) : (
					<div>
						<p class={style.twoFactorAuthDescription}>
							<Text id="settings.accounts.editAccount.twoFactorAuth.description" />
						</p>
						<Button
							styleType="primary"
							brand="primary"
							onClick={callWith(setTwoFactorAuthSetup, true)}
							class={style.enableTwoFactorAuthBtn}
						>
							<Text id="buttons.enableTwoFactorAuth" />
						</Button>
					</div>
				)}

				{showTwoFactorAuthSetup && (
					<TwoFactorAuthSetup
						emailAddress={emailAddress}
						hideTwoFactorAuthSetup={callWith(setTwoFactorAuthSetup, false)}
						onTwoFactorAuthSetupComplete={callWith(setTwoFactorAuthSetup, false)}
					/>
				)}

				{showOneTimeCodes && (
					<OneTimeCodes
						oneTimeCodes={oneTimeCodes}
						emailAddress={emailAddress}
						hideOneTimeCodes={callWith(toggleShowOneTimeCodes, false)}
						setOneTimeCodes={setOneTimeCodes}
					/>
				)}

				{!!confirmModalDialogState && (
					<ConfirmModalDialog
						title={
							<Text
								id={
									confirmModalDialogState === MODAL_SHOW_DISABLE_2FA
										? 'buttons.disableTwoFactorAuth'
										: confirmModalDialogState === MODAL_SHOW_FORGET_CURR_TRUSTED_DEVICE
										? 'settings.accounts.editAccount.twoFactorAuth.trustedDevices.forgetDevice'
										: confirmModalDialogState === MODAL_SHOW_FORGET_OTHER_TRUSTED_DEVICES
										? 'settings.accounts.editAccount.twoFactorAuth.trustedDevices.forgetOtherDevices'
										: ''
								}
							/>
						}
						acceptText="buttons.confirm"
						rejectText="buttons.cancel"
						cancelButton={false}
						onResult={this.handleUserResponse}
					/>
				)}
			</div>
		);
	}
}
