import { Component } from 'preact';
import get from 'lodash-es/get';
import wire from 'wiretie';

// Zimlets that will always be loaded from Zimbra Server.
// @TODO add information for loading development zimlets
const CONSTANT_ZIMLETS = {
	serverConsolidatedZimlets: {
		url: `/service/zimlet/res/Zimlets-nodev_all.zgz?zimbraXZimletCompatibleWith=${PKG_VERSION}&cacheBust=${Date.now()}` // TODO: Add cosId
	}
};

@wire('zimlets', null, ({ loadZimletByUrls }) => ({ loadZimletByUrls }))
export default class ZimletLoader extends Component {
	static pending = {};
	static running = {};
	static errors = {};

	loadRemoteZimlet = ({ loadZimletByUrls, zimlets, accountZimlets }) => {
		const disabledZimlets = accountZimlets.reduce((memo, zimlet) => {
			const name = get(zimlet, 'zimlet.0.name');
			if (name && get(zimlet, 'zimletContext.0.presence') === 'disabled') {
				memo.push(name);
			}

			return memo;
		}, []);

		if (!zimlets || !Object.keys(zimlets).length) {
			return;
		}

		// TODO: Try to get rid of suggestedName entirely, use URL as cacheKey
		Object.keys(zimlets).forEach(suggestedName => {
			const { url } = zimlets[suggestedName];
			if (ZimletLoader.running[suggestedName]) {
				return console.warn(`[ZimletSDK "${suggestedName}"]: Zimlet is already running.`); // eslint-disable-line no-console
			}

			if (ZimletLoader.pending[suggestedName]) {
				ZimletLoader.pending[suggestedName].then(this.callOnLoad);

				return console.warn(`[ZimletSDK "${suggestedName}"]: Zimlet is already pending.`); // eslint-disable-line no-console
			}

			if (!url) {
				const errorMsg = 'Zimlet URL Required';
				ZimletLoader.errors[suggestedName] = errorMsg;

				return console.warn(`[ZimletSDK "${suggestedName}"]: ${errorMsg}`); // eslint-disable-line no-console
			}

			return (ZimletLoader.pending[suggestedName] = loadZimletByUrls(url, {
				name: suggestedName,
				compat: false
			})
				.then(results =>
					results.forEach(
						({ name, zimlet }) => disabledZimlets.indexOf(name) === -1 && zimlet.init()
					)
				)
				.then(() => {
					console.info(`[ZimletSDK "${suggestedName}"]: Loaded successfully from ${url}`); // eslint-disable-line no-console

					ZimletLoader.running[suggestedName] = { url };
					delete ZimletLoader.errors[suggestedName];
				})
				.catch(error => {
					console.warn(`[ZimletSDK "${suggestedName}"]:`, error); // eslint-disable-line no-console

					ZimletLoader.errors[suggestedName] = error;
				})
				.then(() => {
					this.callOnLoad();
					delete ZimletLoader.pending[suggestedName];
				}));
		});

		return this.callOnLoad();
	};

	callOnLoad = () =>
		this.props.onLoadZimlets &&
		this.props.onLoadZimlets({ running: ZimletLoader.running, errors: ZimletLoader.errors });

	componentWillMount() {
		this.loadRemoteZimlet({
			...this.props,
			zimlets: CONSTANT_ZIMLETS
		});
	}
}
