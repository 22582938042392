import { Component } from 'preact';
import { Text, Localizer } from 'preact-i18n';
import style from '../style';
import FIELD_MAPS from '.';
import { getAddressFieldPrefixAndSuffix } from './helper';
import { connect } from 'react-redux';

@connect(state => ({ template: state.template }))
export default class AddressGroup extends Component {
	state = getAddressFieldPrefixAndSuffix(this.props.attribute);

	componentWillReceiveProps(props) {
		const { prefix, suffix } = getAddressFieldPrefixAndSuffix(props.attribute);
		this.setState({
			prefix,
			suffix
		});
	}

	render({ pfx, contact, createContactFieldUpdater, readonly, template }, { prefix, suffix }) {
		const ADDRESS_FIELDS = template?.contacts?.edit?.addressFields;
		return (
			<div class={style.addressGroup}>
				{ADDRESS_FIELDS.map(field => {
					const FieldComponent = FIELD_MAPS[field] || 'input';
					const fieldKey = prefix + field + suffix;
					return readonly ? (
						<span class={style.readonlyValue}>{contact.attributes[field]}</span>
					) : (
						<div class={style.inputWrap}>
							<Localizer>
								<FieldComponent
									id={pfx + field}
									name={fieldKey}
									placeholder={<Text id={`contacts.edit.fields.${field}`} />}
									value={contact.attributes[fieldKey] || ''}
									autocomplete={'off'}
									onInput={createContactFieldUpdater('attributes.' + fieldKey)}
								/>
							</Localizer>
						</div>
					);
				})}
			</div>
		);
	}
}
