import { canMoveMailInto } from '../../utils/folders';

export const setMoveableFolderFlag = folder => {
	const { name, oname, permissions, folders } = folder;

	return {
		...folder,
		droppable: canMoveMailInto(oname || name, permissions),
		...(folders && { folders: folders.map(setMoveableFolderFlag) })
	};
};

export const folderActionMutationVariables = ({ id, op, folderId, name }) => {
	return {
		action: { id, op, name, folderId }
	};
};

export const createFolderMutationVariables = ({
	color,
	fetchIfExists,
	flags,
	name,
	parentFolderId,
	url,
	view
}) => {
	return { color, fetchIfExists, flags, name, parentFolderId, url, view };
};
