import { Component } from 'preact';
import { withText, Text } from 'preact-i18n';
import { AddToBriefcase } from '../briefcase/add-to-briefcase';
import AttachmentBadge from '../attachment-badge';
import { Icon } from '@zimbra/blocks';
import { route } from 'preact-router';
import cx from 'classnames';
import { isEML, isUploadedEml } from '../../lib/util';
import s from './style.less';
import saveToModalDialog from './save-to-modal-dialog';
import get from 'lodash-es/get';
import ZimletSlot from '../zimlet-slot';
import { minWidth, screenSm } from '../../constants/breakpoints';
import withMediaQuery from '../../enhancers/with-media-query';
import withTabManager from '../../enhancers/tab-manager';
import { connect } from 'react-redux';
import { notify as notifyActionCreator } from '../../store/notifications/actions';
import FormatBytesTranslation from '../format-bytes-translation';

const formatFilename = name => {
	const extensionPivotIdx = name.lastIndexOf('.');

	return extensionPivotIdx !== -1
		? {
				name: name.slice(0, extensionPivotIdx),
				extension: `.${name.slice(extensionPivotIdx + 1)}`
		  }
		: { name, extension: '' };
};
@connect(
	state => ({
		isOffline: state?.network?.isOffline
	}),
	{
		notify: notifyActionCreator
	}
)
@withText({
	saveToBriefcaseLabel: 'buttons.saveToBriefcase',
	downloadLabel: 'buttons.download'
})
@withTabManager()
@withMediaQuery(minWidth(screenSm), 'matchesScreenSm')
export default class AttachmentTile extends Component {
	state = {
		showSaveAttachmentModal: false,
		showAttachToBriefcaseModal: false
	};

	handleRemove = () => {
		const { onRemove, attachment } = this.props;
		onRemove({ attachment });
	};

	onPreview = () => {
		const {
			onPreview,
			attachment,
			hideAttachmentPreview,
			handleNewTab,
			matchesScreenSm,
			messageId,
			localFolder,
			isOffline,
			notify
		} = this.props;

		if (isUploadedEml(attachment)) {
			const isOfflineWebapp = isOffline && typeof process.env.ELECTRON_ENV === 'undefined';
			if (isOfflineWebapp) {
				notify({
					message: <Text id="mail.notifications.noPreview" />,
					warning: true
				});
			} else if (matchesScreenSm) {
				handleNewTab({
					messageId,
					type: 'message',
					full: true,
					title: attachment.filename,
					url: `/email/Inbox/message/${messageId}/${attachment.part}?full=true${
						localFolder ? '&local=true' : ''
					}`
				});
			} else {
				// In Mobile view
				route(`/email/Inbox/message/${messageId}/${attachment.part}`);
			}
		} else {
			!hideAttachmentPreview &&
				onPreview &&
				(get(attachment, 'part') || get(attachment, 'base64')) &&
				onPreview(attachment);
		}
	};

	onDownload = () => {
		const { onDownload, attachment } = this.props;
		onDownload(attachment);
		this.setState({ showSaveAttachmentModal: false });
	};

	onCloseModal = () => {
		this.setState({ showSaveAttachmentModal: false });
	};

	onSave = () => {
		const useModal =
			this.context.zimlets.isSlotActive('slot::attachment-single-action') ||
			this.context.zimlets.isSlotActive('slot::attachment-multi-action');
		if (useModal) {
			this.setState({ showSaveAttachmentModal: true });
		} else {
			this.onDownload();
		}
	};

	toggleAddToBriefcase = () => {
		this.setState(({ showAttachToBriefcaseModal }) => ({
			showAttachToBriefcaseModal: !showAttachToBriefcaseModal
		}));
	};

	componentDidMount() {
		const { attachment } = this.props;
		if (attachment) {
			const filename = attachment.filename || attachment.name || 'Unnamed';
			// eslint-disable-next-line react/no-did-mount-set-state
			this.setState({
				filename,
				formattedFilename: formatFilename(filename)
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		const { attachment } = nextProps;
		if (attachment) {
			const nextFilename = attachment.filename || attachment.name || 'Unnamed';
			if (nextFilename !== this.state.filename) {
				this.setState({
					filename: nextFilename,
					formattedFilename: formatFilename(nextFilename)
				});
			}
		}
	}

	render(
		{
			attachment,
			removable,
			isLocalFilePath,
			isUploading,
			onRemove,
			saveToBriefcaseLabel,
			downloadLabel,
			hideCloseIcon,
			showAttachToBriefcase,
			zimbraFeatureBriefcasesEnabled
		},
		{ formattedFilename, showSaveAttachmentModal, showAttachToBriefcaseModal }
	) {
		const isEMLAttachment = isEML(attachment);
		return (
			<div class={cx(s.attachment, isUploading && s.uploading)}>
				<div class={s.attachmentDetails} onClick={this.onPreview}>
					<div class={s.iconContainer}>
						<AttachmentBadge
							attachment={attachment}
							class={s.left}
							isUploading={isUploading}
							isLocalFilePath={isLocalFilePath}
						/>
					</div>

					<div class={s.nameContainer}>
						<div class={s.fileInfo}>
							<div class={s.filename}>
								<span class={s.filenameText}>{formattedFilename?.name}</span>
								<span class={s.filenameExt}>{formattedFilename?.extension}</span>
							</div>
							<div class={s.fileSize}>
								<FormatBytesTranslation bytes={attachment.size} />
							</div>
						</div>
					</div>
				</div>
				<div class={s.fileOperation}>
					<ZimletSlot
						name="email-attachment-action"
						class={s.actionIcon}
						attachment={attachment}
						removable={removable}
					/>
					{onRemove && !hideCloseIcon && (
						<Icon name="close" class={s.actionIcon} onClick={this.handleRemove} />
					)}
					{zimbraFeatureBriefcasesEnabled &&
						!removable &&
						showAttachToBriefcase &&
						!isEMLAttachment && (
							<Icon
								name="briefcase"
								title={saveToBriefcaseLabel}
								class={s.actionIcon}
								onClick={this.toggleAddToBriefcase}
							/>
						)}
					{zimbraFeatureBriefcasesEnabled &&
						!removable &&
						showAttachToBriefcaseModal &&
						!isEMLAttachment && (
							<AddToBriefcase
								active={showAttachToBriefcaseModal}
								onClose={this.toggleAddToBriefcase}
								attachment={attachment}
							/>
						)}
					{!removable &&
						!isEMLAttachment && [
							<Icon
								name="download"
								title={downloadLabel}
								class={s.actionIcon}
								onClick={this.onSave}
							/>,
							showSaveAttachmentModal &&
								saveToModalDialog([attachment], this.onCloseModal, this.onDownload)
						]}
				</div>
			</div>
		);
	}
}
