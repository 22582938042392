import { Component } from 'preact';
import { Text } from 'preact-i18n';
import get from 'lodash-es/get';
import { connect } from 'react-redux';
import { withAriaId } from '@zimbra/a11y';
import clientConfiguration from '../../enhancers/client-config';
import appConfiguration from '../../enhancers/app-config';
import PasswordInput from '../password-input';
import { Button, AnimatedButton } from '@zimbra/blocks';
import TextInput from '../text-input';
import ClientLogo from '../client-logo';
import ChangePasswordForm from './change-password-form';
import ResetPasswordForm from './reset-password-form';
import withLogin from '../../graphql-decorators/login';
import linkState from 'linkstate';
import cx from 'classnames';
import style from './style';
import ForgotPassword from './forgot-password';
import TwoFactorAuthForm from './two-factor-auth-form';
import queryString from 'query-string';
import TwoFactorAuthSetup from '../two-factor-auth-setup';
import LoginBackground from './background';
import TagLine from './tagline';
import CopyrightTextComponent from '../copyright-modal';
import { branch, renderComponent } from 'recompose';
import { Error } from './error';
import { types as apiClientTypes } from '@zimbra/api-client';
import { setAuthTokenExpired } from '../../store/active-account/actions';

import {
	logoutStatus,
	offlineConnectionStatus,
	setLoginVisible
} from '../../store/network/actions';
import { rendererIpc } from '@zimbra/electron-app';
import { clearOfflineData } from '../../utils/offline';

const { ResetPasswordStatus } = apiClientTypes;

@branch(() => queryString.parse(location.search).errorCode, renderComponent(Error))
@withLogin()
@clientConfiguration('clientName,disableForgotPassword,loginLogoLink,loginLogoTitle')
@appConfiguration('zimbraOrigin,useJwt,useCsrf')
@withAriaId('login-form')
@connect(null, {
	setAuthTokenExpired,
	logoutStatus,
	offlineConnectionStatus,
	setLoginVisible
})
export default class Login extends Component {
	state = {
		validity: {
			username: true,
			password: true
		},
		username: this.props.username || queryString.parse(location.search).username,
		showChangePassword: this.props.showChangePassword,
		forgotPasswordFlow: queryString.parse(location.search).forgotPassword,
		showResetPassword:
			(this.props.username || queryString.parse(location.search).username) &&
			this.props.showResetPassword
	};

	handleError = error => {
		this.setState({ error });
	};

	shakeLoginButton = () => {
		this.setState({
			loading: false,
			shake: true
		});
	};

	afterLoginShake = () => {
		this.setState({ shake: false });
	};

	setForgotPassword = () => {
		this.setState({ forgotPasswordFlow: true, error: false });
	};

	unsetForgotPassword = () => {
		this.setState({ forgotPasswordFlow: false });
	};

	forgotPasswordChange = ({ username }) => {
		this.setState({
			forgotPasswordFlow: false,
			showResetPassword: true,
			username
		});
	};

	handleCancelResetPassword = () => {
		this.setState({
			showResetPassword: false
		});
	};

	setTwoFactorAuthFlow = (trustedDevicesEnabled, password) => {
		this.setState(state => ({
			show2FAForm: true,
			trustedDevicesEnabled,
			showTwoFactorSetup: false,
			showChangePassword: false,
			showResetPassword: false,
			loading: false,
			password: state.password || password
		}));
	};

	resetTwoFactorAuthFlow = () => {
		this.setState({
			show2FAForm: false,
			loading: false,
			trustedDevicesEnabled: false,
			password: ''
		});
	};

	resetTwoFactorSetup = () => {
		this.setState({ showTwoFactorSetup: false });
	};

	onLogin = () => {
		// If there are relay/redirect params present in url then instead of loading the app
		// we will relay/redirect to url

		const {
			onLogin,
			zimbraOrigin,
			showChangePassword,
			setAuthTokenExpired: setAuthExpired,
			logoutStatus: setLogoutStatus,
			offlineConnectionStatus: setOfflineStatus,
			setLoginVisible: setLoginVisibility,
			username
		} = this.props;

		const isDesktopUpdatedUser =
			typeof process.env.ELECTRON_ENV !== 'undefined' &&
			username &&
			username !== this.state.username;

		const { relay } = queryString.parse(window.location.search);
		const relayPattern = /^\/\//;

		// Check conditions only for login flow, not for change password flow
		if (!showChangePassword && relay && relay[0] === '/' && !relayPattern.test(relay)) {
			// relay back to somewhere else on the same server
			const origin =
				(zimbraOrigin &&
					zimbraOrigin.length > 1 &&
					// don't bother adding it if it's already at the start of the relay because then we're just duplicating.
					!relay.startsWith(zimbraOrigin) &&
					zimbraOrigin) ||
				'';

			const relayUrl = window.location.origin + origin + relay;

			window.location.href = relayUrl;

			return;
		}

		if (isDesktopUpdatedUser) {
			Promise.all([rendererIpc.resetDesktopApp(), clearOfflineData(this.context)]).then(
				() => (window.location.href = '/')
			);
		} else if (typeof process.env.ELECTRON_ENV !== 'undefined') {
			rendererIpc.resetDesktopApp().then(() => {
				setAuthExpired(false);
				setLoginVisibility(false);
				setOfflineStatus(false);
				setLogoutStatus(false);
				onLogin();
			});
		} else {
			onLogin();
		}
	};

	handleLogin = ({ username, password, twoFactorCode, deviceTrusted }) => {
		const { login, useJwt, useCsrf } = this.props;
		return login({
			username,
			password,
			...(useJwt && { tokenType: 'JWT' }),
			csrfTokenSecured: useCsrf,
			...(twoFactorCode && { twoFactorCode }),
			...(deviceTrusted && { deviceTrusted }),
			...(typeof process.env.ELECTRON_ENV !== 'undefined' && { ignoreSameSite: true })
		})
			.then(res => {
				if (get(res, 'data.login.twoFactorAuthRequired._content')) {
					this.setTwoFactorAuthFlow(
						get(res, 'data.login.trustedDevicesEnabled._content'),
						password
					);
				} else {
					return this.onLogin();
				}
			})
			.catch(err => {
				console.error(err);

				// Get error code and corresponding count (if returned)
				const errorObj = get(err, 'graphQLErrors.0.originalError.faults.0.Detail.Error');
				if (errorObj) {
					// Handle errors generated by server side code
					const errorCode = errorObj && errorObj.Code;
					const errorMetadata = errorObj && get(errorObj, 'a.0');

					this.setState({
						error: errorCode !== 'account.TWO_FACTOR_SETUP_REQUIRED' && (
							<Text
								id={`faults.${errorCode}${errorMetadata ? `.${errorMetadata.n}` : ''}`}
								fields={{
									count: errorMetadata && errorMetadata._content
								}}
							/>
						),
						showTwoFactorSetup: errorCode === 'account.TWO_FACTOR_SETUP_REQUIRED',
						showChangePassword: errorCode === 'account.CHANGE_PASSWORD',
						showResetPassword: false,
						loading: false
					});
				} else {
					this.setState({
						error: <Text id="error.genericInvalidRequest" />,
						loading: false
					});
				}
				throw err;
			});
	};

	submit = () => {
		const { username, password } = this.state;
		this.setState({ error: '', loading: true });

		const validity = {
			username: username && username.length > 1,
			password: password && password.length > 1
		};

		if (!validity.username || !validity.password) {
			this.shakeLoginButton();
			this.setState({
				loading: false,
				validity
			});
			return;
		}

		this.handleLogin({
			username,
			password
		}).catch(() => {
			this.shakeLoginButton();
		});

		return false;
	};

	handleTwoFA = ({ password }) =>
		this.setState({
			showTwoFactorSetup: true,
			password,
			showResetPassword: false
		});

	handleCancel = () => {
		const { setLoginVisible: setLoginVisibility } = this.props;
		setLoginVisibility(false);
	};

	componentWillReceiveProps({ username, showChangePassword, showResetPassword }) {
		this.setState({
			...(username && username !== this.state.username && { username }),
			...(showChangePassword &&
				showChangePassword !== this.state.showChangePassword && { showChangePassword }),
			...(showResetPassword &&
				showResetPassword !== this.state.showResetPassword && { showResetPassword })
		});
	}

	render(
		{
			a11yId,
			clientName,
			login,
			showChangePassword: changePasswordFlow,
			onClose,
			disableForgotPassword,
			loginLogoLink,
			loginLogoTitle,
			twoFactorEnabled,
			zimbraFeatureResetPasswordStatus
		},
		{
			showChangePassword,
			showResetPassword,
			forgotPasswordFlow,
			show2FAForm,
			showTwoFactorSetup,
			error,
			loading,
			username,
			password,
			validity,
			shake,
			trustedDevicesEnabled
		}
	) {
		const emailInputId = `${a11yId}-email`;
		const passInputId = `${a11yId}-password`;

		// disableForgotPassword is passed from client config which handles showing the forget password option
		// this is been depricated to support backward compatibilty keeping that part of the code,
		// it will be removed in future

		let isForgotPasswordVisible = !disableForgotPassword;
		const showCancel = typeof process.env.ELECTRON_ENV !== 'undefined';

		// is disableForgotPassword is not set for a client if a value is set for zimbraFeatureResetPasswordStatus we need to take that
		// value in consideration , zimbraFeatureResetPasswordStatus can be set to any of the value = "enabled, disbaled, suspended" if not
		// set it will be undefined
		if (!disableForgotPassword) {
			// isForgotPasswordVisible will be set to true if disableForgotPassword is not defined  in the client config
			// if zimbraFeatureResetPasswordStatus is enabled isForgotPasswordVisible will be set to true otherwise it will set to false
			isForgotPasswordVisible = zimbraFeatureResetPasswordStatus === ResetPasswordStatus.Enabled;
		}

		return (
			<LoginBackground
				class={!changePasswordFlow && style.container}
				changePasswordFlow={changePasswordFlow}
			>
				<div class={style.login} zmLoginPanel>
					<ClientLogo
						href={!changePasswordFlow && loginLogoLink}
						title={loginLogoTitle}
						target="_blank"
					/>
					<h1>
						{showChangePassword || showResetPassword ? (
							changePasswordFlow ? (
								<Text id="loginScreen.resetPass.headerChangePassword" />
							) : (
								<Text id="loginScreen.resetPass.header" />
							)
						) : forgotPasswordFlow ? (
							<Text id="loginScreen.forgotPass.header" />
						) : show2FAForm ? (
							<Text id="loginScreen.twoFactorAuth.headerTitle" />
						) : (
							!showTwoFactorSetup && <Text id="loginScreen.header.title" />
						)}
					</h1>

					{!error &&
						!showChangePassword &&
						!showResetPassword &&
						!forgotPasswordFlow &&
						!show2FAForm &&
						!showTwoFactorSetup && (
							<p>
								<Text id="loginScreen.header.tagline" fields={{ name: clientName }} />
							</p>
						)}

					<div class={cx(style.error, error && style.showing)}>
						<div class={style.inner}>{error}</div>
					</div>

					{showResetPassword ? (
						<ResetPasswordForm
							username={username}
							password={password}
							class={style.form}
							login={this.handleLogin}
							onError={this.handleError}
							handleTwoFA={this.handleTwoFA}
							onCancelResetPassword={this.handleCancelResetPassword}
						/>
					) : showChangePassword ? (
						<ChangePasswordForm
							twoFactorEnabled={twoFactorEnabled}
							username={username}
							password={password}
							class={style.form}
							login={login}
							onLogin={this.onLogin}
							onError={this.handleError}
							onClose={onClose}
						/>
					) : forgotPasswordFlow ? (
						<ForgotPassword
							a11yId={a11yId}
							style={style}
							onCancel={this.unsetForgotPassword}
							onCodeSubmit={this.forgotPasswordChange}
						/>
					) : show2FAForm ? (
						<TwoFactorAuthForm
							username={username}
							password={password}
							trustedDevicesEnabled={trustedDevicesEnabled}
							login={this.handleLogin}
							onCancel={this.resetTwoFactorAuthFlow}
						/>
					) : showTwoFactorSetup ? (
						<TwoFactorAuthSetup
							emailAddress={username}
							password={password}
							isMandatorySetup
							onTwoFactorAuthSetupComplete={this.onLogin}
							hideTwoFactorAuthSetup={this.resetTwoFactorSetup}
						/>
					) : (
						<form onSubmit={this.submit} novalidate action="javascript:" method="POST">
							<div class={style.form}>
								<label for={emailInputId} class={cx(!validity.username && style.invalid)}>
									<Text id="loginScreen.labels.email" />
								</label>
								<TextInput
									autofocus
									autocomplete="username email"
									autocorrect="off"
									autocapitalize="off"
									spellcheck="false"
									type="email"
									disabled={loading}
									id={emailInputId}
									class={cx(!validity.username && style.invalid)}
									value={username}
									onAutoComplete={linkState(this, 'username')}
									onInput={linkState(this, 'username')}
								/>

								<label for={passInputId} class={cx(!validity.password && style.invalid)}>
									<Text id="loginScreen.labels.pass" />
								</label>
								<PasswordInput
									disabled={loading}
									autocomplete="current-password"
									id={passInputId}
									class={cx(!validity.password && style.invalid)}
									value={password}
									onAutoComplete={linkState(this, 'password')}
									onInput={linkState(this, 'password')}
								/>

								<div class={cx(style.buttons, showCancel && style.withCancel)}>
									<AnimatedButton
										loading={loading}
										disabled={loading}
										shake={shake}
										styleType="primary"
										brand="primary"
										type="submit"
										title={<Text id="loginScreen.header.title" />}
										afterShake={this.afterLoginShake}
									/>
									{showCancel && (
										<Button onClick={this.handleCancel} disabled={!this.props.username}>
											<Text id="loginScreen.labels.cancel" />
										</Button>
									)}
									{isForgotPasswordVisible && (
										<Button onClick={this.setForgotPassword} styleType="text">
											<Text id="loginScreen.forgotPass.forgotPassword" />
										</Button>
									)}
								</div>
							</div>
						</form>
					)}
				</div>
				{!changePasswordFlow && (
					<footer class={style.footer}>
						<TagLine />
						{/*
						Unfinished - Will be finished in another Login ticket
						<h6><Text id="loginScreen.switchToBasic" /></h6>
						<ol>
							<li>
								<Text id="loginScreen.privacyPolicy" />
							</li>
							<li>
								<Text id="loginScreen.acceptableUsePolicy" />
							</li>
							<li>
								<Text id="loginScreen.contact" />
							</li>
						</ol>
						*/}
						<CopyrightTextComponent isLogin />
					</footer>
				)}
			</LoginBackground>
		);
	}
}
