import moment from 'moment';

const emailTime = (date, showMailReceiveTime) => {
	const now = moment();

	if (now.isSame(date, 'day') || moment(now).subtract(24, 'hours').isBefore(date, 'hours')) {
		// If email arrived within last 24 hours.
		return date.format('LT');
	}

	const dateFormat = [now.isSame(date, 'year') ? '[formatMonthDayMedium]' : 'L'];

	showMailReceiveTime && dateFormat.push('LT');

	return date.format(dateFormat.join(', '));
};

const EmailTime = ({ time, showMailReceiveTime, ...props }) => {
	const date = moment(time);
	return (
		<time {...props} title={date.format('LLLL')}>
			{emailTime(date, showMailReceiveTime)}
		</time>
	);
};

export default EmailTime;
