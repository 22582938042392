import postMessagesToWebkit from '../utils/webkit-ios';
import bridge from '../utils/bridge';

const openExternalBrowser = (URL, name, windowOptions) => {
	if (typeof process.env.ELECTRON_ENV !== 'undefined') {
		const { rendererIpc } = require('@zimbra/electron-app');

		rendererIpc.openExternalBrowser(URL);
		return;
	} else if (window.AndroidBridge) {
		bridge('AndroidBridge').call({ openExternalBrowser: [URL] });
		return;
	} else if (typeof webkit !== 'undefined') {
		postMessagesToWebkit('openExternalBrowser', { URL });
		return;
	}

	return window.open(URL, name, windowOptions);
};

export { openExternalBrowser };
