import { Component } from 'preact';
import cx from 'classnames';
import { ChoiceInput, Button } from '@zimbra/blocks';
import { Text, withText } from 'preact-i18n';
import get from 'lodash-es/get';
import merge from 'lodash-es/merge';
import set from 'lodash-es/set';
import flatten from 'lodash-es/flatten';
import cloneDeep from 'lodash-es/cloneDeep';
import has from 'lodash-es/has';
import Select from '../../select';
import style from './style.less';

import {
	getRulePredicateOptions,
	FILTER_ACTION_TYPE,
	NEW_FILTER_RULE,
	FILTER_CONDITION_TYPE,
	FILTER_TEST_TYPE
} from '../../../constants/filter-rules';
import {
	getFilterConditionConfig,
	dropFolderFilter,
	normalizePath,
	getConditionCount,
	getActionCount
} from '../../../utils/settings-filter';
import { withPropsOnChange, withProps } from 'recompose';
import { flattenFolders } from '../../../utils/folders';
import ModalDialog from '../../modal-dialog';
import AdvancedConditionContainer from './advanced-condition-container';
import AdvancedActionContainer from './advanced-action-container';
import { INBOX } from '../../../constants/folders';

@withText({
	toccLabel: 'settings.filterRules.tocc',
	fromLabel: 'settings.filterRules.from',
	subjectLabel: 'settings.filterRules.subject',
	bodyLabel: 'settings.filterRules.body',
	containsLabel: 'settings.filterRules.contains',
	containsNotLabel: 'settings.filterRules.containsNot',
	matchesWildCard: 'settings.filterRules.matchesWildCard',
	matchesWildCardNot: 'settings.filterRules.matchesWildCardNot',
	matchesExactlyLabel: 'settings.filterRules.matchesExactly',
	matchesExactlyNotLabel: 'settings.filterRules.matchesExactlyNot',
	toLabel: 'settings.filterRules.to',
	ccLabel: 'settings.filterRules.cc',
	headerNamedLabel: 'settings.filterRules.headerNamed',
	headerDoesNotexistLabel: 'settings.filterRules.doesNotExist',
	headerExistLabel: 'settings.filterRules.exists',
	headerInputPlaceholder: 'settings.filterRules.headerInputPlaceholder'
})
@withPropsOnChange(
	['folders'],
	({
		folders,
		containsLabel,
		containsNotLabel,
		matchesWildCard,
		matchesWildCardNot,
		matchesExactlyLabel,
		matchesExactlyNotLabel,
		headerDoesNotexistLabel,
		headerExistLabel
	}) => ({
		folders: flattenFolders(folders)
			.filter(folder => dropFolderFilter(folder))
			.map(({ absFolderPath }) => normalizePath(absFolderPath)),
		rulePredicateOptions: getRulePredicateOptions({
			containsLabel,
			containsNotLabel,
			matchesWildCard,
			matchesWildCardNot,
			matchesExactlyLabel,
			matchesExactlyNotLabel,
			headerDoesNotexistLabel,
			headerExistLabel
		})
	})
)
@withProps(
	({
		value,
		rulePredicateOptions,
		toccLabel,
		fromLabel,
		bodyLabel,
		subjectLabel,
		toLabel,
		ccLabel,
		headerNamedLabel
	}) => {
		const clonedValue = cloneDeep(value);
		Object.keys(clonedValue.conditions[0])
			.filter(f => f !== FILTER_CONDITION_TYPE.ALLORANY)
			.forEach(condition => {
				clonedValue.conditions[0][condition].forEach(eachCondition => {
					eachCondition.key = condition;
					/*
					We need to separate out conditions which fall under 'Header named' test by checking against following criterions.
						1. Header tests of type 'Subject' should be excluded.
						2. Header tests having predicate selected as either 'exists' or 'does not exist' and all other headers tests excluding condition #1 should be listed under 'Header named'.
					*/

					if (
						condition === FILTER_TEST_TYPE.HEADER_EXIST ||
						(condition === FILTER_TEST_TYPE.HEADER && eachCondition.header !== 'subject')
					) {
						eachCondition.selectedType = FILTER_TEST_TYPE.HEADER;
					}
				});
			});
		Object.keys(clonedValue.actions[0]).forEach(action => {
			clonedValue.actions[0][action].forEach(eachAction => (eachAction.key = action));
		});
		const { address, header, body, headerExists } = clonedValue.conditions[0];
		const { fileInto, discard, redirect, flag, keep, tag } = clonedValue.actions[0];
		const allConditions = flatten([address, header, body, headerExists])
			.filter(Boolean)
			.sort((a, b) => a.index - b.index);
		const allActions = flatten([fileInto, discard, redirect, flag, keep, tag])
			.filter(Boolean)
			.sort((a, b) => a.index - b.index);

		const filterConditionConfig = getFilterConditionConfig({
			rulePredicateOptions,
			toccLabel,
			fromLabel,
			bodyLabel,
			subjectLabel,
			toLabel,
			ccLabel,
			headerNamedLabel
		});
		return {
			allActions,
			allConditions,
			filterConditionConfig
		};
	}
)
export default class AdvancedFilterModal extends Component {
	state = {
		confirmModalDialogState: false
	};

	handleAddNewCondition = () => {
		const { value, onChange } = this.props;
		const allConditions = cloneDeep(value.conditions[0]);

		const conditionToBeAdded = {
			...get(NEW_FILTER_RULE, 'conditions.0.address.0'),
			index: getConditionCount(allConditions)
		};

		//Add new condition with index value equals, total of all conditions present for filter.
		allConditions.address = (allConditions.address || []).concat([conditionToBeAdded]);

		onChange(set(value, 'conditions.0', allConditions));
	};

	handleAddNewAction = () => {
		const { value, onChange } = this.props;
		//Adding newly added action as of type fileInto, with 1st folder as value.
		const allActions = cloneDeep(value.actions[0]);
		const actionToBeAdded = {
			folderPath: INBOX,
			index: getActionCount(allActions)
		};

		//Add new action with index value equals, total of all actions present for filter.
		allActions.fileInto = (allActions.fileInto || []).concat([actionToBeAdded]);

		onChange(set(value, 'actions.0', allActions));
	};

	handleDeleteItem = ({ item: { key, index }, isCondition }) => {
		const { value, onChange } = this.props;

		//Do not delete if only one item remains.
		const calculateIndex = isCondition ? getConditionCount : getActionCount;
		const firstItem = isCondition ? value.conditions[0] : value.actions[0];
		const itemToUpdate = isCondition ? 'conditions.0' : 'actions.0';

		if (calculateIndex(firstItem) !== 1) {
			//Delete item based on the conditionType and its index.
			const clonedValue = cloneDeep(firstItem);
			const clonedItem = clonedValue[key];

			clonedItem.splice(
				clonedItem.findIndex(({ index: indexForItem }) => indexForItem === index),
				1
			);

			//Decrement all the indexes by 1, that are greater than the index deleted.
			Object.keys(clonedValue).forEach(itemKey => {
				if (itemKey !== FILTER_CONDITION_TYPE.ALLORANY) {
					clonedValue[itemKey].forEach(item => {
						if (item.index > index) {
							item.index = item.index - 1;
						}
					});
				}
			});
			onChange(set(value, itemToUpdate, clonedValue));
		}
	};

	handleSwitchToBasicFilter = () => {
		const { value, onChange, switchToBasicFilter } = this.props;
		const clonedValue = cloneDeep(value);
		const actions = clonedValue.actions[0];
		const conditions = clonedValue.conditions[0];
		this.setState({
			confirmModalDialogState: false
		});

		// Delete action 'Keep in Inbox', as it is only supported by advance filter.

		if (actions.keep) {
			delete actions.keep;
		}

		const totalActions = getActionCount(actions);

		if (!totalActions) {
			//Only action present was Keep in Inbox, replace it with Move Into Folder.
			actions.fileInto = [
				{
					folderPath: INBOX,
					index: 0
				}
			];
		} else if (totalActions !== 1) {
			//Basic filter supports only 1 action, removing all extra actions.
			const allActionsKeys = Object.keys(actions);
			allActionsKeys.forEach((key, index) => {
				if (index === 0) {
					if (actions[key].length > 1) {
						actions[key].length = 1;
					}
				} else if (key !== 'stop') {
					delete actions[key];
				}
			});
		}

		//Remove extra conditions not supported by basic filter
		//Empty headerExists array as it is not supported by basic filter.
		conditions.headerExists = [];
		// Filtering out 'header named' except subject , as it is not supported by basic filter
		conditions.header = conditions.header.filter(({ header }) => header === 'subject');

		const { body, header, address } = conditions;

		//Remove all but 1 if multiple body headers are present or add 1 if none are present
		keepOneCondition(body, 'body');
		keepOneCondition(header, 'header');
		//Keep only the first occurrence of 'from' and 'to,cc' and add 1 if none are present.
		const from = address.find(add => add.header === FILTER_CONDITION_TYPE.FROM);
		const toCC = address.find(add => add.header === FILTER_CONDITION_TYPE.TOCC);
		conditions.address = [
			{
				...(from ||
					NEW_FILTER_RULE.conditions[0].address.find(
						add => add.header === FILTER_CONDITION_TYPE.FROM
					))
			},
			{
				...(toCC ||
					NEW_FILTER_RULE.conditions[0].address.find(
						add => add.header === FILTER_CONDITION_TYPE.TOCC
					))
			}
		];
		onChange(clonedValue);
		switchToBasicFilter();
	};

	handleAllOrAnyFilterChange = ev => {
		const { value, onChange } = this.props;
		onChange(set(value, 'conditions[0].allOrAny', get(ev, 'target.value')));
	};

	handleRulePredicateChange = (rule, { target: { value: eventValue } }) => {
		const { value, onChange } = this.props;
		/*
			Header tests are further divided into two types while storing them onto the server.
			1. headerTests
			2. headerExistsTests

			Header exists tests have predicate values as either 'exists' or 'does not exist'.
		*/
		const isHeaderExistsTest = ['HEADER_EXIST', 'HEADER_DOES_NOT_EXIST'].includes(eventValue);
		const predicateForValue = get(this.props, `rulePredicateOptions.${eventValue}`);
		const clonedValue = cloneDeep(value.conditions[0][rule.key]);
		let newObject = {};
		if ([FILTER_TEST_TYPE.HEADER, FILTER_TEST_TYPE.HEADER_EXIST].includes(rule.key)) {
			// Populate/Modify these header tests as per current values.
			const allCondition = cloneDeep(value.conditions[0]);
			if (isHeaderExistsTest) {
				const splicedIndex = findHeaderTestIndex(allCondition.header, rule.index);
				if (splicedIndex !== -1) {
					newObject = {
						header: rule.header,
						key: FILTER_TEST_TYPE.HEADER_EXIST,
						selectedType: FILTER_TEST_TYPE.HEADER,
						index: rule.index,
						...predicateForValue.update
					};
					allCondition.header.splice(splicedIndex, 1);
					allCondition.headerExists.push(newObject);
				} else {
					merge(findItem(allCondition.headerExists, rule.index), predicateForValue.update);
				}
				onChange(set(value, 'conditions.0', allCondition));
			} else {
				const splicedIndex = findHeaderTestIndex(allCondition.headerExists, rule.index);
				newObject = {
					header: rule.header,
					index: rule.index,
					value: '',
					selectedType: FILTER_TEST_TYPE.HEADER,
					caseSensitive: false,
					...predicateForValue.update
				};
				if (splicedIndex !== -1) {
					allCondition.headerExists.splice(splicedIndex, 1);
					allCondition.header.push(newObject);
				} else {
					merge(findItem(allCondition.header, rule.index), predicateForValue.update);
				}
				onChange(set(value, 'conditions.0', allCondition));
			}
		} else {
			merge(findItem(clonedValue, rule.index), predicateForValue.update);
			onChange(set(value, `conditions.0.${rule.key}`, clonedValue));
		}
	};

	handleConditionLabelChange = (condition, event) => {
		const { value, onChange } = this.props;
		const parentProperty = {
			from: 'address',
			'to,cc': 'address',
			body: 'body',
			subject: 'header',
			to: 'address',
			cc: 'address',
			header: 'header'
		};
		const targetProperty = get(event, 'target.value');
		const clonedValue = cloneDeep(value.conditions[0]);
		const {
			key,
			index: conditionIndex,
			value: conditionValue,
			caseSensitive,
			stringComparison,
			negative
		} = condition;

		clonedValue[key].splice(
			clonedValue[key].findIndex(({ index }) => index === conditionIndex),
			1
		);
		let newObject = {};

		if (targetProperty !== 'body') {
			newObject = {
				header: targetProperty !== 'header' ? targetProperty : '',
				index: conditionIndex,
				value: conditionValue,
				...(targetProperty === FILTER_TEST_TYPE.HEADER && {
					selectedType: targetProperty
				}),
				caseSensitive,
				stringComparison: stringComparison || 'contains',
				...(negative && { negative }),
				...(targetProperty !== 'subject' &&
					targetProperty !== FILTER_TEST_TYPE.HEADER && { part: 'all' })
			};
		} else {
			newObject = {
				value: conditionValue,
				index: conditionIndex,
				caseSensitive,
				...(stringComparison === 'contains' && negative && { negative: true })
			};
		}

		clonedValue[parentProperty[targetProperty]] = (
			clonedValue[parentProperty[targetProperty]] || []
		).concat([newObject]);

		onChange(set(value, 'conditions.0', clonedValue));
	};

	handleRuleMatchCaseChange = condition => {
		const { value, onChange } = this.props;
		const clonedValue = cloneDeep(value.conditions[0][condition.key]);
		findItem(clonedValue, condition.index).caseSensitive = has(condition, 'caseSensitive')
			? !condition.caseSensitive
			: true;
		onChange(set(value, `conditions.0.${condition.key}`, clonedValue));
	};

	handleRuleValueChange = (condition, ev) => {
		const { value, onChange } = this.props;
		const clonedValue = cloneDeep(value.conditions[0][condition.key]);
		findItem(clonedValue, condition.index).value = get(ev, 'target.value');
		onChange(set(value, `conditions.0.${condition.key}`, clonedValue));
	};

	handleHeaderNameChanged = condition => ev => {
		const { value, onChange } = this.props;
		const clonedValue = cloneDeep(value.conditions[0][condition.key]);
		clonedValue.find(({ index }) => index === condition.index).header = get(ev, 'target.value');
		onChange(set(value, `conditions.0.${condition.key}`, clonedValue));
	};

	handleFilterNameChange = ev => {
		const { value, onChange } = this.props;
		onChange(set(value, 'name', get(ev, 'target.value')));
	};

	handleFolderOrRedirectAddressChange = (action, path) => ev => {
		const { value, onChange } = this.props;
		const clonedValue = cloneDeep(value.actions[0][action.key]);
		findItem(clonedValue, action.index)[path] = get(ev, 'target.value');
		onChange(set(value, `actions.0.${action.key}`, clonedValue));
	};

	handleActionChange = action => ev => {
		const { value, onChange, tagList } = this.props;
		const targetProperty = get(ev, 'target.value');
		const clonedValue = cloneDeep(value.actions[0]);
		clonedValue[action.key].splice(
			clonedValue[action.key].findIndex(({ index }) => index === action.index),
			1
		);
		let newObject = {
			index: action.index
		};

		// Add properties to object based on the type of action.
		switch (targetProperty) {
			case FILTER_ACTION_TYPE.FLAG:
				newObject = {
					...newObject,
					flagName: 'flagged'
				};
				break;
			case FILTER_ACTION_TYPE.FILE_INTO:
				newObject = {
					...newObject,
					folderPath: INBOX,
					copy: false
				};
				break;
			case FILTER_ACTION_TYPE.REDIRECT:
				newObject = {
					...newObject,
					address: '',
					copy: false
				};
				break;
			case FILTER_ACTION_TYPE.MARK_AS_READ:
				newObject = {
					...newObject,
					flagName: 'read'
				};
				break;
			case FILTER_ACTION_TYPE.TAG:
				newObject = {
					...newObject,
					tagName: tagList[0]
				};
				break;
		}

		const selectedProperty =
			targetProperty === FILTER_ACTION_TYPE.MARK_AS_READ ? FILTER_ACTION_TYPE.FLAG : targetProperty;

		clonedValue[selectedProperty] = (clonedValue[selectedProperty] || []).concat([newObject]);
		onChange(set(value, 'actions.0', clonedValue));
	};

	handleProcessAdditionalChange = e => {
		const { value, onChange } = this.props;
		const clonedValue = cloneDeep(value.actions[0]);

		if (e.target.checked) {
			clonedValue.stop = [{ index: 1 }];
		} else {
			delete clonedValue.stop;
		}
		onChange(set(value, 'actions.0', clonedValue));
	};

	handleCloseConfirmModalDialog = () => {
		this.setState({
			confirmModalDialogState: false
		});
	};

	handleOpenConfirmModal = () => {
		this.setState({
			confirmModalDialogState: true
		});
	};

	render(
		{
			value,
			folders,
			matchesScreenMd,
			title,
			allActions,
			allConditions,
			rulePredicateOptions,
			filterConditionConfig,
			headerInputPlaceholder,
			tagList,
			filterMailFeature
		},
		{ confirmModalDialogState }
	) {
		const selectAllOrAnyRule = get(value, 'conditions[0].allOrAny');

		return (
			<div class={cx(style.filterModalContent, style.advancedFilterModal)}>
				<div class={cx(style.subsection, style.titleSubsection)}>
					{!matchesScreenMd && title && (
						<div class={style.title}>
							<Text id={title} />
						</div>
					)}
					<div
						class={cx(style.subsectionTitle, style.filterSubsectionTitle, style.filterTitleLabel)}
					>
						<Text id="settings.filterRuleModal.filterNameLabel" />
					</div>

					<div class={style.subsectionBody}>
						<input
							class={cx(style.textInput, style.textInputOfFilter, style.filterName)}
							type="text"
							value={value.name}
							onChange={this.handleFilterNameChange}
						/>
					</div>
					<Button
						class={style.advancedFilterSetting}
						styleType="text"
						onClick={this.handleOpenConfirmModal}
					>
						<Text id="settings.filterRuleModal.switchToBasic" />
					</Button>
				</div>

				<div class={cx(style.half, style.inline, style.rulePrompt)}>
					<Text id="settings.filterRuleModal.rulePrompt1" />
				</div>
				<div class={cx(style.half, style.inline)}>
					<Select value={selectAllOrAnyRule} onChange={this.handleAllOrAnyFilterChange}>
						<option value="allof">
							<Text id="settings.filterRules.allof" />
						</option>
						<option value="anyof">
							<Text id="settings.filterRules.anyof" />
						</option>
					</Select>
				</div>
				<div class={cx(style.half, style.inline, style.rulePrompt)}>
					<Text id="settings.filterRuleModal.rulePrompt2" />
				</div>
				<AdvancedConditionContainer
					allConditions={allConditions}
					rulePredicateOptions={rulePredicateOptions}
					filterConditionConfig={filterConditionConfig}
					matchesScreenMd={matchesScreenMd}
					onRuleMatchCaseChange={this.handleRuleMatchCaseChange}
					onConditionLabelChange={this.handleConditionLabelChange}
					onRulePredicateChange={this.handleRulePredicateChange}
					onRuleValueChange={this.handleRuleValueChange}
					onDeleteCondition={this.handleDeleteItem}
					onAddNewCondition={this.handleAddNewCondition}
					onHeaderNameChanged={this.handleHeaderNameChanged}
					headerInputPlaceholder={headerInputPlaceholder}
				/>
				<AdvancedActionContainer
					allActions={allActions}
					onFolderOrAddressChange={this.handleFolderOrRedirectAddressChange}
					onDeleteAction={this.handleDeleteItem}
					onAddNewAction={this.handleAddNewAction}
					onActionChange={this.handleActionChange}
					folders={folders}
					tagList={tagList}
					filterMailFeature={filterMailFeature}
				/>
				<div>
					<span class={style.additionalFilters}>
						<Text id="settings.filterRuleModal.also" />
					</span>
					{processAdditionalFilter(value.actions[0].stop, this.handleProcessAdditionalChange)}
				</div>
				{confirmModalDialogState && (
					<ModalDialog
						title="settings.filterRuleModal.confirmModalTitle"
						actionLabel="buttons.continue"
						cancelLabel="buttons.cancel"
						onClose={this.handleCloseConfirmModalDialog}
						onAction={this.handleSwitchToBasicFilter}
					>
						<p>
							<Text id="settings.filterRuleModal.switchToBasicWarning" />
						</p>
					</ModalDialog>
				)}
			</div>
		);
	}
}

function processAdditionalFilter(checked, onChange) {
	return (
		<div class={style.processAdditional}>
			<label>
				<ChoiceInput onChange={onChange} type="checkbox" checked={checked} />
				<Text id="settings.filterRuleModal.stopProcessing" />
			</label>
		</div>
	);
}

function keepOneCondition(condition, conditionType) {
	if (condition.length > 1) {
		condition.length = 1;
	} else if (!condition.length) {
		condition.push(NEW_FILTER_RULE.conditions[0][conditionType][0]);
	}
}

function findItem(filter, indexToFind) {
	return filter.find(({ index }) => index === indexToFind);
}

function findHeaderTestIndex(condition, indexToFind) {
	return condition.findIndex(({ index }) => index === indexToFind);
}
