import PersonaForm from './persona-form';
import { useCallback } from 'preact/hooks';

export default function AddEditPersonaView({
	updateAccountSettings,
	selectedAccountId,
	isEditView,
	sendTypes,
	selectedAccount,
	...restProps
}) {
	const handleEditChange = useCallback(
		(key, value) => {
			const { sendAs, sendOBO } = sendTypes;

			let emailAddressType;
			if (key === 'emailAddress') {
				//need to set the correct type (sendAs or sendOBO)
				const emailArr = value.split(' ');
				value = emailArr[emailArr.length - 1];
				emailAddressType = emailArr.length > 1 ? sendOBO : sendAs;
			}

			updateAccountSettings(
				{
					[key]: value,
					...(emailAddressType && { emailAddressType })
				},
				selectedAccountId
			);
		},
		[selectedAccountId, sendTypes, updateAccountSettings]
	);

	return (
		<PersonaForm
			{...restProps}
			{...(updateAccountSettings && {
				onChange: handleEditChange
			})}
			isEditView={isEditView}
			sendTypes={sendTypes}
			formData={selectedAccount || {}}
		/>
	);
}
