import { createElement, Component, Fragment } from 'preact';

export default function withDialog(showHandler, renderDialog) {
	return BaseComponent =>
		class WithDialog extends Component {
			state = {
				showDialog: false
			};

			handleShow = () => {
				this.setState({ showDialog: true });
			};

			handleClose = () => {
				this.setState({ showDialog: false });
			};

			render() {
				const handler = { [showHandler]: this.handleShow };

				return (
					<Fragment>
						<BaseComponent {...this.props} {...handler} />

						{this.state.showDialog &&
							createElement(renderDialog, {
								onClose: this.handleClose,
								closeDialog: this.handleClose,
								...this.props
							})}
					</Fragment>
				);
			}
		};
}
