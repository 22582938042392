import Search from '../../graphql/queries/search/search.graphql';
import { last } from '../../lib/util';
import unionBy from 'lodash-es/unionBy';
import { useQuery } from '@apollo/client';

const TYPE_MAP = {
	conversation: 'conversations',
	message: 'messages',
	contact: 'contacts',
	appointment: 'appointments',
	task: 'tasks',
	wiki: 'wikis',
	document: 'documents'
};

function mergeResults(prev, next, type) {
	return next.search && next.search[type]
		? unionBy(prev.search[type], next.search[type], 'id') // merge items by id
		: prev.search[type];
}

/**
 * Given two search results, merge them together
 */
export function mergeSearches(prev, next) {
	if (!next) {
		return prev;
	}

	return {
		...next.search,
		contacts: mergeResults(prev, next, 'contacts'),
		messages: mergeResults(prev, next, 'messages'),
		conversations: mergeResults(prev, next, 'conversations')
	};
}

export const useSearchQuery = ({ variables, fetchPolicy, ...options }) => {
	const { fetchMore, data, ...rest } = useQuery(Search, {
		variables,
		...options,
		//fetchPolicy is passed here bcoz in certain places we directly extract data
		//instead of passing from parent to child
		fetchPolicy: fetchPolicy || 'cache-and-network',
		nextFetchPolicy: 'cache-first'
	});

	const more = () => {
		const items = data?.search?.[TYPE_MAP[variables.types]];
		const offset = items?.length || 0;
		const { id, sortField } = last(items) || {};

		return fetchMore({
			variables: {
				...variables,
				offset,
				cursor: {
					id,
					sortVal: sortField
				}
			},
			updateQuery: (previousResult, { fetchMoreResult }) => ({
				...fetchMoreResult,
				search: mergeSearches(previousResult, fetchMoreResult)
			})
		});
	};
	return {
		more,
		search: data && data.search,
		...rest
	};
};
