import cx from 'classnames';
import style from './style';
import { Icon } from '@zimbra/blocks';
import { getBasePath } from '../../lib/util';
import { Match } from 'preact-router/match';
import { useSelector } from 'react-redux';
import { useCallback } from 'preact/hooks';
import { Localizer, Text } from 'preact-i18n';
import ZimletSlot from '../zimlet-slot';
import get from 'lodash-es/get';
import { route } from 'preact-router';

const basePath = getBasePath();

const IconLink = ({ url, icon, name, matches, currentURL, onItemClick, iconText }) => {
	const view = useSelector(state => state.url.view);
	const isActive = matches || view === name;

	const handleClick = useCallback(
		e => {
			onItemClick(e, { name, url, currentURL });
		},
		[currentURL, name, onItemClick, url]
	);

	return (
		<Localizer>
			<a
				href={url}
				title={<Text id={`appNavigation.${name}`} />}
				{...(typeof onItemClick === 'function' && { onClick: handleClick })}
				class={cx(style.link, isActive && style.active)}
			>
				<Icon name={icon} />
				{iconText && <span class={style.iconText}>{iconText}</span>}
			</a>
		</Localizer>
	);
};

const MenuItem = ({ id, onItemClick }) => {
	const { url, icon, name } = useSelector(state => get(state, `navigation.verticals.${id}`));
	const iconText = id === 'calendar' && new Date().getDate();
	return (
		<li class={style.navListItem}>
			<Match path={url} basePath={basePath}>
				{({ matches, url: currentURL }) => (
					<IconLink
						url={url}
						icon={icon}
						onItemClick={onItemClick}
						name={name}
						matches={matches}
						currentURL={currentURL}
						iconText={iconText}
					/>
				)}
			</Match>
		</li>
	);
};

const AppNavigation = ({ class: cls, onItemClick }) => {
	const verticals = useSelector(state => get(state, 'navigation.verticals'));

	const handleItemClick = useCallback(
		(event, { name, url, currentURL }) => {
			typeof onItemClick === 'function' && onItemClick();
			const ctrlOrMetaKey = event.ctrlKey || event.metaKey;

			if (!ctrlOrMetaKey) {
				event.preventDefault();
				event.stopPropagation();

				const lastVisitedTab = verticals[name]?.lastVisitedTab;

				if (currentURL === lastVisitedTab) {
					route(basePath + url);
				} else {
					route(lastVisitedTab ? lastVisitedTab : basePath + url);
				}
			}
		},
		[verticals, onItemClick]
	);

	return (
		<ul class={cx(style.navList, cls)}>
			{Object.keys(verticals).map(key => (
				<MenuItem key={key} id={key} onItemClick={handleItemClick} />
			))}
			<ZimletSlot name="vertical-menu-item" />
		</ul>
	);
};

export default AppNavigation;
