import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUrl, route } from 'preact-router';
import { Text, Localizer } from 'preact-i18n';
import { Icon } from '@zimbra/blocks';
import ActionMenu, { DropDownWrapper } from '../action-menu';
import ActionMenuItem from '../action-menu-item';
import ActionMenuGroup from '../action-menu-group';
import MenuItem from '../menu-item';
import queryString from 'query-string';
import s from './style.less';
import { addTabThunk, removeAllTabsThunk, removeTabThunk } from '../../utils/thunk';
import { useClientConfig } from '../../hooks/client-config';
import { useCallback, useEffect, useMemo } from 'preact/hooks';
import { useCommandHandlers } from '../../keyboard-shortcuts/command-handlers';
import escapeStringRegexp from 'escape-string-regexp';

const Tab = ({ tab, MenuItemComponent = MenuItem, class: cls, onCloseActive }) => {
	const dispatch = useDispatch();
	const { url, titleId, title, permanent, onClose } = tab;

	const isActive = useCallback(
		urlToCheck => {
			const tabUrlRegex = new RegExp(
				'^' + escapeStringRegexp(url) + (url.endsWith('/') ? '?' : '')
			);
			return tabUrlRegex.test(urlToCheck);
		},
		[url]
	);

	const handleCloseClick = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();

			if (getCurrentUrl() === url) {
				onCloseActive(tab);
			} else {
				onClose ? onClose(tab) : dispatch(removeTabThunk(tab));
			}
		},
		[dispatch, onClose, onCloseActive, tab, url]
	);

	return (
		<Localizer>
			<MenuItemComponent
				href={url}
				isItemActive={isActive}
				title={titleId ? <Text id={titleId} /> : title}
				class={cls}
				activeClass={s.active}
				responsive
			>
				<div class={s.tab}>
					<div class={s.tabTitle}>{titleId ? <Text id={titleId} /> : title}</div>
					{!permanent && (
						<Icon name="close" size="sm" class={s.tabClose} onClick={handleCloseClick} />
					)}
				</div>
			</MenuItemComponent>
		</Localizer>
	);
};

export default function AppNavigationTabs(_, context) {
	const url = useSelector(
		({ navigation, url: currentUrl }) => navigation?.verticals?.[currentUrl.view]?.url || '/'
	);
	const tabs = useSelector(
		({ navigation, url: currentUrl }) => navigation?.verticals?.[currentUrl.view]?.tabs || []
	);

	const dispatch = useDispatch();
	const closableTabs = useMemo(() => tabs.filter(({ permanent }) => !permanent), [tabs]);
	const { slugs } = useClientConfig('routes.slugs');

	const handleCloseActive = useCallback(
		tab => {
			const { onClose, type } = tab;
			const index = tabs.indexOf(tab);

			if (index > -1 && tabs.length - 1 > index) {
				route(tabs[index + 1].url);
			} else if (index > 0) {
				route(tabs[index - 1].url);
			} else if (type === 'search') {
				const regexCal = new RegExp(`^/search/${slugs.calendar}/`, 'i');
				const regexCont = new RegExp(`^/search/${slugs.contacts}/`, 'i');

				if (regexCal.test(tab.url)) {
					route('/' + slugs.calendar);
				} else if (regexCont.test(tab.url)) {
					route('/' + slugs.contacts);
				} else {
					route('/');
				}
			} else if (type === 'event') {
				route(`/${slugs.calendar}`);
			} else {
				route(url);
			}
			onClose ? onClose(tab) : dispatch(removeTabThunk(tab));
		},
		[tabs, url, dispatch, slugs.calendar, slugs.contacts]
	);

	const handleEscape = useCallback(() => {
		const currentUrl = getCurrentUrl();
		const tab = closableTabs.find(({ url: tabUrl }) => tabUrl === currentUrl);
		tab && handleCloseActive(tab);
	}, [handleCloseActive, closableTabs]);

	const handleCloseAll = useCallback(() => {
		const currentUrl = getCurrentUrl();
		const shouldRoute = closableTabs.find(({ url: tabUrl }) => tabUrl === currentUrl);

		dispatch(removeAllTabsThunk());

		// if user is on closable tab and clicks on close all
		// then route to parent vertical url
		if (shouldRoute) {
			route(url);
		}
	}, [dispatch, url, closableTabs]);

	useEffect(() => {
		// After reload, if there is no tabs in redux state. check url and create one
		if (tabs.length === 0 && location.search.includes('tabid')) {
			const { pathname: path, search } = location;
			dispatch(
				addTabThunk({
					url: `${path}${search}`,
					id: path.substr(path.lastIndexOf('/') + 1),
					tabId: queryString.parse(search).tabid
				})
			);
		}
	}, [dispatch, tabs.length]);

	useCommandHandlers(
		() => [
			{
				context: 'all',
				command: 'CLOSE_TAB',
				handler: handleEscape
			}
		],
		context
	);

	return (
		<div class={s.tabs}>
			{tabs.map(tab => (
				<div class={s.tabMenuItemWrapper}>
					<Tab class={s.tabMenuItem} tab={tab} onCloseActive={handleCloseActive} />
				</div>
			))}

			{closableTabs.length > 0 && (
				<ActionMenu
					class={s.tabsDropdown}
					popoverClass={s.tabsDropdownPopover}
					toggleClass={s.tabsDropdownToggle}
					arrowSize="xs"
					anchor="end"
					iconOnly
				>
					<DropDownWrapper>
						<ActionMenuGroup class={s.dropdownTabList}>
							{closableTabs.map(tab => (
								<Tab
									class={s.tabDropdownMenuItem}
									tab={tab}
									MenuItemComponent={ActionMenuItem}
									onCloseActive={handleCloseActive}
								/>
							))}
						</ActionMenuGroup>
						<ActionMenuGroup>
							<ActionMenuItem onClick={handleCloseAll} class={s.tabsCloseAll}>
								<Icon name="close" size="sm" class={s.tabsCloseAllIcon} />
								<Text id="navigation.closeAllTabs" />
							</ActionMenuItem>
						</ActionMenuGroup>
					</DropDownWrapper>
				</ActionMenu>
			)}
		</div>
	);
}
