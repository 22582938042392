import moment from 'moment';
import groupBy from 'lodash-es/groupBy';
import get from 'lodash-es/get';

export function groupByArray(array, getKey) {
	const groups = {};
	array.forEach(function (o) {
		const group = getKey(o);
		groups[group] = (groups[group] || []).concat([o]);
	});
	return Object.keys(groups).map(group => groups[group]);
}

export function getTimeRange(item, selectedDate = moment()) {
	if (isAllDayEvent(item, selectedDate)) {
		return 'miniCal.allDay';
	}

	const start = item.instances[0].start;
	const end = start + item.duration;
	const startSelectedDay = moment(selectedDate).startOf('day').valueOf();
	const endSelectedDay = moment(selectedDate).endOf('day').valueOf();

	const eventStart = start < startSelectedDay ? startSelectedDay : start;
	const eventEnd = end < endSelectedDay ? end : endSelectedDay;

	return `${moment(eventStart).format('LT')} - ${moment(eventEnd).format('LT')}`;
}

// check event is started before the given day and ending after the given day || all day event
export function isAllDayEvent(event, selectedDate = moment()) {
	const eventStart = event.instances[0].start;

	return (
		(eventStart <= moment(selectedDate).startOf('day').valueOf() &&
			moment(selectedDate).endOf('day').valueOf() <= eventStart + event.duration) ||
		get(event, 'allDay')
	);
}

export function getSortedAppointments(appointments, selectedDate) {
	const groupedAppointments = groupBy(appointments, appointment =>
		isAllDayEvent(appointment, selectedDate)
	);

	const allDayAppointments =
		(groupedAppointments && groupedAppointments.true && [groupedAppointments.true]) || [];

	const nonAllDayAppointment = groupByArray(
		(groupedAppointments && groupedAppointments.false) || [],
		item => `${item.instances[0].start} - ${item.instances[0].start + item.duration}`
	);

	const sortedAppointments = (nonAllDayAppointment || []).sort(
		(a, b) => a[0].instances[0].start - b[0].instances[0].start
	);

	return allDayAppointments.concat(sortedAppointments);
}

export function dateToFromNow(date) {
	const now = moment().startOf('day');

	switch (true) {
		case moment(now).isSame(date, 'day'):
			return 'miniCal.today';
		case moment(now).add(-1, 'days').isSame(date, 'day'):
			return 'miniCal.yesterday';
		case moment(now).add(1, 'days').isSame(date, 'day'):
			return 'miniCal.tomorrow';
		default:
			return moment(date).format('[formatWeekMonthDayMedium]');
	}
}
