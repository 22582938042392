import cx from 'classnames';
import { Icon } from '@zimbra/blocks';
import NakedButton from '../naked-button';
import { useCallback, useEffect } from 'preact/hooks';
import s from './style.less';

let inputBox;

export default function ActionMenuFilter({ onBack, onClear = () => {}, ...rest }) {
	const handleClick = useCallback(e => e.stopPropagation, []);

	const handleClear = useCallback(
		e => {
			e.stopPropagation();
			inputBox && inputBox.focus();
			onClear();
		},
		[onClear]
	);

	useEffect(() => {
		inputBox && inputBox.focus();
	}, []);

	return (
		<div class={cx(s.actionMenuFilter, onBack && s.withBack)}>
			{onBack && (
				<NakedButton onClick={onBack} class={s.backButton}>
					<Icon name="angle-left" />
				</NakedButton>
			)}
			<Icon name="search" class={s.icon} />
			<input
				{...rest}
				type="text"
				ref={ref => (inputBox = ref)}
				class={cx(s.input, rest.class, rest.className)}
				onClick={handleClick}
			/>
			{rest.value && rest.value !== '' && (
				<NakedButton onClick={handleClear} class={s.clear}>
					<Icon name="close" size="sm" />
				</NakedButton>
			)}
		</div>
	);
}
