import { handleActions } from 'redux-actions';
import { circularIndex } from '../../lib/util';
import * as actionCreators from './actions';
import findIndex from 'lodash-es/findIndex';
import isEqual from 'lodash-es/isEqual';

const initialState = {
	selected: -1,
	selectedAttachment: null,
	group: []
};

function getIndex(attachment, group) {
	return findIndex(group, g => isEqual(g, attachment));
}

function showNextPrev(state, next = false) {
	const { selectedAttachment, group } = state;
	let index = getIndex(selectedAttachment, group);
	index = next ? index + 1 : index - 1;
	const selectedIndex = circularIndex(index, group.length);
	return {
		...state,
		selectedAttachment: group[selectedIndex],
		selected: selectedIndex
	};
}

export default handleActions(
	{
		[actionCreators.setPreviewAttachment]: (state, action) => {
			let { group, attachment } = action.payload;
			if (!group && !attachment) {
				return {
					...state,
					selected: initialState.selected,
					group: initialState.group
				};
			}
			group = group || state.group;
			const index = getIndex(attachment, group);
			return {
				...state,
				group,
				selectedAttachment: attachment,
				selected:
					typeof attachment === 'number' ? attachment : index !== -1 ? index : state.selected
			};
		},
		[actionCreators.setAttachments]: (state, action) => {
			const { group } = action.payload;
			return {
				...state,
				group
			};
		},
		[actionCreators.setSelectedAttachment]: (state, action) => {
			const { attachment } = action.payload;
			const index = getIndex(attachment, state.group);
			return {
				...state,
				selected: index,
				selectedAttachment: attachment
			};
		},
		[actionCreators.previewPreviousPage]: state => {
			return showNextPrev(state);
		},
		[actionCreators.previewNextPage]: state => {
			return showNextPrev(state, true);
		},
		[actionCreators.showNextOnRemove]: (state, action) => {
			const { attachment } = action.payload;

			if (isEqual(attachment, state.selectedAttachment)) {
				return showNextPrev(state, true);
			}
			const index = getIndex(state.selectedAttachment, state.group);
			return {
				...state,
				selected: index
			};
		}
	},
	initialState
);
