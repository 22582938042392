import { Component } from 'preact';
import { withText } from 'preact-i18n';
import { connect } from 'react-redux';
import { getCurrentUrl, route } from 'preact-router';
import get from 'lodash-es/get';
import { multitasking } from '../../constants/mailbox-metadata';
import { openModalCompose } from '../../store/email/actions';
import { addNewEvent } from '../../store/calendar/actions';
import { getMailboxMetadata } from '../../graphql-decorators/mailbox-metadata';
import withMediaQuery from '../with-media-query/index';
import clientConfiguration from '../client-config';
import { minWidth, screenMd } from '../../constants/breakpoints';
import { getBasePath, uriSegment } from '../../lib/util';
import { addTabThunk, getDataFromReduxStoreThunk } from '../../utils/thunk';

export default function withTabManager(mapPropsToTab = () => {}) {
	return BaseComponent => {
		@getMailboxMetadata({}, ({ getMailboxMetadata: mailboxMetadata }) => ({
			[multitasking.name]: get(mailboxMetadata, `meta.0._attrs.${multitasking.name}`)
		}))
		@connect(
			(state, props) => ({
				verticals: get(state, 'navigation.verticals'),
				multitasking: props[multitasking.name],
				verticalViewType: get(state, 'url.view')
			}),
			{
				openModalCompose,
				addNewEventState: addNewEvent,
				addTabThunk,
				getDataFromReduxStoreThunk
			}
		)
		@withText({
			newTabEvent: 'navigation.newTabEvent',
			newTabMail: 'navigation.newTabMail'
		})
		@withMediaQuery(minWidth(screenMd), 'matchesScreenMd')
		@clientConfiguration('routes.slugs')
		class TabManager extends Component {
			register = props => {
				if (props.multitasking === multitasking.values.tabs || !props.multitasking) {
					const tab = mapPropsToTab(props);
					if (!tab) {
						return;
					}

					const tabs = get(props.verticals, `${props.verticalViewType}.tabs`);
					const currentUrl = getCurrentUrl();
					const isRegistered = tabs.find(t => t.url === currentUrl);
					if (!isRegistered) {
						props.addTabThunk({
							...tab,
							url: tab.url || currentUrl
						});
					}
				}
			};

			handleNewTab = (tab, view) => {
				if (!tab) {
					return;
				}
				const {
					verticals,
					matchesScreenMd,
					addTabThunk: addTabAction,
					openModalCompose: openModalComposeAction,
					slugs,
					newTabEvent,
					newTabMail,
					verticalViewType
				} = this.props;

				const viewType = view || verticalViewType;
				const tabs = get(verticals, `${viewType}.tabs`);

				// Just route to the url in case if opening same saved-item-tab again and openInNewTab is set to false
				if (!tab.openInNewTab && tab.id) {
					const matchedTab = tabs.find(
						existingTab => existingTab.id === tab.id && existingTab.url.includes(tab.url)
					);
					if (matchedTab) {
						route(matchedTab.url);
						return;
					}
				}

				// Open tab in case of desktop screen size, avoid otherwise
				// Open tab for new/edit events in mobile screens
				if (matchesScreenMd || tab.type === 'event') {
					// Generate a unique tab id
					const tabId = new Date().getTime().toString();
					let url = tab.url;

					// Adjust tab id into query parameters
					url = `${getBasePath()}${url}${url.includes('?') ? '&' : '?'}tabid=${tabId}`;

					let baseSegment = uriSegment(tab.url);
					if (baseSegment === slugs.search) {
						baseSegment = uriSegment(tab.url, 1);
					}

					// Add tab into redux state and route to URL
					addTabAction({
						...tab,
						url,
						tabId,
						// if basesegment is not present inside slugs then use integrations as view
						view: tab.view || (slugs[baseSegment] && baseSegment) || slugs.integrations,
						...{ title: tab.title ? tab.title : tab.type === 'event' ? newTabEvent : newTabMail },
						...(tab.onClose && { onClose: tab.onClose })
					});
					route(url);
				} else {
					tab.tabItem && openModalComposeAction({ message: tab.tabItem, mode: 'mailto' });
					route(tab.url);
				}
			};

			getTabItem = (tabId, view) => {
				if (!tabId) return;

				view = view || this.props.getDataFromReduxStoreThunk('url.view');
				const tabsForVertical = this.props.getDataFromReduxStoreThunk(
					`navigation.verticals.${view}.tabs`
				);
				const matchedTab = tabsForVertical.find(tab => tab.tabId === tabId);

				return get(matchedTab, 'tabItem');
			};

			componentWillMount() {
				this.register(this.props);
			}

			componentWillReceiveProps(nextProps) {
				this.register(nextProps);
			}

			render() {
				return (
					<BaseComponent
						{...this.props}
						handleNewTab={this.handleNewTab}
						getTabItem={this.getTabItem}
					/>
				);
			}
		}

		return TabManager;
	};
}
